import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FlexTileGrid from '../../../Components/Shared/TileGrid/TileGrid';
import grievanceSettings from '../../../Assets/grievance-settings.svg';
import recordIcon from '../../../Assets/record-icon.svg';
import reportIcon from '../../../Assets/grievance-report.svg';
import DataStore from '../../../Stores/DataStore';
import { useGrievanceRole } from '../../../hooks/useGrievancePermissions';
import { ROLES } from '../../../Lib/CONSTANTS';
import Authentication from '../../../Stores/Authentication';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { Icon } from '../../../Components/Shared/Icon';
import { Box } from '@mui/material';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';

const baseRoute = '/forms/admin';

const adminFeatures = [
  {
    name: 'Grievances and Forms',
    icon: recordIcon,
    link: `${baseRoute}/grievances`,
    getNotifications: DataStore.getAllGrievancesCount,
    permissionKey: 'all-grievances'
  },
  {
    name: 'Grievances and Forms Settings',
    icon: grievanceSettings,
    link: `${baseRoute}/settings`,
    permissionKey: 'grievance-settings'
  }
];

const FormsAdmin = () => {
  const [features, setFeatures] = useState([]);
  const grievanceRole = useGrievanceRole();
  const userPermissions = useUserPermissions();

  useEffect(() => {
    (async () => {
      await fetchNotifications();
    })();
  }, [grievanceRole.canGoToGrievances()]);

  const permissionMap = {
    'all-grievances': grievanceRole.canGoToGrievances(),
    'grievance-settings': Authentication.fullUserRole === ROLES.SUPER_ADMIN
  };

  const fetchNotifications = async () => {
    for (const feature of adminFeatures) {
      if (feature.getNotifications) {
        feature.notificationCount = (await feature.getNotifications()).count;
      }
    }

    const features = adminFeatures
      .filter((feature) =>
        feature.permissionKey !== undefined
          ? permissionMap[feature.permissionKey]
          : true
      )
      .map(({ name, icon, link, notificationCount }, i) => (
        <Link to={link} key={i} style={{ margin: '0 auto' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              whiteSpace: '',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                color: '#FF9900',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.83em',
                visibility: notificationCount ? 'visible' : 'hidden'
              }}
            >
              <Icon icon="Warning" style={{ width: '0.8em' }} />{' '}
              <span style={{ marginLeft: '3px', paddingTop: '2px' }}>
                New Alerts
              </span>
            </div>
            <div style={{ color: '#3899FF', display: 'inline' }}>
              <img src={icon} height={40} alt="" />
            </div>
            <h2 style={{ fontSize: '18px' }}> {name} </h2>
          </div>
        </Link>
      ));

    setFeatures(features);
  };

  useEffect(() => {
    (async () => {
      if (userPermissions.canViewGrievanceReport() && !adminFeatures.find(f => f.name === 'Grievance Report')) {
        adminFeatures.push({
          name: 'Grievance Report',
          icon: reportIcon,
          link: `${baseRoute}/reports`
        });
      }
      // await fetchNotifications()
    })();
  }, []);

  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title="Grievances and Forms" />
      <div className='home'>
        <FlexTileGrid tileStyle={{ padding: '25px' }}>{features}</FlexTileGrid>
      </div>
    </Box>
  );
};

export default FormsAdmin;
