import React, { useCallback } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import AuthenticationStore from '../Stores/Authentication';
import { observer } from 'mobx-react';
import { removeSocketConnections } from '../Lib/socket';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { clearCookies } from '../Lib/util';
import { LOGIN_PATH, LOGOUT_PATH, VERIFY_PATH } from '../constants/paths';

const initialValue = {};

export const AppContext = React.createContext(initialValue);

export const useAppContext = () => React.useContext(AppContext);

export const AppContextProvider = observer(({ value, children }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const logout = useCallback(async () => {
    try {
      const response = await AuthenticationStore.logOut();
      AuthenticationStore.setIsAuthenticated(false);

      if (response.error) {
        throw response.error;
      }

      removeSocketConnections();
      queryClient.clear();

      const logoutMessage = localStorage.getItem('logoutMessage');
      localStorage.clear();
      sessionStorage.clear();

      localStorage.setItem('logoutMessage', logoutMessage);

      clearCookies(window.location.hostname);

      history.replace(`${LOGIN_PATH}?force-reload=true`);
    } catch (e) {
      const hasMatch = [LOGOUT_PATH, VERIFY_PATH].find((path) => !!matchPath(history.location.pathname, { path, exact: true }));

      if (hasMatch) {
        history.replace(`${LOGIN_PATH}?force-reload=true`);
      } else {
        toast.error(localStorage.getItem('logoutMessage') || 'An error occurred while logging out.');
      }
    }
  }, [history, queryClient]);

  return (
    <AppContext.Provider
      value={{
        ...value,
        logout
      }}
    >
      {children}
    </AppContext.Provider>
  );
});
