import React, { useMemo } from 'react';
import { Icon } from './Icon';
import HTMLReactParser from 'html-react-parser';

const Banner = ({ type, show, message, handleClose, top, bannerId }) => {
  const attributes = useMemo(() => {
    if (type === 'error') {
      return {
        backgroundColor: '#F83232',
        icon: <Icon icon="CancelRounded" />
      };
    } else if (type === 'warning') {
      return {
        backgroundColor: '#FF8E26',
        icon: <Icon icon="Report" />
      };
    } else {
      return {
        backgroundColor: '#0F61AC',
        icon: <Icon icon="ErrorRounded" />
      };
    }
  }, [type]);

  if (!show) { return null; }

  return (
    <div className="nucleos-core">
      <div
        id={bannerId}
        style={{
          width: '100%',
          backgroundColor: attributes.backgroundColor,
          color: 'white',
          padding: '10px',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 99999,
          top: top || 0
        }}
      >
        <p style={{
          flex: 1,
          display: 'flex',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '19px',
          alignItems: 'center'
        }}>
          <span className="mr-2">
            {attributes.icon}
          </span>
          <span className="text-center" style={{ flex: 1 }}>
            {HTMLReactParser(message)}
          </span>
        </p>
        <button
          className="ml-2"
          onClick={handleClose}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
            fontSize: '1.2rem',
            cursor: 'pointer'
          }}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Banner;
