import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DataStore from '../Stores/DataStore';
import AuthenticationStore from '../Stores/Authentication';
import LoadingComponent from '../Components/Widgets/LoadingComponent';
import ToastMessage from '../Components/Widgets/ToastMessage';
import { styled } from '@mui/material';
import { useAppContext } from '../Contexts/AppContext';

const PREFIX = 'Verify';
const classes = {
  verifyEmailText: `${PREFIX}-verifyEmailText`
};

const Root = styled('div')(() => ({
  [`& .${classes.verifyEmailText}`]: {
    textAlign: 'center',
    padding: '20px',
    color: '#333333',
    fontSize: '20px',
    lineHeight: '19px'
  }
}));

const Verify = () => {
  const appContext = useAppContext();

  const [loader, setLoader] = useState(true);
  const [verifyError, setVerifyError] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const { code } = useParams();
  const history = useHistory();

  useEffect(() => {
    verifyEmailCode();
  }, []);

  const verifyEmailCode = async () => {
    const { verifyEmail, getSelf } = DataStore;
    const { isAuthenticated, uid } = AuthenticationStore;
    setLoader(true);
    const response = await verifyEmail({ verificationCode: code });
    if (isAuthenticated) {
      await getSelf();
    }
    setLoader(false);
    setOpenToast(true);
    if (response && response.error) {
      setVerifyError(true);
      setTimeout(() => {
        appContext.logout();
      }, 2000);
    } else {
      if (isAuthenticated) {
        if (uid == response.userId) {
          setTimeout(() => {
            if (DataStore.isCurrentUserFormsAdmin()) {
              history.push('/forms/admin');
            } else {
              history.push('/home');
            }
          }, 2000);
        } else {
          setTimeout(() => {
            appContext.logout();
          }, 2000);
        }
      } else {
        setTimeout(() => {
          appContext.logout();
        }, 2000);
      }
    }
  };

  return (
    <Root style={{ width: '100%' }}>

      <>
        <LoadingComponent />
        {loader ? <p className={classes.verifyEmailText}>Verifying Your Email!</p> : null}
      </>

      <ToastMessage
        autoHideDuration={2000}
        message={verifyError ? 'Email verification failed' : 'Email is verified!'}
        onClose={() => setOpenToast(false)}
        open={openToast}
        type={verifyError ? 'error' : 'success'}
      />
    </Root>
  );
};

export default (Verify);
