import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Box, Grid, Stack, Typography, Button } from '@mui/material';
import googleLoginLogo from '../Assets/btn_google_signin_dark_normal_web.png';
import Authentication from '../Stores/Authentication';
import { validatePassword } from '../Components/Form';
import { TextInput } from '@nucleos/core-ui';
import achieveDxpLoginArt from '../Assets/achieve-dxp-login-art.svg';
import Header from '../Components/Layout/Header';

@withRouter
class ChangePassword extends Component {
  static propTypes = {
    // react-router object see below
    history: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.state = {
      error: '',
      reset: false
    };
  }

  componentDidMount = () => {
    const code = this.props.match.params.code;
    if (!code) {
      this.props.history.push('/login');
    }
    this.setState({ resetCode: code });
  }

  handleInputChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  }

  returnToLogin = () => {
    this.props.history.push('/login');
  }

  getLoginHost = () => {
    return window.location.protocol + '//' + (window.location.host.startsWith('localhost') ? 'localhost:8082' : 'sp.' + window.location.host);
  }

  validateAlphanumericPassword = (password) => {
    // if (!(/[a-z]+/.test(password) || /[A-Z]+/.test(password))) return "Password must contain at least one alphabet character";
    if (!(/[0-9]+/.test(password))) { return 'Password must contain at least one numeric character'; }
    return undefined;
  }

  handlePasswordChange = (e) => {
    e.preventDefault();

    const { password, password1, resetCode } = this.state;

    const passwordError = validatePassword(password, password1, true);
    if (passwordError) { return this.setState({ error: [passwordError] }); } else { this.setState({ error: [] }); }

    if (password && password1 && resetCode && password1 === password) {
      Authentication.resetPassword('', password, resetCode)
        .then((res) => {
          if (res.error) {
            const errMsg = (res.body && res.body.error) || 'Sorry, this password reset link is no longer valid, please request a new password reset.';
            this.setState({ error: errMsg });
          } else {
            this.setState({ reset: true });
          }
        })
        .catch(() => {
          this.setState({ error: 'A client error occurred' });
        });
    } else {
      if (password1 !== password) { return this.setState({ error: 'Passwords do not match' }); }
      this.setState({ error: 'please fill out all fields' });
    }
  }

  passwordChangeForm = () => {
    return (<Stack spacing={2} width='75%' className="flex-1 flex flex-col justify-center">
      <Typography sx={{ fontSize: '36px', fontWeight: 800, lineHeight: '49px' }}>Password Reset Request</Typography>

      <Box>
        <Typography variant="disabled" sx={{ fontSize: '15px', fontWeight: '400', lineHeight: '20px' }}>
        Enter a new password to reset the password on your account. We&apos;ll ask for this password whenever you login.
        </Typography>

        <section style={{ marginTop: '40px' }}>
          <form className='auth__login-form' onSubmit={this.handlePasswordChange}>
            {this.state.error.length > 0 && (
              <div style={{ color: 'red' }}>{this.state.error}</div>
            )}
            <Typography style={{ fontSize: '14px' }}>Password</Typography>
            <div className='nucleos-core'>
              <div id="creds-input" className='mt-2'>
                <TextInput
                  className="mb-4"
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={(_, evt) => this.handleInputChange(evt)}
                  disableHelperText
                  defaultShowPassword
                  autoComplete={'new-password'}
                  testId='change-password'
                />
              </div>
            </div>
            <Typography style={{ fontSize: '14px' }}>Confirm New Password</Typography>
            <div className='nucleos-core'>
              <div id="creds-input" className='mt-2'>
                <TextInput
                  className="mb-4"
                  testId='confirm-change-password'
                  name='password1'
                  onChange={(_, evt) => this.handleInputChange(evt)}
                  type="password"
                  label="Confirm Password"
                  placeholder={'Re-Enter Password'}
                  defaultShowPassword
                  disableHelperText
                />
              </div>
            </div>

            <Box sx={{ mt: 2, display: 'flex' }}>
              <Button type='submit' color='primary' variant='contained' sx={{ flex: 1 }}>
                Change Password
              </Button>
            </Box>
          </form>
        </section>
      </Box>
    </Stack>);
  }

  success = () => (
    <Stack spacing={3} className="flex-1 flex flex-col justify-center">
      <Typography sx={{ fontSize: '36px', fontWeight: 800, lineHeight: '49px', mb: 5 }}>Password Reset Request</Typography>
      <Typography variant="disabled" sx={{ fontSize: '15px', fontWeight: '400', lineHeight: '20px' }}>
          Your password has been successfully reset.
      </Typography>
      <section>
        <Button onClick={this.returnToLogin} color='primary' variant='contained'>
          Return to Login
        </Button>
        { window.nucleosConfig.gsuite &&
              <div>
                <hr style={{ marginTop: '25px' }} />
                <a href={this.getLoginHost() + '/login'}>
                  <img alt='Google Signin' src={googleLoginLogo} className='auth-header__google__logo' />
                </a>
              </div>
        }
      </section>
    </Stack>
  )

  render () {
    const { reset } = this.state;

    return (
      <Box display='flex' flexDirection='column' width='100%'>
        <Box sx={{ position: 'sticky', top: 0 }}>
          <Header hideSidebarIcon hideHeaderIcons/>
        </Box>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ maxWidth: '80%' }}>
            <Grid container>
              <Grid item xs={6}>
                <Box mt='100px'>
                  {reset === true && this.success()}
                  {reset === false && this.passwordChangeForm()}
                </Box>
              </Grid>
              <Grid xs={6} item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  id="login-art"
                  alt="Login art"
                  src={achieveDxpLoginArt}
                  className="w-full h-full ml-20"
                  style={{ maxWidth: '100%' }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    );
  }
}

export default ChangePassword;
