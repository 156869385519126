import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import CryptoJS from 'crypto-js';
import {
  Typography,
  SearchInput,
  Table,
  Button
} from '@nucleos/core-ui';
import { Modal, DialogContent, Switch } from '@mui/material';
import AddUpdateUserDetails from './AddUpdateUserDetails';
import Data from '../../Middleware/Data';
import { formatDateTime } from '../../Lib/util';
import NoResultFoundSVG from '../../Assets/graphics/table_no_data_found.svg';
import PasswordDots from '../../Assets/password-dots.svg';
import { Icon } from '../../Components/Shared/Icon';

const defaultSorting = {
  column: 'NONE',
  sortOrder: 'ASC'
};

const UserPasswords = ({ applicationName, applicationUid, applicationKey, userPasswordMetadata }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAllPasswords, setShowAllPasswords] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [columnSorting, setColumnSorting] = useState(defaultSorting);

  const { data, status, refetch } = useQuery(
    [
      'APPLICATION_USERS',
      applicationUid,
      searchTerm,
      columnSorting,
      page,
      recordsPerPage
    ],
    () =>
      Data.getApplicationUsers(
        applicationUid,
        searchTerm,
        columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
        columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
        page,
        recordsPerPage
      ),
    {
      select: (data) => {
        return {
          ...data,
          rows: (data.rows || []).map((user) => {
            return {
              ...user,
              password: CryptoJS.AES.decrypt(
                user.password,
                process.env.REACT_APP_CODE_ENCRYPTION_KEY
              ).toString(CryptoJS.enc.Utf8)
            };
          })
        };
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const columns = useMemo(() => {
    const columns = [
      {
        title: 'Name',
        enableSort: true,
        sortOrder:
          columnSorting.column === 'fullname' ? columnSorting.sortOrder : 'NONE',
        onSortChange: (sortOrder) =>
          setColumnSorting({ column: 'fullname', sortOrder }),
        render: (item) => (
          <Typography style={{ fontWeight: 600, color: '#333' }}>
            <div>{item.User.fullname}</div>
          </Typography>
        )
      },
      {
        title: `Nucleos ${window.nucleosConfig.usernameLabel || 'Username'}`,
        enableSort: true,
        sortOrder:
          columnSorting.column === 'username' ? columnSorting.sortOrder : 'NONE',
        onSortChange: (sortOrder) =>
          setColumnSorting({ column: 'username', sortOrder }),
        render: (item) => (
          <Typography>
            <div>{item.User.username}</div>
          </Typography>
        )
      },
      {
        title: 'App Username/Email',
        enableSort: true,
        sortOrder:
          columnSorting.column === 'appUsername'
            ? columnSorting.sortOrder
            : 'NONE',
        onSortChange: (sortOrder) =>
          setColumnSorting({ column: 'appUsername', sortOrder }),
        render: (item) => (
          <Typography>
            <div>{item.username}</div>
          </Typography>
        )
      },
      {
        title: 'App Password',
        enableSort: false,
        render: (item) => (
          <Typography>
            {showAllPasswords
              ? (
                <div>{item.password}</div>
              )
              : (
                <img src={PasswordDots} alt="hidden" />
              )}
          </Typography>
        )
      },
      {
        title: 'Updated On',
        enableSort: true,
        sortOrder:
          columnSorting.column === 'updatedAt' ? columnSorting.sortOrder : 'NONE',
        onSortChange: (sortOrder) =>
          setColumnSorting({ column: 'updatedAt', sortOrder }),
        render: (item) => (
          <Typography>
            <div>{formatDateTime(item.updatedAt)}</div>
          </Typography>
        )
      },
      {
        title: 'Action',
        enableSort: false,
        render: (item) => (
          <Icon
            icon="Edit"
            className="cursor-pointer"
            onClick={() => onEditDetails(item)}
          />
        )
      }
    ];
    if (userPasswordMetadata) {
      const conditionalColumns = [];
      userPasswordMetadata.forEach((obj) => {
        conditionalColumns.push({
          title: obj.label,
          enableSort: true,
          sortOrder:
            columnSorting.column === obj.key ? columnSorting.sortOrder : 'NONE',
          onSortChange: (sortOrder) =>
            setColumnSorting({ column: obj.key, sortOrder }),
          render: (item) => (
            <Typography>
              <div>{item[obj.key]}</div>
            </Typography>
          )
        });
      });
      columns.splice(columns.length - 2, 0, ...conditionalColumns);
    }
    return columns;
  }, [userPasswordMetadata, columnSorting, showAllPasswords]);

  const onAddUser = () => {
    const details = {
      uid: '',
      appUserId: '',
      name: '',
      appUsername: '',
      appPassword: ''
    };
    userPasswordMetadata.forEach((obj) => {
      details[obj.key] = '';
    });
    setUserDetails(details);
    setOpenModal('ADD');
  };

  const onEditDetails = (user) => {
    const details = {
      uid: user.User.uid,
      appUserId: user.id,
      name: user.User.fullname,
      appUsername: user.username,
      appPassword: user.password
    };
    userPasswordMetadata.forEach((obj) => {
      details[obj.key] = user[obj.key];
    });
    setUserDetails(details);
    setOpenModal('Edit');
  };

  return (
    <>
      <div className="nucleos-core">
        <div className="my-3 flex justify-between">
          <SearchInput
            placeholder="Search"
            style={{ width: '240px' }}
            onSearch={(search) => {
              setSearchTerm(search);
              setPage(1);
            }}
          />
          <div className="flex items-center">
            <Typography>Show all passwords</Typography>
            <Switch
              checked={showAllPasswords}
              onChange={(event) => setShowAllPasswords(event.target.checked)}
            />
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className="ml-3"
              style={{ width: 'auto', padding: '8px 12px', fontWeight: 400, fontSize: '0.875rem' }}
              onClick={onAddUser}
            >
              <div className="flex items-center justify-center">
                <Icon icon="Add" />
                Add User
              </div>
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />
              <Typography className="mb-2" variant="h3">
                Sorry! No results found.
              </Typography>
              {searchTerm && (
                <Typography className="mb-2">
                  Sorry, there are no results for this search.
                </Typography>
              )}
            </div>
          }
          loading={status !== 'success'}
          rowsData={data ? data.rows : []}
          pagination
          totalRecords={data ? data.count : 0}
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </div>
      <Modal
        open={!!openModal}
        handleClose={() => setOpenModal('')}
        className="nucleos-core"
      >
        <DialogContent className="h-full flex justify-center items-center">
          <AddUpdateUserDetails
            action={openModal}
            applicationUid={applicationUid}
            applicationName={applicationName}
            applicationKey={applicationKey}
            userPasswordMetadata={userPasswordMetadata}
            userDetails={userDetails}
            onClose={(reload) => {
              setOpenModal('');
              if (reload) {
                refetch();
              }
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default UserPasswords;
