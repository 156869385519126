import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Select } from '../../Components/Form';
import { UserSearch } from '../../Components/Widgets/UserSearch';
import Data from '../../Middleware/Data';
import enrollStudent from './helpers';

export const ApplicationEnroll = ({ appUid }) => {
  const [courses, setCourses] = useState(null);
  const [courseSelect, setCourseSelect] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const handleSelectUser = (user) => {
    setCurrentUser(user);
  };

  const handleCourseChange = (e) => {
    setCourseSelect(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, uid } = currentUser;

    const submittedData = {
      courseguid: courseSelect,
      firstname: firstName,
      lastname: lastName,
      inmateno: uid,
      enrolledAt: new Date(),
      appUid
    };

    await enrollStudent(submittedData);
  };

  const getCourses = async () => {
    const { data } = await Data.acciCourses();

    setCourses(data);
  };

  useEffect(() => {
    getCourses();
    return () => {
      setCourses(null);
    };
  }, []);

  const options = courses && courses.map(({ courseTitle, courseToken }) => {
    return { label: courseTitle, value: courseToken };
  });
  return (
    <div className='app-detail__info-panel'>
      {currentUser
        ? (
          <p>
            {currentUser.username} is selected
          </p>
        )
        : (
          <p>
          Enter name of user you want to enroll
          </p>
        )}
      {courses && (
        <UserSearch onSelectUser={handleSelectUser} />
      )}
      {currentUser && (
        <>
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', paddingTop: '1rem' }}>
              <Select
                name='courseEnroll'
                value={courseSelect && courseSelect}
                onChange={handleCourseChange}
                options={[{ value: '', label: '' }, ...options]}
                selectStyle={{ backgroundColor: 'rgb(248, 248, 248)' }}
              >
                {courses.map(({ courseTitle, id, courseToken }) => {
                  return (
                    <option value={courseToken} key={id}>
                      {courseTitle}
                    </option>
                  );
                })}
              </Select>
              <Button
                type='submit'
                style={{ marginLeft: '1rem' }}
                color='primary'
                variant='contained'
              >
                Enroll User
              </Button>
            </div>
          </form>
          {/*
          <Button
            onClick={handleClick}
            type='submit'
            style={{ marginLeft: '1rem' }}
            color='primary'
            variant='contained'
          >
        View enrollment
          </Button> */}
        </>
      )}
    </div>
  );
};
