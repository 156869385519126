import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DataStore from '../Stores/DataStore';

export const ClearUserOnPageLoad = () => {
  const { pathname } = useLocation();
  const { clearCurrentUser } = DataStore;
  useEffect(
    () => {
      clearCurrentUser();
    },
    [pathname, clearCurrentUser]
  );
  return null;
};
