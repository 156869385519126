import React, { useCallback, useEffect, useState } from 'react';

import { LoadingSpinner, Typography } from '@nucleos/core-ui';
import { useQuery } from 'react-query';

import './styles.scss';
import Data from '../../../Middleware/Data';
import socket from '../../../Lib/socket';
import LoadingGIF from '../../../Assets/loading-gif.gif';
import NoActiveUsersSvg from '../../../Assets/no-active-users.svg';

import UsersListDrawer from './UsersList';
import LiveActivityHeader from './LiveActivityHeader';
import Authentication from '../../../Stores/Authentication';
import { stringToBase64Url } from '../../../Lib/util';
import { useHistory } from 'react-router-dom';

const cardSize = {
  1: {
    style: { height: 40 + 'rem', width: 40 * 1.6 + 'rem' },
    maxWidth: 100 + 'rem'
  },
  2: {
    style: { height: 28 + 'rem', width: 28 * 1.6 + 'rem' },
    maxWidth: 100 + 'rem'
  },
  4: {
    style: { height: 22 + 'rem', width: 22 * 1.6 + 'rem' },
    maxWidth: 80 + 'rem'
  },
  8: {
    style: { height: 14 + 'rem', width: 14 * 1.6 + 'rem' },
    maxWidth: 100 + 'rem'
  }
};

const PAGE_CHANGE_INTERVAL = 1000 * 60;
const PAGE_SIZE = 16;

function base64UrlToString (base64Url) {
  // Step 1: Add padding if needed
  const padding = '='.repeat((4 - (base64Url.length % 4)) % 4);
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') + padding;

  // Step 2: Convert back to a string
  const decodedString = atob(base64);

  return decodedString;
}

export default function LiveActivity () {
  const history = useHistory();

  const filters = JSON.parse(
    base64UrlToString(
      new URLSearchParams(window.location.search).get('filters')
    ) || '{}'
  );
  const selectedUser =
    base64UrlToString(
      new URLSearchParams(window.location.search).get('selectedUser') || ''
    ) || null;

  const [page, setPage] = useState(1);

  const filterParams = {
    users: filters.learners,
    pods: filters.pods,
    facilityIds: filters.facilities,
    getLive: filters.liveLearners,
    page,
    limit: PAGE_SIZE
  };
  const liveUsersQuery = useQuery(
    ['LIVE_USERS', filterParams],
    () => Data.getLiveUsers(filterParams),
    {
      refetchOnMount: true,
      keepPreviousData: true,
      cacheTime: 0
    }
  );
  const [isUserListOpen, setIsUserListOpen] = useState(false);
  const [images, setImages] = useState({});
  const totalPages = liveUsersQuery.isSuccess
    ? Math.ceil(liveUsersQuery.data.allLiveLearners.count / PAGE_SIZE) || 1
    : null;

  // useEffect(() => {
  //   document
  //     .getElementsByClassName('app__content')[0]
  //     .classList.add('reset_app__content_start');
  //   document.getElementsByClassName('header')[0].classList.add('hidden_header');

  //   return () => {
  //     document
  //       .getElementsByClassName('app__content')[0]
  //       .classList.remove('reset_app__content_start');
  //     document
  //       .getElementsByClassName('header')[0]
  //       .classList.remove('hidden_header');
  //   };
  // }, []);

  useEffect(() => {
    const unloadCallback = () => {
      socket.emit('exitHawkAi', { learnerUid: selectedUser });
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [selectedUser]);

  useEffect(() => {
    socket.on('sequenceChangedForLiveUsers', () => liveUsersQuery.refetch());
    socket.on('liveImage', (data) => {
      setImages((images) => ({
        ...images,
        [data.learnerUid]: {
          ...(images[data.learnerUid] ? images[data.learnerUid] : {}),
          url: data.image,
          imageCollectedAt: Date.now(),
          isRestrictedPath: data.isRestrictedPath,
          serialNumber: data.serialNumber
        }
      }));
    });
    socket.on('singleLearnerLiveImage', (data) => {
      setImages((images) => ({
        ...images,
        [data.learnerUid]: {
          ...(images[data.learnerUid] ? images[data.learnerUid] : {}),
          fullSizeImage: data.image,
          fullSizeImageCollectedAt: Date.now(),
          isRestrictedPath: data.isRestrictedPath,
          serialNumber: data.serialNumber
        }
      }));
    });

    return () => {
      socket.off('sequenceChangedForLiveUsers');
      socket.off('liveImage');
    };
  }, []);

  useEffect(() => {
    if (!selectedUser) { return; }
    socket.emit('viewingSingleLearner', {
      learnerUid: selectedUser,
      adminUid: Authentication.uid
    });
  }, [selectedUser]);

  useEffect(() => {
    if (!totalPages) { return; }
    setPage((currentPage) => (totalPages <= currentPage ? 1 : currentPage));
    const intervalId = setInterval(showNextPage, PAGE_CHANGE_INTERVAL);
    return () => clearInterval(intervalId, showNextPage);
  }, [totalPages]);

  const showNextPage = useCallback(() => {
    if (!totalPages) { return; }
    setPage((currentPage) => {
      if (currentPage === totalPages) { return 1; }
      return currentPage + 1;
    });
  }, [totalPages]);

  const setSelectedUser = (uid) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (uid) {
      searchParams.set('selectedUser', stringToBase64Url(uid));
    } else {
      searchParams.delete('selectedUser');
    }

    history.replace('?' + searchParams.toString());
  };

  const onFullScreenLiveFeedClose = () => {
    socket.emit('exitViewingSingleLearner', {
      learnerUid: selectedUser,
      adminUid: Authentication.uid
    });
    setSelectedUser(null);
  };

  // useEffect(() => {
  //   // Clear the images which are being shown for more than 10 seconds.
  //   const intervalId = setInterval(clearOlderImages, 1000);
  //   return () => clearInterval(intervalId, clearOlderImages);
  // }, []);

  // const clearOlderImages = useCallback(() => {
  //   setImages((images) =>
  //     Object.entries(images)
  //       .filter(([, image]) => {
  //         return image.imageCollectedAt >= Date.now() - 10 * 1000;
  //       })
  //       .reduce(
  //         (acc, [learnerId, image]) => ({ ...acc, [learnerId]: image }),
  //         {}
  //       )
  //   );
  // }, [setImages]);

  useEffect(() => {
    // Function to calculate header height and set margin-top
    if (liveUsersQuery.isLoading) { return; }
    const calculateMarginTop = () => {
      const headerElement = document.querySelector('#live-activity-header');
      const contentElement = document.querySelector('#live-activity-content');
      const gridElement = document.querySelector('#live-activity-grid');
      if (!(headerElement && contentElement && gridElement)) { return; }
      contentElement.style.height = `calc(100vh - ${headerElement.offsetHeight}px)`;
      gridElement.style.height = `calc(100vh - ${headerElement.offsetHeight}px)`;
    };

    // Initial calculation
    calculateMarginTop();

    // Recalculate on window resize (if needed)
    window.addEventListener('resize', calculateMarginTop);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', calculateMarginTop);
  }, [liveUsersQuery.isLoading]);

  const liveUsers = liveUsersQuery.isSuccess
    ? liveUsersQuery.data.liveLearnerUsers.rows
    : [];
  const allLiveUsers = liveUsersQuery.isSuccess
    ? liveUsersQuery.data.allLiveLearners.rows
    : [];

  const selectedSize =
    cardSize[
      Object.keys(cardSize).find((length) => liveUsers.length <= length) || 8
    ];

  const NoLiveUsersFoundUI = (
    <div
      className="w-full h-full flex flex-col justify-center items-center"
      data-testid="live-activity-no-live-learners-found"
    >
      <img src={NoActiveUsersSvg} alt="No active users found" />
      <Typography variant="h3" color="white">
        No active users detected.
      </Typography>
      <Typography color="white" className="mt-2" style={{ opacity: 0.7 }}>
        Whenever they online, it will be displayed here automatically.
      </Typography>
    </div>
  );

  if (liveUsersQuery.isLoading) {
    return (
      <div
        className="nucleos-core"
        style={{ height: '100vh' }}
        data-testid="live-activity-live-learners-loading"
      >
        <div className="h-full flex justify-center items-center">
          <LoadingSpinner size={12} />
        </div>
      </div>
    );
  }

  const _selectedUser = liveUsers.find((user) => user.uid === selectedUser);

  const selectedUserData = images[selectedUser] || {};
  const isSelectedUserOnline = liveUsersQuery.isSuccess
    ? liveUsersQuery.data.allLiveLearners.rows.some(
      (learner) => learner.uid === selectedUser
    )
    : null;

  return (
    <div
      className="nucleos-core"
      style={{ backgroundColor: '#202124', overflow: 'hidden' }}
    >
      <LiveActivityHeader
        totalLearners={liveUsersQuery.data.allLiveLearners.count}
        totalPage={totalPages}
        page={page}
        setPage={setPage}
        onUsersListIconClick={() => setIsUserListOpen(true)}
        showCloseButton={selectedUser}
        headerName={
          _selectedUser
            ? `${_selectedUser.lastName} ${_selectedUser.firstName} (${_selectedUser.username})`
            : null
        }
        onClose={onFullScreenLiveFeedClose}
      />
      <UsersListDrawer
        isOpen={isUserListOpen}
        setIsOpen={setIsUserListOpen}
        totalLiveUsers={liveUsersQuery.data.allLiveLearners.count}
        liveUsers={allLiveUsers}
      />
      <div id="live-activity-content" className="relative">
        <div id="live-activity-grid" data-testid="live-activity-learners-grid">
          <div
            className="relative p-10 h-full flex flex-wrap justify-center items-center mx-auto overflow-y-auto content-start"
            style={{ maxWidth: selectedSize.maxWidth }}
          >
            {liveUsers.length === 0 ? NoLiveUsersFoundUI : null}
            {liveUsers.map((user, index) => (
              <div
                key={index}
                data-testid="live-activity-learner-card"
                data-userid={user.uid}
                className={
                  'relative flex items-center justify-center rounded overflow-hidden m-1'
                }
                style={{
                  ...selectedSize.style,
                  backgroundColor:
                    user.isLive &&
                    !(images[user.uid] && images[user.uid].isRestrictedPath)
                      ? '#FFF'
                      : '#CCC',
                  cursor: user.isLive ? 'pointer' : 'default'
                }}
                onClick={() => (user.isLive ? setSelectedUser(user.uid) : null)}
              >
                {user.isLive
                  ? (
                    images[user.uid] && images[user.uid].isRestrictedPath
                      ? (
                        <div className="flex items-center justify-center flex-col">
                          <Typography
                            variant="h3"
                            data-testid="live-activity-learner-restricted"
                            className="text-center"
                          >
                        Recording Paused
                          </Typography>
                          <Typography className="text-center">
                        As soon as the recording becomes available, it will
                        automatically resume.
                          </Typography>
                        </div>
                      )
                      : (
                        <img
                          className="absolute"
                          data-testid="live-activity-learner-image"
                          style={{ maxHeight: images[user.uid] ? '100%' : '60px' }}
                          src={
                            images[user.uid]
                              ? `data:image/jpeg;base64, ${images[user.uid].url}`
                              : LoadingGIF
                          }
                          alt={`${user.username}`}
                        />
                      )
                  )
                  : (
                    <Typography
                      variant="h3"
                      data-testid="live-activity-learner-offline"
                    >
                    User Offline
                    </Typography>
                  )}
                <div
                  className="absolute px-2 py-0.5 rounded-tl-md z-100 right-0 bottom-0"
                  style={{ backgroundColor: '#333333CC' }}
                >
                  <Typography
                    color="white"
                    data-testid={'live-activity-learner-name'}
                    data-userid={user.uid}
                  >
                    {`${user.lastName} ${user.firstName}`}{' '}
                    {images[user.uid] &&
                    images[user.uid].serialNumber &&
                    images[user.uid].serialNumber !== 'null'
                      ? `(#${images[user.uid].serialNumber})`
                      : ''}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*
         * This is to only show the overlay loading when query key is changed and data is being
         * fetched in background and the currunt data provided by query is previous data.
         * The isFetching flag cannot be used here as we are also refetching the data on live users sequence change.
         */}
        {liveUsersQuery.isPreviousData
          ? (
            <div
              className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center"
              style={{ background: '#202124' }}
              data-testid="live-activity-page-loading"
            >
              <div
                className="w-full flex justify-center items-center"
                style={{ color: 'white' }}
              >
                <LoadingSpinner size={12} />
              </div>
            </div>
          )
          : null}
        <div
          id="image_wrapper"
          className="absolute left-0 right-0 bg-white"
          style={{
            top: selectedUser ? '0vh' : '100vh',
            height: '100%',
            backgroundColor:
              (selectedUserData && selectedUserData.isRestrictedPath) ||
              isSelectedUserOnline === false
                ? '#CCC'
                : '#FFF'
          }}
          data-testid="live-activity-full-screen-view"
          data-userid={selectedUser}
        >
          <div className="h-full flex justify-center items-center">
            {(() => {
              const LoadingUI = (
                <img
                  src={LoadingGIF}
                  style={{ maxHeight: '60px' }}
                  alt="Live user"
                  data-testid="live-activity-full-screen-image"
                  className="rounded-md object-contain"
                />
              );
              if (
                !selectedUser ||
                !selectedUserData ||
                isSelectedUserOnline === null
                // (selectedUserData && isSelectedUserOnline && !selectedUserData.fullSizeImage)
              ) {
                return LoadingUI;
              }
              if (isSelectedUserOnline === false) {
                return (
                  <div className="flex items-center justify-center flex-col">
                    <Typography
                      variant="h3"
                      data-testid="live-activity-learner-restricted"
                      className="text-center"
                    >
                      User Offline
                    </Typography>
                  </div>
                );
              }
              if (selectedUserData.isRestrictedPath) {
                return (
                  <div className="flex items-center justify-center flex-col">
                    <Typography
                      variant="h3"
                      data-testid="live-activity-learner-restricted"
                      className="text-center"
                    >
                      Recording Paused
                    </Typography>
                    <Typography className="text-center">
                      As soon as the recording becomes available, it will
                      automatically resume.
                    </Typography>
                  </div>
                );
              }
              if (selectedUserData && selectedUserData.fullSizeImage) {
                return (
                  <img
                    src={`data:image/jpeg;base64, ${selectedUserData.fullSizeImage}`}
                    style={{ maxHeight: '95%' }}
                    alt="Live user"
                    data-testid="live-activity-full-screen-image"
                    className="rounded-md object-contain"
                  />
                );
              }
              return LoadingUI;
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
