import * as yup from 'yup';
import {
  validateSpecialCharacterInString,
  validateStringNotToStartWithSpecialCharacter
} from '../Form';

yup.addMethod(yup.string, 'noSpecialCharacter', function (errorMessage) {
  return this.test('no-special-character', errorMessage, function (value) {
    const { path, createError } = this;

    return validateSpecialCharacterInString(value)
      ? createError({ path, message: errorMessage })
      : true;
  });
});

yup.addMethod(yup.string, 'noSpecialCharacterAtStart', function (errorMessage) {
  return this.test(
    'no-special-character-at-start',
    errorMessage,
    function (value) {
      const { path, createError } = this;

      return validateStringNotToStartWithSpecialCharacter(value)
        ? createError({ path, message: errorMessage })
        : true;
    }
  );
});

export const getGroupFormSchema = ({ isAddUsersToGroupForm }) => {
  return yup.object().shape({
    name: yup
      .string()
      .label('Group Name')
      .noSpecialCharacter(
        'Field can only contain following special characters: dot(.) and hyphen(-)'
      )
      .noSpecialCharacterAtStart(
        'Group name cannot start with special character.'
      )
      .max(50)
      .required('Please enter group name.'),
    description: yup
      .string()
      .label('Group Description')
      .max(250)
      .optional('Please enter group description.'),
    groupType: yup
      .string()
      .label('Group Type')
      .required('Please select group type.'),
    roleId: yup
      .string()
      .label('Grievance Role')
      .optional('Please select grievance role.'),
    userIds: yup
      .array(yup.string())
      .label('Users')
      .when({
        is: () => isAddUsersToGroupForm,
        then: (schema) =>
          schema
            .required('Please select users to add.'),
        otherwise: (schema) => schema.notRequired()
      })
  });
};

export const getAddUsersToGroupFormSchema = () => {
  return yup.object().shape({
    groupId: yup
      .string()
      .label('Group')
      .required('Please select the group.'),
    userIds: yup
      .array(yup.string())
      .label('Users')
      .required('Please select users to add.')
  });
};
