
const url = 'http://localhost:8081';

const enrollStudent = async (data) => {
  const { courseguid, firstname, lastname, inmateno, appUid } = data;

  const bodyObj = {
    courseguid,
    firstname,
    lastname,
    inmateno,
    appUid
  };

  const res = await window.fetch(`${url}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyObj)
  });
  const resUrl = await res.json();

  return resUrl;
};

export default enrollStudent;
