import React from 'react';
import { LoadingSpinner } from '@nucleos/core-ui';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function ScrollBottomLoading ({ isFetching, fetchNextPage, dataCount, hasMore, children }) {
  return (
    <InfiniteScroll
      dataLength={dataCount}
      hasMore={hasMore}
      next={!isFetching && fetchNextPage}
      scrollableTarget="scrollableDiv"
      loader={
        <div className="nucleos-core">
          <div
            className={`flex justify-center h-32 my-4 ${isFetching ? '' : 'opacity-0'
            }`}
          >
            <LoadingSpinner size={12} />
          </div>
        </div>
      }
    >
      {children}
    </InfiniteScroll>
  );
}
