import React, { useEffect, useState } from 'react';
import { SearchDropdown } from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import Data from '../../../Middleware/Data';
import { usePaginatedQuery } from '../../../hooks/usePaginatedQuery';

export default function UniversalUserSearch ({
  label,
  Icon,
  placeholder,
  userId,
  onUserSelect: _onUserSelect,
  selectedUser: _selectedUser,
  learnerOnly
}) {
  const selectedUserState = useState(null);

  const [selectedUser, setSelectedUser] =
    _selectedUser !== undefined
      ? [_selectedUser, _onUserSelect]
      : selectedUserState;
  const [searchValue, setSearchValue] = useState('');
  const selectedUserQuery = useQuery(
    ['USERS_SEARCH_SELECTED', userId],
    () => Data.getUser(userId),
    {
      placeholderData: {},
      enabled: !!userId,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
  const usersSearchQuery = usePaginatedQuery(
    ['USERS_SEARCH', searchValue],
    ({ pageParam = {} }) => {
      return Data.getUsers({
        query: searchValue,
        page: pageParam.page ? pageParam.page - 1 : 0,
        roleType: learnerOnly ? 'LEARNER' : undefined,
        limit: 10
      });
    },
    {
      listKeyName: 'users'
    }
  );

  useEffect(() => {
    if (
      userId &&
      selectedUserQuery.status === 'success' &&
      selectedUserQuery.data &&
      selectedUserQuery.data.uid
    ) {
      setSelectedUser({
        label: `${selectedUserQuery.data.firstName} ${selectedUserQuery.data.lastName} (${selectedUserQuery.data.username})`,
        value: selectedUserQuery.data.uid
      });
    }
  }, [userId, selectedUserQuery.data]);

  const onUserSelect = (user) => {
    setSelectedUser(user);
    setSearchValue('');

    const originalUser = usersSearchQuery.data.pages.find(
      (qUser) => qUser.uid === user.value
    );

    if (_onUserSelect) { _onUserSelect(originalUser); }
  };

  const selectedUserOption =
    selectedUserQuery.status === 'success' &&
    selectedUserQuery.data &&
    selectedUserQuery.data.uid
      ? {
        label: `${selectedUserQuery.data.firstName} ${selectedUserQuery.data.lastName} (${selectedUserQuery.data.username})`,
        value: selectedUserQuery.data.uid
      }
      : null;

  const fetctNextPaginatedResults = () => {
    usersSearchQuery.fetchNextPage();
  };

  return (
    <div>
      <SearchDropdown
        label={label}
        Icon={Icon}
        selectedOption={selectedUser ? selectedUser.value : null}
        isLoading={usersSearchQuery.isPlaceholderData}
        isMoreRecordsLoading={usersSearchQuery.isFetching}
        placeholder={placeholder || 'Search user'}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        options={[
          ...usersSearchQuery.data.pages.map((user) => ({
            label: `${user.firstName} ${user.lastName} (${user.username})`,
            value: user.uid
          })),
          ...(selectedUserOption && !searchValue ? [selectedUserOption] : [])
        ]}
        onItemSelect={onUserSelect}
        onScrollReachBottom={fetctNextPaginatedResults}
      />
    </div>
  );
}
