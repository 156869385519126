import { Button, Typography } from '@nucleos/core-ui';
import React, { useState } from 'react';
import { Icon } from '../../../../Components/Shared/Icon';

export default function SelectGrievanceType ({ previousStep, nextStep, grievance }) {
  const [selectedType, setSelectedType] = useState(grievance.requestType || 'GRIEVANCE');

  const handleNext = () => {
    nextStep(selectedType);
  };

  return (
    <div className="nucleos-core">
      <div className="mt-6 grid gap-12 grid-cols-3 mobile-grid-col-2">
        {[
          {
            title: 'Grievance',
            value: 'GRIEVANCE',
            desccription:
              "Use this option to formally address any concerns related to treatment or conditions within the institution. It's your avenue to ensure concerns about your incarceration experience are officially noted."
          },
          {
            title: 'Action Request',
            value: 'ACTION_REQUEST',
            desccription:
              'Choose this if you need specific services or decisions from the institution. From medical appointments to programming access, streamline your requests using this feature.'
          }
        ].map((type) => (
          <div
            className={
              'relative p-6 border-2 rounded-xl bg-background-light hover:border-primary-light ' +
              (selectedType === type.value ? 'border-primary-light' : '')
            }
            onClick={() => {
              setSelectedType(type.value);
            }}
          >
            <div>
              <div className="flex flex-col items-center py-4">
                <Typography variant="h4" style={{ textAlign: 'center' }}>{type.title}</Typography>
                <Typography variant="body2" style={{ textAlign: 'center' }}>{type.desccription}</Typography>
              </div>
              <div
                className={
                  (selectedType === type.value ? '' : 'hidden') +
                ' absolute right-2 top-2'
                }
              >
                <Icon icon="CheckCircle" className="text-primary-dark h-8 w-8" />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6 pb-6 flex justify-between">
        <Button variant="outlined" className="bg-background-light" onClick={previousStep}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next Step
        </Button>
      </div>
    </div>
  );
}
