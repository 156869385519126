import { ACTIVE_ROLES } from '../Lib/CONSTANTS';
import {
  HOME_PATH,
  COURSE_CONTENT_LIBRARY_PATH,
  LEARNING_RECORDS_PATH,
  INVESTIGATIVE_PATH,
  LIVE_ACTIVITY_PATH,
  ACTIVITY_LOOKUP_DETAILS_PATH,
  GROUP_LEARNING_RECORDS_PATH,
  USER_PATH,
  GROUP_PATH,
  ROLES_SETTINGS_PATH,
  LEARNER_SESSION_PATH,
  LEARNER_SESSION_LOOKUP_PATH,
  FLAGGED_ACTIVITY_PATH,
  ACCOUNT_SETTINGS_PATH,
  MESSENGER_ADMIN_PATH,
  MESSENGER_USER_PATH,
  FLAGGED_MESSAGES_LIST_PATH,
  MESSAGE_ALERTS_PATH,
  ALL_MESSAGES_LIST_PATH,
  MESSAGE_DETAILS_PATH,
  MESSAGE_USER_PATH,
  LIVE_ACTIVITY_MODAL_PATH,
  MESSENGER_ADMIN_USERS_PATHS,
  USER_ANG_GROUP_PATH,
  MESSENGER,
  MESSAGE_USER_DETAILS_PATH,
  SETTINGS_USER_PATH,
  SETTINGS_GROUP_PATH,
  LEARNER_RECORD_DETAILS_PATH,
  COURSE_CONTENT_APPLICATION_PATH,
  LEARNER_USER_PATH,
  NON_LEARNER_USER_PATH
} from './paths';

const { SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER, INVESTIGATOR, MESSENGER: MESSENGER_ROLE, LEARNER } = ACTIVE_ROLES;

export const rolesArr = {
  [HOME_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER, LEARNER, INVESTIGATOR, MESSENGER_ROLE],
  [COURSE_CONTENT_LIBRARY_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER, LEARNER],
  [COURSE_CONTENT_APPLICATION_PATH[0]]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER, LEARNER],
  [COURSE_CONTENT_APPLICATION_PATH[1]]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER, LEARNER],
  [LEARNING_RECORDS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, LEARNER],
  [LEARNER_RECORD_DETAILS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, LEARNER],
  [INVESTIGATIVE_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [LIVE_ACTIVITY_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [ACTIVITY_LOOKUP_DETAILS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [LIVE_ACTIVITY_MODAL_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [GROUP_LEARNING_RECORDS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [SETTINGS_USER_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [SETTINGS_GROUP_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [USER_ANG_GROUP_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [USER_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [LEARNER_USER_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [NON_LEARNER_USER_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [GROUP_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [ROLES_SETTINGS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [LEARNER_SESSION_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [LEARNER_SESSION_LOOKUP_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [FLAGGED_ACTIVITY_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER],
  [ACCOUNT_SETTINGS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, EXTERNAL_PARTNER, INVESTIGATOR, MESSENGER_ROLE],
  [MESSENGER_ADMIN_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR, MESSENGER_ROLE],
  [MESSENGER]: [LEARNER],
  [MESSENGER_USER_PATH]: [LEARNER],
  [MESSENGER_ADMIN_USERS_PATHS]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [MESSAGE_USER_PATH]: [SUPER_ADMIN, STAFF_ADMIN, MESSENGER_ROLE, INVESTIGATOR],
  [MESSAGE_USER_DETAILS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, MESSENGER_ROLE, INVESTIGATOR],
  [FLAGGED_MESSAGES_LIST_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [MESSAGE_ALERTS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [ALL_MESSAGES_LIST_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR],
  [MESSAGE_DETAILS_PATH]: [SUPER_ADMIN, STAFF_ADMIN, INVESTIGATOR]
};
