import React, { useState } from 'react';
import { Button, TextInput, Typography } from '@nucleos/core-ui';
import { GRIEVANCE_RESPONSE_STATUS } from '../../Lib/CONSTANTS';
import { Icon } from '../Shared/Icon';

export const LevelDisplayName = {
  1: 'Staff',
  2: 'Supervisor',
  3: 'Commander'
};

export const LearnerAcceptOrRejectUI = ({
  lernerResponseStatus,
  setLernerResponseStatus,
  grievance,
  submitLearnersResponse,
  isSubmitLoading
}) => {
  const [learnerSignature, setLearnerSignature] = useState('');
  const [learnerSignatureError, setLearnerSignatureError] = useState('');

  const onSubmit = () => {
    if (learnerSignature.toLowerCase() !== grievance.signature.toLowerCase()) {
      return setLearnerSignatureError('Please enter correct signature');
    } else {
      setLearnerSignatureError('');
    }
    submitLearnersResponse();
  };

  const currentAppeal = LevelDisplayName[grievance.level];
  const nextAppeal = LevelDisplayName[grievance.level + 1];

  const responseMessage =
    grievance.level === 3
      ? 'Please select whether you are satisfied or dissatisfied with the response.'
      : `Please select whether you are satisfied with the response, or are
  dissatisfied and would like to appeal ${currentAppeal} Response to a ${nextAppeal}.`;

  return (
    <div className="mt-6">
      <Typography variant="h5">Do you accept this response?</Typography>
      <Typography>{responseMessage}</Typography>
      <div className="flex mt-4">
        <Button
          className="mr-4"
          style={{
            background:
              lernerResponseStatus === GRIEVANCE_RESPONSE_STATUS.SATISFIED
                ? '#469C30'
                : undefined,
            color:
              lernerResponseStatus === GRIEVANCE_RESPONSE_STATUS.SATISFIED
                ? 'white'
                : undefined
          }}
          variant={
            lernerResponseStatus === GRIEVANCE_RESPONSE_STATUS.SATISFIED
              ? 'contained'
              : 'outlined'
          }
          onClick={() =>
            setLernerResponseStatus(GRIEVANCE_RESPONSE_STATUS.SATISFIED)
          }
        >
          <Icon icon="ThumbUp" style={{ height: 20, marginRight: 6 }} />
          Yes, Satisfied
        </Button>
        <Button
          style={{
            background:
              lernerResponseStatus === GRIEVANCE_RESPONSE_STATUS.DISSATISFIED
                ? '#d32f2f'
                : undefined,
            color:
              lernerResponseStatus === GRIEVANCE_RESPONSE_STATUS.DISSATISFIED
                ? 'white'
                : undefined
          }}
          variant={
            lernerResponseStatus === GRIEVANCE_RESPONSE_STATUS.DISSATISFIED
              ? 'contained'
              : 'outlined'
          }
          onClick={() =>
            setLernerResponseStatus(GRIEVANCE_RESPONSE_STATUS.DISSATISFIED)
          }
        >
          <Icon icon="ThumbDown" style={{ height: 20, marginRight: 6 }} />
          {grievance.level === 3 || grievance.requestType === 'ACTION_REQUEST'
            ? 'No, Dissatisfied'
            : `No, Appeal to ${nextAppeal}`}
        </Button>
      </div>
      <EnterSignatureUI
        grievance={grievance}
        learnerSignature={learnerSignature}
        learnerSignatureError={learnerSignatureError}
        setLearnerSignature={setLearnerSignature}
      />
      <Button
        className="mt-4"
        color="primary"
        variant="contained"
        disabled={!lernerResponseStatus}
        loading={isSubmitLoading}
        onClick={() => onSubmit()}
      >
        Submit
      </Button>
    </div>
  );
};

const EnterSignatureUI = ({
  grievance,
  learnerSignatureError,
  learnerSignature,
  setLearnerSignature
}) => {
  return (
    <div>
      <div className="flex my-4">
        <Typography variant="h6" className="mr-2">
          <Typography variant="h5">Digital Signature</Typography> - Please type
          your full name to sign this request: *
        </Typography>
      </div>
      <div className="w-96">
        <TextInput
          disableHelperText
          className="py-3"
          placeholder="Enter your full name"
          value={learnerSignature}
          onChange={(value, e) => {
            e.stopPropagation();
            e.preventDefault();
            setLearnerSignature(value);
          }}
        />
        {learnerSignatureError && (
          <Typography color="error">{learnerSignatureError}</Typography>
        )}
      </div>
      <Typography className="mt-2">
        Name: {`${grievance.User.firstName} ${grievance.User.lastName}`}
      </Typography>
    </div>
  );
};
