import React from 'react';
import { Icon } from '../../../Components/Shared/Icon';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

const FacilityPodAvailabilityConflictDialog = ({ onClose, onCancel, onOk, content }) => {
  return (
    <Dialog
      open
      maxWidth='sm'
      onClose={onClose}
    >
      <DialogContent sx={{ p: 6 }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default
          })}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Icon icon="Cancel" sx={(theme) => ({ color: theme.palette.error.main, fontSize: 70 })} />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography variant='h5'>Are you sure?</Typography>

              <Typography variant='body2'>{content}</Typography>

              <Typography variant='body2'>Are you sure you want to proceed with this change?</Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <Button
                color='disabled'
                variant='outlined'
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  onOk();
                  onClose();
                }}
              >
                Yes, Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FacilityPodAvailabilityConflictDialog;
