import React, { useState } from 'react';
import GrievanceReport from './GrievanceReport';
import DataStore from '../../../../../Stores/DataStore';
import GrievanceFilterReport from './GrievanceReportFilter';
import LoadingComponent from '../../../../../Components/Widgets/LoadingComponent';
import { Box } from '@mui/material';
import HeaderSubPanel from '../../../../../Components/HeaderSubPanel';
import { GRIEVANCE_PATH } from '../../../../../constants/paths';

const breadcrumbLinks = [
  { href: GRIEVANCE_PATH, label: 'Grievances and Forms' },
  { href: '#', label: 'Grievance Request Report' }
];

const Reports = () => {
  const [allReports, setAllReports] = useState({});
  const [seperateByFacility, setSeperateByFacility] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isExportInProcess, setIsExportInProcess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getReports = async (filters) => {
    const response = await DataStore.getGrievanceReport(filters);
    setAllReports(response);
    setIsLoading(false);
  };

  const updateHandler = (filters) => {
    setIsLoading(true);
    getReports(filters);
    setSeperateByFacility(filters.seperateByFacility);
    setEndDate(filters.endDate);
    setStartDate(filters.startDate);
  };

  const exportBtnHandler = (exportType) => {
    setIsExportInProcess(true);
    DataStore.downloadGrievanceReport({ responseType: exportType, startDate, endDate, seperateByFacility }).then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        if (exportType == 'xls') { a.download = 'GrievanceReport.xlsx'; }

        if (exportType == 'pdf') { a.download = 'GrievanceReport.pdf'; }

        if (exportType == 'csv') { a.download = 'GrievanceReport.zip'; }

        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsExportInProcess(false);
      });
  };
  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title="Grievance Request Report" links={breadcrumbLinks} />
      <div>
        <div>
          <GrievanceFilterReport updateHandler={updateHandler} />
        </div>
        <div>
          { isLoading
            ? <LoadingComponent small="true" />
            : <GrievanceReport
              data={allReports}
              isSeperateByFacility={seperateByFacility}
              exportBtnHandler={exportBtnHandler}
              isExportInProcess={isExportInProcess}
            /> }
        </div>
      </div>
    </Box>
  );
};

export default Reports;
