import jwtDecode from 'jwt-decode';
import { observable, action, makeObservable } from 'mobx';
import { ROLES } from '../Lib/CONSTANTS';
import { Authentication } from '../Middleware/NucleosApi';
import socket from '../Lib/socket';

export const SESSION_STORAGE_KEYS = {
  username: 'nucleos__username',
  approvalStatus: 'nucleos__approvalStatus',
  uid: 'nucleos__uid',
  role: 'nucleos__user-role',
  fullRole: 'nucleos__user-full-role',
  lastAccessed: 'nucleos__last-login',
  firstName: 'nucleos__firstName',
  lastName: 'nucleos__lastName',
  messengerConsent: 'nucleos__messengerConsent',
  messengerActive: 'nucleos__messengerActive',
  consentStatus: 'nucleos__consentStatus'
};

// const existingToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.token)
const existingUsername = localStorage.getItem(SESSION_STORAGE_KEYS.username);
const existingFirstName = localStorage.getItem(SESSION_STORAGE_KEYS.firstName);
const existingLastName = localStorage.getItem(SESSION_STORAGE_KEYS.lastName);
const existingUid = localStorage.getItem(SESSION_STORAGE_KEYS.uid);
const existingRole = localStorage.getItem(SESSION_STORAGE_KEYS.role);
const existingFullRole = localStorage.getItem(SESSION_STORAGE_KEYS.fullRole);
const existingLastAccessed = localStorage.getItem(SESSION_STORAGE_KEYS.lastAccessed);
const activeCookie = document.cookie.split('active=')[1] || '';
const isActive = activeCookie.split(';')[0] === 'true';
const messengerConsent = localStorage.getItem(SESSION_STORAGE_KEYS.messengerConsent) === 'true';
const messengerActive = sessionStorage.getItem(SESSION_STORAGE_KEYS.messengerActive) === 'true';
const approvalStatus = sessionStorage.getItem(SESSION_STORAGE_KEYS.approvalStatus);
const consentStatus = sessionStorage.getItem(SESSION_STORAGE_KEYS.consentStatus);

class AuthenticationStore {
  // @observable isAuthenticated = existingToken && existingUsername;
  @observable isAuthenticated = isActive;

  @observable isValidUser = false;

  @observable authenticationError = null;

  @observable username = existingUsername || null;

  @observable uid = existingUid || null;

  @observable userRole = existingRole || null;

  @observable fullUserRole = existingFullRole || null;

  @observable isStaffUser = existingFullRole && existingFullRole !== ROLES.STUDENT;

  @observable lastAccessed = existingLastAccessed || null;

  @observable approvalStatus = approvalStatus || 'unapproved'

  @observable messengerActive = messengerActive || false;

  @observable firstName = existingFirstName || null;

  @observable lastName = existingLastName || null;

  @observable messengerConsent = messengerConsent || false;

  @observable consentStatus = consentStatus;

  @observable extensionInfo = null;

  constructor () {
    makeObservable(this);

    this.baseURL = process.env.REACT_APP_PUBLIC_URL;
  }

  parseToken = async (data) => {
    if (!data) {
      this.authenticationError = 'Authentication Failure (ErrCode: Unknown)';
      return;
    }
    if (data.passwordReset && data.resetCode) {
      window.location.assign(`/change-password/${data.resetCode}`);
    } else if (data.success && !data.token) {
      this.setUserValidity(true);
    } else if (data.token) {
      this.isAuthenticated = true;
      // TokenStore.setToken(data.token)
      const tokenObj = jwtDecode(data.token);
      this.uid = tokenObj.uid || tokenObj.id;
      // this.userRole = tokenObj.roles[0];
      this.fullUserRole = tokenObj.role.name;
      this.userRole = tokenObj.role.name.split('.')[0];
      this.fullRole = tokenObj.role.name;
      this.username = tokenObj.username;
      this.firstName = tokenObj.firstName;
      this.lastName = tokenObj.lastName;
      this.lastAccessed = tokenObj.date;
      this.approvalStatus = tokenObj.userApprovalStatus;
      this.messengerActive = tokenObj.messengerActive;
      localStorage.setItem(SESSION_STORAGE_KEYS.lastAccessed, tokenObj.date);

      localStorage.setItem(SESSION_STORAGE_KEYS.role, this.userRole);
      localStorage.setItem(SESSION_STORAGE_KEYS.fullRole, this.fullUserRole);
      this.isStaffUser = this.fullRole && this.fullRole !== ROLES.STUDENT;

      localStorage.setItem(SESSION_STORAGE_KEYS.username, tokenObj.username);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.approvalStatus, tokenObj.userApprovalStatus);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.messengerActive, tokenObj.messengerActive);
      localStorage.setItem(SESSION_STORAGE_KEYS.uid, this.uid);
      localStorage.setItem(SESSION_STORAGE_KEYS.firstName, tokenObj.firstName);
      localStorage.setItem(SESSION_STORAGE_KEYS.lastName, tokenObj.lastName);
      localStorage.setItem(SESSION_STORAGE_KEYS.messengerConsent, tokenObj.messengerConsent);
      localStorage.setItem(SESSION_STORAGE_KEYS.consentStatus, tokenObj.consent || 'false');
      socket.connect();

      if (this.userRole.includes('forms')) {
        window.history.replaceState('', '', '/forms/admin');
      } else {
        const searchParams = new URLSearchParams(window.location.search);
        const redirectTo = searchParams.get('redirectTo');
        window.history.replaceState('', '', redirectTo || '/home');
      }
    } else if (data.error) {
      const msg = await data.errorMessage;
      this.authenticationError = msg ? msg.message || msg.error : 'Unknown Error Occured';
      this.username = null;
      this.token = null;
    }
  };

  @action attemptLogin (username, password, image = null, deviceInfo = null) {
    const requestData = {
      username: username.toLowerCase(),
      password,
      image,
      siteName: window.nucleosConfig.SITE,
      deviceInfo,
      isTablet: !!(window.nucleosDeviceInfo && window.nucleosDeviceInfo.serialNumber && window.nucleosDeviceInfo.deviceId),
      serialNumber: window.nucleosDeviceInfo && window.nucleosDeviceInfo.serialNumber ? window.nucleosDeviceInfo.serialNumber : null,
      macAddress: window.nucleosDeviceInfo && window.nucleosDeviceInfo.macAddress ? window.nucleosDeviceInfo.macAddress : null
    };
    if (image && requestData.deviceInfo) {
      delete requestData.deviceInfo.serialNumber;

      delete requestData.deviceInfo.macAddress;
    }
    return Authentication.attemptLogin(requestData).then((data) => {
      action(this.parseToken)(data);
      return data;
    });
  }

  @action openidLogin (code) {
    return Authentication.openIDLogin(code).then(action(this.parseToken));
  }

  @action requestPasswordReset (email) {
    return Authentication.requestPasswordReset(email);
  }

  @action resetPassword (username, password, resetCode) {
    return Authentication.resetPassword(username, password, resetCode);
  }

  @action logOut () {
    // this.isAuthenticated = false;
    this.setUserValidity(false);

    return Authentication.openIDLogout({
      userUid: this.uid,
      isTablet: !!(window.nucleosDeviceInfo && window.nucleosDeviceInfo.serialNumber && window.nucleosDeviceInfo.deviceId),
      serialNumber: window.nucleosDeviceInfo && window.nucleosDeviceInfo.serialNumber ? window.nucleosDeviceInfo.serialNumber : null
    });
  }

  @action
  setConsensus = consensus => {
    this.messengerConsent = consensus;
    localStorage.setItem(SESSION_STORAGE_KEYS.messengerConsent, consensus);
  }

  @action
  setInitialConsent = consent => {
    this.consentStatus = consent;
    localStorage.setItem(SESSION_STORAGE_KEYS.consentStatus, consent);
  }

  @action
  setExtensionInfo = extInfo => {
    this.extensionInfo = extInfo;
  }

  @action setUserValidity = valid => {
    this.isValidUser = valid;
  }

  @action setIsAuthenticated = (isAuthenticated) => {
    this.isAuthenticated = isAuthenticated;
  }
}

const token = AuthenticationStore.token;

export { token };
export default new AuthenticationStore();
