import { QueryKeys } from '../Lib/query-keys';
import Data from '../Middleware/Data';
import { usePaginatedQuery } from './usePaginatedQuery';
import AuthenticationStore from '../Stores/Authentication';

export const useNotificationsQuery = ({ showUnread = false, category = '', search = '' } = {}) => {
  return usePaginatedQuery(
    QueryKeys.Notifications.listing({
      showUnread,
      category,
      search
    }),
    ({ pageParam = { page: 1 } }) => Data.getNotifications({
      page: pageParam.page, category: category, isRead: showUnread ? false : undefined, searchTerm: search
    }),
    {
      listKeyName: 'notifications',
      enabled: Boolean(AuthenticationStore.isAuthenticated && AuthenticationStore.uid),
      refetchOnMount: true
    }
  );
};
