import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(
    () => {
      window.scrollTo(0, 0);
    },
    [pathname]
  );
  return null;
};

/* Putting this here in case it turns out later
  that scrolling to the top on every route change
  isn't appropriate. */

// export const ScrollToTopOnMount = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])
//   return null
// }
