import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Typography,
  MultiSelectDropDown
} from '@nucleos/core-ui';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import Data from '../../Middleware/Data';
import toast from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { getAddUsersToGroupFormSchema } from './validation-schema';
import { Icon } from '../Shared/Icon';
import { Box } from '@mui/material';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';

export default function AddUsersToGroupForm ({
  isModalRenderedForm,
  groupData,
  preDeterminedGroupType,
  selectedUsers,
  disableForm,
  onCloseClick,
  onGroupCreateOrUpdate
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const visibleFields = {
    groupId: true,
    userIds: true
  };

  const enabledFields = {
    groupId: true,
    userIds: true
  };

  const usersOptions = useMemo(() => {
    return selectedUsers.map((user) => ({
      title: `${user.firstName} ${user.lastName}`,
      value: user.uid
    }));
  }, [selectedUsers]);

  const groupsListQuery = usePaginatedQuery(
    [
      'GROUPS_LIST',
      searchTerm,
      preDeterminedGroupType
    ],
    ({ pageParam = { limit: 10, page: 1 } }) => Data.getGroups(
      false,
      searchTerm,
      false,
      { groupType: [preDeterminedGroupType] },
      pageParam.page,
      pageParam.limit
    ), {
      listKeyName: 'rows'
    }
  );

  const groupOptions = useMemo(() => {
    return groupsListQuery.data.pages.map((group) => ({
      title: group.name,
      value: group.id
    }));
  }, [groupsListQuery.data.pages]);

  const groupForm = useForm({
    resolver: yupResolver(getAddUsersToGroupFormSchema()),
    defaultValues: {
      groupId: '',
      userIds: []
    },
    mode: 'onChange'
  });

  const initiateFormToOriginalState = useCallback(() => {
    groupForm.reset({
      userIds: selectedUsers ? groupData.userIds : ''
    });
  }, [groupData, groupForm, selectedUsers]);

  useEffect(() => {
    if (groupData) {
      initiateFormToOriginalState();
    }
  }, [groupData, initiateFormToOriginalState]);

  const addUsersToGroupMutation = useMutation(({ groupId, userIds }) => Data.addUserToGroup(groupId, userIds).then(GenericErrorDetectorForMutations));

  const addUsersToGroup = useCallback((data) => {
    addUsersToGroupMutation.mutate(data, {
      onSuccess: () => {
        toast.success('Successfully added user(s) to the group.');

        onGroupCreateOrUpdate();
      },
      onError: (error) => toast.error(error.message || 'Failed to add user(s) to the group.')
    });
  }, [addUsersToGroupMutation, onGroupCreateOrUpdate]);

  return (
    <div
      className="nucleos-core"
      data-test-id='add-users-to-group-form'
    >
      <Box sx={{ mb: isModalRenderedForm ? 16 : 2 }}>
        {
          visibleFields.groupId && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>
                Group <span className="text-red-600">*</span>
                </Typography>
                <Controller
                  name="groupId"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <MultiSelectDropDown
                      fullWidth
                      dropdownId='add-users-to-group-form-group-select-dropdown'
                      testId='group-type'
                      label='Group'
                      name='groupId'
                      value={groupOptions.filter((op) => op.value === field.value)}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      onChange={([item]) => field.onChange(item.value)}
                      disabled={disableForm || !enabledFields.groupId}
                      options={groupOptions}
                      disableMultiSelect
                      searchable
                      isSearchLoading={!groupsListQuery.isFetched && groupsListQuery.isFetching}
                      onSearchChange={(search) => {
                        setSearchTerm(search);
                      }}
                      fetchNextOptionsPage={groupsListQuery.fetchNextPage}
                      isNextPageLoading={groupsListQuery.isFetching}
                      hasNextOptions={groupsListQuery.hasNextPage}
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }

        {
          visibleFields.userIds && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>
              Users <span className="text-red-600">*</span>
                </Typography>
                <Controller
                  name="userIds"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <MultiSelectDropDown
                      {...field}
                      dropdownId="group-from-users-select"
                      onChange={(values) =>
                        field.onChange(values.map((v) => v.value))
                      }
                      testId="group-users"
                      value={field.value ? usersOptions.filter(user => field.value.includes(user.value)) : []}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      disabled={disableForm || !enabledFields.userIds}
                      label='Select Users'
                      options={usersOptions}
                      fullWidth
                      getLabel={(selectedValues) => (
                        <div
                          className="flex flex-wrap gap-1"
                          data-testid="add-users-to-group-form-selected-users-list"
                        >
                          {selectedValues.map((user) => (
                            <div
                              key={user}
                              className="px-2 py-1 mr-2 flex items-center rounded-full bg-lightblue-light"
                              data-testid="add-users-to-group-form-selected-user"
                            >
                              {
                                (usersOptions.find((f) => f.value === user) || {}).title
                              }
                              <Icon
                                icon="Close"
                                data-testid="add-users-to-group-form-selected-user-remove-btn"
                                data-userid={user}
                                style={{ fontSize: 16 }}
                                className="ml-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  field.onChange(
                                    field.value.filter((v) => v !== user)
                                  );
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }
      </Box>

      <div className='flex justify-between'>
        <Button
          variant="outlined"
          onClick={onCloseClick}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={groupForm.handleSubmit(addUsersToGroup, console.error)}
          disabled={!groupForm.formState.isDirty}
          loading={addUsersToGroupMutation.isLoading}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
