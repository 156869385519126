import React from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@nucleos/core-ui';
import moment from 'moment';
import Data from '../../../../Middleware/Data';
import { useGroupsLearningRecordContext } from '../../GroupLearningRecordContext';
import { QueryKeys } from '../../../../Lib/query-keys';
import { LoadingAndErrorHandler } from '../../../../Components/Shared/LoadingErrorHandler';
import { useAppContext } from '../../../../Contexts/AppContext';

const GroupAbout = () => {
  const appContext = useAppContext();

  const institutionQuery = useQuery(
    QueryKeys.Institutions.listing(),
    () => Data.getInstitution(),
    { placeholderData: { INSTITUTION: '' } }
  );

  const GLRContext = useGroupsLearningRecordContext();
  const group = GLRContext.group;

  return (
    <div
      className="my-4 flex p-4 flex-col w-full rounded-xl"
      style={{ backgroundColor: '#E9F0FF' }}
    >
      <LoadingAndErrorHandler
        isError={institutionQuery.isError}
        isLoading={
          institutionQuery.isFetching && institutionQuery.isPlaceholderData
        }
        isSuccess={institutionQuery.isSuccess}
      >
        <div className="flex justify-between mb-4">
          <div>
            {group && <Typography variant="h6">{group.name}</Typography>}
            <Typography>
              {moment(GLRContext.timePeriod.split('|')[1]).format(
                'D MMMM YYYY'
              )}{' '}
              -{' '}
              {moment(GLRContext.timePeriod.split('|')[2]).format(
                'D MMMM YYYY'
              )}
            </Typography>
          </div>
          <div className="flex flex-col items-end justify-between">
            <Typography className="opacity-50">Verified by</Typography>
            <img src={appContext.logoURL} className="h-4" alt="Nucleos" />
          </div>
        </div>
        <div className=" mb-4">
          <Typography variant="h6">Institution</Typography>
          <Typography>{institutionQuery.data.INSTITUTION || 'none'}</Typography>
        </div>
        <div className=" mb-4">
          <Typography variant="h6">About</Typography>
          <Typography style={{ display: 'block' }}>
            The Nucleos Learning Record provides a verifiable record that the
            learner listed above has completed the following courses of study.
            For more information, please see{' '}
            <a
              style={{ textDecoration: 'underline', color: '#2D95FF' }}
              href="https://www.record.nucleos.com/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              record.nucleos.com/about
            </a>
            .
          </Typography>
        </div>
      </LoadingAndErrorHandler>
    </div>
  );
};

export default GroupAbout;
