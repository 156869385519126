import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useObserver } from 'mobx-react';
import BaseUserSearch from './BaseUserSearch';
import DataStore from '../../../Stores/DataStore';
import './UserSearch.scss';

const UserSearch = (props) => {
  const [searchString, setSearchString] = useState('');
  const [mayDisplaySearchResults, setMayDisplaySearchResults] = useState(false);
  const { searchUser } = DataStore;

  function handleSearchInputChange (event) {
    const { value } = event.target;
    searchUser(value, props.inactive);
    setSearchString(value);
    setMayDisplaySearchResults(true);
  }

  function setUser (user) {
    const { onSelectUser } = props;
    searchUser(null);
    setSearchString('');
    onSelectUser(user);
    return { success: true };
  }

  function handleBlur () {
    const wait = () =>
      new Promise((resolve) => {
        setTimeout(resolve, 400);
      });
    wait()
      .then(() => {
        setMayDisplaySearchResults(false);
        setSearchString('');
      })
      .catch(() => null);
  }
  return useObserver(() => {
    const { userSearchResult } = DataStore;
    const { label, materialUI, user } = props;
    return (
      <BaseUserSearch
        user={user}
        label={label}
        searchString={searchString}
        handleSearchInputChange={handleSearchInputChange}
        handleBlur={handleBlur}
        userSearchResult={userSearchResult}
        mayDisplaySearchResults={mayDisplaySearchResults}
        setUser={setUser}
        materialUI={materialUI}
      />
    );
  });
};

UserSearch.propTypes = {
  label: PropTypes.string,
  onSelectUser: PropTypes.func.isRequired,
  inactive: PropTypes.bool
};

export default UserSearch;
