import React from 'react';
import moment from 'moment-timezone';
import { Box } from '@mui/material';
import { useAppContext } from '../../../../../Contexts/AppContext';

const TIMEZONE = process.env.REACT_APP_TIMEZONE_IDENTIFIER;

export const GrievancePDFHeader = (props) => {
  const { requestNumber, requestType, requestorName, createdAt } = props;

  const appContext = useAppContext();

  return (
    <Box
      className="px-28 py-24"
      sx={(theme) => ({
        background: `linear-gradient(315.43deg, ${theme.palette.primary.main} -17.41%, ${theme.palette.primary.dark} 100%)`,
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      })}
    >
      <Box>
        <img src={appContext.monochromeLogoURL} alt='logo' style={{ height: 64 }} />
      </Box>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p
          style={{
            fontSize: 56,
            fontWeight: 600,
            textTransform: 'uppercase',
            color: '#fff'
          }}
        >
          {requestType === 'GRIEVANCE' ? 'Grievance' : 'Action Request'}
          <br />
          {requestNumber}
        </p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', color: '#fff' }}>
        <div>
          <p style={{ fontSize: 24 }}>
            Requestor
          </p>

          <p style={{ fontSize: 28, fontWeight: 600 }}>
            {requestorName}
          </p>
        </div>

        <div>
          <p style={{ fontSize: 24 }}>
            Date & Time
          </p>

          <p style={{ fontSize: 28, fontWeight: 600 }}>
            {`${moment(createdAt).tz(TIMEZONE).format('MMM DD, YYYY HH:mm')}`}
          </p>
        </div>
      </div>
    </Box>
  );
};
