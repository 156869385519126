import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  TextInput,
  Typography,
  MultiSelectDropDown,
  DatePicker
} from '@nucleos/core-ui';
import { Controller, useForm } from 'react-hook-form';
import { useFacilitiesQuery } from '../../../hooks/useFacilities';
import { yupResolver } from '@hookform/resolvers/yup';
import ConfigRoleSelect from '../../Roles/RolesSelect';
import { RoleSelect } from '../../Form';
import { useMutation, useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { useUserQuery } from '../../../hooks/useUser';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import toast from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import { useHistory } from 'react-router-dom';
import { getUserSchema } from './validation-schema';
import { LoadingAndErrorHandler } from '../../Shared/LoadingErrorHandler';
import { confirmAlert } from 'react-confirm-alert';
import { Divider, IconButton, Switch } from '@mui/material';
import { Icon } from '../../Shared/Icon';
import { USER_PATH } from '../../../constants/paths';
import DefaultPasswordModalContent from '../DefaultPasswordModalContent';
import useUserPermissions from '../../../hooks/useUserPermissions';

export default function NewUserForm ({
  isModalRenderedForm,
  userId,
  disableForm,
  onCloseClick,
  onUserCreateOrUpdate
}) {
  const isPublicExtension = window.nucleosConfig.isPublicExtension;
  const isEditingUser = !!userId;
  const isCreatingUser = !isEditingUser;
  const loggedInUserQuery = useUserQuery();
  const userPermissions = useUserPermissions();
  const history = useHistory();
  const userQuery = useQuery(
    QueryKeys.Users.item(userId),
    () => Data.getUser(userId),
    { enabled: !!userId }
  );

  const userTitles = useQuery(
    [QueryKeys.UserTitles],
    () => Data.getUserTitles(),
    {
      placeholderData: [],
      select: (data) => data.map((d) => ({ title: d.title, value: d.uid }))
    }
  );

  const currentUserRole =
    userQuery.status === 'success' ? userQuery.data.Role.name : '';
  const loggedInUserRole = loggedInUserQuery.data
    ? loggedInUserQuery.data.Role.name
    : '';

  const fetchUserPasswordMutation = useMutation(() => Data.getPassword(userId));

  const userFormSchema = getUserSchema({ isEditingUser, isPublicExtension });
  const userForm = useForm({
    resolver: yupResolver(userFormSchema),
    defaultValues: {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      role: ACTIVE_ROLES.LEARNER,
      grievanceRole: '',
      showFirstNameToLearners: false,
      facilities: [],
      password: ''
    },
    mode: 'onChange'
  });

  const emailValue = userForm.watch('email');
  const selectedRole = userForm.watch('role');
  const selectedGrievanceRole = userForm.watch('grievanceRole');
  const isLearnerRoleSelected = selectedRole === 'learner.default';

  const isEmailRequired = (
    selectedRole !== ACTIVE_ROLES.LEARNER ||
    (selectedRole === ACTIVE_ROLES.LEARNER && emailValue) ||
    isPublicExtension
  );

  const createUserMutation = useMutation(
    (data) =>
      Data.addUser(
        data.firstName,
        data.lastName,
        data.username,
        data.password,
        data.email,
        data.role,
        data.facilities,
        undefined,
        data.grievanceRole,
        data.title,
        data.showFirstNameToLearners,
        data.dob ? moment.utc(data.dob) : undefined
      ).then(GenericErrorDetectorForMutations),
    {
      onSuccess: (result) => {
        if (onUserCreateOrUpdate) { onUserCreateOrUpdate(); }
        return toast.success(
          <div className="nucleos-core">
            User{' '}
            <span
              className="cursor-pointer underline"
              style={{ color: '#449fff' }}
              onClick={() => history.replace(`${USER_PATH}/${result.uid}`)}
            >
              {result.username}
            </span>{' '}
            created successfully.
          </div>,
          { duration: 8000 }
        );
      },
      onError: (error) => toast.error(error.message || 'Failed to create user')
    }
  );
  const updateUserMutation = useMutation(
    (data) =>
      Data.updateUser(userId, {
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        password: data.password,
        emailAddress: data.email,
        Role: data.role,
        facilities: data.facilities,
        grievanceRole: data.grievanceRole,
        userTitleUid: data.title,
        showFirstNameToLearners: data.showFirstNameToLearners,
        dob: data.dob ? moment.utc(data.dob) : undefined
      }, true).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        if (onUserCreateOrUpdate) { onUserCreateOrUpdate(); }
        toast.success('User details updated');
        userQuery.refetch();
      },
      onError: (error) => toast.error(error.message || 'Failed to update user')
    }
  );

  const facilitiesQuery = useFacilitiesQuery();
  const facilities = facilitiesQuery.data || [];

  useEffect(() => {
    if (userQuery.status === 'success' && userQuery.isFetching === false) {
      initiateFormToOriginalState();
    }
  }, [userQuery.status, userQuery.isFetching]);

  const initiateFormToOriginalState = () => {
    userForm.reset({
      title: isEditingUser ? userQuery.data.userTitleUid : '',
      firstName: isEditingUser ? userQuery.data.firstName : '',
      lastName: isEditingUser ? userQuery.data.lastName : '',
      username: isEditingUser ? userQuery.data.username : '',
      dob: isEditingUser ? userQuery.data.dob ? new Date(userQuery.data.dob) : '' : '',
      showFirstNameToLearners: isEditingUser
        ? userQuery.data.showFirstName || false
        : '',
      email: isEditingUser
        ? userQuery.data.emailAddresses
          ? userQuery.data.emailAddresses[0]
          : ''
        : '',
      password: '',
      role: isEditingUser ? userQuery.data.Role.name : '',
      grievanceRole: isEditingUser
        ? userQuery.data.ConfigRole
          ? userQuery.data.ConfigRole.id
          : ''
        : '',
      facilities: isEditingUser
        ? userQuery.data.facilities.map((f) => f.id)
        : ''
    });
  };

  const setDefaultPasswordMutation = useMutation(({ uids }) =>
    Data.setDefaultPassword(uids).then(GenericErrorDetectorForMutations)
  );

  const onSetDefaultPassword = useCallback(
    (userIDs) => {
      const loadingId = toast.loading('Setting default password...');
      setDefaultPasswordMutation.mutate(
        { uids: userIDs },
        {
          onSuccess: () => {
            toast.success('Default password set successfully.');
            fetchUserPasswordMutation.mutate(undefined, {
              onSuccess: (data) => {
                userForm.setValue('password', data.password);
              }
            });
          },
          onError: () => {
            toast.error('Failed to set default password.');
          },
          onSettled: () => {
            toast.dismiss(loadingId);
          }
        }
      );
    },
    [setDefaultPasswordMutation, fetchUserPasswordMutation, userForm]
  );

  const handleSetDefaultPasswordClick = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <DefaultPasswordModalContent
          onClose={onClose}
          selectedRows={[userQuery.data]}
          onSuccess={(userIDs) => {
            onSetDefaultPassword(userIDs);
            onClose();
          }}
        />
      )
    });
  }, [onSetDefaultPassword, userQuery.data]);

  const createUserHandler = (data) => {
    if (isEditingUser) {
      const updatedData = Object.keys(userForm.formState.dirtyFields).reduce((updatedDataAcc, field) => {
        updatedDataAcc[field] = data[field];

        return updatedDataAcc;
      }, {});

      if ((updatedData.firstName || updatedData.lastName) && isLearnerRoleSelected) {
        confirmAlert({
          customUI: ({ onClose }) => (
            <div className="nucleos-core">
              <div className="mx-auto my-0 w-96">
                <div
                  className="flex flex-col p-6 rounded-md"
                  style={{ background: '#ffffff' }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <Typography variant="h4">Please Note:</Typography>
                    </div>

                    <div>
                      <IconButton onClick={onClose}>
                        <Icon icon="Close" />
                      </IconButton>
                    </div>
                  </div>

                  <Divider className="my-3" style={{ height: 1 }} />

                  <div className="text-sm">
                    Changing the first name or the last name of the learner here does not change the user&apos;s details in the applications.&nbsp;
                    Are you sure you want to change the user&apos;s details?
                  </div>

                  <div className="flex justify-between items-center mt-6">
                    <Button style={{ color: '#333333' }} onClick={onClose}>
                      No
                    </Button>
                    <Button type="submit" color="primary" variant="contained" onClick={() => {
                      updateUserMutation.mutate({ ...updatedData });

                      onClose();
                    }}>
                      Yes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )
        });
      } else {
        updateUserMutation.mutate({ ...updatedData });
      }
    } else {
      createUserMutation.mutate({ ...data });
    }
  };

  const onShowPasswordClick = (show) => {
    if (!show || !isEditingUser) { return; }
    fetchUserPasswordMutation.mutate(undefined, {
      onSuccess: (data) => {
        userForm.setValue('password', data.password);
      }
    });
  };

  const visibleFields = {
    title: !isLearnerRoleSelected,
    firstName: true,
    lastName: true,
    email: true,
    username: true,
    dob: isLearnerRoleSelected && window.nucleosConfig.showDOB,
    password: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
      loggedInUserRole
    ),
    confirmPassword: isCreatingUser,
    defaultPassword: userPermissions.canSetDefaultPassword() && selectedRole === ACTIVE_ROLES.LEARNER && isEditingUser,
    showFirstNameToLearners: !isLearnerRoleSelected,
    role: true,
    grievanceRole: true,
    facilities:
      selectedRole === ACTIVE_ROLES.LEARNER ||
      (selectedRole !== ACTIVE_ROLES.LEARNER && selectedGrievanceRole)
  };

  const enabledFields = {
    title: visibleFields.title && isCreatingUser,
    firstName: visibleFields.firstName && isCreatingUser,
    lastName: visibleFields.lastName && isCreatingUser,
    email: visibleFields.email && isCreatingUser,
    username: visibleFields.username && isCreatingUser,
    dob: visibleFields.dob && isCreatingUser,
    password: visibleFields.password && isCreatingUser,
    defaultPassword: visibleFields.defaultPassword && isEditingUser,
    confirmPassword: visibleFields.confirmPassword && isCreatingUser,
    showFirstNameToLearners: visibleFields.showFirstNameToLearners && isCreatingUser,
    role: visibleFields.role && isCreatingUser,
    grievanceRole:
      visibleFields.grievanceRole && isCreatingUser,
    facilities: visibleFields.facilities && isCreatingUser
  };

  if (userQuery.data && Array.isArray(userQuery.data.editableFields)) {
    userQuery.data.editableFields.forEach((field) => {
      enabledFields[field] = true;
    });
  }

  return (
    <div
      className="nucleos-core"
      data-test-id={isCreatingUser ? 'create-user-form' : 'update-user-form'}
    >
      <LoadingAndErrorHandler
        className="w-full h-96"
        isError={isEditingUser ? userQuery.isError : false}
        isLoading={isEditingUser ? userQuery.isLoading : false}
        isSuccess={isEditingUser ? userQuery.isSuccess : true}
      >
        <div className="flex mb-4">
          {visibleFields.title && (
            <div className="mr-4 flex-none w-40">
              <Typography>Rank/Title</Typography>
              <Controller
                name="title"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <MultiSelectDropDown
                    dropdownId="user-form-user-title-select"
                    testId={'rank-title'}
                    fullWidth
                    disableMultiSelect
                    label="Rank/Title"
                    value={field.value ? userTitles.data.filter(title => field.value.includes(title.value)) : []}
                    onChange={([item]) => field.onChange(item.value)}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.title}
                    options={userTitles.data}
                  />
                )}
              />
            </div>
          )}
          {visibleFields.firstName && (
            <div className="mr-4 flex-1">
              <Typography>
                First Name <span className="text-red-600">*</span>
              </Typography>
              <Controller
                name="firstName"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    testId={'first-name'}
                    onChange={(_, evt) => {
                      if (isCreatingUser) {
                        const lastName = userForm.getValues('lastName');
                        userForm.setValue(
                          'username',
                          `${_ ? _[0] : ''}${lastName || ''}`
                        );
                      }
                      field.onChange(evt);
                    }}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.firstName}
                    label="First Name"
                    fullWidth
                    placeholder="Enter First Name"
                  />
                )}
              />
            </div>
          )}
          {visibleFields.lastName && (
            <div className="flex-1">
              <Typography>
                Last Name <span className="text-red-600">*</span>
              </Typography>
              <Controller
                name="lastName"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    testId={'last-name'}
                    onChange={(_, evt) => {
                      if (isCreatingUser) {
                        const firstName = userForm.getValues('firstName');
                        userForm.setValue(
                          'username',
                          `${firstName ? firstName[0] : ''}${_ || ''}`
                        );
                      }
                      field.onChange(evt);
                    }}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.lastName}
                    label="Last Name"
                    fullWidth
                    placeholder="Enter Last Name"
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className="flex mb-4">
          {visibleFields.username && (
            <div className="flex-1">
              <Typography>
                Username <span className="text-red-600">*</span>
              </Typography>
              <Controller
                name="username"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    testId={'username'}
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.username}
                    label="Username"
                    fullWidth
                    placeholder="Enter Username"
                  />
                )}
              />
            </div>
          )}
          {visibleFields.dob && (
            <div className="ml-4 flex-1">
              <Typography>
                Date of Birth <span className="text-red-600">*</span>
              </Typography>
              <Controller
                name="dob"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    testId="dob"
                    fullWidth
                    placeholder="Date of Birth"
                    date={field.value}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    onChange={(date) => field.onChange(date)}
                  />
                )}
              />
            </div>
          )}
        </div>
        {visibleFields.showFirstNameToLearners && (
          <div className="flex mb-2">
            <Typography className='mt-2'>Show first name to the Learners</Typography>
            <Controller
              name="showFirstNameToLearners"
              control={userForm.control}
              render={({ field, fieldState }) => (
                <Switch
                  {...field}
                  color='primary'
                  testId={'show-first-name'}
                  checked={!!field.value}
                  onChange={(event) => field.onChange(event.target.checked)}
                  disabled={
                    disableForm || !enabledFields.showFirstNameToLearners
                  }
                />
              )}
            />
          </div>
        )}
        {visibleFields.email && (
          <div className="mb-4">
            <div className="flex-1">
              <Typography>
                Email Address { isEmailRequired ? (<span className="text-red-600">*</span>) : null}
              </Typography>
              <Controller
                name="email"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    testId="email"
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.email}
                    label="Email Address"
                    fullWidth
                    placeholder="Enter Email Address"
                  />
                )}
              />
            </div>
          </div>
        )}
        {visibleFields.password && (
          <div className="mb-4">
            <div className="flex-1">
              <Typography>
                Password{' '}
                {isCreatingUser && <span className="text-red-600">*</span>}
              </Typography>
              <Controller
                name="password"
                control={userForm.control}
                shouldUnregister={true}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    testId="password"
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    type="password"
                    autoComplete={'new-password'}
                    onShowPasswordClick={onShowPasswordClick}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.password}
                    hideShowPasswordIcon={
                      !(
                        (loggedInUserRole === ACTIVE_ROLES.SUPER_ADMIN ||
                          loggedInUserRole === ACTIVE_ROLES.STAFF_ADMIN) &&
                        currentUserRole === ACTIVE_ROLES.LEARNER
                      )
                    }
                    label="Password"
                    fullWidth
                    placeholder={
                      userId
                        ? fetchUserPasswordMutation.isLoading
                          ? 'Loading...'
                          : '********'
                        : 'Enter Password'
                    }
                  />
                )}
              />
            </div>
          </div>
        )}
        {window.nucleosConfig.setDefaultPasswordEnabled && visibleFields.defaultPassword && (
          <div className="mb-4 -mt-4 flex justify-end">
            <Button
              size="small"
              variant='text'
              style={{ color: '#449FFF', paddingRight: 0 }}
              onClick={handleSetDefaultPasswordClick}
            >
              Set Default Password
            </Button>
          </div>
        )}
        {visibleFields.confirmPassword && (
          <div className="mb-4">
            <div className="flex-1">
              <Typography>
                Confirm Password{' '}
                {isCreatingUser && <span className="text-red-600">*</span>}
              </Typography>
              <Controller
                name="confirmPassword"
                control={userForm.control}
                shouldUnregister={true}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    testId="confirm-password"
                    type="password"
                    onShowPasswordClick={onShowPasswordClick}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.confirmPassword}
                    label="Confirm Password"
                    fullWidth
                    placeholder={'Re-Enter Password'}
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className="flex mb-4">
          {visibleFields.role && (
            <div className="mr-4 flex-1">
              <Typography>
                Role <span className="text-red-600">*</span>
              </Typography>
              <Controller
                name="role"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <RoleSelect
                    {...field}
                    id='user-form-role-select'
                    testId="user-role"
                    name="role"
                    value={field.value ? [field.value] : []}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    onChange={([item]) => {
                      userForm.setValue('grievanceRole', '');
                      field.onChange(item.value);
                    }}
                    disabled={disableForm || !enabledFields.role}
                    label="Select Role"
                    DropdownContainerProps={isModalRenderedForm ? { position: 'top' } : {}}
                  />
                )}
              />
            </div>
          )}
          {visibleFields.grievanceRole && (
            <div className="flex-1">
              <Typography>Grievance Role</Typography>
              <Controller
                name="grievanceRole"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <ConfigRoleSelect
                    id='user-form-grievance-role-select'
                    name="configRole"
                    testId="grievance-role"
                    value={field.value ? [field.value] : []}
                    error={!!fieldState.error}
                    noSelectedValue="Select"
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!(fieldState.error || {}).message}
                    onChange={([item]) => field.onChange(item.value)}
                    ConfigRole={{}}
                    disabled={disableForm || !enabledFields.grievanceRole}
                    roleId={field.value}
                    filterOptions={{
                      byLearner: isLearnerRoleSelected,
                      byNonLearner: !isLearnerRoleSelected
                    }}
                    label={'Select grievance role'}
                    DropdownContainerProps={isModalRenderedForm ? { position: 'top' } : {}}
                  />
                )}
              />
            </div>
          )}
        </div>
        {visibleFields.facilities && (
          <div className="mb-4">
            <div>
              <Typography>
                {isLearnerRoleSelected ? 'Facility' : 'Facilities'} <span className="text-red-600">*</span>
              </Typography>
              <Controller
                name="facilities"
                control={userForm.control}
                render={({ field, fieldState }) => (
                  <MultiSelectDropDown
                    {...field}
                    dropdownId="user-from-facility-select"
                    onChange={(values) =>
                      field.onChange(values.map((v) => v.value))
                    }
                    testId="facilities"
                    value={field.value ? facilities.filter(facility => field.value.includes(facility.value)) : []}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableMultiSelect={isLearnerRoleSelected}
                    disableHelperText={!(fieldState.error || {}).message}
                    disabled={disableForm || !enabledFields.facilities}
                    label={isLearnerRoleSelected ? 'Select facility' : 'Select facilities'}
                    options={facilities}
                    fullWidth
                    getLabel={(selectedValues) => (
                      <div
                        className="flex flex-wrap gap-1"
                        data-testid="user-form-selected-facilities-list"
                      >
                        {selectedValues.map((facility) => (
                          <div
                            key={facility}
                            className="px-2 py-1 mr-2 flex items-center rounded-full bg-lightblue-light"
                            data-testid="user-form-selected-facility"
                            data-facilityid={facility}
                          >
                            {
                              (
                                facilities.find((f) => f.value === facility) ||
                                {}
                              ).title
                            }
                            <Icon
                              icon="Close"
                              data-testid="user-form-selected-facility-remove-btn"
                              data-facilityid={facility}
                              style={{ fontSize: 16 }}
                              className="ml-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                field.onChange(
                                  field.value.filter((v) => v !== facility)
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    DropdownContainerProps={isModalRenderedForm ? { position: 'top' } : {}}
                  />
                )}
              />
            </div>
          </div>
        )}

        {Object.values(enabledFields).some((val) => val === true)
          ? (
            <div
              className={
                'flex justify-between ' +
                (isEditingUser ? '' : 'flex-row-reverse')
              }
            >
              <Button
                color="primary"
                variant="contained"
                onClick={userForm.handleSubmit(createUserHandler, console.error)}
                disabled={!userForm.formState.isDirty}
                loading={
                  (userId ? updateUserMutation : createUserMutation).isLoading
                }
              >
                {isEditingUser ? 'Save Changes' : 'Create User'}
              </Button>
              <Button
                variant="outlined"
                onClick={
                  isEditingUser ? initiateFormToOriginalState : onCloseClick
                }
              >
                {isEditingUser ? 'Clear Changes' : 'Close'}
              </Button>
            </div>
          )
          : null}
      </LoadingAndErrorHandler>
    </div>
  );
}
