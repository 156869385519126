import React, { useState } from 'react';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import {
  MultiSelectDropDown,
  Typography,
  Button,
  DateRangePicker
} from '@nucleos/core-ui';
import { getDays } from '../../../Lib/util';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import moment from 'moment';

export default function FilterToolbar () {
  const GLRContext = useGroupsLearningRecordContext();
  const isCustomTimePeriod = getDays().every(
    (day) => day.val !== GLRContext.timePeriod
  );
  const [isCustomDateVisible, setIsCustomDateVisible] =
    useState(isCustomTimePeriod);

  const applicationsQuery = useQuery(
    QueryKeys.Applications.listing(),
    () => Data.getApplications(),
    {
      select: (data) =>
        data.map((app) => ({ title: app.name, value: app.uid })),
      placeholderData: [],
      onSuccess: (data) => {
        GLRContext.setApps(data.map((app) => app.value));
      }
    }
  );

  const getLabelForSelectedApps = (apps) => {
    if (apps.length === applicationsQuery.data.length) { return 'All Apps'; }
    return `Selected (${apps.length})`;
  };

  const timePeriodOptions = [
    { title: 'Custom Date', value: 'custom' },
    ...getDays().map((item) => ({
      title: item.name,
      value: item.val
    }))
  ];

  return (
    <div className="flex justify-between items-center my-4">
      <div>
        <Typography>
          Showing results from{' '}
          {moment(GLRContext.timePeriod.split('|')[1]).format('MMM DD')} -{' '}
          {moment(GLRContext.timePeriod.split('|')[2]).format('MMM DD, YYYY')}
        </Typography>
      </div>
      <div className="flex items-center">
        <div className="flex items-center">
          <Typography className="pr-2">Time Period:</Typography>
          <MultiSelectDropDown
            dropdownId="group-learning-records-time-period-select"
            className="w-48"
            label="Time Period"
            options={timePeriodOptions}
            value={timePeriodOptions.filter(
              (op) =>
                op.value ===
                (isCustomDateVisible ? 'custom' : GLRContext.timePeriod)
            )}
            onChange={([timePeriod]) => {
              if (timePeriod.value === 'custom') {
                setIsCustomDateVisible(true);
                return;
              }

              setIsCustomDateVisible(false);
              GLRContext.setTimePeriod(timePeriod.value);
            }}
            disableMultiSelect
            disableHelperText
          />
          <div className="chart-date__picker">
            <DateRangePicker
              maxDate={new Date()}
              disableHelperText
              className={
                'w-48 text-left h-1/6' + (isCustomDateVisible ? '' : ' hidden')
              }
              placeholder="Select date range"
              fullWidth
              onChange={({ startDate, endDate }) => {
                const timePeriod = `custom|
                ${moment(startDate).format('YYYY-MM-DD')}|${moment(
      endDate
    ).format('YYYY-MM-DD')}`;
                GLRContext.setTimePeriod(timePeriod);
              }}
              value={{
                startDate: new Date(GLRContext.timePeriod.split('|')[1]),
                endDate: new Date(GLRContext.timePeriod.split('|')[2])
              }}
            />
          </div>
        </div>
        <div className="flex items-center ml-10 w-72">
          <Typography className="pr-2">Apps:</Typography>
          <div className="w-full">
            <MultiSelectDropDown
              dropdownId="group-learning-records-applications-select"
              label="All Apps"
              fullWidth
              options={applicationsQuery.data}
              value={
                applicationsQuery.isSuccess
                  ? applicationsQuery.data.filter(
                    (op) =>
                      GLRContext.apps && GLRContext.apps.includes(op.value)
                  ) || []
                  : []
              }
              isSearchLoading={applicationsQuery.isFetching}
              onChange={(apps) => {
                if (apps.length === 0) { return GLRContext.setApps(null); }
                GLRContext.setApps(apps.map((ap) => ap.value));
              }}
              getLabel={getLabelForSelectedApps}
              disableHelperText
              ActionButton={
                <Button
                  className="mb-2"
                  size="small"
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    if (
                      (GLRContext.apps || []).length ===
                      applicationsQuery.data.length
                    ) { return GLRContext.setApps(null); }

                    GLRContext.setApps(
                      applicationsQuery.data.map((ap) => ap.value)
                    );
                  }}
                >
                  {
                    (GLRContext.apps || []).length ===
                      applicationsQuery.data.length
                      ? 'De-select All'
                      : 'Select All'
                  }
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
