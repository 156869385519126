import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import classNames from 'classnames';
import * as faceapi from 'face-api.js';
import { Button, Dialog, Typography } from '@nucleos/core-ui';

import AuthenticationStore from '../Stores/Authentication';
import { GenericErrorDetectorForMutations } from '../Middleware/Api';
import { getDeviceInfo } from '../Lib/extension';
import { Alert, Box, Grid } from '@mui/material';
import HTMLReactParser from 'html-react-parser';

const CaptureUserImage = ({ username, password, onCancelClick }) => {
  const [pauseVideo, setPauseVideo] = useState(false);
  const [isValidImage, setIsValidImage] = useState(false);
  const [faceDetectionError, setFaceDetectionError] = useState('');
  const [userImage, setUserImage] = useState(null);
  const [imageCapture, setImageCapture] = useState(null);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [error, setError] = useState('');
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    onGetUserMedia();
    getDeviceInfo()
      .then((info) =>
        setDeviceInfo({
          ...info,
          extensionId: window.nucleosConfig.extensionId
        })
      )
      .catch(() => setDeviceInfo(null));

    return () => stopWebcam();
  }, []);

  useEffect(() => {
    if (
      AuthenticationStore.isAuthenticated &&
      ['/', '/login'].includes(history.location.pathname)
    ) {
      stopWebcam();
      onCancelClick();
    }
  }, [AuthenticationStore.isAuthenticated, history.location.pathname]);

  useEffect(() => {
    let interval = null;
    const videoElement = document.querySelector('#videoElement');
    if (videoElement && imageCapture) {
      interval = setInterval(detectFaces, 500);
    }
    return () => clearInterval(interval);
  }, [imageCapture]);

  const loginMutation = useMutation(
    () =>
      AuthenticationStore.attemptLogin(
        username,
        password,
        userImage,
        deviceInfo
      ).then(GenericErrorDetectorForMutations),
    {
      onError: (error) => {
        if (error.response) {
          if (error.response.flaggedUser) {
            setErrorModalMessage(error.response.message);
            setError('FLAGGED_USER');

            return;
          }
          if (typeof error.response.error === 'string') { setError(error.response.error); }
          if (typeof error.response.message === 'string') { setError(error.response.message); }
        }
      }
    }
  );

  const detectFaces = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');

    const video = document.querySelector('#videoElement');
    if (video) {
      try {
        const detections = await faceapi.detectAllFaces(
          video,
          new faceapi.TinyFaceDetectorOptions()
        );
        if (detections.length === 1) {
          setIsValidImage(true);
          setFaceDetectionError('');
        } else {
          setIsValidImage(false);
          !detections.length
            ? setFaceDetectionError('Face not detected')
            : setFaceDetectionError('Multiple faces detected');
        }
      } catch (error) {
        console.error('detectFaces() error:', error);
        setFaceDetectionError('Failed to detect face');
        setIsValidImage(false);
      }
    }
  };

  const onStopImageCapture = () => {
    setImageCapture(null);
    setUserImage(null);
    setIsValidImage(false);
    setFaceDetectionError('');
    setPauseVideo(false);
    stopWebcam();
    setError('');
    onCancelClick();
    AuthenticationStore.setUserValidity(false);
  };

  const onRetakeButtonClick = () => {
    document.querySelector('#videoElement').play();
    setUserImage(null);
    setIsValidImage(false);
    setFaceDetectionError('');
    setPauseVideo(false);
    setError('');
  };

  const onCaptureButtonClick = () => {
    const video = document.getElementById('videoElement');
    const canvas = document.getElementById('canvas');
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(
      async (blob) => {
        document.querySelector('#videoElement').pause();
        setPauseVideo(true);
        setUserImage(blob);
      },
      'image/jpeg',
      0.6
    );
  };

  const stopWebcam = () => {
    const videoElement = document.querySelector('#videoElement');
    if (videoElement) {
      const stream = videoElement.srcObject;
      if (stream) {
        const tracks = stream.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
      videoElement.srcObject = null;
    }
  };

  const onGetUserMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((mediaStream) => {
        document.querySelector('#videoElement').srcObject = mediaStream;

        const track = mediaStream.getVideoTracks()[0];
        setImageCapture(new ImageCapture(track));
      })
      .catch((error) => console.error('getUserMedia() error:', error));
  };

  return (
    <Box sx={{ flex: 1, mx: 4 }}>
      <Grid container spacing={6}>
        <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Box>
            <Typography
              variant="h1"
              className="text-4xl"
              style={{ color: '#4468FF' }}
            >
              Capture your face
            </Typography>

            <Box sx={{ my: 4 }}>
              <Box sx={{ '& ul': { listStyle: 'disc !important' } }}>
                {
                  HTMLReactParser(window.nucleosConfig.captureFaceAgreement || '')
                }
              </Box>
            </Box>

            {!['FLAGGED_USER'].includes(error) && error
              ? (
                <Box sx={{ width: '100%', my: 4 }}>
                  <Alert severity='error'>{error}</Alert>
                </Box>
              )
              : null}

            <Dialog
              isOpen={error === 'FLAGGED_USER'}
              onClose={() => onCancelClick()}
              title="Login Restricted"
              size="medium"
              hideCloseButton
            >
              <Typography className="mt-4">
                {errorModalMessage}
              </Typography>
              <Button
                className="mt-6"
                variant="contained"
                color="primary"
                onClick={() => onCancelClick({ reload: true })}
              >
              Back to Login
              </Button>
            </Dialog>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                loading={loginMutation.isLoading}
                fullWidth
                disabled={!userImage || (userImage && !isValidImage)}
                onClick={() => loginMutation.mutate()}
              >
                Login
              </Button>

              <Button
                color="primary"
                fullWidth
                className="cancel-image-capture"
                onClick={onStopImageCapture}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div
              className={classNames('image-border flex', {
                success: isValidImage,
                error: !isValidImage,
                default: !imageCapture
              })}
            >
              <video id="videoElement" autoPlay className="m-auto" />
              <canvas
                id="canvas"
                width="640"
                height="480"
                style={{ display: 'none' }}
              ></canvas>
            </div>
            <Button
              color={
                !imageCapture ? 'secondary' : isValidImage ? 'primary' : 'error'
              }
              className={classNames('my-4', {
                'btn-success': imageCapture && isValidImage
              })}
              disabled={!pauseVideo && (!imageCapture || !isValidImage)}
              onClick={() =>
                pauseVideo ? onRetakeButtonClick() : onCaptureButtonClick()
              }
            >
              {pauseVideo ? 'Retake' : faceDetectionError || 'Capture'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaptureUserImage;
