import React, { useEffect } from 'react';
import AuthenticationStore from '../Stores/Authentication';
import NucleosBot from '../Assets/NucleosBot.png';

const UnderConstruction = () => {
  const { userRole } = AuthenticationStore;
  const isLearner = userRole === 'learner';
  useEffect(() => {
    if (isLearner) {
      const header = document.querySelector('.header');
      header.style.display = 'none';
    } else {
      const headerNav = document.querySelector('.header__nav');
      const headerMenu = document.querySelector('.header__menu');
      headerNav.style.display = 'none';
      headerMenu.style.top = 0;
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 46px)' }}>
      <img src={NucleosBot} alt='Work In Progress' height="50%" />
      <h1>We’ll be back soon!</h1>
      <div>
        <p>
          Sorry for the inconvenience but we’re under construction at the moment.
          we’ll be back online shortly!
        </p>
      </div>
    </div>
  );
};

export default UnderConstruction;
