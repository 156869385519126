import React from 'react';
import PropTypes from 'prop-types';
import { TextField, styled } from '@mui/material';
import './Form.scss';

const PREFIX = 'Textarea';
const classes = {
  root: `${PREFIX}-root`,
  underline: `${PREFIX}-underline`
};

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    padding: 0,
    width: '100%'
  },
  [`& .${classes.underline}`]: {
    '&&&:before': {
      borderBottom: 'none'
    },
    '&&:after': {
      borderBottom: 'none'
    },
    '&&:hover': {
      '&&&:before': {
        borderBottom: 'none'
      },
      '&&:after': {
        borderBottom: 'none'
      }
    }
  }
}));

const Textarea = props => {
  const {
    className,
    disabled,
    error,
    name,
    onChange,
    placeholder,
    required,
    rows = 1,
    rowsMax,
    value,
    fullWidth,
    maxLength,
    autoFocus,
    hideMargin,
    label,
    labelObject,
    sx
  } = props;
  const classNameAddOn = className ? ` ${className}` : '';
  const containerClassName = `nucleos-field-container textarea-container${classNameAddOn}`;
  const errorClassName = `nucleos-field-error textarea-error${classNameAddOn}`;
  const labelClassName = `nucleos-label input-label${classNameAddOn}`;
  const wrapperClassName = `nucleos-field-label-wrapper input-wrapper${classNameAddOn}`;
  const justify = hideMargin ? { justifyContent: 'flex-start' } : {};
  return (
    <Root className={containerClassName}>
      <div className={wrapperClassName} style={justify}>
        { !hideMargin && <div style={{ marginRight: '20px' }}> {labelObject && labelObject} </div> }
        {label && <label className={labelClassName}>{label}:</label>}
        <TextField
          className={classes.root}
          disabled={disabled}
          id={name}
          InputProps={{ classes, maxLength }}
          multiline
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          rows={rows}
          rowsMax={rowsMax}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          sx={sx}
        />
        {error && <span className={errorClassName}>{error}</span>}
      </div>
    </Root>
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  value: PropTypes.string.isRequired
};

export default (Textarea);
