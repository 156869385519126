import React from 'react';
import {
  styled
} from '@mui/material';
import UpcomingIcon from '../../../../Assets/UpcomingSmall.svg';
import { Icon } from '../../../../Components/Shared/Icon';

const PREFIX = 'AlertLegends';
const classes = {
  alertLegendsContainer: `${PREFIX}-alertLegendsContainer`,
  legendsWrapper: `${PREFIX}-legendsWrapper`,
  appealLevelIcon: `${PREFIX}-appealLevelIcon`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div')(() => ({
  [`& .${classes.alertLegendsContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0px 0px 20px 0px',
    flexWrap: 'wrap'
  },

  [`& .${classes.legendsWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 10px 0px 0px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: 'rgba(51, 51, 51, 0.8)'
  },

  [`& .${classes.appealLevelIcon}`]: {
    borderRadius: '20px',
    fontSize: '8px',
    padding: '8px',
    color: 'white',
    width: '15px',
    height: '15px',
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '5px'
  },

  [`& .${classes.icon}`]: {
    marginRight: '5px',
    height: '18px',
    width: '18px'
  }
}));

const AlertLegends = () => {
  return (
    <Root className={classes.alertLegendsContainer} sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <div className={classes.legendsWrapper} >
        <img src={UpcomingIcon} className={classes.icon} alt='Low priority' />
        <span>Response required</span>
      </div>
      <div className={classes.legendsWrapper}>
        <Icon icon="Warning" className={classes.icon} style={{ color: '#ffa726' }}/>
        <span>{'Response required. No response > 3 days'}</span>
      </div>
      <div className={classes.legendsWrapper}>
        <Icon icon="Error" className={classes.icon} style={{ color: '#f44336' }}/>
        <span>{'Response required. No response > 5 days'}</span>
      </div>
      <div className={classes.legendsWrapper}>
        <Icon icon="CheckCircle" className={classes.icon} style={{ color: '#66bb6a' }}/>
        <span>Responded</span>
      </div>
      <div className={classes.legendsWrapper} >
        <div className={classes.appealLevelIcon} style={{ backgroundColor: '#247DDC', color: '#FFF' }}>A1</div>
        <span>Appeal Level 1</span>
      </div>
      <div className={classes.legendsWrapper} >
        <div className={classes.appealLevelIcon} style={{ backgroundColor: '#800080', color: '#FFF' }}>A2</div>
        <span>Appeal Level 2</span>
      </div>
    </Root>
  );
};

export default (AlertLegends);
