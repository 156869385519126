import './TileGrid.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, Grid } from '@mui/material';

const paperBaseStyle = {
  padding: 30,
  borderRadius: '12px',
  boxShadow: '0 10px 40px 0 rgba(64, 72, 82, 0.07)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

class FlexTileGrid extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
      tileHeight: PropTypes.string,
      tileWidth: PropTypes.string,
      spacing: PropTypes.number,
      gridColTiers: PropTypes.object,
      tileStyle: PropTypes.object,
      gridItemStyle: PropTypes.object,
      gridItemClassName: PropTypes.string
    };

    static defaultProps = {
      tileHeight: '100%',
      tileWidth: '100%',
      spacing: 5,
      gridColTiers: {
        xs: 12,
        md: 6,
        lg: 4
      },
      tileStyle: {},
      gridItemStyle: {},
      gridItemClassName: ''
    };

    render () {
      const { spacing, children } = this.props;
      const tiles = children && children.map ? children.map(this.generateTiles) : this.generateTiles(children);

      return (
        <Grid container justify='flex-start' spacing={spacing}>
          {tiles}
        </Grid>
      );
    }

    generateTiles = (tileContent, idx) => (
      <Grid key={idx} item className={this.props.gridItemClassName} {...this.props.gridColTiers} style={this.props.gridItemStyle}>
        <Paper style={{ ...paperBaseStyle, height: this.props.tileHeight, width: this.props.tileWidth, ...this.props.tileStyle }}>{tileContent}</Paper>
      </Grid>
    );
}

export default FlexTileGrid;
