import { Typography } from '@nucleos/core-ui';
import React from 'react';

export default function NewGrievanceDetails ({
  requestorName,
  category,
  jailName,
  cellNumber
}) {
  return (
    <div className="nucleos-core">
      <div className="flex p-8 border border-gray-light rounded-xl bg-background-light">
        <div className="flex flex-col w-96">
          <Typography>Requestor Name:</Typography>
          <Typography variant="h6">{requestorName}</Typography>
        </div>
        <div className="flex flex-col w-96">
          <Typography>Type of Request:</Typography>
          <Typography variant="h6">{category.title}</Typography>
        </div>
        <div className="flex flex-col w-96">
          <Typography>Jail:</Typography>
          <Typography variant="h6">{jailName || 'Not Available'}</Typography>
        </div>
        <div className="flex flex-col w-96">
          <Typography>Cell #:</Typography>
          <Typography variant="h6">{cellNumber || 'Not Available'}</Typography>
        </div>
      </div>
    </div>
  );
}
