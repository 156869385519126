import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DataStore from '../../../../Stores/DataStore';
import GrievanceResponses from '../../../../Components/Grievance/GrievanceResponses';
import GrievanceInfo from '../../../../Components/Grievance/GrievanceInfo';
import { Typography } from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import Data from '../../../../Middleware/Data';
import GrievanceTypeChip from '../../../../Components/Grievance/GrievanceTypeChip';
import { Box } from '@mui/material';
import HeaderSubPanel from '../../../../Components/HeaderSubPanel';
import { GRIEVANCES_FORMS_PATH } from '../../../../constants/paths';

const breadcrumbLinks = (name) => [
  { href: GRIEVANCES_FORMS_PATH, label: 'Grievances and Forms' },
  { href: '#', label: `Request #${name}` }
];

const ViewGrievance = () => {
  const history = useHistory();
  const { id } = useParams();
  const grievanceQuery = useQuery(
    ['GRIEVANCE', 'LEARNER', id],
    () => Data.getGrievance(id),
    {
      onSuccess: ({ grievance }) => {
        DataStore.setPageTitleDetail(
          `Grievance and Form Request > ${
            (grievance && grievance.requestNumber) || id
          }`
        );
      }
    }
  );

  useEffect(() => {
    const unregister = history.listen((location) => {
      grievanceQuery.refetch();
    });
    return unregister;
  }, [id]);

  const grievance = (grievanceQuery.data ? grievanceQuery.data : {}).grievance;

  return (
    <Box>
      {grievance && <HeaderSubPanel links={breadcrumbLinks(grievance.requestNumber)} title={grievance.requestNumber} sx={{ mb: 3 }} />}
      <div className="user-update nucleos-core">
        {grievance && (
          <>
            <Typography variant="h1" className="mb-8">
            Request #{grievance.requestNumber} <GrievanceTypeChip className="ml-4" requestType={grievance.requestType} />
            </Typography>
            <div className="flex">
              <div className="flex-1">
                <GrievanceResponses
                  grievance={grievance}
                  onResponseSubmit={() => grievanceQuery.refetch()}
                  lernerMode
                  showRequestInCard={grievance.GrievanceResponses.length === 0}
                />
              </div>
              <div className="ml-12 w-80" style={{ marginTop: -16 }}>
                <GrievanceInfo
                  grievance={grievance}
                  hideAlertIcon
                  showDate
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Box>
  );
};
export default ViewGrievance;
