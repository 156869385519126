import { useEffect } from 'react';
import DataStore from '../Stores/DataStore';

export const SetPageTitleFromUsername = () => {
  const { currentUser, setPageTitleDetail } = DataStore;
  useEffect(() => {
    const { username } = currentUser;
    if (username) {
      setPageTitleDetail(username);
    }
    return () => setPageTitleDetail(null);
  }, [currentUser, setPageTitleDetail]);

  return null;
};
