import React from 'react';

import PlayIcon from '../../../Assets/PlayIcon.svg';

export const PlayPauseOverlay = ({
  children,
  isPaused,
  togglePlayPause
}) => {
  return (
    <div className="play-pause-overlay-container" onClick={togglePlayPause}>
      <div>{children}</div>
      {
        isPaused
          ? (
            <div className='play-pause-overlay'>
              <img src={PlayIcon} />
            </div>
          )
          : null
      }
    </div>
  );
};
