import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Popover,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tabs,
  Tab,
  styled,
  Box
} from '@mui/material';
import ExportIcon from '../../../../../Assets/ExportIcon.svg';
import './GrievanceReport.scss';
import { Icon } from '../../../../../Components/Shared/Icon';

const PREFIX = 'GrievanceReport';
const classes = {
  subTitleWrapper: `${PREFIX}-subTitleWrapper`,
  colValue: `${PREFIX}-colValue`,
  colSubTitle: `${PREFIX}-colSubTitle`,
  colMainTitle: `${PREFIX}-colMainTitle`,
  colSubMainTitle: `${PREFIX}-colSubMainTitle`,
  mainTitleWrapper: `${PREFIX}-mainTitleWrapper`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  bold: `${PREFIX}-bold`,
  grievanceReportTable: `${PREFIX}-grievanceReportTable`,
  blueBackground: `${PREFIX}-blueBackground`,
  blueBorder: `${PREFIX}-blueBorder`,
  greenBackground: `${PREFIX}-greenBackground`,
  greenBorder: `${PREFIX}-greenBorder`,
  yellowBackground: `${PREFIX}-yellowBackground`,
  yellowBorder: `${PREFIX}-yellowBorder`,
  greyBackground: `${PREFIX}-greyBackground`,
  greyBorder: `${PREFIX}-greyBorder`,
  redBackground: `${PREFIX}-redBackground`,
  offWhiteBackground: `${PREFIX}-offWhiteBackground`,
  totalsBackground: `${PREFIX}-totalsBackground`,
  reportContainer: `${PREFIX}-reportContainer`,
  headerTabRoot: `${PREFIX}-headerTabRoot`,
  tabRoot: `${PREFIX}-tabRoot`,
  tabContainer: `${PREFIX}-tabContainer`,
  tabLabelContainer: `${PREFIX}-tabLabelContainer`,
  exportBtn: `${PREFIX}-exportBtn`,
  exportBtnWrapper: `${PREFIX}-exportBtnWrapper`,
  exportOptionsWrapper: `${PREFIX}-exportOptionsWrapper`,
  exportOptionTitle: `${PREFIX}-exportOptionTitle`,
  exportOptionCloseIcon: `${PREFIX}-exportOptionCloseIcon`,
  exportOptionValueWrapper: `${PREFIX}-exportOptionValueWrapper`,
  exportOptionValueLabel: `${PREFIX}-exportOptionValueLabel`,
  radioButton: `${PREFIX}-radioButton`,
  downloadBtn: `${PREFIX}-downloadBtn`,
  exportBtnMessage: `${PREFIX}-exportBtnMessage`,
  btnLoader: `${PREFIX}-btnLoader`
};

const Root = styled('div')(() => ({
  [`& .${classes.subTitleWrapper}`]: {
    height: '120px'
  },
  [`& .${classes.colValue}`]: {
    textAlign: 'center',
    lineHeight: '19px',
    fontWeight: 400,
    fontSize: '14px',
    height: '50px',
    minWidth: '55px'
  },
  [`& .${classes.colSubTitle}`]: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#333333',
    transform: 'rotate(-70deg)'
  },
  [`& .${classes.colMainTitle}`]: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#515151'
  },
  [`& .${classes.colSubMainTitle}`]: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#515151'
  },
  [`& .${classes.mainTitleWrapper}`]: {
    padding: '10px',
    height: '55px'
  },
  [`& .${classes.categoryTitle}`]: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
    padding: '16px',
    minWidth: '180px'
  },
  [`& .${classes.bold}`]: {
    fontWeight: 700
  },
  [`& .${classes.grievanceReportTable}`]: {
    'display': 'table',
    'width': '100%',
    'background': '#ffffff !important',
    'borderCollapse': 'collapse',
    'border': '1px solid #F2EDED',
    '& tr': { border: '1px solid #F2EDED' },
    '& th': { border: '1px solid #F2EDED' },
    '& td': { border: '1px solid #F2EDED' }
  },
  [`& .${classes.blueBackground}`]: {
    background: '#C6D6F9'
  },
  [`& .${classes.blueBorder}`]: {
    borderRight: '2px solid #C6D6F9 !important'
  },
  [`& .${classes.greenBackground}`]: {
    background: '#90EE90'
  },
  [`& .${classes.greenBorder}`]: {
    borderRight: '2px solid #90EE90 !important'
  },
  [`& .${classes.yellowBackground}`]: {
    background: '#F0F0BF'
  },
  [`& .${classes.yellowBorder}`]: {
    borderRight: '2px solid #F0F0BF !important'
  },
  [`& .${classes.greyBackground}`]: {
    background: '#D3D3D3'
  },
  [`& .${classes.greyBorder}`]: {
    borderRight: '2px solid #D3D3D3 !important'
  },
  [`& .${classes.redBackground}`]: {
    background: '#FFB6C1'
  },
  [`& .${classes.offWhiteBackground}`]: {
    background: '#F8F8F8'
  },
  [`& .${classes.totalsBackground}`]: {
    background: '#F6E8D7'
  },
  [`& .${classes.reportContainer}`]: {
    padding: '20px 0px'
  },
  [`& .${classes.headerTabRoot}`]: {
    background: '#f5f6fa',
    boxShadow: 'none',
    zIndex: 1,
    width: 'auto'
  },
  [`& .${classes.tabRoot}`]: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 500,
    color: '#333333',
    opacity: 1,
    textTransform: 'capitalize',
    minWidth: 'auto'
  },
  [`& .${classes.tabContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    borderBottom: '1px solid #D3D3D3'
  },
  [`& .${classes.tabLabelContainer}`]: {
    padding: '6px 15px'
  },
  [`& .${classes.exportBtn}`]: {
    'padding': '6px 10px',
    'gap': '4px',
    'textTransform': 'capitalize',
    'height': '28px',
    'color': 'rgba(51, 51, 51, 0.8)',
    'fontSize': '12px',
    'lineHeight': '16px',
    'background': '#FFFFFF',
    'border': '1px solid rgba(51, 51, 51, 0.2)',
    'borderRadius': '4px',
    '& img': { marginRight: '5px' }
  },
  [`& .${classes.exportBtnWrapper}`]: {
    textAlign: 'end',
    marginBottom: '12px',
    position: 'relative'
  },
  [`& .${classes.exportOptionsWrapper}`]: {
    width: '240px',
    background: '#ffffff',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px'
  },
  [`& .${classes.exportOptionTitle}`]: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'padding': '12px 16px',
    'borderBottom': '1px solid #D3D3D3',

    '& .title': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#000000'
    }
  },

  [`& .${classes.exportOptionCloseIcon}`]: {
    color: 'rgba(51, 51, 51, 0.8)',
    cursor: 'pointer'
  },
  [`& .${classes.exportOptionValueWrapper}`]: {
    padding: '12px'
  },

  [`& .${classes.exportOptionValueLabel}`]: {
    color: '#333333',
    fontSize: '14px',
    lineHeight: '19px',
    marginBottom: '3px'
  },

  [`& .${classes.radioButton}`]: {
    padding: '3px 8px 3px 12px'
  },

  [`& .${classes.downloadBtn}`]: {
    'background': '#449FFF',
    'color': '#ffffff',
    'width': '117px',
    'height': '35px',
    'fontSize': '14px',
    'lineHeight': '19px',
    'fontWeight': '400',
    'textTransform': 'capitalize',
    'padding': '0px',
    '&:hover': {
      background: 'rgb(23, 105, 170)'
    }
  },
  [`& .${classes.exportBtnMessage}`]: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '12px',
    color: 'rgba(51, 51, 51, 0.6)',
    marginRight: '10px'
  },
  [`& .${classes.btnLoader}`]: {
    zIndex: 10,
    marginRight: '5px'
  }
}));

const tableSubHeaderSkeleton = (border) => {
  return (
    <>
      <td className={classes.subTitleWrapper}>
        {' '}
        <div className={classes.colSubTitle}>Initial Response</div>
      </td>
      <td className={classes.subTitleWrapper}>
        {' '}
        <div className={classes.colSubTitle}>Appeal 1</div>
      </td>
      <td className={classes.subTitleWrapper}>
        {' '}
        <div className={classes.colSubTitle}>Appeal 2</div>
      </td>
      <td className={`${classes.subTitleWrapper} ${border}`}>
        {' '}
        <div className={classes.colSubTitle}>All</div>
      </td>
    </>
  );
};

const getReportTable = (props) => {
  const { data } = props;
  const { grievances } = data;
  const tableRows = grievances.map((d, index) => (
    <tr
      key={`${d.name}-row-values`}
      className={!(index % 2) ? classes.offWhiteBackground : ''}
      data-test-id="category-rows"
    >
      <td colSpan={4} className={classes.categoryTitle}>
        {d.name}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.closedL1}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.closedL2}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.closedL3}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.blueBorder}`}>
        {' '}
        <div className={classes.bold}>{d.closed}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL1}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL2}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL3}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.greenBorder}`}>
        {' '}
        <div className={classes.bold}>{d.openAll}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL1lessthan72}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL2lessthan72}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL3lessthan72}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.greyBorder}`}>
        {' '}
        <div className={classes.bold}>{d.openAllLessThan72}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL1lessthan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL2lessthan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL3lessthan120}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.greyBorder}`}>
        {' '}
        <div className={classes.bold}>{d.openAllLessThan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL1morethan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL2morethan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div>{d.openL3morethan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{d.openAllMoreThan120}</div>{' '}
      </td>
    </tr>
  ));
  return tableRows;
};

const getTotalsRow = (props) => {
  const { data } = props;
  const { totals } = data;
  return (
    <tr
      key={'totals-row-values'}
      className={classes.totalsBackground}
      data-test-id="total-rows"
    >
      <td colSpan={4} className={classes.categoryTitle}>
        {'Total'}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.closedL1}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.closedL2}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.closedL3}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.blueBorder}`}>
        {' '}
        <div className={classes.bold}>{totals.closed}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL1}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL2}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL3}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.greenBorder}`}>
        {' '}
        <div className={classes.bold}>{totals.openAll}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL1lessthan72}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL2lessthan72}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL3lessthan72}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.greyBorder}`}>
        {' '}
        <div className={classes.bold}>{totals.openAllLessThan72}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL1lessthan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL2lessthan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL3lessthan120}</div>{' '}
      </td>
      <td className={`${classes.colValue} ${classes.greyBorder}`}>
        {' '}
        <div className={classes.bold}>{totals.openAllLessThan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL1morethan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL2morethan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openL3morethan120}</div>{' '}
      </td>
      <td className={classes.colValue}>
        {' '}
        <div className={classes.bold}>{totals.openAllMoreThan120}</div>{' '}
      </td>
    </tr>
  );
};

const ReportTable = (props) => {
  return (
    <div style={{ display: 'block', overflowX: 'auto' }}>
      <table
        className={classes.grievanceReportTable}
        data-test-id="grievance-table"
      >
        <thead data-test-id="grievance-table-head">
          <tr>
            <th colSpan={4}></th>
            <th
              colSpan={4}
              className={`${classes.mainTitleWrapper} ${classes.blueBackground}`}
              data-test-id="close-request-table-head"
            >
              <div className={classes.colMainTitle}>Closed Requests</div>
              <div className={classes.colSubMainTitle}>(Received a response)</div>
            </th>
            <th
              colSpan={4}
              className={`${classes.mainTitleWrapper} ${classes.greenBackground}`}
              data-test-id="all-open-request-table-head"
            >
              <div className={classes.colMainTitle}>All open requests</div>
              <div className={classes.colSubMainTitle}>(Need a response)</div>
            </th>
            <th
              colSpan={4}
              className={`${classes.mainTitleWrapper} ${classes.yellowBackground}`}
              data-test-id="open-less-tan-3-days-table-head"
            >
              <div className={classes.colMainTitle}>Open less than 3 days</div>
            </th>
            <th
              colSpan={4}
              className={`${classes.mainTitleWrapper} ${classes.greyBackground}`}
              data-test-id="open-3-5-days-head-table"
            >
              <div className={classes.colMainTitle}>Open 3-5 days</div>
            </th>
            <th
              colSpan={4}
              className={`${classes.mainTitleWrapper} ${classes.redBackground}`}
              data-test-id="open-more-than-5-days-table-head"
            >
              <div className={classes.colMainTitle}>Open more than 5 days</div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={4} className={classes.categoryTitle}>
            Category
            </td>
            {tableSubHeaderSkeleton(classes.blueBorder)}
            {tableSubHeaderSkeleton(classes.greenBorder)}
            {tableSubHeaderSkeleton(classes.greyBorder)}
            {tableSubHeaderSkeleton(classes.greyBorder)}
            {tableSubHeaderSkeleton()}
          </tr>
          {getReportTable(props)}
          {getTotalsRow(props)}
        </tbody>
      </table>
    </div>
  );
};

const ExportButton = (props) => {
  const { exportBtnHandler, isExportInProcess } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportType, setExportType] = useState('csv');
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const exportTypeHandler = (e) => {
    setExportType(e.target.value);
  };
  const open = Boolean(anchorEl);
  return (
    <div className={classes.exportBtnWrapper}>
      {isExportInProcess
        ? (
          <span className={classes.exportBtnMessage}>
          Once exporting is complete, downloading will begin automatically.
          </span>
        )
        : null}
      <Button
        data-test-id="export-btn"
        disabled={isExportInProcess}
        aria-owns={open ? 'exportBtn-popover' : undefined}
        aria-haspopup="true"
        className={classes.exportBtn}
        onClick={handleClick}
      >
        {isExportInProcess
          ? (
            <div className={`loader-btn ${classes.btnLoader}`}></div>
          )
          : (
            <img src={ExportIcon} />
          )}
        {isExportInProcess ? 'Export is in progress' : 'Export'}
      </Button>
      <Popover
        id="exportBtn-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorPosition={{ top: 200, left: 400 }}
      >
        <Box sx={{
          width: '240px',
          background: '#ffffff',
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
          borderRadius: '8px'
        }}>
          <Box sx={{
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'padding': '12px 16px',
            'borderBottom': '1px solid #D3D3D3',

            '& .title': {
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '22px',
              color: '#000000'
            }
          }}>
            <span className={classes.title}>Export Report</span>
            <Box
              component='span'
              onClick={handleClose}
              sx={ {
                color: 'rgba(51, 51, 51, 0.8)',
                cursor: 'pointer'
              }}
            >
              <Icon icon="Close" />
            </Box>
          </Box>
          <Box sx={{
            padding: '12px'
          }}>
            <FormControl component="fieldset" className={classes.formControl}>
              <Box
                sx={{
                  color: '#333333',
                  fontSize: '14px',
                  lineHeight: '19px',
                  marginBottom: '3px'
                }}
              >
                Choose file format to export
              </Box>
              <RadioGroup
                aria-label="gender"
                name="gender2"
                className={classes.group}
                value={exportType}
                onChange={exportTypeHandler}
              >
                <FormControlLabel
                  value="csv"
                  control={
                    <Radio
                      data-test-id="csv-radio"
                      color="primary"
                      className={classes.radioButton}
                    />
                  }
                  label="CSV"
                  labelPlacement="end"
                  sx={{
                    color: '#333333',
                    fontSize: '14px',
                    lineHeight: '19px',
                    marginBottom: '3px'
                  }}
                />
                <FormControlLabel
                  value="xls"
                  control={
                    <Radio
                      data-test-id="xls-radio"
                      color="primary"
                      className={classes.radioButton}
                    />
                  }
                  label="XLS"
                  labelPlacement="end"
                  sx={{
                    color: '#333333',
                    fontSize: '14px',
                    lineHeight: '19px',
                    marginBottom: '3px'
                  }}
                />
                {/* TODO: Uncomment the code when pdf export is supported */}
                {/* <FormControlLabel
                        value="pdf"
                        control={<Radio data-test-id="pdf-radio" color="primary" className={classes.radioButton} />}
                        label="PDF"
                        labelPlacement="end"
                        className={classes.exportOptionValueLabel}
                        /> */}
              </RadioGroup>
            </FormControl>
            <Button
              data-test-id="export-report-button"
              onClick={() => {
                handleClose();
                exportBtnHandler(exportType);
              }}
              color='primary'
              variant='contained'
            >
              {' '}
              Export Report{' '}
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

const GrievanceReport = (props) => {
  const { data, isSeperateByFacility } = props;
  const tabs = data ? Object.keys(data) : [];
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, v) => {
    setTabValue(v);
  };

  return (
    <Root>
      <div className={classes.reportContainer}>
        <div>
          {data && Object.keys(data).length > 0 && <ExportButton {...props} />}
        </div>
        {isSeperateByFacility && (
          <div className={classes.tabContainer}>
            <AppBar
              position="static"
              classes={{ root: classes.headerTabRoot }}
              data-test-id="grievance-reports-header"
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                classes={{ indicator: classes.tabIndicator }}
                data-test-id="grievance-reports-tabs"
              >
                {tabs &&
                tabs.length &&
                tabs.map((t) => (
                  <Tab
                    data-test-id="grievance-reports-tab"
                    key={`tab-${t}`}
                    label={t}
                    classes={{
                      root: classes.tabRoot,
                      labelContainer: classes.tabLabelContainer
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
          </div>
        )}

        {data && data[tabs[tabValue]] && (
          <ReportTable {...props} data={data[tabs[tabValue]]} />
        )}
      </div>
    </Root>
  );
};

export default (GrievanceReport);
