import React, { useCallback, useMemo } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { useUserContext } from '../UserContext';
import toast from 'react-hot-toast';
import { stringToBase64Url } from '../../../Lib/util';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import Data from '../../../Middleware/Data';
import { useMutation } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';

import BanUserIcon from '../../../Assets/ban_user.svg';
import DisableUserIcon from '../../../Assets/disable_user.svg';
import ViewAuditLogIcon from '../../../Assets/view_audit_log.svg';
import ViewLearningRecordsIcon from '../../../Assets/view_learning_records.svg';
import ViewLiveActivityIcon from '../../../Assets/view_live_activity.svg';
import ViewUserGrievancesIcon from '../../../Assets/view_user_grievances.svg';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import useUserPermissions from '../../../hooks/useUserPermissions';
import UnbanUserModalContent from '../../../Components/User/UnbanUserModalContent';
import BanUserModalContent from '../../../Components/User/BanUserModalContent';
import { EnableOrDisableUser } from '../../../Components/UserSettings/UsersTable';
import { LEARNING_RECORDS_PATH } from '../../../constants/paths';

const UserActions = {
  Enable: {
    key: 'enable_user',
    title: 'Activate User',
    icon: DisableUserIcon
  },
  Disable: {
    key: 'disable_user',
    title: 'Deactivate User',
    icon: DisableUserIcon
  },
  Ban: {
    key: 'ban_user',
    title: 'Ban User',
    icon: BanUserIcon,
    color: '#FC5130'
  },
  Unban: {
    key: 'unban_user',
    title: 'Unban User',
    icon: BanUserIcon,
    color: '#FC5130'
  },
  ViewAuditLogs: {
    key: 'view_audit_logs',
    title: 'View Audit Logs',
    icon: ViewAuditLogIcon
  },
  ViewLiveActivity: {
    key: 'view_live_activity_feed',
    title: 'View Live Activity Feed',
    icon: ViewLiveActivityIcon
  },
  ViewLearningRecords: {
    key: 'view_learning_records',
    title: 'View Learning Records',
    icon: ViewLearningRecordsIcon
  },
  ViewUserGrievances: {
    key: 'view_user_grievances',
    title: 'View User Grievances',
    icon: ViewUserGrievancesIcon
  }
};

const QuickActionsList = () => {
  const permissions = useUserPermissions();

  const userContext = useUserContext();
  const { user, refetchUser } = userContext;

  // const [auditLogsModalVisible, setAuditLogsModalVisible] = useState(false);

  const bulkActivateUserMutation = useMutation(({ uids }) =>
    Data.bulkReactivateUsers(uids).then(GenericErrorDetectorForMutations)
  );
  const bulkDeActivateUserMutation = useMutation(({ uids, internalNote }) =>
    Data.bulkDeactivateUsers(uids, internalNote).then(
      GenericErrorDetectorForMutations
    )
  );

  const unbanLearnerMutation = useMutation((data) =>
    Data.unbanLearner(data).then(GenericErrorDetectorForMutations)
  );

  const banLearnerMutation = useMutation((data) =>
    Data.banLearner(data).then(GenericErrorDetectorForMutations)
  );

  const openLiveActivity = useCallback((uid) => {
    const filters = {
      learners: [uid],
      pods: [],
      facilities: [],
      liveLearners: false
    };

    const url = `${
      window.location.origin
    }/investigative-tools/live-activity/?filters=${stringToBase64Url(
      JSON.stringify(filters)
    )}&selectedUser=${stringToBase64Url(uid)}`;

    window.open(url, '_blank').focus();
  }, []);

  const viewLearnerGrienvances = useCallback(() => {
    const filters = [
      {
        key: 'assignee',
        value: 'ALL'
      },
      {
        key: 'learnerIds',
        value: user.uid,
        meta: {
          label: `${user.firstName} ${user.lastName}`
        }
      }
    ];

    const searchParams = new URLSearchParams();
    searchParams.set('filters', JSON.stringify(filters));

    const url = `${
      window.location.origin
    }/forms/admin/grievances?${searchParams.toString()}`;

    window.open(url, '_blank').focus();
  }, []);

  const viewLearningRecords = useCallback((userId) => {
    const url = `${window.location.origin}${LEARNING_RECORDS_PATH}/user/${userId}/learner-record`;

    window.open(url, '_blank').focus();
  }, []);

  const onUserEnableToggle = (action, userId, userName) => {
    const buttonLabel =
      action === UserActions.Enable.key ? 'Activate' : 'Deactivate';
    const ctaColor = action === UserActions.Enable.key ? '#4C741F' : '#C03223';
    const mutation =
      action === UserActions.Enable.key
        ? bulkActivateUserMutation
        : bulkDeActivateUserMutation;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <EnableOrDisableUser
            action={
              {
                [UserActions.Enable.key]: 'enable',
                [UserActions.Disable.key]: 'disable'
              }[action]
            }
            buttonLabel={buttonLabel}
            ctaColor={ctaColor}
            mutation={mutation}
            onClose={onClose}
            userIds={[userId]}
            userName={userName}
            usersQuery={userContext.userQuery}
          />
        );
      }
    });
  };

  const onUnban = useCallback(
    (userUids) => {
      const loadingId = toast.loading('Unbanning users...');

      unbanLearnerMutation.mutate(
        { userUids },
        {
          onSuccess: () => {
            toast.success('User successfully unbanned.');

            refetchUser();
          },
          onError: () => {
            toast.error('Failed to unban user.');
          },
          onSettled: () => {
            toast.dismiss(loadingId);
          }
        }
      );
    },
    [unbanLearnerMutation]
  );

  const onBan = useCallback(
    (userUid) => {
      return ({
        periodInDays,
        banIndefinitely,
        internalNote,
        noteForLearner
      }) => {
        const loadingId = toast.loading('Banning users...');

        banLearnerMutation.mutate(
          {
            userUid,
            periodInDays,
            banIndefinitely,
            internalNote,
            noteForLearner
          },
          {
            onSuccess: () => {
              toast.success('User successfully banned.');

              refetchUser();
            },
            onError: () => {
              toast.error('Failed to ban user.');
            },
            onSettled: () => {
              toast.dismiss(loadingId);
            }
          }
        );
      };
    },
    [banLearnerMutation]
  );

  const onActionClick = (actionName) => {
    const userId = user.uid;
    const userDisplayName = `${user.firstName} ${user.lastName}`;

    switch (actionName) {
    case UserActions.Enable.key:
    case UserActions.Disable.key:
      onUserEnableToggle(
        actionName,
        userId,
        `${user.firstName} ${user.lastName}`
      );
      break;

    case UserActions.Ban.key: {
      confirmAlert({
        customUI: ({ onClose }) => (
          <BanUserModalContent
            userDisplayName={userDisplayName}
            onClose={onClose}
            onSuccess={onBan(userId)}
          />
        )
      });

      break;
    }

    case UserActions.Unban.key: {
      confirmAlert({
        customUI: ({ onClose }) => (
          <UnbanUserModalContent
            userDisplayName={userDisplayName}
            onClose={onClose}
            onSuccess={() => onUnban([userId])}
          />
        )
      });

      break;
    }

    // case UserActions.ViewAuditLogs.key: {
    //   setAuditLogsModalVisible(true);

    //   break;
    // }

    case UserActions.ViewLiveActivity.key: {
      openLiveActivity(userId);

      break;
    }

    case UserActions.ViewLearningRecords.key: {
      viewLearningRecords(userId);

      break;
    }

    case UserActions.ViewUserGrievances.key: {
      viewLearnerGrienvances();

      break;
    }

    default:
      break;
    }
  };

  const userActions = useMemo(
    () => [
      {
        action: UserActions.Enable,
        getPermission: (user) =>
          !user.active && permissions.canActivateOtherUser()
      },
      {
        action: UserActions.Disable,
        getPermission: (user) =>
          user.active && permissions.canDeactivateOtherUser()
      },
      {
        action: UserActions.Ban,
        getPermission: (user) =>
          user.Role.name === 'learner.default' &&
          !user.isBanned &&
          permissions.canActivateOtherUser(),
        color: UserActions.Unban.color
      },
      {
        action: UserActions.Unban,
        getPermission: (user) =>
          user.Role.name === 'learner.default' &&
          user.isBanned &&
          permissions.canDeactivateOtherUser()
      },
      {
        action: UserActions.ViewLiveActivity,
        getPermission: (user) =>
          user.Role.name === ACTIVE_ROLES.LEARNER &&
          permissions.canGoToLiveActivity()
      },
      {
        action: UserActions.ViewLearningRecords,
        getPermission: (user) =>
          user.Role.name === ACTIVE_ROLES.LEARNER &&
          permissions.canViewLearningRecords()
      },
      {
        action: UserActions.ViewUserGrievances,
        getPermission: (user) =>
          user.Role.name === ACTIVE_ROLES.LEARNER &&
          permissions.canGoToGrievances()
      }
      // {
      //   action: UserActions.ViewAuditLogs,
      //   getPermission: (user) => true
      // },
    ],
    [permissions]
  );

  const filteredUserActions = useMemo(
    () => userActions.filter((action) => action.getPermission(user)),
    [userActions, user]
  );

  if (!filteredUserActions.length) {
    return null;
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Quick Actions
          </Typography>
        </Grid>

        <Grid item>
          {filteredUserActions.map(({ action }) => (
            <div key={action.name} className="mb-1">
              <Button
                size="small"
                onClick={() => onActionClick(action.key)}
                style={{
                  color: action.color || '#449FFF',
                  textAlign: 'left',
                  fontSize: 14
                }}
              >
                <img src={action.icon} style={{ marginRight: 8 }} />
                {action.title}
              </Button>
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default QuickActionsList;
