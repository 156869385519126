import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Divider, styled } from '@mui/material';
import MessageLearnerUser from './MessageLearnerUser';
import DataStore from '../../../../../Stores/DataStore';
import LoadingComponent from '../../../../../Components/Widgets/LoadingComponent';
import MessageDetail from './MessageDetail';
import HeaderSubPanel from '../../../../../Components/HeaderSubPanel';
import { ALL_MESSAGES_LIST_PATH, MESSENGER_ADMIN_PATH } from '../../../../../constants/paths';

const PREFIX = 'MessageDetails';
const classes = {
  detailHeader: `${PREFIX}-detailHeader`,
  link: `${PREFIX}-link`,
  paperBaseStyle: `${PREFIX}-paperBaseStyle`,
  flaggedKeyword: `${PREFIX}-flaggedKeyword`
};

const Root = styled('div')(() => ({
  [`& .${classes.detailHeader}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  [`& .${classes.link}`]: {
    color: '#449FFF !important',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  [`& .${classes.paperBaseStyle}`]: {
    padding: 30,
    borderRadius: '12px',
    boxShadow: '0 10px 40px 0 rgba(64, 72, 82, 0.07)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  [`& .${classes.flaggedKeyword}`]: {
    color: 'red',
    fontWeight: '500'
  }
}));

const breadcrumbLinks = (name) => [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: ALL_MESSAGES_LIST_PATH, label: 'Users' },
  { href: '#', label: name }
];

const MessageDetails = () => {
  const { setPageTitleDetail, getMessage } = DataStore;
  const [message, setMessage] = useState(null);
  const [getMessageError, setGetMessageError] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  useEffect(
    () => {
      const fetchMessage = async () => {
        if (!id) {
          history.push('/messenger/messages');
        } else {
          try {
            const response = await getMessage(id);
            if (!response.success) {
              setGetMessageError(true);
            } else {
              setMessage(response.message);
              setPageTitleDetail(`Messenger Admin > Messages > #${id}`);
            }
          } catch (e) {
            setGetMessageError(true);
          }
        }
      };
      fetchMessage();
    },
    [id, getMessage]
  );

  useEffect(() => {
    return () => setPageTitleDetail(null);
  }, [setPageTitleDetail]);

  if (getMessageError) {
    return <h2>Message details not available</h2>;
  } else if (!message) {
    return <LoadingComponent />;
  } else {
    return (
      <Box>
        <HeaderSubPanel sx={{ mb: 3 }} title={`#${message.id}`} links={breadcrumbLinks(message.id)} />
        <Root className='user-update'>
          <Box component="section" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h1 style={{ display: 'flex' }}>
              <div>{`Message #${message.id}`} </div>
            </h1>
          </Box>
          <Divider />
          <section className='section-user-info'>
            {
              message.UserUid
                ? <MessageLearnerUser
                  classes={classes}
                  user={message.ApprovedMessaging.User}
                  sender={true}
                />
                : null
            }
            <Divider style={{ margin: '42px 0' }} />
            <MessageDetail classes={classes} message={message} />

          </section>
        </Root>
      </Box>
    );
  }
};

export default (observer(MessageDetails));
