import { passwordMinimumLength } from '../../Lib/CONSTANTS';
export function validateString (str, isRequired, min, max) {
  if (!str && isRequired) {
    return 'Required';
  }
  const trimmedString = str.trim();
  const stringMinimumError = min
    ? trimmedString.length >= min
      ? undefined
      : `Must be at least ${min} characters`
    : undefined;
  const stringMaximumError = max
    ? trimmedString.length <= max
      ? undefined
      : `Must be no longer than ${max} characters`
    : undefined;
  const stringLengthError =
    stringMinimumError || stringMaximumError || undefined;
  const error = isRequired
    ? !trimmedString ? 'Required' : stringLengthError
    : !trimmedString ? undefined : stringLengthError;
  return error;
}

export function validateSpecialCharacterInString (str) {
  if (/[!@#$%/:;"_+=?><~`,^&*)(]+/.test(str)) {
    return 'Field can only contain following special characters: dot(.) and hyphen(-)';
  }
}

export function validateStringNotToStartWithNumber (str) {
  if ((/^[0-9]/.test(str))) {
    return 'Field can not start with number';
  }
}

export function validateStringNotToStartWithSpecialCharacter (str) {
  if (/^[^A-Za-z0-9]/.test(str)) {
    return 'Field cannot start with a special character';
  }
}

export function validateUsername (str) {
  if (/[!@#$%/:;".+=?><~`,^&*)(\s]+/.test(str)) {
    return 'Field can only contain following special characters: hyphen(-) and underscore(_)';
  }
}

export function validateEmail (email, isRequired) {
  const validator = /^(([^<>^%()[\]\\.,;:\s@"]+(\.[^<>^%()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const typeError = email.toLowerCase().match(validator)
    ? undefined
    : 'Not a valid email address';
  const error = isRequired && !email
    ? 'Required'
    : !email || email.length < 1 ? undefined : typeError;
  return error;
}

export function validatePassword (password, passwordConfirm, isRequired) {
  const passwordRequiredErrorMessage = 'Required';
  const passwordLengthErrorMessage = `Password must be at least ${passwordMinimumLength} characters long`;
  const passwordMatchErrorMessage = 'Passwords do not match';

  function doPasswordsMatch () {
    return password === passwordConfirm;
  }

  function doesPasswordMeetLengthRequirement () {
    return password.length >= passwordMinimumLength;
  }

  function getPasswordValidationError (confirmed) {
    return confirmed
      ? doPasswordsMatch()
        ? doesPasswordMeetLengthRequirement()
          ? undefined
          : passwordLengthErrorMessage
        : passwordMatchErrorMessage
      : doesPasswordMeetLengthRequirement()
        ? undefined
        : passwordLengthErrorMessage;
  }

  const error =
    (!!password && (!!passwordConfirm || passwordConfirm === '')) || (!!passwordConfirm && !password)
      ? getPasswordValidationError(true)
      : password
        ? getPasswordValidationError(false)
        : isRequired ? passwordRequiredErrorMessage : undefined;
  return error;
}
