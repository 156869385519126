import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Divider, Tab, Tabs } from '@mui/material';

import { UserContextProvider, useUserContext } from './UserContext';
import useUserPermissions from '../../hooks/useUserPermissions';
import { ACTIVE_ROLES } from '../../Lib/CONSTANTS';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';

import { UserDetails } from './UserDetails/UserDetails';
import GrievanceLimit from './GrievanceLimit/GrievanceLimit';
import UserApplications from './ApplicationAvailable/UserApplications';
import FaceCaptureLogs from './FaceCaptureLogs/FaceCaptureLogs';
import DataStore from '../../Stores/DataStore';
import AuditLogsModal from './UserDetails/AuditLogsModal';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { USER_PATH } from '../../constants/paths';

const TABS = {
  USER_DETAILS: {
    label: 'User Details',
    value: 'details'
  },
  GRIEVANCE_LIMIT: {
    label: 'Grievance Limit',
    value: 'g_limit'
  },
  APPS_AVAILABLE: {
    label: 'Applications Available',
    value: 'apps'
  },
  FACE_CAPTURE_LOGS: {
    label: 'Face Capture Logs',
    value: 'face_capture_logs'
  },
  AUDIT_LOGS: {
    label: 'Audit Logs',
    value: 'audit_logs'
  }
};

const breadcrumbLinks = (name) => [
  { href: USER_PATH, label: 'User & Group Settings' },
  { href: '#', label: name }
];

const _UserLayout = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userCTX = useUserContext();
  const permissions = useUserPermissions();
  const canViewFaceCaptureLogs = permissions.canViewFaceCaptureLogs();

  const [activeTab, setActiveTab] = useState(() => {
    let activeTab = TABS.USER_DETAILS.value;
    const currentTab = searchParams.get('tab');

    const tab = Object.values(TABS).find((e) => e.value === currentTab);
    if (tab) {
      activeTab = tab.value;
    }

    const qs = new URLSearchParams({ tab: activeTab });
    history.replace({ pathname: location.pathname, search: qs.toString() });

    return activeTab;
  });

  useEffect(() => {
    setTimeout(() => {
      if (userCTX.user) {
        DataStore.setPageTitleDetail(
          `${userCTX.user.firstName} ${userCTX.user.lastName}`
        );
      }
    }, 0);
  }, [userCTX.user, activeTab]);

  function handleTabChange (_, value) {
    setActiveTab(value);

    const qs = new URLSearchParams({ tab: value });
    history.replace({ pathname: location.pathname, search: qs.toString() });
  }

  const isCurrentUserLearner =
    userCTX.user &&
    userCTX.user.Role &&
    userCTX.user.Role.name === ACTIVE_ROLES.LEARNER;

  return (
    <div>
      <LoadingAndErrorHandler
        isError={userCTX.isError}
        isLoading={userCTX.isLoading}
        isSuccess={userCTX.isSuccess}
      >
        {() => (
          <>
            <HeaderSubPanel
              sx={{ mb: 3 }}
              links={breadcrumbLinks(`${userCTX.user.firstName} ${userCTX.user.lastName}`)}
              title={`${userCTX.user.firstName} ${userCTX.user.lastName}`}
            />
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab
                style={{ textAlign: '', minWidth: '70px' }}
                classes={{ labelContainer: 'course-library__tab-label' }}
                label={TABS.USER_DETAILS.label}
                value={TABS.USER_DETAILS.value}
              />

              {permissions.canSetGrievanceLimit() && isCurrentUserLearner
                ? (
                  <Tab
                    style={{ textAlign: '', minWidth: '70px' }}
                    classes={{ labelContainer: 'course-library__tab-label' }}
                    label={TABS.GRIEVANCE_LIMIT.label}
                    value={TABS.GRIEVANCE_LIMIT.value}
                  />
                )
                : null}

              {permissions.canSetApplicationAccess() && isCurrentUserLearner
                ? (
                  <Tab
                    style={{ textAlign: '', minWidth: '70px' }}
                    classes={{ labelContainer: 'course-library__tab-label' }}
                    label={TABS.APPS_AVAILABLE.label}
                    value={TABS.APPS_AVAILABLE.value}
                  />
                )
                : null}

              {canViewFaceCaptureLogs && isCurrentUserLearner
                ? (
                  <Tab
                    style={{ textAlign: '', minWidth: '70px' }}
                    classes={{ labelContainer: 'course-library__tab-label' }}
                    label={TABS.FACE_CAPTURE_LOGS.label}
                    value={TABS.FACE_CAPTURE_LOGS.value}
                  />
                )
                : null}

              {permissions.canViewAuditLogs()
                ? (
                  <Tab
                    style={{ textAlign: '', minWidth: '70px' }}
                    classes={{ labelContainer: 'course-library__tab-label' }}
                    label={TABS.AUDIT_LOGS.label}
                    value={TABS.AUDIT_LOGS.value}
                  />
                )
                : null}
            </Tabs>

            <Divider />

            <div className="nucleos-core">
              <div className="mt-4">
                {activeTab === TABS.USER_DETAILS.value ? <UserDetails /> : null}

                {permissions.canSetGrievanceLimit() &&
                userCTX.user.Role.name === ACTIVE_ROLES.LEARNER &&
                activeTab === TABS.GRIEVANCE_LIMIT.value
                  ? (
                    <GrievanceLimit />
                  )
                  : null}

                {permissions.canSetApplicationAccess() &&
                activeTab === TABS.APPS_AVAILABLE.value
                  ? (
                    <UserApplications />
                  )
                  : null}

                {canViewFaceCaptureLogs &&
                activeTab === TABS.FACE_CAPTURE_LOGS.value
                  ? (
                    <FaceCaptureLogs userId={userCTX.user.uid} />
                  )
                  : null}

                {activeTab === TABS.AUDIT_LOGS.value
                  ? (
                    <AuditLogsModal isOpen={true} close={() => null} />
                  )
                  : null}
              </div>
            </div>
          </>
        )}
      </LoadingAndErrorHandler>
    </div>
  );
};

export const UserLayout = () => (
  <UserContextProvider>
    <_UserLayout />
  </UserContextProvider>
);
