import React /*, { useEffect, useState } */ from 'react';
import { Link } from 'react-router-dom';
import FlexTileGrid from '../../../Components/Shared/TileGrid/TileGrid';
import messageIcon from '../../../Assets/msgr_messages.svg';
import usersIcon from '../../../Assets/msgr_users.svg';
import DataStore from '../../../Stores/DataStore';
import { Badge, Box, styled } from '@mui/material';
import { Icon } from '../../../Components/Shared/Icon';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';
import { MESSAGE_USER_PATH } from '../../../constants/paths';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import useUserPermissions from '../../../hooks/useUserPermissions';

const baseRoute = '/messenger/admin';

const adminFeatures = [
  {
    name: 'Users',
    icon: usersIcon,
    link: `${baseRoute}/users`,
    allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR]
  },
  {
    name: 'Messages',
    icon: messageIcon,
    link: `${baseRoute}/messages`,
    allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR]
  },
  {
    name: 'Message Alerts',
    icon: messageIcon,
    link: `${baseRoute}/messageAlerts`,
    getNotifications: DataStore.getMessageAlertsCount,
    allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR]
  }
];

if (!window.nucleosConfig.disableConversationView) {
  adminFeatures.push({
    name: 'Message Users',
    icon: messageIcon,
    link: MESSAGE_USER_PATH,
    allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER]
  });
}

const StyledBadge = styled(Badge)({
  top: 17
});

const MessengerAdmin = () => {
  const userPermissions = useUserPermissions();

  // const [features, setFeatures] = useState([]);

  const fetchNotifications = () => {
    for (const feature of adminFeatures) {
      if (feature.getNotifications) {
        feature.notificationCount = (feature.getNotifications()).count;
      }
    }

    return adminFeatures.filter((feature) => feature.allowedRoles.includes(userPermissions.role)).map(({ name, icon, link, notificationCount }, i) => (
      <Link to={link} key={i} style={{ margin: '0 auto' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            whiteSpace: '',
            justifyContent: 'center'
          }}
        >
          {notificationCount
            ? <div style={{ color: '#FF9900', display: 'flex', alignItems: 'center' }}>
              <Icon icon="Warning" style={{ width: '0.8em' }} /> <span style={{ marginLeft: '3px', paddingTop: '2px' }}>New Alerts</span>
            </div>
            : ''
          }
          <div style={{ color: '#3899FF', display: 'inline' }}>
            {notificationCount
              ? <StyledBadge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                max={999}
                badgeContent={notificationCount}
                color='secondary'
              >
                <img src={icon} alt='' />
              </StyledBadge>
              : <img src={icon} alt='' height={70} width={70}/>
            }

          </div>
          <h2 style={{ fontSize: '18px' }}> {name} </h2>
        </div>
      </Link>
    ));

    // setFeatures(features);
  };

  // useEffect(() => {
  //   (async () => {
  //     await fetchNotifications();
  //   })();
  // }, []);

  return (
    <Box>
      <HeaderSubPanel title="Messenger Admin" sx={{ mb: 3 }} />
      <div className='home'>
        <FlexTileGrid tileStyle={{ padding: '25px' }} spacing={4}>{fetchNotifications()}</FlexTileGrid>
      </div>
    </Box>
  );
};

export default MessengerAdmin;
