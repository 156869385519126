import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react';
import { Dialog, Typography, Button } from '@nucleos/core-ui';

import './learning-records.scss';
import LearnerRecords from '../LearnerRecords/LearnerRecords';
import {
  useHistory,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import DataStore from '../../Stores/DataStore';
import AuthenticationStore from '../../Stores/Authentication';
import UsageHistory from '../UsageHistory';
import { LearningOverview } from '../LearningOverview';
import UniversalUserSearch from '../../Components/Widgets/UserSearch/UniversalUserSearch';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import { getDays } from '../../Lib/util';
import toast from 'react-hot-toast';
import { Icon } from '../../Components/Shared/Icon';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { LEARNING_RECORDS_PATH } from '../../constants/paths';

export const LearnerRecordsOptionKeys = {
  LearningRecords: 'learning_records',
  ProgressAndGoals: 'progress_and_goals',
  UsageHistory: 'usage_history'
};

export const exportOptions = [
  {
    label: 'Learning Records',
    description: '',
    key: LearnerRecordsOptionKeys.LearningRecords
  },
  {
    label: 'Progress and Goals',
    description: '',
    key: LearnerRecordsOptionKeys.ProgressAndGoals
  },
  {
    label: 'Usage History',
    description: '',
    key: LearnerRecordsOptionKeys.UsageHistory
  }
];

function SaveAsPDFDialog ({ isOpen, onClose, getPDF }) {
  const [optionsEnabled, setOptionsEnabled] = useState(
    exportOptions.reduce((acc, option) => {
      return { ...acc, [option.key]: true };
    }, {})
  );

  const generatePDF = () => {
    setTimeout(() => {
      onClose();

      toast.loading('The PDF can take some time to be generated. The download will start automatically post that. Thank you for your patience.', {
        duration: 5000,
        style: {
          maxWidth: '70%'
        }
      });
    }, 200);

    getPDF(optionsEnabled);
  };

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        title="Save as PDF"
      >
        {exportOptions.map((option) => (
          <div className="flex items-center my-4" key={option.key}>
            <div className="mr-4">
              <Checkbox
                sx={{ padding: '2px' }}
                checked={optionsEnabled[option.key]}
                onChange={(_, isEnabled) => {
                  setOptionsEnabled({
                    ...optionsEnabled,
                    [option.key]: isEnabled
                  });
                }}
              />
            </div>
            <div className="">
              <Typography variant="h6">{option.label}</Typography>
              <Typography variant="body2">{option.description}</Typography>
            </div>
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            onClick={generatePDF}
            color="primary"
            variant="contained"
          >
            Generate PDF
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const [_, start, end] = getDays()[0].val.split('|');

function LearningRecords () {
  const history = useHistory();
  const { uid } = useParams();
  const [selectedFilters, setSelectedFilters] = useState({});

  const [startDate, setStartDate] = useState(selectedFilters.selectedStartDate || start);
  const [endDate, setEndDate] = useState(selectedFilters.selectedEndDate || end);
  const [timePeriod, setTimePeriod] = useState(selectedFilters.selectedTimePeriod || 'month');
  const [timeSelect, setTimeSelect] = useState(selectedFilters.selectedTimeSelect || getDays()[0].val);

  const [selectedApps, setSelectedApps] = useState(selectedFilters.selectedApplications || []);

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const [saveAsPDFModalOpen, setSaveAsPDFModalOpen] = useState(false);

  const [selectedTab, setSelectedTab] = useState(
    window.location.pathname.includes('learner-record')
      ? 1
      : window.location.pathname.includes('learning-overview')
        ? 2
        : window.location.pathname.includes('usage-history')
          ? 3
          : 1
  );
  const tabToUrlMap = {
    1: 'learner-record',
    2: 'learning-overview',
    3: 'usage-history'
  };
  const { currentUser, getLearningRecordsLoading } = DataStore;
  const { userRole, uid: learnerUid } = AuthenticationStore;
  const isLearner = userRole === 'learner';
  const isAdmin = userRole === 'admin';

  function setUser (user) {
    if (!user) { return; }
    const { uid } = user;
    DataStore.getUser(uid);
    history.push(`${LEARNING_RECORDS_PATH}/user/${uid}/learner-record`);
  }

  async function getPdf (enabledOptions) {
    setIsGeneratingPDF(true);

    const options = {
      credentials: 'include',
      method: 'GET'
    };

    const search = new URLSearchParams();
    search.set('from', startDate);
    search.set('to', endDate);
    search.set('timePeriod', timePeriod);
    search.set('apps', JSON.stringify(selectedApps.map(app => app.value)));
    search.set('enabledOptions', JSON.stringify(enabledOptions));

    try {
      const response = await fetch(process.env.REACT_APP_PUBLIC_URL + `/user/${uid}/pdf?${search.toString()}`, options);
      const pdfBlob = await response.blob();

      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${currentUser.firstName}${currentUser.lastName}_LearningRecord.pdf`;

      document.body.appendChild(a);
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);

      toast.error('Failed to generate PDF.');
    } finally {
      setIsGeneratingPDF(false);
    }
  }

  useEffect(() => {
    if (isLearner) {
      setUser({ uid: learnerUid });
    }
  }, []);

  return (
    <Box>
      <HeaderSubPanel
        title='Learner Records' sx={{ mb: 3 }}
      />
      <div>
        {!isLearner && (
          <div className="nucleos-core">
            <div className="flex items-center pb-5 mb-5 border-b">
              <Typography variant="h4" className="mr-8">
              Record Lookup:{' '}
              </Typography>
              <div className="user-search-input rounded-full w-96">
                <UniversalUserSearch
                  Icon={
                    <Icon icon="Account"
                      style={{ paddingRight: 6, fill: '#3899FF' }}
                    />
                  }
                  onUserSelect={setUser}
                  userId={uid}
                  learnerOnly
                />
              </div>
            </div>
          </div>
        )}

        {!isLearner && !currentUser.uid && getLearningRecordsLoading ? <LoadingComponent /> : null}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <Tabs
              style={{
                display: !isLearner && !uid ? 'none' : 'block'
              }}
              value={selectedTab}
              onChange={(_, tab) => {
                setSelectedTab(tab);
                setSelectedFilters({
                  selectedStartDate: startDate,
                  selectedEndDate: endDate,
                  selectedTimePeriod: timePeriod,
                  selectedTimeSelect: timeSelect,
                  selectedApplications: selectedApps
                });
                history.push(`${LEARNING_RECORDS_PATH}/user/${uid}/${tabToUrlMap[tab]}`);
              }}
            >
              <Tab label="Learning Records" value={1} />
              <Tab label="Progress & Goals" value={2} />
              <Tab label="Usage History" value={3} />
            </Tabs>
            <Divider sx={{ mt: '-8px' }} />
          </div>
          <div>
            {uid && isAdmin && currentUser && (
              <div className='nucleos-core' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  size='small'
                  disabled={isGeneratingPDF}
                  id='pdfBtn'
                  onClick={() => setSaveAsPDFModalOpen(true)}
                >
                  {isGeneratingPDF ? <div className={'loader-btn mr-2'}></div> : <Icon icon="Pdf" className='mr-2' />}
                  {isGeneratingPDF ? 'Generating PDF...' : 'Save as PDF'}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          {selectedTab === 1 && <LearnerRecords />}
          {selectedTab === 2 && <LearningOverview />}
          {selectedTab === 3 && (
            <UsageHistory
              startDate={startDate}
              endDate={endDate}
              timePeriod={timePeriod}
              timeSelect={timeSelect}
              selectedApps={selectedApps}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimePeriod={setTimePeriod}
              setTimeSelect={setTimeSelect}
              setSelectedApps={setSelectedApps}
            />
          )}
        </div>

        <SaveAsPDFDialog isOpen={saveAsPDFModalOpen} onClose={() => setSaveAsPDFModalOpen(false)} getPDF={getPdf} />
      </div>

    </Box>
  );
}

export default observer(LearningRecords);
