import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const SortOrder = {
  None: 'NONE',
  Ascending: 'ASC',
  Descending: 'DESC'
};

export function useTableSorting ({ defaultSorting, prefix = '' }) {
  const location = useLocation();
  const history = useHistory();
  const urlKey = prefix ? `${prefix}-sorting` : 'sorting';
  const params = new URLSearchParams(location.search);
  const columnSorting = params.get(urlKey)
    ? JSON.parse(params.get(urlKey))
    : defaultSorting;

  const _setColumnSorting = (sorting) => {
    const params = new URLSearchParams(location.search);
    params.set(urlKey, JSON.stringify(sorting));
    history.replace({
      search: params.toString()
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get(urlKey)) { _setColumnSorting(columnSorting); }

    // return () => {
    //   const params = new URLSearchParams(location.search);
    //   params.delete(urlKey);
    //   history.replace({
    //     search: params.toString(),
    //   });
    // };
  }, []);

  const setColumnSorting = ({ column, sortOrder }) => {
    // This logic is to make sure that there is sorting applied at any given movement.
    // This will fallback to ascending sort of alert (AGE) column if no sort is applied.
    if (sortOrder === SortOrder.None && column !== defaultSorting.column) { return _setColumnSorting(defaultSorting); }
    if (sortOrder === SortOrder.None && column === defaultSorting.column) {
      return _setColumnSorting({
        ...defaultSorting,
        sortOrder: SortOrder.Ascending
      });
    }
    _setColumnSorting({ column, sortOrder });
  };

  const getCurrentSorting = (column) => {
    return columnSorting.column === column ? columnSorting.sortOrder : 'NONE';
  };

  return {
    columnSorting,
    setColumnSorting,
    getCurrentSorting
  };
}
