import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Divider, Paper, Button, Grid, styled, Box } from '@mui/material';
import LoadingComponent from '../../../Components/Widgets/LoadingComponent';
import DataStore from '../../../Stores/DataStore';
import ToastMessage from '../../../Components/Widgets/ToastMessage';
import {
  Input,
  Switch
} from '../../../Components/Form';
import UserMessages from './UserMessages';
import { Icon } from '../../../Components/Shared/Icon';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';
import { LEARNING_RECORDS_PATH, MESSENGER_ADMIN_PATH, MESSENGER_ADMIN_USERS_PATHS, USER_PATH } from '../../../constants/paths';

const PREFIX = 'MessengerUser';
const classes = {
  detailHeader: `${PREFIX}-detailHeader`
};

const Root = styled('div')(() => ({
  [`& .${classes.detailHeader}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  }
}));

const paperBaseStyle = {
  padding: 30,
  borderRadius: '12px',
  boxShadow: '0 10px 40px 0 rgba(64, 72, 82, 0.07)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

const breadcrumbLinks = (name) => [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: MESSENGER_ADMIN_USERS_PATHS, label: 'Users' },
  { href: '#', label: name }
];

const MessengerUser = () => {
  const { getUser, currentUser, getUserError, setPageTitleDetail } = DataStore;
  const history = useHistory();
  const { uid } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [messengerStatusUpdatedSuccess, setMessengerStatusUpdatedSuccess] = useState(false);
  const [messengerStatusUpdatedError, setMessengerStatusUpdatedError] = useState(false);

  const handleMessengerStatusChange = async (event) => {
    const status = event.target.checked;
    const result = await DataStore.updateUserMessengerStatus(uid, status);
    if (result.success) {
      userDetail.messengerActive = status;
      setMessengerStatusUpdatedSuccess(true);
    } else {
      setMessengerStatusUpdatedError(true);
    }
  };

  useEffect(
    () => {
      const fetchUsers = async () => {
        if (!uid) {
          history.push(USER_PATH);
        } else {
          getUser(uid, false, true);
        }
      };

      fetchUsers();
    },
    [uid, getUser, history]
  );

  useEffect(() => {
    const { username } = currentUser;
    if (username) { setPageTitleDetail('Messenger Admin > Users > ' + username); }

    setUserDetail(currentUser);
    return () => setPageTitleDetail(null);
  }, [setPageTitleDetail, currentUser]);

  if (getUserError) {
    return <h2>No user data available</h2>;
  } else if (!currentUser.uid) {
    return <LoadingComponent />;
  } else {
    return (
      <Box>
        <HeaderSubPanel
          sx={{ mb: 3 }}
          title={`${currentUser.firstName} ${currentUser.lastName}`}
          links={breadcrumbLinks(`${currentUser.firstName} ${currentUser.lastName}`)}
        />
        <Root className='user-update'>
          <Box component="section" sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <h1 style={{ display: 'flex' }}>
              {' '}
              <Icon
                icon="AccountCircle"
                color='primary'
                style={{ fontSize: '50px', marginRight: '20px' }}
              />{' '}
              <div>{`${currentUser.firstName} ${currentUser.lastName}`} </div>
            </h1>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ margin: '10px', marginBottom: '30px' }}>
                <Link to={`${LEARNING_RECORDS_PATH}/user/${uid}/usage-history`}>
                  <Button color='primary' variant='contained'>
                    <Icon icon="Computer" style={{ marginRight: '10px' }} />
                  View Nucleos User
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
          <Divider />
          <section className='section-user-info'>
            <div className={classes.detailHeader}>
              <Box component="h2" sx={{ textDecoration: 'underline' }}>User Details</Box>
              <Button onClick={() => history.push(`${USER_PATH}/${uid}`)}>
                <Icon icon="Edit" style={{ marginRight: 10 }} />
                <span>Edit Contact Details</span>
              </Button>
            </div>

            <Paper style={{ ...paperBaseStyle, maxWidth: '100%' }}>
              <form className='user-form' method='post'>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          label='First Name'
                          name='firstName'
                          value={userDetail.firstName}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label='Last Name'
                          name='lastName'
                          value={userDetail.lastName}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label='Username'
                          name='username'
                          value={userDetail.username}
                          readOnly
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          label='Booking ID'
                          name='bookingID'
                          readOnly
                          value={userDetail.bookingID}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          label='User Status'
                          name='status'
                          value={userDetail.active ? 'Active' : 'Deactivated'}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {
                          userDetail.messengerActive !== undefined
                            ? <Switch
                              label='Messenger Status'
                              name='messengerStatus'
                              value={userDetail.messengerActive}
                              onChange={handleMessengerStatusChange}
                              statusLabel = {userDetail.messengerActive ? 'Enabled' : 'Disabled'}
                            />
                            : <Switch
                              label='Messenger Status'
                              name='messengerStatus'
                              value={true}
                              onChange={handleMessengerStatusChange}
                              statusLabel= {'Enabled'}
                            />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
            <Divider style={{ margin: '42px 0' }} />
            <div style={{ marginTop: 50 }}>
              <Box component='h2' sx={{ textDecoration: 'underline' }}>User Messages</Box>
              <Paper style={{ ...paperBaseStyle, maxWidth: '100%' }}>
                <UserMessages
                  uid={userDetail.uid}
                />
              </Paper>
            </div>
            <ToastMessage
              autoHideDuration={4000}
              message={`Messenger ${userDetail.messengerActive === null ? 'activated' : userDetail.messengerActive ? 'activated' : 'deactivated'} successfully`}
              onClose={() => setMessengerStatusUpdatedSuccess(false)}
              open={!!messengerStatusUpdatedSuccess}
              type='success'
            />
            <ToastMessage
              autoHideDuration={4000}
              message={'There was an error updating messenger status. Please try again in a moment.'}
              onClose={() => setMessengerStatusUpdatedError(false)}
              open={!!messengerStatusUpdatedError}
              type='error'
            />
          </section>
        </Root>
      </Box>
    );
  }
};

export default (observer(MessengerUser));
