import React from 'react';
import { Button, Typography } from '@nucleos/core-ui';

import { Icon } from '../Shared/Icon';

const DefaultPasswordModalContent = ({ selectedRows, onClose, onSuccess }) => {
  const userIDs = selectedRows.map((row) => row.uid);
  const getDisplayText = () => {
    if (selectedRows.length === 1) {
      const { firstName, lastName } = selectedRows[0];
      return `${firstName} ${lastName}`;
    }
    return `${selectedRows.length} learner${selectedRows.length > 1 ? 's' : ''}`;
  };

  return (
    <div className="nucleos-core">
      <div className="mx-auto my-0 w-10/12">
        <div className="flex flex-col justify-start items-center p-10 rounded-md bg-white">
          <Icon icon="Cancel" className="h-14 w-14" style={{ fill: '#F83232' }} />
          <div className="text-2xl mt-6">Are you sure?</div>
          <Typography className="text-center mt-6">
            <span>Do you want to reset the password for <u><b>{getDisplayText()}</b></u> to the default password?</span>
          </Typography>
          <div className="flex gap-x-6 items-center mt-10">
            <Button style={{ color: '#333333' }} onClick={onClose}>
              Cancel
            </Button>
            <Button color="error" variant="contained" onClick={() => onSuccess(userIDs)}>
              Set Default Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultPasswordModalContent;
