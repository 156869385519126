import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Sidebar from './Sidebar';
import { Box, Button, Container, Dialog, DialogActions } from '@mui/material';

import AuthenticationStore from '../../Stores/Authentication';
import { usePreferences } from '../../hooks/usePreferences';
import { DEFAULT_APP_CONFIG } from '../../Lib/CONSTANTS';

import PopupImage from '../../Assets/sf_learner_alert_popup.png';

const Layout = ({ children, hideHeader, hideSidebar, hideHeaderIcons }) => {
  const { appConfig, setAppConfig } = usePreferences('appConfig', {
    defaultAppConfig: DEFAULT_APP_CONFIG
  }, {
    showToastMessage: false
  });

  const [sidebarOpen, setSidebarOpen] = useState(appConfig.sidebarOpen);
  const [showWarningPopup, setShowWarningPopup] = useState(!localStorage.getItem('warningPopupClosed'));

  useEffect(() => {
    setSidebarOpen(appConfig.sidebarOpen);
  }, [appConfig.sidebarOpen]);

  const toggleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);

    setAppConfig({
      ...appConfig,
      sidebarOpen: !sidebarOpen
    });
  };

  const handleWarningPopupClose = useCallback(() => {
    localStorage.setItem('warningPopupClosed', true);

    setShowWarningPopup(false);
  }, []);

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {
        !hideHeader
          ? (
            <Header hideSidebarIcon={hideSidebar} hideHeaderIcons={hideHeaderIcons} toggleSidebarOpen={toggleSidebarOpen} />
          )
          : null
      }

      <Box display='flex' flex={1} overflow='hidden'>
        {
          !hideSidebar
            ? (
              <Sidebar open={sidebarOpen} />
            )
            : null
        }

        <Container disableGutters component='main' maxWidth={false} sx={{ overflow: 'auto', padding: 4 }} id="scrollableDiv">
          {children}
        </Container>

        {
          window.nucleosConfig.showWarningPopup &&
          AuthenticationStore.isAuthenticated &&
          AuthenticationStore.userRole === 'learner'
            ? (
              <Dialog
                data-test-id="learner-warning-popup"
                maxWidth="xl"
                open={showWarningPopup}
                onClose={handleWarningPopupClose}
              >
                <div>
                  <img src={PopupImage} style={{ maxWidth: '100%', maxHeight: '65vh' }} />
                </div>

                <DialogActions>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={handleWarningPopupClose}
                  >
                      Close
                  </Button>
                </DialogActions>
              </Dialog>
            )
            : null
        }
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
