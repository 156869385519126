import { Stack, Typography } from '@mui/material';
import React from 'react';
import Breadcrumbs from '../Breadcrumbs';

const HeaderSubPanel = ({ title, links, sx }) => {
  return (
    <Stack spacing={1} sx={sx}>
      {links && links.length ? <Breadcrumbs links={links} /> : null}
      {
        title
          ? (
            <Typography sx={{ fontWeight: 800, fontSize: '36px', lineHeight: '49px' }}>
              {title}
            </Typography>
          )
          : null
      }
    </Stack>
  );
};

export default HeaderSubPanel;
