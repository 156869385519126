import React, { useRef } from 'react';
import { Button, MultiSelectDropDown, Typography } from '@nucleos/core-ui';
import { PLAYER_PLAYBACK_SPEED_OPTIONS } from '../../../Lib/CONSTANTS';

import PlayIcon from '../../../Assets/PlayIcon-White.svg';
import PauseIcon from '../../../Assets/PauseIcon-White.svg';
import moment from 'moment';

export const PlayerControlsOverlay = ({
  children,
  isPaused,
  userDetails,
  currentFrame,
  togglePlayPause,
  playbackSpeed,
  handlePlaybackSpeedChange
}) => {
  const playbackSpeedSelectRef = useRef(null);

  return (
    <div className="player-controls-overlay-container">
      <div>{children}</div>
      <div className='player-controls-overlay flex justify-between items-center'>
        <div className='flex items-center gap-x-4'>
          <Button variant='text' size='small' onClick={togglePlayPause} style={{ padding: 0 }}>
            {
              isPaused
                ? (
                  <img src={PlayIcon} />
                )
                : (
                  <img src={PauseIcon} />
                )
            }
          </Button>

          <div ref={playbackSpeedSelectRef}>
            <MultiSelectDropDown
              dropdownId='activity-lookup-playback-speed-selector'
              className='playback-speed-selector'
              disableHelperText
              disableMultiSelect
              onChange={handlePlaybackSpeedChange}
              value={[playbackSpeed]}
              label='Select Playback Speed'
              options={PLAYER_PLAYBACK_SPEED_OPTIONS}
            />
          </div>
        </div>
        <div className='flex'>
          <Typography color='white' variant='subtitle1'>{`${userDetails.firstName} ${userDetails.lastName}${currentFrame.serialNumber && currentFrame.serialNumber !== 'null' ? ` (#${currentFrame.serialNumber})` : ''}`}&nbsp;</Typography>
          {
            currentFrame.time
              ? (
                <Typography color='white' variant='subtitle1'>-&nbsp;{`${moment(currentFrame.time).format('MMM DD, YYYY hh:mm:ss a')}`}</Typography>
              )
              : null
          }
        </div>
      </div>
    </div>
  );
};
