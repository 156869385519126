import React from 'react';
import { observer } from 'mobx-react';
import DataStore from '../../Stores/DataStore';
import Icon from './Icon';
import AuthenticationStore from '../../Stores/Authentication';
import moment from 'moment';
export default observer(function Message (props) {
  const {
    message,
    UserUid,
    createdAt,
    ApprovedMessagingId,
    ExternalUserId,
    NucleosUserUid
  } = props.children;

  const ApprovedMessaging = DataStore.recentById[ApprovedMessagingId];

  if (!ApprovedMessaging) { return; }

  const MessageUser = UserUid
    ? ApprovedMessaging.User
    : ExternalUserId
      ? ApprovedMessaging.ExternalUser
      : ApprovedMessaging.NucleosUser;

  const { firstName, lastName } = MessageUser;

  const initials = `${firstName[0]}${lastName[0]}`;

  const isUser =
    AuthenticationStore.uid === UserUid ||
    AuthenticationStore.uid === ExternalUserId ||
    AuthenticationStore.uid === NucleosUserUid;

  const userSpecificStyle = isUser
    ? {
      backgroundColor: '#e6e6e6',
      borderBottomRightRadius: '0px',
      borderColor: '#e6e6e6'
    }
    : {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderBottomLeftRadius: '0px',
      borderColor: 'rgba(0, 0, 0, 0.08)'
    };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        ...(isUser
          ? { alignSelf: 'flex-end', marginLeft: '100px' }
          : {
            alignSelf: 'flex-start',
            marginRight: '100px',
            flexDirection: 'row-reverse'
          })
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isUser ? 'flex-end' : 'flex-start'
        }}
      >
        <div
          id={isUser ? 'sent-message' : 'received-message'}
          style={{
            fontSize: `${props.fontSize}px`,
            borderStyle: 'solid',
            borderWidth: '2px',
            padding: '0.7em',
            margin: '1em',
            marginBottom: '5px',
            borderRadius: '15px',
            ...userSpecificStyle
          }}
        >
          {message}
        </div>
        <div
          style={{
            fontSize: '10px',
            [isUser ? 'paddingRight' : 'paddingLeft']: '14px'
          }}
        >
          {moment(new Date(createdAt)).format('hh:mm A')}
        </div>
      </div>
      <Icon initials={initials} isUser={isUser} />
    </div>
  );
});
