import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@mui/material';
import { Card, Chip } from '@nucleos/core-ui';
import NewUserForm from '../../../Components/User/UserForm/UserForm';
import QuickActionsList from './QuickActionsList';
import ExternalStatusActivation from './ExternalStatusActivation';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { UserGroupsCard } from './UserGroupsCard';
import { getUserStatusLabels } from '../../../Lib/util';

export function UserDetails () {
  const { uid: userId } = useParams();
  const userCTX = useUserContext();

  return (
    <div className="nucleos-core">
      <Grid container spacing={4} justify="space-between">
        <Grid item xs={10}>
          <Card className="bg-white p-6 mb-6">
            <Card.Header className="mb-2" style={{ borderBottom: 'none' }}>
              <div className="flex justify-between items-center">
                <Typography
                  variant="h4"
                  style={{ fontSize: 18, color: '#333' }}
                  className="font-bold"
                >
                  User Details
                </Typography>
                <div className="flex items-center">
                  <Typography variant="h5" className="mr-2">
                    Status{' '}
                  </Typography>
                  <div className="flex gap-2">
                    {
                      getUserStatusLabels(userCTX.user.active, userCTX.user.isBanned).map(status => (
                        <Chip
                          key={status.label}
                          rounded="full"
                          variant={'outlined'}
                          label={status.label}
                          color={status.color}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
              {userCTX.user.isBanned && (
                <div className="flex italic justify-end pt-2">
                  <Typography variant="h6">
                    Banned {userCTX.user.bannedUntil ? 'until' : ''}
                  </Typography>
                  &nbsp;
                  <Typography style={{ color: '#d32f2f', fontWeight: 500 }}>
                    {userCTX.user.bannedUntil
                      ? moment(userCTX.user.bannedUntil).format('MMM DD, YYYY')
                      : 'Indefinitely'}
                  </Typography>
                </div>
              )}
            </Card.Header>
            <Card.Body>
              <NewUserForm userId={userId} />
            </Card.Body>
          </Card>

          <UserGroupsCard />
        </Grid>
        <Grid item xs={2}>
          <QuickActionsList />
          {window.nucleosConfig.showExternalStatus && <ExternalStatusActivation />}
        </Grid>
      </Grid>
    </div>
  );
}
