export const breadcrumbStyle = {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '19px'
};

export const breadcrumbsLinkItem = {
  'cursor': 'pointer',
  'textDecoration': 'none',
  'letterSpacing': '0.15px',
  '&:hover': {
    textDecoration: 'underline'
  }
};

export const breadcrumbsDisabledLinkItem = {
  cursor: 'default',
  textDecoration: 'none',
  letterSpacing: '0.15px'
};
