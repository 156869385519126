import React, { useEffect } from 'react';
import RolesTable from './RolesTable';
import { useHistory, useLocation } from 'react-router-dom';
import CreateRoleRefactored from './CreateRoleRefactored';
import { ROLES_SETTINGS_PATH } from '../../constants/paths';

const Roles = (props) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const selectedRole = query.get('selectedRole');

  const history = useHistory();

  const [openModal, setOpenModal] = React.useState(false);
  const [isEditRole, setIsEditRole] = React.useState(null);

  useEffect(() => {
    if (selectedRole && selectedRole.length) {
      setIsEditRole(true);
      setOpenModal(true);
    }
  }, [selectedRole]);

  const closeModal = () => {
    history.replace(ROLES_SETTINGS_PATH);
    setIsEditRole(false);
    setOpenModal(false);
  };

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const rowClickHandler = (item) => {
    history.replace(`${ROLES_SETTINGS_PATH}/?selectedRole=${item.id}`);
    setOpenModal(true);
    setIsEditRole(false);
  };

  return (
    <div>
      <CreateRoleRefactored
        openModal={openModal}
        closeModal={closeModal}
        selectedRoleId={selectedRole}
        isEditRole={isEditRole}
      />
      <RolesTable
        onRowClick={rowClickHandler}
        openModalHandler={openModalHandler}
      />
    </div>
  );
};

export default (Roles);
