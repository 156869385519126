import React from 'react';
import moment from 'moment';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { Box } from '@mui/material';
import { useAppContext } from '../../../Contexts/AppContext';

export const PDFHeader = () => {
  const appContext = useAppContext();
  const GLRContext = useGroupsLearningRecordContext();
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const groupDetailsQuery = useQuery(
    QueryKeys.Groups.item(groupId),
    () => Data.getGroup(groupId),
    { placeholderData: {}, enabled: groupId && groupId !== 'ALL' }
  );

  return (
    <section>
      <Box
        className="px-28 py-24"
        sx={(theme) => ({
          background: `linear-gradient(315.43deg, ${theme.palette.primary.main} -17.41%, ${theme.palette.primary.dark} 100%)`,
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column'
        })}
      >
        <Box>
          <img src={appContext.monochromeLogoURL} alt='logo' style={{ height: 64 }} />
        </Box>

        <div
          className="mt-auto"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div>
            <p
              style={{
                fontSize: 56,
                fontWeight: 600,
                textTransform: 'uppercase',
                color: '#fff'
              }}
            >
              Group Learning
              <br />
              Records
            </p>
          </div>

          <div className="mt-16">
            <p style={{ fontSize: 32, color: '#fff' }}>
              {`${moment(GLRContext.timePeriod.split('|')[1]).format(
                'MMM DD, YYYY'
              )} - ${moment(GLRContext.timePeriod.split('|')[2]).format(
                'MMM DD, YYYY'
              )}`}
            </p>
          </div>

          <div
            className="mt-16"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div>
              <p style={{ fontSize: 32, color: '#fff' }}>
                {groupId !== 'ALL' ? groupDetailsQuery.data.name : 'All Groups'}
              </p>
              <p
                style={{
                  fontSize: 18,
                  color: '#e5e5e5',
                  fontStyle: 'italic'
                }}
              >
                {groupDetailsQuery.data.description}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <p style={{ fontSize: 24, color: '#fff' }}>
              {`${moment(new Date()).format('MMM DD, YYYY')}`}
            </p>
          </div>
        </div>
      </Box>
    </section>
  );
};
