import React from 'react';
import { Link, Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material';
import { breadcrumbStyle, breadcrumbsDisabledLinkItem, breadcrumbsLinkItem } from './style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Icon } from '../Shared/Icon';

const Breadcrumbs = ({ links }) => {
  const history = useHistory();

  return (
    <MUIBreadcrumbs
      sx={breadcrumbStyle}
      separator={<Icon icon="RightArrow" fontSize="small" />}
    >
      {links.map((breadCrumbLink) => {
        const isLinkDisabled =
          location.pathname === breadCrumbLink.href ||
          breadCrumbLink.href === '#';

        return (
          <Typography
            key={`${breadCrumbLink.href}-${breadCrumbLink.label}`}
            sx={breadcrumbStyle}
          >
            <Link
              variant={isLinkDisabled ? 'disabled' : 'link'}
              sx={
                isLinkDisabled
                  ? breadcrumbsDisabledLinkItem
                  : breadcrumbsLinkItem
              }
              onClick={() => {
                if (isLinkDisabled) {
                  return;
                }
                history.push(breadCrumbLink.href);
              }}
            >
              {breadCrumbLink.label}
            </Link>
          </Typography>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
