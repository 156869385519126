import React from 'react';
import { Button } from '@mui/material';
import { Card, Table, Typography } from '@nucleos/core-ui';
import CourseListByApplicationsModal from './CourseListByApplicationsModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import { SortOrder, useTableSorting } from '../../../hooks/useTableSorting';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';

const ColumnKeys = {
  CoursesCompleted: 'completed_courses',
  CoursesInProgress: 'inprogress_courses'
};

const defaultSorting = {
  column: ColumnKeys.CoursesCompleted,
  sortOrder: SortOrder.Descending
};

export default function CourseStatusReportByApplications () {
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'status-report-by-applications'
    });
  const requestData = {
    groupId,
    type: 'all_courses_count_in_group',
    apps: GLRContext.apps,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2],
    order: columnSorting.sortOrder,
    orderBy: columnSorting.column
  };
  const courseStatusReportQuery = useQuery(
    QueryKeys.GroupLearningRecords.listing(requestData),
    () => Data.getGroupLearningRecordReportData(requestData),
    { placeholderData: [], enabled: !!groupId }
  );

  const columns = [
    {
      title: 'Applications',
      render: (data) => data.title
    },
    {
      title: 'Courses Completed',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CoursesCompleted),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CoursesCompleted, sortOrder }),
      render: (data) => (
        <div>
          {data.isTotal ||
          +data.completedCourses === 0 ||
          GLRContext.isPDFMode
            ? (
              data.completedCourses
            )
            : (
              <Button
                sx={(theme) => ({ padding: 0, minWidth: 0, color: `${theme.palette.link.main} !important` })}
                onClick={() => {
                  const urlSearch = new URLSearchParams(window.location.search);
                  urlSearch.set('appId', data.id);
                  urlSearch.set('mode', 'completed');
                  history.replace({
                    search: urlSearch.toString()
                  });
                }}
              >
                {data.completedCourses}
              </Button>
            )}
        </div>
      )
    },
    {
      title: 'Courses in progress',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CoursesInProgress),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CoursesInProgress, sortOrder }),
      render: (data) => (
        <div>
          {data.isTotal ||
          +data.coursesInProgress === 0 ||
          GLRContext.isPDFMode
            ? (
              data.coursesInProgress
            )
            : (
              <Button
                sx={(theme) => ({ padding: 0, minWidth: 0, color: `${theme.palette.link.main} !important` })}
                onClick={() => {
                  const urlSearch = new URLSearchParams(window.location.search);
                  urlSearch.set('appId', data.id);
                  urlSearch.set('mode', 'in-progress');
                  history.replace({
                    search: urlSearch.toString()
                  });
                }}
              >
                {data.coursesInProgress}
              </Button>
            )}
        </div>
      )
    }
  ];

  const rowsData = courseStatusReportQuery.data
    ? courseStatusReportQuery.data.length
      ? [
        ...courseStatusReportQuery.data.map((app) => ({
          id: app.course_id,
          title: app.course_name,
          completedCourses: app.courses_completed,
          coursesInProgress: app.courses_in_progress
        })),
        {
          id: null,
          isTotal: true,
          title: <Typography className="font-bold">TOTAL</Typography>,
          completedCourses: (
            <Typography className="font-bold">
              {courseStatusReportQuery.data.reduce(
                (acc, app) => acc + Number(app.courses_completed),
                0
              )}
            </Typography>
          ),
          coursesInProgress: (
            <Typography className="font-bold">
              {courseStatusReportQuery.data.reduce(
                (acc, app) => acc + Number(app.courses_in_progress),
                0
              )}
            </Typography>
          )
        }
      ]
      : []
    : [];

  return (
    <div className="h-full">
      <Card className="rounded-xl bg-white h-full">
        <Card.Header style={{ border: 'none' }}>
          <Typography
            className="py-1.5 ml-2"
            variant="h4"
            style={{ fontWeight: 700, fontSize: '18px' }}
          >
            Course Status Report - By Applications
          </Typography>
        </Card.Header>
        <Card.Body
          style={{ padding: 0 }}
          className="course-status-report-card-body"
        >
          <div className={GLRContext.isPDFMode ? '' : 'h-80 overflow-y-auto'}>
            <Table
              columns={columns}
              loading={courseStatusReportQuery.isFetching}
              totalRecords={Infinity}
              noDataMessage={<GenericNoDataFound className="pb-1" size="small" />}
              rowsData={rowsData}
            />
          </div>
        </Card.Body>
      </Card>
      <CourseListByApplicationsModal
        appId={new URLSearchParams(window.location.search).get('appId')}
        mode={new URLSearchParams(window.location.search).get('mode')}
        onClose={() => {
          const urlSearch = new URLSearchParams(window.location.search);
          urlSearch.delete('appId');
          urlSearch.delete('mode');
          history.replace({
            search: urlSearch.toString()
          });
        }}
      />
    </div>
  );
}
