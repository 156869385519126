import React from 'react';
import Select from './Select';

import { observer } from 'mobx-react';

function YearSelect (props) {
  const {
    className,
    error,
    label,
    name,
    onBlur,
    onChange,
    options = [],
    touched,
    value
  } = props;

  return (
    <Select
      className={className}
      error={error}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      touched={touched}
      value={value}
    />
  );
}

export default observer(YearSelect);
