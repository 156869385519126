import React from 'react';
import PropTypes from 'prop-types';
import './UserSearch.scss';

import { Input as MUIInput } from '@mui/material';
import { Input } from '../../Form';

const HTMLInput = ({ disabled, ...props }) => {
  return <Input disable={disabled} {...props} />;
};

const BaseUserSearch = props => {
  const { label, searchString, handleSearchInputChange, handleBlur, userSearchResult, mayDisplaySearchResults, setUser, materialUI, disabled } = props;
  const shouldDisplaySearchResults =
      userSearchResult.length > 0 &&
      searchString.length > 0 &&
      mayDisplaySearchResults;

  const SelectedInput = materialUI ? MUIInput : HTMLInput;

  return (
    <form
      className='user-search-form'
      method='post'
      onSubmit={event => event.preventDefault()}
    >
      {label && <label className='user-search-label'>{label}:</label>}
      <div className='search-input-results-wrapper'>
        <SelectedInput
          type='text'
          name='search-input'
          placeholder='Search'
          value={searchString}
          disable={!!disabled}
          onChange={handleSearchInputChange}
          onBlur={handleBlur}
        />
        {shouldDisplaySearchResults && (
          <div className='user-search-results' style={{ maxHeight: '350px', overflowY: 'auto' }}>
            {userSearchResult.map(item => {
              const { firstName, lastName, username, uid, name } = item;
              return (
                <div
                  className='search-result-item'
                  key={uid}
                  onClick={() => {
                    setUser(item);
                  }}
                >
                  {name || `${firstName} ${lastName}`}
                  <span className='search-result-item-username'>
                    {name ? 'group' : username}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </form>
  );
};

BaseUserSearch.propTypes = {
  label: PropTypes.string,
  searchString: PropTypes.string,
  handleSearchInputChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  userSearchResult: PropTypes.array,
  mayDisplaySearchResults: PropTypes.bool,
  setUser: PropTypes.func.isRequired
};

export default BaseUserSearch;
