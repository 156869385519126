import React, { useEffect, useState } from 'react';
import AccountNotificationsCollapse from './NewAccountNotificationSettings';
import { useUserQuery } from '../../hooks/useUser';
import { useMutation, useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import Card from '../../Components/Shared/Card';
import toast from 'react-hot-toast';
import { Button } from '@mui/material';
import useUserPermissions from '../../hooks/useUserPermissions';
import { QueryKeys } from '../../Lib/query-keys';

export default function NotificationsSettings () {
  const userPermissions = useUserPermissions();
  const userProfile = useUserQuery();

  const actionRequestCategoriesQuery = useQuery(
    QueryKeys.Categories.listing({
      type: 'ACTION_REQUEST'
    }),
    () => Data.getAllGrievanceCategories({
      requestType: 'ACTION_REQUEST'
    }),
    {
      select: (data) => data.categories,
      placeholderData: { categories: [] }
    }
  );

  const [notificationsSettings, setNotificationsSettings] = useState({
    isInitialState: true,
    grievanceCategories: [],
    actionRequestCategories: [],
    pushNotification: {
      isGrievanceEnabled: true,
      notifyForAssignedGrievances: true,
      isGrievanceCategoryEnabled: true,

      notifyForNewMessage: true,
      notifyForFlaggedMessage: true,
      isMessengerAndFlaggingEnabled: true,

      isActionRequestEnabled: true,
      notifyForAssignedActionRequest: true,
      isActionRequestCategoryEnabled: true
    },
    emailNotification: {
      isGrievanceEnabled: true,
      notifyForAssignedGrievances: true,
      isGrievanceCategoryEnabled: true,

      notifyForNewMessage: true,
      notifyForFlaggedMessage: true,
      isMessengerAndFlaggingEnabled: true,

      isActionRequestEnabled: true,
      notifyForAssignedActionRequest: true,
      isActionRequestCategoryEnabled: true
    }
  });

  const userSettingsMutation = useMutation(async (data) => {
    const response = await Data.updateUser((userProfile.data || {}).uid, data);

    if (response.error) {
      const error = await response.errorMessage;

      throw error.error;
    }

    return response;
  });

  useEffect(() => {
    if (userProfile.data) {
      let actionRequestCategories = [];
      actionRequestCategories = [...new Set((userProfile.data.notification.actionRequestCategories || []))];

      if (actionRequestCategoriesQuery.data) {
        actionRequestCategories = actionRequestCategories.filter((cat) => actionRequestCategoriesQuery.data.some((e) => e.id === cat));
      }

      setNotificationsSettings({
        ...userProfile.data.notification,
        actionRequestCategories,
        grievanceCategories: userProfile.data.notification.categories
      });
    }
  }, [userProfile.data, actionRequestCategoriesQuery.data]);

  const saveCurrentSettings = () => {
    if (
      (notificationsSettings.pushNotification.isGrievanceCategoryEnabled ||
        notificationsSettings.emailNotification.notifyForAssignedGrievances) &&
      !notificationsSettings.grievanceCategories.length
    ) {
      toast.error('Please select at least one grievance category');
      return;
    }
    if (
      (notificationsSettings.pushNotification.isActionRequestCategoryEnabled ||
        notificationsSettings.emailNotification
          .notifyForAssignedActionRequest) &&
      !notificationsSettings.actionRequestCategories.length
    ) {
      toast.error('Please select at least one action request category');
      return;
    }
    userSettingsMutation.mutate(
      {
        notification: {
          emailNotificationSettings: notificationsSettings.emailNotification,
          pushNotificationSettings: notificationsSettings.pushNotification,
          categories: notificationsSettings.grievanceCategories,
          actionRequestCategories:
            notificationsSettings.actionRequestCategories
        }
      },
      {
        onSuccess: () => {
          userProfile.refetch();
          toast.success('Notification settings successfully updated');
        },
        onError: () => {
          toast.error('Failed to update notification settings');
        }
      }
    );
  };

  const handleSettingsChange = ({ settingType, key, value, categories }) => {
    const keysToCategoryMap = {
      isActionRequestCategoryEnabled: 'actionRequestCategories',
      isGrievanceCategoryEnabled: 'grievanceCategories'
    };
    switch (settingType) {
    case 'EMAIL': {
      setNotificationsSettings((notificationsSettings) => {
        const newSettings = {
          ...notificationsSettings,
          emailNotification: {
            ...notificationsSettings.emailNotification,
            [key]: value
          }
        };
        newSettings.emailNotification.isMessengerAndFlaggingEnabled =
            newSettings.emailNotification.notifyForFlaggedMessage ||
            newSettings.emailNotification.notifyForNewMessage;
        newSettings.emailNotification.isGrievanceEnabled =
            newSettings.emailNotification.notifyForAssignedGrievances ||
            newSettings.emailNotification.isGrievanceCategoryEnabled;
        newSettings.emailNotification.isActionRequestEnabled =
            newSettings.emailNotification.notifyForAssignedActionRequest ||
            newSettings.emailNotification.isActionRequestCategoryEnabled;
        return newSettings;
      });
      break;
    }

    case 'PUSH': {
      setNotificationsSettings((notificationsSettings) => {
        const newSettings = {
          ...notificationsSettings,
          pushNotification: {
            ...notificationsSettings.pushNotification,
            [key]: value
          }
        };
        newSettings.pushNotification.isMessengerAndFlaggingEnabled =
            newSettings.pushNotification.notifyForFlaggedMessage ||
            newSettings.pushNotification.notifyForNewMessage;
        newSettings.pushNotification.isGrievanceEnabled =
            newSettings.pushNotification.notifyForAssignedGrievances ||
            newSettings.pushNotification.isGrievanceCategoryEnabled;
        newSettings.pushNotification.isActionRequestEnabled =
            newSettings.pushNotification.notifyForAssignedActionRequest ||
            newSettings.pushNotification.isActionRequestCategoryEnabled;
        return newSettings;
      });

      break;
    }

    case 'CATEGORY': {
      setNotificationsSettings((notificationsSettings) => {
        const newSettings = {
          ...notificationsSettings,
          pushNotification: {
            ...notificationsSettings.pushNotification,
            [key]:
                categories.length === 0
                  ? false
                  : notificationsSettings.pushNotification[key]
          },
          emailNotification: {
            ...notificationsSettings.emailNotification,
            [key]:
                categories.length === 0
                  ? false
                  : notificationsSettings.emailNotification[key]
          }
        };

        if (keysToCategoryMap[key]) { newSettings[keysToCategoryMap[key]] = categories; }
        return newSettings;
      });

      break;
    }

    default:
      break;
    }
  };

  return (
    <Card
      head={
        <h2 style={{ textDecoration: 'underline', paddingBottom: '10px' }}>
          Notification Settings
        </h2>
      }
      body={
        <LoadingAndErrorHandler
          isError={userProfile.isError}
          isLoading={
            userProfile.isLoading || notificationsSettings.isInitialState
          }
          isSuccess={userProfile.isSuccess}
        >
          <div>
            <AccountNotificationsCollapse
              title="Messenger"
              isDisabled={userSettingsMutation.isLoading}
              handleSettingsChange={handleSettingsChange}
              activities={[
                {
                  title: 'Notify for new messages',
                  isEmailNotificationEnabled:
                    notificationsSettings.emailNotification.notifyForNewMessage,
                  isPushNotificationEnabled:
                    notificationsSettings.pushNotification.notifyForNewMessage,
                  key: 'notifyForNewMessage'
                },
                {
                  title: 'Notify for flagged keyword messages',
                  isEmailNotificationEnabled:
                    notificationsSettings.emailNotification
                      .notifyForFlaggedMessage,
                  isPushNotificationEnabled:
                    notificationsSettings.pushNotification
                      .notifyForFlaggedMessage,
                  key: 'notifyForFlaggedMessage'
                }
              ].slice(0, userPermissions.canUseMessengerAdminFeatures() ? 2 : 1)}
            />
            <AccountNotificationsCollapse
              title="Grievance"
              isDisabled={
                userSettingsMutation.isLoading ||
                !window.nucleosConfig.formsEnabled
              }
              handleSettingsChange={handleSettingsChange}
              activities={[
                {
                  title: 'Notify me only for my assigned grievances',
                  subtitle: 'If checked, you will only receive notifications for grievances assigned directly to you by other admins, not for unassigned ones or those given to others, even in your selected categories.',
                  isEmailNotificationEnabled:
                    notificationsSettings.emailNotification
                      .notifyForAssignedGrievances,
                  isPushNotificationEnabled:
                    notificationsSettings.pushNotification
                      .notifyForAssignedGrievances,
                  key: 'notifyForAssignedGrievances'
                },
                {
                  title: 'Notify for the following grievance categories',
                  isEmailNotificationEnabled:
                    notificationsSettings.emailNotification
                      .isGrievanceCategoryEnabled,
                  isPushNotificationEnabled:
                    notificationsSettings.pushNotification
                      .isGrievanceCategoryEnabled,
                  selectedCategoriesIDs:
                    notificationsSettings.grievanceCategories,
                  hasCategories: true,
                  keepAtLeastOneSelected: true,
                  key: 'isGrievanceCategoryEnabled'
                }
              ]}
            />
            <AccountNotificationsCollapse
              title="Action Request"
              isDisabled={
                userSettingsMutation.isLoading ||
                !window.nucleosConfig.formsEnabled
              }
              handleSettingsChange={handleSettingsChange}
              activities={[
                {
                  title: 'Notify me only for my assigned action requests',
                  subtitle: 'If checked, you will only receive notifications for action requests assigned directly to you by other admins, not for unassigned ones or those given to others, even in your selected categories.',
                  isEmailNotificationEnabled:
                    notificationsSettings.emailNotification
                      .notifyForAssignedActionRequest,
                  isPushNotificationEnabled:
                    notificationsSettings.pushNotification
                      .notifyForAssignedActionRequest,
                  key: 'notifyForAssignedActionRequest'
                },
                {
                  title: 'Notify for the following action requests categories',
                  isEmailNotificationEnabled:
                    notificationsSettings.emailNotification
                      .isActionRequestCategoryEnabled,
                  isPushNotificationEnabled:
                    notificationsSettings.pushNotification
                      .isActionRequestCategoryEnabled,
                  selectedCategoriesIDs:
                    notificationsSettings.actionRequestCategories,
                  hasCategories: true,
                  categoriesType: 'ACTION_REQUEST',
                  keepAtLeastOneSelected: true,
                  key: 'isActionRequestCategoryEnabled'
                }
              ]}
            />
            {/* <AccountNotificationsCollapse
              title="Flagged Activity"
              isDisabled={userSettingsMutation.isLoading}
              handleSettingsChange={handleSettingsChange}
              activities={[
                {
                  title: "Notify for login flagged activities",
                  isEmailNotificationEnabled: notificationsSetting.emailNotification,
                  isPushNotificationEnabled: notificationsSetting.pushNotification,
                },
              ]}
            /> */}
            <div className="p-4" style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={saveCurrentSettings}
                disabled={userSettingsMutation.isLoading}
                sx={{ padding: '10px 20px', height: '40px', width: '40px' }}
              >Save</Button>
            </div>
          </div>
        </LoadingAndErrorHandler>
      }
    />
  );
}
