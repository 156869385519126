import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { LinearProgress, Modal, Popper, MenuItem, ClickAwayListener, Grow, MenuList, IconButton } from '@mui/material';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import GoalForm from './GoalForm';
import './LearningOverview.scss';
import DataStore from '../../Stores/DataStore';
import { getHoursAndMinutes } from '../../Lib/util';
import NoGoal from '../../Assets/no-goal.svg';
import { toast } from 'react-hot-toast';
import defaultIcon from '../../Assets/course-icon-default.svg';

import {
  Typography,
  Button
} from '@nucleos/core-ui';
import CompletedGoals from './CompletedGoals';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import useUserPermissions from '../../hooks/useUserPermissions';
import { Icon } from '../../Components/Shared/Icon';

// const theme = createMuiTheme({
//   palette: {
//     primary: { main: '#21cd05', light: '#E9E9E9' }
//   },
//   typography: {
//     useNextVariants: true
//   }
// });

const NoGoalsAvailable = () => {
  return (
    <div className='nucleos-core'>
      <div className='h-40 rounded-lg flex justify-center items-center py-6 flex-col' style={{ background: '#ffffff', boxShadow: '0px 10px 40px 0px rgba(64, 72, 82, 0.10)' }}>
        <div>
          <img src={NoGoal} />
        </div>
        <div className='mt-3'><Typography className='font-bold text-base' style={{ color: '#19223D' }} >No Goals Set Yet</Typography></div>
        <div className='mt-1'><Typography className='font-normal text-base' style={{ color: 'rgba(51, 51, 51, 0.7)' }}>Start setting goals and embark on a journey of personal growth and success.</Typography></div>
      </div>
    </div>
  );
};

const Goal = (props) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [openPoper, setOpenPoper] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const { name, applicationName, applicationIcon, minutes, minutesRemaining, id, handleEditButtonClick, handleDeleteButtonClick, handleViewButtonClick, isAdmin } = props;
  const { hoursAdd: originalHoursAdd, minutesRemainder: originalMinutesRemainder } = getHoursAndMinutes(minutes);
  const goalProgress = minutes - minutesRemaining;
  const { hoursAdd: goalHrs, minutesRemainder: goalMins } = getHoursAndMinutes(goalProgress);
  const percentage = minutes ? ((minutes - minutesRemaining) / minutes) * 100 : undefined;

  function handleCloseMenu () {
    setOpenPoper(false);
    setAnchorElement(null);
  }

  function handleOpenMenu ({ currentTarget }) {
    setOpenPoper(true);
    setAnchorElement(currentTarget);
  }

  return (
    <>
      <div className={`p-6 mx-2 rounded-lg ${!isAdmin ? 'cursor-pointer' : ''} `} onClick={() => {
        if (isAdmin) { return; }
        handleViewButtonClick(id);
      }} style={{ background: '#ffffff', width: '32%', minWidth: '32%', height: '100%' }}>
        <div className='mb-4 flex justify-between'>
          <div className='min-w-0' style={{ flex: 1 }}>
            <p title={name} className='truncate text-base font-bold' >{name}</p>
          </div>
          {isAdmin && <ClickAwayListener
            onClickAway={handleCloseMenu}
          >
            <div>
              <Icon
                icon="ThreeDots"
                aria-label="More"
                aria-owns={openPoper ? 'goal-option' : undefined}
                aria-haspopup="true"
                style={{ fill: 'rgba(51, 51, 51, 0.8)' }} className='h-4 cursor-pointer'
                onClick={handleOpenMenu}
              />
              <Popper
                open={!!anchorElement && openPoper}
                anchorEl={anchorElement}
                transition
                disablePortal
                onClose={handleCloseMenu}
                className='z-50'
                placement='left-start'
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                  >
                    <div className='rounded w-20' style={{ background: '#ffffff', border: '1px solid #D3D3D3' }}>
                      <MenuList>
                        <MenuItem
                          className='text-sm'
                          onClick={() => handleEditButtonClick(id)}
                          style={{ color: '#333333' }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          className='text-sm'
                          onClick={() => setDeleteConfirm(true)}
                          style={{ color: '#333333' }}

                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          </ClickAwayListener>}
        </div>
        <div className='flex justify-start items-center'>
          <img src={applicationIcon} className='h-5 w-5 object-contain' alt='App Logo' style={{ borderRadius: '50%' }} title={applicationName} />
          <Typography className='ml-1 text-lg font-extrabold' style={{ color: '#000000' }}> {applicationName} </Typography>
        </div>
        <div className='mt-4'>
          {/* <MuiThemeProvider theme={theme} > */}
          <LinearProgress
            color='primary'
            value={percentage} variant='determinate' />
          {/* </MuiThemeProvider> */}

        </div>
        <div>
          <div className='mt-5' style={{ wordBreak: 'break-word' }}>
            {applicationName
              ? (
                <div>
                  {
                    (!!minutes || minutes === 0)
                      ? (
                        <div className='flex justify-start items-center'>
                          <Icon icon="Clock" color='primary' />
                          <span className='ml-2 text-sm' style={{ color: '#000000' }} >
                            {`${goalHrs ? goalHrs.toString() : ''}${goalHrs ? 'h ' : ''}${goalMins.toString()}m out of ${originalHoursAdd ? originalHoursAdd.toString() : ''}${originalHoursAdd ? 'h ' : ''}${originalMinutesRemainder.toString()}m`}
                          </span></div>)
                      : ''
                  }

                  {/* {(!!minutes || minutes === 0) && <div>{isWeekly ? 'this week' : 'remaining'}</div>} */}

                  {(minutes === null || undefined) && <div className='flex justify-start items-center'><Icon icon="Clock" color='primary' /> <span className='ml-2 text-sm' style={{ color: '#000000' }}>Choose a time to track application progress.</span></div>}

                </div>
              )
              : (
                <div className='flex justify-start items-center' ><Icon icon="Clock" color='primary' /> <span className='ml-2 text-sm' style={{ color: '#000000' }}> {(minutes === null || undefined) ? 'Choose a time and an Application to track progress.' : 'Choose an Application in order to track time.'}</span></div>
              )
            }
          </div>
        </div>

      </div>
      <Modal open={deleteConfirm} onClose={() => setDeleteConfirm(false)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='nucleos-core'>
          <div className='mx-auto my-0 w-3/4'>
            <div className='flex flex-col justify-start items-center p-10 rounded-md' style={{ background: '#ffffff' }} >
              <div> <Icon icon="Cancel" className='h-14 w-14' style={{ fill: '#F83232' }} /> </div>
              <div className='text-2xl mt-6'>Are you sure ?</div>
              <div className='text-sm mt-3 text-center'>Are you sure you want to delete this goal?</div>
              <div className='flex items-center mt-10'>
                <Button style={{ color: '#333333' }} onClick={() => setDeleteConfirm(false)} className="mr-2.5" >Cancel</Button>
                <Button style={{ color: '#ffffff', background: '#F83232' }} onClick={() => {
                  handleDeleteButtonClick(id);
                  setDeleteConfirm(false);
                }}
                className="ml-2.5">Delete</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Goals = (props) => {
  const elementRef = useRef(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const { goals, handleEditButtonClick, handleDeleteButtonClick, handleViewButtonClick, isAdmin } = props;
  useEffect(() => {
    if (elementRef && elementRef.current) {
      const { scrollWidth, clientWidth } = elementRef.current;
      if (scrollWidth == clientWidth) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
    }
  }, [goals]);

  const rightScrollHandler = (elementRef) => {
    const { scrollWidth, clientWidth, scrollLeft } = elementRef.current;
    elementRef.current.scrollLeft += (clientWidth / 3);
    setHideLeftArrow(false);
    if ((scrollLeft + (clientWidth / 3) + clientWidth) >= scrollWidth) {
      setHideRightArrow(true);
    }
  };
  const leftScrollHandler = (elementRef) => {
    const { clientWidth, scrollLeft } = elementRef.current;
    elementRef.current.scrollLeft -= (clientWidth / 3);
    if ((scrollLeft - (clientWidth / 3)) <= 0) {
      setHideLeftArrow(true);
    }
    setHideRightArrow(false);
  };
  return (
    <div className='flex flex-start w-full' style={{ position: 'relative' }} >
      {
        !hideLeftArrow && goals && goals.length > 3 && (
          <IconButton size='large' sx={{ position: 'absolute', left: '0px', bottom: '50%' }} onClick={() => leftScrollHandler(elementRef)}>
            <Icon icon='ArrowBack' sx={(theme) => ({ color: theme.palette.primary.main })} />
          </IconButton>
        )
      }

      <div className="app-tiles-scroll flex justify-start items-center w-full overflow-hidden mx-2 px-2" style={{ scrollBehavior: 'smooth' }} ref={elementRef}>
        {
          goals.map((g, index) => {
            const progressPercent = g.minutes ? ((g.minutes - g.minutesRemaining) / g.minutes) * 100 : undefined;

            return (<Goal key={`${g.ApplicationUid}-${index}`}
              name={g.name}
              applicationName={g.Application && g.Application.name}
              progressPercent={progressPercent}
              applicationIcon={g.Application && (g.Application.logo || g.Application.icon || defaultIcon)}
              minutes={g.minutes}
              minutesRemaining={g.minutesRemaining}
              id={g.id}
              handleDeleteButtonClick={handleDeleteButtonClick}
              handleEditButtonClick={handleEditButtonClick}
              handleViewButtonClick={handleViewButtonClick}
              isAdmin={isAdmin}
              {...props}
            />);
          })
        }
      </div>

      {
        !hideRightArrow && goals && goals.length > 3 && (
          <IconButton size='large' sx={{ position: 'absolute', right: '0px', bottom: '50%' }} onClick={() => rightScrollHandler(elementRef)}>
            <Icon icon='ArrowForward' sx={(theme) => ({ color: theme.palette.primary.main })} />
          </IconButton>
        )
      }
    </div>
  );
};

const UserGoals = (props) => {
  const { goals: storedGoals, isGoalsLoading } = DataStore;
  const userPermissions = useUserPermissions();
  const [goals, setGoals] = useState([]);
  const [goalIndexToEdit, setGoalIndexToEdit] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [completedGoalsModal, setCompletedGoalsModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    setGoalSuccess,
    setGoalError,
    deleteGoalSuccess,
    deleteGoalError,
    setSetGoalSuccess,
    setSetGoalError,
    setDeleteGoalSuccess,
    setDeleteGoalError,
    deleteGoal,
    isCurrentUserFormsSuperAdmin,
    isCurrentUserAdmin,
    isCurrentUserFormsAdmin
  } = DataStore;

  const isAdmin = isCurrentUserFormsSuperAdmin() || isCurrentUserAdmin() || isCurrentUserFormsAdmin();

  useEffect(() => {
    if (setGoalSuccess && goalIndexToEdit) {
      toast.success('Goal was successfully updated.');
      setSetGoalSuccess(null);
    } else if (setGoalSuccess) {
      toast.success('Goal was successfully set.');
      setSetGoalSuccess(null);
    } else if (setGoalError && goalIndexToEdit) {
      toast.error('Goal could not be updated. Please try again in a moment.');
      setSetGoalError(null);
    } else if (setGoalError) {
      toast.error('Goal could not be set. Please try again in a moment.');
      setSetGoalError(null);
    }

    if (deleteGoalSuccess) {
      toast.success('Goal has been deleted');
      setDeleteGoalSuccess(null);
    }
    if (deleteGoalError) {
      toast.error('Goal could not be deleted. Please try again in a moment.');
      setDeleteGoalError(null);
    }
  }, [setGoalSuccess, setGoalError, deleteGoalSuccess, deleteGoalError]);

  useEffect(
    () => {
      setGoals(storedGoals);
    },
    [storedGoals, setGoals]
  );

  function handleFormSubmitCompleted (isEditingOrView) {
    DataStore.setSetGoalSuccess(null);
    if (isEditingOrView) {
      setGoalIndexToEdit(null);
      setIsReadOnly(false);
    }
  }

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setGoalIndexToEdit(null);
    setIsReadOnly(false);
    setModalOpen(false);
  };

  const inProgresGoals = goals && goals.length ? goals.filter(g => (g.minutesRemaining != 0 && g.minutes)) : [];
  const completedGoals = goals && goals.length ? goals.filter(g => (g.minutesRemaining == 0 && g.minutes)) : [];

  if (isGoalsLoading) {
    return <LoadingComponent small='true' tillApiResponse={true} />;
  }

  return (
    <React.Fragment>
      <div className='nucleos-core'>
        <div className='flex justify-between items-center mb-4'>
          <div>
            <Typography className='font-semibold text-lg' style={{ color: '#19223D' }} >
              Goals:
            </Typography>
          </div>
          {userPermissions.canSetGoalsForLearner() && <div>
            <Button
              color="primary"
              variant="contained"
              onClick={openModal}
            >
              + Add Goal
            </Button>
          </div>}
        </div>
        {
          inProgresGoals.length == 0
            ? <NoGoalsAvailable />
            : <Goals
              goals={inProgresGoals}
              handleEditButtonClick={(id) => {
                setGoalIndexToEdit(id);
                openModal();
              }}
              handleViewButtonClick={(id) => {
                setIsReadOnly(true);
                setGoalIndexToEdit(id);
                openModal();
              }}
              handleDeleteButtonClick={(id) => deleteGoal(id)}
              isAdmin={isAdmin}
              {...props}
            />
        }

        {completedGoals && completedGoals.length
          ? <div className='mt-4 text-base font-normal' style={{ color: '#449FFF' }} >
            <span className='cursor-pointer' onClick={() => setCompletedGoalsModal(true)} >
            View Completed Goals
            </span>
          </div>
          : null}

        <Modal
          open={completedGoalsModal}
          handleClose={() => setCompletedGoalsModal(false)}
          className='flex justify-center items-center'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <CompletedGoals
            closeModal={() => setCompletedGoalsModal(false)}
            goals={completedGoals}
            handleEditButtonClick={(id) => {
              setGoalIndexToEdit(id);
              setCompletedGoalsModal(false);
              openModal();
            }}
            handleDeleteButtonClick={(id) => {
              setCompletedGoalsModal(false);
              deleteGoal(id);
            }}
            handleViewButtonClick={(id) => {
              setIsReadOnly(true);
              setCompletedGoalsModal(false);
              setGoalIndexToEdit(id);
              openModal();
            }}
            isAdmin={isAdmin}
          />
        </Modal>

        <Modal
          open={modalOpen}
          handleClose={closeModal}
          className='flex justify-center items-center'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <GoalForm
            closeModal={closeModal}
            goal={goalIndexToEdit ? goals && goals.length && goals.find(g => g.id == goalIndexToEdit) : null}
            isEditing={!!goalIndexToEdit}
            isReadOnly={isReadOnly}
            handleFormSubmitCompleted={handleFormSubmitCompleted}

          />
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default observer(UserGoals);
