import React, { useState } from 'react';
import { SearchInput, Typography } from '@nucleos/core-ui';
import { IconButton, Drawer } from '@mui/material';
import { Icon } from '../../../Components/Shared/Icon';

function UsersListDrawer ({
  isOpen,
  setIsOpen,
  liveUsers = [],
  totalLiveUsers
}) {
  const [search, setSearch] = useState('');

  const searchedLearbers = liveUsers.filter((user) =>
    !search
      ? true
      : `${user.firstName} ${user.lastName} ${user.username}`.search(
        new RegExp(search, 'i')
      ) !== -1
  );

  const sideList = (
    <div className={'p-4 w-80'}>
      <div className="flex justify-between items-center mb-4">
        <Typography
          variant="h3"
          data-testid="live-activity-learner-list-all-learners"
        >
          All Learners ({totalLiveUsers})
        </Typography>
        <IconButton
          className="p-2"
          data-testid="live-activity-learner-list-close-btn"
          onClick={() => setIsOpen(false)}
        >
          <Icon icon="Close" className="fill-gray-300" />
        </IconButton>
      </div>
      <div className="mb-4">
        <SearchInput onSearch={setSearch} value={search} fullWidth />
      </div>
      <div>
        {searchedLearbers.map((user) => (
          <div className="flex mb-4" key={user.uid}>
            <div className="flex justify-center items-center mr-2">
              <div
                className="h-10 w-10 rounded-full border-2 bg-gray-300 uppercase flex justify-center items-center"
                data-testid="live-activity-learner-list-item"
                data-userid={user.uid}
              >
                {user.lastName.charAt(0)}
                {user.firstName.charAt(0)}
              </div>
            </div>
            <div className="w-full">
              <div className="capitalize">
                <Typography variant="h5">
                  {user.lastName} {user.firstName}
                </Typography>
              </div>
              <div>
                <Typography>{user.username}</Typography>
              </div>
            </div>
          </div>
        ))}
        {searchedLearbers.length === 0
          ? (
            <Typography data-testid="live-activity-learner-list-search-no-learners-found">
            No learners found.
            </Typography>
          )
          : null}
      </div>
    </div>
  );

  return (
    <div>
      <Drawer
        anchor="right"
        BackdropProps={{ invisible: true }}
        PaperProps={{ style: { marginTop: 80 } }}
        elevation={0}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className="nucleos-core" tabIndex={0} role="button">
          {sideList}
        </div>
      </Drawer>
    </div>
  );
}

export default UsersListDrawer;
