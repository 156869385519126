import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Divider, Tab, Tabs } from '@mui/material';
import { UserSettings } from '../Components/UserSettings';
import ToastMessage from '../Components/Widgets/ToastMessage';
import DataStore from '../Stores/DataStore';
import { Roles } from '../Components/Roles';
import useUserPermissions from '../hooks/useUserPermissions';
import HeaderSubPanel from '../Components/HeaderSubPanel';
import { GROUP_PATH, LEARNER_USER_PATH, NON_LEARNER_USER_PATH, ROLES_SETTINGS_PATH, USER_ANG_GROUP_PATH, USER_PATH } from '../constants/paths';
import GroupsTable from '../Components/GroupSettings/GroupsTable';

const TABS = {
  allUsers: {
    label: 'All Users',
    value: 'allUsers',
    path: USER_PATH
  },
  learners: {
    label: 'Learners',
    value: 'learners',
    path: LEARNER_USER_PATH
  },
  nonLearners: {
    label: 'Non Learners',
    value: 'nonLearners',
    path: NON_LEARNER_USER_PATH
  },
  groups: {
    label: 'Groups',
    value: 'groups',
    path: GROUP_PATH
  },
  roles: {
    label: 'Roles',
    value: 'roles',
    path: ROLES_SETTINGS_PATH
  }
};

const UsersGroups = () => {
  const history = useHistory();
  const { pathname } = history.location;

  const userPermissions = useUserPermissions();

  const [activeTab, setActiveTab] = useState(() => {
    if (pathname === TABS.learners.path) { return TABS.learners.value; }
    if (pathname === TABS.nonLearners.path) { return TABS.nonLearners.value; }
    if (pathname === TABS.groups.path) { return TABS.groups.value; }
    if (pathname === TABS.roles.path) { return TABS.roles.value; }

    return TABS.allUsers.value;
  });

  useEffect(() => {
    if (pathname === USER_ANG_GROUP_PATH) {
      history.replace(TABS.allUsers.path);
    } else {
      if (pathname === TABS.allUsers.path) { setActiveTab(TABS.allUsers.value); }
      if (pathname === TABS.learners.path) { setActiveTab(TABS.learners.value); }
      if (pathname === TABS.nonLearners.path) { setActiveTab(TABS.nonLearners.value); }
      if (pathname === TABS.groups.path) { setActiveTab(TABS.groups.value); }
      if (pathname === TABS.roles.path) { setActiveTab(TABS.roles.value); }
    }
  }, [history, pathname]);

  const handleTabChange = useCallback((_, value) => {
    history.push(TABS[value].path);
  }, [history]);

  function handleSnackBarClose () {
    const { setDeleteUserSuccess } = DataStore;
    setDeleteUserSuccess(null);
  }

  const { deleteUserSuccess, disableUserSuccess, isCurrentUserAdmin } =
    DataStore;

  const isSuperAdmin = isCurrentUserAdmin();

  return (
    <>
      <HeaderSubPanel title="User & Group Settings" sx={{ mb: 3 }} />
      <div className="user-groups">
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Users" value={TABS.allUsers.value} />
          <Tab label="Learners" value={TABS.learners.value} />
          <Tab label="Non Learners" value={TABS.nonLearners.value} />

          {
            userPermissions.canManageGroups()
              ? (
                <Tab label="Groups" value={TABS.groups.value} />
              )
              : null
          }

          {isSuperAdmin && (
            <Tab label="Roles" value={TABS.roles.value} />
          )}
        </Tabs>

        <Divider />

        {activeTab === TABS.allUsers.value && <UserSettings listViewMode='ALL' />}
        {activeTab === TABS.learners.value && <UserSettings listViewMode='LEARNER' />}
        {activeTab === TABS.nonLearners.value && <UserSettings listViewMode='NON_LEARNER' />}
        {activeTab === TABS.groups.value && userPermissions.canManageGroups() && <GroupsTable />}
        {activeTab === TABS.roles.value && isSuperAdmin && <Roles />}
      </div>
      <ToastMessage
        autoHideDuration={4000}
        message={
          disableUserSuccess
            ? 'User deactivated successfully'
            : 'User deleted successfully.'
        }
        onClose={handleSnackBarClose}
        open={!!deleteUserSuccess}
        type="success"
      />
    </>
  );
};
export default observer(UsersGroups);
