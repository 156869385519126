import React from 'react';
import { useAppContext } from '../Contexts/AppContext';

const withAppContext = (WrappedElement) => {
  return React.forwardRef((props, ref) => {
    const appContext = useAppContext();

    return (
      <WrappedElement appContext={appContext} {...props} ref={ref} />
    );
  });
};

export default withAppContext;
