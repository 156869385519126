import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, styled } from '@mui/material';
import PhotoIcon from '../../Assets/photo-icon.svg';

const PREFIX = 'PhotoUpload';
const classes = {
  container: `${PREFIX}-container`,
  imgContainer: `${PREFIX}-imgContainer`,
  actionContainer: `${PREFIX}-actionContainer`,
  input: `${PREFIX}-input`,
  label: `${PREFIX}-label`,
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  info: `${PREFIX}-info`
};

const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    marginLeft: 20
  },
  [`& .${classes.imgContainer}`]: {
    position: 'relative',
    width: 156,
    height: 156,
    overflow: 'hidden'
  },
  [`& .${classes.actionContainer}`]: {
    marginLeft: 20
  },
  [`& .${classes.input}`]: {
    display: 'none'
  },
  [`& .${classes.label}`]: {
    padding: '8px 24px',
    borderRadius: 6,
    border: '1px solid #E3E6EC'
  },
  [`& .${classes.image}`]: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundColor: '#F5F6FA',
    borderRadius: 12
  },
  [`& .${classes.title}`]: {
    marginBottom: 6
  },
  [`& .${classes.info}`]: {
    fontSize: 14,
    marginBottom: 6,
    color: '#75E263'
  }
}));

/**
 * Displays and Updates photo
 */
const PhotoUpload = ({ id, name, title, light, setImages, images, setImageErrors }) => {
  // const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [info, setInfo] = useState();
  const hiddenFileInput = useRef(null);

  // const MAX_USER_PROFILE_IMAGE_SIZE = 1000000;
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = '';

    if (!file) { return; }

    // if (file.size >= MAX_USER_PROFILE_IMAGE_SIZE) {
    //   setLoading(false);
    //   message.error(`File size should be less then ${MAX_USER_PROFILE_IMAGE_SIZE / 1000000}MB`);
    //   return;
    // }
    if (images) { setImages({ ...images, [name]: file }); }
    setImage(file);

    // setLoading(true);
    setInfo(`Uploading photo ${file.name}`);

    await new Promise((resolve) => setTimeout(resolve, 2000));
    setInfo(`Photo ${file.name} uploaded successfully`);
    // setLoading(false);
    setImageErrors([]);
  };

  const renderProfileImage = () => {
    // if (loading) {
    //   return <Loading top="xl" />;
    // }

    return image
      ? (
        <img className={classes.image} src={URL.createObjectURL(image)} alt='profile' accept='image/x-png,image/jpeg' />
      )
      : (
        <img className={classes.image} src={PhotoIcon} width='156' alt='profile' />
      );
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  return (
    <Root className={classes.container} style={{ color: light ? 'white' : 'inherit' }}>
      <div className={classes.imgContainer}>{renderProfileImage()}</div>
      <div className={classes.actionContainer}>
        <div className={classes.title}>{title}</div>
        {info && <div className={classes.info}>{info}</div>}
        <div style={{ display: 'flex' }}>
          <label htmlFor={id}>
            <Button className={classes.label} variant='contained' onClick={handleClick}>
              {image ? 'Replace Photo' : 'Upload Photo'}
            </Button>
          </label>
          <input
            className={classes.input}
            name={name}
            type='file'
            id={id}
            ref={hiddenFileInput}
            accept='image/x-png,image/jpeg'
            onChange={handleImageChange}
          />
        </div>
      </div>
    </Root>
  );
};

PhotoUpload.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  light: PropTypes.bool
};

export default (PhotoUpload);
