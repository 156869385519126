import React, { useState } from 'react';

import { Dialog, Typography, Button } from '@nucleos/core-ui';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { Checkbox } from '@mui/material';

export const OptionKeys = {
  ApplicationDescription: 'activity_description',
  CourseStatusReportByApplications: 'course_status_report_by_applications',
  CourseStatusReportByUsers: 'course_status_report_by_users',
  AggregateAppUsage: 'aggregate_app_usage',
  ActivityByDate: 'activity_by_date',
  ActivityTotalByUsers: 'activity_totals'
};

export const exportOptions = [
  {
    label: 'Applications Description',
    description: '',
    key: OptionKeys.ApplicationDescription
  },
  {
    label: 'Course Status Report - By Applications',
    description: '',
    key: OptionKeys.CourseStatusReportByApplications
  },
  {
    label: 'Course Status Report - By Users',
    description: '',
    key: OptionKeys.CourseStatusReportByUsers
  },
  {
    label: 'Aggregate App Usage',
    description: '',
    key: OptionKeys.AggregateAppUsage
  },
  {
    label: 'Activity By Date',
    description: '',
    key: OptionKeys.ActivityByDate
  },
  {
    label: 'Activity Total By Users',
    description: '',
    key: OptionKeys.ActivityTotalByUsers
  }
];
export default function SaveAsPDFDialog ({ isOpen, onClose }) {
  const [optionsEnabled, setOptionsEnabled] = useState(
    exportOptions.reduce((acc, option) => {
      if (option.key !== OptionKeys.ActivityTotalByUsers) {
        return { ...acc, [option.key]: true };
      }

      return acc;
    }, {})
  );
  const generatePDFMutation = useMutation(
    ['PDF_MUTATION'],
    async () => {
      const search = new URLSearchParams(window.location.search);
      search.set('enabledOptions', JSON.stringify(optionsEnabled));
      setTimeout(() => {
        onClose();

        toast.loading('The PDF can take up to 5-10 minutes to generate. The download will start automatically post that. Thank you for your patience.', {
          duration: 5000,
          style: {
            maxWidth: '70%'
          }
        });
      }, 200);
      const response = await fetch(
        process.env.REACT_APP_PUBLIC_URL +
          `/reports/learningRecords/pdf?${search.toString()}`,
        {
          credentials: 'include',
          method: 'GET',
          timeout: 600000 // 10 * 60 * 1000 -> 10 mins timeout
        }
      );
      const pdfBlob = await response.blob();

      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'GroupLearningRecord.pdf';

      document.body.appendChild(a);
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    },
    {
      onError: () => {
        toast.error('Failed to generate PDF.');
      }
    }
  );

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        title="Save as PDF"
      >
        {exportOptions.map((option) => (
          <div className="flex items-center my-4" key={option.key}>
            <div className="mr-4">
              <Checkbox
                sx={{ padding: '2px' }}
                checked={optionsEnabled[option.key]}
                disabled={generatePDFMutation.isLoading}
                onChange={(_, isEnabled) => {
                  setOptionsEnabled({
                    ...optionsEnabled,
                    [option.key]: isEnabled
                  });
                }}
              />
            </div>
            <div className="">
              <Typography variant="h6">{option.label}</Typography>
              <Typography variant="body2">{option.description}</Typography>
            </div>
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            onClick={() => generatePDFMutation.mutate()}
            loading={generatePDFMutation.isLoading}
            color="primary"
            variant="contained"
          >
            Generate PDF
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
