import { useInfiniteQuery } from 'react-query';

const RECORDS_PER_PAGE = 10;

export function usePaginatedQuery (queryKey, queryFn, options = {}) {
  return useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: (_, data) => {
      const currentPage = data.length;
      const totalPages = Math.ceil(data[0].count / RECORDS_PER_PAGE);
      return totalPages > currentPage
        ? {
          page: currentPage + 1,
          offset: (currentPage + 1) * RECORDS_PER_PAGE - 10,
          limit: RECORDS_PER_PAGE
        }
        : undefined;
    },
    getPreviousPageParam () {
      return undefined;
    },
    placeholderData: {
      pages: []
    },
    ...options,
    select: (data) => {
      const val = {
        pageParams: data.pageParams,
        pages: (data.pages || [])
          .map((item) => item[options.listKeyName])
          .flat(1),
        data: data.pages
      };
      return options.select ? options.select(val) : val;
    }
  });
}
