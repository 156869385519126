import React, { useState } from 'react';
import GrievanceReportSelect from '../../Shared/GrievanceReportSelect';
import { Checkbox, FormControlLabel, Button, styled } from '@mui/material';
import { Input } from '../../../../../Components/Form';
import moment from 'moment';

const PREFIX = 'GrievanceReportFilter';
const classes = {
  grievanceFilter: `${PREFIX}-grievanceFilter`,
  checkboxLabel: `${PREFIX}-checkboxLabel`,
  checkbox: `${PREFIX}-checkbox`,
  updateReports: `${PREFIX}-updateReports`,
  updateReportsBtnDisabled: `${PREFIX}-updateReportsBtnDisabled`,
  timeframWrapper: `${PREFIX}-timeframWrapper`,
  endDateWrapper: `${PREFIX}-endDateWrapper`,
  dateInput: `${PREFIX}-dateInput`,
  dateLabel: `${PREFIX}-dateLabel`,
  fieldLabel: `${PREFIX}-fieldLabel`,
  fieldValueWrapper: `${PREFIX}-fieldValueWrapper`,
  collapsedFilterBar: `${PREFIX}-collapsedFilterBar`,
  changeReportBtn: `${PREFIX}-changeReportBtn`,
  filterSummary: `${PREFIX}-filterSummary`
};

const Root = styled('div')(() => ({
  [`& .${classes.grievanceFilter}`]: {
    background: '#ffffff',
    padding: '15px'
  },
  [`& .${classes.checkboxLabel}`]: {
    color: '#333333',
    fontSize: '14px',
    lineHeight: '18px',
    paddingBottom: '0px'
  },
  [`& .${classes.checkbox}`]: {
    color: '#CCCCCC',
    padding: '0px 10px 0px 10px'
  },
  [`& .${classes.updateReports}`]: {
    'background': '#449FFF',
    'color': '#ffffff !important',
    'width': '117px',
    'height': '35px',
    'fontSize': '14px',
    'lineHeight': '19px',
    'fontWeight': '400',
    'textTransform': 'capitalize',
    'padding': '0px',
    'marginTop': '5px',
    '&:hover': {
      background: 'rgb(23, 105, 170)'
    }
  },
  [`& .${classes.updateReportsBtnDisabled}`]: {
    background: '#CCCCCC',
    color: '#ffffff !important',
    width: '117px',
    height: '35px',
    fontSize: '14px',
    lineHeight: '19px',
    fontWeight: '400',
    textTransform: 'capitalize',
    padding: '0px',
    marginTop: '5px'
  },
  [`& .${classes.timeframWrapper}`]: {
    'display': 'flex',
    'paddingLeft': '50px',
    'margin': '7px 0px',
    '& .input-wrapper': {
      justifyContent: 'flex-start'
    },
    '& .nucleos-field': {
      background: '#ffffff !important',
      border: '1px solid #D3D3D3 !important',
      borderRadius: '4px !important'
    },
    '& .input-error': {
      textTransform: 'capitalize'
    }
  },
  [`& .${classes.endDateWrapper}`]: {
    marginLeft: '20px'
  },
  [`& .${classes.dateInput}`]: {
    'textTransform': 'uppercase',
    '& .nucleos-input': {
      minWidth: '190px',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#adadad !important'
    }
  },
  [`& .${classes.dateLabel}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#333333'
  },
  [`& .${classes.fieldLabel}`]: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#333333'
  },
  [`& .${classes.fieldValueWrapper}`]: {
    paddingLeft: '50px'
  },
  [`& .${classes.collapsedFilterBar}`]: {
    border: '1px solid #D3D3D3',
    height: '60px',
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '8px'
  },
  [`& .${classes.changeReportBtn}`]: {
    border: '1px solid #D3D3D3',
    borderRadius: '4px',
    color: '#333333',
    textTransform: 'capitalize'
  },
  [`& .${classes.filterSummary}`]: {
    color: '#333333',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19px'
  }
}));

const GrievanceFilterReport = (props) => {
  const { updateHandler } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [seperateByFacility, setSeperateByFacility] = useState(false);
  const [reportType, setReportType] = useState('responseTimeAndStatusReport');
  // const [selectedGroupBy, setSelectedGroupBy] = useState('Week');
  const [validationErrors, setValidationErrors] = useState({
    startDate: {
      error: false,
      message: ''
    },
    endDate: {
      error: false,
      message: ''
    }
  });
  const [toggleFilter, setToggleFilter] = useState(true);
  const [isUpdatedBtnDisabled, setIsUpdatedBtnDisabled] = useState(false);
  const [isInitialRequest, setIsInitialRequest] = useState(true);

  const dateChangeHandler = (e) => {
    if (e.target.name == 'startDate') {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
    setIsUpdatedBtnDisabled(false);
  };

  const handleSeperateByFacility = () => {
    setIsUpdatedBtnDisabled(false);

    setSeperateByFacility((s) => !s);
  };

  const updateReportHandler = () => {
    if (startDate == null && endDate != null) {
      setValidationErrors({
        ...validationErrors,
        startDate: {
          error: true,
          message: 'Required'
        }
      });
    } else if (startDate != null && endDate == null) {
      setValidationErrors({
        ...validationErrors,
        endDate: {
          error: true,
          message: 'Required'
        }
      });
    } else {
      setToggleFilter(false);
      setIsUpdatedBtnDisabled(true);
      setIsInitialRequest(false);
      updateHandler({ startDate, endDate, seperateByFacility });
    }
  };

  const getDatesSummary = () => {
    if (startDate && endDate) {
      return `: ${moment(new Date(startDate)).format('MM/DD/YYYY')} - ${moment(new Date(endDate)).format('MM/DD/YYYY')}`;
    }
    return '';
  };
  return (
    <Root>
      {toggleFilter
        ? <div className={classes.grievanceFilter}>
          <table>
            <tbody>
              <tr className={classes.grievanceSelect}>
                <td><label className={classes.fieldLabel} data-test-id="report-label" >Choose report</label></td>
                <td className={classes.fieldValueWrapper} data-test-id="grievance-option-select" >
                  <GrievanceReportSelect selectedReport={reportType} handleChange={(e) => setReportType(e)} />
                </td>
              </tr>
              <tr>
                <td><label className={classes.fieldLabel} data-test-id="timeframe-label" >Timeframe</label></td>
                <td className={classes.timeframWrapper}>
                  <div>
                    <label className={classes.dateLabel} data-test-id="start-date-label" >Start Date</label>
                    <Input
                      id="startDate"
                      name="startDate"
                      value={startDate}
                      onChange={dateChangeHandler}
                      type="date"
                      maxDate={endDate}
                      defaultValue={new Date()}
                      className={classes.dateInput}
                      InputLabelProps={{
                        shrink: true
                      }}
                      hideMargin={true}
                      touched={validationErrors.startDate.error}
                      error={validationErrors.startDate.message}
                    />
                  </div>
                  <div className={classes.endDateWrapper}>
                    <label className={classes.dateLabel} data-test-id="end-date-label" >End Date</label>
                    <Input
                      id="endDate"
                      name="endDate"
                      value={endDate}
                      onChange={dateChangeHandler}
                      type="date"
                      minDate={startDate}
                      defaultValue={new Date()}
                      className={classes.dateInput}
                      InputLabelProps={{
                        shrink: true
                      }}
                      hideMargin={true}
                      touched={validationErrors.endDate.error}
                      error={validationErrors.endDate.message}
                    />
                  </div>

                </td>
              </tr>
              {/* TODO: Un comment the code and add condition to show hide the filter */}
              {/* <tr className={classes.grievanceSelect}>
                        <td><label className={classes.fieldLabel} data-test-id="group-by-label" >Group By</label></td>
                        <td className={classes.fieldValueWrapper} data-test-id="grievance-report-group-by-select" ><GrievanceReportGroupBySelect disabled={true} selectedGroupBy={selectedGroupBy} handleChange={(e) => setSelectedGroupBy(e)} /></td>
                    </tr> */}
              <tr>
                <td></td>
                <td className={classes.fieldValueWrapper}>
                  <FormControlLabel
                    data-test-id="is-seperate-by-facility"
                    className={classes.checkboxLabel}
                    classes={{ label: classes.checkboxLabel }}
                    control={
                      <Checkbox
                        classes={{ root: classes.checkbox }}
                        checked={seperateByFacility}
                        value={seperateByFacility}
                        color='primary'
                        onChange={handleSeperateByFacility}
                        data-test-id="is-seperate-by-facility-checkbox"
                      />
                    }
                    label="Separate data by facility"
                  />

                </td>
              </tr>
              <tr>
                <td></td>
                <td className={classes.fieldValueWrapper}>
                  <Button
                    color="primary"
                    disabled={isUpdatedBtnDisabled}
                    data-test-id="update-report-button"
                    onClick={updateReportHandler}
                    style={{ marginTop: '10px' }}
                    variant="contained"
                  >{isInitialRequest ? 'Generate' : 'Update'} Report</Button>  </td>
              </tr>
            </tbody>
          </table> </div>
        : <div className={classes.collapsedFilterBar}>
          <div className={classes.filterSummary}> {`Showing Grievance Status Report ${getDatesSummary()}`} </div>
          <div> <Button className={classes.changeReportBtn} onClick={() => setToggleFilter(true)} > Change Report</Button> </div>
        </div>
      }

    </Root>
  );
};

export default (GrievanceFilterReport);
