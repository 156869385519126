import React from 'react';
import { Typography } from '@nucleos/core-ui';

import EmptyNotificationsGraphic from '../../Assets/graphics/empty_notifications_panel.svg';

export const EmptyNotificationsInfo = () => {
  return (
    <div className="nucleos-core">
      <div className="flex flex-col items-center">
        <div>
          <img src={EmptyNotificationsGraphic} />
        </div>
        <div>
          <Typography className='px-4 py-2' variant="h3">You have no notifications.</Typography>
        </div>
      </div>
    </div>
  );
};
