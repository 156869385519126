import React, { Component } from 'react';
import './Form.scss';

const types = [
  'sad',
  'dislike',
  'neutral',
  'happy',
  'delighted'
];
const fill = 'fill';
const stroke = 'stroke';

class Smiles extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selected: null,
      debounce: Date.now() - 2000
    };
  }

  handleSmiley (value) {
    const selected = this.state.selected === value && Date.now() - this.state.debounce > 500 ? null : value;
    this.setState({ selected });
    if (this.props.handleSmiley) {
      this.props.handleSmiley(selected);
    }
    this.setState({ debounce: Date.now() });
  }

  handleSelected (pos, postfix) {
    const type = types[pos];
    return `emote-${type}-${postfix}${this.state.selected === pos ? '-selected' : ''}`;
  }

  render () {
    return (
      <div className='smiles-box'>
        {/* sad - 1 */}
        <svg onClick={() => this.handleSmiley(0)} className='emote-sad' xmlns='http://www.w3.org/2000/svg' width='41' height='41' viewBox='0 0 41 41' fill='none'>
          <circle className={this.handleSelected(0, stroke)} cx='20.0904' cy='20.0396' r='19.0396' fill='white' strokeWidth='2' />
          <circle className={this.handleSelected(0, fill)} cx='12.6339' cy='15.3792' r='2.33019' />
          <circle className={this.handleSelected(0, fill)} cx='26.6149' cy='15.3792' r='2.33019' />
          <path className={this.handleSelected(0, stroke)} d='M9.37147 30.7585L9.55512 30.4348C14.2798 22.1081 26.3403 22.2918 30.8092 30.7585V30.7585' strokeWidth='2' />
        </svg>
        {/* dislike */}
        <svg onClick={() => this.handleSmiley(1)} className='emote-dislike' xmlns='http://www.w3.org/2000/svg' width='40' height='41' viewBox='0 0 40 41' fill='none'>
          <path className={this.handleSelected(1, stroke)} d='M38.4621 20.0396C38.4621 30.5772 30.1244 39.0792 19.8885 39.0792C9.65267 39.0792 1.31494 30.5772 1.31494 20.0396C1.31494 9.50202 9.65267 1 19.8885 1C30.1244 1 38.4621 9.50202 38.4621 20.0396Z' fill='white' strokeWidth='2' />
          <circle className={this.handleSelected(1, fill)} cx='11.9658' cy='15.3792' r='2.33019' />
          <circle className={this.handleSelected(1, fill)} cx='25.947' cy='15.3792' r='2.33019' />
          <path className={this.handleSelected(1, stroke)} d='M30.9101 25.1665L19.4367 27.1923L9.79895 28.8939' strokeWidth='2' strokeLinecap='round' />
        </svg>
        {/* neutral */}
        <svg onClick={() => this.handleSmiley(2)} className='emote-neutral' xmlns='http://www.w3.org/2000/svg' width='41' height='41' viewBox='0 0 41 41' fill='none'>
          <circle className={this.handleSelected(2, stroke)} cx='20.6866' cy='20.0396' r='19.0396' fill='white' strokeWidth='2' />
          <circle className={this.handleSelected(2, fill)} cx='13.23' cy='15.3792' r='2.33019' />
          <circle className={this.handleSelected(2, fill)} cx='27.2112' cy='15.3792' r='2.33019' />
          <path className={this.handleSelected(2, stroke)} d='M31.4055 27.0302H19.7546H9.96777' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />

        </svg>
        {/* happy */}
        <svg onClick={() => this.handleSmiley(3)} className='emote-happy' xmlns='http://www.w3.org/2000/svg' width='41' height='44' viewBox='0 0 41 44' fill='none'>
          <circle className={this.handleSelected(3, stroke)} cx='20.9508' cy='20.0396' r='19.0396' fill='white' strokeWidth='2' />
          <circle className={this.handleSelected(3, fill)} cx='13.4943' cy='15.3792' r='2.33019' />
          <circle className={this.handleSelected(3, fill)} cx='27.4753' cy='15.3792' r='2.33019' />
          <path className={this.handleSelected(3, stroke)} d='M31.6697 24.234L31.486 24.5576C26.7614 32.8843 14.7009 32.7006 10.2319 24.234V24.234' strokeWidth='2' strokeLinejoin='round' />
        </svg>
        {/* delighted */}
        <svg onClick={() => this.handleSmiley(4)} className='emote-delighted' xmlns='http://www.w3.org/2000/svg' width='40' height='55' viewBox='0 0 40 55' fill='none'>
          <path className={this.handleSelected(4, stroke)} d='M38.1979 20.0396C38.1979 30.5772 29.8602 39.0792 19.6244 39.0792C9.38851 39.0792 1.05078 30.5772 1.05078 20.0396C1.05078 9.50202 9.38851 1 19.6244 1C29.8602 1 38.1979 9.50202 38.1979 20.0396Z' fill='white' strokeWidth='2' />
          <circle className={this.handleSelected(4, fill)} cx='13.2452' cy='15.3302' r='2.33019' />
          <circle className={this.handleSelected(4, fill)} cx='27.2262' cy='15.3302' r='2.33019' />
          <path className={this.handleSelected(4, fill)} d='M29.7088 24.1849H9.83047C9.44919 24.1849 9.18189 24.5611 9.30736 24.9212C12.7613 34.8324 26.778 34.8324 30.2319 24.9212C30.3574 24.5611 30.0901 24.1849 29.7088 24.1849Z' />
        </svg>
      </div>
    );
  }
}

export default Smiles;
