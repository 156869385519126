import React from 'react';
import { Tooltip } from '@mui/material';
import { UpcomingIcon } from '../../../../Components/Icons/Upcoming';
import { APPEAL_LEVELS } from '../../../../Lib/CONSTANTS';
import { Icon } from '../../../../Components/Shared/Icon';

export const getIconForSummary = (summary) => {
  if (summary) {
    switch (summary.responseStatus) {
    case 'PENDING_FROM_ADMIN': {
      const { age } = summary;
      switch (true) {
      case age <= 72:
        return (
          <Tooltip title={'Response required'}>
            <div style={{ position: 'relative' }}>
              <UpcomingIcon
                data-testid="grievance-alert"
                data-test-value="response-required"
                data-test-age={summary.appealLevel}
                style={{ fill: '#64748B' }} />
              {getAppealLevelIcon(summary)}
            </div>
          </Tooltip>
        );
      case age <= 120:
        return (
          <Tooltip title={'Response required. No response > 3 days'}>
            <div style={{ position: 'relative' }}>
              <Icon
                icon="Warning"
                data-testid="grievance-alert"
                data-test-value="response-required-more-than-3-days"
                data-test-age={summary.appealLevel}
                style={{
                  color: '#ffa726',
                  width: '1.6rem',
                  height: '1.6rem'
                }}
              />
              {getAppealLevelIcon(summary)}
            </div>
          </Tooltip>
        );
      case age > 120:
        return (
          <Tooltip title={'Response required. No response > 5 days'}>
            <div style={{ position: 'relative' }}>
              <Icon
                icon="Error"
                data-testid="grievance-alert"
                data-test-value="response-required-more-than-5-days"
                data-test-age={summary.appealLevel}
                style={{
                  color: '#f44336',
                  width: '1.6rem',
                  height: '1.6rem'
                }}
              />
              {getAppealLevelIcon(summary)}
            </div>
          </Tooltip>
        );

      default:
        break;
      }
      break;
    }

    case 'PENDING_FROM_LEARNER':
      return (
        <Tooltip title={'Responded'}>
          <div style={{ position: 'relative' }}>
            <Icon
              icon="CheckCircle"
              data-testid="grievance-alert"
              data-test-value="responded"
              data-test-age={summary.appealLevel}
              style={{ color: '#66bb6a', width: '1.6rem', height: '1.6rem' }}
            />
            {getAppealLevelIcon(summary)}
          </div>
        </Tooltip>
      );

    case 'COMPLETED':
      return (
        <Tooltip title={'Completed'}>
          <div style={{ position: 'relative' }}>
            <Icon
              icon="CheckCircle"
              data-testid="grievance-alert"
              data-test-value="completed"
              data-test-age={summary.appealLevel}
              style={{ color: '#66bb6a', width: '1.6rem', height: '1.6rem' }}
            />
            {getAppealLevelIcon(summary)}
          </div>
        </Tooltip>
      );

    default:
      return null;
    }
  }
};

const appealLevelIcon = {
  position: 'absolute',
  borderRadius: '50%',
  fontSize: '8px',
  padding: '2px',
  color: 'white',
  height: '14px',
  width: '14px',
  top: '13px',
  left: '15px',
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const getAppealLevelIcon = (summary) => {
  const { appealLevel } = summary;
  if (appealLevel && appealLevel == APPEAL_LEVELS.APPEAL_LEVEL_1.apiName) {
    return <span style={{ ...appealLevelIcon, backgroundColor: '#247DDC' }}>A1</span>;
  } else if (
    appealLevel &&
    appealLevel == APPEAL_LEVELS.APPEAL_LEVEL_2.apiName
  ) {
    return <span style={{ ...appealLevelIcon, backgroundColor: '#800080' }}>A2</span>;
  } else {
    return null;
  }
};

export const getNumberForAppealLevel = (appealLevel) => {
  const gLevel = {
    [APPEAL_LEVELS.INITIAL_REQUEST.apiName]: 1,
    [APPEAL_LEVELS.APPEAL_LEVEL_1.apiName]: 2,
    [APPEAL_LEVELS.APPEAL_LEVEL_2.apiName]: 3
  };
  return gLevel[appealLevel];
};

export const chunkifyArray = (array = [], chunkSize = 10) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};
