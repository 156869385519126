import React, { useCallback } from 'react';
import { Dialog, Typography, Table, Button } from '@nucleos/core-ui';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import Skeleton from 'react-loading-skeleton';
import { SortOrder, useTableSorting } from '../../../hooks/useTableSorting';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { Avatar } from '@mui/material';
import { LEARNING_RECORDS_PATH } from '../../../constants/paths';

const ColumnKeys = {
  AppName: 'name'
};

const defaultSorting = {
  column: ColumnKeys.AppName,
  sortOrder: SortOrder.Descending
};

export default function CourseListByUsersModal ({
  /*
   * Mode can be completed or in-progress
   */
  mode,
  onClose
}) {
  const history = useHistory();
  const userId = new URLSearchParams(history.location.search).get('userId');
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'course-by-user-modal'
    });
  const isOpen = !!userId && !!groupId;
  const userDetailsQuery = useQuery(
    QueryKeys.Users.item(userId),
    () => Data.getUser(userId),
    { enabled: isOpen && !!userId }
  );
  const requestData = {
    userId,
    apps: GLRContext.apps,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2],
    status: mode === 'completed' ? 'COMPLETED' : 'STARTED',
    order: columnSorting.sortOrder,
    orderBy: columnSorting.column
  };
  const courseQuery = useQuery(
    QueryKeys.Applications.listing(requestData),
    () => Data.getGroupLearningRecordUserCourses(requestData),
    { enabled: isOpen, select: (data) => data.result }
  );

  const viewLearningRecords = useCallback((userId) => {
    const url = `${window.location.origin}${LEARNING_RECORDS_PATH}/user/${userId}/learner-record`;

    window.open(url, '_blank').focus();
  }, []);

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        size="large"
        title={
          mode === 'completed' ? 'Courses Completed' : 'Courses In Progress'
        }
      >
        <div className="flex justify-between items-center my-5">
          <div className="flex items-center">
            {!userDetailsQuery.isSuccess || !courseQuery.isSuccess
              ? (
                <>
                  <Skeleton
                    count={1}
                    circle
                    height={40}
                    width={40}
                    className="mr-2"
                  />
                  <Skeleton count={1} width={300} height={20} />
                </>
              )
              : (
                <>
                  <Avatar>{`${userDetailsQuery.data.firstName[0]}${userDetailsQuery.data.lastName[0]}`}</Avatar>

                  <Typography
                    className="pl-4 font-semibold"
                    variant="h6"
                    style={{ color: '#19223D' }}
                  >
                    {`${userDetailsQuery.data.firstName} ${userDetailsQuery.data.lastName}`}
                  </Typography>
                </>
              )}
          </div>

          <div>
            <Button variant="text" style={{ color: '#449FFF' }} onClick={() => viewLearningRecords(userDetailsQuery.data.uid)}>View Learning Records</Button>
          </div>
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: '70vh' }}>
          <Table
            columns={[
              {
                title: 'App Name',
                render: (data) => data.name,
                enableSort: true,
                sortOrder: getCurrentSorting(ColumnKeys.AppName),
                onSortChange: (sortOrder) =>
                  setColumnSorting({
                    column: ColumnKeys.AppName,
                    sortOrder
                  })
              },
              {
                title: 'Course Name',
                render: (data) => {
                  const courses = data.Courses;

                  if (courses.length) {
                    if (courses.length > 1) {
                      return (
                        <ol>
                          {
                            courses.map((course, i) => <li key={course.title}>{`${i + 1}. ${course.title}`}</li>)
                          }
                        </ol>
                      );
                    } else {
                      return courses[0].title;
                    }
                  }
                }
              }
            ]}
            noDataMessage={<GenericNoDataFound />}
            loading={courseQuery.isLoading || userDetailsQuery.isLoading}
            rowsData={courseQuery.data || []}
          />
        </div>
      </Dialog>
    </div>
  );
}
