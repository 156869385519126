import './Modal.scss';
import React from 'react';
import { Modal, styled } from '@mui/material';
import { Icon } from '../../Components/Shared/Icon';

function getModalStyle (width, height) {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: width || 400 + 'px',
    height: height || 600 + 'px',
    borderRadius: '12px',
    outline: '0'
  };
}

const PREFIX = 'SimpleModal';
const classes = {
  paper: `${PREFIX}-paper`,
  modalClose: `${PREFIX}-modalClose`
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  [`& .${classes.modalClose}`]: {
    cursor: 'pointer'
  }
}));

const SimpleModal = ({ handleClose, open, title, width, children, height }) => {
  return (
    <StyledModal className='modal' aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description' open={!!open} onClose={handleClose}>
      <div style={getModalStyle(width, height)} className={classes.paper}>
        <header className='modal__header'>
          <span>{title}</span>
          <Icon icon="Close" onClick={handleClose} className={classes.modalClose} />
        </header>
        <div className='modal__body'>{children}</div>
      </div>
    </StyledModal>
  );
};

export default (SimpleModal);
