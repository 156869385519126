import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Grid, Button, Box, Typography, Stack, TextField } from '@mui/material';
import achieveDxpLoginArt from '../Assets/achieve-dxp-login-art.svg';
import checkLogo from '../Assets/check-logo.svg';
import Authentication from '../Stores/Authentication';
import { LINK_BLUE } from '../constants/colors';
import { LOGIN_PATH } from '../constants/paths';
import { Icon } from '../Components/Shared/Icon';

@withRouter
class ForgotPassword extends Component {
  static propTypes = {
    // react-router object see below
    history: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.state = {
      error: ''
    };
  }

  handleInputChange = ({ target: { value, name } }) => {
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  handleSubmit = event => {
    event.preventDefault();
    const { history } = this.props;
    const id = this.state.requestPasswordId;
    const data = id.includes('@') ? { email: id } : { username: id };
    Authentication.requestPasswordReset(data).then(() => {
      history.push('/password-change-requested');
    });
  }

  initialForm = () => (
    <Stack spacing={3} width='75%' className="flex-1 flex flex-col justify-center">
      <Typography sx={{ fontSize: '36px', fontWeight: 800, lineHeight: '49px' }}>Password Reset Request</Typography>

      <Box>
        <Typography variant="disabled" sx={{ fontSize: '15px', fontWeight: '400', lineHeight: '20px' }}>
            If you forgot your password, then click &lsquo;Request New Password&rsquo; below
            to request a password reset. A password will be sent to the email
            address you sent to set up your Nucleos account.
        </Typography>

        <section style={{ marginTop: '70px' }}>
          <form
            method='post'
            onSubmit={this.handleSubmit}
            className='auth__login-form'
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '19px' }}>
                Username or Email Address:
            </Typography>
            <Stack spacing={6} mt={1}>
              <TextField
                name='requestPasswordId'
                onChange={this.handleInputChange}
                placeholder='Enter username or email address'
                sx={{
                  height: '44px',
                  outline: 'none'
                }}
                InputProps={{
                  style: {
                    height: '44px',
                    fontSize: '15px'
                  }
                }}
              />
              <Button type='submit' color='primary' variant='contained' sx={{ padding: '10px 20px' }}>
                  Request Password Reset
              </Button>
            </Stack>
            <Box mt={2} display='flex' justifyContent='center'>
              <Button
                variant='text'
                startIcon={<Icon icon="ArrowBackIos" htmlColor={LINK_BLUE} fontSize='14px' />}
                onClick={() => {
                  this.props.history.push(LOGIN_PATH);
                }}
              >
                <Typography variant="link" sx={{ fontSize: '16px', fontWeight: 600 }}>Back to Login</Typography>
              </Button>
            </Box>
          </form>
        </section>
      </Box>
    </Stack>
  )

  submitMessage = () => (
    <Box>
      <Stack textAlign='center' spacing={4} mt={2} mr={10}>
        <Box>
          <img
            id="check-logo"
            alt="checklogo"
            src={checkLogo}
            style={{ height: '40px', width: '40px', marginBottom: '10px' }}
          />
          <Typography sx={{ fontSize: '36px', fontWeight: 800, lineHeight: '49px' }}>Password reset link sent</Typography>
        </Box>
        <Typography variant='disabled' sx={{ fontSize: '15px', fontWeight: 400, lineHeight: '20px' }}>
        For administrators, an email will be sent to your registered email address if an account
        exists. For all other users, please ask your teacher or administrator to
        provide you with your password or initiate a password reset process.
        </Typography>
        <Box>
          <Button variant="contained" sx={{ padding: '10px 20px', width: '167px', mt: '10px' }} onClick={() => this.props.history.push(LOGIN_PATH)}>Back to Login</Button>
        </Box>
      </Stack>
    </Box>
  )

  render () {
    const { location } = this.props;
    const { pathname } = location;
    const isSubmitted = pathname === '/password-change-requested';

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
        <div style={{ maxWidth: '80%' }}>
          <Box ml={12}>
            <Grid container>
              <Grid xs={6} item mt='80px'>
                <Box>
                  {!isSubmitted ? this.initialForm() : this.submitMessage()}
                </Box>
              </Grid>
              <Grid xs={6} item>
                <img
                  id="login-art"
                  alt="Login art"
                  src={achieveDxpLoginArt}
                  className="w-full h-full ml-20"
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
