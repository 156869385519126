export const WELCOME_PATH = '/';
export const LOGOUT_PATH = '/logout';
export const LOGIN_PATH = '/login';
export const PASSWORD_CHANGE_REQUESTED_PATH = '/password-change-requested';

export const CHANGE_PASSWORD_PATH = '/change-password/:code';
export const VERIFY_PATH = '/verify/:code';
export const LEARNER_RECORD_PDF_PATH = '/user/:uid/learner-record/pdf';
export const GROUP_RECORD_PDF_PATH = '/group/records/pdf';
export const GRIEVANCE_PDF_PATH = '/forms/admin/grievance/:grievanceId/pdf';
export const REDIRECT_PATH = '/redirect';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const HOME_PATH = '/home';

export const COURSE_CONTENT_LIBRARY_PATH = '/course-content-library';
export const COURSE_CONTENT_APPLICATION_PATH = ['/course-content-library/:uid', '/course-content-library/:uid/:tabId'];

export const LEARNING_RECORDS_PATH = '/learner-records';
export const LEARNER_UID_PATH = `${LEARNING_RECORDS_PATH}/user/:uid`;
export const LEARNER_RECORD_DETAILS_PATH = `${LEARNER_UID_PATH}/:tabId`;
export const LEARNING_RECORD_PATH = [
  `${LEARNER_UID_PATH}/learner-record`,
  `${LEARNER_UID_PATH}/usage-history`,
  `${LEARNER_UID_PATH}/learning-overview`
];

export const INVESTIGATIVE_PATH = '/investigative-tools';
export const LIVE_ACTIVITY_PATH = '/investigative-tools/live-activity';
export const LIVE_ACTIVITY_MODAL_PATH = '/investigative-tools/show-live-activity-modal';
export const ACTIVITY_LOOKUP_PATH = '/investigative-tools/activity-lookup';
export const ACTIVITY_LOOKUP_DETAILS_PATH =
  '/investigative-tools/activity-lookup/:uid';

export const GROUP_LEARNING_RECORDS_PATH = '/group-reports';

export const USER_ANG_GROUP_PATH = '/users-and-group';
export const LEARNER_USER_PATH = `${USER_ANG_GROUP_PATH}/learners`;
export const NON_LEARNER_USER_PATH = `${USER_ANG_GROUP_PATH}/non-learners`;
export const USER_PATH = `${USER_ANG_GROUP_PATH}/users`;
export const GROUP_PATH = `${USER_ANG_GROUP_PATH}/groups`;
export const ROLES_SETTINGS_PATH = `${USER_ANG_GROUP_PATH}/roles`;

export const LEARNER_SESSION_PATH = '/learner-session';
export const LEARNER_ACTIVE_SESSION_PATH = '/learner-session/active-sessions';
export const LEARNER_SESSION_LOOKUP_PATH = '/learner-session/:tabId';
export const FLAGGED_ACTIVITY_PATH = `${LEARNER_SESSION_PATH}/flagged-activity/:uid`;

export const SETTINGS_USER_PATH = `${USER_PATH}/:uid`;
export const SETTINGS_GROUP_PATH = `${GROUP_PATH}/:uid`;
export const ACCOUNT_SETTINGS_PATH = '/account-settings';
export const CONSENT_PATH = '/consent';
export const KEEFE_INTEGRATION_PATH = '/integrations/keefe';
export const UNDER_CONSTRUCTION_PATH = '/under-construction';
export const NOTIFICATIONS_PATH = '/notifications';

export const GRIEVANCE_PATH = '/forms/admin';
export const GRIEVANCE_SETTINGS = '/forms/admin/settings';
export const RESPONSES_GRIEVANCE_PATH = '/forms/admin/grievances';
export const GRIEVANCES_FORMS_PATH = '/forms/grievances';
export const ADD_GRIEVANCE_PATH = '/forms/grievances/add';
export const VIEW_GRIEVANCE_PATH = '/forms/grievances/view/:id';
export const GRIEVANCE_REQUEST_REPORT = '/forms/admin/reports';

export const MESSENGER_ADMIN_PATH = '/messenger/admin';
export const FLAGGED_MESSAGES_LIST_PATH =
'/messenger/admin/messageAlerts/:keyword';
export const MESSAGE_ALERTS_PATH = '/messenger/admin/messageAlerts';
export const MESSENGER_ADMIN_USERS_PATHS = '/messenger/admin/users';
export const ALL_MESSAGES_LIST_PATH = '/messenger/admin/messages';
export const MESSAGE_DETAILS_PATH = '/messenger/admin/messages/:id';
export const MESSENGER_USER_DETAIL_PATH = '/messenger/admin/users/:uid';
export const MESSENGER = '/messenger';
export const MESSENGER_USER_PATH = `${MESSENGER}/user/:uid`;
export const MESSAGE_USER_PATH = '/messenger/admin/messenger';
export const MESSAGE_USER_DETAILS_PATH = `${MESSAGE_USER_PATH}/user/:uid`;
