import React, { useState } from 'react';
import NewGrievanceDetails from './NewGrievanceDetails';
import { Button, Typography } from '@nucleos/core-ui';

const SpeakToSupervisor = ({ user, nextStep, previousStep, grievance, sendBackToListPage }) => {
  const [spokeToSupervisor, setSpokeToSupervisor] = useState(null);
  const [error, setError] = useState('');

  const validateNext = () => {
    if (spokeToSupervisor === null) { return setError('Please select one of the above options.'); }

    setError('');
    nextStep();
  };

  const message =
    spokeToSupervisor === false
      ? `Before writing ${grievance.requestType === 'ACTION_REQUEST' ? 'an action request' : 'a grievance'}, speak with the Housing Deputy and/or a supervisor to resolve the issue.`
      : 'Have you first spoken with a Housing Deputy or Supervisor to resolve the issue?';

  return (
    <div className="nucleos-core">
      <Typography variant="h1" className="mb-6">
        Did you speak to Supervisor?
      </Typography>
      <NewGrievanceDetails
        cellNumber={user.cellNumber}
        jailName={user.Facility ? user.Facility.name : ''}
        category={grievance.category || {}}
        requestorName={`${user.firstName} ${user.lastName}`}
      />
      <div>
        <div className="mt-6 p-8 border border-gray-light rounded-xl bg-background-light">
          <Typography variant="h4" className={spokeToSupervisor === false ? 'font-extrabold' : ''}>{message}</Typography>
          <Typography
            variant="h6"
            className={`mt-4 ${spokeToSupervisor === false ? '' : 'hidden'}`}
          >
            {`Would you still like to continue and submit ${grievance.requestType === 'ACTION_REQUEST' ? 'an action request' : 'a grievance'}?`}
          </Typography>
          <div
            className={`flex mt-4 ${
              spokeToSupervisor === false ? 'hidden' : ''
            }`}
          >
            <div
              className={
                'py-2 px-4 mr-4 border rounded-md cursor-pointer border-gray-light hover:border-primary-light ' +
                (spokeToSupervisor === true ? 'border-primary-light' : '')
              }
              onClick={() => setSpokeToSupervisor(true)}
            >
              <div className="flex">
                <div className="flex">
                  <Typography
                    variant="body1"
                    color={spokeToSupervisor === true ? 'primary' : 'none'}
                  >
                    Yes, but still I need help
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={
                'py-2 px-4 border rounded-md cursor-pointer border-gray-light hover:border-primary-light ' +
                (spokeToSupervisor === false ? 'border-primary-light' : '')
              }
              onClick={() => setSpokeToSupervisor(false)}
            >
              <div className="flex">
                <div className="flex">
                  <Typography
                    variant="body1"
                    color={spokeToSupervisor === false ? 'primary' : 'none'}
                  >
                    No, I haven’t spoken yet
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <Typography className="mt-2" color="error">
            {error}
          </Typography>
        </div>
      </div>
      <div className="mt-6 flex justify-between">
        <Button
          variant="outlined"
          className="bg-background-light"
          onClick={() =>
            spokeToSupervisor === false ? sendBackToListPage() : previousStep()
          }
        >
          {spokeToSupervisor === false ? 'No, Cancel Request' : 'Back'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => validateNext()}
        >
          {spokeToSupervisor === false ? 'Yes, Continue' : 'Next Step'}
        </Button>
      </div>
    </div>
  );
};
export default SpeakToSupervisor;
