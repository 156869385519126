import React, { useCallback, useState } from 'react';
import Data from '../../Middleware/Data';
import { useNotificationsQuery } from '../../hooks/useNotificationsQuery';

const initialValue = {
  notifications: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
  isFetching: false,
  isFetchingNextPage: false,
  unreadNotificationsCount: null,

  showUnread: true,
  category: false,
  search: false,

  fetchNextPage: async () => { },
  refetchNotifications: async () => { },

  setShowUnread: () => { },
  setCategory: () => { },
  setSearch: () => { },

  markAsRead: async () => { },
  archiveNotification: async () => { }
};

export const NotificationsContext = React.createContext(initialValue);

export const useNotificationsContext = () => React.useContext(NotificationsContext);

export const NotificationsContextProvider = ({ children }) => {
  const [showUnread, setShowUnread] = useState(false);
  const [category, setCategory] = useState('');
  const [search, setSearch] = useState('');

  const notificationsQuery = useNotificationsQuery({
    showUnread,
    category,
    search
  });

  const markAsRead = useCallback(async ({ notificationId, all = false }) => {
    await Data.markNotificationsAsRead({
      all,
      notificationIds: [notificationId],
      category,
      searchTerm: search
    });

    notificationsQuery.refetch();
  }, [notificationsQuery, category, search, Data.markNotificationsAsRead]);

  const archiveNotification = useCallback(async (notificationId) => {
    const response = await Data.dismissNotification(notificationId);

    notificationsQuery.refetch();

    return response;
  }, [notificationsQuery, Data.dismissNotification]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications: notificationsQuery.data.pages,
        isLoading: notificationsQuery.isLoading || notificationsQuery.isPlaceholderData,
        isSuccess: notificationsQuery.isSuccess,
        isError: notificationsQuery.isError,
        isFetching: notificationsQuery.isFetching,
        isFetchingNextPage: notificationsQuery.isFetchingNextPage,
        unreadNotificationsCount: notificationsQuery.data.data[0] && notificationsQuery.data.data[0].unreadNotificationsCount,

        showUnread,
        category,
        search,

        fetchNextPage: notificationsQuery.fetchNextPage,
        refetchNotifications: notificationsQuery.refetch,

        setShowUnread,
        setCategory,
        setSearch,

        markAsRead,
        archiveNotification
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
