import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Typography, TextInput } from '@nucleos/core-ui';
import { Icon } from '../../../Components/Shared/Icon';
import { ERROR_RED } from '../../../constants/colors';

const deleteLicenseSchema = yup.object().shape({
  note: yup.string().max(250, 'Number of characters exceeded the limit of 250.').optional()
});

const DeleteLicensePopup = ({ username, courseName, licenseId, onSubmitHandler, onCloseModal }) => {
  const deleteLicenseForm = useForm({
    resolver: yupResolver(deleteLicenseSchema),
    defaultValues: { note: '' }
  });

  const closeModalHandler = () => {
    deleteLicenseForm.reset({ note: '' });
    onCloseModal();
  };

  return (
    <div className="nucleos-core">
      <div className="mx-auto my-0 w-full max-w-md">
        <div
          className="flex flex-col justify-start items-center p-10 rounded-md"
          style={{ background: '#ffffff' }}
        >
          <div>
            <Icon icon="CircleCancel"
              className="h-14 w-14"
              style={{ fill: ERROR_RED }}
            />
          </div>
          <div className="text-2xl my-5">Are you sure?</div>
          <Typography className="text-center">
            <span>Do you want to delete <b>{username}</b>&apos;s license for the <b>{courseName}</b>?</span>
          </Typography>
          <form
            className="w-full mt-3"
            onSubmit={(e) => {
              e.preventDefault();
              deleteLicenseForm.handleSubmit((data) =>
                onSubmitHandler(data, username, licenseId, onCloseModal)
              )(e);
            }}
          >
            <div>
              <Controller
                name="note"
                control={deleteLicenseForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    style={{ resize: 'none' }}
                    placeholder="Add a note..."
                    multiline
                    rows={3}
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!fieldState.error}
                    fullWidth
                  />
                )}
              />
            </div>
            <Typography className="flex justify-end italic">
              Maximum 250 characters
            </Typography>
            <div className="flex items-center justify-center mt-8">
              <Button onClick={closeModalHandler} className="mr-2.5">Cancel</Button>
              <Button color="error" type="submit" variant="contained" className="ml-2.5">
                Yes, Delete
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteLicensePopup;
