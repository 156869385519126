import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Divider, IconButton, InputAdornment, TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  TableSortLabel,
  Grid,
  Button,
  styled,
  Box
} from '@mui/material';
import debounce from 'lodash.debounce';
import FlexTileGrid from '../../../../Components/Shared/TileGrid/TileGrid';
import LoadingComponent from '../../../../Components/Widgets/LoadingComponent';
import DataStore from '../../../../Stores/DataStore';
import { PAGE_ROW_LIMIT } from '../../../../Lib/CONSTANTS';
import { formatDateTime, getMessageSentFrom, getMessageSentTo } from '../../../../Lib/util';
import { Input } from '../../../../Components/Form';
import UserSearch from '../../../../Components/Widgets/UserSearch/_UserSearch';
import { Icon } from '../../../../Components/Shared/Icon';
import HeaderSubPanel from '../../../../Components/HeaderSubPanel';
import { MESSENGER_ADMIN_PATH } from '../../../../constants/paths';

const PREFIX = 'Messages';
const classes = {
  searchField: `${PREFIX}-searchField`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
  link: `${PREFIX}-link`,
  divider: `${PREFIX}-divider`,
  noBorderIconBtn: `${PREFIX}-noBorderIconBtn`,
  filters: `${PREFIX}-filters`,
  clearUserFilter: `${PREFIX}-clearUserFilter`
};

const Root = styled('div')(() => ({
  [`& .${classes.searchField}`]: {
    borderRadius: '20px',
    padding: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    width: '547px'
  },
  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  [`& .${classes.link}`]: {
    color: '#449FFF !important',
    textDecoration: 'underline'
  },
  [`& .${classes.divider}`]: {
    marginTop: '20px'
  },
  [`& .${classes.noBorderIconBtn}`]: {
    borderBottom: '0 !important'
  },
  [`& .${classes.filters}`]: {
    background: '#e9f0ff',
    padding: '20px',
    borderRadius: '10px'
  },
  [`& .${classes.clearUserFilter}`]: {
    marginLeft: '20px',
    color: 'red',
    fontWeight: '600',
    cursor: 'pointer'
  }
}));

const breadcrumbLinks = [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: '#', label: 'Messages' }
];

const AllMessagesList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [messagesCount, setMessagesCount] = useState(0);
  const [orderBy, setOrderBy] = React.useState('username');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showFlagged, setShowFlagged] = useState(false);
  const [fromUser, setFromUser] = useState(null);
  const [toUser, setToUser] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    startDate: {
      error: false,
      message: ''
    },
    endDate: {

      error: false,
      message: ''
    }
  });
  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const getFilters = () => {
    return {
      startDate,
      endDate,
      showFlagged,
      fromUser: fromUser ? fromUser.uid : null,
      toUser: toUser ? toUser.uid : null
    };
  };

  const resetSortAndSearch = () => {
    setSearchQuery('');
    setOrderBy('username');
    setOrder('asc');
    setPage(0);
  };

  const applyFilters = async () => {
    resetSortAndSearch();
    if (startDate == '' && endDate == '') {
      getAllMessages('', 0, 'username', 'asc');
    } else {
      if (startDate == '' && endDate != '') {
        setValidationErrors({
          ...validationErrors,
          startDate: {
            error: true,
            message: 'Required'
          }
        });
      } else if (endDate == '' && startDate != '') {
        setValidationErrors({
          ...validationErrors,
          endDate: {
            error: true,
            message: 'Required'
          }
        });
      } else {
        if (startDate != '' && endDate != '') {
          if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
            setValidationErrors({
              ...validationErrors,
              endDate: {
                error: true,
                message: 'End Date should be greater than Start Date'
              }
            });
          } else {
            getAllMessages('', 0, 'username', 'asc');
          }
        } else {
          getAllMessages('', 0, 'username', 'asc');
        }
      }
    }
  };

  const getAllMessages = async (query, page, orderBy, order, filters) => {
    setMessagesLoading(true);
    const { messages, count } = await DataStore.getAllMessages(query, page, orderBy, order, filters || getFilters());
    setAllMessages(messages);
    setMessagesCount(count);
    setMessagesLoading(false);
  };

  useEffect(() => {
    getAllMessages(searchQuery.toLowerCase(), page, orderBy, order);
  }, [page]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setPage(0);
    setSearchQuery(query);
    const filters = getFilters();
    debouncedSearch(query, filters);
  };

  const debouncedSearch = useRef(
    debounce(async (query, filters) => {
      await getAllMessages(query.toLowerCase(), 0, orderBy, order, filters);
    }, 500)
  ).current;

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    getAllMessages(searchQuery.toLowerCase(), 0, orderBy, order);
  };

  const dateChangeHandler = (e) => {
    if (e.target.name == 'startDate') {
      if (validationErrors.startDate.error) { setValidationErrors({ ...validationErrors, startDate: { error: false } }); }
      setStartDate(e.target.value);
    } else {
      if (validationErrors.endDate.error) { setValidationErrors({ ...validationErrors, endDate: { error: false } }); }
      setEndDate(e.target.value);
    }
  };

  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title="Messages" links={breadcrumbLinks} />
      <Root
        className='user-update'
      >
        <div style={{ maxWidth: '100%', fontSize: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ maxWidth: '60%', paddingRight: '50px' }}>
            <div style={{ display: 'flex', paddingBottom: '20px' }}>
              <b style={{ maxWidth: '20%', paddingRight: '20px' }}>Messages: </b>
            </div>
            <TextField
              className={classes.searchField}
              onChange={handleSearch}
              value={searchQuery}
              placeholder='Search Messages'
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <Icon icon='Search' />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { height: '32px' }
              }}
              sx={{
                '& fieldset': { border: 'none' }
              }}
            />
          </div>
        </div>
        <Divider className={classes.divider} variant='fullWidth' />
        <div style={{ paddingTop: '20px' }}>
          <div style={{ display: 'flex', paddingBottom: '20px' }}>
            <b style={{ paddingRight: '10px' }}>Message Filters </b>
            <InputAdornment>
              <IconButton onClick={toggleFilters} className={classes.noBorderIconBtn} style={{ marginTop: '20px' }}>
                {
                  filtersOpen ? <Icon icon="ArrowDropUp" /> : <Icon icon="ArrowDropDown" />
                }
              </IconButton>
            </InputAdornment>
          </div>
          {
            filtersOpen &&
            <Box className={classes.filters}>
              <Grid container spacing={4} mb='14px'>
                <Grid item xs={3}>
                  <Input
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    value={startDate}
                    onChange={dateChangeHandler}
                    type="date"
                    maxDate={endDate}
                    defaultValue={new Date()}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    touched={validationErrors.startDate.error}
                    error={validationErrors.startDate.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    value={endDate}
                    onChange={dateChangeHandler}
                    type="date"
                    minDate={startDate}
                    defaultValue={new Date()}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    touched={validationErrors.endDate.error}
                    error={validationErrors.endDate.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Input
                    id="showFlagged"
                    name="showFlagged"
                    value={showFlagged}
                    onChange={() => setShowFlagged(!showFlagged)}
                    label="Only show flagged messages"
                    type="checkbox"
                    defaultValue={new Date()}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3} className="nucleos-field-container input-container">
                  <div className="nucleos-field-label-wrapper input-wrapper">
                    <label className="nucleos-label input-label">From</label>
                    <UserSearch className="user-searchable-input w-200" isClearable={true}
                      classNamePrefix="user-searchable-input" onSelectUser={setFromUser} />
                  </div>

                </Grid>
                <Grid item xs={3} className="nucleos-field-container input-container">
                  <div className="nucleos-field-label-wrapper input-wrapper">
                    <label className="nucleos-label input-label">To</label>
                    <UserSearch className="user-searchable-input w-200" isClearable={true} classNamePrefix="user-searchable-input" onSelectUser={setToUser} />
                  </div>

                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant='contained' color='primary' className='user__approveFieldButton' onClick={applyFilters} disabled={messagesLoading} sx={{ padding: '10px 20px' }}>
                  Apply Filters
                </Button>
              </div>

            </Box>
          }
        </div>
        <Divider className={classes.divider} variant='fullWidth' />
        {
          messagesLoading
            ? <LoadingComponent />
            : allMessages.length === 0
              ? <h2>No messages available, please contact your system administrator if this continues.</h2>
              : <section style={{ marginBottom: '42px' }}>
                <div style={{ display: 'flex', paddingBottom: '20px' }}>
                  <b style={{ maxWidth: '20%', paddingRight: '20px' }}>Contact Messages: </b>
                </div>
                <FlexTileGrid
                  gridColTiers={{ xs: 12 }}
                  tileStyle={{ padding: 0, maxWidth: '100%', overflow: 'scroll' }}
                  className='usage-history__table-container'
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>From</TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>To</TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>Message</TableCell>
                        <TableCell style={{ whiteSpace: 'nowrap' }}>Alert Flag</TableCell>
                        <TableCell
                          key={'date'}
                          style={{ whiteSpace: 'nowrap' }}
                          sortDirection={orderBy === 'date' ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === 'date'}
                            direction={orderBy === 'date' ? order : 'asc'}
                            onClick={createSortHandler('date')}
                          >
                          Date Sent
                            {orderBy === 'date'
                              ? (
                                <span className={classes.visuallyHidden}>
                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                              )
                              : null}
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allMessages.map((row, index) => {
                        const from = getMessageSentFrom(row);
                        const to = getMessageSentTo(row);
                        return <TableRow
                          key={row.uid}
                          style={{
                            background: index % 2 ? 'white' : '#f8f8fb'
                          }}
                        >
                          <TableCell style={{ whiteSpace: 'nowrap' }}><Link className={classes.link} to={`${from.url}`}>{from.name}</Link></TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}><Link className={classes.link} to={`${to.url}`}>{to.name}</Link></TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}><Link className={classes.link} to={`/messenger/admin/messages/${row.id}`}>{row.message}</Link></TableCell>
                          <TableCell className={classes.flaggedRow}>{row.flagged ? '!' : ''}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDateTime(row.createdAt)}</TableCell>
                        </TableRow>;
                      })}
                    </TableBody>
                  </Table>
                </FlexTileGrid>
                <TablePagination component="div" count={messagesCount} rowsPerPage={PAGE_ROW_LIMIT} page={page} onPageChange={(event, nextPage) => {
                  setPage(nextPage);
                }} rowsPerPageOptions={[10]} />
              </section>

        }
        <Divider variant='fullWidth' />
      </Root>

    </Box>
  );
};

export default (observer(AllMessagesList));
