import moment from 'moment';

export function usageData (totals, setEmptyFirstDay = true) {
  const applications = new Set(Object.keys(totals).flatMap(day => {
    return Object.keys(totals[day]).map(app => app).filter(key => totals[day][key] !== 0);
  }));
  const preparedTotals = Object.keys(totals).map(key => {
    return { day: key, ...totals[key] };
  });
  const emptyFirstDay = {
    day: '',
    ...Array.from(applications).reduce((noActivity, application) => ({ ...noActivity, [application]: 0 }), {})
  };
  return {
    data: (setEmptyFirstDay ? [emptyFirstDay, ...preparedTotals] : preparedTotals).sort(({ day: a }, { day: b }) => new Date(a).getTime() - new Date(b).getTime()),
    applications
  };
}

export function getRandomColor () {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const colors = [
  'rgba(71, 142, 255, 1)',
  'rgba(247, 57, 57, 1)'
];

export function minutesToHHMM (numberOfMinutes) {
  const duration = moment.duration(numberOfMinutes, 'minutes');
  const hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());
  const mm = duration.minutes();
  return `${hh} hrs ${mm} mins`;
}

export const COLORSET = [
  '#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#E377C2', '#7F7F7F', '#BCBD22', '#17BECF',
  '#FF9896', '#98DF8A', '#9679ba', '#7f84d1', '#b16116', '#e2bbde', '#34dba5', '#17a4e3', '#f8ca01', '#988c0e',
  '#fcb61c', '#704bf3', '#9bc1e2', '#a1f084', '#f14b06', '#4e4678', '#ad6b33', '#8ed137', '#dad573', '#506381',
  '#4B8AF9', '#FFA44C', '#36D836', '#E84E4E', '#A688D4', '#9F7263', '#EB6FAF', '#A8A8A8', '#C1C333', '#2BBDD8',
  '#FFB5B3', '#A5E994', '#9C81C6', '#8D927E', '#FFD458', '#49E9B1', '#1F8ED9', '#F9D503', '#B5A41E', '#FECF49'
];

export function formatNumber (number) {
  if (typeof number !== 'number') { return number; }
  if (number >= 1e6) {
    // Convert the number to M format for millions
    return (number / 1e6).toFixed(2) + 'M';
  } else if (number >= 1e3) {
    // Convert the number to K format for thousands
    return (number / 1e3).toFixed(1) + 'K';
  } else {
    // If the number is less than 1000, leave it as is
    return number.toString();
  }
}
