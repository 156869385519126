import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';
import UserGoals from './UserGoals';
import UserProgress from './UserProgress';
import './LearningOverview.scss';
import { SetPageTitleFromUsername } from '../../Lib/SetPageTitleFromUsername';
import AuthenticationStore from '../../Stores/Authentication';
import DataStore from '../../Stores/DataStore';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import ScrollToTop from '../../Components/ScrollToTop';

const LearningOverview = () => {
  const { uid: loggedInUserId } = AuthenticationStore;
  const {
    currentUser,
    getUser
  } = DataStore;

  const { uid } = useParams();

  useEffect(
    () => {
      if (uid || loggedInUserId) {
        getUser(uid || loggedInUserId);
      }
    },
    [uid, loggedInUserId]
  );

  return (
    <React.Fragment>
      <SetPageTitleFromUsername />
      <ScrollToTop />
      <div className='learning-overview'>
        {
          currentUser.uid
            ? (
              <section>
                <div>
                  <Grid className='nucleos-core' container id={'overview-data'}>
                    <Grid className='w-full'>
                      <UserGoals />
                    </Grid>
                    <Grid className='mt-10 w-full'>
                      <UserProgress />
                    </Grid>
                  </Grid>
                </div>
              </section>
            )
            : (
              <LoadingComponent />
            )
        }
      </div>
    </React.Fragment>
  );
};

export default observer(LearningOverview);
