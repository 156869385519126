const lang = {
  ENG: {
    code: 'ENG',
    heading: 'Consent to store and use personal Information',
    consent: 'I consent',
    logout: 'logout',
    langname: 'English',
    langword: 'Language'
  },
  SPA: {
    code: 'SPA',
    heading: 'Consentimiento para almacenar y usar información personal',
    consent: 'yo consiento',
    logout: 'cerrar sesión',
    langname: 'español',
    langword: 'Idioma'
  },
  YUE: {
    code: 'YUE',
    heading: '同意存儲和使用個人信息',
    consent: '同意',
    logout: '註銷',
    langname: '广东话',
    langword: '語言'
  }
};

export { lang };
