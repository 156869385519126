/**
 * External dependencies
 */
import React from 'react';

/**
 * Internal dependencies
 */
import { useQueryClient } from 'react-query';

const withQueryClient = (Element) => {
  return React.forwardRef((props, ref) => {
    const queryClient = useQueryClient();
    return <Element queryClient={queryClient} {...props} ref={ref} />;
  });
};

export default withQueryClient;
