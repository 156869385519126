import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react';

import AuthenticationStore from '../../Stores/Authentication';
import DataStore from '../../Stores/DataStore';
import { Icon } from '../Shared/Icon';
import { useGrievanceRole } from '../../hooks/useGrievancePermissions';
import { ACTIVE_ROLES, ROLES } from '../../Lib/CONSTANTS';
import useUserPermissions from '../../hooks/useUserPermissions';
import {
  ACTIVITY_LOOKUP_PATH, ALL_MESSAGES_LIST_PATH, COURSE_CONTENT_LIBRARY_PATH, GRIEVANCES_FORMS_PATH, GRIEVANCE_PATH, GRIEVANCE_REQUEST_REPORT, GRIEVANCE_SETTINGS, GROUP_LEARNING_RECORDS_PATH, INVESTIGATIVE_PATH, LEARNER_SESSION_PATH, LEARNING_RECORDS_PATH, LIVE_ACTIVITY_MODAL_PATH, MESSAGE_ALERTS_PATH, MESSAGE_USER_PATH, MESSENGER, MESSENGER_ADMIN_PATH, MESSENGER_ADMIN_USERS_PATHS, RESPONSES_GRIEVANCE_PATH, USER_ANG_GROUP_PATH
} from '../../constants/paths';

const StyledListItemIcon = styled(({ open, ...props }) => <ListItemIcon {...props} sx={{ minWidth: open ? 36 : 'unset' }} />)``;

const StyledListItemButton = styled(
  ({ open, ...props }) => (
    <ListItemButton
      sx={{ justifyContent: open ? 'flex-start' : 'center' }}
      {...props}
    />
  ))(({ theme }) => ({
  'padding': theme.spacing(1),
  'borderRadius': 12,
  'width': '100%',

  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  flexDirection: 'column',
  color: theme.palette.primary.contrastText,
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5)
}));

const Sidebar = (props) => {
  const { open } = props;

  const location = useLocation();

  const userPermissions = useUserPermissions();

  const isAdmin = AuthenticationStore.userRole === 'admin';
  const isLearner = AuthenticationStore.userRole === 'learner';
  const documentationURL = `https://nucleos-docs.webflow.io/${isAdmin ? 'admin/admin' : 'learners/learner'}-access?role=${isAdmin ? 'admin' : 'learner'}`;

  const [expandedMenuKeys, setExpandedMenuKeys] = useState({});

  const toggleSubmenuExpand = useCallback((key) => {
    setExpandedMenuKeys({
      ...expandedMenuKeys,
      [key]: !expandedMenuKeys[key]
    });
  }, [expandedMenuKeys]);

  const grievanceRole = useGrievanceRole();
  const userRole = DataStore.self.Role ? DataStore.self.Role.name : '';
  const permissionMap = useMemo(() => ({
    'investigative-tools': grievanceRole.canGoToInvestigativeTools(),
    'all-grievances': grievanceRole.canGoToGrievances(),
    'grievance-settings': userRole === ROLES.SUPER_ADMIN,
    'learning-records': userPermissions.canViewLearningRecords(),
    'group-learning-records': userPermissions.canViewGroupLearningRecords(),
    'messenger-admin': userPermissions.canViewMessenger() && userPermissions.role !== ACTIVE_ROLES.LEARNER,
    'messenger': userPermissions.canViewMessenger(),
    'messenger-conversation-view': userPermissions.canViewMessengerConversationView(),
    'learner-session': userPermissions.canViewLearnerSessions()
  }), [grievanceRole, userPermissions, userRole]);

  const filterMenuItems = useCallback((items) => {
    return items.filter(item => {
      if (item.allowedRoles && !item.allowedRoles.includes(userPermissions.role)) {
        return false;
      }

      if (item.permissionKey && !permissionMap[item.permissionKey]) {
        return false;
      }

      if (item.children) {
        item.children = filterMenuItems(item.children);
      }

      return true;
    });
  }, [permissionMap, userPermissions.role]);

  const optionsMain = useMemo(() => {
    const items = [{
      name: 'Home',
      icon: 'Home',
      link: '/home',
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER, ACTIVE_ROLES.LEARNER]
    }, {
      name: isLearner ? 'All Applications' : 'Course & Content Library',
      icon: 'CourseContentLibrary',
      link: COURSE_CONTENT_LIBRARY_PATH,
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.LEARNER]
    }, {
      name: 'Learning Records',
      icon: 'LearningRecords',
      link: LEARNING_RECORDS_PATH,
      expandedKey: 'learning-records',
      permissionKey: 'learning-records',
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN],
      children: [{
        name: 'Learner Records',
        link: LEARNING_RECORDS_PATH,
        permissionKey: 'learning-records'
      }, {
        name: 'Group Learning Records',
        link: GROUP_LEARNING_RECORDS_PATH,
        permissionKey: 'group-learning-records'

      }]
    }, {
      name: 'Learner Records',
      icon: 'LearningRecords',
      link: LEARNING_RECORDS_PATH,
      permissionKey: 'learning-records',
      allowedRoles: [ACTIVE_ROLES.LEARNER]
    }, {
      name: 'Investigative Toolset',
      icon: 'InvestigativeToolset',
      link: INVESTIGATIVE_PATH,
      expandedKey: 'investigative-tools',
      permissionKey: 'investigative-tools',
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.INVESTIGATOR],
      children: [{
        name: 'Activity Lookup',
        link: ACTIVITY_LOOKUP_PATH,
        permissionKey: 'investigative-tools'
      }, {
        name: 'Live Activity',
        link: LIVE_ACTIVITY_MODAL_PATH,
        permissionKey: 'investigative-tools'
      }, {
        name: 'Learner Session Lookup',
        link: LEARNER_SESSION_PATH,
        permissionKey: 'learner-session'
      }]
    }, {
      name: 'Grievances',
      icon: 'Grievances',
      link: GRIEVANCE_PATH,
      expandedKey: 'grievances',
      permissionKey: 'all-grievances',
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER],
      children: [{
        name: 'Grievances & Forms',
        link: RESPONSES_GRIEVANCE_PATH,
        permissionKey: 'all-grievances'
      }, {
        name: 'Grievances & Forms Settings',
        link: GRIEVANCE_SETTINGS,
        permissionKey: 'grievance-settings'
      }, {
        name: 'Grievance Report',
        link: GRIEVANCE_REQUEST_REPORT,
        allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN]
      }]
    }, {
      name: 'Grievances and Forms',
      icon: 'Grievances',
      link: GRIEVANCES_FORMS_PATH,
      allowedRoles: [ACTIVE_ROLES.LEARNER],
      permissionKey: 'all-grievances'
    }, {
      name: 'Messenger',
      icon: 'Messenger',
      link: MESSENGER_ADMIN_PATH,
      expandedKey: 'messenger',
      permissionKey: 'messenger-admin',
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER],
      children: [{
        name: 'Users',
        link: MESSENGER_ADMIN_USERS_PATHS,
        allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.INVESTIGATOR]
      }, {
        name: 'Messages',
        link: ALL_MESSAGES_LIST_PATH,
        allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.INVESTIGATOR]
      }, {
        name: 'Message Alerts',
        link: MESSAGE_ALERTS_PATH,
        allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.INVESTIGATOR]
      }, {
        name: 'Message Users',
        link: MESSAGE_USER_PATH,
        permissionKey: 'messenger-conversation-view',
        allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER]
      }]
    }, {
      name: 'Messenger',
      icon: 'Messenger',
      link: MESSENGER,
      permissionKey: 'messenger',
      allowedRoles: [ACTIVE_ROLES.LEARNER]
    }];
    return filterMenuItems(items);
  }, [filterMenuItems, isLearner]);

  const optionsSecondary = useMemo(() => {
    return ([{
      name: 'Users & Groups',
      icon: 'UsersAndGroups',
      link: USER_ANG_GROUP_PATH,
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER]
    }, {
      name: 'Account Settings',
      icon: 'AccountSettings',
      link: '/account-settings',
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER]
    }, {
      name: 'Usage Guide',
      icon: 'UsageGuidelines',
      link: documentationURL,
      external: true,
      allowedRoles: [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER, ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER, ACTIVE_ROLES.LEARNER]
    }]).filter((item) => item.allowedRoles.includes(userPermissions.role));
  }, [documentationURL, userPermissions.role]);

  const isSelected = useCallback((pathList) => {
    return !!pathList.find((path) => location.pathname.includes(path));
  }, [location.pathname]);

  return (
    <Box>
      <Collapse orientation='horizontal' in={open} collapsedSize={64} sx={{ height: '100%' }}>
        <Box sx={(theme) => ({ width: open ? 240 : 64, backgroundColor: theme.palette.primary.main, height: '100%', overflowY: 'auto' })}>
          {
            open
              ? (
                <List sx={{ paddingX: 1 }}>
                  {
                    optionsMain.map((item) => (
                      <StyledListItem disablePadding key={item.name}>
                        {
                          Array.isArray(item.children) && item.children.length
                            ? (
                              <>
                                <StyledListItemButton open={open} onClick={() => toggleSubmenuExpand(item.expandedKey)}>
                                  <StyledListItemIcon open={open}>
                                    <Icon icon={item.icon} sx={(theme) => ({ color: theme.palette.primary.contrastText })} />
                                  </StyledListItemIcon>

                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                                    <Typography>{item.name}</Typography>

                                    <Icon icon={expandedMenuKeys[item.expandedKey] ? 'ExpandLess' : 'ExpandMore'} />
                                  </Box>
                                </StyledListItemButton>

                                <Collapse in={expandedMenuKeys[item.expandedKey]} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                                  <List disablePadding>
                                    {
                                      item.children.map((nestedItem) => (
                                        <StyledListItem disablePadding key={nestedItem.name}>
                                          <StyledListItemButton selected={isSelected([nestedItem.link])} open={true} component={RouterLink} to={nestedItem.link} sx={{ pl: 6 }}>
                                            <Typography>{nestedItem.name}</Typography>
                                          </StyledListItemButton>
                                        </StyledListItem>
                                      ))
                                    }
                                  </List>
                                </Collapse>
                              </>
                            )
                            : (
                              <StyledListItemButton selected={isSelected([item.link])} open={open} component={RouterLink} to={item.external ? { pathname: item.link } : item.link} target={item.external ? '_blank' : ''} rel={item.external ? 'noopener noreferrer' : ''}>
                                <StyledListItemIcon open={open}>
                                  <Icon icon={item.icon} sx={(theme) => ({ color: isSelected([item.link]) ? theme.palette.primary.main : theme.palette.primary.contrastText })} />
                                </StyledListItemIcon>
                                <Typography>{item.name}</Typography>
                              </StyledListItemButton>
                            )
                        }
                      </StyledListItem>
                    ))
                  }

                  <Divider variant='middle' sx={(theme) => ({ marginY: 3, marginX: 1, borderColor: theme.palette.white.main, opacity: 0.2 })} />

                  {
                    optionsSecondary.map((item) => (
                      <StyledListItem disablePadding key={item.name}>
                        <StyledListItemButton selected={isSelected([item.link])} open={open} component={RouterLink} to={item.external ? { pathname: item.link } : item.link} target={item.external ? '_blank' : ''} rel={item.external ? 'noopener noreferrer' : ''}>
                          <StyledListItemIcon open={open}>
                            <Icon icon={item.icon} sx={(theme) => ({ color: isSelected([item.link]) ? theme.palette.primary.main : theme.palette.primary.contrastText })} />
                          </StyledListItemIcon>
                          <Typography>{item.name}</Typography>
                        </StyledListItemButton>
                      </StyledListItem>
                    ))
                  }
                </List>
              )
              : (
                <List sx={{ paddingX: 1 }}>
                  {
                    optionsMain.map((item) => (
                      <StyledListItem disablePadding key={item.name}>
                        <StyledListItemButton selected={isSelected([item.link, ...(Array.isArray(item.children) ? item.children.map((child) => child.link) : [])])} component={RouterLink} to={item.external ? { pathname: item.link } : item.link} target={item.external ? '_blank' : ''} rel={item.external ? 'noopener noreferrer' : ''}>
                          <StyledListItemIcon>
                            <Icon icon={item.icon} sx={(theme) => ({ color: isSelected([item.link, ...(Array.isArray(item.children) ? item.children.map((child) => child.link) : [])]) ? theme.palette.primary.main : theme.palette.primary.contrastText })} />
                          </StyledListItemIcon>
                        </StyledListItemButton>
                      </StyledListItem>
                    ))
                  }

                  <Divider variant='middle' sx={(theme) => ({ marginY: 3, marginX: 1, borderColor: theme.palette.white.main, opacity: 0.2 })} />

                  {
                    optionsSecondary.map((item) => (
                      <StyledListItem disablePadding key={item.name}>
                        <StyledListItemButton selected={isSelected([item.link])} component={RouterLink} to={item.external ? { pathname: item.link } : item.link} target={item.external ? '_blank' : ''} rel={item.external ? 'noopener noreferrer' : ''}>
                          <StyledListItemIcon>
                            <Icon icon={item.icon} sx={(theme) => ({ color: isSelected([item.link]) ? theme.palette.primary.main : theme.palette.primary.contrastText })} />
                          </StyledListItemIcon>
                        </StyledListItemButton>
                      </StyledListItem>
                    ))
                  }
                </List>
              )
          }
        </Box>
      </Collapse>
    </Box>
  );
};

export default observer(Sidebar);
