import { io } from 'socket.io-client';
import DataStore from '../Stores/DataStore';
const socket = io(process.env.REACT_APP_SOCKET_URL || `https://wss.${window.location.host}`, {
  withCredentials: true,
  reconnectionDelayMax: 10000,
  closeOnBeforeunload: false
});

// This is being managed from Header.
// socket.on('notification', notification => {
//   DataStore.addNewNotification(notification)
// })

socket.on('allMessages', data => {
  DataStore.setMessages(data);
});

socket.on('message', message => {
  DataStore.addMessage(message);
});

socket.on('permission-change-notifications', message => {
  DataStore.setPermissionChangeToast(true);
});

export const removeSocketConnections = () => {
  socket.emit('disconnectAllConnections');
  socket.close();
};

// window.addEventListener("beforeunload", removeSocketConnections);

export default socket;
