import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogContent, styled, Typography } from '@mui/material';
import SelectCategory from './SelectCategory';
import SpeakToSupervisor from './SpeakToSupervisor';
import DataStore from '../../../../Stores/DataStore';
import AddRequest from './AddRequest';
import { useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { observer } from 'mobx-react';
import SelectGrievanceType from './SelectGrievanceType';
import HeaderSubPanel from '../../../../Components/HeaderSubPanel';
import { GRIEVANCES_FORMS_PATH } from '../../../../constants/paths';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import Data from '../../../../Middleware/Data';

const PREFIX = 'AddGrievance';
const classes = {
  divider: `${PREFIX}-divider`,
  pageTitle: `${PREFIX}-pageTitle`,
  footer: `${PREFIX}-footer`
};

const breadcrumbLinks = [
  { href: GRIEVANCES_FORMS_PATH, label: 'Grievances and Forms' },
  { href: '#', label: 'Submit New Request' }
];

const Root = styled('div')(() => ({
  divider: {
    marginTop: '40px',
    marginBottom: '40px'
  },
  pageTitle: {
    paddingBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  footer: {
    textAlign: 'center',
    color: '#D0021B',
    marginTop: '60px',
    paddingBottom: '50px'
  }
}));

const AddGrievance = () => {
  const [confirmationDialogNode, openConfirmationDialog] = useConfirmationDialog();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [userDetails, setUserDetails] = useState({});
  const [grievance, setGrievance] = useState({});
  const [loading, setLoading] = useState(false);
  const { createGrievanceSteps, setCreateGrievanceSteps } = DataStore;

  const grievanceWeeklylimitStatusQuery = useQuery(
    ['GRIEVANCES', 'LERNER', 'STATUS'],
    () => Data.checkGrievanceWeeklylimitStatus()
  );

  const submitGrievance = async (data) => {
    setLoading(true);
    await DataStore.addGrievance(data);
    setLoading(false);
    DataStore.setGrievanceToast(true);
    history.push('/forms/grievances');
    setCreateGrievanceSteps(1);
    queryClient.refetchQueries(['GRIEVANCES', 'LERNER']);
  };

  const nextStep = async (data) => {
    let shouldContinue = true;

    if (userDetails.UserSetting && userDetails.UserSetting.restrictGrievances) {
      shouldContinue = await openConfirmationDialog((close) => (
        <Dialog
          open
          maxWidth='sm'
          onClose={() => close(false)}
        >
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant='h6' sx={{ fontWeight: 700, fontSize: 18 }}>
                {`You have ${grievanceWeeklylimitStatusQuery.data.grievanceLimit - grievanceWeeklylimitStatusQuery.data.usedGrievanceLimit} grievances remaining this week.`}
              </Typography>

              <Typography variant='caption'>
                {`You are allowed up to ${grievanceWeeklylimitStatusQuery.data.grievanceLimit} grievances or action requests per week. `}
                Please plan your submissions carefully and prioritize urgent issues. If you have any urgent issues, prioritize them accordingly.
              </Typography>

              <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
                If this is an emergency, notify staff immediately.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' color='primary' onClick={() => close(true)}>
                Okay, Understood
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      ));
    }

    if (!shouldContinue) {
      return;
    }

    if (createGrievanceSteps === 0) {
      grievance.requestType = data;
      setGrievance({ ...grievance, requestType: data });
      setCreateGrievanceSteps(createGrievanceSteps + 1);
    } else if (createGrievanceSteps === 1) {
      grievance.category = data;
      setGrievance(grievance);
      setCreateGrievanceSteps(createGrievanceSteps + 1);
    } else if (createGrievanceSteps === 3) {
      const newGrievance = {
        ...data,
        category: grievance.category.value,
        requestType: grievance.requestType
      };

      return submitGrievance(newGrievance);
    } else {
      setCreateGrievanceSteps(createGrievanceSteps + 1);
    }
  };

  const previousStep = () => {
    setCreateGrievanceSteps(createGrievanceSteps - 1);
  };

  useEffect(() => {
    async function fetchUser () {
      const user = await DataStore.getSelf();
      setUserDetails(user);
    }
    fetchUser();
    setCreateGrievanceSteps(0);
  }, []);

  const sendBackToListPage = () => {
    history.push('/forms/grievances');
  };

  return (
    <Box>
      <HeaderSubPanel links={breadcrumbLinks} title="Submit New Request" sx={{ mb: 3 }} />
      <Root className="user-update" style={{ paddingBottom: '5%' }}>
        {
          createGrievanceSteps === 0 && (
            <Box sx={{ mb: 2 }}>
              <Alert severity='error'>
                Any emergencies or thoughts of self harm please contact any deputy/staff immediately.
              </Alert>
            </Box>
          )
        }

        <div style={{ maxWidth: '100%', fontSize: '16px' }}>
          {createGrievanceSteps === 0 && (
            <SelectGrievanceType
              nextStep={nextStep}
              previousStep={sendBackToListPage}
              grievance={grievance}
            />
          )}
          {createGrievanceSteps === 1 && (
            <SelectCategory
              nextStep={nextStep}
              previousStep={previousStep}
              grievance={grievance}
            />
          )}
          {createGrievanceSteps === 2 && (
            <SpeakToSupervisor
              classes={classes}
              user={userDetails}
              grievance={grievance}
              nextStep={nextStep}
              previousStep={previousStep}
              sendBackToListPage={sendBackToListPage}
            />
          )}
          {createGrievanceSteps === 3 && (
            <AddRequest
              classes={classes}
              user={userDetails}
              grievance={grievance}
              nextStep={nextStep}
              previousStep={previousStep}
              loading={loading}
            />
          )}
        </div>
      </Root>

      {
        confirmationDialogNode
      }
    </Box>
  );
};
export default (observer(AddGrievance));
