import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  Typography,
  Table
} from '@nucleos/core-ui';
import Data from '../../Middleware/Data';
import { COURSE_METRICS_FORMATS, COURSE_STATUS } from '../../Lib/CONSTANTS';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import moment from 'moment';
import { msToHHMMSS } from '../../Lib/util';
import defaultIcon from '../../Assets/course-icon-default.svg';
import { Icon } from '../../Components/Shared/Icon';

const defaultSorting = {
  sortOrder: 'ASC',
  sortBy: 'TITLE'
};
const CourseLessons = (props) => {
  const { closeLessonModalHandler, courseId, uid, selectedAppDetails } = props;
  const { createdAt, updatedAt, logo, appName } = selectedAppDetails;
  const [columnSort, setColumnSort] = useState(defaultSorting);
  const lessonQuery = useQuery(
    ['GET_LESSONS', columnSort, courseId],
    () => {
      const payload = {
        uid,
        courseId,
        sortBy: columnSort.sortBy,
        sortOrder: columnSort.sortOrder
      };
      return Data.getCourseLessons(payload);
    },
    {
      refetchOnWindowFocus: false,
      enabled: true
    }
  );
  const setColumnSorting = (sortBy, sortOrder) => {
    // This logic is to make sure that there is sorting applied at any given movement.
    // This will fallback to ascending sort of alert (AGE) column if no sort is applied.
    if (sortOrder === 'NONE' && sortBy !== defaultSorting.sortBy) { return setColumnSort(defaultSorting); }
    if (sortOrder === 'NONE' && sortBy === defaultSorting.sortBy) { return setColumnSort({ ...defaultSorting, sortOrder: 'ASC' }); }

    setColumnSort({ sortBy, sortOrder });
  };
  useEffect(() => {
    if (courseId) {
      lessonQuery.refetch();
    }
  }, [courseId]);

  const getLessonTitle = (title) => {
    if (title && title.length > 50) {
      return <span>{title.slice(0, 50)} <span className='cursor-pointer' title={title} >...</span></span>;
    } else {
      return title;
    }
  };

  const columns = [
    {
      title: 'Lesson Name',
      render: (item) => <Typography>{getLessonTitle(item.title) || 'N/A'}</Typography>,
      enableSort: true,
      sortOrder: columnSort.sortBy == 'TITLE' ? columnSort.sortOrder : 'NONE',
      onSortChange: (sortOrder) => {
        setColumnSorting('TITLE', sortOrder);
      }
    },
    {
      title: 'Date',
      render: (item) => <Typography>{ moment(new Date(item.CourseLessonProgresses && item.CourseLessonProgresses.length && item.CourseLessonProgresses[0] && item.CourseLessonProgresses[0].lastActivity || item.CourseLessonProgresses[0].progressUpdatedAt || item.CourseLessonProgresses[0].updatedAt)).format('MMM DD, YYYY HH:mm') || 'N/A'}</Typography>,
      enableSort: true,
      sortOrder: columnSort.sortBy == 'ACTIVITY' ? columnSort.sortOrder : 'NONE',
      onSortChange: (sortOrder) => {
        setColumnSorting('ACTIVITY', sortOrder);
      }
    }
  ];
  const lessonData = lessonQuery.data && lessonQuery.data.course && lessonQuery.data.course.CourseLessons;
  const isLoading = lessonQuery.status !== 'success';
  const additionalColumns = lessonQuery.data && lessonQuery.data.course.metaRawData && lessonQuery.data.course.metaRawData.lesson_cols;
  if (additionalColumns && additionalColumns.length) {
    additionalColumns.forEach(c => {
      const getRowValue = (item) => {
        let rowValue = (item.CourseLessonProgresses &&
                              item.CourseLessonProgresses.length &&
                              item.CourseLessonProgresses[0].rawData &&
                              item.CourseLessonProgresses[0].rawData[c.key]) || 'NA';
        if (c.format == COURSE_METRICS_FORMATS.STATUS && (item.CourseLessonProgresses &&
                    item.CourseLessonProgresses.length &&
                    item.CourseLessonProgresses[0].rawData &&
                    item.CourseLessonProgresses[0].rawData[c.key])) {
          rowValue = COURSE_STATUS[item.CourseLessonProgresses[0].rawData[c.key]];
        }

        if (c.format == COURSE_METRICS_FORMATS.DURATION && (item.CourseLessonProgresses &&
                    item.CourseLessonProgresses.length &&
                    item.CourseLessonProgresses[0].rawData &&
                    (item.CourseLessonProgresses[0].rawData[c.key] || item.CourseLessonProgresses[0].rawData[c.key] == 0))) {
          rowValue = `${msToHHMMSS(item.CourseLessonProgresses[0].rawData[c.key])}h`;
        }

        if (c.format == COURSE_METRICS_FORMATS.PERCENT && (item.CourseLessonProgresses &&
                    item.CourseLessonProgresses.length &&
                    item.CourseLessonProgresses[0].rawData &&
                    item.CourseLessonProgresses[0].rawData[c.key] && item.CourseLessonProgresses[0].rawData[c.key] != 'NA')) {
          rowValue = `${parseFloat(item.CourseLessonProgresses[0].rawData[c.key]).toFixed(2).replace(/[.,]00$/, '')}%`;
        }

        if (c.format == COURSE_METRICS_FORMATS.NUMBER && (item.CourseLessonProgresses &&
                    item.CourseLessonProgresses.length &&
                    item.CourseLessonProgresses[0].rawData &&
                    item.CourseLessonProgresses[0].rawData[c.key])) {
          rowValue = item.CourseLessonProgresses[0].rawData[c.key];
        }

        if (c.format == COURSE_METRICS_FORMATS.STRING && (item.CourseLessonProgresses &&
                    item.CourseLessonProgresses.length &&
                    item.CourseLessonProgresses[0].rawData &&
                    item.CourseLessonProgresses[0].rawData[c.key])) {
          rowValue = item.CourseLessonProgresses[0].rawData[c.key];
        }
        return rowValue;
      };

      columns.push(
        {
          title: c.label,
          render: (item) => <Typography>{getRowValue(item)}</Typography>
        }
      );
    });
  }

  return (
    <div className='nucleos-core' style={{ outline: 'none' }}>
      <div className='outline-none px-5 py-5 rounded-lg' style={{ background: '#ffffff' }} >
        <div className='flex justify-between items-center pb-3' style={{ borderBottom: '1px solid rgba(51, 51, 51, 0.2)' }}>
          <div className='text-2xl' style={{ color: '#333333' }} >Lesson Details</div>
          <div className='flex justify-between items-center cursor-pointer' onClick={closeLessonModalHandler}>
            <Icon icon="Close" />
            <span>Close</span>
          </div>
        </div>
        <div className='flex justify-start items-center'>
          <div className='mt-4 ml-4 h-11 w-11'>
            <img className='h-11 w-11 object-contain' style={{ borderRadius: '50%' }} src={logo || defaultIcon} title={appName} />
          </div>
          <div className='ml-3 mt-4 mr-8'>
            <div style={{ color: '#19223D' }} className='text-base'>
              {appName}
            </div>
            <div className='flex items-center text-xs mt-1' style={{ color: 'rgba(51, 51, 51, 0.6)' }} >
              <div>Enrollment: {createdAt ? moment(new Date(createdAt)).format('MM/DD/YYYY') : 'N/A'}</div>
              <div className='h-1 w-1 mx-2 rounded-sm' style={{ background: '#adadad' }}></div>
              <div>Last Activity: {updatedAt ? moment(new Date(updatedAt)).format('MM/DD/YYYY') : 'N/A'}</div>
            </div>

          </div>
        </div>

        {isLoading
          ? <div className='flex justify-center items-center' style={{ height: window.screen.height * 0.3, width: window.screen.width * 0.6 }}> <LoadingComponent tillApiResponse={true} small='true'/> </div>
          : <div className='mt-4 overflow-y-auto' style={{ maxHeight: window.screen.height * 0.6, width: window.screen.width * 0.6 }}>
            <Table
              columns={columns}
              rowsData={lessonData || []}
              loading={isLoading}
            />
          </div>}
      </div>

    </div>
  );
};

export default CourseLessons;
