import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Switch, Typography, styled, useTheme } from '@mui/material';
import ToastMessage from '../../Components/Widgets/ToastMessage';
import './Application.scss';
import AuthenticationStore from '../../Stores/Authentication';
import DataStore from '../../Stores/DataStore';
import { iconStyleMap, appIconMap, updateAndAssignURL, checkCurrentTimeIsBetween } from '../../Lib/util';
import Data from '../../Middleware/Data';
import { confirmAlert } from 'react-confirm-alert';
import useUserPermissions from '../../hooks/useUserPermissions';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import { useMutation } from 'react-query';
import { Icon } from '../../Components/Shared/Icon';
import ExtensionPrompt from '../../Components/ExtensionPrompt';
import { getExtensionInfo } from '../../Lib/extension';

const PREFIX = 'ApplicationHeader';
const classes = {
  switchContainer: `${PREFIX}-switchContainer`,
  switchBase: `${PREFIX}-switchBase`,
  granularIcon: `${PREFIX}-granularIcon`
};

const Root = styled('div')(() => ({
  [`& .${classes.switchContainer}`]: {
    marginLeft: '-14px'
  },
  [`& .${classes.switchBase}`]: {
    height: 'unset'
  },
  [`& .${classes.granularIcon}`]: {
    fontSize: '16.5px',
    marginRight: '4px'
  }
}));

const ApplicationHeader = props => {
  const { userRole, uid: userUid } = AuthenticationStore;
  const { appDetail, setApplicationActive, setApplicationActiveSuccess, setApplicationActiveError, setSetApplicationActiveSuccess, setSetApplicationActiveError } = DataStore;
  const { name, title, displayName, linkAddress, active, granular, cookie, metadata, uid } = appDetail;
  const { enroll, useLaunchURL, useConditionalURL, conditionalURLs, loginRequired } = metadata || {};
  const isStudent = userRole === 'learner';
  const isAdmin = userRole === 'admin';
  const userPermissions = useUserPermissions();
  const theme = useTheme();
  const { uid: id } = useParams();

  const [showExtensionPrompt, setShowExtensionPrompt] = useState(false);

  const vantageSSOLoginMutation = useMutation(Data.vantageSSOLogin);

  function handleAppActivationSwitch (event) {
    event.stopPropagation();
    const isEnabling = event.target.checked;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p>
              {isEnabling
                ? (
                  'If you enable the course it will start showing up for the learners. Are you sure you want to enable the course?'
                )
                : (
                  "If you disable the course, the learners won't be able to see or access it. Are you sure you want to disable the course?"
                )}
            </p>
            <div className="react-confirm-alert-button-group">
              <Button style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={() => { onClose(); }}>No</Button>
              <Button
                style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                onClick={() => {
                  setApplicationActive(id, isEnabling);
                  onClose();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        );
      },
      overlayClassName: 'app-confirmation'
    });
  }

  function handleToastMessageclose (status) {
    const setAction = status === 'success' ? setSetApplicationActiveSuccess : setSetApplicationActiveError;
    setAction(null);
  }

  const successMessage = `${displayName || name} application has been ${active ? 'activated' : 'deactivated'}.`;
  const errorMessage = `${displayName || name} application could not be ${active ? 'deactivated' : 'activated'}. Please try again in a moment.`;

  const isAppAvailableToAccess = isAdmin
    ? true
    : appDetail.userOnboarded && (appDetail.availabilityJson && appDetail.availabilityJson.appAvailability
      ? checkCurrentTimeIsBetween(
        appDetail.availabilityJson.appAvailability
      )
      : true);

  if (vantageSSOLoginMutation.isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Root>
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div className='app-detail__info-header'>
            <img style={iconStyleMap(appDetail)} src={appIconMap(appDetail)} alt='icon' />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '0 30px' }}>
              <h2 style={{ marginTop: 5, marginBottom: 5 }}>
                {displayName || name || title || 'no title in data'}{linkAddress === '/under-construction' ? '*' : ''}
              </h2>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  fontSize: '10px',
                  marginBottom: '3px',
                  whiteSpace: 'nowrap'
                }}
              >
                {!isStudent && userPermissions.canEnableDisableApp() && (
                  <>
                    <Switch
                      checked={active}
                      color='primary'
                      disabled={window.nucleosConfig.disableCourseUserAccessActions}
                      classes={{ root: classes.switchContainer, switchBase: classes.switchBase }}
                      onClick={handleAppActivationSwitch}
                    />
                    <span style={{ marginLeft: '-5px', marginRight: '10px' }}>
                      {' '}
                      {active ? 'Enabled' : 'Disabled'}{' '}
                    </span>
                  </>
                )}
                {(!!granular && isAdmin) && (
                  <span
                    style={{
                      color: '#ff4e00',
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexWrap: 'nowrap',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      letterSpacing: '-0.02',
                      whiteSpace: 'nowrap'
                    }}><Icon icon="Warning" classes={{ root: classes.granularIcon }} /> User access filter on
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          {(linkAddress || (!linkAddress && (name === 'Evolve' || title === 'Evolve'))) && (
            <Button
              color='primary'
              variant='contained'
              disabled={!isAppAvailableToAccess}
              onClick={async () => {
                if (window.nucleosConfig.isPublicExtension) {
                  let extInfo = null;
                  try {
                    extInfo = await getExtensionInfo();
                  } catch (error) {
                    if (!extInfo && loginRequired) {
                      delete window.nucleosExtension;
                      localStorage.setItem('extensionPromptClosed', false);
                      setShowExtensionPrompt(true);
                      return;
                    }
                  }
                }
                if (!linkAddress && (name === 'Evolve' || title === 'Evolve')) {
                  props.ssoVantageLogin(userUid);
                } else if (!enroll) {
                  if (useLaunchURL) {
                    DataStore.setAppLoading(true);
                    // TODO - loading widget over page
                    Data.getLaunchURL(uid).then(({ url }) => {
                      window.location.assign(url);
                    });
                  } else {
                    if (cookie) { document.cookie = `${cookie}; expires=${new Date(Date.now() + 60000).toUTCString()}; path=/; domain=nucleos.com`; }
                    if (useConditionalURL) {
                      updateAndAssignURL(name, conditionalURLs);
                    } else {
                      window.nucleosConfig.isPublicExtension ? window.open(linkAddress) : window.location.assign(linkAddress);
                    }
                  }
                } else {
                  if (cookie) { document.cookie = `${cookie}; expires=${new Date(Date.now() + 60000).toUTCString()}; path=/; domain=nucleos.com`; }
                  window.nucleosConfig.isPublicExtension ? window.open(linkAddress) : window.location.assign(linkAddress);
                }
              }}
            >
              <Icon icon="Launch" sx={{ marginRight: 1 }} />

              <Typography textTransform='none'>
                Launch App
              </Typography>
            </Button>
          )}
          {linkAddress &&
            isAdmin && (
            <Button
              color='primary'
              variant='contained'
              style={{ marginTop: '20px', display: 'none' }}
            >
              <Icon icon="Edit" /> Edit
            </Button>
          )}
        </div>
      </section>
      {displayName === 'ACCI' || name === 'ACCI' || title === 'ACCI'
        ? (
          <div className="nucleos-core">
            <div className="w-full p-2 my-2" style={{ background: '#E9F0FF' }}>
              <Typography variant="body2">
                ACCI certificates will only be awarded if work is assigned by Programs staff.
              </Typography>
            </div>
          </div>
        )
        : null}
      <ToastMessage
        message={successMessage}
        open={setApplicationActiveSuccess && setApplicationActiveSuccess.uid === id}
        autoHideDuration={4000}
        onClose={() => handleToastMessageclose('success')}
        type='success'
      />
      <ToastMessage
        message={errorMessage}
        open={setApplicationActiveError && setApplicationActiveError.uid === id}
        autoHideDuration={4000}
        onClose={() => handleToastMessageclose('error')}
        type='error'
      />
      {showExtensionPrompt && <ExtensionPrompt onPromptClosed={() => setShowExtensionPrompt(false)} />}
    </Root>
  );
};

export default (observer(ApplicationHeader));
