import React, { useEffect, useState } from 'react';
import { Paper, Switch } from '@mui/material';
import { TextInput, Typography, Button as NCButton } from '@nucleos/core-ui';
import { useMutation } from 'react-query';
import Data from '../../../Middleware/Data';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import { toast } from 'react-hot-toast';
import { useUserContext } from '../UserContext';

const paperBaseStyle = {
  padding: 30,
  borderRadius: '12px',
  boxShadow: '0 10px 40px 0 rgba(64, 72, 82, 0.07)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

const GrievanceLimit = () => {
  const userContext = useUserContext();
  const { user, refetchUser } = userContext;

  const [isGrievanceLimitActive, setIsGrievanceLimitActive] = useState(
    user.UserSetting && user.UserSetting.restrictGrievances
  );
  const [grievanceLimit, setGrievanceLimit] = useState(
    user.UserSetting && user.UserSetting.grievanceLimit
  );

  const updateGrievanceLimitMutation = useMutation(
    (restrictGrievances = isGrievanceLimitActive) =>
      Data.updateGrievanceLimit({
        userUid: user.uid,
        restrictGrievances: restrictGrievances,
        grievanceLimit: restrictGrievances ? grievanceLimit : 0
      }).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        toast.success('Grievances and Forms limit updated successfully');

        refetchUser();
      },
      onError: () => toast.error('Failed to update Grievances and Forms limit')
    }
  );

  useEffect(() => {
    if (!isGrievanceLimitActive) {
      setGrievanceLimit(user.UserSetting.grievanceLimit || 'Unlimited');
    }
  }, [user.UserSetting]);

  return (
    <div>
      <Paper className="nucleos-core" style={{ ...paperBaseStyle }}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            Set Grievances and Forms Limit
          </div>
          <Switch
            checked={isGrievanceLimitActive}
            onChange={(_, checked) => {
              setIsGrievanceLimitActive(checked);
              setGrievanceLimit(checked ? '' : (user.UserSetting.grievanceLimit || 'Unlimited'));
              if (!checked) { updateGrievanceLimitMutation.mutate(false); }
            }}
          />
          <div className="flex items-center">
            <span className="pr-2">New Grievances and Forms Limit</span>
          </div>
          <div className="flex items-center">
            <TextInput
              disableHelperText
              value={
                grievanceLimit || (isGrievanceLimitActive ? '' : 'Unlimited')
              }
              onChange={(value) => {
                if (isNaN(value.replace('Unlimited', ''))) { return; }
                setGrievanceLimit(+value.replace('Unlimited', ''));
              }}
              disabled={!isGrievanceLimitActive}
            />
            <span className="px-2">per week</span>
          </div>
          <div></div>
          <Typography variant="body2">
            Note: The week begins on Sunday and ends on Saturday
          </Typography>
          <div></div>
          {((user.UserSetting && user.UserSetting.restrictGrievances) ||
            isGrievanceLimitActive) && (
            <NCButton
              className={'w-40'}
              color="primary"
              variant="contained"
              loading={updateGrievanceLimitMutation.isLoading}
              disabled={isGrievanceLimitActive && !grievanceLimit}
              onClick={() => updateGrievanceLimitMutation.mutate()}
            >
              Save Changes
            </NCButton>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default GrievanceLimit;
