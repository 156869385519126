import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from '../../../Lib/util';
import { observer } from 'mobx-react';
import { useGrievanceRole } from '../../../hooks/useGrievancePermissions';
import { useQuery } from 'react-query';
import Data from '../../../Middleware/Data';
import NoDataFoundImage from '../../../Assets/Setup Wizard-bro 1.svg';
import { Button, Typography, Table } from '@nucleos/core-ui';
import GrievanceTypeChip from '../../../Components/Grievance/GrievanceTypeChip';
import { Box } from '@mui/material';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';

const defaultSorting = {
  column: 'REQUEST_NUM',
  sortOrder: 'DESC'
};

const Grievances = () => {
  const grievanceRole = useGrievanceRole();
  const [page, setPage] = useState(1);
  const [columnSorting, _setColumnSorting] = useState(defaultSorting);
  const checkGrievanceWeeklylimitStatusQuery = useQuery(
    ['GRIEVANCES', 'LERNER', 'STATUS'],
    () => Data.checkGrievanceWeeklylimitStatus()
  );
  const grievancesQuery = useQuery(
    ['GRIEVANCES', 'LERNER', page, columnSorting],
    () =>
      Data.getAllGrievances(
        page - 1,
        columnSorting.column,
        columnSorting.sortOrder
      ),
    {
      enabled: grievanceRole.canViewGrievance() === true
    }
  );

  const history = useHistory();

  const isGrievanceLimitExceeded =
    checkGrievanceWeeklylimitStatusQuery.data &&
    checkGrievanceWeeklylimitStatusQuery.data.isGrievanceLimitExceeded;

  const newGrievanceMessage = window.nucleosConfig.disableNewGrievance
    ? (
      <Typography variant="body2" style={{ color: 'red' }}>
      Electronic grievances are currently restricted to Edit and View only.
      Please file paper grievances until further notice.
      </Typography>
    )
    : isGrievanceLimitExceeded
      ? (
        <Typography variant="body2" style={{ color: 'red' }}>
      Your ability to submit Electronic Grievances has been temporarily
      suspended as you have surpassed your weekly submission quota.
        </Typography>
      )
      : (
        ''
      );

  const setColumnSorting = ({ column, sortOrder }) => {
    // This logic is to make sure that there is sorting applied at any given movement.
    // This will fallback to ascending sort of alert (AGE) column if no sort is applied.
    if (sortOrder === 'NONE' && column !== defaultSorting.column) { return _setColumnSorting(defaultSorting); }
    if (sortOrder === 'NONE' && column === defaultSorting.column) { return _setColumnSorting({ ...defaultSorting, sortOrder: 'ASC' }); }
    _setColumnSorting({ column, sortOrder });
  };

  return (
    <Box>
      <HeaderSubPanel title="Grievances and Forms" sx={{ mb: 3 }} />
      <div className="user-update">
        <div className="nucleos-core" style={{ width: '100%' }}>
          <div className="flex justify-between">
            <Typography variant="h1">Grievance and Form Requests</Typography>
            {!(
              window.nucleosConfig.disableNewGrievance ||
            isGrievanceLimitExceeded ||
            grievanceRole.canCreateGrievance() === false
            ) && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => history.push('/forms/grievances/add')}
              >
              + Submit New Request
              </Button>
            )}
          </div>
          {newGrievanceMessage}
          <div className="mt-8">
            {grievanceRole.canViewGrievance() ===
          false
              ? null
              : grievancesQuery.status === 'success' &&
            grievancesQuery.data.grievances.length === 0
                ? (
                  <div className="py-10 flex flex-col justify-center items-center border border-gray-light">
                    <img src={NoDataFoundImage} alt="No data found" />
                    <Typography variant="h4" className="mx-6">
                  No Grievance submitted yet
                    </Typography>
                    <Typography className="mb-10">
                  By clicking on submit new request, you can submit a Grievance
                  Request.
                    </Typography>
                  </div>
                )
                : (
                  <Table
                    pagination
                    disableRecordsPerPageChange
                    loading={grievancesQuery.status !== 'success'}
                    rowsData={
                      grievancesQuery.data ? grievancesQuery.data.grievances : []
                    }
                    totalRecords={
                      grievancesQuery.data ? grievancesQuery.data.count : 0
                    }
                    onPageChange={(_page) => setPage(_page)}
                    page={page}
                    onRowClick={(item) => {
                      history.push(`/forms/grievances/view/${item.id}`);
                    }}
                    columns={[
                      {
                        title: 'Request #',
                        render: (item) => item.id,
                        enableSort: true,
                        onSortChange: (sortOrder) =>
                          setColumnSorting({ column: 'REQUEST_NUM', sortOrder }),
                        sortOrder:
                    columnSorting.column === 'REQUEST_NUM'
                      ? columnSorting.sortOrder
                      : 'NONE'
                      },
                      {
                        title: 'Type',
                        render: (item) => (
                          <GrievanceTypeChip requestType={item.requestType} />
                        ),
                        enableSort: true,
                        onSortChange: (sortOrder) =>
                          setColumnSorting({ column: 'REQUEST_TYPE', sortOrder }),
                        sortOrder:
                    columnSorting.column === 'REQUEST_TYPE'
                      ? columnSorting.sortOrder
                      : 'NONE'
                      },
                      {
                        title: 'Date Submitted',
                        render: (item) => formatDateTime(item.createdAt),
                        enableSort: true,
                        onSortChange: (sortOrder) =>
                          setColumnSorting({ column: 'CREATED_AT', sortOrder }),
                        sortOrder:
                    columnSorting.column === 'CREATED_AT'
                      ? columnSorting.sortOrder
                      : 'NONE'
                      },
                      {
                        title: 'Assigned To',
                        render: (item) =>
                          item.assignedToUser
                            ? `${item.assignedToUser.UserTitle ? item.assignedToUser.UserTitle.title : ''} ${
                              item.assignedToUser.showFirstName
                                ? item.assignedToUser.firstName
                                : ''
                            } ${item.assignedToUser.lastName}`
                            : '-',
                        enableSort: true,
                        onSortChange: (sortOrder) =>
                          setColumnSorting({ column: 'ASSIGNEE', sortOrder }),
                        sortOrder:
                    columnSorting.column === 'ASSIGNEE'
                      ? columnSorting.sortOrder
                      : 'NONE'
                      },
                      {
                        title: 'Category',
                        render: (item) => item.GrievanceCategory.name,
                        enableSort: true,
                        onSortChange: (sortOrder) =>
                          setColumnSorting({ column: 'CATEGORY', sortOrder }),
                        sortOrder:
                    columnSorting.column === 'CATEGORY'
                      ? columnSorting.sortOrder
                      : 'NONE'
                      },
                      {
                        title: 'Status',
                        render: (item) => item.status,
                        enableSort: true,
                        onSortChange: (sortOrder) =>
                          setColumnSorting({ column: 'STATUS', sortOrder }),
                        sortOrder:
                    columnSorting.column === 'STATUS'
                      ? columnSorting.sortOrder
                      : 'NONE'
                      }
                    ]}
                  />
                )}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default observer(Grievances);
