import React, { useState } from 'react';
import { observer } from 'mobx-react';
import UsersTable from './UsersTable';
import './UserSettings.scss';
import NewUserForm from '../User/UserForm/UserForm';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Icon } from '../Shared/Icon';

const UserSettings = ({ listViewMode = 'ALL' }) => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  return (
    <React.Fragment>
      <div>
        <Dialog
          fullWidth
          maxWidth='md'
          open={isAddUserModalOpen}
          onClose={() => setIsAddUserModalOpen(false)}
        >
          <DialogTitle sx={{ py: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h6'>Create User</Typography>

              <Button
                color='disabled'
                variant='text'
                startIcon={<Icon icon='Close' />}
                onClick={() => setIsAddUserModalOpen(false)}
              >
                Close
              </Button>
            </Box>
          </DialogTitle>

          <DialogContent dividers>
            <NewUserForm
              isModalRenderedForm={true}
              onCloseClick={() => setIsAddUserModalOpen(false)}
              onUserCreateOrUpdate={() => setIsAddUserModalOpen(false)}
            />
          </DialogContent>
        </Dialog>
        <UsersTable
          listViewMode={listViewMode}
          onAddClick={() => setIsAddUserModalOpen(!isAddUserModalOpen)}
        />
      </div>
    </React.Fragment>
  );
};

export default observer(UserSettings);
