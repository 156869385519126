import React from 'react';

import { observer } from 'mobx-react';
import { MultiSelectDropDown } from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../Lib/query-keys';
import Data from '../../Middleware/Data';

function RoleSelect ({
  id,
  testId = '',
  label,
  name,
  onChange,
  value,
  disabled,
  error,
  helperText,
  disableHelperText,
  DropdownContainerProps
}) {
  const rolesQuery = useQuery(
    QueryKeys.Roles.listing({ usersListing: true }),
    () => Data.getRoles(true),
    {
      placeholderData: [],
      select: (data) =>
        data.map((d) => ({ title: d.displayName, value: d.name }))
    }
  );

  const _options = rolesQuery.data;

  return (
    <div className={''}>
      <MultiSelectDropDown
        fullWidth
        dropdownId={`${id}-dropdown`}
        testId={testId}
        label={label}
        name={name}
        onChange={onChange}
        options={_options}
        value={_options.filter((op) => value.includes(op.value))}
        disabled={disabled}
        disableMultiSelect
        error={error}
        helperText={helperText}
        disableHelperText={disableHelperText}
        DropdownContainerProps={DropdownContainerProps}
      />
    </div>
  );
}

export default observer(RoleSelect);
