import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Switch } from '@mui/material';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';
import ToastMessage from '../../Components/Widgets/ToastMessage';
import './Application.scss';
import AuthenticationStore from '../../Stores/Authentication';
import DataStore from '../../Stores/DataStore';

const ApplicationCourses = () => {
  const [currentAction, setCurrentAction] = useState(null);
  const {
    appDetail,
    activeCourse,
    // set3rdPartyTitleData,
    activateCourseSuccess,
    activateCourseError
  } = DataStore;
  const courses = appDetail && appDetail.Courses ? appDetail.Courses : [];

  // const history = useHistory();

  function handleCourseEnabledSwitch (event) {
    const { name, checked } = event.target;
    const action = checked ? 'activate' : 'deactivate';
    setCurrentAction(action);
    activeCourse(name, checked);
  }

  // function handleLaunchAppButton (address) {
  //   const { name, title, linkAddress } = appDetail;
  //   set3rdPartyTitleData(name || title);
  //   if (typeof address === 'string' || typeof linkAddress !== 'object') {
  //     history.push(
  //       `/launch/${encodeURIComponent(
  //         typeof address === 'string' || linkAddress
  //       )}`
  //     );
  //   }
  // }

  function handleSnackBarClose (status) {
    const { setActivateCourseSuccess, setActivateCourseError } = DataStore;
    if (status === 'success') {
      setActivateCourseSuccess(null);
    } else {
      setActivateCourseError(null);
    }
    setCurrentAction(null);
  }

  return courses.length > 0
    ? (
      <React.Fragment>
        <div>
          <section>
            <h2 style={{ margin: '0 30px' }}>Courses</h2>
            <FlexTileGrid
              gridColTiers={{ xs: 12 }}
              horizontal
              tileStyle={{ padding: '10px 30px' }}
              spacing={0}
              gridItemStyle={{ padding: '10px 0' }}
            >
              {courses.map((course, index) => {
                const { title, id, active } = course;
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                    key={id}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <strong>{index + 1}.</strong>
                      <div
                        className='app-detail__record-detail'
                        // onClick={() => handleLaunchAppButton(course.linkAddress)}
                      >
                        {' '}
                        {title || 'Name not provided'}
                      </div>
                    </div>
                    {AuthenticationStore.userRole === 'admin' && (
                      <div>
                        <Switch
                          name={id + ''}
                          defaultChecked={!!active}
                          value='enabled'
                          color='primary'
                          classes={{ root: '' }}
                          onClick={handleCourseEnabledSwitch}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </FlexTileGrid>
          </section>
        </div>
        {/* these toast messages ain't appearing */}
        <ToastMessage
          autoHideDuration={4000}
          message={'Course has been activated.'}
          onClose={() => handleSnackBarClose('success')}
          open={!!activateCourseSuccess && currentAction === 'activate'}
          type='success'
        />
        <ToastMessage
          autoHideDuration={4000}
          message={'Course could not be activated. Please try again in a moment.'}
          onClose={() => handleSnackBarClose('error')}
          open={!!activateCourseError && currentAction === 'activate'}
          type='error'
        />
        <ToastMessage
          autoHideDuration={4000}
          message={'Course has been deactivated.'}
          onClose={() => handleSnackBarClose('success')}
          open={!!activateCourseSuccess && currentAction === 'deactivate'}
          type='error'
        // I did a type error for the red color; to contrast with green/success
        />
        <ToastMessage
          autoHideDuration={4000}
          message={'Course could not be deactivated. Please try again in a moment.'}
          onClose={() => handleSnackBarClose('error')}
          open={!!activateCourseError && currentAction === 'deactivate'}
          type='error'
        />
      </React.Fragment>
    )
    : null;
};

export default observer(ApplicationCourses);
