import React from 'react';
import { Dialog } from '@nucleos/core-ui';

import GroupAbout from './GroupAbout/GroupAbout';
import ApplicationList from './ApplicationList/ApplicationList';

export default function ApplicationDescriptionModal ({ open, onClose }) {
  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      size="medium"
      title="Application Description"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: '20px',
          overflow: 'auto',
          height: 'calc(100vh - 8rem)'
        }}
      >
        <GroupAbout />
        <ApplicationList />
      </div>
    </Dialog>
  );
}
