import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Data from '../../../Middleware/Data';

export default function AddFacilityDropdown ({ addedFacilityIdsList, onFacilityAdd }) {
  const facilitiesQuery = useQuery(
    ['ApplicationFacilities'],
    () => Data.getAllFacilities(),
    {
      placeholderData: [],
      select: (data) => {
        let facilities = [];

        if (Array.isArray(data.facilities)) {
          facilities = data.facilities.map((facility) => ({ uid: facility.id, name: facility.name }));
        }

        return facilities;
      }
    }
  );

  const facilityOptions = useMemo(() => {
    return facilitiesQuery.data.filter((facility) => !addedFacilityIdsList.find((addedFacilityId) => addedFacilityId === facility.uid));
  }, [facilitiesQuery.data, addedFacilityIdsList]);

  const [addFacilityMenuAnchorEl, setAddFacilityMenuAnchorEl] = useState(null);

  const handleAddFacilityMenuOpen = useCallback((event) => {
    setAddFacilityMenuAnchorEl(event.currentTarget);
  }, []);

  const handleAddFacilityMenuClose = useCallback(() => {
    setAddFacilityMenuAnchorEl(null);
  }, []);

  const handleAddFacilityMenuOptionClick = useCallback((facility) => {
    onFacilityAdd(facility);

    handleAddFacilityMenuClose();
  }, [onFacilityAdd, handleAddFacilityMenuClose]);

  const addFacilityMenuOpen = Boolean(addFacilityMenuAnchorEl);

  return (
    <Box>
      <Button
        id='add-facility-button'
        aria-haspopup="true"
        aria-controls={addFacilityMenuOpen ? 'add-facility-menu' : undefined}
        aria-expanded={addFacilityMenuOpen ? 'true' : undefined}
        color='secondary'
        sx={{ fontWeight: 700 }}
        onClick={handleAddFacilityMenuOpen}
      >
        Add Facility
      </Button>

      <Menu
        id='add-facility-menu'
        aria-labelledby='add-facility-button'
        open={addFacilityMenuOpen}
        anchorEl={addFacilityMenuAnchorEl}
        elevation={2}
        variant='menu'
        onClose={handleAddFacilityMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: '40vh'
            }
          }
        }}
      >
        {
          facilityOptions.length > 0
            ? facilityOptions.map((facility) => (
              <MenuItem key={facility.uid} onClick={() => handleAddFacilityMenuOptionClick(facility)}>
                {
                  facility.name
                }
              </MenuItem>
            ))
            : (
              <Box sx={{ px: 2, py: 1 }}>
                <Typography>No Facilities available to add.</Typography>
              </Box>
            )
        }
      </Menu>
    </Box>
  );
}
