import React, { useCallback } from 'react';
import { Grid, Button, Typography, Dialog, DialogContent, Box } from '@mui/material';
import toast from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { useMutation } from 'react-query';
import { useGroupContext } from './GroupContext';
import Data from '../../Middleware/Data';
import { Icon } from '../../Components/Shared/Icon';
import { useHistory } from 'react-router-dom';
import { GROUP_PATH } from '../../constants/paths';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';

const GroupDeletionConfirmationDialog = ({ onClose, onCancel, onOk }) => {
  return (
    <Dialog
      open
      maxWidth='sm'
      onClose={onClose}
    >
      <DialogContent sx={{ p: 6 }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default
          })}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Icon icon="Cancel" sx={(theme) => ({ color: theme.palette.error.main, fontSize: 70 })} />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography variant='h5'>Are you sure?</Typography>

              <Typography variant='body2'>
                Warning: Deleting a group&apos;s data cannot be undone, and the data will be deleted permanently.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <Button
                color='disabled'
                variant='outlined'
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  onOk();
                  onClose();
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const QuickActionsList = () => {
  const [confirmationDialogNode, openConfirmationDialog] = useConfirmationDialog();

  const history = useHistory();

  const groupContext = useGroupContext();

  const deleteGroupMutation = useMutation((groupId) =>
    Data.deleteGroup(groupId).then(GenericErrorDetectorForMutations)
  );

  const handleGroupDelete = useCallback(async () => {
    const shouldDelete = await openConfirmationDialog((close) => (
      <GroupDeletionConfirmationDialog
        onClose={() => close(false)}
        onCancel={() => close(false)}
        onOk={() => close(true)}
      />
    ));

    if (!shouldDelete) {
      return;
    }

    const loadingId = toast.loading('Deleting group...');

    deleteGroupMutation.mutate(groupContext.groupId, {
      onSuccess: () => {
        toast.success('Group deletion success.');

        history.replace(GROUP_PATH);
      },
      onError: () => {
        toast.error('Failed to delete group.');
      },
      onSettled: () => {
        toast.dismiss(loadingId);
      }
    });
  }, [history, groupContext, deleteGroupMutation, openConfirmationDialog]);

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Quick Actions
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant='text'
            size="small"
            color='error'
            onClick={handleGroupDelete}
            startIcon={
              <Icon icon='DeleteOutlined' />
            }
          >
          Delete Group
          </Button>
        </Grid>
      </Grid>

      {
        confirmationDialogNode
      }
    </>
  );
};

export default QuickActionsList;
