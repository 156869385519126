import React from 'react';
import './Card.scss';

const Card = ({ head, body, footer, containerStyle, bodyStyle }) => {
  return (
    <div className='card__wrapper' style={{ ...(containerStyle || {}) }}>
      <div className='card__head'>{head}</div>
      <div className='card__body' style={{ ...(bodyStyle || {}) }}>
        {body}
      </div>
      <div className='card__footer'>
        {footer}
      </div>
    </div>
  );
};

export default Card;
