import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Select, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import DataStore from '../../Stores/DataStore';
import { appIconMap, iconStyleMap, iconTextMap } from '../../Lib/util';

@observer
class ApplicationSelect extends Component {
  constructor (props) {
    super(props);
    this.state = {
      name: 'No Application Selected'
    };
  }

  componentDidMount () {
    DataStore.getApplications();
  }

  handleChange = e => {
    this.setState({ name: e.target.value });
    DataStore.selectApplication(DataStore.applications.find(({ name }) => name === e.target.value));
  }

  render () {
    const { applications } = DataStore;
    const values = [{ name: 'No Application Selected' }, ...applications.filter(app => app.active)];
    const { onChange: handleChange, value, name } = this.props;
    return (
      <Select name={name} value={value} onChange={handleChange} style={{ minWidth: '150px' }} >
        {
          values.map((app, i) => {
            const text = iconTextMap(app);
            const icon = appIconMap(app);
            return (
              <MenuItem key={app.name} value={app.name}>
                {!!icon && (
                  <ListItemIcon style={{ position: 'absolute', bottom: '3px', top: '2px' }}>
                    <img style={iconStyleMap(app)} src={icon} alt={`${app.name} logo`} />
                  </ListItemIcon>
                )}
                <ListItemText style={{ marginLeft: '20px' }}>{text}</ListItemText>
              </MenuItem>
            );
          })
        }
      </Select>
    );
  }
}

export default ApplicationSelect;
