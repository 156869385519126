import React from 'react';
import { Typography, Box } from '@mui/material';
import { SimpleLoader } from './SimpleLoader';

export const LoadingAndErrorHandler = ({
  containerStyles,
  isLoading,
  isError,
  isSuccess,
  errorMessage = '',
  textStyles,
  ...props
}) => {
  return (
    <>
      {
        (isLoading || isError) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', ...containerStyles }}>
            {
              isLoading && (
                <SimpleLoader />
              )
            }
            {
              isError && (
                <Typography sx={{ ...textStyles }}>
                  {errorMessage || 'Error while loading the data'}
                </Typography>
              )
            }
          </Box>
        )
      }
      {
        isSuccess && !isLoading && !isError
          ? typeof props.children === 'function'
            ? props.children()
            : props.children
          : null
      }
    </>
  );
};
