import React, { useState } from 'react';
import Data from '../../Middleware/Data';
import { MultiSelectDropDown } from '@nucleos/core-ui';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';
import { QueryKeys } from '../../Lib/query-keys';
import { useQuery } from 'react-query';

const RolesSelect = ({
  id,
  testId,
  error,
  label,
  name,
  onChange,
  value,
  filterOptions = null,
  disabled,
  noSelectedValue,
  helperText,
  disableHelperText,
  roleId,
  DropdownContainerProps
}) => {
  const [search, setSearch] = useState('');
  const rolesQuery = usePaginatedQuery(
    [
      'ROLES_DROPDOWN',
      filterOptions.byLearner,
      !filterOptions.byNonLearner,
      search
    ],
    ({ pageParam = {} }) =>
      Data.getUpdatedRoles({
        page: pageParam.page || 1,
        isLearner: filterOptions.byLearner || !filterOptions.byNonLearner,
        limit: 10,
        roleName: search,
        sortBy: 'label',
        sortOrder: 'ASC'
      }),
    { listKeyName: 'rows' }
  );
  const roleQuery = useQuery(
    QueryKeys.Roles.item(roleId),
    () => Data.getUpdatedRole(roleId),
    {
      enabled:
        !!roleId && !rolesQuery.data.pages.some((role) => role.id === roleId)
    }
  );

  let options = [];

  if (rolesQuery && rolesQuery.data && rolesQuery.data.pages) {
    options = rolesQuery.data.pages.map((r) => {
      return { ...r, title: r.label, value: r.id };
    });
  }

  if (
    !!roleId &&
    !rolesQuery.data.pages.some((role) => role.id === roleId) &&
    roleQuery.status === 'success'
  ) {
    options = [
      { title: roleQuery.data.label, value: roleQuery.data.id, hidden: !!search },
      ...options
    ];
  }

  if (noSelectedValue && !search) {
    options = [{ title: noSelectedValue, value: '' }, ...options];
  }

  return (
    <div>
      <MultiSelectDropDown
        dropdownId={id}
        fullWidth
        testId={testId}
        label={label}
        name={name}
        onChange={onChange}
        options={options}
        searchable
        isSearchLoading={!rolesQuery.isFetched && rolesQuery.isFetching}
        onSearchChange={(search) => {
          setSearch(search);
        }}
        value={options.filter((op) => value.includes(op.value))}
        disabled={disabled}
        disableMultiSelect
        fetchNextOptionsPage={rolesQuery.fetchNextPage}
        isNextPageLoading={rolesQuery.isFetching}
        hasNextOptions={rolesQuery.hasNextPage}
        error={error}
        helperText={helperText}
        disableHelperText={disableHelperText}
        DropdownContainerProps={DropdownContainerProps}
      />
    </div>
  );
};

export default RolesSelect;
