import React, { useEffect } from 'react';
import { getDays } from '../../Lib/util';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../Lib/query-keys';
import Data from '../../Middleware/Data';

const timePeriodFromURL = new URLSearchParams(window.location.search).get(
  'timePeriod'
);
const appsFromURL = new URLSearchParams(window.location.search).get('apps');

const initialValue = {
  timePeriod: timePeriodFromURL || getDays()[1].val,
  apps: appsFromURL ? JSON.parse(appsFromURL) : null,
  isPDFMode: false,
  group: null
};

export const GroupsLearningRecordContext = React.createContext(initialValue);

export const useGroupsLearningRecordContext = () =>
  React.useContext(GroupsLearningRecordContext);

export const GroupsLearningRecordContextProvider = ({
  children,
  isPDFMode
}) => {
  const location = useLocation();
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const selectedGroupQuery = useQuery(
    QueryKeys.Groups.item(groupId),
    () => Data.getGroup(groupId),
    {
      placeholderData: {},
      enabled: !!groupId && groupId !== 'ALL',
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
  const timePeriodFromURL = new URLSearchParams(location.search).get(
    'timePeriod'
  );
  const appsFromURL = new URLSearchParams(location.search).get('apps');
  const state = {
    timePeriod: timePeriodFromURL || getDays()[1].val,
    apps: appsFromURL ? JSON.parse(appsFromURL) : null
  };

  useEffect(() => {
    if (!timePeriodFromURL) {
      const search = new URLSearchParams(location.search);
      search.set('timePeriod', getDays()[1].val);
      history.replace({ search: search.toString() });
    }
  }, []);

  const setTimePeriod = (value) => {
    const search = new URLSearchParams(location.search);
    search.set('timePeriod', value);
    history.replace({ search: search.toString() });
  };

  const setApps = (value) => {
    const search = new URLSearchParams(location.search);
    search.set('apps', JSON.stringify(value));
    history.replace({ search: search.toString() });
  };

  return (
    <GroupsLearningRecordContext.Provider
      value={{
        ...state,
        isPDFMode,
        setApps,
        setTimePeriod,
        group:
          groupId === 'ALL'
            ? { name: 'All Learners', id: 'ALL' }
            : selectedGroupQuery.data
      }}
    >
      {children}
    </GroupsLearningRecordContext.Provider>
  );
};
