import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Divider, IconButton, InputAdornment, TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  TableSortLabel,
  styled,
  Box
} from '@mui/material';
import debounce from 'lodash.debounce';
import FlexTileGrid from '../../../Components/Shared/TileGrid/TileGrid';
import LoadingComponent from '../../../Components/Widgets/LoadingComponent';
import DataStore from '../../../Stores/DataStore';
import moment from 'moment';
import manageIcon from '../manage_user.svg';
import { PAGE_ROW_LIMIT } from '../../../Lib/CONSTANTS';
import { getUserStatusLabels } from '../../../Lib/util';
import { Icon } from '../../../Components/Shared/Icon';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';
import { MESSENGER_ADMIN_PATH, USER_PATH } from '../../../constants/paths';

const PREFIX = 'MessengerUsers';
const classes = {
  searchField: `${PREFIX}-searchField`,
  visuallyHidden: `${PREFIX}-visuallyHidden`
};

const Root = styled('div')(() => ({
  [`& .${classes.searchField}`]: {
    borderRadius: '20px',
    padding: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    width: '547px'
  },
  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const breadcrumbLinks = [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: '#', label: 'Users' }
];

const MessengerUsers = () => {
  const history = useHistory();
  const { allUsers, getUsersLoading, getUsers, allUsersCount } = DataStore;
  const [searchQuery, setSearchQuery] = useState('');
  const [orderBy, setOrderBy] = React.useState('username');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = useState(0);

  useEffect(
    () => {
      getUsers(searchQuery, page, orderBy, order);
    },
    [getUsers, page]
  );

  const formatDate = (date) => {
    return moment(date).format('MMM DD, YYYY hh:mm a');
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setPage(0);
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const debouncedSearch = useRef(
    debounce(async (query) => {
      getUsers(query.toLowerCase(), 0, orderBy, order);
    }, 500)
  ).current;

  const createSortHandler = (property) => (event) => {
    setOrderBy(property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    getUsers(searchQuery.toLowerCase(), 0, orderBy, order);
  };

  const headCells = [
    {
      id: 'username',
      label: window.nucleosConfig.usernameLabel || 'Username'
    },
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'bookingID', label: 'Booking ID' },
    { id: 'createdAt', label: 'Date Created' },
    { id: 'updatedAt', label: 'Last Accessed' },
    { id: 'active', label: 'Status' }
  ];

  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title="Users" links={breadcrumbLinks} />
      <Root
        className='user-update'
        // onClick={() => setShouldShowSearchResults(false)}
      >
        <div style={{ maxWidth: '100%', fontSize: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ maxWidth: '60%', paddingRight: '50px' }}>
            <TextField
              className={classes.searchField}
              onChange={handleSearch}
              value={searchQuery}
              placeholder='Search Users'
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <Icon icon="Search" />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { height: '32px' }
              }}
              sx={{
                '& fieldset': { border: 'none' }
              }}
            />
          </div>
          <Button style={{ height: '42px' }} color='primary' variant='contained' onClick={() => history.push(USER_PATH)}>
            <img
              style={{ paddingRight: '10px' }}
              src={manageIcon}
              alt='user'
            />{' '}
          Manage Users
          </Button>
        </div>
        {
          getUsersLoading
            ? <LoadingComponent />
            : allUsers && allUsers.length === 0
              ? <h2>No users available, please contact your system administrator if this continues.</h2>
              : <section style={{ marginBottom: '42px' }}>
                <FlexTileGrid
                  gridColTiers={{ xs: 12 }}
                  tileStyle={{ padding: 0, maxWidth: '100%', overflow: 'scroll' }}
                  className='usage-history__table-container'
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            style={{ whiteSpace: 'nowrap' }}
                            sortDirection={orderBy === headCell.id ? order : false}
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={orderBy === headCell.id ? order : 'asc'}
                              onClick={createSortHandler(headCell.id)}
                            >
                              {headCell.label}
                              {orderBy === headCell.id
                                ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                  </span>
                                )
                                : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allUsers && allUsers.map((row, index) => (
                        <TableRow
                          key={row.uid}
                          style={{
                            background: index % 2 ? 'white' : '#f8f8fb'
                          }}
                        >
                          <TableCell style={{ whiteSpace: 'nowrap' }}><Link style={{ color: '#449FFF', textDecoration: 'underline' }} to={`/messenger/admin/users/${row.uid}`}>{row.username}</Link></TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{row.firstName}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{row.lastName}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{row.bookingID}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(row.createdAt)}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDate(row.updatedAt)}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>{getUserStatusLabels(row.active, row.isBanned).map(status => status.label).join(', ')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </FlexTileGrid>
                <TablePagination component='div' count={allUsersCount} rowsPerPage={PAGE_ROW_LIMIT} page={page} onPageChange={(event, nextPage) => {
                  setPage(nextPage);
                }} rowsPerPageOptions={[10]} />
              </section>

        }
        <Divider variant='fullWidth' />
      </Root>
    </Box>
  );
};

export default (observer(MessengerUsers));
