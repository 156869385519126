import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Typography, SearchInput, Table } from '@nucleos/core-ui';

import Data from '../../Middleware/Data';
import { QueryKeys } from '../../Lib/query-keys';
import { useFiltersManager } from '../../hooks/useFilters';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import { GenericNoDataFound } from '../../Components/ErrorStates/GenericNoDataFound';
import { formatDateTime } from '../../Lib/util';
import { useTableSorting } from '../../hooks/useTableSorting';

const defaultSorting = {
  column: 'CREATED_AT',
  sortOrder: 'DESC'
};

const ApiKeys = {
  learnerName: 'learnerName',
  adminName: 'adminName',
  note: 'note'
};

const ColumnKeys = {
  LEARNER_NAME: 'LEARNER_NAME',
  ADMIN_NAME: 'ADMIN_NAME',
  NOTE: 'NOTE',
  CREATED_AT: 'CREATED_AT'
};

const AuditLogs = () => {
  const filterManager = useFiltersManager({
    defaultFilter: [],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'device-audit-logs'
    });

  const requestData = {
    offset: (page - 1) * recordsPerPage,
    orderBy: columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
    order: columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
    q: JSON.stringify({
      learnerName: filterManager.getValue(ApiKeys.learnerName) || '',
      adminName: filterManager.getValue(ApiKeys.adminName) || '',
      note: filterManager.getValue(ApiKeys.note) || ''
    }),
    limit: recordsPerPage
  };

  const auditLogsQuery = useQuery(
    QueryKeys.LearnerSessionLookup.listing(requestData),
    () => Data.getLearnerSessionAuditLogs(requestData),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { rows: [], count: 0 }
    }
  );

  const columns = [
    {
      title: 'Learner Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.LEARNER_NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.LEARNER_NAME, sortOrder }),
      render: (item) => <Typography>{item.learnerName}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Full name"
          value={filterManager.getValue(ApiKeys.learnerName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.learnerName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.learnerName }); }
            filterManager.onFilterApply({
              key: ApiKeys.learnerName,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Unlocked By',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.ADMIN_NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.ADMIN_NAME, sortOrder }),
      render: (item) => <Typography>{item.adminName}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Username"
          value={filterManager.getValue(ApiKeys.adminName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.adminName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.adminName }); }
            filterManager.onFilterApply({
              key: ApiKeys.adminName,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Note',
      enableSort: false,
      render: (item) =>
        <Typography
          title={item.note}
          style={{ display: 'inline-block', width: '50ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {item.note}
        </Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Note"
          style={{ width: '50ch' }}
          value={filterManager.getValue(ApiKeys.note) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.note) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.note }); }
            filterManager.onFilterApply({
              key: ApiKeys.note,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Date & Time',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CREATED_AT),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CREATED_AT, sortOrder }),
      render: (item) => <Typography>{formatDateTime(item.createdAt)}</Typography>
    }
  ];

  return (
    <div className="nucleos-core">
      <div className="my-4 flex justify-between">
        <Typography style={{ fontSize: 16, color: '#333', fontWeight: 600 }}>
          Audit Logs for Device Unlocking
        </Typography>
      </div>
      <LoadingAndErrorHandler
        isLoading={auditLogsQuery.isLoading}
        isSuccess={auditLogsQuery.isSuccess}
        isError={auditLogsQuery.isError}
      >
        <Table
          columns={columns}
          noDataMessage={<GenericNoDataFound className="mb-5" />}
          loading={auditLogsQuery.isFetching}
          rowsData={auditLogsQuery.data.rows ? auditLogsQuery.data.rows : []}
          pagination
          totalRecords={auditLogsQuery.data.count ? auditLogsQuery.data.count : 0}
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </LoadingAndErrorHandler>
    </div>
  );
};

export default AuditLogs;
