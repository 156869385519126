import { useEffect } from 'react';
import Authentication from '../Stores/Authentication';
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';

export default function Redirect () {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!Authentication.isAuthenticated) {
      history.replace('/login/' + location.search);
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const redirectTo = searchParams.get('redirectTo');
    if (redirectTo) {
      history.replace(redirectTo);
    } else {
      history.replace('/home');
    }
  }, []);

  return null;
}
