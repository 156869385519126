import * as yup from 'yup';

export const actionTypes = Object.freeze({
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  ASSIGN: 'ASSIGN',
  RESPOND: 'RESPOND',
  DUPLICATE: 'DUPLICATE',
  REQUEST_TYPE_CHANGE: 'REQUEST_TYPE_CHANGE'
});

export const actionLabels = Object.freeze({
  [actionTypes.VIEW]: 'View',
  [actionTypes.CREATE]: 'Create',
  [actionTypes.ASSIGN]: 'Assign',
  [actionTypes.RESPOND]: 'Respond',
  [actionTypes.DUPLICATE]: 'Duplicate',
  [actionTypes.REQUEST_TYPE_CHANGE]: 'Change Request Type'
});

export const permissionEnum = Object.freeze({
  ALL: 'ALL',
  BY_FACILITY: 'BY_FACILITY',
  BY_CATEGORY: 'BY_CATEGORY',
  NONE: 'NONE'
});

const learnerPermissionSchema = yup
  .object()
  .shape({
    [actionTypes.VIEW]: yup
      .string()
      .oneOf([permissionEnum.BY_CATEGORY, permissionEnum.NONE], 'Required')
      .required('Required'),

    [actionTypes.CREATE]: yup
      .string()
      .oneOf([permissionEnum.BY_CATEGORY, permissionEnum.NONE], 'Required')
      .required('Required')
  })
  .required('Required');

const nonLearnerPermissionSchema = yup.object().when('isEnabled', {
  is: true,
  then: (schema) =>
    schema
      .shape({
        [actionTypes.VIEW]: yup
          .string()
          .oneOf(Object.values(permissionEnum), 'Required')
          .required('Required'),

        [actionTypes.ASSIGN]: yup
          .string()
          .oneOf(Object.values(permissionEnum), 'Required')
          .required('Required'),

        [actionTypes.RESPOND]: yup
          .string()
          .oneOf(Object.values(permissionEnum), 'Required')
          .required('Required'),

        [actionTypes.DUPLICATE]: yup
          .string()
          .oneOf(Object.values(permissionEnum), 'Required')
          .required('Required'),

        [actionTypes.REQUEST_TYPE_CHANGE]: yup
          .string()
          .oneOf([permissionEnum.ALL, permissionEnum.NONE], 'Required')
          .required('Required')
      })
      .required('Required')
});

const categoriesSchema = yup
  .array(yup.number())
  .when('permissions', {
    is: (permissions) =>
      permissions.some(
        (per) =>
          per.isEnabled &&
          Object.values(per.permission).includes(permissionEnum.BY_CATEGORY)
      ),
    then: (schema) => schema.min(1, 'Select at least one category'),
    otherwise: (schema) => schema.min(0)
  })
  .required();

export const roleSchema = yup.object().shape({
  name: yup.string().max(32).required('Please enter name'),
  description: yup.string().max(250).required('Please enter description'),
  isGrievanceEnabled: yup.boolean().is([true], 'Please enable grievance'),
  isLearner: yup.boolean(),
  grievancePermission: yup.object().when('isLearner', {
    is: true,
    then: (schema) =>
      schema.shape({
        permissions: yup.array(
          yup.object().shape({
            level: yup.number().required(),
            isEnabled: yup.boolean().required(),
            permission: learnerPermissionSchema
          })
        ),
        categories: categoriesSchema
      }),
    otherwise: (schema) =>
      schema.shape({
        permissions: yup
          .array(
            yup.object().shape({
              level: yup.number().required(),
              isEnabled: yup.boolean().required(),
              permission: nonLearnerPermissionSchema
            })
          )
          .test({
            test: (permissions) =>
              !permissions.every((per) => per.isEnabled === false),
            message: 'At lease one level needs to be enabled',
            name: 'atLeastOneLevel'
          }),
        categories: categoriesSchema
      })
  })
});
