import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Data from '../../../../../Middleware/Data';
import { GenericErrorDetectorForMutations } from '../../../../../Middleware/Api';

import { GrievancePDFHeader } from './GrievancePDFHeader';
import { Chip, Typography } from '@nucleos/core-ui';
import moment from 'moment-timezone';
import { useUserQuery } from '../../../../../hooks/useUser';
import { getIconForSummary } from '../utils';
import { ACTIVE_ROLES, GRIEVANCE_RESPONSE_STATUS } from '../../../../../Lib/CONSTANTS';
import { LevelDisplayName } from '../../../../../Components/Grievance/LearnerAcceptOrRejectUI';
import PDFIcon from '../../../../../Assets/pdf-icon.svg';
import { Icon } from '../../../../../Components/Shared/Icon';
import { useAppContext } from '../../../../../Contexts/AppContext';
import { Box } from '@mui/material';

const TIMEZONE = process.env.REACT_APP_TIMEZONE_IDENTIFIER;

function GrievancePDF () {
  const { grievanceId } = useParams();

  const appContext = useAppContext();

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#fff';
    document.body.style.backgroundColor = '#fff';
  }, []);

  const grievanceQuery = useQuery(
    ['grievance', grievanceId],
    () =>
      Data.getAdminGrievance(grievanceId).then(
        GenericErrorDetectorForMutations
      ),
    {
      enabled: !!grievanceId,
      select: (data) => data.grievance
    }
  );

  const userProfile = useUserQuery({ enabled: true });

  const rolesQuery = useQuery(['ALL_ROLE'], () => Data.getAllFormRoles(), {
    select: (data) => data.roles,
    placeholderData: { roles: [] }
  });

  const newRolesQuery = useQuery(
    ['NEW_ROLES', grievanceId],
    () => Data.getNewFormRoles(grievanceId),
    {
      select: (roles) =>
        roles.map((f) => ({
          title: f.label,
          value: f.id
        })),
      placeholderData: []
    }
  );

  return (
    <div className="nucleos-core">
      <div className="nucleos-core nucleos-grievance-pdf">
        {grievanceQuery.data && (
          <div>
            <section>
              <GrievancePDFHeader
                requestNumber={grievanceQuery.data.requestNumber}
                requestType={grievanceQuery.data.requestType}
                requestorName={`${grievanceQuery.data.User.firstName} ${grievanceQuery.data.User.lastName}`}
                createdAt={grievanceQuery.data.createdAt}
              />
            </section>

            <section className="flex flex-col gap-y-4 pb-8">
              <Box className="flex justify-between items-center px-8 py-4" sx={(theme) => ({ borderLeft: `6px solid ${theme.palette.primary.dark} !important` })}>
                <div>
                  <span style={{ fontSize: 24, fontWeight: 700, lineHeight: '29px', letterSpacing: 1.2, textTransform: 'uppercase' }}>
                    {`${grievanceQuery.data.requestType === 'GRIEVANCE' ? 'Grievance' : 'Action Request'} - ${grievanceQuery.data.requestNumber}`}
                  </span>
                </div>

                <div>
                  <img src={appContext.logoURL} alt='logo' style={{ height: 32 }} />
                </div>
              </Box>

              <div className="flex flex-col gap-y-4 px-8" style={{ borderLeft: '6px solid transparent' }}>
                <div className="flex flex-col gap-4 p-4 rounded-lg" style={{ backgroundColor: '#E9F0FF' }}>
                  <div className="flex items-center justify-between border-b border-gray-300 pb-4">
                    <span className="font-bold text-lg">Request Details</span>

                    <Typography variant='subtitle2'>{moment(grievanceQuery.data.createdAt).tz(TIMEZONE).format('MMM D, yyyy HH:mm')}</Typography>
                  </div>

                  <div className="flex justify-between gap-4 border-b border-gray-300 pb-4">
                    <div className="flex flex-col gap-1">
                      <Typography variant='subtitle1'>Requestor Name</Typography>
                      <Typography className="font-bold capitalize">{`${grievanceQuery.data.User.firstName} ${grievanceQuery.data.User.lastName}`}</Typography>
                    </div>

                    <div className="flex flex-col gap-1">
                      <Typography variant='subtitle1'>Alert</Typography>
                      <div>
                        {
                          getIconForSummary({
                            responseStatus: grievanceQuery.data.response_status,
                            age: grievanceQuery.data.age,
                            appealLevel: grievanceQuery.data.appeal_level
                          })
                        }
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <Typography variant='subtitle1'>Category</Typography>
                      <Typography className="font-bold">{grievanceQuery.data.GrievanceCategory.name}</Typography>
                    </div>

                    <div className="flex flex-col gap-1">
                      <Typography variant='subtitle1'>Assigned To</Typography>
                      <Typography className="font-bold">
                        {grievanceQuery.data.assignedToUser
                          ? `${grievanceQuery.data.assignedToUser.UserTitle
                            ? grievanceQuery.data.assignedToUser.UserTitle.title
                            : ''
                          } ${grievanceQuery.data.assignedToUser.showFirstName ||
                            (userProfile.status === 'success' && userProfile.data.Role.name !== ACTIVE_ROLES.LEARNER)
                            ? grievanceQuery.data.assignedToUser.firstName
                            : ''
                          } ${grievanceQuery.data.assignedToUser.lastName}`
                          : grievanceQuery.data.AssignedToRole
                            ? (
                              rolesQuery.data.find(
                                (role) => role.uid === grievanceQuery.data.AssignedToRole
                              ) || {}
                            ).displayName
                            : grievanceQuery.data.AssignedToConfigRole
                              ? (
                                newRolesQuery.data.find(
                                  (role) => role.value === grievanceQuery.data.AssignedToConfigRole
                                ) || {}
                              ).title
                              : '-'}
                      </Typography>
                    </div>

                    <div className="flex flex-col gap-1">
                      <Typography variant='subtitle1'>Jail</Typography>
                      <Typography className="font-bold capitalize">{grievanceQuery.data.Facility.name}</Typography>
                    </div>

                    <div className="flex flex-col gap-1">
                      <Typography variant='subtitle1'>Cell Number</Typography>
                      <Typography className="font-bold">{grievanceQuery.data.User.cellNumber || 'Not Available'}</Typography>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <Typography className="font-bold" variant='h4'>Details</Typography>

                    <Typography>{grievanceQuery.data.request}</Typography>

                    <div className="flex mt-4">
                      <Typography className="font-bold">Digital Signature -&nbsp;</Typography>
                      <Typography>{grievanceQuery.data.signature}</Typography>
                    </div>

                    {
                      [
                        'Psychiatric / Mental Services',
                        'Medical Services'
                      ].includes(grievanceQuery.data.GrievanceCategory.name) &&
                        grievanceQuery.data.authorization
                        ? (
                          <div className="flex mt-2">
                            <Typography className="font-bold">Medical and Psychiatric Grievance Authorization:&nbsp;</Typography>
                            <Typography>{grievanceQuery.data.authorization}</Typography>
                          </div>
                        )
                        : null
                    }
                  </div>
                </div>

                <div className="flex flex-col rounded-lg">
                  {
                    [...grievanceQuery.data.GrievanceResponses].reverse().map((response) => {
                      if (response.status === GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED) {
                        return (
                          <div
                            key={response.id}
                            className="p-2 flex items-center justify-between items-center px-4 py-2 border border-slate-500 border-collapse avoid-element-break"
                            style={{ backgroundColor: '#E5B12933' }}
                          >
                            <Typography variant="body1">{response.response}</Typography>
                            <Typography color="gray" variant="subtitle2">
                              {moment(response.createdAt).tz(TIMEZONE).format('MMM D, yyyy HH:mm')}
                            </Typography>
                          </div>
                        );
                      }

                      if (response.status === GRIEVANCE_RESPONSE_STATUS.CATEGORY_CHANGE) {
                        return (
                          <div
                            key={response.id}
                            className="p-2 flex items-center justify-between items-center px-4 py-2 border border-slate-500 border-collapse avoid-element-break"
                            style={{ backgroundColor: '#E9F0FF' }}
                          >
                            <Typography variant="body1">{response.response}</Typography>
                            <Typography color="gray" variant="subtitle2">
                              {moment(response.createdAt).tz(TIMEZONE).format('MMM D, yyyy HH:mm')}
                            </Typography>
                          </div>
                        );
                      }

                      return (
                        <div key={response.id} className="p-4 border border-slate-500 border-collapse avoid-element-break">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center">
                              <div
                                className="p-1 mr-4 bg-gray-main flex justify-center items-center"
                                style={{ borderRadius: '50%', width: 40, height: 40 }}
                              >
                                <Typography variant="h3" color="white">
                                  {response.User.firstName.slice(undefined, 1).toUpperCase()}
                                  {response.User.lastName.slice(undefined, 1).toUpperCase()}
                                </Typography>
                              </div>
                              <div>
                                <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                                  {response.User.UserTitle ? response.User.UserTitle.title : ''}{' '}
                                  {response.User.showFirstName === true ? response.User.firstName : ''}{' '}
                                  {response.User.lastName}
                                </Typography>
                                <Typography color="gray" variant="subtitle2">
                                  {`${LevelDisplayName[response.level]} Response`}
                                </Typography>
                              </div>
                            </div>
                            <Typography color="gray" variant="subtitle2">
                              {moment(response.createdAt).tz(TIMEZONE).format('MMM D, yyyy HH:mm')}
                            </Typography>
                          </div>

                          <div className="mt-4">
                            <Typography>{response.response}</Typography>

                            {
                              Array.isArray(response.attachments) && response.attachments.length
                                ? (
                                  <div className="grid grid-cols-3 gap-4 my-4">
                                    {
                                      response.attachments.map((file) => (
                                        <div className="flex justify-between items-center bg-gray-100 rounded border p-2">
                                          <div className="flex flex-auto gap-x-2">
                                            <div className="flex-none">
                                              <img src={PDFIcon} style={{ height: 28 }} />
                                            </div>

                                            <div className="flex-1">
                                              <Typography className="font-semibold break-all">
                                                {file.filename}
                                              </Typography>

                                              <Typography variant="mini">
                                                {(file.sizeInKb / 1000).toFixed(2)} MB
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                )
                                : null
                            }

                            <div className="mt-4 inline-block">
                              {response.status === GRIEVANCE_RESPONSE_STATUS.SATISFIED && (
                                <Chip rounded="sharp" className="py-1 pl-2 pr-2" color="success">
                                  <div className="flex justify-center items-center">
                                    <Icon icon="ThumbUp" style={{ height: 20, marginRight: 6 }} />{' '}
                                    <Typography color="success">Yes, Satisfied</Typography>
                                  </div>
                                </Chip>
                              )}

                              {response.status === GRIEVANCE_RESPONSE_STATUS.DISSATISFIED && (
                                <Chip rounded="sharp" className="py-1 pl-2 pr-2" color="error">
                                  <div className="flex justify-center items-center">
                                    <Icon icon="ThumbDown" style={{ height: 20, marginRight: 6 }} />{' '}
                                    <Typography color="success">
                                      {LevelDisplayName[response.level + 1] &&
                                        grievanceQuery.data.requestType === 'GRIEVANCE'
                                        ? `Appeal to ${LevelDisplayName[response.level + 1]}`
                                        : 'Dissatisfied'}
                                    </Typography>
                                  </div>
                                </Chip>
                              )}

                              {[
                                GRIEVANCE_RESPONSE_STATUS.SATISFIED,
                                GRIEVANCE_RESPONSE_STATUS.DISSATISFIED
                              ].includes(response.status) && (
                                <div>
                                  <div className="flex mt-4">
                                    <Typography className="font-bold">Digital Signature -&nbsp;</Typography>
                                    <Typography>{grievanceQuery.data.signature}</Typography>
                                  </div>

                                  <div className="flex mt-4">
                                    <Typography className="font-bold">Responded At -&nbsp;</Typography>
                                    <Typography>{moment(response.learnerActedAt || response.updatedAt).tz(TIMEZONE).format('MMM D, yyyy HH:mm')}</Typography>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}

export default GrievancePDF;
