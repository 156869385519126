import { observable, action, onBecomeObserved, extendObservable, makeObservable } from 'mobx';
import { Data } from '../Middleware/NucleosApi';
import AuthenticationStore, { SESSION_STORAGE_KEYS } from './Authentication';
import moment from 'moment';
import { ROLES } from '../Lib/CONSTANTS';

// #simple-data require no parameters to fetch
// they use either the entire `response` or a single property of the response
// they are initialized to `[]` unless `default` getter is defined
const simpleDataConfigs = [
  // {
  //   endpoint: '/application',
  //   dataKey: '',
  //   observableKey: 'applications'
  //   // get default() {return SomeCrazy.Default}
  // },
  {
    endpoint: '/user?progress=true',
    dataKey: '',
    observableKey: 'user',
    get default () {
      return {
        CourseProgresses: [],
        UsageData: [{ Application: {}, Course: {} }]
      };
    }
  }
];

const blankUser = { Role: { name: '' }, Groups: [] };

class DataStore {
  @observable logout = false

  @observable cases = []

  @observable componentDataUrls = []

  @observable activity = []

  @observable appDetail = {}

  @observable getAppDetailLoading = null

  @observable goals = []

  @observable setGoalSuccess = null

  @observable setGoalError = null

  @observable deleteGoalSuccess = null

  @observable deleteGoalError = null

  @observable usageChartData = []

  @observable self = {}

  @observable getSelfLoading = null

  @observable currentUser = blankUser

  @observable getUserError = null

  @observable addedUser = {}

  @observable updatedUser = {}

  @observable allUsers = []

  @observable allUsersCount = 0

  @observable getUsersLoading = null

  @observable getExternalUsersLoading = null

  @observable allExternalUsers = []

  @observable externalUsersCount = 0

  @observable groups = []

  @observable getGroupsLoading = null

  @observable group = { Users: [] }

  @observable getGroupError = null

  @observable groupId = null

  @observable userId = null

  @observable groupDeleteId = null

  @observable groupDeleteResult = {}

  @observable updatedGroup = {}

  @observable userSearchResult = []

  @observable learningRecord = { CourseProgresses: [] }

  @observable getLearningRecordsLoading = null

  @observable groupSearchResult = []

  @observable resetPassword = ''

  @observable messagelogs = ''

  @observable messagelogCounts = {}

  @observable chartData = []

  @observable getChartDataLoading = null

  @observable rawActivity = []

  @observable getRawActivityLoading = null

  @observable lastActivity = {}

  @observable
  titleBarInfo = {
    ApplicationTitle: null,
    CourseTitle: null,
    ModuleTitle: null
  }

  @observable addedInfo = {}

  @observable applications = []

  @observable applicationCourses = []

  @observable setApplicationActiveSuccess = null

  @observable setApplicationActiveError = null

  @observable getApplicationsLoading = null

  @observable updateApplicationLoading = null

  @observable addAppItemSuccess = null

  @observable addAppItemError = null

  @observable activateCourseSuccess = null

  @observable activateCourseError = null

  @observable loading = false

  @observable deleteUserError = null

  @observable deleteUserSuccess = null

  @observable disableUserSuccess = null

  @observable createGroupSuccess = null

  @observable createGroupError = null

  @observable updateGroupSuccess = null

  @observable updateGroupError = null

  @observable addUserToGroupSuccess = null

  @observable addUserToGroupError = null

  @observable deleteUsersFromGroupSuccess = null

  @observable deleteUsersFromGroupError = null

  @observable deleteGroupSuccess = null

  @observable deleteGroupError = null

  /* UI State Management Flags */
  @observable activeUsersGroupsTab = 'users'

  @observable pageTitleDetail = null

  @observable roles = []

  @observable facilities = []

  @observable gsuiteUsers = []

  @observable gsuiteUser = null

  @observable searchReturned = false

  @observable selectedApplication

  @observable groupApplicationTotals = []

  @observable isGroupApplicationTotalsLoading = false

  @observable userUsageTotals = []

  @observable usageHistoryChartLoading = false

  @observable userApplications = []

  @observable usersInGroupTotals = []

  @observable userMap = {}

  @observable maxUserInGroupSeconds = -1

  @observable userInGroupMaxTotal = 0

  @observable institution = 'none'

  @observable iframe = false

  @observable appLoading = false

  @observable notifications = []

  @observable recent = []

  @observable recentById = {}

  @observable messages = {}

  @observable allMessagesLoaded = {}

  @observable getRequestedExternalUsers = []

  @observable showEmailVerification = false

  @observable grievanceSuccessToast = false;

  @observable showPermissionChangeToast = false;

  @observable userLearningProgress = [];

  @observable userLearningProgressLoading = false;

  @observable userLearningCourseLessons = {};

  @observable userLearningCourseLessonsLoading = false;

  @observable createGrievanceSteps = 0;

  @observable recordingConfiguration = null;

  @observable updateUserLoading = false;

  @observable grievancePDFGenerating = [];

  constructor () {
    makeObservable(this);

    // #simple-data added here
    simpleDataConfigs.forEach(conf => {
      extendObservable(this, { [conf.observableKey]: conf.default || [] });
      onBecomeObserved(
        this,
        conf.observableKey,
        this.handleSimpleData.bind(this, conf)
      );
    });

    onBecomeObserved(this, 'activity', this.getActivity);
    onBecomeObserved(this, 'goals', this.getGoals);
    onBecomeObserved(this, 'usageChartData', this.getUsageChartData);
  }

  @action
  setSetApplicationActiveSuccess = setApplicationActiveSuccess => {
    this.setApplicationActiveSuccess = setApplicationActiveSuccess;
  }

  setSetApplicationActiveError = setApplicationActiveError => {
    this.setApplicationActiveError = setApplicationActiveError;
  }

  @action
  setAddAppItemSuccess = addUserSuccess => {
    this.addAppItemSuccess = addUserSuccess;
  }

  @action
  setAddAppItemError = addUserError => {
    this.addAppItemError = addUserError;
  }

  @action
  setActivateCourseSuccess = activateCourseSuccess => {
    this.activateCourseSuccess = activateCourseSuccess;
  }

  @action
  setActivateCourseError = activateCourseError => {
    this.activateCourseError = activateCourseError;
  }

  @action
  setDeleteUserError = deleteUserError => {
    this.deleteUserError = deleteUserError;
  }

  @action
  setDeleteUserSuccess = deleteUserSuccess => {
    this.deleteUserSuccess = deleteUserSuccess;
    this.disableUserSuccess = deleteUserSuccess;
  }

  @action
  setCreateGroupSuccess = createGroupSuccess => {
    this.createGroupSuccess = createGroupSuccess;
  }

  @action
  setCreateGroupError = createGroupError => {
    this.createGroupError = createGroupError;
  }

  @action
  setUpdateGroupSuccess = updateGroupSuccess => {
    this.updateGroupSuccess = updateGroupSuccess;
  }

  @action
  setUpdateGroupError = updateGroupError => {
    this.updateGroupError = updateGroupError;
  }

  @action
  setAddUserToGroupSuccess = addUserToGroupSuccess => {
    this.addUserToGroupSuccess = addUserToGroupSuccess;
  }

  @action
  setAddUserToGroupError = addUserToGroupError => {
    this.addUserToGroupError = addUserToGroupError;
  }

  @action
  setDeleteUsersFromGroupSuccess = deleteUsersFromGroupSuccess => {
    this.deleteUsersFromGroupSuccess = deleteUsersFromGroupSuccess;
  }

  @action
  setDeleteUsersFromGroupError = deleteUsersFromGroupError => {
    this.deleteUsersFromGroupError = deleteUsersFromGroupError;
  }

  @action
  setDeleteGroupSuccess = deleteGroupSuccess => {
    this.deleteGroupSuccess = deleteGroupSuccess;
  }

  @action
  setDeleteGroupError = deleteGroupError => {
    this.deleteGroupError = deleteGroupError;
  }

  @action
  setSetGoalSuccess = setGoalSuccess => {
    this.setGoalSuccess = setGoalSuccess;
  }

  @action
  setSetGoalError = setGoalError => {
    this.setGoalError = setGoalError;
  }

  @action
  setDeleteGoalSuccess = deleteGoalSuccess => {
    this.deleteGoalSuccess = deleteGoalSuccess;
  }

  @action
  setDeleteGoalError = deleteGoalError => {
    this.deleteGoalError = deleteGoalError;
  }

  @action
  getLastActivity = uid => {
    Data.getLastActivity(uid || AuthenticationStore.uid).then(
      action(lastActivity => {
        this.lastActivity = lastActivity;
      })
    );
  }

  @action
  addAppUser = async (uid, UserUid, add, currentUser, noQuery) => {
    const addUser = () => {
      // if (add && this.appDetail.Users.find(user => user.uid === UserUid)) { return; }
      // if (add && this.appDetail.Groups.reduce((acc, val) => ([...acc, ...val.users]), []).find(user => user.uid === UserUid)) { return; }
      // this.appDetail.Users = add
      //   ? [...this.appDetail.Users, currentUser]
      //   : this.appDetail.Users.filter(user => user.uid !== UserUid);
    };
    if (noQuery) { return addUser(); }
    return Data.addAppUser(uid, UserUid, add).then(
      action(data => {
        if (data.error) {
          this.addAppItemSuccess = null;
          this.addAppItemError = true;
        } else {
          this.addAppItemSuccess = true;
          this.addAppItemError = null;
          this.addedInfo = data;
          addUser();
        }
      })
    );
  }

  @action
  addAppGroup = async (uid, GroupUid, add, group) => {
    return Data.addAppGroup(uid, GroupUid, add).then(
      action(data => {
        if (data.error) {
          this.addAppItemSuccess = null;
          this.addAppItemError = true;
        } else {
          this.addAppItemSuccess = true;
          this.addAppItemError = null;
          this.addedInfo = data;
          // if (add && this.appDetail.Groups.find(group => group.uid === GroupUid)) { return; }
          // this.appDetail.Groups = add
          //   ? [...this.appDetail.Groups, group]
          //   : this.appDetail.Groups.filter(group => group.uid !== GroupUid);
        }
      })
    );
  }

  @action
  setGranular = (uid, granular) => {
    return Data.setGranular(uid, granular).then(
      action(res => {
        this.appDetail.granular = granular;
      })
    );
  }

  @action
  setLogout = logout => {
    this.logout = logout;
  }

  @action
  getApplications = () => {
    this.getApplicationsLoading = true;
    return Data.getApplications().then(
      action(data => {
        this.applications = data;
        this.getApplicationsLoading = null;
      })
    );
  }

  @action
  updateApplication = (uid, data) => {
    this.updateApplicationLoading = true;
    return Data.updateApplications(uid, data).then(
      action(() => {
        this.updateApplicationLoading = false;
      })
    );
  }

  @action
  getApplicationsActive = () => {
    this.getApplicationsLoading = true;
    return Data.getAppActive().then(
      action(data => {
        this.applications = data;
        this.getApplicationsLoading = null;
      })
    );
  }

  @action
  setApplicationActive = (uid, active) => {
    return Data.putAppActive(uid, active).then(
      action(data => {
        if (data.error) {
          return data.errorMessage.then(({ error }) => {
            this.setApplicationActiveSuccess = null;
            this.setApplicationActiveError = { uid, error };
          });
        } else {
          if (this.applications.length > 0) {
            const index = this.applications.findIndex(app => app.uid === uid);
            this.applications[index].active = data.active;
          }
          if (Object.keys(this.appDetail).includes('active')) {
            this.appDetail.active = data.active;
          }
          this.setApplicationActiveSuccess = { uid };
          this.setApplicationActiveError = null;
        }
      })
    );
  }

  @action
  handleSimpleData = ({ endpoint, dataKey, observableKey }) => {
    return Data.getSimpleData(endpoint).then(
      action(data => {
        if (dataKey ? data[dataKey] : data) {
          this[observableKey] = dataKey ? data[dataKey] : data;
        }
      })
    );
  }

  @action
  getUsageChartData = (sortPaylod, uid) => {
    Data.getUsageChartData({
      user: uid || AuthenticationStore.uid,
      groupBy: ['day', 'application'],
      startDate: moment().subtract('days', 14).utc().toISOString(),
      endDate: moment().utc().toISOString()
    }).then(action(data => (this.usageChartData = data || [])));
  }

  @action
  getActivity = (sortOptions, uid, from, to, orderBy, order, page, limit, apps) => {
    this.getRawActivityLoading = true;
    if (uid) { sortOptions.user = uid; }
    Data.getRawActivity(uid || AuthenticationStore.uid, from, to, orderBy, order, page, limit, apps).then(
      action(data => {
        this.rawActivity = data;
        this.getRawActivityLoading = null;
      })
    );
    Data.getActivity({
      // user: uid,  // || AuthenticationStore.uid,
      ...sortOptions,
      numResults: 5
    }).then(
      action(data => {
        this.activity = data || [];
      })
    );
  }

  @action
  getCourseDetail = id => {
    this.appDetail = {};
    Data.getCourseDetail({
      id
    }).then(
      action(courseData => {
        this.appDetail = courseData;
      })
    );
  }

  @action
  renderImgUrlsInPdf = () => {
    const imgObjForPdf = this.componentDataUrls.map(img => {
      return {
        image: img,
        width: 517,
        margin: [0, 0, 0, 50]
      };
    });
    return this.componentDataUrls.length > 0 ? imgObjForPdf : 'There was an error loading your document.';
  }

  @action
  activeCourse = (id, active) => {
    Data.updateCourse(id, { active }).then(
      action(result => {
        if (result.length === 0) {
          this.activateCourseSuccess = null;
          this.activateCourseError = true;
        } else {
          this.activateCourseSuccess = true;
          this.activateCourseError = null;
          const index = this.appDetail.Courses.findIndex(
            course => course.id === Number(id)
          );
          this.appDetail.Courses[index].active = active;
        }
      })
    );
  }

  @action
  getAppDetail = id => {
    this.getAppDetailLoading = true;
    this.appDetail = {};
    // TODO - get course doesn't return.
    Data.getAppDetail({
      id
    }).then(
      action(data => {
        const { application } = data;
        this.getAppDetailLoading = null;

        if (application) {
          if (application.Groups) {
            const groupUsers = application.Groups.reduce((acc, val) => ([...acc, ...val.users]), []);
            application.Users = application.Users.filter(user => !groupUsers.find(groupUser => groupUser.uid === user.uid));
          }
          this.appDetail = application;

          // RegEx replace ${CONFIG_KEY} with window.nucleosConfig[CONFIG_KEY] or ${CONFIG_KEY} if window.nucleosConfig[CONFIG_KEY] is undefined
          // This is used to replace environment variables in the url patterns
          this.appDetail.linkAddress = this.appDetail.linkAddress && this.appDetail.linkAddress.replace(/\$\{(USERNAME)\}/g, () => localStorage.getItem(SESSION_STORAGE_KEYS.username));
          this.appDetail.linkAddress = this.appDetail.linkAddress && this.appDetail.linkAddress.replace(/\$\{(\w+)\}/g, (match, p1) => window.nucleosConfig[p1] || match);
        }

        if (data.error && !data.error.status.ok) {
          this.getCourseDetail(id);
        }
      })
    );
  }

  @action
  getGoals = () => {
    this.goals = [];
    this.isGoalsLoading = true;
    Data.getGoals(AuthenticationStore.userRole === 'learner' ? AuthenticationStore.uid : this.currentUser.uid).then(
      action(data => {
        this.goals = data || [];
        this.isGoalsLoading = false;
      })
    );
  }

  @action
  setGoal = (description, name, application, minutes, hours, isWeekly, existingGoalId, isLearner) => {
    const existingGoalIdx =
      existingGoalId && this.goals.findIndex(goal => goal.id === existingGoalId);
    if (existingGoalIdx && existingGoalIdx !== -1) {
      // update the existing goal if found
      this.goals[existingGoalIdx].name = name;
      this.goals[existingGoalIdx].description = description;
      this.goals = [...this.goals];
    } else {
      this.goals.push({ description, id: null, name });
    }
    Data.setGoal(
      description,
      name,
      application,
      (minutes || hours ? (minutes || 0) + ((hours || 0) * 60) : undefined),
      isWeekly,
      isLearner ? AuthenticationStore.uid : this.currentUser.uid,
      existingGoalId
    ).then(action(data => {
      if (data.error) {
        this.setGoalSuccess = null;
        this.setGoalError = true;
      } else {
        this.setGoalSuccess = true;
        this.setGoalError = null;
        this.getGoals();
      }
    }));
  }

  @action
  deleteGoal = existingGoalId => {
    Data.deleteGoal(existingGoalId).then(data => {
      if (data.error) {
        this.deleteGoalSuccess = null;
        this.deleteGoalSuccess = true;
      } else {
        this.getGoals();
        this.deleteGoalSuccess = true;
        this.deleteGoalSuccess = null;
      }
    });
  }

  @action
  addUser = ({ firstName, lastName, username, password, email, role, grade, FacilityIds, configRole }) => {
    return Data.addUser(
      firstName,
      lastName,
      username,
      password,
      email,
      role,
      FacilityIds,
      grade,
      configRole
    ).then(
      action(data => {
        if (data.error) {
          return data.errorMessage.then(({ error }) => {
            return {
              data: null,
              error
            };
          });
        }
        const allUsers = this.allUsers && this.allUsers.length ? this.allUsers : [];
        this.allUsers = [...allUsers, data];
        return {
          data: data,
          error: null
        };
      })
    );
  }

  @action
  deleteUser = uid => {
    Data.deleteUser(uid).then(
      action(result => {
        if (result.deleted === true || result.disabled === true) {
          this.allUsers = this.allUsers.filter(u => u.uid !== uid);
          if (result.disabled) { this.disableUserSuccess = true; }
          this.deleteUserSuccess = true;
          this.deleteUserError = null;
        } else {
          this.deleteUserSuccess = null;
          this.deleteUserError = true;
        }
      })
    );
  }

  @action
  bulkDeactivateUsers = async (uids) => {
    const response = await Data.bulkDeactivateUsers(uids);
    return response;
  }

  @action
  bulkReactivateUsers = async (uids) => {
    const response = await Data.bulkReactivateUsers(uids);
    return response;
  }

  @action
  bulkResetUsersPassword = async (uids) => {
    const response = await Data.bulkResetUsersPassword(uids);
    return response;
  }

  @action
  getSelf = async () => {
    this.getSelfLoading = true;
    return Data.getSelf().then(action(data => {
      this.self = data;
      this.getSelfLoading = null;
      return data;
    }));
  }

  @action
  getUser = (uid, progress, externals) => {
    Data.getUser(uid, progress, externals).then(
      action(data => {
        if (data.error) {
          this.getUserError = true;
        } else {
          this.getUserError = null;
          this.currentUser = data;
        }
      })
    );
  }

  @action
  updateUser = (uid, data, self) => {
    this.updateUserLoading = true;
    return Data.updateUser(uid, data).then(
      action(data => {
        this.updateUserLoading = false;
        if (data.error) {
          return data.errorMessage.then(({ error }) => {
            return {
              changed: null,
              error
            };
          });
        }
        if (self) {
          this.self = data;
        } else {
          this.currentUser = data;
        }
        return {
          changed: data.changed,
          error: null
        };
      })
    );
  }

  @action
  clearCurrentUser = () => {
    this.currentUser = blankUser;
  }

  @action
  addGroupToCurrentUser = (group) => {
    this.currentUser.Groups = [...this.currentUser.Groups, group];
  }

  @action
  removedGroupFromCurrentUser = (group) => {
    this.currentUser.Groups = this.currentUser.Groups.filter(g => (g.id !== group.id || g.uid !== group.uid));
  }

  @action
  isCurrentUserAdmin = () => {
    return AuthenticationStore.fullUserRole === ROLES.SUPER_ADMIN;
  }

  @action
  isCurrentUserFormsSuperAdmin = () => {
    return AuthenticationStore.fullUserRole === ROLES.FORMS_SUPER_ADMIN;
  }

  @action
  isCurrentUserFormsAdmin = () => {
    return AuthenticationStore.userRole === 'forms';
  }

  @action
  getUsers = async (searchQuery, page, orderBy, order, filters, limit, updateGlobalList = true) => {
    this.getUsersLoading = true;
    return await Data.getUsers({ query: searchQuery, page, orderBy, order, filters, limit }).then(
      action(data => {
        this.allUsers = updateGlobalList ? data.users : this.allUsers;
        this.allUsersCount = updateGlobalList ? data.count : this.allUsers;
        this.getUsersLoading = null;
        return data;
      })
    );
  }

  @action
  getExternalUsers = (searchQuery, page, orderBy, order) => {
    this.getExternalUsersLoading = true;
    Data.getExternalUsers(searchQuery, page, orderBy, order).then(
      action(data => {
        this.allExternalUsers = data.users;
        this.externalUsersCount = data.count;
        this.getExternalUsersLoading = null;
      })
    );
  }

  @action
  getGroups = (groupsWithUsers, search) => {
    this.getGroupsLoading = true;
    Data.getGroups(groupsWithUsers, search).then(
      action(data => {
        if (data && data.rows) {
          this.groups = data.rows;
        }
        this.getGroupsLoading = null;
      })
    );
  }

  @action
  getGroup = id => {
    Data.getGroup(id).then(
      action(data => {
        if (!data) {
          this.getGroupError = true;
        } else {
          this.group = data;
        }
      })
    );
  }

  @action
  deleteGroup = id => {
    this.groupDeleteId = id;
    Data.deleteGroup(id).then(
      action(result => {
        this.groupDeleteResult = result;
        if (result && result.success) {
          this.deleteGroupSuccess = true;
          this.deleteGroupError = null;
          this.groups = this.groups.filter(group => group.id !== id);
        } else {
          this.deleteGroupSuccess = null;
          this.deleteGroupError = true;
        }
      })
    );
  }

  @action
  createGroup = data => {
    return Data.createGroup(data).then(
      action(data => {
        if (data && data.rows && data.rows.length) {
          this.groups = [...data.rows];
          this.createGroupSuccess = true;
          this.createGroupError = null;
        } else {
          this.createGroupSuccess = null;
          this.createGroupError = true;
        }
      })
    );
  }

  @action
  updateGroup = async (id, data, source = 'UPDATED_GROUP') => {
    const result = await Data.updateGroup(id, data);

    if (result.rows) {
      this.groups = result.rows;

      if (source == 'UPDATED_GROUP') {
        this.updateGroupSuccess = true;
        this.updateGroupError = null;
        const updatedGroup = result.rows.filter(g => g.id == id);
        this.group = updatedGroup[0];
      }

      if (source == 'ADD_USER') {
        this.addUserToGroupError = null;
        this.addUserToGroupSuccess = true;
        const updatedGroup = result.rows.filter(g => g.id == id);
        if (updatedGroup && updatedGroup.length) {
          this.group = updatedGroup[0];
          this.currentUser.Groups = [...this.currentUser.Groups, this.group];
        }
      }

      if (source == 'DELETE_USER') {
        this.deleteUsersFromGroupSuccess = true;
        this.deleteUsersFromGroupError = null;
        const updatedGroups = this.currentUser.Groups.filter(g => {
          const groupId = g.uid || g.id;
          return groupId != id;
        });

        this.currentUser.Groups = [...updatedGroups];

        const updatedGroup = result.rows.filter(g => g.id == id);
        if (updatedGroup && updatedGroup.length) {
          this.group = updatedGroup[0];
        }
      }
    } else {
      if (source == 'UPDATED_GROUP') {
        this.updateGroupSuccess = null;
        this.updateGroupError = true;
      }

      if (source == 'ADD_USER') {
        if (result.errorMessage) {
          const err = await result.errorMessage;
          this.addUserToGroupError = err.error;
        } else {
          this.addUserToGroupError = 'User could not be added to the group. Please try again in a moment';
          this.addUserToGroupSuccess = null;
        }
      }

      if (source == 'DELETE_USER') {
        this.deleteUsersFromGroupSuccess = null;
        this.deleteUsersFromGroupError = true;
      }
    }
  }

  @action
  setGroupPageId = id => {
    this.groupId = id;
  }

  @action
  setUserPageId = id => {
    this.userId = id;
  }

  @action
  searchUser = (searchString, inactive) => {
    if (searchString === null) {
      this.userSearchResult = [];
      return;
    }
    Data.searchUser(searchString, inactive).then(
      action(data => {
        this.userSearchResult = data;
      })
    );
  }

  // Created the function below to use in refactored code.
  // Remove the function above (`searchUser`) once all its references are eliminated from the code base.
  @action
  searchUserAsyncAwait = async (searchString, inactive) => {
    if (searchString === null) {
      return [];
    }
    const response = await Data.searchUser(searchString, inactive);
    return response;
  }

  @action
  searchFormsAdminUser = (searchString, inactive, includeAppAdmins, facility, category) => {
    if (searchString === null) {
      this.userSearchResult = [];
      return;
    }
    Data.searchFormsAdminUser(searchString, inactive, includeAppAdmins, facility, category).then(
      action(data => {
        this.userSearchResult = data;
      })
    );
  }

  // Created the function below to use in refactored code.
  // Remove the function above (`searchFormsAdminUser`) once all its references are eliminated from the code base.
  @action
  searchFormsAdminUserAsyncAwait = async (searchString, inactive, includeAppAdmins, facility, category) => {
    if (searchString === null) {
      return [];
    }
    const response = await Data.searchFormsAdminUser(searchString, inactive, includeAppAdmins, facility, category);
    return response;
  }

  @action
  searchGroup = searchString => {
    if (searchString === null) {
      this.groupSearchResult = [];
      return;
    }
    Data.searchGroup(searchString).then(
      action(data => {
        this.groupSearchResult = data;
      })
    );
  }

  @action
  addUserToGroup = (user, group) => {
    const { uid } = user;
    // make request with groupId and uid to add user to group
    Data.addUserToGroup(uid, group ? group.id : this.group.uid).then(
      action(data => {
        if (data && data.error) {
          return data.errorMessage.then(({ error }) => {
            this.addUserToGroupError = error;
            this.addUserToGroupSuccess = null;
          });
        } else if (data) {
          const groupToAddToUser = group || this.group;
          this.addUserToGroupSuccess = true;
          this.addUserToGroupError = null;
          this.group.Users = [user, ...this.group.Users];
          this.getGroup(groupToAddToUser.uid);
          this.currentUser.Groups = [
            ...this.currentUser.Groups,
            groupToAddToUser
          ];
        } else {
          this.addUserToGroupError = 'User could not be added to the group. Please try again in a moment';
          this.addUserToGroupSuccess = true;
        }
      })
    );
    // add user object to group object's users.
  }

  @action
  deleteUsersFromGroup = (users, group) => {
    Data.deleteUsersFromGroup(users, group ? group.uid : this.group.uid).then(
      action(data => {
        if (data) {
          this.deleteUsersFromGroupSuccess = true;
          this.deleteUsersFromGroupError = null;
          /* if number deleted is the same as number in users,
          remove them all; if not, run get groups. */
          if (users.length === data.deleted) {
            users.forEach(user => {
              this.group.Users = this.group.Users.filter(u => user !== u.uid);
            });
          } else {
            Data.getGroup(this.groupId).then(
              action(data => {
                this.group = data;
              })
            );
          }
          /* `group` might not have been passed in and therefore
              would be undefined */
          this.currentUser.Groups = this.currentUser.Groups.filter(g => {
            return group ? group.uid !== g.uid : this.group.uid !== g.uid;
          });
        } else {
          this.deleteUsersFromGroupSuccess = null;
          this.deleteUsersFromGroupError = true;
        }
      })
    );
  }

  @action
  getPasswordReset = uid => {
    Data.getPasswordReset(uid).then(
      action(result => {
        this.resetPassword = result.resetCode;
      })
    );
  }

  @action
  getPassword = uid => {
    this.getPasswordLoading = true;
    Data.getPassword(uid).then(
      action(result => {
        this.getPasswordLoading = false;
        this.currentUser.password = result.password;
      })
    ).catch(() => {
      this.getPasswordLoading = false;
    });
  }

  @action
  getLearningRecords = uid => {
    this.getLearningRecordsLoading = true;
    Data.getLearningRecords(uid).then(
      action(result => {
        this.learningRecord = result;
        this.getLearningRecordsLoading = null;
      })
    );
  }

  @action
  getUserLearningProgress = uid => {
    this.userLearningProgressLoading = true;
    Data.getUserLearningProgress(uid).then(
      action(response => {
        if (response.courses) {
          this.userLearningProgress = response.courses;
        }
        this.userLearningProgressLoading = false;
      })
    );
  }

  @action
  getCourseLessons = (uid, courseId) => {
    this.userLearningCourseLessonsLoading = true;
    Data.getCourseLessons(uid, courseId).then(
      action(response => {
        if (response.course) {
          this.userLearningCourseLessons = response.course;
        }
        this.userLearningCourseLessonsLoading = false;
      })
    );
  }

  @action
  getMessagelogs = () => {
    Data.getMessagelogs().then(
      action(result => {
        this.messagelogs = result;
      })
    );
  }

  @action
  getMessageLogCounts = () => {
    Data.getMessageLogCounts().then(
      action(result => {
        this.messagelogCounts = result;
      })
    );
  }

  @action
  getChartData = uid => {
    this.getChartDataLoading = true;
    Data.getChartData(uid).then(
      action(result => {
        this.chartData = result;
        this.getChartDataLoading = null;
      })
    );
  }

  @action
  set3rdPartyTitleData = (ApplicationTitle, CourseTitle, ModuleTitle) => {
    // TODO - figure out how to get the right info even though it doesn't exist
    this.titleBarInfo = {
      ApplicationTitle,
      CourseTitle,
      ModuleTitle
    };
  }

  /* UI State Management Flags */

  @action
  setActiveUsersGroupsTab = tab => {
    this.activeUsersGroupsTab = tab;
  }

  @action
  setPageTitleDetail = title => {
    this.pageTitleDetail = title;
  }

  @action getRoles = () => {
    Data.getRoles().then(
      action(roles => {
        this.roles = roles;
      })
    );
  }

  @action searchGsuite = search => {
    if (search === false) {
      this.gsuiteUsers = [];
      // this.searchReturned = true
      return;
    }
    Data.searchGsuite(search).then(
      action(users => {
        this.gsuiteUsers = users;
        this.searchReturned = true;
      })
    );
  }

  @action resetSearchReturned = () => {
    this.searchReturned = false;
  }

  @action setGsuiteUser = user => {
    this.searchReturned = false;
    this.gsuiteUser = user;
  }

  @action selectApplication = application => {
    this.selectedApplication = application;
  }

  @action
  getApplicationCourses = courseArray => {
    this.applicationCourses = courseArray;
  }

@action getGroupApplicationTotals = (groupId) => {
  this.isGroupApplicationTotalsLoading = true;
  Data.groupApplicationTotals(groupId).then(
    action(totals => {
      this.groupApplicationTotals = totals;
      this.isGroupApplicationTotalsLoading = false;
    })
  );
}

@action getUserInGroupTotals = GroupId => {
  if (GroupId === '') { return; }
  Data.usersInGroupTotals(GroupId).then(
    action(totals => {
      this.usersInGroupTotals = totals.reduce((acc, val) => {
        const { UserUid, firstName, lastName, username, application, seconds } = val;
        this.userMap[UserUid] = { firstName, lastName, username };
        const hours = seconds / 3600;
        if (hours > this.maxUserInGroupSeconds) { this.maxUserInGroupSeconds = Number(hours); }
        const curr = (acc[UserUid] || {});
        return { ...acc, [UserUid]: { ...curr, [application]: hours, Total: (curr.Total || 0) + Number(hours) } };
      }, {});
      this.userInGroupMaxTotal = Math.max(...Object.values(this.usersInGroupTotals).map(item => item.Total));
    })
  );
}

  @action getUserUsageHistory = (uid, from, to, timePeriod) => {
    this.usageHistoryChartLoading = true;

    Data.getUserUsageData(uid, from, to, timePeriod).then(
      action(totals => {
        this.userUsageTotals = totals && totals.records;
        this.userApplications = totals && totals.applications;
        this.usageHistoryChartLoading = false;
      })
    );
  }

  @action getInstitution = () => {
    Data.getInstitution().then(
      action(institution => {
        this.institution = institution.INSTITUTION;
      })
    );
  }

  @action createIframe = () => {
    this.iframe = true;
  }

  @action removeIframe = () => {
    this.iframe = false;
  }

  @action setAppLoading = appLoading => {
    this.appLoading = appLoading;
  }

  @action setNotifications = notifications => {
    this.notifications = notifications;
  }

  @action addNewNotification = notification => {
    this.notifications = [notification, ...(this.notifications || [])];
  }

  @action dismissNotification = id => {
    this.notifications = this.notifications.filter(notification => notification.id !== id);
    return Data.dismissNotification(id);
  }

  @action getRecentMessageSessions = (search) =>
    Data.getRecentMessageSessions(search).then(recent => {
      if (!recent.error) {
        this.recent = recent;
        this.recentById = recent.reduce((acc, val) => {
          return { ...acc, [val.id]: val };
        }, {});
        return recent;
      }
      return [];
    })

  @action setMessages = ({ id, messages }) => {
    this.messages = { ...this.messages, [id]: messages };
  }

  @action addMessage = message => {
    const { ApprovedMessagingId: id } = message;
    this.messages[id] = [...this.messages[id], message];

    this.recent = this.recent.map((e) => {
      if (e.id === id) {
        const item = {
          ...e
        };

        if (Array.isArray(item.Messages) && item.Messages.length > 0) {
          item.Messages[0] = {
            ...message
          };
        } else {
          item.Messages = [{
            ...message
          }];
        }

        return item;
      }

      return e;
    });
  }

  @action getMessageHistory = id => {
    Data.getMessageHistory(id, this.messages[id].length, 10).then(messages => {
      if (messages.length === 0) { this.allMessagesLoaded[id] = true; }
      this.messages[id] = [...this.messages[id], ...messages];
    });
  }

  @action getMessage = async id => {
    const response = await Data.getMessage(id);
    return response;
  }

  @action getUnApprovedUser = async (page) => {
    const response = await Data.getUnApprovedUser(page);
    return response;
  }

  @action updateUserApprovalStatus = async ({ approved, id }) => {
    const response = await Data.updateUserApprovalStatus({ approved, id });
    return response;
  }

  @action getApprovedUser = async () => {
    const response = await Data.getApprovedUser();
    return response;
  }

  @action createApprovedMessages = async (data) => {
    const response = await Data.createApprovedMessages(data);
    return response;
  }

  @action updateUserMessagingApprovalStatus = async (status, id) => {
    const response = await Data.updateUserMessagingApprovalStatus(status, id);
    return response;
  }

  @action updateUserMessagingResponseStatus = async (status, id) => {
    const response = await Data.updateUserMessagingResponseStatus(status, id);
    if (response.success) {
      this.recent = this.recent.map((user) => {
        return user.id === id ? { ...user, canUserRespond: status } : user;
      });
    }

    return response;
  }

  @action deActivateApprovedMessage = async (id) => {
    const response = await Data.deActivateApprovedMessage(id);
    return response;
  }

  @action updateUserMessengerStatus = async (uid, status, externalUser = false) => {
    const response = await Data.updateUserMessengerStatus(uid, status, externalUser);
    return response;
  }

  @action getMessengerStatus = async () => {
    const response = await Data.getMessengerStatus();
    return response;
  }

  @action getMessageAlertKeywords = async (page) => {
    const response = await Data.getMessageAlertKeywords(page);
    return response;
  }

  @action addMessageAlertKeyword = async (keyword) => {
    const response = await Data.addMessageAlertKeyword(keyword);
    return response;
  }

  @action deleteMessageAlertKeyword = async (id) => {
    const response = await Data.deleteMessageAlertKeyword(id);
    return response;
  }

  @action getMessagesForAlertKeyword = async (keyword, page) => {
    const response = await Data.getMessagesForAlertKeyword(keyword, page);
    return response;
  }

  @action getMessageAlertsCount = async () => {
    const response = await Data.getMessageAlertsCount();
    return response;
  }

  @action getAllMessages = async (searchQuery, page, orderBy, order, filters) => {
    const response = Data.getAllMessages(searchQuery, page, orderBy, order, filters);
    return response;
  }

  @action markMessageAlertsAsViewed = async (keyword, page) => {
    await Data.markAlertsAsViewed(keyword, page);
  }

  @action getNotifications = () => {
    Data.getNotifications().then((notifications) => {
      this.notifications = notifications;
    });
  }

  @action getExternalUser = async (uid) => {
    const response = await Data.getExternalUser(uid, true);
    return response;
  }

  @action getPaginatedMessages = async (uid, page) => {
    const response = await Data.getMessages(uid, page);
    return response;
  }

  @action getAllGrievances = async (page) => {
    const response = await Data.getAllGrievances(page);
    return response;
  }

  @action getAllAdminGrievances = async (searchQuery, page, orderBy, order, filters) => {
    const response = Data.getAllAdminGrievances(searchQuery, page, orderBy, order, filters);
    return response;
  }

  @action getAdminAssignedGrievances = async (searchQuery, page, orderBy, order, filters) => {
    const response = Data.getAdminAssignedGrievances(searchQuery, page, orderBy, order, filters);
    return response;
  }

  @action getGrievance = async (id) => {
    const response = await Data.getGrievance(id);
    return response;
  }

  @action getAdminGrievance = async (id) => {
    const response = await Data.getAdminGrievance(id);
    return response;
  }

  @action getAllGrievanceCategories = async () => {
    const response = await Data.getAllGrievanceCategories();
    return response;
  }

  @action addGrievance = async (grievance) => {
    const response = await Data.addGrievance(grievance);
    return response;
  }

  @action getAllGrievancesCount = async () => {
    const response = await Data.getAllGrievancesCount();
    return response;
  }

  @action getAssignedGrievancesCount = async () => {
    const response = await Data.getAssignedGrievancesCount();
    return response;
  }

  @action getAllFormRoles = async (category, facility) => {
    const response = await Data.getAllFormRoles(category, facility);
    return response;
  }

  @action getFormRolesWithRespondPermissions = async (category, facility, gid) => {
    const response = await Data.getFormRolesWithRespondPermissions(category, facility, gid);
    return response;
  }

  @action getAllFacilities = async () => {
    const response = await Data.getAllFacilities();
    return response;
  }

  @action assignFormRoleToUser = async (roleId, userId) => {
    const response = await Data.assignFormRoleToUser(roleId, userId);
    return response;
  }

  @action unAssignFormRoleFromUser = async (roleId, userId) => {
    const response = await Data.unAssignFormRoleFromUser(roleId, userId);
    return response;
  }

  @action submitGrievanceResponse = async (id, response) => {
    const res = await Data.submitGrievanceResponse(id, response);
    return res;
  }

  @action getFormResponseTemplates = async (cId) => {
    const response = await Data.getFormResponseTemplates(cId);
    return response;
  }

  @action getAllFormTemplateCategories = async () => {
    const response = await Data.getAllFormTemplateCategories();
    return response;
  }

  @action addFormResponseTemplate = async (template) => {
    const response = await Data.addFormResponseTemplate(template);
    return response;
  }

  @action updateFormResponseTemplate = async (template) => {
    const response = await Data.updateFormResponseTemplate(template);
    return response;
  }

  @action updateGrievanceResponseStatus = async (grievanceId, responseId, status) => {
    const response = await Data.updateGrievanceResponseStatus(grievanceId, responseId, status);
    return response;
  }

  @action assignGrievance = async (gid, cid, assignToType, assignId) => {
    const response = await Data.assignGrievance(gid, cid, assignToType, assignId);
    return response;
  }

  @action duplicateAndAssignGrievance = async (gid, cid, assignToType, assignId) => {
    const response = await Data.duplicateAndAssignGrievance(gid, cid, assignToType, assignId);
    return response;
  }

  @action getGrievanceReport = async (filters) => {
    const response = await Data.getGrievanceReport(filters);
    return response;
  }

  @action downloadGrievanceReport = async (filters) => {
    const response = await Data.downloadGrievanceReport(filters);
    return response;
  }

  @action verifyEmail = async (data) => {
    const response = await Data.verifyEmail(data);
    return response;
  }

  @action resendVerificationEmail = async (data) => {
    const response = await Data.resendVerificationEmail(data);
    return response;
  }

  @action setEmailVerification = async (status) => {
    this.showEmailVerification = status;
  }

  @action setGrievanceToast = async (status) => {
    this.grievanceSuccessToast = status;
  }

  @action createRole = async (data) => {
    const response = await Data.createRole(data);
    return response;
  }

  @action getUpdatedRoles = async (data) => {
    const response = await Data.getUpdatedRoles(data);
    return response;
  }

  @action getUpdatedAllRoles = async () => {
    const response = await Data.getUpdatedAllRoles();
    return response;
  }

  @action getUpdatedRole = async (roleId) => {
    const response = await Data.getUpdatedRole(roleId);
    return response;
  }

  @action updateRole = async (data, roleId) => {
    const response = await Data.updateRole(data, roleId);
    return response;
  }

  @action setPermissionChangeToast = (status) => {
    this.showPermissionChangeToast = status;
  }

  @action setCreateGrievanceSteps = (step) => {
    this.createGrievanceSteps = step;
  }

  @action getRecordingConfigurations = async () => {
    Data.getConfigurations().then(
      action(config => {
        this.recordingConfiguration = config;
      })
    );
  }

  @action setGrievancePDFLoading = (grievanceId, completed) => {
    if (completed) {
      this.grievancePDFGenerating = this.grievancePDFGenerating.filter(gId => gId !== grievanceId);

      return;
    }

    this.grievancePDFGenerating.push(grievanceId);
  }
}

export default new DataStore();
