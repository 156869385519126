import React, { useState, useEffect } from 'react';
import { Dialog } from '@nucleos/core-ui';

import PlaceHolderImg from '../../../Assets/placeholderImg.png';

export default function FaceCaptureModal ({ date, image, open, onClose }) {
  const [imgSrc, setImgSrc] = useState(image);

  useEffect(() => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      // If the image is valid, set the source
      setImgSrc(image);
    };

    img.onerror = () => {
      // If the image is not valid, set the source to the placeholder image
      setImgSrc(PlaceHolderImg);
    };
  }, [image]);

  return (
    <Dialog
      isOpen={open}
      onClose={onClose}
      size="medium"
      title={`${date ? `Face Capture - ${date}` : 'Preview'}`}
    >
      <div className="flex items-center justify-center pt-4">
        <img
          className="object-cover rounded-lg"
          src={imgSrc}
          alt={'Captured Face'}
        />
      </div>
    </Dialog>
  );
}
