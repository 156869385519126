import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  styled
} from '@mui/material';
import FlexTileGrid from '../../../Components/Shared/TileGrid/TileGrid';
import { formatDateTime, getMessageSentFrom, getMessageSentTo } from '../../../Lib/util';
import { PAGE_ROW_LIMIT } from '../../../Lib/CONSTANTS';
import DataStore from '../../../Stores/DataStore';

const PREFIX = 'UserMessages';
const classes = {
  flaggedRow: `${PREFIX}-flaggedRow`,
  link: `${PREFIX}-link`
};

const Root = styled('section')(() => ({
  [`& .${classes.flaggedRow}`]: {
    whiteSpace: 'nowrap',
    fontWeight: '600',
    color: 'red'
  },
  [`& .${classes.link}`]: { color: '#449FFF !important', textDecoration: 'underline' }
}));

const UserMessages = ({ uid }) => {
  const [page, setPage] = useState(0);
  const [messages, setMessages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(
    () => {
      const fetchMessages = async () => {
        const { messages, count } = await DataStore.getPaginatedMessages(uid, page);
        setMessages(messages);
        setTotalCount(count);
      };
      if (uid) {
        fetchMessages();
      }
    },
    [uid, page]
  );

  if (!messages || messages.length === 0) {
    return <h3>No data available.</h3>;
  }

  return (
    <Root style={{ marginBottom: '42px', width: '100%' }}>
      <FlexTileGrid
        gridColTiers={{ xs: 12 }}
        tileStyle={{ padding: 0, maxWidth: '100%', overflow: 'scroll' }}
        className='usage-history__table-container'
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>From</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>To</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Message</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Alert Flag</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Date Sent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages
              ? messages.map((row, index) => {
                const from = getMessageSentFrom(row);
                const to = getMessageSentTo(row);
                return <TableRow
                  key={row.uid}
                  style={{
                    background: index % 2 ? 'white' : '#f8f8fb'
                  }}
                >
                  <TableCell style={{ whiteSpace: 'nowrap' }}><Link className={classes.link} to={`${from.url}`}>{from.name}</Link></TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}><Link className={classes.link} to={`${to.url}`}>{to.name}</Link></TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}><Link className={classes.link} to={`/messenger/admin/messages/${row.id}`}>{row.message}</Link></TableCell>
                  <TableCell className={classes.flaggedRow}>{row.flagged ? '!' : ''}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDateTime(row.createdAt)}</TableCell>
                </TableRow>;
              })
              : <div style={{ 'margin': '30px', 'text-align': 'center' }}>No messages sent/received for this user</div>}
          </TableBody>
        </Table>
      </FlexTileGrid>
      {
        messages &&
          <TablePagination component="div" count={totalCount} rowsPerPage={PAGE_ROW_LIMIT} page={page} onPageChange={(event, nextPage) => {
            setPage(nextPage);
          }} rowsPerPageOptions={[10]} />
      }

    </Root>
  );
};

export default (UserMessages);
