import { getExtensionInfo } from '../Lib/extension';
import { clearCookies } from '../Lib/util';

class Api {
  constructor () {
    this.baseURL = process.env.REACT_APP_PUBLIC_URL;
  }

  postRequest (url, data = {}) {
    return this.request(url, 'POST', data);
  }

  putRequest (url, data = {}) {
    return this.request(url, 'PUT', data);
  }

  deleteRequest (url, data = {}) {
    return this.request(url, 'DELETE', data);
  }

  getRequest (url, data = {}) {
    const params = serialiseObject(data);

    return this.request(url + (params ? '?' + params : ''), 'GET');
  }

  uploadPostRequest (url, data = {}) {
    return this.uploadRequest(url, 'POST', data);
  }

  downloadGetRequest (url, data = {}) {
    const params = serialiseObject(data);

    return this.downloadRequest(url + (params ? '?' + params : ''), 'GET');
  }

  async request (url, method, data) {
    let extensionInfo;
    try {
      extensionInfo = await getExtensionInfo();
    } catch { }
    const body = ['get', 'head'].includes(method.toLowerCase())
      ? undefined
      : JSON.stringify(data);

    const extInfo = extensionInfo
      ? { 'Nucleos-Extension': JSON.stringify(extensionInfo) }
      : null;

    const options = {
      credentials: 'include',
      mode: 'cors',
      method: method,
      body,
      headers: new Headers({
        'Content-Type': 'application/json',
        ...extInfo
      })
    };
    return fetch(this.baseURL + url, options).then(handleApiSuccess);
  }

  async downloadRequest (url, method, data) {
    let extensionInfo;
    try {
      extensionInfo = await getExtensionInfo();
    } catch { }
    const body = ['get', 'head'].includes(method.toLowerCase())
      ? undefined
      : JSON.stringify(data);

    const extInfo = extensionInfo
      ? { 'Nucleos-Extension': JSON.stringify(extensionInfo) }
      : null;

    const options = {
      credentials: 'include',
      mode: 'cors',
      method: method,
      body,
      responseType: 'arraybuffer',
      headers: new Headers({
        'Content-Type': 'blob',
        ...extInfo
      })
    };
    return fetch(this.baseURL + url, options);
  }

  async uploadRequest (url, method, data) {
    let extensionInfo;
    try {
      extensionInfo = await getExtensionInfo();
    } catch { }
    const formData = new FormData();
    for (const key in data) {
      if (key === 'deviceInfo') {
        Object.entries(data[key]).forEach(([key, value]) => {
          formData.append(key, value);
        });
      } else {
        if (Array.isArray(data[key])) {
          data[key].forEach((val) => {
            formData.append(key, val);
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    const body = ['get', 'head'].includes(method.toLowerCase())
      ? undefined
      : formData;

    const extInfo = extensionInfo
      ? { 'Nucleos-Extension': JSON.stringify(extensionInfo) }
      : null;

    const options = {
      credentials: 'include',
      mode: 'cors',
      method: method,
      body,
      headers: new Headers({
        ...extInfo
      })
    };
    return fetch(this.baseURL + url, options).then(handleApiSuccess);
  }

  async XHRUploadRequest (url, data, onProgress) {
    let extensionInfo;

    try {
      extensionInfo = await getExtensionInfo();
    } catch {}
    const formData = new FormData();
    for (const key in data) {
      if (key === 'deviceInfo') {
        Object.entries(data[key]).forEach(([key, value]) => {
          formData.append(key, value);
        });
      } else {
        if (Array.isArray(data[key])) {
          data[key].forEach((val) => {
            formData.append(key, val);
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const extInfo = extensionInfo
      ? { 'Nucleos-Extension': JSON.stringify(extensionInfo) }
      : null;

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('POST', this.baseURL + url);

      xhr.withCredentials = true;

      new Headers({
        ...extInfo
      }).forEach((val, key) => xhr.setRequestHeader(key, val));

      xhr.upload.onprogress = onProgress;

      xhr.onload = () => {
        console.log(xhr.response);

        resolve(xhr.response);
      };

      xhr.onerror = () => reject(new Error('Failed to upload.'));

      xhr.send(formData);
    });
  }
}

function handleApiSuccess (response) {
  if (response.status === 401 && window.location.pathname !== '/logout') {
    clearCookies(window.location.hostname);

    // localStorage.setItem('error', true);
    localStorage.setItem('logoutMessage', 'Session expired, Please login again.');
    if (window.location.pathname !== '/login') {
      window.location.assign('/logout');
    }
  }

  if (!response.ok) {
    return { errorMessage: response.json(), error: response };
  }

  if (localStorage.getItem('error')) {
    localStorage.setItem('error', false);
  }

  return response.json();
}

function serialiseObject (obj) {
  const pairs = [];
  for (const prop in obj) {
    if (!Object.hasOwn(obj, prop)) {
      continue;
    }
    // if the object is an array we will duplicate the query param entry
    if (Array.isArray(obj[prop])) {
      obj[prop].forEach((duplicatedProp) => {
        pairs.push(prop + '=' + duplicatedProp);
      });
    } else {
      pairs.push(prop + '=' + obj[prop]);
    }
  }
  return pairs.join('&');
}

class APIError extends Error {
  response;
  constructor (message, error) {
    super(message);
    this.response = error;
  }
}

export const GenericErrorDetectorForMutations = (res) => {
  if (res.success === true || !res.errorMessage) { return res; }

  return res.errorMessage.then((BEResponse) => {
    if (BEResponse.success === true) { return BEResponse; }
    if (typeof BEResponse.error === 'string') { throw new APIError(BEResponse.error, BEResponse); }
    if (typeof BEResponse.message === 'string') { throw new APIError(BEResponse.message, BEResponse); }

    throw new APIError('Something went wrong', BEResponse);
  });
};

export default Api;
