import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import { validateString } from '../../Components/Form';
import './LearningOverview.scss';
import DataStore from '../../Stores/DataStore';
import AuthenticationStore from '../../Stores/Authentication';
import { getHoursAndMinutes } from '../../Lib/util';
import defaultIcon from '../../Assets/course-icon-default.svg';

import { TextInput, Typography, MultiSelectDropDown, Button } from '@nucleos/core-ui';
import { Icon } from '../../Components/Shared/Icon';

const GoalForm = props => {
  const { userRole } = AuthenticationStore;
  const isLearner = userRole === 'learner';
  const { isEditing, handleFormSubmitCompleted, closeModal, goal, isReadOnly } = props;
  const { setGoal } = DataStore;
  const { hoursAdd, minutesRemainder } = getHoursAndMinutes(isEditing ? goal.minutes : undefined);
  const [minutes, setMinutes] = useState(minutesRemainder);
  const [hours, setHours] = useState(hoursAdd);
  const [searchAppString, setSearchAppString] = useState('');
  const initialValues = {
    name: isEditing ? goal && goal.name : '',
    description: isEditing ? goal && goal.description : '',
    timeType: isEditing ? goal && goal.isWeekly ? 'week' : 'total' : 'total',
    application: isEditing ? goal && goal.ApplicationUid : '',
    minutes: minutesRemainder,
    hours: hoursAdd
  };

  const handleMinutes = e => {
    const value = parseInt(e.target.value || 0);
    const { hoursAdd, minutesRemainder } = getHoursAndMinutes(value);
    setMinutes(minutesRemainder === 0 ? undefined : minutesRemainder);
    const hoursToSet = (Number(hours) || 0) + hoursAdd;
    setHours(hoursToSet === 0 ? undefined : hoursToSet);
    setFieldValue('minutes', minutesRemainder === 0 ? undefined : minutesRemainder);
    setFieldValue('hours', hoursToSet === 0 ? undefined : hoursToSet);
  };

  const handleHours = e => {
    const value = Number(e.target.value) | 0;
    setHours(value === 0 ? undefined : value);
    setFieldValue('hours', value === 0 ? undefined : value);
  };

  function validate (values) {
    const { name, description, application, minutes, hours } = values;
    const nameError = validateString(name, true, 2, 256);
    const descriptionError = validateString(description, false, 0, 255);
    const errors = {};
    if (nameError) { errors.name = nameError; }
    if (descriptionError) { errors.description = descriptionError; }
    if (!(application && application.length)) { errors.application = 'Required'; }
    if (!(minutes || hours)) { errors.minutes = 'Required'; }
    if (!(hours || minutes)) { errors.hours = 'Required'; }
    return errors;
  }

  function onSubmit (values, { setSubmitting }) {
    const { name, description, timeType, application } = values;
    setGoal(description, name, application, minutes, hours, timeType === 'week', !isEditing ? undefined : goal.id, isLearner);
    setSubmitting(false);
    closeModal();
  }

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit
  });

  function handleSnackBarClose (action, status) {
    const {
      setSetGoalSuccess,
      setSetGoalError,
      setDeleteGoalSuccess,
      setDeleteGoalError
    } = DataStore;
    const updateStore =
      action === 'set'
        ? status === 'success' ? setSetGoalSuccess : setSetGoalError
        : status === 'success' ? setDeleteGoalSuccess : setDeleteGoalError;
    updateStore();
  }

  const {
    setGoalSuccess,
    applications,
    getApplications
  } = DataStore;

  useEffect(() => {
    if (setGoalSuccess) {
      handleFormSubmitCompleted(isEditing);
    }
  }, [setGoalSuccess]);

  useEffect(() => {
    getApplications();
  }, []);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    resetForm
  } = formik;

  const closeModalHandler = () => {
    closeModal();
    resetForm();
  };

  let appOptions = applications;
  if (searchAppString && searchAppString.length && applications && applications.length) {
    appOptions = applications.filter(app => app.name.toLowerCase().includes(searchAppString.toLowerCase()));
  }

  const applicationOptions = appOptions && appOptions.length
    ? appOptions.map(app => {
      return {
        title: (<> <img className='h4 w-4' src={app.logo || app.icon || defaultIcon} alt='app-logo' /> <span className='ml-2'> {app.name} </span>  </>),
        value: app.uid
      };
    })
    : [];

  const timeTypeOptions = [
    {
      value: 'total', title: 'Total hours'
    },
    {
      value: 'week', title: 'Hours by week'
    }
  ];

  return (
    <React.Fragment>
      <Grid style={{ width: window.screen.width * 0.4 }}>
        <div className='nucleos-core' >
          <div className='p-5 rounded-lg' style={{ background: '#FFFFFF' }}>
            <div className='flex justify-between items-center pb-3' style={{ borderBottom: '1px solid rgba(51, 51, 51, 0.2)' }} >
              <div className='text-2xl font-medium'>
                { isReadOnly ? 'View Goal' : isEditing ? 'Edit Goal' : 'Add Goal'}
              </div>
              <div className='flex cursor-pointer' onClick={closeModalHandler}>
                <Icon icon="Close" />
                <span>Close</span>
              </div>
            </div>
            <div className='mt-4 overflow-scroll' style={{ maxHeight: window.screen.height * 0.6 }}>
              <form method='post' onSubmit={handleSubmit} >
                <div >
                  <div >

                    <div>
                      <Typography className='mb-1.5' > Goal Name <span className='text-xs' style={{ color: '#d32f2f' }}>*</span> </Typography>
                      <TextInput
                        fullWidth
                        name='name'
                        placeholder='Name Your Goal'
                        // onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('name', e);
                        }
                        }
                        disabled={isReadOnly}
                        value={values.name}
                        // touched={touched.name}
                        error={ touched.name && errors.name}
                        helperText={touched.name && errors.name && <span className='text-xs' style={{ color: '#d32f2f' }} >{errors.name}</span>}
                      />

                    </div>
                    <div>
                      <Typography className='mb-1.5'> Description </Typography>
                      <TextInput
                        name='description'
                        multiline
                        placeholder='Describe Your Goal'
                        onChange={(e) =>
                          setFieldValue('description', e)

                        }
                        value={values.description}
                        disabled={isReadOnly}

                        error={touched.description && errors.description}
                        helperText={touched.description && errors.description && <span className='text-xs' style={{ color: '#d32f2f' }} >{errors.description}</span>}
                      />
                    </div>
                    <div>
                      <Typography className='mb-1.5'>Application <span className='text-xs' style={{ color: '#d32f2f' }}>*</span> </Typography>
                      <MultiSelectDropDown
                        dropdownId="goals-application-select"
                        className='border-error'
                        style={{ border: '1px solid #d32f2f' } }
                        disableMultiSelect
                        label="Select Application"
                        fullWidth
                        searchable
                        onSearchChange={(app) => {
                          setSearchAppString(app);
                        }}
                        disabled={isReadOnly}

                        name='application'
                        onChange={(e, ev) => setFieldValue('application', e[0].value)}
                        options={applicationOptions}
                        value={applicationOptions.filter(op => op.value === values.application)}
                        error={ touched.application && errors.application}
                        helperText={touched.application && errors.application && <span className='text-xs' style={{ color: '#d32f2f' }} >{errors.application}</span>}
                      />
                    </div>
                  </div>

                </div>

                <div >
                  <Typography className='mb-1.5'>Completion Time (HH:MM) <span className='text-xs' style={{ color: '#d32f2f' }}>*</span> </Typography>

                  <div className='flex h-9'>

                    <div className='h-9'>
                      <input
                        className='w-20 h-9 pl-3 rounded text-sm'
                        style={ touched.hours && errors && errors.hours ? { border: '1px solid #d32f2f', color: '#525252' } : { border: '1px solid #D3D3D3', color: '#525252' }}
                        name={'hours'}
                        value={((hours || '') + '').replace(/\D/g, '')}
                        // onBlur={handleBlur}
                        onChange={handleHours}
                        type={'number'}
                        placeholder={'HH'}
                        maxLength={2}
                        step={1}
                        min={'0'}
                        max={'999999999999999999'}
                        disabled={isReadOnly}

                      />
                    </div>
                    <div className='mx-2 h-9 mt-1.5'> : </div>
                    <div className='h-9'>
                      <input
                        className='w-20 h-9 pl-3 rounded text-sm'
                        style={ touched.minutes && errors && errors.minutes ? { border: '1px solid #d32f2f', color: '#525252' } : { border: '1px solid #D3D3D3', color: '#525252' }}
                        step='5'
                        type='number'
                        name='minutes'
                        placeholder='MM'
                        // onBlur={handleBlur}
                        onChange={handleMinutes}
                        value={minutes || ''}
                        min={'0'}
                        max={'999999999999999999'}
                        disabled={isReadOnly}

                      />

                    </div>
                    <div className='ml-2'>
                      <MultiSelectDropDown
                        dropdownId="goals-completion-time-type-select"
                        disableMultiSelect
                        name={'timeType'}
                        fullWidth
                        options={timeTypeOptions}
                        onChange={(e) => setFieldValue('timeType', e[0].value)}
                        value={timeTypeOptions.filter(op => op.value === values.timeType)}
                        disabled={isReadOnly}
                      />

                    </div>

                  </div>
                  {((touched.minutes && errors && errors.minutes) || (touched.hours && errors && errors.hours)) && <div className='m-1 text-left  text-background-contrastText' > <span className='text-xs' style={{ color: '#d32f2f' }}> {errors && errors.minutes} </span></div>}

                </div>

                {!isReadOnly && <div className='flex justify-between mt-4'>
                  <Button onClick={closeModalHandler}>
                    Cancel
                  </Button>
                  <Button
                    color='primary'
                    variant='contained'
                    type='submit'
                    onClick={() => {
                      handleSnackBarClose('set', 'success');
                    }}
                  >
                    {isEditing ? 'Save Changes' : 'Add Goal'}
                  </Button>

                </div>}
              </form>
            </div>
          </div>
        </div>

      </Grid>
    </React.Fragment>
  );
};

export default observer(GoalForm);
