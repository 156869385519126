import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Paper } from '@mui/material';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import UpdateSelfForm from './UpdateSelfForm';
import './AccountSettings.scss';
import DataStore from '../../Stores/DataStore';
import Card from '../../Components/Shared/Card';
import NotificationsSettings from './NotificationsSettings';
import HeaderSubPanel from '../../Components/HeaderSubPanel';

const paperBaseStyle = {
  padding: 30,
  borderRadius: '12px',
  boxShadow: '0 10px 40px 0 rgba(64, 72, 82, 0.07)',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
};

const AccountSettings = () => {
  const { getSelf, getSelfLoading, self } = DataStore;

  useEffect(() => {
    getSelf();
  }, [getSelf]);

  if (getSelfLoading) {
    return <LoadingComponent />;
  } else if (!self.uid) {
    return (
      <h2>
        There was an issue loading your account page. Contact your system
        administrator if this continues.
      </h2>
    );
  } else {
    return (
      <Box>
        <HeaderSubPanel sx={{ mb: 3 }} title="Account Settings" />
        <React.Fragment>
          <div className="user-groups">
            <section>
              <Card
                head={
                  <h2
                    style={{ textDecoration: 'underline', paddingBottom: '10px' }}
                  >
                  Profile
                  </h2>
                }
                body={
                  <div>
                    <Paper style={{ ...paperBaseStyle, maxWidth: '100%' }}>
                      <UpdateSelfForm />
                    </Paper>
                  </div>
                }
              />
            </section>

            <NotificationsSettings />

            <div className="serial-number" id="serialNumber">
              {window && window.nucleosDeviceInfo
                ? window.nucleosDeviceInfo.serialNumber
                : ''}
            </div>
          </div>
        </React.Fragment>
      </Box>
    );
  }
};

export default observer(AccountSettings);
