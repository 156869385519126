import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import liveActivityIcon from '../../Assets/live-activity-icon.svg';
import liveActivityLookupIcon from '../../Assets/live-activity-lookup-icon.svg';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';
import { Button, Dialog } from '@nucleos/core-ui';
import LearnersFilter from '../../Components/Shared/LearnersFilter';
import { stringToBase64Url } from '../../Lib/util';
import { Box, Switch, Typography } from '@mui/material';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { INVESTIGATIVE_PATH, LIVE_ACTIVITY_MODAL_PATH } from '../../constants/paths';

const baseRoute = '/investigative-tools';

const adminFeatures = [
  {
    name: 'Activity Lookup',
    icon: liveActivityLookupIcon,
    link: `${baseRoute}/activity-lookup`
  }
];

export const InvestigativeTools = () => {
  const [selectedFaclities, setSelectedFaclities] = useState([]);
  const [selectedPODs, setSelectedPODs] = useState([]);
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showOnlyLiveLearners, setShowOnlyLiveLearners] = useState(true);
  const [showAllLearners, setShowAllLearners] = useState(true);
  const [learnersCount, setLearnersCount] = useState(0);
  const showLiveActivityModal = location.pathname.endsWith('show-live-activity-modal');
  const history = useHistory();

  const playLiveActivity = () => {
    const filters = {
      learners: selectedLearners.map((l) => l.value),
      pods: selectedPODs.map((pod) => pod.value),
      facilities: selectedFaclities.map((f) => f.value),
      liveLearners: showOnlyLiveLearners
    };

    window.open(
      `${window.location.origin
      }${baseRoute}/live-activity/?filters=${stringToBase64Url(
        JSON.stringify(filters)
      )}`
    );
  };

  const features = adminFeatures.map(({ name, icon, link }, i) => (
    <Link to={link} key={i}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: '',
          justifyContent: 'space-around'
        }}
      >
        <p style={{ color: '#3899FF', display: 'inline' }}>
          <img src={icon} alt="" />
        </p>
        <h2 style={{ paddingLeft: '25px', fontSize: '18px' }}> {name} </h2>
      </div>
    </Link>
  ));

  const getLearnersCount = () => {
    if (!showAllLearners &&
      !selectedFaclities.length &&
      !selectedPODs.length &&
      !selectedLearners.length) {
      return 0;
    }
    return learnersCount;
  };

  useEffect(() => {
    if (!showLiveActivityModal) {
      setSelectedFaclities([]);
      setSelectedPODs([]);
      setSelectedLearners([]);
      setShowOnlyLiveLearners(true);
      setShowAllLearners(true);
    }
  }, [showLiveActivityModal]);

  features.push(
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: '',
        justifyContent: 'space-around',
        cursor: 'pointer'
      }}
      onClick={() => {
        history.replace(LIVE_ACTIVITY_MODAL_PATH);
      }}
    >
      <p style={{ color: '#3899FF', display: 'inline' }}>
        <img src={liveActivityIcon} alt="" />
      </p>
      <h2 style={{ paddingLeft: '25px', fontSize: '18px' }}>Live Activity</h2>
    </div>
  );

  const onModalClose = () => {
    history.replace(INVESTIGATIVE_PATH);
  };

  return (
    <Box>
      <HeaderSubPanel title="Investigative Toolset" sx={{ mb: 3 }} />
      <div>
        <FlexTileGrid tileStyle={{ padding: '25px' }}>{features}</FlexTileGrid>

        <Dialog
          isOpen={showLiveActivityModal}
          onClose={onModalClose}
          size="medium"
          title="Live Activity"
        >
          <div className={'nucleos-core'}>
            <div>
              <div className="flex items-center mb-4 mt-4">
                <Typography>Select All Learners</Typography>
                <div>
                  <Switch
                    checked={showAllLearners}
                    onChange={(event) => setShowAllLearners(event.target.checked)}
                    label="Select All Learners"
                    testId="all-learners"
                  />
                </div>
              </div>
              <LearnersFilter
                isFormDisabled={showAllLearners}
                selectedFaclities={selectedFaclities}
                selectedLearners={selectedLearners}
                selectedPODs={selectedPODs}
                learnersCount={learnersCount}
                onLoadingChange={setLoading}
                onChange={({
                  selectedFaclities,
                  selectedLearners,
                  selectedPODs,
                  learnersCount
                }) => {
                  if (selectedFaclities) { setSelectedFaclities(selectedFaclities); }
                  if (selectedLearners) { setSelectedLearners(selectedLearners); }
                  if (selectedPODs) { setSelectedPODs(selectedPODs); }
                  if (learnersCount) { setLearnersCount(learnersCount); }
                }}
              />
              <div className="flex items-center mb-12 mt-4">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <span className="mr-2">Total Learners: </span>
                    <span className="mr-2" data-testId="live-activity-total-learners-count">{getLearnersCount()}</span>
                  </div>
                  <div className='flex items-center'>
                    <Typography>Show only Live Learners</Typography>
                    <Switch
                      testId="live-activity-show-only-live-switch"
                      checked={showOnlyLiveLearners}
                      onChange={(event) => setShowOnlyLiveLearners(event.target.checked)}
                      label="Show only Live Learners"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'nucleos-core'}>
              <div className={'flex justify-between'}>
                <Button
                  variant='outlined'
                  color="primary"
                  onClick={onModalClose}
                  testId="live-activity-learner-filter-close"
                >
                Cancel
                </Button>
                <Button
                  testId="live-activity-learner-filter-play"
                  onClick={playLiveActivity}
                  disabled={isLoading || getLearnersCount() == 0}
                  variant="contained"
                  color="primary"
                >
                Play
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </Box>
  );
};

export default InvestigativeTools;
