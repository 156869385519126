import React, { useState } from 'react';
import { Table } from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import Data from '../../../Middleware/Data';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { formatDateTime } from '../../../Lib/util';
import { useUserContext } from '../UserContext';
import HTMLReactParser from 'html-react-parser';

export default function AuditLogsModal () {
  const userContext = useUserContext();
  const { user } = userContext;

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const userAuditLogsQuery = useQuery(
    ['UserAuditLogs', user.uid, page, recordsPerPage],
    () => Data.getUserAuditLogs(user.uid, page, recordsPerPage),
    {
      select: (data) => {
        return {
          ...data,
          rows: (data.rows || []).map((item) => {
            return {
              event: item.description,
              createdBy: item.User ? item.User.fullname : 'System Generated',
              createdAt: item.createdAt
            };
          })
        };
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  return (
    <div>
      <div className="mt-4">
        <Table
          columns={[
            {
              title: 'Event',
              render: (data) => HTMLReactParser(data.event)
            },
            {
              title: 'By User',
              render: (data) => data.createdBy
            },
            {
              title: 'Date & Time',
              render: (data) => formatDateTime(data.createdAt)
            }
          ]}
          noDataMessage={<GenericNoDataFound />}
          loading={userAuditLogsQuery.isFetching}
          pagination
          page={page}
          onPageChange={setPage}
          recordsPerPage={recordsPerPage}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          totalRecords={(userAuditLogsQuery.data || {}).count}
          rowsData={(userAuditLogsQuery.data || {}).rows || []}
        />
      </div>
    </div>
  );
}
