import React from 'react';

import { getIcon } from '../../../Lib/iconMappings';

export const Icon = ({ icon, ...props }) => {
  const IconElement = getIcon(icon);

  return <IconElement {...props} />;
};

export default Icon;
