import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Data from '../Middleware/Data';
import { DEFAULT_APP_CONFIG, PAGE_ROW_LIMIT } from '../Lib/CONSTANTS';
import toast from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../Middleware/Api';

export function usePreferences (
  preferencesKey = '',
  {
    defaultColumnOrder,
    defaultHiddenColumns,
    defaultRecordsPerPage,
    defaultAppConfig,
    onPreferencesUpdated
  } = {},
  {
    showToastMessage = true
  } = {}
) {
  const [recordsPerPage, setRecordsPerPage] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState(defaultHiddenColumns);
  const [columnsOrder, setColumnsOrder] = useState(defaultColumnOrder);
  const [appConfig, setAppConfig] = useState(defaultAppConfig);

  const preferencesQuery = useQuery(
    ['USER', 'PREFERENCES'],
    Data.getPreferences,
    {
      select: (res) => ({
        columnsOrder: defaultColumnOrder || [],
        hiddenColumns: defaultHiddenColumns || [],
        recordsPerPage: defaultRecordsPerPage || PAGE_ROW_LIMIT,
        appConfig: (res.preferences || {}).appConfig || defaultAppConfig || DEFAULT_APP_CONFIG,
        ...((res.preferences || {})[preferencesKey] || {})
      })
    }
  );

  const updatePreferencesMutation = useMutation(
    (data) =>
      Data.savePreferences({ preferencesKey, preferences: data }).then(
        GenericErrorDetectorForMutations
      ),
    {
      onSuccess: () => {
        preferencesQuery.refetch();
        if (onPreferencesUpdated) { onPreferencesUpdated(); }

        if (showToastMessage) {
          toast.success('Your preferences updated successfully.');
        }
      },
      onError: (err) => {
        if (showToastMessage) {
          toast.error(err.message);
        }
      }
    }
  );

  useEffect(() => {
    if (preferencesQuery.status !== 'success' || preferencesQuery.isRefetching) { return; }

    if (preferencesQuery.status === 'success') {
      setToDefaultPreferences();
    }
  }, [preferencesQuery.status, preferencesQuery.isRefetching]);

  const saveCurrentPreferences = () => {
    if (preferencesKey === 'appConfig') {
      updatePreferencesMutation.mutate({
        ...appConfig
      });
    } else {
      updatePreferencesMutation.mutate({
        columnsOrder,
        recordsPerPage,
        hiddenColumns,
        appConfig
      });
    }
  };

  useEffect(() => {
    if (preferencesQuery.status !== 'success') { return; }
    if (recordsPerPage === null) { return; }
    if (recordsPerPage !== preferencesQuery.data.recordsPerPage) {
      saveCurrentPreferences();
    }

    if (JSON.stringify(appConfig) !== JSON.stringify(preferencesQuery.data.appConfig)) {
      saveCurrentPreferences();
    }
  }, [recordsPerPage, appConfig]);

  const getSortedColumns = (columns = []) => {
    const sortedColumns = preferencesQuery.data.columnsOrder.length
      ? preferencesQuery.data.columnsOrder
        .map((col) => columns.find((dCol) => dCol.title === col))
        .filter(Boolean)
      : columns;

    // Insert all the columns which are not stored under preferences. This may include newly added columns.
    if (preferencesQuery.data.columnsOrder.length) {
      columns.forEach((col, index) =>
        !preferencesQuery.data.columnsOrder.includes(col.title)
          ? sortedColumns.splice(index, 0, col)
          : null
      );
    }
    return sortedColumns;
  };

  const getFilteredColumns = (columns = []) => {
    if (!preferencesQuery.isSuccess) { return []; }
    // This is to ensure that even if the query is failed, The table should be shown willout save preferences.
    if (preferencesQuery.isError) { return columns; }

    const sortedColumns = getSortedColumns(columns);

    const filteredColumns = sortedColumns
      .filter((col) => !preferencesQuery.data.hiddenColumns.includes(col.title))
      .filter(Boolean);

    return filteredColumns;
  };

  const getUnfilteredColumns = (columns = []) => {
    if (!preferencesQuery.isSuccess) { return []; }
    // This is to ensure that even if the query is failed, The table should be shown willout save preferences.
    if (preferencesQuery.isError) { return columns; }
    const sortedColumns = getSortedColumns(columns);
    return sortedColumns;
  };

  const setToDefaultPreferences = () => {
    setHiddenColumns(preferencesQuery.data.hiddenColumns);
    setRecordsPerPage(preferencesQuery.data.recordsPerPage);
    setColumnsOrder(preferencesQuery.data.columnsOrder);
    setAppConfig(preferencesQuery.data.appConfig);
  };

  return {
    status: preferencesQuery.status,
    hiddenColumns,
    recordsPerPage: recordsPerPage || defaultRecordsPerPage,
    columnsOrder,
    appConfig,
    setRecordsPerPage,
    setHiddenColumns,
    setColumnsOrder,
    setAppConfig,
    setToDefaultPreferences,
    saveCurrentPreferences,
    updatePreferencesStatus: updatePreferencesMutation.status,
    getFilteredColumns,
    getUnfilteredColumns
  };
}
