import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Data from '../../../Middleware/Data';

export default function AddPodDropdown ({ addedPodIdsList, onPodAdd }) {
  const podsQuery = useQuery(
    ['ApplicationPods'],
    () => Data.getApplicationPods(),
    {
      placeholderData: []
    }
  );

  const podOptions = useMemo(() => {
    return podsQuery.data.filter((pod) => !addedPodIdsList.find((addedPodId) => addedPodId === pod.uid));
  }, [podsQuery.data, addedPodIdsList]);

  const [addPodMenuAnchorEl, setAddPodMenuAnchorEl] = useState(null);

  const handleAddPodMenuOpen = useCallback((event) => {
    setAddPodMenuAnchorEl(event.currentTarget);
  }, []);

  const handleAddPodMenuClose = useCallback(() => {
    setAddPodMenuAnchorEl(null);
  }, []);

  const handleAddPodMenuOptionClick = useCallback((pod) => {
    onPodAdd(pod);

    handleAddPodMenuClose();
  }, [onPodAdd, handleAddPodMenuClose]);

  const addPodMenuOpen = Boolean(addPodMenuAnchorEl);

  return (
    <Box>
      <Button
        id='add-pod-button'
        aria-haspopup="true"
        aria-controls={addPodMenuOpen ? 'add-pod-menu' : undefined}
        aria-expanded={addPodMenuOpen ? 'true' : undefined}
        color='secondary'
        sx={{ fontWeight: 700 }}
        onClick={handleAddPodMenuOpen}
      >
        Add POD
      </Button>

      <Menu
        id='add-pod-menu'
        aria-labelledby='add-pod-button'
        open={addPodMenuOpen}
        anchorEl={addPodMenuAnchorEl}
        elevation={2}
        variant='menu'
        onClose={handleAddPodMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: '40vh'
            }
          }
        }}
      >
        {
          podOptions.length > 0
            ? podOptions.map((pod) => (
              <MenuItem key={pod.uid} onClick={() => handleAddPodMenuOptionClick(pod)}>
                {
                  pod.name
                }
              </MenuItem>
            ))
            : (
              <Box sx={{ px: 2, py: 1 }}>
                <Typography>No PODs available to add.</Typography>
              </Box>
            )
        }
      </Menu>
    </Box>
  );
}
