import React from 'react';
import PropTypes from 'prop-types';
import { useObserver } from 'mobx-react';
import AutoComplete from './AutoComplete';
import DataStore from '../../../Stores/DataStore';
import './UserSearch.scss';
import { components } from 'react-select';

const debounced = function (fn, delay) {
  let timer = null;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
};

const transformDataForReactSelect = function (data) {
  return data.map((d) => ({
    firstName: d.firstName,
    lastName: d.lastName,
    username: d.username,
    uid: d.uid
  }));
};

function Option (props) {
  const {
    data: { firstName, lastName, username }
  } = props;
  return (
    <components.Option {...props} >
      <div data-test-id="user-search-options">
        <div>{`${firstName} ${lastName}`}</div>
        <div>{username}</div>
      </div>
    </components.Option>
  );
}

function SingleValue (props) {
  const {
    data: { firstName, lastName }
  } = props;
  return (
    <components.SingleValue {...props} >
      <div data-test-id="user-selected-option-value" >{`${firstName} ${lastName}`}</div>
    </components.SingleValue>
  );
}

const UserSearch = (props) => {
  const { onSelectUser } = props;

  const selectUserHandler = (e) => {
    onSelectUser(e);
  };

  const fetchUser = async (inputValue, callback) => {
    const { users } = await DataStore.getUsers(inputValue, undefined, undefined, undefined, undefined, 20);

    const transformedUser = transformDataForReactSelect(users);
    callback(transformedUser);
  };

  const debouncedSearch = debounced(fetchUser, 400);

  return useObserver(() => {
    const { label, className, classNamePrefix, isClearable, clearSelectedValue } = props;
    return (
      <div className="base-user-search-container">
        <AutoComplete
          placeholder = "Search"
          onSelect={selectUserHandler}
          className={className}
          classNamePrefix={classNamePrefix}
          isClearable={isClearable}
          loadOptions={debouncedSearch}
          components={{ Option, SingleValue }}
          label={label}
          clearSelectedValue={clearSelectedValue}
        />
      </div>
    );
  });
};

UserSearch.propTypes = {
  label: PropTypes.string,
  onSelectUser: PropTypes.func.isRequired,
  inactive: PropTypes.bool
};

export default UserSearch;
