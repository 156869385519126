import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { MultiSelectDropDown, Typography } from '@nucleos/core-ui';
import Data from '../../Middleware/Data';
import { useFacilitiesQuery } from '../../hooks/useFacilities';
import { Icon } from '../../Components/Shared/Icon';

export default function LearnersFilter ({
  onChange = () => null,
  onLoadingChange = () => null,
  isFormDisabled,
  selectedPODs: _selectedPODs,
  selectedLearners: _selectedLearners,
  selectedFaclities: _selectedFaclities,
  learnersCount: _learnersCount
}) {
  const facilitiesState = useState([]);
  const PODsState = useState([]);
  const learnersState = useState([]);
  const propsState = {
    facilities: _selectedFaclities,
    learners: _selectedLearners,
    pods: _selectedPODs
  };

  // The below 3 states are using the state value if being provided sent through props or using local state value if not sent by props.
  const [selectedFaclities, setSelectedFaclities] = _selectedFaclities
    ? [
      _selectedFaclities,
      (selectedFaclities) => onChange({ ...propsState, selectedFaclities })
    ]
    : facilitiesState;
  const [selectedPODs, setSelectedPODs] = _selectedPODs
    ? [
      _selectedPODs,
      (selectedPODs) => onChange({ ...propsState, selectedPODs })
    ]
    : PODsState;
  const [selectedLearners, setSelectedLearners] = _selectedLearners
    ? [
      _selectedLearners,
      (selectedLearners) => onChange({ ...propsState, selectedLearners })
    ]
    : learnersState;

  // eslint-disable-next-line no-unused-vars
  const [learnersCount, setLearnersCount] = _learnersCount !== null
    ? [
      _learnersCount,
      (learnersCount) => onChange({ ...propsState, learnersCount })
    ]
    : learnersState;

  const [learnerSearch, setLearnerSearch] = useState('');
  const facilitiesQuery = useFacilitiesQuery(true);

  useEffect(() => {
    if (isFormDisabled) {
      setSelectedFaclities([]);
      setSelectedPODs([]);
      setSelectedLearners([]);
    }
  }, [isFormDisabled]);

  const podsQuery = useQuery(
    ['PODS'],
    () => Data.getPods(),
    {
      refetchOnMount: true,
      placeholderData: [],
      select: (data) =>
        data.map((pod) => ({ ...pod, title: pod.pod, value: pod.pod }))
    }
  );

  const learnersQuery = useQuery(
    ['LEARNER', selectedFaclities, selectedPODs, selectedLearners],
    () =>
      Data.getLearnerUsers(
        null,
        selectedFaclities.map((f) => f.value),
        selectedPODs.map((p) => p.value),
        selectedLearnersIds
      ),
    { placeholderData: { rows: [] }, refetchOnMount: true }
  );
  const allLearners = useQuery(
    ['LEARNER'],
    () =>
      Data.getLearnerUsers(),
    { placeholderData: { rows: [] }, refetchOnMount: true }
  );

  const learnersSearchQuery = useQuery(
    ['LEARNER', learnerSearch],
    () =>
      Data.getLearnerUsers(learnerSearch),
    { placeholderData: { rows: [] } }
  );

  useEffect(() => {
    onLoadingChange(learnersQuery.isFetching || podsQuery.isFetching);
  }, [learnersQuery.isFetching || podsQuery.isFetching]);

  useEffect(() => {
    if (learnersQuery.isSuccess) {
      setLearnersCount(learnersQuery.data.rows.length);
    }
  }, [learnersQuery]);

  const facilities = facilitiesQuery.data || [];

  const learners = allLearners.data.rows.map((learner) => ({
    title: `${learner.firstName} ${learner.lastName}`,
    value: learner.uid
  }));
  const searchedLearners = learnersSearchQuery.data.rows.map((learner) => ({
    title: `${learner.firstName} ${learner.lastName}`,
    value: learner.uid
  }));

  /**
   * Below use effect is required to remove the selected pods from the state if they are not part of the pods options in the dropdown.
   * If these are not removed then there will be pods (selected pods basically) which does not belong to the selected facilities
   * because when selected facilities are changes the pods options are also changed based on the selected facilities.
   */
  useEffect(() => {
    if (podsQuery.isPlaceholderData) { return; }
    const updatedPODs = selectedPODs.filter((sPod) =>
      podsQuery.data.some((pod) => pod.value === sPod.value)
    );
    if (updatedPODs.length !== selectedPODs.length) {
      setSelectedPODs(updatedPODs);
    }
  }, [
    podsQuery.isPlaceholderData,
    podsQuery.data
      .map((l) => l.value)
      .sort()
      .join(' ')
  ]);

  /**
   * Below use effect is required to remove the selected learners from the state if they are not part of the learner options in the dropdown.
   * If these are not removed then there will be learners (selected learners basically) which does not belong to the selected facilities and pods
   * because when selected facilities or pods are changes the learners options are also changed based on the selected pods and facilities.
   */
  useEffect(() => {
    if (allLearners.isPlaceholderData) { return; }
    const updatedLearners = selectedLearners.filter((sl) =>
      learners.some((learner) => learner.value === sl.value)
    );
    if (updatedLearners.length !== selectedLearners.length) {
      setLearnerSearch('');
      setSelectedLearners(updatedLearners);
    }
  }, [
    allLearners.isPlaceholderData,
    learners
      .map((l) => l.value)
      .sort()
      .join(' ')
  ]);

  const selectedLearnersIds = selectedLearners.map((v) => v.value);
  const learnerOptions = learnerSearch
    ? [
      ...selectedLearners.map((l) => ({ ...l, hidden: true })),
      ...searchedLearners
    ]
    : learners;

  return (
    <div className="nucleos-core border border-gray-300 p-4 rounded-md">
      <Typography
        variant="body2"
        className="mt-2"
        style={{ color: isFormDisabled ? '#9ca3af' : '#333333E5' }}
      >
        Filter by Facility
      </Typography>
      <MultiSelectDropDown
        dropdownId="learner-filters-facilities-select"
        testId="learner-filter-facility"
        label={
          <Typography
            data-testid="learners-filter-all-facilities"
            data-all-facility-count={facilities.length}
            variant="body2"
            style={{ color: isFormDisabled ? '#9ca3af' : '#333333E5' }}
          >Choose Facility</Typography>
        }
        options={facilities}
        fullWidth
        disableHelperText
        className="mb-3"
        enableAllSelect
        allSelectLabel='All Facilities'
        disabled={isFormDisabled}
        onChange={setSelectedFaclities}
        value={selectedFaclities}
        getLabel={(selectedValues) => (
          <div
            className="flex flex-wrap gap-1"
            data-testid="learners-filter-selected-facilities-list"
          >
            {selectedValues.map((facility) => {
              const selectedFacility = facilities.find((f) => f.value === facility);
              const facilityTitle = (selectedFacility || {}).title;
              const facilityCount = (selectedFacility || {}).userCount;

              return (
                <div
                  className="px-2 py-1 mr-2 flex items-center rounded-full bg-lightblue-light"
                  data-testid="learners-filter-selected-facility"
                  data-facilityid={facility}
                >
                  {facilityTitle} ({facilityCount})
                  <Icon
                    icon="Close"
                    data-testid="learners-filter-selected-facility-remove-btn"
                    data-facilityid={facility}
                    style={{ fontSize: 16 }}
                    className="ml-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFaclities(
                        selectedFaclities.filter((f) => f.value !== facility)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      />
      <Typography
        variant="body2"
        className="mt-2"
        style={{ color: isFormDisabled ? '#9ca3af' : '#333333E5' }}
      >
        Filter by POD
      </Typography>
      <MultiSelectDropDown
        dropdownId="learner-filters-pods-select"
        testId="learner-filter-pods"
        label={
          <Typography
            data-testid="learners-filter-all-pods"
            data-all-pods-count={
              podsQuery.isSuccess ? podsQuery.data.length : ''
            }
            data-all-pods-loading={podsQuery.isRefetching}
            variant="body2"
            style={{ color: isFormDisabled ? '#9ca3af' : '#333333E5' }}
          >
            {podsQuery.isFetching
              ? 'Loading...'
              : 'Choose POD'}
          </Typography>
        }
        options={podsQuery.data}
        fullWidth
        enableAllSelect
        allSelectLabel='All PODs'
        disabled={isFormDisabled}
        disableHelperText
        className="mb-3"
        onChange={setSelectedPODs}
        value={selectedPODs}
        getLabel={(selectedValues) =>
          podsQuery.isFetching
            ? (
              'Loading...'
            )
            : (
              <div
                className="flex flex-wrap gap-1"
                data-testid="learners-filter-selected-pods-list"
              >
                {selectedValues.map((pod) => {
                  const selectedPod = podsQuery.data.find((f) => f.value === pod);
                  const podTitle = (selectedPod || {}).title;
                  const podCount = (selectedPod || {}).userCount;

                  return (
                    <div
                      className="px-2 py-1 mr-2 flex items-center rounded-full bg-lightblue-light"
                      data-testid="learners-filter-selected-pod"
                      data-podid={pod}
                      key={pod}
                    >
                      {podTitle} ({podCount})
                      <Icon
                        icon="Close"
                        data-testid="learners-filter-selected-pod-remove-btn"
                        data-podid={pod}
                        style={{ fontSize: 16 }}
                        className="ml-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPODs(
                            selectedPODs.filter((f) => f.value !== pod)
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )
        }
      />
      <Typography
        variant="body2"
        className="mt-2"
        style={{ color: isFormDisabled ? '#9ca3af' : '#333333E5' }}
      >
        Choose learners
      </Typography>
      <MultiSelectDropDown
        dropdownId="learner-filters-learners-select"
        onDropdownClose={() => setLearnerSearch('')}
        testId="learner-filter-learner"
        label={
          <Typography
            data-testid="learners-filter-all-learners"
            data-all-learners-count={learnersQuery.isSuccess ? learners.length : ''}
            data-all-learners-loading={learnersQuery.isRefetching}
            variant="body2"
            style={{ color: isFormDisabled ? '#9ca3af' : '#333333E5' }}
          >
            {allLearners.isRefetching
              ? 'Loading...'
              : 'Choose Learner'}
          </Typography>
        }
        fullWidth
        enableAllSelect
        allSelectLabel='All Learners'
        disabled={isFormDisabled}
        disableHelperText
        className="mb-3"
        options={learnerOptions}
        onChange={(selectedItems) => {
          // make sure to only select unique values
          setSelectedLearners([...new Map(selectedItems.map(item =>
            [item.value, item])).values()]);
        }}
        value={selectedLearners}
        searchValue={learnerSearch}
        searchable
        onSearchChange={setLearnerSearch}
        isSearchLoading={learnersSearchQuery.isRefetching}
        getLabel={(learner) =>
          learnersQuery.isFetching
            ? (
              'Loading...'
            )
            : (
              <div
                className="flex flex-wrap gap-1"
                data-testid="learners-filter-selected-learners-list"
              >
                {learner.map((learner) => (
                  <div
                    className="px-2 py-1 flex items-center rounded-full bg-lightblue-light"
                    data-testid="learners-filter-selected-learner"
                    data-learnerid={learner}
                  >
                    {(learners.find((l) => l.value === learner) || {}).title}
                    <Icon
                      icon="Close"
                      data-testid="learners-filter-selected-learner-remove-btn"
                      data-learnerid={learner}
                      style={{ fontSize: 16 }}
                      className="ml-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedLearners(
                          selectedLearners.filter((v) => v.value !== learner)
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            )
        }
      />
    </div>
  );
}
