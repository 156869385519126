import React, { useState } from 'react';
import {
  Button,
  Card,
  MultiSelectDropDown,
  SearchDropdown,
  ToggleButton,
  Typography
} from '@nucleos/core-ui';
import { useMutation, useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { toast } from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { useHistory } from 'react-router-dom';
import './AssignGrievanceCard.scss';
import ConfirmGrievanceCategoryChangeCard from './ConfirmGrievanceCategoryChangeCard';
import { useCategoriesQuery } from '../../hooks/useCategories';

export const AssignGrievanceCard = ({
  grievance,
  duplicateAndAssign = false,
  onAssignSuccess,
  onCardClose
}) => {
  const history = useHistory();
  const grievanceCategorySearch = grievance.GrievanceCategory.name
    .toLowerCase()
    .replace(/ /g, '-');
  const grievanceFacilitySearch = grievance.Facility.shortName
    .toLowerCase()
    .replace(/ /g, '-');

  const [assigneeToType, setAssigneeToType] = useState(
    grievance.AssignedToUser ? 'individual' : 'new-role'
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    grievance.GrievanceCategoryId || ''
  );
  const [selectedRoleId, setSelectedRoleId] = useState(
    grievance.AssignedToRole || grievance.AssignedToConfigRole || ''
  );
  const [isLegacyRoleSelected, setIsLegacyRoleSelected] = useState(
    grievance.AssignedToRole
      ? true
      : grievance.AssignedToConfigRole
        ? false
        : null
  );
  const [selectedIndividualId, setSelectedIndividualId] = useState(
    grievance.AssignedToUser || null
  );
  const [formsAdminSearchText, setFormsAdminSearchText] = useState(
    grievance.AssignedToUser
      ? `${grievance.assignedToUser.firstName} ${grievance.assignedToUser.lastName}`
      : ''
  );

  const categoriesQuery = useCategoriesQuery({
    categoriesType: grievance.requestType
  });

  const assignGrievanceMutation = useMutation(
    () => {
      const mutation = duplicateAndAssign
        ? Data.duplicateAndAssignGrievance
        : Data.assignGrievance;
      const requestBody = {
        grievanceId: grievance.id,
        categoryId: selectedCategoryId
          ? parseInt(selectedCategoryId)
          : undefined,
        assigneeId:
          assigneeToType === 'new-role'
            ? selectedRoleId || undefined
            : selectedIndividualId || undefined,
        isLegacyRole: isLegacyRoleSelected
      };
      return mutation(
        requestBody.grievanceId,
        requestBody.categoryId,
        requestBody.assigneeId ? assigneeToType.replace('new-', '') : undefined,
        requestBody.assigneeId,
        requestBody.isLegacyRole
      ).then(GenericErrorDetectorForMutations);
    },
    {
      onSuccess: (res) => {
        onAssignSuccess();
        if (duplicateAndAssign) {
          toast.success((t) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toast.dismiss(t.id);
                history.replace(`?grievanceId=${res.duplicatedGrievanceId}`);
              }}
            >
              {res.msg}
            </div>
          ));
          return;
        }
        toast.success(
          duplicateAndAssign
            ? 'Grievance duplicated & reclassified successfully.'
            : 'Grievance assigned successfully.'
        );
      },
      onError: (err) => toast.error(err.message)
    }
  );

  const newRolesQuery = useQuery(
    ['NEW_ROLES', grievance.id, selectedCategoryId, duplicateAndAssign],
    () => Data.getNewFormRoles(grievance.id, selectedCategoryId, duplicateAndAssign ? 'DUPLICATE' : 'ASSIGN'),
    {
      select: (roles) =>
        roles.map((f) => ({
          title: f.label,
          value: f.id
        })),
      refetchOnMount: true
    }
  );

  const searchFormAdminQuery = useQuery(
    [
      'FORMS_ADMINS',
      formsAdminSearchText,
      selectedCategoryId,
      grievanceFacilitySearch,
      grievanceCategorySearch,
      grievance.id,
      duplicateAndAssign
    ],
    () =>
      Data.searchFormsAdminUser(
        formsAdminSearchText,
        false,
        false,
        grievanceFacilitySearch,
        selectedCategoryId
          ? categoriesQuery.data.find((cat) => cat.value === selectedCategoryId)
            .slug
          : grievanceCategorySearch,
        grievance.id,
        selectedCategoryId,
        duplicateAndAssign ? 'DUPLICATE' : 'ASSIGN'
      ),
    {
      placeholderData: [],
      refetchOnMount: true,
      enabled: !categoriesQuery.isLoading && !categoriesQuery.isPlaceholderData
    }
  );

  const isCategoriesLoding =
    categoriesQuery.isLoading || categoriesQuery.isPlaceholderData;

  const [
    categoryChangeConfirmationVisible,
    setCategoryChangeConfirmationVisible
  ] = useState(false);

  if (categoryChangeConfirmationVisible) {
    const initialCategory = categoriesQuery.data.find(
      (e) => e.value === grievance.GrievanceCategoryId
    );
    const newCategory = categoriesQuery.data.find(
      (e) => e.value === selectedCategoryId
    );

    return (
      <ConfirmGrievanceCategoryChangeCard
        onClose={onCardClose}
        onCategoryChange={assignGrievanceMutation.mutate}
        isCategoryChangeLoading={assignGrievanceMutation.isLoading}
        requestType={grievance.requestType}
        initialCategory={initialCategory.title}
        newCategory={newCategory.title}
      />
    );
  }

  return (
    <Card className="w-96 grievance-card mt-2">
      <div>
        <Card.Header
          title={
            duplicateAndAssign
              ? 'Duplicate & Reclassify Request'
              : 'Assign Request'
          }
          onClose={() => onCardClose()}
        ></Card.Header>
      </div>
      <Card.Body>
        <div className="mb-4">
          <Typography>Category</Typography>
          <MultiSelectDropDown
            dropdownId='assign-grievance-category-select'
            testId="assign-grievance-category"
            disableHelperText
            disableMultiSelect
            onChange={([category]) => {
              if (!category) { return setSelectedCategoryId(''); }
              setSelectedCategoryId(category.value);
              setSelectedRoleId('');
              setIsLegacyRoleSelected(null);
              setSelectedIndividualId('');
              setFormsAdminSearchText('');
            }}
            value={
              (categoriesQuery.data || []).filter(
                (op) => op.value === selectedCategoryId
              ) || []
            }
            label="Choose category"
            fullWidth
            options={categoriesQuery.data || []}
          />
        </div>
        <div className="mb-4">
          <Typography>Assign To</Typography>
          <ToggleButton
            options={[
              { label: 'Role', value: 'new-role' },
              { label: 'Individual', value: 'individual' }
            ]}
            defaultSelected={assigneeToType}
            onChange={(option) => setAssigneeToType(option.value)}
          />
        </div>
        <div className="mb-4">
          {assigneeToType === 'new-role'
            ? (
              <div>
                <div>
                  <Typography>Choose Role</Typography>
                  <MultiSelectDropDown
                    dropdownId='assign-grievance-role-select'
                    disableHelperText
                    disableMultiSelect
                    isSearchLoading={newRolesQuery.isLoading}
                    disabled={isCategoriesLoding}
                    onChange={([role]) => {
                      if (!role) {
                        setSelectedRoleId('');
                        setIsLegacyRoleSelected(null);
                        return;
                      }
                      setIsLegacyRoleSelected(false);
                      setSelectedRoleId(role.value);
                    }}
                    value={
                      selectedRoleId && isLegacyRoleSelected === false
                        ? (newRolesQuery.data || []).filter(
                          (op) => op.value === selectedRoleId
                        ) || []
                        : []
                    }
                    options={newRolesQuery.data || []}
                    label="Choose role"
                    fullWidth
                  />
                </div>
              </div>
            )
            : assigneeToType === 'individual'
              ? (
                <SearchDropdown
                  value="Search"
                  placeholder="Search member name"
                  fullWidth
                  onSearchChange={(search) => {
                    setFormsAdminSearchText(search);
                  }}
                  searchValue={formsAdminSearchText}
                  options={searchFormAdminQuery.data.map((item) => ({
                    value: item.uid,
                    label: `${item.firstName} ${item.lastName}`
                  }))}
                  onItemSelect={(item) =>
                    setSelectedIndividualId(item ? item.value : null)
                  }
                  selectedOption={selectedIndividualId}
                  isLoading={searchFormAdminQuery.isFetching}
                />
              )
              : null}
        </div>
      </Card.Body>
      <Card.Action>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (!selectedCategoryId) { return toast.error('Please select category.'); }
            if (!duplicateAndAssign && !selectedIndividualId && !selectedRoleId) { return toast.error('Please select a role or an individual user.'); }

            if (
              !duplicateAndAssign &&
              selectedCategoryId !== grievance.GrievanceCategoryId
            ) {
              setCategoryChangeConfirmationVisible(true);
            } else {
              assignGrievanceMutation.mutate();
            }
          }}
          loading={assignGrievanceMutation.isLoading}
        >
          {duplicateAndAssign ? 'Duplicate' : 'Assign Request'}
        </Button>
      </Card.Action>
    </Card>
  );
};

export default AssignGrievanceCard;
