import moment from 'moment';
import React from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Legend
} from 'recharts';
import { getRandomColor, colors, formatNumber } from '../helpers';

const ResponsiveBarChart = ({
  data,
  applications,
  yLabel,
  colorMapped,
  xDataKey,
  printMode,
  gridColor,
  xAxisLineColor,
  yAxisLineColor,
  xAxisTickColor,
  yAxisTickColor,
  yAxisLineMargin
}) => {
  const yAxisLabel = yLabel
    ? { value: yLabel, angle: -90, position: 'insideLeft', fill: '#737373' }
    : null;

  const xAxisLabel = data.sort((a, b) => {
    const sortDatesOldToNew = moment(a.day).diff(b.day);

    return sortDatesOldToNew;
  });

  const gridStyle = gridColor ? { stroke: gridColor } : null;

  return (
    <ResponsiveContainer
      className={'nucleos-core'}
      width="100%"
      aspect={printMode ? 16.0 / 9.0 : 16.0 / 5.0}
    >
      <BarChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid
          horizontal={true}
          vertical={false}
          stroke={gridStyle && gridStyle.stroke}
        />
        <XAxis
          dataKey={xDataKey || 'day'}
          label={xAxisLabel}
          axisLine={xAxisLineColor ? { stroke: xAxisLineColor } : undefined}
          tickLine={xAxisTickColor ? { stroke: xAxisTickColor } : undefined}
        />
        <YAxis
          label={yAxisLabel}
          axisLine={yAxisLineColor ? { stroke: yAxisLineColor } : undefined}
          tickLine={yAxisTickColor ? { stroke: yAxisTickColor } : undefined}
          tickFormatter={(value) => formatNumber(value)}
          width={80}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <div className="bg-white shadow-md p-4 rounded h-96 flex flex-wrap flex-col">
                  <h6 className="mb-2">
                    {label} ({yLabel})
                  </h6>
                  {payload.map((entry, index) => (
                    <div
                      key={`tooltip-${index}`}
                      style={{ color: entry.color }}
                      className="w-52 mb-2"
                    >
                      {`${entry.name}: ${entry.value}`}
                    </div>
                  ))}
                </div>
              );
            }
            return null;
          }}
        />
        <Legend
          layout="horizontal"
          verticalAlign="bottom"
          align="center"
          iconSize={12}
          iconType="square"
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px'
          }}
        />
        {applications.map((name, i) => {
          const color =
            colorMapped && colorMapped[name]
              ? colorMapped[name]
              : colors[i] || getRandomColor();
          return (
            <Bar dataKey={name} key={i} stackId="a" fill={color} barSize={28} />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ResponsiveBarChart;
