import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { QueryKeys } from '../../Lib/query-keys';

const initialValue = {
  userQuery: null,
  user: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
  refetchUser: async () => {}
};

export const UserContext = React.createContext(initialValue);

export const useUserContext = () => React.useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const { uid: userId } = useParams();

  const userQuery = useQuery(
    QueryKeys.Users.item(userId),
    () => Data.getUser(userId),
    {
      enabled: !!userId,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  return (
    <UserContext.Provider
      value={{
        userQuery: userQuery,
        user: userQuery.data,
        isLoading: userQuery.isLoading,
        isError: userQuery.isError,
        isSuccess: userQuery.isSuccess,
        refetchUser: userQuery.refetch
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
