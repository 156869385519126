import React from 'react';
import NotAllowed from '../../../Assets/no-access-100.svg';
import { Typography } from '@nucleos/core-ui';

export const Screen = ({ src, isRestrictedImage, time }) => {
  return (
    <div className="screen">
      {isRestrictedImage
        ? (
          <div className="player-content-center nucleos-core">
            <div className="flex flex-col items-center justify-center">
              <img
                src={NotAllowed}
                alt="paused-recording"
                style={{ height: 82 }}
              />
              <Typography
                variant="h3"
                className="text-center"
                data-testid="lookup-activity-learner-restricted"
              >
              The screen recording has been paused <br /> because of compliance issues.
              </Typography>
              <Typography className="text-center">
              As soon as the recording becomes available, it will automatically
              resume.
              </Typography>
            </div>
          </div>
        )
        : (
          <img src={src} />
        )}
    </div>
  );
};
