import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemAvatar,
  Button,
  Box
} from '@mui/material';
import debounce from 'lodash.debounce';
import Messages from './Messages';
import './Messenger.css';
import DataStore from '../../Stores/DataStore';
import Skeleton from '../../Components/Widgets/Skeleton';
import Icon from './Icon';
import socket from '../../Lib/socket';
import Authentication from '../../Stores/Authentication';
import AddContact from './AddContact';
import { APPROVAL_STATUS } from '../../Lib/CONSTANTS';
import { formatDateTime } from '../../Lib/util';
import { useHistory, useParams } from 'react-router-dom';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import moment from 'moment';
import { Icon as NucleosIcon } from '../../Components/Shared/Icon';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { MESSAGE_USER_PATH, MESSENGER, MESSENGER_ADMIN_PATH } from '../../constants/paths';
import { useUserQuery } from '../../hooks/useUser';

const sameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

const breadcrumbLinks = [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: '#', label: 'Message Users' }
];

function Messenger () {
  const userProfile = useUserQuery();

  const [over, setOver] = useState(null);
  const [selected, setSelected] = useState({});
  const joinedRoomId = useRef(null);
  const [message, setMessage] = useState('');
  const [scrollDown, setScrollDown] = useState(false);
  const [msgLoaded, setMsgLoaded] = useState(false);
  const [showAddContact, setShowAddContact] = useState(true);
  const [query, setQuery] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [canViewPage, setCanViewPage] = useState(false);
  const borderRadius = '10px';
  const history = useHistory();
  const { userRole } = Authentication;
  const params = useParams();
  const { pageTitleDetail, setPageTitleDetail } = DataStore;

  const isLearner = userRole === 'learner';
  const debouncedSearch = useRef(
    debounce(async (query) => {
      DataStore.getRecentMessageSessions(query);
    }, 500)
  ).current;

  async function getRecentMessageSessions () {
    try {
      setPageLoading(true);

      if (isLearner) {
        const isActive = (await DataStore.getMessengerStatus())
          .active;
        if (isActive) {
          setCanViewPage(true);
        }
      } else {
        setCanViewPage(true);
      }
    } catch (e) {
      history.push('/home');
    }
    await DataStore.getRecentMessageSessions();
    setPageLoading(false);
    setMsgLoaded(true);
  }

  useEffect(() => {
    debouncedSearch(query);
  }, [query]);

  const reloadContacts = async () => {
    await getRecentMessageSessions();
  };

  useEffect(() => {
    getRecentMessageSessions();
  }, []);

  useEffect(() => {
    return () => { setPageTitleDetail(null); };
  }, [setPageTitleDetail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    socket.emit('sendMessage', { message, id: selected.id, isLearner });
    setScrollDown(true);
    setTimeout(() => {
      setScrollDown(false);
    }, 500);
    setMessage('');
  };

  const resetScreen = () => {
    history.replace(isLearner ? MESSENGER : MESSAGE_USER_PATH);
    reloadContacts();
    setShowAddContact(true);
  };

  const handleInput = (e) => {
    setMessage(e.target.value);
  };
  const handleShowContactForm = () => {
    if (params.uid) {
      history.replace(isLearner ? MESSENGER : MESSAGE_USER_PATH);
    }
    setShowAddContact(!showAddContact);
  };

  const allContacts = [...DataStore.recent].sort((a, b) => {
    const firstContactTime = a.Messages.length > 0 ? a.Messages[0].createdAt : a.createdAt;
    const secondContactTime = b.Messages.length > 0 ? b.Messages[0].createdAt : b.createdAt;
    return new Date(secondContactTime) - new Date(firstContactTime);
  });

  useEffect(() => {
    if (!params.uid || !DataStore.recent.length) {
      return;
    }
    for (const {
      ExternalUser,
      User,
      NucleosUser,
      canUserRespond,
      id
    } of allContacts) {
      const messageUser = isLearner
        ? ExternalUser || NucleosUser
        : User;

      if (messageUser.uid === params.uid) {
        messageUser.id = id;
        messageUser.canRespond = canUserRespond;
        setSelected(messageUser);
        setShowAddContact(false);
        !pageTitleDetail && setPageTitleDetail('Messenger');
        if (joinedRoomId.current) { socket.emit('exitRoom', { id: joinedRoomId.current }); }
        socket.emit('joinRoom', { id });
        joinedRoomId.current = id;
        break;
      }
    }
  }, [params.uid, allContacts.length]);

  useEffect(() => {
    return () => {
      if (joinedRoomId.current) { socket.emit('exitRoom', { id: joinedRoomId.current }); }
    };
  }, []);

  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title={isLearner ? 'Messenger' : 'Message Users'} links={!isLearner ? breadcrumbLinks : []} />
      <div>
        {pageLoading || userProfile.isLoading
          ? (
            <LoadingComponent />
          )
          : !canViewPage
            ? (
              <h2>
          You do not have permissions to access messenger, please contact your
          system administrator if this continues.
              </h2>
            )
            : (
              <article
                style={{ display: 'flex' }}
              >
                <List
                  style={{
                    marginRight: '30px',
                    borderRight: '1px solid #BFC5D0',
                    width: '406px'
                  }}
                >
                  <h2 style={{ marginLeft: '16px' }}>Contacts</h2>
                  {(!isLearner) && (
                    <Button
                      style={{
                        marginLeft: '16px',
                        marginBottom: '10px',
                        textTransform: 'capitalize'
                      }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleShowContactForm()}
                    >
                + Add New Contact
                    </Button>
                  )}
                  <input
                    value={query}
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                    style={{
                      backgroundColor: '#EBEBEB',
                      borderStyle: 'none',
                      width: '95%',
                      padding: '1.2em',
                      margin: '0px 10px 20px 10px',
                      borderRadius
                    }}
                    placeholder="Search"
                  />
                  {allContacts.length > 0
                    ? (
                      allContacts.map(
                        ({
                          id,
                          Messages,
                          ExternalUser,
                          User,
                          NucleosUser,
                          approvalStatus,
                          canUserRespond,
                          createdAt: approvalCreatedAt
                        }) => {
                          const {
                            message,
                            createdAt,
                            ExternalUserId: MessageExternalUserId,
                            NucleosUserUid: MessageNucleosUserUid,
                            UserUid: MessageUserUid
                          } = Messages[0] || {
                            message: '',
                            createdAt: approvalCreatedAt
                          };
                          const dateCreateAt = new Date(createdAt || new Date());
                          const isSameDay = sameDay(new Date(), dateCreateAt);
                          const date = isSameDay
                            ? dateCreateAt.toLocaleTimeString()
                            : moment(dateCreateAt).format('MM/DD/YY');
                          const messageUser = isLearner
                            ? ExternalUser || NucleosUser
                            : User;
                          messageUser.canRespond = canUserRespond;
                          messageUser.id = id;
                          return (
                            <ListItem
                              key={messageUser.id}
                              id={`conversation-${messageUser.id}`}
                              style={{
                                alignItems: 'flex-start',
                                minHeight: '60px',
                                maxHeight: '60px',
                                backgroundColor:
                          id === over || id === selected.id
                            ? '#FDFDFD'
                            : '#EEEEEE',
                                marginTop: '1px',
                                borderBottom: '0.75px solid #717A92'
                              }}
                              onMouseOver={() => {
                                setOver(id);
                              }}
                              onMouseOut={() => {
                                if (over === id) {
                                  setOver(null);
                                }
                              }}
                              onClick={() => {
                                if (approvalStatus === APPROVAL_STATUS.APPROVED && messageUser.uid !== params.uid) {
                                  setShowAddContact(false);
                                  history.replace(`${isLearner ? MESSENGER : MESSAGE_USER_PATH}/user/${messageUser.uid}`);
                                }
                              }}
                              button
                            >
                              <ListItemAvatar>
                                <Icon
                                  initials={`${messageUser.firstName[0]}${messageUser.lastName[0]}`}
                                  isUser={false}
                                />
                              </ListItemAvatar>
                              <ListItemText>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <p
                                    id="user_name"
                                    style={{
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      display: 'block',
                                      fontSize: '14px'
                                    }}
                                  >
                                    {messageUser.UserTitle ? messageUser.UserTitle.title : ''}{' '}
                                    {messageUser.showFirstName === true || !isLearner ? messageUser.firstName : ''}{' '}
                                    {messageUser.lastName}
                                  </p>
                                  <p style={{ fontSize: '11px', marginTop: '3px' }}>
                                    {approvalStatus === APPROVAL_STATUS.APPROVED
                                      ? date
                                      : 'Requested ' +
                                formatDateTime(approvalCreatedAt)}
                                  </p>
                                </div>
                                <p
                                  style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    display: 'block',
                                    fontSize: '13px'
                                  }}
                                >
                                  {approvalStatus === APPROVAL_STATUS.APPROVED
                                    ? userProfile.data && [MessageExternalUserId, MessageNucleosUserUid, MessageUserUid].includes(userProfile.data.uid)
                                      ? 'You: ' + message
                                      : message
                                    : approvalStatus === APPROVAL_STATUS.UNAPPROVED
                                      ? 'Pending Approval'
                                      : 'Declined'}
                                </p>
                              </ListItemText>
                            </ListItem>
                          );
                        }
                      )
                    )
                    : msgLoaded
                      ? (
                        <div style={{ textAlign: 'center' }}>
                          <h2>No Messages Found</h2>
                          <p>
                  No contacts are currently available. Only facility
                  administrators or friends and family can start a message
                  thread. Friends and family need to register on the InTouch app
                  and request you as a contact.{' '}
                            {false && (
                              <label
                                style={{ color: 'blue', textDecoration: 'underline' }}
                              >
                      Steps on how to get help with this
                              </label>
                            )}
                          </p>
                        </div>
                      )
                      : (
                        <Skeleton height="50px" width="100vw" count={8} />
                      )}
                </List>
                {selected.id && !showAddContact && (
                  <section style={{ width: '70%' }}>
                    <Messages
                      key={selected.id || ''}
                      resetScreen={resetScreen}
                      user={selected}
                      scrollDown={scrollDown}
                    />
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px'
                      }}
                    >
                      <form
                        onSubmit={handleSubmit}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: '#EBEBEB',
                          width: '90%',
                          justifyContent: 'flex-start',
                          borderRadius,
                          marginTop: '40px'
                        }}
                      >
                        <input
                          disabled={isLearner && !selected.canRespond}
                          onChange={handleInput}
                          value={message}
                          style={{
                            backgroundColor: '#EBEBEB',
                            borderStyle: 'none',
                            width: '100%',
                            padding: '1.2em',
                            margin: '0',
                            borderRadius
                          }}
                          placeholder="Write a message"
                        />
                        <IconButton
                          disabled={isLearner && !selected.canRespond}
                          type="submit"
                          color="primary"
                          style={{ borderRadius }}
                        >
                          <NucleosIcon icon='SendOutlined' />
                        </IconButton>
                      </form>
                    </div>
                  </section>
                )}
                {showAddContact && !isLearner && (
                  <AddContact reloadContacts={reloadContacts} />
                )}
              </article>
            )}
      </div>
    </Box>
  );
}

export default observer(Messenger);
