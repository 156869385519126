import React, { useEffect, useState } from 'react';
import { SearchDropdown } from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';
import { QueryKeys } from '../../Lib/query-keys';

const ALL_GROUPS_OPTION = { id: 'ALL', name: 'All Learners' };

export default function UniversalGroupSearch ({
  label,
  Icon,
  placeholder,
  groupId,
  onGroupSelect: _onGroupSelect,
  selectedGroups: _selectedGroups,
  enableAllGroupOption
}) {
  const selectedGroupState = useState(null);
  const [selectedGroup, setSelectedGroup] =
    _selectedGroups !== undefined
      ? [_selectedGroups, _onGroupSelect]
      : selectedGroupState;
  const [searchValue, setSearchValue] = useState('');

  const selectedGroupQuery = useQuery(
    QueryKeys.Groups.item(groupId),
    () => Data.getGroup(groupId),
    {
      placeholderData: {},
      enabled: !!groupId && groupId !== 'ALL',
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  const groupsSearchQuery = usePaginatedQuery(
    QueryKeys.Groups.listing({ searchValue }),
    ({ pageParam = {} }) => {
      return Data.getGroups(
        false,
        searchValue,
        undefined,
        { groupType: ['LEARNER'] },
        pageParam.page ? pageParam.page : 0,
        undefined,
        'name',
        'ASC'
      );
    },
    {
      listKeyName: 'rows',
      select: (data) => {
        return {
          ...data,
          pages: enableAllGroupOption
            ? [ALL_GROUPS_OPTION, ...data.pages]
            : data.pages
        };
      }
    }
  );

  useEffect(() => {
    if (groupId === 'ALL') {
      setSelectedGroup({
        label: ALL_GROUPS_OPTION.name,
        value: ALL_GROUPS_OPTION.id
      });
    } else if (
      groupId &&
      selectedGroupQuery.status === 'success' &&
      selectedGroupQuery.data &&
      selectedGroupQuery.data.id
    ) {
      setSelectedGroup({
        label: selectedGroupQuery.data.name,
        value: selectedGroupQuery.data.id
      });
    }
  }, [groupId, selectedGroupQuery.data]);

  const onGroupSelect = (group) => {
    setSelectedGroup(group);
    setSearchValue('');

    const selectedGroup = group || {};

    let originalGroup = {};
    if (selectedGroup.value === 'ALL') {
      originalGroup = ALL_GROUPS_OPTION;
    } else {
      originalGroup = groupsSearchQuery.data.pages.find(
        (qUser) => qUser.id === selectedGroup.value
      );
    }

    if (_onGroupSelect) { _onGroupSelect(originalGroup); }
  };

  const selectedGroupOption =
    selectedGroupQuery.status === 'success' &&
    selectedGroupQuery.data &&
    selectedGroupQuery.data.id
      ? {
        label: selectedGroupQuery.data.name,
        value: selectedGroupQuery.data.id
      }
      : null;

  const fetchNextPaginatedResults = () => {
    groupsSearchQuery.fetchNextPage();
  };

  return (
    <div>
      <SearchDropdown
        label={label}
        Icon={Icon}
        selectedOption={selectedGroup ? selectedGroup.value : null}
        isLoading={groupsSearchQuery.isPlaceholderData}
        isMoreRecordsLoading={groupsSearchQuery.isFetching}
        placeholder={placeholder || 'Search group'}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        options={[
          ...groupsSearchQuery.data.pages
            .map((group) => ({
              label: group.name,
              value: group.id
            }))
            .filter((grp) => grp.value !== (selectedGroupOption || {}).value),
          ...(selectedGroupOption && !searchValue ? [selectedGroupOption] : [])
        ]}
        onItemSelect={onGroupSelect}
        onScrollReachBottom={fetchNextPaginatedResults}
      />
    </div>
  );
}
