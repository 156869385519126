import api from './Api';

class AuthApi extends api {
  constructor () {
    super();
    this.loginURL = '/login';
    this.openidURL = '/openid';
    this.googleURL = '/sso/google';
    this.forgotPasswordURL = '/forgotPassword';
    this.requestPasswordResetURL = '/reset';
    this.logoutURL = '/logout';
  }

  attemptLogin (data) {
    if (data && data.image) {
      return this.uploadPostRequest(this.loginURL, data);
    } else {
      return this.postRequest(this.loginURL, data);
    }
  }

  requestPasswordReset (data) {
    return this.putRequest(this.requestPasswordResetURL, data);
  }

  resetPassword (username, password, resetCode) {
    return this.postRequest(this.requestPasswordResetURL, { username, resetCode, password });
  }

  openIDLogin (code) {
    return this.postRequest(this.openidURL, { code });
  }

  openIDLogout ({ userUid, serialNumber, isTablet }) {
    return this.postRequest(this.logoutURL, {
      userUid,
      serialNumber,
      isTablet
    });
  }
}

export default new AuthApi();
