import React, { useEffect } from 'react';

function ErrorFallback ({ error }) {
  // can setup SENTRY for UI error logging
  useEffect(() => {
    localStorage.setItem('errorOccurred', true);
  }, []);

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <>
      <div className="card my-5">
        <div className="card-header">
          <p>
            An error has occurred. Please contact support.{' '}
            <span
              style={{ cursor: 'pointer', color: '#0077FF' }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload this page
            </span>{' '}
          </p>
        </div>
      </div>
    </>
  );
}
export default ErrorFallback;
