import React from 'react';
import PropTypes from 'prop-types';
import './Form.scss';

const Select = props => {
  const {
    className,
    error,
    label,
    name,
    onBlur,
    onChange,
    options = [],
    touched,
    value,
    placeholder,
    disabled,
    selectStyle
  } = props;

  const classNameAddOn = className ? ` ${className}` : '';
  const containerClassName = `nucleos-field-container select-container${classNameAddOn}`;
  const wrapperClassName = `nucleos-field-label-wrapper select-wrapper${classNameAddOn}`;
  const labelClassName = `nucleos-label select-label${classNameAddOn}`;
  const selectClassName = `nucleos-field nucleos-select${classNameAddOn}`;
  const errorClassName = `nucleos-field-error select-error${classNameAddOn}`;

  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        {label && <label className={labelClassName}>{label}:</label>}
        <select
          className={selectClassName}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          name={name}
          value={value}
          placeholder={placeholder || 'Student'}
          style={selectStyle || {}}
        >
          {options.map(option => {
            const key = option.value ? option.value.split('.').join('-').toLowerCase() : '-1';
            return (
              // eslint-disable-next-line react/no-unknown-property
              <option key={key} chosen={(option.value == value).toString()} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
      {error && touched && <span className={errorClassName}>{error}</span>}
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  touched: PropTypes.bool,
  value: PropTypes.string.isRequired
};

export default Select;
