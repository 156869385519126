import React from 'react';
import NoUsageHistoryFound from '../../Assets/graphics/no_data_found.svg';

export const NoDataFound = () => {
  return (
    <div className="nucleos-core">
      <div className="flex justify-center items-center flex-col p-20">
        <img src={NoUsageHistoryFound} alt="No results found" />
        <div className="text-xl font-bold" style={{ color: '#333333' }}>
          {' '}
          Sorry! No data found.{' '}
        </div>
        <div
          className="text-base font-normal mt-3 w-85 text-center"
          style={{ color: 'rgba(51, 51, 51, 0.7)' }}
        >
          Sorry, there are no results for this filters, please try adjusting the
          filter.
        </div>
      </div>
    </div>
  );
};
