import React from 'react';
import { useHistory } from 'react-router-dom';

import { GroupsLearningRecordContextProvider } from '../GroupLearningRecordContext';
import ActivityByDate from '../ActivityByDate/ActivityByDate';
import ActivityTotals from '../ActivityTotals/ActivityTotals';
import CourseStatusReportByApplications from '../CourseStatusReport/CourseStatusReportByApplications';
import CourseStatusReportByUsers from '../CourseStatusReport/CourseStatusReportByUsers';
import AggregateAppUsage from '../AggregateAppUsage/AggregateAppUsage';
import GroupRecordDetails from '../GroupRecordHeaderComponents/GroupRecordDetails';
import { PDFHeader } from './PDFHeader';
import { OptionKeys } from './ExportAsPDFModal';
import GroupAbout from '../ApplicationDescription/GroupAbout/GroupAbout';
import ApplicationList from '../ApplicationDescription/ApplicationList/ApplicationList';

function GroupRecordPdf () {
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const _enabledOptions = new URLSearchParams(history.location.search).get(
    'enabledOptions'
  );
  const enabledOptions = _enabledOptions ? JSON.parse(_enabledOptions) : {};

  return (
    <div className="nucleos-core" style={{ paddingBottom: '150px' }}>
      <div className="nucleos-core nucleos-learner-record-pdf">
        <GroupsLearningRecordContextProvider isPDFMode={true}>
          {groupId && (
            <div>
              <PDFHeader />
              <div className="px-4">
                <GroupRecordDetails />
                {enabledOptions[OptionKeys.ApplicationDescription] && (
                  <div className="mb-8" style={{ pageBreakAfter: 'always' }}>
                    <GroupAbout />
                    <ApplicationList />
                  </div>
                )}
                {enabledOptions[OptionKeys.CourseStatusReportByApplications] && (
                  <div className="mb-8" style={{ pageBreakAfter: 'always' }}>
                    <CourseStatusReportByApplications />
                  </div>
                )}
                {enabledOptions[OptionKeys.CourseStatusReportByUsers] && (
                  <div className="mb-8" style={{ pageBreakAfter: 'always' }}>
                    <CourseStatusReportByUsers />
                  </div>
                )}
                {enabledOptions[OptionKeys.AggregateAppUsage] && (
                  <div className="mb-8" style={{ pageBreakAfter: 'always' }}>
                    <AggregateAppUsage />
                  </div>
                )}
                {enabledOptions[OptionKeys.ActivityByDate] && (
                  <div className="mb-8" style={{ pageBreakAfter: 'always' }}>
                    <ActivityByDate />
                  </div>
                )}
                {enabledOptions[OptionKeys.ActivityTotalByUsers] && (
                  <div className="mb-8" style={{ pageBreakAfter: 'always' }}>
                    <ActivityTotals />
                  </div>
                )}
              </div>
            </div>
          )}
        </GroupsLearningRecordContextProvider>
      </div>
    </div>
  );
}

export default GroupRecordPdf;
