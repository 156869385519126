import React from 'react';
import { Button, Typography } from '@nucleos/core-ui';
import { Icon } from '../Shared/Icon';

const UnbanUserModalContent = ({
  isBulk,
  userDisplayName,
  onClose,
  onSuccess
}) => {
  return (
    <div className="nucleos-core">
      <div className="mx-auto my-0 w-8/12">
        <div
          className="flex flex-col justify-start items-center p-10 rounded-md"
          style={{ background: '#ffffff' }}
        >
          <div>
            <Icon icon="Cancel" className="h-14 w-14" style={{ fill: '#F83232' }} />
          </div>
          <div className="text-2xl mt-6">Are you sure?</div>

          <div className="flex justify-cente mt-6">
            <Typography className="text-center">
              <span>
                You want to unban {isBulk ? 'selected users' : ''}
                {userDisplayName
                  ? (
                    <span>
                    user <b>{userDisplayName}</b>
                    </span>
                  )
                  : (
                    ''
                  )}
                ? Once the account is unbaned, the learner can gain access to
                it.
              </span>
            </Typography>
          </div>

          <div className="flex gap-x-6 items-center mt-10">
            <Button style={{ color: '#333333' }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              style={{ backgroundColor: '#4C741F' }}
              variant="contained"
              onClick={() => {
                onSuccess();
                onClose();
              }}
            >
              Unban
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnbanUserModalContent;
