import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Typography, Button, Table, SearchInput } from '@nucleos/core-ui';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../UserContext';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { formatDateTime } from '../../../Lib/util';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { useMutation } from 'react-query';
import Data from '../../../Middleware/Data';
import { IconButton, MenuItem, MenuList, Popover } from '@mui/material';
import toast from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import { QueryKeys } from '../../../Lib/query-keys';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { usePaginatedQuery } from '../../../hooks/usePaginatedQuery';
import { Icon } from '../../../Components/Shared/Icon';
import { ERROR_RED } from '../../../constants/colors';

export function UserGroupsCard () {
  const { uid: userId } = useParams();
  const userCTX = useUserContext();

  const permissions = useUserPermissions();

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const [searchValue, setSearchValue] = useState('');

  const handlePopoverOpen = useCallback((e) => {
    setPopoverAnchorEl(e.currentTarget);
  }, []);

  const handlePopoverClose = useCallback((e) => {
    setPopoverAnchorEl(null);
  }, []);

  const groupsSearchQuery = usePaginatedQuery(
    QueryKeys.Groups.listing({ searchValue }),
    ({ pageParam = { page: 0 } }) => {
      return Data.getGroups(
        false,
        searchValue,
        undefined,
        { groupType: userCTX.user.Role.name === ACTIVE_ROLES.LEARNER ? ['LEARNER'] : ['NON_LEARNER'] },
        pageParam.page,
        20,
        'name',
        'ASC'
      );
    },
    {
      listKeyName: 'rows',
      select: (data) => {
        console.log('groups', data);

        const { pages } = data;

        const groupIdsSet = new Set();
        userCTX.user.groups.forEach(group => groupIdsSet.add(group.uid));

        return pages.filter((group) => group && !groupIdsSet.has(group.id)) || [];
      }
    }
  );

  const addUserMutation = useMutation(({ groupId, userIds }) => Data.addUserToGroup(groupId, userIds).then(GenericErrorDetectorForMutations));
  const removeUserMutation = useMutation(({ groupId, userIds }) => Data.removeUserFromGroup(groupId, userIds).then(GenericErrorDetectorForMutations));

  function addUserToGroup (groupId) {
    if (userId) {
      addUserMutation.mutate({ groupId, userIds: [userId] }, {
        onSuccess: () => {
          setSearchValue('');
          setPopoverAnchorEl(null);

          toast.success('Successfully added user to the group.');

          userCTX.refetchUser();
        },
        onError: (error) => toast.error(error.message || 'Failed to add user to the group.')
      });
    }
  }

  const removeUserFromGroup = useCallback((groupId) => {
    if (userId) {
      removeUserMutation.mutate({ groupId, userIds: [userId] }, {
        onSuccess: () => {
          toast.success('Successfully removed user from the group.');

          userCTX.refetchUser();
        },
        onError: (error) => toast.error(error.message || 'Failed to remove user from the group.')
      });
    }
  }, [userId]);

  const groupsData = useMemo(() => {
    return Array.isArray(userCTX.user.groups) && userCTX.user.groups.length
      ? userCTX.user.groups.map((group) => ({
        id: group.uid,
        name: group.name,
        addedOn: group.UserGroup.createdAt
      }))
      : [];
  }, [userCTX.user.groups]);

  const columns = useMemo(() => {
    const columns = [
      {
        title: 'Group Name',
        render: (data) => data.name
      },
      {
        title: 'Added On',
        render: (data) => formatDateTime(data.addedOn)
      }
    ];

    if (permissions.canRemoveUserFromAGroup()) {
      columns.push({
        title: 'Action',
        render: (data) => {
          return (
            <IconButton onClick={() => removeUserFromGroup(data.id)}>
              <Icon icon="CloseRounded" style={{ color: ERROR_RED }} />
            </IconButton>
          );
        }
      });
    }

    return columns;
  }, []);

  useEffect(() => {
    groupsSearchQuery.refetch();
  }, []);

  return (
    <Card className="bg-white p-6 mb-6">
      <Card.Header className="mb-4" style={{ borderBottom: 'none' }}>
        <div className="flex justify-between items-center">
          <Typography
            variant="h4"
            style={{ fontSize: 18, color: '#333' }}
            className="font-bold"
          >
            Groups
          </Typography>

          {
            permissions.canAddUserToAGroup()
              ? (
                <div className="flex items-center">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlePopoverOpen}
                  >
                    + Add Group
                  </Button>

                  <Popover
                    onClose={handlePopoverClose}
                    open={Boolean(popoverAnchorEl)}
                    anchorEl={popoverAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    PaperProps={{
                      elevation: 2,
                      style: { width: 200, overflow: 'hidden' }
                    }}
                  >
                    <div className="nucleos-core">
                      <div className="p-2">
                        <SearchInput
                          placeholder="Search Group Name..."
                          fullWidth
                          value={searchValue}
                          onSearch={(search) => setSearchValue(search)}
                        />
                      </div>

                      <BottomScrollListener onBottom={() => !groupsSearchQuery.isFetching && groupsSearchQuery.fetchNextPage()} offset={20}>
                        {(ref) => (
                          <div ref={ref} style={{ maxHeight: 300, overflowY: 'scroll' }}>
                            {
                              Array.isArray(groupsSearchQuery.data) && groupsSearchQuery.data.length
                                ? (
                                  <MenuList dense>
                                    {
                                      groupsSearchQuery.data.map((group) => (
                                        <MenuItem key={group.id} button onClick={() => addUserToGroup(group.id)}>
                                          {group.name}
                                        </MenuItem>
                                      ))
                                    }
                                  </MenuList>
                                )
                                : <Typography className="p-2" align="center" color="textMuted">No groups found...</Typography>
                            }
                          </div>
                        )}
                      </BottomScrollListener>
                    </div>
                  </Popover>
                </div>
              )
              : null
          }
        </div>
      </Card.Header>
      <Card.Body>
        <Table
          pagination
          columns={columns}
          noDataMessage={<GenericNoDataFound />}
          totalRecords={groupsData.length}
          rowsData={groupsData}
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </Card.Body>
    </Card>
  );
}
