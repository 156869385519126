import React, { useState } from 'react';
import { Box, Button, Checkbox } from '@mui/material';
import { Card, Table, Typography } from '@nucleos/core-ui';
import CourseListByUsersModal from './CourseListByUsersModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import { SortOrder, useTableSorting } from '../../../hooks/useTableSorting';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';

const ColumnKeys = {
  Fullname: 'fullname',
  CoursesCompleted: 'completed_courses',
  CoursesInProgress: 'inprogress_courses'
};

const defaultSorting = {
  column: ColumnKeys.CoursesCompleted,
  sortOrder: SortOrder.Descending
};

export default function CourseStatusReportByUsers () {
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();

  const [showLearnersWithCourses, setShowLearnersWithCourses] = useState(true);

  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'status-report-by-users'
    });
  const requestData = {
    groupId,
    type: 'all_courses_count_by_users',
    apps: GLRContext.apps,
    learnersWithCourses: showLearnersWithCourses,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2],
    order: columnSorting.sortOrder,
    orderBy: columnSorting.column
  };
  const courseStatusReportQuery = useQuery(
    QueryKeys.GroupLearningRecords.listing(requestData),
    () => Data.getGroupLearningRecordReportData(requestData),
    { placeholderData: [], enabled: !!groupId }
  );

  const columns = [
    {
      title: 'User Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.Fullname),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.Fullname, sortOrder }),
      render: (data) => data.title
    },
    {
      title: 'Courses Completed',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CoursesCompleted),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CoursesCompleted, sortOrder }),
      render: (data) => (
        <div>
          {data.isTotal ||
            +data.completedCourses === 0 ||
            GLRContext.isPDFMode
            ? (
              data.completedCourses
            )
            : (
              <Button
                sx={(theme) => ({ padding: 0, minWidth: 0, color: `${theme.palette.link.main} !important` })}
                onClick={() => {
                  const urlSearch = new URLSearchParams(window.location.search);
                  urlSearch.set('userId', data.id);
                  urlSearch.set('mode', 'completed');
                  history.replace({
                    search: urlSearch.toString()
                  });
                }}
              >
                {data.completedCourses}
              </Button>
            )}
        </div>
      )
    },
    {
      title: 'Courses in progress',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CoursesInProgress),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CoursesInProgress, sortOrder }),
      render: (data) => (
        <div>
          {data.isTotal ||
            +data.coursesInProgress === 0 ||
            GLRContext.isPDFMode
            ? (
              data.coursesInProgress
            )
            : (
              <Button
                sx={(theme) => ({ padding: 0, minWidth: 0, color: `${theme.palette.link.main} !important` })}
                onClick={() => {
                  const urlSearch = new URLSearchParams(window.location.search);
                  urlSearch.set('userId', data.id);
                  urlSearch.set('mode', 'in-progress');
                  history.replace({
                    search: urlSearch.toString()
                  });
                }}
              >
                {data.coursesInProgress}
              </Button>
            )}
        </div>
      )
    }
  ];

  const rowsData = courseStatusReportQuery.data
    ? courseStatusReportQuery.data.length
      ? [
        ...courseStatusReportQuery.data.map((user) => ({
          id: user.userId,
          title: user.fullname,
          completedCourses: user.courses_completed,
          coursesInProgress: user.courses_in_progress
        })),
        {
          id: null,
          isTotal: true,
          title: <Typography className="font-bold">TOTAL</Typography>,
          completedCourses: (
            <Typography className="font-bold">
              {courseStatusReportQuery.data.reduce(
                (acc, user) => acc + Number(user.courses_completed),
                0
              )}
            </Typography>
          ),
          coursesInProgress: (
            <Typography className="font-bold">
              {courseStatusReportQuery.data.reduce(
                (acc, user) => acc + Number(user.courses_in_progress),
                0
              )}
            </Typography>
          )
        }
      ]
      : []
    : [];

  return (
    <div className="h-full">
      <Card className="rounded-xl bg-white h-full">
        <Card.Header style={{ border: 'none' }}>
          <div className="flex justify-between items-center gap-x-4">
            <div>
              <Typography
                className="py-1.5 ml-2"
                variant="h4"
                style={{ fontWeight: 700, fontSize: '18px' }}
              >
                Course Status Report - By Users
              </Typography>
            </div>

            {
              GLRContext.isPDFMode
                ? (
                  <>
                    {
                      showLearnersWithCourses
                        ? (
                          <Typography variant="subtitle1">*Only showing learners with progress.</Typography>
                        )
                        : null
                    }
                  </>
                )
                : (
                  <Box display='flex' alignItems="center">
                    <Checkbox
                      checked={showLearnersWithCourses}
                      onChange={(_, value) => setShowLearnersWithCourses(value)}
                    />
                    <Typography>Only show learners with progress</Typography>
                  </Box>
                )
            }
          </div>
        </Card.Header>
        <Card.Body
          style={{ padding: 0 }}
          className="course-status-report-card-body"
        >
          <div className={GLRContext.isPDFMode ? '' : 'overflow-y-auto'} style={GLRContext.isPDFMode ? {} : { height: '41rem' }}>
            <Table
              columns={columns}
              loading={courseStatusReportQuery.isFetching}
              totalRecords={Infinity}
              noDataMessage={<GenericNoDataFound className="pb-1" size="small" />}
              rowsData={rowsData}
            />
          </div>
        </Card.Body>
      </Card>
      <CourseListByUsersModal
        userId={new URLSearchParams(window.location.search).get('userId')}
        mode={new URLSearchParams(window.location.search).get('mode')}
        onClose={() => {
          const urlSearch = new URLSearchParams(window.location.search);
          urlSearch.delete('userId');
          urlSearch.delete('mode');
          history.replace({
            search: urlSearch.toString()
          });
        }}
      />
    </div>
  );
}
