import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Grid, Modal } from '@mui/material';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import './LearningOverview.scss';
import AuthenticationStore from '../../Stores/Authentication';
import defaultIcon from '../../Assets/course-icon-default.svg';
import CourseLessons from './CourseLessons';
import CourseProgressMetrics from './CourseProgressMetrics';
import SliderArrow from '../../Assets/slider-arrow.svg';
import { TimeLine, Typography } from '@nucleos/core-ui';
import NoActivity from '../../Assets/graphics/no_activity.svg';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';
import Data from '../../Middleware/Data';
import ScrollBottomLoading from '../../Components/Shared/ScrollBottomLoading';
import DataStore from '../../Stores/DataStore';
import { COURSE_CONTENT_LIBRARY_PATH } from '../../constants/paths';

const NoProgressFound = () => {
  return (
    <div className='nucleos-core'>
      <div className='mt-6 rounded-lg flex justify-center items-center py-6 flex-col'>
        <div>
          <img src={NoActivity} />
        </div>
        <div className='mt-3'><Typography className='font-bold text-xl' style={{ color: '#19223D' }} >Sorry! No learning progress yet.</Typography></div>
        <div className='mt-1'><Typography className='font-normal text-sm' style={{ color: 'rgba(51, 51, 51, 0.7)' }}>It seems that no significant learning progress has been made at this time.</Typography></div>
      </div>
    </div>
  );
};

const ProgressItem = props => {
  const { title, updatedAt, courseIcon, createdAt, courseLessons, viewLessonHandler, courseId, rawData, metaRawData, courseStatus, applicationUid, applicationName } = props;

  const inProgressCourses = courseLessons && courseLessons.length && courseLessons.filter(cl => {
    if (cl && cl.CourseLessonProgresses && cl.CourseLessonProgresses.length) {
      return !!(cl.CourseLessonProgresses[0] && cl.CourseLessonProgresses[0].status && cl.CourseLessonProgresses[0].status === 'STARTED');
    } else {
      return false;
    }
  });

  const linkTo = applicationUid
    ? `${COURSE_CONTENT_LIBRARY_PATH}/${applicationUid}`
    : COURSE_CONTENT_LIBRARY_PATH;
  return (
    <Grid item xs={12} className='learning-overview__progr ess-container'>
      <div className='learning-overview__progr ess-row'>

        <div className='w-full rounded-lg' style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 10px 40px rgba(64, 72, 82, 0.1)' }}>

          <div className={`flex items-center flex-start  ${courseLessons.length ? 'mb-3.5' : 'pb-4'}`}>
            <div className='mt-4 ml-4 h-11 w-11'>
              <img className='object-contain h-11 w-11' style={{ borderRadius: '50%' }} src={courseIcon || defaultIcon} title={applicationName} />
            </div>
            <div className='ml-3 mt-4 mr-8' style={{ width: '40%' }}>
              <div style={{ color: '#19223D' }} className='text-base'>
                {title}
              </div>
              <div className='progress-date-container text-xs mt-1' style={{ color: 'rgba(51, 51, 51, 0.6)' }} >
                <div>Enrollment: {createdAt ? moment(new Date(createdAt)).format('MM/DD/YYYY') : 'N/A'}</div>
                <div className='h-1 w-1 mx-2 progress-date-dot' style={{ background: '#adadad', borderRadius: '70%' }}></div>
                <div>Last Activity: {updatedAt ? moment(new Date(updatedAt)).format('MM/DD/YYYY') : 'N/A'}</div>
              </div>

            </div>
            {/* Course Metrics */}
            <div className='w-3/5 mt-4'>
              <CourseProgressMetrics rawData={rawData} metaRawData={metaRawData} courseStatus={courseStatus} />
            </div>
            <div>
              <Link to={linkTo}>
                <img src={SliderArrow} />
              </Link>
            </div>
          </div>
          {courseLessons.length
            ? <div className='flex justify-between  pt-3.5 ml-4 mr-4 pb-5' style={{ borderTop: '1px solid #E4E4E4', color: 'rgba(51, 51, 51, 0.6)' }}>
              <div className='text-xs'>
                Lessons in Progress: <span style={{ color: '#333333' }}>{inProgressCourses.length}</span>
              </div>
              <div className='text-sm cursor-pointer' style={{ color: '#449FFF' }} onClick={(e) => {
                e.preventDefault();
                viewLessonHandler(courseId, { updatedAt, createdAt, appName: title, logo: courseIcon });
              }
              } > View Lesson Details </div>
            </div>
            : null}

        </div>
      </div>
    </Grid>
  );
};

const UserProgress = () => {
  const { uid } = useParams();
  const { uid: loggedInUserId, userRole } = AuthenticationStore;
  const recordsUserUid = userRole === 'learner' ? loggedInUserId : uid;

  const [openLessonModal, setOpenLessonModal] = React.useState(false);
  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  const [selectedAppDetails, setSelectedAppDetails] = React.useState({ appName: '', logo: '', createdAt: '', updatedAt: '' });

  const learningProgressQuery = usePaginatedQuery(
    ['LEARNING_PROGRESS', recordsUserUid],
    ({ pageParam = {} }) => Data.getUserLearningProgress(
      recordsUserUid,
      pageParam.offset || '0',
      pageParam.limit || '10'
    ),
    { listKeyName: 'rows', enabled: !!recordsUserUid }
  );

  const userLearningProgress = learningProgressQuery.data.pages;

  const viewLessonHandler = (courseId, appDetails) => {
    setOpenLessonModal(true);
    setSelectedCourseId(courseId);
    setSelectedAppDetails(appDetails);
  };

  const closeLessonModalHandler = () => {
    setOpenLessonModal(false);
    setSelectedCourseId(null);
    setSelectedAppDetails({ appName: '', logo: '', createdAt: '', updatedAt: '' });
  };

  const pi = useMemo(() => userLearningProgress.map((ul, index) => {
    const icon = (ul.Application && (ul.Application.logo || ul.Application.icon)) || ul.icon;
    const metaRawData = ul.metaRawData;
    const rawData = ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && ul.CourseProgresses[0].rawData;
    const courseStatus = ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && ul.CourseProgresses[0].status;
    const applicationUid = ul.ApplicationUid;
    const applicationName = ul.Application && ul.Application.name;
    const updatedAt = ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && ((ul.CourseProgresses[0].rawData && ul.CourseProgresses[0].rawData.lastActivity) || ul.CourseProgresses[0].progressUpdatedAt);
    return {
      renderLabel: () => (
        <div className='w-32 whitespace-nowrap flex justify-start'>
          <div className='relative top-2.5'>
            <Typography className='text-sm font-normal' style={{ color: 'rgba(51, 51, 51, 0.7)' }} >{updatedAt ? moment(new Date(updatedAt)).format('MMM DD, YYYY') : 'Not Available'}</Typography>
          </div>
        </div>
      ),
      renderValue: () => (<div className='mb-5 w-full '> <ProgressItem
        key={`progress-item-${ul.applicationUid}-${index}`}
        title={ul.title}
        updatedAt={updatedAt}
        createdAt={ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && (ul.CourseProgresses[0].enrolledAt || ul.CourseProgresses[0].createdAt)}
        courseIcon={icon}
        courseLessons={ul.CourseLessons}
        viewLessonHandler={viewLessonHandler}
        courseId={ul.id}
        metaRawData={metaRawData}
        rawData={rawData}
        courseStatus={courseStatus}
        applicationUid={applicationUid}
        applicationName={applicationName}
      /> </div>)
    };
  }), [userLearningProgress]);

  if ((learningProgressQuery.isPlaceholderData || learningProgressQuery.isLoading) && DataStore.isGoalsLoading) {
    return <LoadingComponent />;
  }

  if (learningProgressQuery.isPlaceholderData || learningProgressQuery.isLoading) {
    return (
      <div className='flex justify-center'>
        <LoadingComponent small='true' tillApiResponse={true} />
      </div>
    );
  } else {
    return (
      <div className='nucleos-core'>
        <div style={{ color: '#19223D', borderBottom: '0.5px solid #BFC5D0' }} className='pb-3 text-lg font-semibold' >
          Learning Progress
        </div>
        {pi.length === 0
          ? <NoProgressFound />
          : <div className='mt-6 overflow-x-auto'>
            <div className='flex flex-col mr-2' style={{ minWidth: '47rem' }}>
              <ScrollBottomLoading
                dataCount={pi.length}
                isFetching={learningProgressQuery.isFetching}
                fetchNextPage={learningProgressQuery.fetchNextPage}
                hasMore={learningProgressQuery.hasNextPage}
              >
                <TimeLine
                  items={[
                    ...pi
                  ]}
                />
              </ScrollBottomLoading>
              <Modal className='nucleos-core' open={openLessonModal} onClose={closeLessonModalHandler} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <CourseLessons
                  selectedAppDetails={selectedAppDetails}
                  closeLessonModalHandler={closeLessonModalHandler}
                  courseId={selectedCourseId}
                  uid={uid || loggedInUserId}
                />
              </Modal>
            </div>
          </div>}
      </div>
    );
  }
};

export default observer(UserProgress);
