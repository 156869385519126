import React, { useState } from 'react';
import {
  Typography,
  Button
} from '@nucleos/core-ui';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getHoursAndMinutes } from '../../Lib/util';
import { LinearProgress, Modal, Popper, MenuItem, ClickAwayListener, Grow, MenuList } from '@mui/material';
import { Icon } from '../../Components/Shared/Icon';

// const theme = createMuiTheme({
//   palette: {
//     primary: { main: '#21cd05', light: '#E9E9E9' }
//   },
//   typography: {
//     useNextVariants: true
//   }
// });

const Goal = (props) => {
  const { name, applicationName, logo, minutes, minutesRemaining, handleDeleteButtonClick, id, handleEditButtonClick, isAdmin, handleViewButtonClick } = props;
  const { hoursAdd: originalHoursAdd, minutesRemainder: originalMinutesRemainder } = getHoursAndMinutes(minutes);
  const goalProgress = minutes - minutesRemaining;
  const { hoursAdd: goalHrs, minutesRemainder: goalMins } = getHoursAndMinutes(goalProgress);
  const percentage = minutes ? ((minutes - minutesRemaining) / minutes) * 100 : undefined;
  const [anchorElement, setAnchorElement] = useState(null);
  const [openPoper, setOpenPoper] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  function handleCloseMenu () {
    setOpenPoper(false);
    setAnchorElement(null);
  }

  function handleOpenMenu ({ currentTarget }) {
    setOpenPoper(true);
    setAnchorElement(currentTarget);
  }

  return (
    <div className={`flex justify-between items-center px-2 py-3 ${!isAdmin ? 'cursor-pointer' : ''} `} style={{ borderBottom: '1px solid rgba(191, 197, 208, 0.5)' }}
      onClick={() => {
        if (isAdmin) { return; }
        handleViewButtonClick(id);
      }}
    >
      <div className="w-3/5 pr-6">
        <div className="flex justify-start items-center">
          <img className="h-5 w-5 object-contain mr-1" src={logo} alt='app-logo' style={{ borderRadius: '50%' }} title={applicationName} />
          <Typography className='ml-1 text-lg font-extrabold' style={{ color: '#000000' }}>{applicationName}</Typography>
        </div>
        <div className="mt-1.6">
          <div className='min-w-0' style={{ flex: 1 }}>
            <p style={{ color: '#19223D' }} title={name} className='truncate text-base font-bold' >{name}</p>
          </div>
        </div>
      </div>
      <div className="w-2/5 flex justify-start items-start">
        <div className="w-full">
          <div>
            {/* <MuiThemeProvider theme={theme} > */}
            <LinearProgress
              color='primary'
              value={percentage}
              variant='determinate' />
            {/* </MuiThemeProvider> */}
          </div>
          <div className='flex justify-start items-center mt-3.5'>
            <Icon icon="Clock" className="h-4 w-4" color='primary' />
            <span className='ml-1 text-sm' style={{ color: '#000000' }} >
              {`${goalHrs ? goalHrs.toString() : ''}${goalHrs ? 'h ' : ''}${goalMins.toString()}m out of ${originalHoursAdd ? originalHoursAdd.toString() : ''}${originalHoursAdd ? 'h ' : ''}${originalMinutesRemainder.toString()}m`}
            </span>
          </div>
        </div>
        <div className="ml-8">
          {isAdmin && <ClickAwayListener
            onClickAway={handleCloseMenu}
          >
            <div>
              <Icon icon="ThreeDots"
                aria-label="More"
                aria-owns={openPoper ? 'goal-option' : undefined}
                aria-haspopup="true"
                style={{ fill: 'rgba(51, 51, 51, 0.8)' }} className='h-4 cursor-pointer'
                onClick={handleOpenMenu}
              />
              <Popper
                open={!!anchorElement && openPoper}
                anchorEl={anchorElement}
                transition
                disablePortal
                onClose={handleCloseMenu}
                className='z-50'
                placement='left-start'
              >
                {({ TransitionProps }) => (
                  <Grow
                    {...TransitionProps}
                  >
                    <div className='rounded w-20' style={{ background: '#ffffff', border: '1px solid #D3D3D3' }}>
                      <MenuList>
                        <MenuItem
                          className='text-sm'
                          onClick={() => handleEditButtonClick(id)}
                          style={{ color: '#333333' }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          className='text-sm'
                          onClick={() => setDeleteConfirm(true)}
                          style={{ color: '#333333' }}

                        >
                          Delete
                        </MenuItem>
                      </MenuList>

                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          </ClickAwayListener>}
        </div>
      </div>
      <Modal open={deleteConfirm} onClose={() => setDeleteConfirm(false)} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='nucleos-core' style={{ outline: 'none' }}>
          <div className='mx-auto my-0 w-3/4'>
            <div className='flex flex-col justify-start items-center p-10 rounded-md' style={{ background: '#ffffff' }} >
              <div> <Icon icon="Cancel" className='h-14 w-14' style={{ fill: '#F83232' }} /> </div>
              <div className='text-2xl mt-6'>Are you sure ?</div>
              <div className='text-sm mt-3 text-center'>Are you sure you want to delete this goal?</div>
              <div className='flex items-center mt-10'>
                <Button style={{ color: '#333333' }} onClick={() => setDeleteConfirm(false)} className="mr-2.5" >Cancel</Button>
                <Button style={{ color: '#ffffff', background: '#F83232' }} onClick={() => {
                  handleDeleteButtonClick(id);
                  setDeleteConfirm(false);
                }}
                className="ml-2.5">Delete</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};

const CompletedGoals = (props) => {
  const { closeModal, goals, handleDeleteButtonClick, handleEditButtonClick, isAdmin, handleViewButtonClick } = props;

  return (
    <div style={{ outline: 'none' }}>
      <div className="nucleos-core">
        <div className='p-5 rounded-lg' style={{ background: '#FFFFFF' }}>
          <div className='flex justify-between items-center pb-3' style={{ borderBottom: '1px solid rgba(51, 51, 51, 0.2)' }} >
            <div className='text-2xl font-medium'>
              Completed Goals
            </div>
            <div className='flex cursor-pointer' onClick={closeModal}>
              <Icon icon="Close" />
              <span>Close</span>
            </div>
          </div>
          <div className="mt-4" style={{
            height: goals && goals.length > 7 ? window.screen.height * 0.6 : '100%',
            width: window.screen.width * 0.4,
            overflow: 'scroll'
          }} >
            {
              goals.map(g => <Goal
                name={g.name}
                minutes={g.minutes}
                minutesRemaining={g.minutesRemaining}
                applicationName={g.Application && (g.Application.name || g.Application.displayName)}
                logo={g.Application && (g.Application.logo || g.Application.icon)}
                id={g.id}
                handleDeleteButtonClick={handleDeleteButtonClick}
                handleEditButtonClick={handleEditButtonClick}
                isAdmin={isAdmin}
                handleViewButtonClick={handleViewButtonClick}
              />)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedGoals;
