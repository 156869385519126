import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, TextInput, Typography } from '@nucleos/core-ui';
import { Icon } from '../../../Components/Shared/Icon';
import { PRIMARY_GREEN } from '../../../constants/colors';

const createValidationSchema = (formFields) => {
  const shape = formFields.reduce((acc, field) => {
    acc[field.key] = yup.string().required(`${field.label} is required`);
    return acc;
  }, {});

  return yup.object().shape(shape);
};

const ApproveLicensePopup = ({ formFields = [], username, licenseId, onSubmitHandler, onCloseModal }) => {
  const validationSchema = createValidationSchema(formFields);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formFields.reduce((acc, field) => {
      acc[field.key] = '';
      return acc;
    }, {})
  });

  const closeModalHandler = () => {
    form.reset();
    onCloseModal();
  };

  return (
    <div className="nucleos-core">
      <div className="mx-auto my-0 w-full max-w-md">
        <div
          className="flex flex-col justify-start items-center p-10 rounded-md"
          style={{ background: '#ffffff' }}
        >
          <div>
            <Icon icon="CircleCheck"
              className="h-14 w-14"
              style={{ fill: PRIMARY_GREEN }}
            />
          </div>
          <div className="text-2xl my-5">Are you sure?</div>
          <Typography className="text-center mb-3">
            <span>Do you want to approve the license for <b>{username}</b>?</span>
          </Typography>
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              form.handleSubmit((data) =>
                onSubmitHandler(data, username, licenseId, onCloseModal)
              )(e);
            }}
          >
            {formFields.map((field) => (
              <div key={field.key} className='mt-3'>
                <Typography className="mb-1.5">
                  {field.label}
                  <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>*</span>
                </Typography>
                <Controller
                  name={field.key}
                  control={form.control}
                  render={({ field: formField, fieldState }) => (
                    <TextInput
                      {...formField}
                      placeholder={`Enter ${field.label}`}
                      label={field.label}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!fieldState.error}
                      fullWidth
                    />
                  )}
                />
              </div>
            ))}
            <div className="flex items-center justify-center mt-8">
              <Button onClick={closeModalHandler} className="mr-2.5">Cancel</Button>
              <Button color="primary" type="submit" variant="contained" className="ml-2.5">
                Yes, Approve
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveLicensePopup;
