import React, { useState } from 'react';
import NewGrievanceDetails from './NewGrievanceDetails';
import { Button, TextInput, Typography } from '@nucleos/core-ui';
import { useHistory } from 'react-router-dom';
import DataStore from '../../../../Stores/DataStore';

const AddRequest = ({ user, nextStep, grievance, loading }) => {
  const history = useHistory();
  const [request, setRequest] = useState('');
  const [authorization, setAuthorization] = useState('');
  const [signature, setSignature] = useState('');
  const [error, setError] = useState('');
  const { setCreateGrievanceSteps } = DataStore;
  const userFullName = user.firstName + ' ' + user.lastName;

  if (!grievance.category) {
    setCreateGrievanceSteps(1);
    return null;
  }

  const validatePage = () => {
    if (request === '') {
      return setError('Please enter grievance request details');
    }
    if (signature === '') {
      return setError('Please enter your digital signature');
    } else {
      if (signature.toLowerCase() !== userFullName.toLowerCase()) {
        return setError(
          'Please enter your correct full name for digital signature'
        );
      }
    }
    if (
      ['Psychiatric / Mental Services', 'Medical Services'].includes(
        grievance.category.title
      )
    ) {
      if (authorization === '') {
        return setError(
          'Please enter your medical or psychiatric grievance authorization'
        );
      } else {
        if (authorization.toLowerCase() !== userFullName.toLowerCase()) {
          return setError(
            'Please enter your correct full name for medical or psychiatric authorization'
          );
        }
      }
    }

    setError('');
    nextStep({
      request,
      signature,
      authorization
    });
  };

  return (
    <div className="user-update nucleos-core">
      <Typography variant="h1" className="mb-6">
        Grievance and Request for
      </Typography>
      <NewGrievanceDetails
        cellNumber={user.cellNumber}
        jailName={user.Facility ? user.Facility.name : ''}
        category={grievance.category || {}}
        requestorName={`${user.firstName} ${user.lastName}`}
      />
      <Typography className="mt-6">
        <span className="pr-2">
          <b>Grievance or Request </b>
        </span>{' '}
        (Be specific and include your resolution):*
      </Typography>
      <TextInput
        className="px-4 pt-4 pb-4 mt-4 border border-bg-gray-main w-full  rounded-lg"
        placeholder="Please enter your request details here....."
        value={request}
        onChange={(value) => setRequest(value.slice(0, 500))}
        multiline
        fullWidth
        helperText="Maximum of 500 characters are allowed."
        rows={10}
      />

      <Typography className="mt-6">
        <b>Digital Signature</b> - Please type your full name to sign this
        request:*
      </Typography>

      <div className="w-96">
        <TextInput
          id="digital-sign-input"
          className="mt-4 py-3 rounded-md"
          placeholder="Enter your full name"
          value={signature}
          onChange={(value) => setSignature(value)}
          fullWidth={false}
          helperText={`Name: ${user.firstName} ${user.lastName}`}
        />
      </div>

      {['Psychiatric / Mental Services', 'Medical Services', 'Dental'].includes(
        grievance.category.title
      ) && (
        <>
          <Typography className="mt-2">
            {grievance.category.title === 'Dental'
              ? <b>Dental Grievance Authorization:* </b>
              : <b>Medical and Psychiatric Grievance Authorization:* </b>
            }
          </Typography>
          <Typography>
            Please type your full name to sign this request to authorize Jail
            Health Services to disclose information in your record to the
            Sheriff’s Department, which pertains to this complaint:
          </Typography>
          <div className="w-96">
            <TextInput
              id="medical-auth-input"
              className="mt-4 py-3 rounded-md"
              placeholder="Enter your full name"
              value={authorization}
              onChange={(value) => setAuthorization(value)}
              fullWidth={false}
            />
          </div>
        </>
      )}

      <Typography color="error">{error}</Typography>

      <div className="mt-6 flex justify-between">
        <Button
          variant="outlined"
          className="bg-background-light"
          onClick={() => {
            setCreateGrievanceSteps(0);
            history.replace('/forms/grievances');
          }}
        >
          Cancel Request
        </Button>
        <Button
          variant="contained"
          color="primary"
          loading={loading}
          onClick={validatePage}
        >
          Submit Request
        </Button>
      </div>
    </div>
  );
};
export default AddRequest;
