import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Box, Divider, Typography } from '@mui/material';
import AddPodDropdown from './AddPodDropdown';
import { useMutation, useQuery } from 'react-query';
import Data from '../../../Middleware/Data';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import AvailabilityAndDailyLimitCard from './AvailabilityAndDailyLimitCard';
import PodLevelAvailabilityAccordion from './PodLevelAvailabilityAccordion';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import toast from 'react-hot-toast';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { AvailabilityAndDailyLimitCardContextProvider } from './AvailabilityAndDailyLimitCardContext';
import NavigationConfirmationDialog from './NavigationConfirmationDialog';
import * as _ from 'lodash-es';
import AddFacilityDropdown from './AddFacilityDropdown';
import FacilityLevelAvailabilityAccordion from './FacilityLevelAvailabilityAccordion';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import FacilityPodAvailabilityConflictDialog from './FacilityPodAvailabilityConflictDialog';

export default function AppAvailabilityAndLimits ({ appUid }) {
  const history = useHistory();
  const [confirmationDialogNode, openConfirmationDialog] = useConfirmationDialog();

  const userPermissions = useUserPermissions();
  const isDisabled = !userPermissions.canEditAppAvailabilityAndLimits();

  const [addedFacility, setAddedFacility] = useState(null);
  const [addedPod, setAddedPod] = useState(null);

  const appAvailabilityAndLimitsQuery = useQuery(
    ['AppAvailabilityAndLimits', appUid],
    () => Data.getAppAvailability(appUid),
    {
      enabled: !!appUid,
      refetchOnMount: true
    }
  );

  const facilityLevelAvailabilityAndLimitsQuery = useQuery(
    ['AppFacilityAvailability', appUid],
    () => Data.getApplicationFacilityAvailability(appUid),
    {
      enabled: !!appUid,
      refetchOnMount: true,
      select: (data) => {
        let restructuredData = [];

        if (Array.isArray(data)) {
          restructuredData = data.map((el) => ({
            ...el,
            Facility: {
              uid: el.Facility.id,
              name: el.Facility.name
            }
          }));
        }

        return restructuredData;
      }
    }
  );

  const podLevelAvailabilityAndLimitsQuery = useQuery(
    ['AppPodAvailability', appUid],
    () => Data.getApplicationPodAvailability(appUid),
    {
      enabled: !!appUid,
      refetchOnMount: true
    }
  );

  const updateAppAvailabilityAndLimitsMutation = useMutation(
    ({ availabilityDetails }) => Data.updateApplications(appUid, availabilityDetails).then(
      GenericErrorDetectorForMutations
    ),
    {
      onSuccess: () => {
        appAvailabilityAndLimitsQuery.refetch();

        toast.success('App availability updated successfully.');
      },
      onError: (err) => {
        console.error(err);

        toast.error('Failed to update app availability.');
      }
    }
  );

  const updateAppFacilityAvailabilityAndLimitsMutation = useMutation(
    ({ facilityId, availabilityDetails }) => Data.updateApplicationFacilityAvailability(appUid, facilityId, availabilityDetails).then(
      GenericErrorDetectorForMutations
    ),
    {
      onSuccess: () => {
        facilityLevelAvailabilityAndLimitsQuery.refetch();

        toast.success('App facility level availability updated successfully.');
      },
      onError: (err) => {
        console.error(err);

        toast.error('Failed to update app facility level availability.');
      }
    }
  );

  const updateAppPodAvailabilityAndLimitsMutation = useMutation(
    ({ podId, availabilityDetails }) => Data.updateApplicationPodAvailability(appUid, podId, availabilityDetails).then(
      GenericErrorDetectorForMutations
    ),
    {
      onSuccess: () => {
        podLevelAvailabilityAndLimitsQuery.refetch();

        toast.success('App pod level availability updated successfully.');
      },
      onError: (err) => {
        console.error(err);

        toast.error('Failed to update app pod level availability.');
      }
    }
  );

  const removeAppFacilityAvailabilityAndLimitsMutation = useMutation(
    (facilityId) => Data.removeApplicationFacilityAvailability(appUid, facilityId).then(
      GenericErrorDetectorForMutations
    ),
    {
      onSuccess: () => {
        facilityLevelAvailabilityAndLimitsQuery.refetch();

        toast.success('Successfully removed app facility level availability.');
      },
      onError: (err) => {
        console.error(err);

        toast.error('Failed to remove app facility level availability.');
      }
    }
  );

  const removeAppPodAvailabilityAndLimitsMutation = useMutation(
    (podId) => Data.removeApplicationPodAvailability(appUid, podId).then(
      GenericErrorDetectorForMutations
    ),
    {
      onSuccess: () => {
        podLevelAvailabilityAndLimitsQuery.refetch();

        toast.success('Successfully removed app pod level availability.');
      },
      onError: (err) => {
        console.error(err);

        toast.error('Failed to remove app pod level availability.');
      }
    }
  );

  const appAvailabilityAndLimits = useMemo(() => {
    let availability = {};

    if (
      appAvailabilityAndLimitsQuery.data &&
      appAvailabilityAndLimitsQuery.data.application &&
      Array.isArray(appAvailabilityAndLimitsQuery.data.application.ApplicationPodAvailabilities) &&
      appAvailabilityAndLimitsQuery.data.application.ApplicationPodAvailabilities[0] &&
      appAvailabilityAndLimitsQuery.data.application.ApplicationPodAvailabilities[0].availabilityJson
    ) {
      availability = appAvailabilityAndLimitsQuery.data.application.ApplicationPodAvailabilities[0].availabilityJson;
    }

    return availability;
  }, [appAvailabilityAndLimitsQuery.data]);

  const facilityLevelAvailabilityAndLimits = useMemo(() => {
    if (Array.isArray(facilityLevelAvailabilityAndLimitsQuery.data)) {
      return facilityLevelAvailabilityAndLimitsQuery.data.map((facilityLevelAvailability) => ({
        facilityDetails: facilityLevelAvailability.Facility,
        availabilityDetails: facilityLevelAvailability.availabilityJson
      }));
    }

    return [];
  }, [facilityLevelAvailabilityAndLimitsQuery.data]);

  const podLevelAvailabilityAndLimits = useMemo(() => {
    if (Array.isArray(podLevelAvailabilityAndLimitsQuery.data)) {
      return podLevelAvailabilityAndLimitsQuery.data.map((podLevelAvailability) => ({
        podDetails: podLevelAvailability.Pod,
        availabilityDetails: podLevelAvailability.availabilityJson
      }));
    }

    return [];
  }, [podLevelAvailabilityAndLimitsQuery.data]);

  const [triggerExit, setTriggerExit] = useState({
    ok: true,
    pathname: ''
  });
  const [navigationConfirmationDialogVisible, setNavigationConfirmationDialogVisible] = useState(false);
  const [appAvailabilityAndLimitsChanges, setAppAvailabilityAndLimitsChanges] = useState(appAvailabilityAndLimits);
  const [facilityLevelAvailabilityAndLimitsChanges, setFacilityLevelAvailabilityAndLimitsChanges] = useState(facilityLevelAvailabilityAndLimits);
  const [podLevelAvailabilityAndLimitsChanges, setPodLevelAvailabilityAndLimitsChanges] = useState(podLevelAvailabilityAndLimits);

  useEffect(() => {
    setAppAvailabilityAndLimitsChanges(appAvailabilityAndLimits);
  }, [appAvailabilityAndLimits]);

  useEffect(() => {
    setFacilityLevelAvailabilityAndLimitsChanges(facilityLevelAvailabilityAndLimits);
  }, [facilityLevelAvailabilityAndLimits]);

  useEffect(() => {
    setPodLevelAvailabilityAndLimitsChanges(podLevelAvailabilityAndLimits);
  }, [podLevelAvailabilityAndLimits]);

  const handleFacilityLevelAvailabilityAndLimitsChanges = useCallback((facilityAvailabilityAndLimit) => {
    setFacilityLevelAvailabilityAndLimitsChanges((current) => {
      const replica = [...current];

      const index = replica.findIndex((el) => el.facilityDetails.uid === facilityAvailabilityAndLimit.facilityDetails.uid);
      if (index >= 0) {
        replica[index] = facilityAvailabilityAndLimit;
      } else {
        replica.push(facilityAvailabilityAndLimit);
      }

      return replica;
    });
  }, []);

  const handlePodLevelAvailabilityAndLimitsChanges = useCallback((podAvailabilityAndLimit) => {
    setPodLevelAvailabilityAndLimitsChanges((current) => {
      const replica = [...current];

      const index = replica.findIndex((el) => el.podDetails.uid === podAvailabilityAndLimit.podDetails.uid);
      if (index >= 0) {
        replica[index] = podAvailabilityAndLimit;
      } else {
        replica.push(podAvailabilityAndLimit);
      }

      return replica;
    });
  }, []);

  useEffect(() => {
    let shouldBlockNavigation = false;
    if (
      !_.isEqual(appAvailabilityAndLimitsChanges, appAvailabilityAndLimits) ||
      !_.isEqual(facilityLevelAvailabilityAndLimitsChanges, facilityLevelAvailabilityAndLimits) ||
      !_.isEqual(podLevelAvailabilityAndLimitsChanges, podLevelAvailabilityAndLimits)
    ) {
      shouldBlockNavigation = true;
    }

    setTriggerExit((obj) => ({ ...obj, ok: !shouldBlockNavigation }));
  }, [
    appAvailabilityAndLimits,
    appAvailabilityAndLimitsChanges,
    facilityLevelAvailabilityAndLimits,
    facilityLevelAvailabilityAndLimitsChanges,
    podLevelAvailabilityAndLimits,
    podLevelAvailabilityAndLimitsChanges
  ]);

  useEffect(() => {
    if (triggerExit.ok && triggerExit.pathname) {
      history.push(triggerExit.pathname);
    }

    const unblock = history.block((location) => {
      setTriggerExit((obj) => ({ ...obj, pathname: location.pathname }));

      if (triggerExit.ok) {
        return true;
      } else {
        setNavigationConfirmationDialogVisible(true);
      }

      return false;
    });

    return () => {
      unblock();
    };
  }, [history, triggerExit]);

  const handleNavigationDialogOnClose = useCallback(() => {
    setNavigationConfirmationDialogVisible(false);

    setTriggerExit((obj) => ({ ...obj, pathname: '' }));
  }, []);

  const handleNavigationDialogOnCancel = useCallback(() => {
    setTriggerExit((obj) => ({ ...obj, ok: true }));
  }, []);

  const handleNavigationDialogOnOk = useCallback(() => {
    setTriggerExit((obj) => ({ ...obj, ok: false }));
  }, []);

  const addedFacilityIdsList = useMemo(() => {
    const facilityIdsList = [];

    if (addedFacility) {
      facilityIdsList.push(addedFacility.uid);
    }

    facilityLevelAvailabilityAndLimits.forEach((facilityLevelAvailability) => {
      facilityIdsList.push(facilityLevelAvailability.facilityDetails.uid);
    });

    return facilityIdsList;
  }, [addedFacility, facilityLevelAvailabilityAndLimits]);

  const addedPodIdsList = useMemo(() => {
    const podIdsList = [];

    if (addedPod) {
      podIdsList.push(addedPod.uid);
    }

    podLevelAvailabilityAndLimits.forEach((podLevelAvailability) => {
      podIdsList.push(podLevelAvailability.podDetails.uid);
    });

    return podIdsList;
  }, [addedPod, podLevelAvailabilityAndLimits]);

  const handleAddFacility = useCallback((facility) => {
    setAddedFacility(facility);
  }, []);

  const handleAddPod = useCallback((pod) => {
    setAddedPod(pod);
  }, []);

  const handleRemoveAppFacilityAvailability = useCallback((facilityId) => {
    if (addedFacility && facilityId === addedFacility.uid) {
      setAddedFacility(null);

      setFacilityLevelAvailabilityAndLimitsChanges((current) => {
        const replica = [...current];

        const index = replica.findIndex((el) => el.facilityDetails.uid === facilityId);
        if (index >= 0) {
          replica.splice(index, 1);
        }

        return replica;
      });
    } else {
      removeAppFacilityAvailabilityAndLimitsMutation.mutate(facilityId);
    }
  }, [addedFacility, removeAppFacilityAvailabilityAndLimitsMutation]);

  const handleRemoveAppPodAvailability = useCallback((podId) => {
    if (addedPod && podId === addedPod.uid) {
      setAddedPod(null);

      setPodLevelAvailabilityAndLimitsChanges((current) => {
        const replica = [...current];

        const index = replica.findIndex((el) => el.podDetails.uid === podId);
        if (index >= 0) {
          replica.splice(index, 1);
        }

        return replica;
      });
    } else {
      removeAppPodAvailabilityAndLimitsMutation.mutate(podId);
    }
  }, [addedPod, removeAppPodAvailabilityAndLimitsMutation]);

  // TODO -- Fix: Uncomment and check for extra re-renders.
  // console.log(podLevelAvailabilityAndLimits, facilityLevelAvailabilityAndLimits);

  const saveAvailabilityAndLimits = useCallback(async ({ facilityId, podId, availabilityDetails }) => {
    if (facilityId) {
      let shouldSave = true;

      if (addedFacility && addedFacility.uid === facilityId) {
        const overlappingPods = podLevelAvailabilityAndLimits.filter((e) => e.podDetails.FacilityId === facilityId).map((e) => e.podDetails.name);

        const isOverlapping = overlappingPods.length > 0;

        if (isOverlapping) {
          shouldSave = await openConfirmationDialog((close) => (
            <FacilityPodAvailabilityConflictDialog
              onClose={() => close(false)}
              onCancel={() => close(false)}
              onOk={() => close(true)}
              content={(
                <>
                  <Typography sx={{ display: 'inline' }} variant='body2' fontWeight={600}>{addedFacility.name} availability overlaps with {overlappingPods.map((podName) => podName).join(', ')}.</Typography>
                  <Typography sx={{ display: 'inline' }} variant='body2'>&nbsp;In this case, the&nbsp;</Typography>
                  <Typography sx={{ display: 'inline' }} variant='body2' fontWeight={600}>POD-level availability will be prioritized.</Typography>
                </>
              )}
            />
          ));
        }
      }

      if (shouldSave) {
        await updateAppFacilityAvailabilityAndLimitsMutation.mutateAsync({ facilityId, availabilityDetails });

        if (addedFacility && facilityId === addedFacility.uid) {
          setAddedFacility(null);
        }
      }
    } else if (podId) {
      let shouldSave = true;

      if (addedPod && addedPod.uid === podId) {
        let overlappingFacility = null;

        const overlappingFacilityLevelAvailability = facilityLevelAvailabilityAndLimits.filter((e) => e.facilityDetails.uid === addedPod.FacilityId);
        if (Array.isArray(overlappingFacilityLevelAvailability) && overlappingFacilityLevelAvailability.length > 0) {
          overlappingFacility = overlappingFacilityLevelAvailability[0].facilityDetails;
        }

        const isOverlapping = !!overlappingFacility;

        if (isOverlapping) {
          shouldSave = await openConfirmationDialog((close) => (
            <FacilityPodAvailabilityConflictDialog
              onClose={() => close(false)}
              onCancel={() => close(false)}
              onOk={() => close(true)}
              content={(
                <>
                  <Typography sx={{ display: 'inline' }} variant='body2' fontWeight={600}>{addedPod.name} availability overlaps with {overlappingFacility.name}.</Typography>
                  <Typography sx={{ display: 'inline' }} variant='body2'>&nbsp;In this case, the&nbsp;</Typography>
                  <Typography sx={{ display: 'inline' }} variant='body2' fontWeight={600}>POD-level availability will be prioritized.</Typography>
                </>
              )}
            />
          ));
        }
      }

      if (shouldSave) {
        await updateAppPodAvailabilityAndLimitsMutation.mutateAsync({ podId, availabilityDetails });

        if (addedPod && podId === addedPod.uid) {
          setAddedPod(null);
        }
      }
    } else {
      await updateAppAvailabilityAndLimitsMutation.mutateAsync({ availabilityDetails });
    }
  }, [
    addedFacility, addedPod,
    facilityLevelAvailabilityAndLimits,
    podLevelAvailabilityAndLimits,
    openConfirmationDialog,
    updateAppAvailabilityAndLimitsMutation,
    updateAppFacilityAvailabilityAndLimitsMutation,
    updateAppPodAvailabilityAndLimitsMutation
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <LoadingAndErrorHandler
        isLoading={appAvailabilityAndLimitsQuery.isLoading}
        isError={appAvailabilityAndLimitsQuery.isError}
        isSuccess={appAvailabilityAndLimitsQuery.isSuccess}
      >
        <Box>
          <Typography variant='h6' fontWeight={700}>App Level - Availability & Daily Limit</Typography>

          <Box sx={{ mt: 1 }}>
            <AvailabilityAndDailyLimitCardContextProvider>
              <AvailabilityAndDailyLimitCard
                id="app-level-availability-and-limits"
                isDisabled={isDisabled}
                availabilityDetails={appAvailabilityAndLimits}
                onUpdate={saveAvailabilityAndLimits}
                onChange={setAppAvailabilityAndLimitsChanges}
              />
            </AvailabilityAndDailyLimitCardContextProvider>
          </Box>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h6' fontWeight={700}>Facility Level - Availability & Daily Limit</Typography>

            {
              facilityLevelAvailabilityAndLimitsQuery.isLoading || isDisabled
                ? null
                : (
                  <AddFacilityDropdown addedFacilityIdsList={addedFacilityIdsList} onFacilityAdd={handleAddFacility} />
                )
            }
          </Box>

          <Divider />

          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <LoadingAndErrorHandler
              isLoading={facilityLevelAvailabilityAndLimitsQuery.isLoading}
              isError={facilityLevelAvailabilityAndLimitsQuery.isError}
              isSuccess={facilityLevelAvailabilityAndLimitsQuery.isSuccess}
            >
              <Box>
                <Alert severity='info' color='secondary'>
                  If Facility availability settings are not configured, the system will automatically fall back to the App level settings.
                </Alert>
              </Box>

              {
                !addedFacility && !facilityLevelAvailabilityAndLimits.length
                  ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
                      <Typography variant='h6' color='GrayText' fontWeight={700}>No Facilities added yet.</Typography>
                    </Box>
                  )
                  : null
              }

              {
                addedFacility
                  ? (
                    <FacilityLevelAvailabilityAccordion
                      isDisabled={isDisabled}
                      defaultExpanded={true}
                      facilityDetails={{ uid: addedFacility.uid, name: addedFacility.name }}
                      availabilityDetails={{}}
                      onRemove={handleRemoveAppFacilityAvailability}
                      onUpdate={saveAvailabilityAndLimits}
                      onChange={handleFacilityLevelAvailabilityAndLimitsChanges}
                    />
                  )
                  : null
              }

              {
                facilityLevelAvailabilityAndLimits.length > 0
                  ? (
                    facilityLevelAvailabilityAndLimits.map((facilityLevelAvailability) => (
                      <FacilityLevelAvailabilityAccordion
                        isDisabled={isDisabled}
                        key={facilityLevelAvailability.facilityDetails.uid}
                        facilityDetails={facilityLevelAvailability.facilityDetails}
                        availabilityDetails={facilityLevelAvailability.availabilityDetails}
                        onRemove={handleRemoveAppFacilityAvailability}
                        onUpdate={saveAvailabilityAndLimits}
                        onChange={handleFacilityLevelAvailabilityAndLimitsChanges}
                      />
                    ))
                  )
                  : null
              }
            </LoadingAndErrorHandler>
          </Box>
        </Box>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h6' fontWeight={700}>POD Level - Availability & Daily Limit</Typography>

            {
              podLevelAvailabilityAndLimitsQuery.isLoading || isDisabled
                ? null
                : (
                  <AddPodDropdown addedPodIdsList={addedPodIdsList} onPodAdd={handleAddPod} />
                )
            }
          </Box>

          <Divider />

          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <LoadingAndErrorHandler
              isLoading={podLevelAvailabilityAndLimitsQuery.isLoading}
              isError={podLevelAvailabilityAndLimitsQuery.isError}
              isSuccess={podLevelAvailabilityAndLimitsQuery.isSuccess}
            >
              <Box>
                <Alert severity='info' color='secondary'>
                  If POD availability settings are not configured, the system will automatically fall back to the default app-level settings.
                </Alert>
              </Box>

              {
                !addedPod && !podLevelAvailabilityAndLimits.length
                  ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
                      <Typography variant='h6' color='GrayText' fontWeight={700}>No PODs added yet.</Typography>
                    </Box>
                  )
                  : null
              }

              {
                addedPod
                  ? (
                    <PodLevelAvailabilityAccordion
                      isDisabled={isDisabled}
                      defaultExpanded={true}
                      podDetails={addedPod}
                      availabilityDetails={{}}
                      onRemove={handleRemoveAppPodAvailability}
                      onUpdate={saveAvailabilityAndLimits}
                      onChange={handlePodLevelAvailabilityAndLimitsChanges}
                    />
                  )
                  : null
              }

              {
                podLevelAvailabilityAndLimits.length > 0
                  ? (
                    podLevelAvailabilityAndLimits.map((podLevelAvailability) => (
                      <PodLevelAvailabilityAccordion
                        isDisabled={isDisabled}
                        key={podLevelAvailability.podDetails.uid}
                        podDetails={podLevelAvailability.podDetails}
                        availabilityDetails={podLevelAvailability.availabilityDetails}
                        onRemove={handleRemoveAppPodAvailability}
                        onUpdate={saveAvailabilityAndLimits}
                        onChange={handlePodLevelAvailabilityAndLimitsChanges}
                      />
                    ))
                  )
                  : null
              }
            </LoadingAndErrorHandler>
          </Box>
        </Box>

        <NavigationConfirmationDialog
          isOpen={navigationConfirmationDialogVisible}
          onClose={handleNavigationDialogOnClose}
          onCancel={handleNavigationDialogOnCancel}
          onOk={handleNavigationDialogOnOk}
        />

        {
          confirmationDialogNode
        }
      </LoadingAndErrorHandler>
    </Box>
  );
}
