import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';

import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';

import Data from '../../Middleware/Data';
import AuthenticationStore from '../../Stores/Authentication';

import EvolveIcon from '../../Assets/icons/evolve.svg';
import CourseContentLibraryIcon from '../../Assets/icons/course_content_library.svg';
import GrievancesIcon from '../../Assets/icons/grievances.svg';
import MessagesIcon from '../../Assets/icons/messenger_dashboard.svg';
import LearningRecordsIcon from '../../Assets/icons/learning_records.svg';

import { useGrievanceRole } from '../../hooks/useGrievancePermissions';
import { LoadingSpinner } from '@nucleos/core-ui';
import { Stack } from '@mui/material';
import useUserPermissions from '../../hooks/useUserPermissions';

const isAdmin = AuthenticationStore.userRole === 'admin';
const isFormsAdmin = AuthenticationStore.userRole === 'forms';
const evolveIntegrated = window.nucleosConfig.evolveIntegrated;

const featuresData = [
  {
    name: 'Messages',
    icon: MessagesIcon,
    link: isAdmin ? '/messenger/admin' : '/messenger'
  },
  {
    name: 'Grievances and Forms',
    icon: GrievancesIcon,
    link: isAdmin || isFormsAdmin ? '/forms/admin' : '/forms/grievances'
  },
  {
    name: 'All Applications',
    icon: CourseContentLibraryIcon,
    link: '/course-content-library'
  },
  evolveIntegrated && {
    name: 'Evolve (Edu & Case Management)',
    icon: EvolveIcon,
    link: '#'
  },
  {
    name: 'Learner Records',
    icon: LearningRecordsIcon,
    link: '/learner-records'
  }
].filter(Boolean);

const LearnerHome = () => {
  const grievanceRole = useGrievanceRole();
  const userPermissions = useUserPermissions();

  const featureFlags = {
    'Messages': userPermissions.canViewMessenger(),
    'Grievances and Forms': grievanceRole.isGrievanceEnabled()
  };

  const renderContent = (icon, name) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: '',
        justifyContent: 'space-around'
      }}
    >
      <p style={{ color: '#3899FF', display: 'inline' }}>
        <img src={icon} alt="" />
      </p>
      <h2 style={{ paddingLeft: '25px', fontSize: '18px' }}> {name} </h2>
    </div>
  );

  const vantageSSOLoginMutation = useMutation(Data.vantageSSOLogin);
  const ssoVantageLogin = () => vantageSSOLoginMutation.mutate(AuthenticationStore.uid, {
    onSuccess: (data) => {
      if (data && data.ssoLoginLink) {
        window.location.assign(data.ssoLoginLink);
      }
    }
  });

  if (grievanceRole.isLoading || vantageSSOLoginMutation.isLoading) {
    return (
      <div className="nucleos-core">
        <div className='h-96 flex justify-center items-center'>
          <LoadingSpinner size={12} />
        </div>
      </div>
    );
  }

  const features = featuresData.filter(({ name }) =>
    featureFlags[name] === undefined ? true : featureFlags[name]
  ).map(({ name, external, icon, link }, i) =>
    external
      ? (
        <a
          key={name}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderContent(icon, name)}
        </a>
      )
      : (
        <Link to={link} key={i} onClick={evolveIntegrated ? ssoVantageLogin : undefined}>
          {renderContent(icon, name)}
        </Link>
      )
  );

  return (
    <Stack gap={12}>
      <FlexTileGrid tileStyle={{ padding: '16px' }}>
        {features}
      </FlexTileGrid>
    </Stack>
  );
};

export default (observer(LearnerHome));
