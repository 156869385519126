import React, { useCallback } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import { Icon } from '../../../Components/Shared/Icon';
import AvailabilityAndDailyLimitCard from './AvailabilityAndDailyLimitCard';
import { AvailabilityAndDailyLimitCardContextProvider } from './AvailabilityAndDailyLimitCardContext';

export default function FacilityLevelAvailabilityAccordion ({
  sx,
  isDisabled,
  defaultExpanded,
  facilityDetails,
  availabilityDetails,
  onRemove,
  onUpdate,
  onChange
}) {
  const { uid: facilityId, name: facilityName } = facilityDetails;

  const handleRemove = useCallback((event) => {
    event.stopPropagation();

    onRemove(facilityId);
  }, [facilityId, onRemove]);

  const handleAvailabilityAndDailyLimitChange = useCallback((availability) => {
    onChange({
      facilityDetails,
      availabilityDetails: availability
    });
  }, [facilityDetails, onChange]);

  return (
    <Box sx={{ ...sx }}>
      <Accordion
        disableGutters
        defaultExpanded={defaultExpanded}
        elevation={0}
        sx={{ border: 1, borderRadius: 1, borderColor: '#ebebeb' }}
      >
        <AccordionSummary
          id={`panel-${facilityId}-header`}
          aria-controls={`panel-${facilityId}-content`}
          expandIcon={<Icon icon='ExpandMore' />}
          sx={{
            '& .MuiAccordionSummary-content': {
              m: 0
            }
          }}
        >
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 2 }}>
            <Box>
              <Typography fontWeight={700}>{facilityName}</Typography>
            </Box>

            {
              !isDisabled
                ? (
                  <Box>
                    <Button color='error' onClick={handleRemove}>
                      <Icon icon='DeleteOutlined' sx={{ fontSize: 18 }} />
                      Remove
                    </Button>
                  </Box>
                )
                : null
            }
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 2, borderTop: 1, borderColor: '#ebebeb' }}>
          <AvailabilityAndDailyLimitCardContextProvider>
            <AvailabilityAndDailyLimitCard
              id={`${facilityId}-facility-level`}
              isDisabled={isDisabled}
              facilityId={facilityId}
              availabilityDetails={availabilityDetails}
              onUpdate={onUpdate}
              onChange={handleAvailabilityAndDailyLimitChange}
            />
          </AvailabilityAndDailyLimitCardContextProvider>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
