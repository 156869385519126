import React from 'react';

import { GroupContextProvider, useGroupContext } from './GroupContext';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { USER_PATH } from '../../constants/paths';
import GroupForm from '../../Components/GroupForm/GroupForm';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import GroupMembersTable from './GroupMembersTable';
import useUserPermissions from '../../hooks/useUserPermissions';
import QuickActionsList from './QuickActionsList';

const breadcrumbLinks = (name) => [
  { href: USER_PATH, label: 'User & Group Settings' },
  { href: '#', label: name }
];

const _GroupLayout = () => {
  const groupCTX = useGroupContext();

  const userPermissions = useUserPermissions();

  return (
    <div>
      <LoadingAndErrorHandler
        isError={groupCTX.isError}
        isLoading={groupCTX.isLoading}
        isSuccess={groupCTX.isSuccess}
      >
        {
          () => (
            <>
              <HeaderSubPanel
                sx={{ mb: 3 }}
                links={breadcrumbLinks(groupCTX.group.name)}
                title={groupCTX.group.name}
              />

              <Grid container spacing={4} justify="space-between">
                <Grid item xs={userPermissions.canDeleteGroup() ? 9 : 12}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Box>
                      <Card>
                        <CardContent>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant='h6' sx={{ fontSize: 18, fontWeight: 700 }}>
                              Group Details
                            </Typography>
                          </Box>

                          <GroupForm
                            groupId={groupCTX.groupId}
                            groupData={groupCTX.group}
                            onGroupCreateOrUpdate={groupCTX.refetchGroup}
                          />
                        </CardContent>
                      </Card>
                    </Box>

                    <Box>
                      <Card>
                        <CardContent>
                          <GroupMembersTable />
                        </CardContent>
                      </Card>
                    </Box>
                  </Box>
                </Grid>

                {
                  userPermissions.canDeleteGroup()
                    ? (
                      <Grid item xs={3}>
                        <QuickActionsList />
                      </Grid>
                    )
                    : null
                }
              </Grid>
            </>
          )
        }
      </LoadingAndErrorHandler>
    </div>
  );
};

export const GroupLayout = () => (
  <GroupContextProvider>
    <_GroupLayout />
  </GroupContextProvider>
);
