import React from 'react';
import PropTypes from 'prop-types';
import AuthenticationStore from '../../Stores/Authentication';
import RoleGuard from './RoleGuard';
import { rolesArr } from '../../constants/roles';
import { HeadlessLayout, Layout } from '../Layout';
import { CONSENT_PATH, LIVE_ACTIVITY_PATH } from '../../constants/paths';
import { Redirect, matchPath } from 'react-router-dom';

const hideSidebarRoutes = [];
const hideLayoutRoutes = [LIVE_ACTIVITY_PATH, CONSENT_PATH];

const AuthGuard = ({ children }) => {
  const loginHost = window.location.protocol + '//' + (window.location.host.startsWith('localhost') ? 'localhost:8082' : 'sp.' + window.location.host);
  const pathname = window.location.pathname;
  const path = Object.keys(rolesArr).find((path) => !!matchPath(pathname, { path, exact: true }));
  const roles = rolesArr[path];
  const hideSidebar = hideSidebarRoutes.includes(pathname);
  const hideLayout = hideLayoutRoutes.find((route) => pathname.startsWith(route));

  // Not Authenticated
  if (!AuthenticationStore.isAuthenticated) {
    // website root "/"" goes to SSO login flow
    if (pathname === '/') {
      // SSO Login
      localStorage.setItem('loginPath', '/');
      if (window.nucleosConfig.gsuite) {
        window.location.assign(`${loginHost}/login`);
      } else {
        window.location.assign('/login');
      }
      return <Redirect to="/login" />;
    }

    return <Redirect to="/login" />;
  }

  return children
    ? (
      <RoleGuard roles={roles}>
        {
          hideLayout
            ? (
              <HeadlessLayout>{children}</HeadlessLayout>
            )
            : (
              <Layout hideSidebar={hideSidebar}>{children}</Layout>
            )
        }
      </RoleGuard>
    )
    : null;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
