import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  TablePagination,
  styled,
  Box
} from '@mui/material';
import FlexTileGrid from '../../../Components/Shared/TileGrid/TileGrid';
import DataStore from '../../../Stores/DataStore';
import { formatDateTime } from '../../../Lib/util';
import { Input } from '../../../Components/Form';
import { useFormik } from 'formik';
import swal from 'sweetalert';
import { PAGE_ROW_LIMIT } from '../../../Lib/CONSTANTS';
import { Icon } from '../../../Components/Shared/Icon';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';
import { MESSENGER_ADMIN_PATH } from '../../../constants/paths';

const PREFIX = 'MessageAlerts';
const classes = {
  addForm: `${PREFIX}-addForm`,
  inputDiv: `${PREFIX}-inputDiv`,
  addInput: `${PREFIX}-addInput`,
  deleteBtn: `${PREFIX}-deleteBtn`
};

const Root = styled('div')(() => ({
  [`& .${classes.addForm}`]: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  [`& .${classes.inputDiv}`]: {
    width: '50%',
    marginLeft: '20px',
    marginRight: '20px'
  },
  [`& .${classes.addInput}`]: {
    backgroundColor: '#EBEBEB !important',
    borderStyle: 'none',
    width: '100%',
    margin: '0',
    borderRadius: '10px !important'
  },
  [`& .${classes.deleteBtn}`]: {
    padding: '10px 10px !important'
  }
}));

const breadcrumbLinks = [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: '#', label: 'Message Alerts' }
];

const MessageAlerts = () => {
  const [isLoading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const onSubmit = async (values, { setSubmitting }) => {
    await DataStore.addMessageAlertKeyword(values);
    swal('Keyword added successfully', {
      icon: 'success'
    });
    setSubmitting(false);
    handleReset();
    await getAllMessageAlertKeywords();
  };

  const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (!values.keyword) {
      errors.keyword = 'Keyword cannot be empty';
    } else if (/\s/g.test(values.keyword)) {
      errors.keyword = 'Keywords cannot have space';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      keyword: ''
    },
    onSubmit,
    validate
  });

  const {
    values,
    touched,
    errors,
    submitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset
  } = formik;

  const handleDelete = async (id, keyword) => {
    swal({
      text: `Are you sure you want to delete the keyword [${keyword}]?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        setLoading(true);
        const resp = await DataStore.deleteMessageAlertKeyword(id);
        if (resp.deleted === 1) {
          setLoading(false);
          await getAllMessageAlertKeywords();
          swal('Keyword deleted successfully', {
            icon: 'success'
          });
        }
      }
    });
  };

  const getAllMessageAlertKeywords = async () => {
    try {
      DataStore.setPageTitleDetail('');
      setLoading(true);
      const { keywords, count } = await DataStore.getMessageAlertKeywords(page);
      setKeywords(keywords);
      setTotalRecords(count);
      setLoading(false);
    } catch (error) {
      // TODO: console.log(error)
    }
  };

  useEffect(() => {
    getAllMessageAlertKeywords();
  }, [page]);
  return (
    <Box>
      <HeaderSubPanel title="Message Alerts" sx={{ mb: 3 }} links={breadcrumbLinks} />
      <Root>
        <Box sx={{
          boxShadow: '0px 10px 40px 0px rgb(64 72 82 / 7%)',
          width: '80vw',
          margin: 'auto'
        }}>
          <Box sx={{
            backgroundColor: '#E9F0FF',
            height: '51px',
            padding: '15px',
            borderRadius: '8px',
            fontWeight: 600
          }}>
            <p>Keyword Alert Triggers</p>
          </Box>
          <div>
            <form onSubmit={handleSubmit}>
              <div className={classes.addForm}>
                <p>Add Keyword</p>
                <div className={classes.inputDiv}>
                  <Input
                    name='keyword'
                    value={values.keyword}
                    touched={touched.keyword}
                    error={errors.keyword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    readOnly={submitting}
                    className={classes.addInput}
                    hideMargin
                  />
                </div>
                <Button
                  className='float-right-flex-btn'
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                + Add Keyword
                </Button>
              </div>
            </form>
          </div>
          <section>
            <FlexTileGrid
              gridColTiers={{ xs: 12 }}
              tileStyle={{ padding: 0, maxWidth: '100%', overflow: 'scroll' }}
              className='usage-history__table-container'
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Keyword</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Messages</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Last Triggered</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Date Created</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keywords.map((row, index) => (
                    <TableRow
                      key={row.id}
                      style={{
                        background: index % 2 ? 'white' : '#f8f8fb'
                      }}
                    >
                      <TableCell style={{ whiteSpace: 'nowrap' }}><Link style={{ color: '#449FFF', textDecoration: 'underline' }} to={`/messenger/admin/messageAlerts/${row.keyword}`}>{row.keyword}</Link></TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>{row.messagesCount}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>{row.lastTriggered ? formatDateTime(row.lastTriggered) : 'Never'}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDateTime(row.createdAt)}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}><IconButton className={classes.deleteBtn} disabled={isLoading} aria-label='delete' onClick={() => handleDelete(row.id, row.keyword)}>
                        <Icon icon='Delete' />
                      </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </FlexTileGrid>
          </section>
        </Box>
        <TablePagination component="div" count={totalRecords} rowsPerPage={PAGE_ROW_LIMIT} page={page} onPageChange={(event, nextPage) => {
          setPage(nextPage);
        }} rowsPerPageOptions={[10]}
        />
      </Root>
    </Box>
  );
};
export default (MessageAlerts);
