import React, { useEffect, useRef, useState } from 'react';
import ResponsiveContainer from '../../Components/Chart/BarChart/ResponsiveBarChart';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import './UserChart.scss';
import { COLORSET, minutesToHHMM } from '../../Components/Chart/helpers';
import SliderArrow from '../../Assets/slider-arrow.svg';
import { NoDataFound } from './NoDataFound';

const TotalAppTimeLabels = (props) => {
  const elementRef = useRef(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const { appTime, applicationWithColors, selectedApp, printMode } = props;
  const appTile = (app) => {
    if (!(selectedApp && selectedApp.length)) {
      return <div key={`app-tile-${app}`} className="font-normal text-sm mr-6" > <div className="" style={{ color: 'rgba(51, 51, 51, 0.8)', whiteSpace: 'nowrap' }} >All Apps</div> <div style={{ color: '#333333', whiteSpace: 'nowrap' }} >{minutesToHHMM(appTime[app])}</div>    </div>;
    } else {
      return <div key={`app-tile-${app}`} className="font-normal text-sm mr-6 rounded py-1 px-3" style={{ borderLeft: `3px solid ${applicationWithColors[app]}`, borderRadius: '4px' }} > <div className="" style={{ color: 'rgba(51, 51, 51, 0.8)', whiteSpace: 'nowrap' }} >{app}</div> <div style={{ color: '#333333', whiteSpace: 'nowrap' }} >{minutesToHHMM(appTime[app])}</div>    </div>;
    }
  };

  useEffect(() => {
    if (elementRef && elementRef.current) {
      const { scrollWidth, clientWidth } = elementRef.current;
      if (scrollWidth == clientWidth) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
    }
  }, [selectedApp]);

  const rightScrollHandler = (elementRef) => {
    elementRef.current.scrollLeft += 200;
    const { scrollWidth, clientWidth, scrollLeft } = elementRef.current;
    if (elementRef.current.scrollLeft != 0) {
      setHideLeftArrow(false);
    }
    if ((scrollLeft + clientWidth) >= scrollWidth) {
      setHideRightArrow(true);
    }
  };
  const leftScrollHandler = (elementRef) => {
    const { scrollWidth, clientWidth, scrollLeft } = elementRef.current;

    elementRef.current.scrollLeft -= 200;
    if (elementRef.current.scrollLeft == 0) {
      setHideLeftArrow(true);
    }

    if ((scrollLeft + clientWidth) <= scrollWidth) {
      setHideRightArrow(false);
    }
  };
  const keys = Object.keys(appTime);
  return <div className="nucleos-core">
    {
      printMode
        ? (
          <div className=" mb-12 ml-6 mt-5 mr-6" >
            <div className="app-tiles-scroll flex justify-start items-center" style={{ gap: '10px', flexWrap: 'wrap' }} >
              {
                keys.map(app => (
                  appTile(app)
                ))
              }
            </div>
          </div>
        )
        : (
          <div className=" mb-12 ml-6 mt-5 mr-6" style={{ position: 'relative' }} >
            {!hideLeftArrow && <img src={SliderArrow} className="absolute cursor-pointer" style={{ left: '-40px', bottom: '-30%', transform: 'rotate(180deg)' }} onClick={ () => leftScrollHandler(elementRef)} />}
            <div className="app-tiles-scroll flex justify-start items-center overflow-hidden" style={{ scrollBehavior: 'smooth' }} ref={elementRef} >
              {
                keys.map(app => (
                  appTile(app)
                ))
              }
            </div>
            {!hideRightArrow && <img src={SliderArrow} className="absolute cursor-pointer" style={{ right: '-40px', bottom: '-30%' }} onClick={ () => rightScrollHandler(elementRef)} />}
          </div>
        )
    }
  </div>;
};

const UserChart = ({
  userUsageTotals,
  loading,
  userApplications,
  selectedApp,
  printMode
}) => {
  const [applicationWithColors, setApplicationWithColors] = useState({});

  useEffect(() => {
    if (userApplications && userApplications.length) {
      const colorMappedApps = {};
      userApplications.forEach((app, i) => {
        colorMappedApps[app] = COLORSET[(i % COLORSET.length)]; // getRandomColor();
      });
      setApplicationWithColors(colorMappedApps);
    }
  }, [userApplications]);

  const data = userUsageTotals;

  let filteredData = data || [];
  const appTime = {};

  console.log('pre-processed chart data:', filteredData);

  if (selectedApp && selectedApp.length) {
    let updatedFilterData = [];
    const unqieDateKeys = {};
    filteredData.forEach(fd => {
      const keys = Object.keys(fd);

      selectedApp.forEach(sa => {
        if (keys.includes(sa.value)) {
          if (unqieDateKeys[fd.label]) {
            updatedFilterData = updatedFilterData.map(d => {
              if (d.label == fd.label) {
                if (appTime[`${sa.value}`] || appTime[`${sa.value}`] == 0) {
                  appTime[`${sa.value}`] = parseInt(fd[`${sa.value}`]) + parseInt(appTime[`${sa.value}`]);
                } else {
                  appTime[`${sa.value}`] = parseInt(fd[`${sa.value}`]);
                }
                return { ...d, [`${sa.value}`]: fd[`${sa.value}`] };
              } else {
                return d;
              }
            });
          } else {
            if (appTime[`${sa.value}`] || appTime[`${sa.value}`] == 0) {
              appTime[`${sa.value}`] = parseInt(fd[`${sa.value}`]) + parseInt(appTime[`${sa.value}`]);
            } else {
              appTime[`${sa.value}`] = parseInt(fd[`${sa.value}`]);
            }

            updatedFilterData.push({ label: fd.label, [`${sa.value}`]: fd[`${sa.value}`] });
            unqieDateKeys[fd.label] = fd.label;
          }
        }
      });
    });
    filteredData = updatedFilterData;
  }

  console.log('post-processed chart data:', filteredData);

  const filteredApplication = userApplications;

  if (filteredData && filteredData.length) {
    if (!(selectedApp && selectedApp.length)) {
      let totalTime = 0;
      filteredData.forEach(d => {
        const c = Object.keys(d);
        if (c && c.length) {
          c.forEach(cd => {
            if (!((cd == 'label') || (cd == 'date'))) {
              totalTime += d[cd];
            }
          });
        }
      });
      appTime.totalTime = totalTime;
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ height: 'auto', padding: 12, background: '#FFF', boxShadow: '0px 10px 40px 0px rgba(64, 72, 82, 0.10)', borderRadius: 12 }}>
        {loading
          ? (
            <LoadingComponent />
          )
          : (
            <div style={{ width: '100%' }}>
              {filteredData && filteredData.length
                ? (
                  <div style={{ padding: ' .8rem' }}>
                    <TotalAppTimeLabels
                      printMode={printMode}
                      appTime={appTime}
                      applicationWithColors={applicationWithColors}
                      selectedApp={selectedApp}
                    />
                    <div className="avoid-element-break">
                      <ResponsiveContainer
                        printMode={printMode}
                        data={filteredData}
                        applications={filteredApplication}
                        yLabel="Minutes"
                        xDataKey='label'
                        colorMapped={applicationWithColors}
                      />
                    </div>
                  </div>
                )
                : (
                  <NoDataFound />
                )}
            </div>
          )}
      </div>
    </div>
  );
};

export default UserChart;
