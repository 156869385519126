import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Message from './Message';
import DataStore from '../../Stores/DataStore';
import moment from 'moment';
import { Button, Checkbox, Divider, FormControlLabel, styled } from '@mui/material';
import Icon from './Icon';
import Authentication from '../../Stores/Authentication';
import ToastMessage from '../../Components/Widgets/ToastMessage';
import { Icon as NucleosIcon } from '../../Components/Shared/Icon';

const insertDividers = messages => {
  const dates = new Set(messages.map(({ createdAt }) => new Date(createdAt).toDateString()));
  return [...messages, ...Array.from(dates).map(date => ({ createdAt: new Date(date), divider: true }))];
};

const PREFIX = 'Messages';
const classes = {
  zoomBtns: `${PREFIX}-zoomBtns`,
  topRow: `${PREFIX}-topRow`,
  flexCenter: `${PREFIX}-flexCenter`
};

const Root = styled('div')(() => ({
  [`& .${classes.zoomBtns}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '25px'
  },
  [`& .${classes.topRow}`]: {
    display: 'flex', justifyContent: 'space-between'
  },
  [`& .${classes.flexCenter}`]: {
    display: 'flex', alignItems: 'center'
  }
}));

let globalId = null;
let loading = false;

const DEFAULT_FONT_SIZE = 14;
const FONT_SIZE_GAP_LIMIT = 5;

function Messages ({ user, scrollDown, resetScreen }) {
  const messagesEndRef = useRef(null);
  const { isStaffUser } = Authentication;
  const [canRespond, setCanRespond] = useState(user.canRespond);
  const [userResponseUpdateSuccess, setUserResponseUpdateSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [bottom, setBottom] = useState(false);
  const id = user ? user.id : null;
  const sortedMessages = [...(DataStore.messages[id] || [])].sort((a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  });
  const messages = insertDividers(sortedMessages);
  const initials = `${user.firstName[0]}${user.lastName[0]}`;
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const scrollToBottom = (behavior = 'smooth') => {
    if (!messagesEndRef) { return; }
    messagesEndRef.current.scrollIntoView({ behavior });
  };

  useEffect(() => {
    if (bottom) { setBottom(false); }
  }, [id]);

  useEffect(() => {
    if (globalId !== id) {
      globalId = id;
      return scrollToBottom('auto');
    }
    if (loading) {
      loading = false;
      return;
    }
    scrollToBottom();
  }, [DataStore.messages, id]);

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - (e.target.scrollTop + 150) > e.target.clientHeight;
    setBottom(bottom);
  };

  const loadMore = () => {
    DataStore.getMessageHistory(id);
    loading = true;
  };

  const handleUserResponseStatusChange = async (e) => {
    const status = e.target.checked;
    await DataStore.updateUserMessagingResponseStatus(status, user.id);
    setCanRespond(status);
    setSuccessMsg('User response status has been updated.');
    setUserResponseUpdateSuccess(true);
  };

  const handleDeactiveApprovedMessaging = async () => {
    await DataStore.deActivateApprovedMessage(user.id);
    setSuccessMsg('Contact has been removed from your list');
    setUserResponseUpdateSuccess(true);
    resetScreen();
  };

  const increaseFontSize = () => {
    if (fontSize < DEFAULT_FONT_SIZE + FONT_SIZE_GAP_LIMIT) {
      setFontSize(fontSize + 1);
    }
  };

  const decreaseFontSize = () => {
    if (fontSize > DEFAULT_FONT_SIZE - FONT_SIZE_GAP_LIMIT) {
      setFontSize(fontSize - 1);
    }
  };

  const { userRole } = Authentication;
  const isLearner = userRole === 'learner';

  return (
    <Root>
      <section className={classes.header}>
        {
          isStaffUser && <div className={classes.topRow}>
            <FormControlLabel
              classes={{ root: classes.fieldLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.checkbox }}
                  checked={canRespond}
                  value={canRespond}
                  color='primary'
                  onChange={handleUserResponseStatusChange}
                />
              }
              label={`Allow ${user.firstName} ${user.lastName} to respond`}
              labelPlacement='end'
            />
            <Button onClick={handleDeactiveApprovedMessaging} variant="contained" size="small" color="secondary">
              <NucleosIcon icon="NotInterested" /> &nbsp;Remove user as contact
            </Button>
          </div>
        }
        <Divider style={{ marginTop: '20px' }} />
        <section className={classes.topRow}>
          <div className={classes.flexCenter}>
            <Icon initials={initials} isUser={true} /> <span style={{ marginLeft: '5px', fontWeight: 600 }}>
              {user.UserTitle ? user.UserTitle.title : ''}{' '}
              {user.showFirstName === true || !isLearner ? user.firstName : ''}{' '}
              {user.lastName}
            </span>
          </div>
          <div className={classes.topRow}>
            <Button className={classes.zoomBtns} onClick={increaseFontSize}><NucleosIcon icon="ZoomIn" /> Bigger Font</Button>
            <Button className={classes.zoomBtns} onClick={decreaseFontSize}><NucleosIcon icon="ZoomOut" /> Smaller Font</Button>
          </div>
        </section>
      </section>
      <section onScroll={handleScroll} style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden', marginRight: '30px', paddingRight: '20px' }}>
        {
          DataStore.allMessagesLoaded[id] === true || DataStore.allMessagesLoaded[id] === undefined
            ? <div className='separator'>All messages loaded</div>
            : <Button onClick={loadMore}>Load More</Button>
        }
        {
          messages.map((message, i) => {
            if (message.divider && messages[i + 1]) {
              return (
                <div key={i} className="separator" style={{ fontSize: '10px' }}>
                  {moment(new Date(messages[i + 1].createdAt)).calendar(
                    new Date()
                  )}
                </div>
              );
            }
            return <Message fontSize={fontSize} key={i}>{message}</Message>;
          })
        }

        {
          bottom && <Button onClick={() => scrollToBottom()} style={{ position: 'fixed', bottom: '10vh', left: '50%' }}>Most Recent</Button>
        }
        <div ref={messagesEndRef} className='separator warning_separator' style={{ color: '#893100', maxWidth: '100%', fontSize: '10px', marginTop: '40px' }}>ALL MESSAGES ARE SUBJECT TO SURVEILLANCE BY SHERIFF’S OFFICE</div>
        <ToastMessage
          message={successMsg}
          open={userResponseUpdateSuccess}
          autoHideDuration={10000}
          onClose={() => setUserResponseUpdateSuccess(false)}
          type='success'
        />
      </section>
    </Root>
  );
}

export default (observer(Messages));
