import React, { useState, useRef, Fragment } from 'react';
import { observer } from 'mobx-react';
import './Messenger.css';
import { Data } from '../../Middleware/NucleosApi';
import { Button, Grid, Card, Stack, Box } from '@mui/material';
import { useFormik } from 'formik';
import { Input } from '../../Components/Form';
import Authentication from '../../Stores/Authentication';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import { useHistory } from 'react-router-dom';
import { ACTIVE_ROLES } from '../../Lib/CONSTANTS';
import { MESSAGE_USER_PATH, MESSENGER } from '../../constants/paths';

function AddContact ({ reloadContacts }) {
  const contactsResultRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [searchedContacts, setSearchedContacts] = useState(null);
  const history = useHistory();
  const isLearner = Authentication.userRole === 'learner';

  const formik = useFormik({
    initialValues: {
      l_name: '',
      f_name: '',
      sf_number: '',
      sf_booking_no: '',
      UserUid: Authentication.uid

    },
    onSubmit: async (values, { setSubmitting }) => {
      const response = await Data.searchContact(formik.values).then((x) => x.data);
      // filter admin accounts
      setSearchedContacts(response.filter(contact => !(contact.uid === Authentication.uid || contact.Role.name === ACTIVE_ROLES.EXTERNAL_PARTNER)));
      window.requestAnimationFrame(() => {
        if (contactsResultRef.current) {
          contactsResultRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          });
        }
      });
      setSubmitting(false);
    }
  });

  const enabled =
  formik.values.f_name.length > 0 ||
  formik.values.l_name.length > 0 ||
  formik.values.sf_number.length > 0 ||
  formik.values.sf_booking_no.length > 0;

  const handleOnClick = (UserUid) => {
    setLoading(true);
    const body = {
      UserUid,
      NucleosUserUid: Authentication.uid
    };

    Data.addContactMessenger(body).then((x) => x.error && alert(x.error));
    formik.handleSubmit();

    setLoading(false);
    reloadContacts();
    history.push(`${isLearner ? MESSENGER : MESSAGE_USER_PATH}/user/${UserUid}`);
  };

  return (
    <>
      <div style={{ marginTop: '50px', minWidth: '60%' }}>
        <h5>Find a person in</h5>

        <Card style={{ padding: '30px' }} className='sf_addContact'>
          <form onSubmit={formik.handleSubmit}>
            <label >Search by Name:</label>
            <p className='labelHelp'>Enter the first three letters of their first name and last name in the spaces provided.</p>
            <Stack spacing={2} marginLeft='28px' mb='20px' >
              <Input
                placeholder='First Name (first 3 letters)'
                name='f_name'
                onChange={formik.handleChange}
                value={formik.values.f_name}
                className='euf-email-input'
                hideMargin
                inputStyle={{ width: '340px' }}
              />
              <Input
                placeholder='Last Name (first 3 letters)'
                className='euf-email-input'
                name='l_name'
                onChange={formik.handleChange}
                value={formik.values.l_name}
                hideMargin
                inputStyle={{ width: '340px' }}
              />
            </Stack>
            <label>Search by {window.nucleosConfig.usernameLabel || 'Username'}:</label>
            <p className='labelHelp'>{window.nucleosConfig.usernameLabel || 'Username'} is a unique identifier within the Jail system.</p>
            <Box mb='20px' marginLeft="28px">
              <Input
                className='euf-email-input'
                placeholder={window.nucleosConfig.usernameLabel || 'Username'}
                name='sf_number'
                onChange={formik.handleChange}
                value={formik.values.sf_number}
                hideMargin
                inputStyle={{ width: '340px' }}
              />

            </Box>
            <label>Search by Booking Number:</label>
            <p className='labelHelp'>Enter the booking number in the space provided.</p>
            <Box mb='20px' marginLeft='28px'>
              <Input
                className='euf-email-input'
                placeholder='Booking Number'
                name='sf_booking_no'
                onChange={formik.handleChange}
                value={formik.values.sf_booking_no}
                hideMargin
                inputStyle={{ width: '340px' }}
              />
            </Box>

            <Grid container justify='flex-start'>
              <Grid item>
                <Button
                  style={{
                    padding: '5px 16px',
                    marginLeft: '28px'
                  }}
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={!enabled || formik.isSubmitting}
                >
                  Search
                </Button>

              </Grid>
            </Grid>
          </form>
        </Card>
        <div ref={contactsResultRef}>
          <Grid container className="addContact__bottomSection" >
            <Grid item xs={9}>
              <></>
            </Grid>
            <Grid item xs={3}>
              {searchedContacts && searchedContacts.length > 0 && <p className='heading'>Persons Found : {searchedContacts.length}</p> }
            </Grid>
            {searchedContacts && searchedContacts.length > 0 && searchedContacts.map((contact) => {
              return (
                <Fragment key={contact.uid}>
                  <Grid item xs={9} className="addContact__Column">
                    <p className='user__name'>{contact.firstName} {contact.lastName}</p>
                    <p className='user__id'>{window.nucleosConfig.usernameLabel || 'Username'}: {contact.username || 'Empty'} BOOKING ID: {contact.bookingID || 'Empty'}</p>
                  </Grid>
                  <Grid item xs={3} className="addContact__Column">
                    <Button
                      style={{
                        padding: '5px 16px'
                      }}
                      variant='contained'
                      color='primary'
                      type='button'
                      value={contact.uid}
                      disabled={isLoading}
                      onClick={() => handleOnClick(contact.uid)}
                    >
                      Request to Add Contact {isLoading && <LoadingComponent tiny={true} />}
                    </Button>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default observer(AddContact);
