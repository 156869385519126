import React from 'react';
import { Select } from '../../../../Components/Form';
import { styled } from '@mui/material';

const PREFIX = 'GrievanceReportSelect';
const classes = {
  grievanceSelect: `${PREFIX}-grievanceSelect`
};

const Root = styled('div')(() => ({
  [`& .${classes.grievanceSelect}`]: {
    'minWidth': '400px',
    '& .select-label': {
      textDecoration: 'auto !important',
      fontWeight: '600 !important',
      fontSize: '14px',
      lineHeight: '19px',
      color: '#333333'
    },
    '& .select-wrapper': {
      justifyContent: 'flex-start'
    },
    '& .nucleos-select': {
      border: '1px solid #D3D3D3 !important',
      borderRadius: '4px !important',
      background: '#ffffff !important'
    }
  }
}));

const GrievanceReportSelect = (props) => {
  const { selectedReport, handleChange, disabled } = props;
  const defaultReports = [{ label: 'Grievance Status Report', value: 'responseTimeAndStatusReport' }];
  return (
    <Root>
      <Select
        onChange={(e) => handleChange(e.target.value)}
        value={selectedReport}
        name="Grievance Report"
        options={[
          ...defaultReports.map((r) => ({
            label: r.label,
            value: r.value
          }))
        ]}
        className={classes.grievanceSelect}
        style={{ background: 'white' }}
        disabled={disabled}
      />
    </Root>
  );
};

export default (GrievanceReportSelect);
