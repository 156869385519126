import React, { useState } from 'react';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import './AutoComplete.scss';
import { Icon } from '../../Shared/Icon';

const Control = (props) => {
  return (
    <components.Control {...props} >
      <span >
        {<Icon icon="Search" className="search-icon" />}
      </span>
      {props.children}
    </components.Control>);
};

const Menu = (props) => {
  if (props.selectProps.inputValue.length === 0) { return null; }

  return (
    <div data-test-id="search-options-list">
      <components.Menu {...props} />
    </div>

  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div>
        No results found
      </div>
    </components.NoOptionsMessage>
  );
};

const AutoComplete = (props) => {
  const { placeholder, onSelect, className, classNamePrefix, label, isClearable, loadOptions, components, clearSelectedValue = false } = props;
  const [selectedValue, setSelectedValue] = useState(null);
  const onChangeHandler = (e) => {
    if (clearSelectedValue) {
      setSelectedValue(null);
    } else {
      setSelectedValue(e);
    }
    onSelect(e);
  };
  return (
    <>
      {label && <label className='user-search-label'>{label}:</label>}
      <AsyncSelect
        cacheOptions
        defaultOptions={false}
        loadOptions={loadOptions}
        components={{ ...components, Control, DropdownIndicator: () => null, IndicatorSeparator: () => null, NoOptionsMessage, Menu }}
        isClearable={isClearable}
        className={className}
        classNamePrefix={classNamePrefix}
        placeholder={placeholder}
        onChange={onChangeHandler}
        value ={selectedValue}
      />

    </>
  );
};

export default AutoComplete;
