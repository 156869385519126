import React, { useState } from 'react';
import {
  Typography,
  MultiSelectDropDown,
  Button,
  Dialog,
  TextInput
} from '@nucleos/core-ui';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Data from '../../Middleware/Data';
import { toast } from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { QueryKeys } from '../../Lib/query-keys';

const initialTemplateData = {
  GrievanceCategoryId: undefined,
  name: '',
  content: ''
};

function AddModifyTemplate ({ isOpen = false, onClose }) {
  const queryClient = useQueryClient();
  const [filterTemplatesCategoryId, setFilterTemplatesCategoryIdCategoryId] =
    useState(undefined);
  const [templateData, setTemplateData] = useState(initialTemplateData);
  const isUpdatingExistingTemplate = Boolean(templateData.id);

  const templateCategoriesQuery = useQuery(
    'TEMPLATE_CATEGORIES',
    () => Data.getAllFormTemplateCategories(),
    {
      select: ({ categories }) =>
        categories
          .map((c) => ({
            title: c.name + `${c.shortName ? ' - ' + c.shortName : ''}`,
            value: c.id,
            slug: c.name.toLowerCase().replace(/ /g, '-')
          }))
          .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
    }
  );
  const templatesQuery = useQuery(
    QueryKeys.GrievanceResponseTemplates.listing({
      categoryId: filterTemplatesCategoryId
    }),
    () => Data.getFormResponseTemplates(filterTemplatesCategoryId),
    {
      placeholderData: {
        templates: []
      }
    }
  );

  const createUpdateTemplateMutation = useMutation(
    () =>
      (isUpdatingExistingTemplate
        ? Data.updateFormResponseTemplate(templateData)
        : Data.addFormResponseTemplate(templateData)
      ).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        toast.success(
          `Template ${
            isUpdatingExistingTemplate ? 'updated' : 'created'
          } successfully.`
        );
        templatesQuery.refetch();
        queryClient.removeQueries(QueryKeys.GrievanceResponseTemplates.all());
      },
      onError: (err) => toast.error(err.message)
    }
  );

  return (
    <Dialog isOpen={isOpen} title="Templates" size="large" onClose={onClose}>
      <div className="flex pt-4">
        <div className="w-3/12 pr-4 border-r border-gray-main">
          <Button
            color="primary"
            className="mb-4"
            fullWidth
            onClick={() => setTemplateData(initialTemplateData)}
          >
            + Create New Template
          </Button>
          <Typography>Filter by Category</Typography>
          <MultiSelectDropDown
            dropdownId='add-modify-template-category-filter-select'
            testId="template-category-filter"
            disableHelperText
            disableMultiSelect
            onChange={([category]) => {
              if (!category) { return setFilterTemplatesCategoryIdCategoryId(undefined); }
              setFilterTemplatesCategoryIdCategoryId(category.value);
            }}
            value={(templateCategoriesQuery.data || []).filter(op => op.value === filterTemplatesCategoryId) || []}
            label={`All Categories (${
              templateCategoriesQuery.data
                ? templateCategoriesQuery.data.length
                : '-'
            })`}
            fullWidth
            options={templateCategoriesQuery.data || []}
          />

          <div style={{ maxHeight: 370 }} className="overflow-y-auto">
            {templatesQuery.data.templates.map((template) => (
              <Typography
                className="mt-3 w-full cursor-pointer"
                onClick={() => {
                  setTemplateData({
                    id: template.id,
                    name: template.name,
                    content: template.content,
                    GrievanceCategoryId: template.GrievanceCategoryId
                  });
                }}
              >
                {template.name}
              </Typography>
            ))}
          </div>
        </div>
        <div className="w-9/12 pl-4">
          <Typography>Template Name</Typography>
          <TextInput
            className="mb-2"
            value={templateData.name}
            onChange={(val) => setTemplateData({ ...templateData, name: val })}
            fullWidth
            disableHelperText
          />

          <Typography>Category</Typography>
          <MultiSelectDropDown
            dropdownId='add-modify-template-category-select'
            disableHelperText
            disableMultiSelect
            onChange={([category]) => {
              if (!category) { return; }
              setTemplateData({
                ...templateData,
                GrievanceCategoryId: category.value
              });
            }}
            value={(templateCategoriesQuery.data || []).filter(op => op.value === templateData.GrievanceCategoryId) || []}
            label="Choose category"
            fullWidth
            options={templateCategoriesQuery.data || []}
          />

          <Typography className="mt-2">Content</Typography>
          <TextInput
            className="mb-2"
            fullWidth
            value={templateData.content}
            onChange={(val) =>
              setTemplateData({ ...templateData, content: val })
            }
            disableHelperText
            rows={16}
            multiline
          />

          <div className="flex justify-between">
            <Button
              variant="contained"
              color="primary"
              onClick={() => createUpdateTemplateMutation.mutate()}
              loading={createUpdateTemplateMutation.isLoading}
              disabled={
                !templateData.content ||
                !templateData.name ||
                !templateData.GrievanceCategoryId
              }
            >
              {isUpdatingExistingTemplate ? 'Update' : 'Create'}
            </Button>
            <Button
              onClick={() =>
                createUpdateTemplateMutation.isLoading ? null : onClose()
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AddModifyTemplate;
