import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Box, Switch, styled, useTheme, Typography as MuiTypography, Button as MuiButton } from '@mui/material';
import ToastMessage from '../../Components/Widgets/ToastMessage';
import DataStore from '../../Stores/DataStore';
import AuthenticationStore from '../../Stores/Authentication';
import { appIconMap, checkCurrentTimeIsBetween, iconStyleMap } from '../../Lib/util';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '@nucleos/core-ui';
import useUserPermissions from '../../hooks/useUserPermissions';
import { Icon } from '../../Components/Shared/Icon';
import { COURSE_CONTENT_LIBRARY_PATH } from '../../constants/paths';
import moment from 'moment';
import { BLACK, BLUE, ERROR_RED } from '../../constants/colors';
import AvailabilityPopover from './AvailabilityPopover';

const PREFIX = 'CourseLibraryTile';
const classes = {
  switchBase: `${PREFIX}-switchBase`,
  granularIcon: `${PREFIX}-granularIcon`
};

const Root = styled('div')(() => ({
  [`& .${classes.switchBase}`]: {
    height: 'unset'
  },
  [`& .${classes.granularIcon}`]: {
    fontSize: '16.5px',
    marginRight: '4px'
  }
}));

const courseLibraryPropTypes = {
  app: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired
  }).isRequired
};

const CourseLibraryTile = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { userRole } = AuthenticationStore;
  const userPermissions = useUserPermissions();
  const canChangeActivationState = userPermissions.canEnableDisableApp();
  const { setApplicationActive, setApplicationActiveSuccess, setApplicationActiveError, setSetApplicationActiveSuccess, setSetApplicationActiveError } = DataStore;
  const { app } = props;
  const {
    displayName, name, description, uid, active, granular,
    availabilities,
    ApplicationPodAvailabilities = {},
    linkAddress
  } = app;
  const availabilityJson = availabilities || ApplicationPodAvailabilities;
  const timeRestricted = !!availabilityJson.appAvailability;
  const limitedUsage = !!availabilityJson.dailyLimit && !!availabilityJson.dailyLimit.minutesAllowed;
  const { minutesAllowed = 0 } = availabilityJson.dailyLimit || {};
  const timeZone = process.env.REACT_APP_TIMEZONE_IDENTIFIER;
  const currentDay = moment(moment(new Date()).tz(timeZone)).format('dddd');
  let isAppAvailableToday = !availabilityJson.appAvailability;

  const timeSlots = (availabilityJson.appAvailability || []).reduce((acc, availability) => {
    if (availability.allowedDays.includes(currentDay)) {
      isAppAvailableToday = true;
      return [...acc, ...availability.allowedTime];
    }
    return acc;
  }, []);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isAdmin = userRole === 'admin';

  function handleAppActivationSwitch (event, uid) {
    event.stopPropagation();
    const isEnabling = event.target.checked;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p>
              {isEnabling
                ? (
                  'If you enable the course it will start showing up for the learners. Are you sure you want to enable the course?'
                )
                : (
                  "If you disable the course, the learners won't be able to see or access it. Are you sure you want to disable the course?"
                )}
            </p>
            <div className="react-confirm-alert-button-group">
              <Button style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={() => { onClose(); }}>No</Button>
              <Button
                style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                onClick={() => {
                  setApplicationActive(uid, isEnabling);
                  onClose();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        );
      },
      overlayClassName: 'app-confirmation'
    });
  }

  function handleToastMessageclose (status) {
    const setAction = status === 'success' ? setSetApplicationActiveSuccess : setSetApplicationActiveError;
    setAction(null);
  }

  const successMessage = `${displayName || name} application has been ${active ? 'activated' : 'deactivated'}.`;
  const errorMessage = `${displayName || name} application could not be ${active ? 'deactivated' : 'activated'}. Please try again in a moment.`;

  const getTimeSlotsMessage = () => {
    if (!timeSlots) { return; }

    return timeSlots.map((timeSlot, index) => {
      const startTime = moment(timeSlot.startTime, 'HH mm').format('hh:mm A');
      const endTime = moment(timeSlot.endTime, 'HH mm').format('hh:mm A');

      if (index === 0) {
        return <>
          <span className={classes.appDetails}>{startTime}</span> -{' '}
          <span className={classes.appDetails}>{endTime}</span>
        </>;
      }

      return <>
        {index < timeSlots.length - 1 ? ', ' : ' and '}
        <span className={classes.appDetails}>{startTime}</span> -{' '}
        <span className={classes.appDetails}>{endTime}</span>
      </>;
    });
  };

  const isAppAvailableToUse = isAdmin
    ? true
    : timeRestricted
      ? checkCurrentTimeIsBetween(
        timeSlots
      )
      : true;

  return (
    <Root>
      <Box
        key={displayName || name}
        style={{
          cursor: isAppAvailableToUse && isAppAvailableToday ? 'pointer' : 'auto'
        }}
        onClick={() => {
          if (isAppAvailableToUse) {
            history.push(`${COURSE_CONTENT_LIBRARY_PATH}/${uid}`);
          }
        }}
      >
        <div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <div style={{ opacity: isAppAvailableToUse && isAppAvailableToday ? '1' : '0.5' }}>
              <img
                src={appIconMap(app)}
                style={iconStyleMap(app)}
                alt={displayName || name}
              />
            </div>
            {!isAdmin
              ? (
                <Box>
                  <Box maxWidth='230px'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifySelf: 'flex-end'

                      }}
                    >
                      {(timeRestricted || limitedUsage) && isAppAvailableToday &&
                       <>
                         {timeRestricted && (
                           <Box display='flex' gap='4px'>
                             <Icon icon="CircleCheck" style={{ fontSize: '15px' }} />
                             <MuiTypography style={{ marginBottom: 4, fontSize: '14px', fontWeight: '600', lineHeight: '15px', color: BLACK }}>
                      Today {' '} {getTimeSlotsMessage()}
                             </MuiTypography>
                           </Box>
                         )}
                         {limitedUsage && (
                           <Box display='flex' gap='4px' alignItems='center'>
                             <div>
                               <Icon icon="TimeRestrictedIcon" style={{ fontSize: '14px' }} />
                             </div>
                             <MuiTypography style={{ fontSize: '14px', fontWeight: '600', lineHeight: '15px', color: BLACK }}>
                      For{' '}
                               {minutesAllowed > 60
                                 ? `${Math.trunc(minutesAllowed / 60)} Hours ${minutesAllowed % 60
                                 } Mins`
                                 : `${minutesAllowed} ${minutesAllowed > 1 ? 'Mins' : 'Min'}`}
                             </MuiTypography>
                           </Box>
                         )}
                       </>
                      }
                      {timeRestricted && !isAppAvailableToday &&
                        <Box display="flex" gap='6px' alignItems='center'>
                          <Icon icon='Warning' sx={{ color: ERROR_RED, fontSize: '15px' }} />
                          <MuiTypography sx={{ fontSize: '14px', fontWeight: 600, color: ERROR_RED }}>Not available today</MuiTypography>
                        </Box>
                      }
                      {timeRestricted && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <MuiButton variant='text' sx={{ color: BLUE, height: '20px', width: '100px', cursor: 'pointer' }} fontSize="14px" onClick={(e) => {
                            handleClick(e);
                            e.stopPropagation();
                          }}
                          data-test-id="availability-btn"
                          aria-owns={open ? 'availability-popover' : undefined}
                          aria-haspopup="true"
                          >View Details</MuiButton>
                        </Box>
                      )}
                    </div>
                  </Box>
                </Box>
              )
              : null}
          </Box>
          <p style={{ opacity: isAppAvailableToUse && isAppAvailableToday ? '1' : '0.5' }}>
            <strong>{displayName || name}{linkAddress === '/under-construction' ? '*' : ''}</strong>
          </p>
          <p style={{ opacity: isAppAvailableToUse && isAppAvailableToday ? '1' : '0.5' }}>{description}</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: canChangeActivationState ? 'flex-end' : undefined,
            alignSelf: 'flex-start'
          }}
        >
          {canChangeActivationState && (
            <span
              style={{
                display: 'inline-block',
                fontSize: '10px',
                marginBottom: '3px',
                whiteSpace: 'nowrap'
              }}
            >
              <>
                <Switch
                  checked={active}
                  value="enabled"
                  color="primary"
                  classes={{
                    switchBase: classes.switchBase
                  }}
                  disabled={window.nucleosConfig.disableCourseUserAccessActions}
                  onClick={(event) => handleAppActivationSwitch(event, uid)}
                />
                <span style={{ marginLeft: '-5px' }}>
                  {' '}
                  {active ? 'Enabled' : 'Disabled'}{' '}
                </span>
              </>
            </span>
          )}
          {!!granular && isAdmin && (
            <p
              style={{
                color: '#ff4e00',
                display: 'flex',
                alignItems: canChangeActivationState ? 'flex-end' : undefined,
                flexWrap: 'nowrap',
                fontSize: '12px',
                fontWeight: 'bold',
                letterSpacing: '-0.02',
                whiteSpace: 'nowrap'
              }}
            >
              <Icon icon="Warning" classes={{ root: classes.granularIcon }} /> User access
              filter on
            </p>
          )}
          <p
            style={{ display: 'none', whiteSpace: 'nowrap' }}
            className="learning-records__record-light"
          >
            6 Jan. 2021
          </p>
        </div>
      </Box>
      <ToastMessage
        message={successMessage}
        open={
          setApplicationActiveSuccess && setApplicationActiveSuccess.uid === uid
        }
        autoHideDuration={4000}
        onClose={() => handleToastMessageclose('success')}
        type="success"
      />
      <ToastMessage
        message={errorMessage}
        open={
          setApplicationActiveError && setApplicationActiveError.uid === uid
        }
        autoHideDuration={4000}
        onClose={() => handleToastMessageclose('error')}
        type="error"
      />
      <AvailabilityPopover open={open} anchorEl={anchorEl} appAvailability={availabilityJson.appAvailability || []} handleClose={handleClose} />
    </Root>
  );
};

CourseLibraryTile.propTypes = courseLibraryPropTypes;

export default (observer(CourseLibraryTile));
