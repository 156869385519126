import { Button, Typography } from '@nucleos/core-ui';
import React, { useMemo, useState } from 'react';
import { getIconForSummary } from '../../Pages/Forms/Admin/Grievances/utils';
import { useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { formatDateTime } from '../../Lib/util';
import { useUserQuery } from '../../hooks/useUser';
import { ACTIVE_ROLES } from '../../Lib/CONSTANTS';
import { Link } from '@mui/material';
import useUserPermissions from '../../hooks/useUserPermissions';

const AttachmentList = ({ attachments }) => {
  const [expanded, setExpanded] = useState(false);

  const attachmentList = useMemo(() => {
    if (expanded) {
      return attachments;
    }

    return attachments.slice(0, 2);
  }, [attachments, expanded]);

  return (
    <div className="flex flex-col gap-y-1">
      {
        attachmentList.map((file) => (
          <Link
            key={file.uid}
            href={file.signedUrl}
            target="_blank"
            style={{ textDecorationLine: 'none' }}
          >
            <Typography className="text-xs" color="primary">
              {file.filename}
            </Typography>
          </Link>
        ))
      }

      {
        attachments.length > 2
          ? (
            <div>
              {
                expanded
                  ? (
                    <Button style={{ padding: 0 }} variant="text" onClick={() => setExpanded(false)}>
                      <Typography>Show less</Typography>
                    </Button>
                  )
                  : (
                    <Button style={{ padding: 0 }} variant="text" onClick={() => setExpanded(true)}>
                      <Typography>{`+${attachments.length - 2} more`}</Typography>
                    </Button>
                  )
              }
            </div>
          )
          : null
      }
    </div>
  );
};

export default function GrievanceInfo ({ grievance, hideAlertIcon, showDate }) {
  const permissions = useUserPermissions();
  const userProfile = useUserQuery();
  const rolesQuery = useQuery(['ALL_ROLE'], () => Data.getAllFormRoles(), {
    select: (data) => data.roles,
    placeholderData: { roles: [] }
  });
  const newRolesQuery = useQuery(
    ['NEW_ROLES', grievance.id],
    () => Data.getNewFormRoles(grievance.id),
    {
      select: (roles) =>
        roles.map((f) => ({
          title: f.label,
          value: f.id
        })),
      placeholderData: []
    }
  );

  return (
    <div>
      <div className="mt-4 mb-4">
        <Typography>Requestor Name</Typography>
        <Typography
          color="textMuted"
          variant="h6"
          className="capitalize"
        >{`${grievance.User.firstName} ${grievance.User.lastName}`}</Typography>
      </div>

      {!hideAlertIcon && (
        <div className="mt-2 mb-4">
          <Typography>Alert</Typography>
          {getIconForSummary({
            responseStatus: grievance.response_status,
            age: grievance.age,
            appealLevel: grievance.appeal_level
          })}
        </div>
      )}

      <div className="mt-2 mb-4">
        <Typography>Request Type</Typography>
        <Typography color="textMuted" variant="h6">
          {grievance.requestType === 'GRIEVANCE'
            ? 'Grievance'
            : 'Action Request'}
        </Typography>
      </div>

      <div className="mt-2 mb-4">
        <Typography>Category</Typography>
        <Typography color="textMuted" variant="h6">
          {grievance.GrievanceCategory.name}
        </Typography>
      </div>

      <div className="mt-2 mb-4">
        <Typography>Request #</Typography>
        <Typography color="textMuted" variant="h6">
          {grievance.requestNumber}
        </Typography>
      </div>

      <div className="mt-2 mb-4">
        <Typography>Assigned To</Typography>
        <Typography color="textMuted" variant="h6">
          {grievance.assignedToUser
            ? `${grievance.assignedToUser.UserTitle
              ? grievance.assignedToUser.UserTitle.title
              : ''
            } ${grievance.assignedToUser.showFirstName ||
              (userProfile.status === 'success' && userProfile.data.Role.name !== ACTIVE_ROLES.LEARNER)
              ? grievance.assignedToUser.firstName
              : ''
            } ${grievance.assignedToUser.lastName}`
            : grievance.AssignedToRole
              ? (
                rolesQuery.data.find(
                  (role) => role.uid === grievance.AssignedToRole
                ) || {}
              ).displayName
              : grievance.AssignedToConfigRole
                ? (
                  newRolesQuery.data.find(
                    (role) => role.value === grievance.AssignedToConfigRole
                  ) || {}
                ).title
                : '-'}
        </Typography>
      </div>

      <div className="mt-2 mb-4">
        <Typography>Jail</Typography>
        <Typography color="textMuted" variant="h6" className="capitalize">
          {grievance.Facility.name.toLowerCase()}
        </Typography>
      </div>

      <div className="mt-2 mb-4">
        <Typography>Cell Number</Typography>
        <Typography color="textMuted" variant="h6">
          {grievance.User.cellNumber || 'Not Available'}
        </Typography>
      </div>

      {
        permissions.canViewGrievanceAttachmentList() && Array.isArray(grievance.allAttachments) && grievance.allAttachments.length
          ? (
            <div className="mt-2 mb-4">
              <Typography>Attachments</Typography>
              <AttachmentList attachments={grievance.allAttachments} />
            </div>
          )
          : null
      }

      {showDate && (
        <div className="mt-2 mb-4">
          <Typography>Date</Typography>
          <Typography color="textMuted" variant="h6">
            {formatDateTime(grievance.createdAt)}
          </Typography>
        </div>
      )}
    </div>
  );
}
