import React, { useCallback, useMemo, useState } from 'react';
import NoResultFoundSVG from '../../Assets/graphics/table_no_data_found.svg';
import Data from '../../Middleware/Data';
import { useMutation, useQuery } from 'react-query';
import { SearchInput, Table } from '@nucleos/core-ui';
import { Box, Button, Checkbox, Dialog, DialogContent, IconButton, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import useUserPermissions from '../../hooks/useUserPermissions';
import { useGroupContext } from './GroupContext';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import toast from 'react-hot-toast';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import { Icon } from '../../Components/Shared/Icon';

const AddMembersDropdown = ({ groupId, onAddUser }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handlePopoverOpen = useCallback((e) => {
    setPopoverAnchorEl(e.currentTarget);
  }, []);

  const handlePopoverClose = useCallback((e) => {
    setPopoverAnchorEl(null);
  }, []);

  const eligibleGroupMembersQuery = usePaginatedQuery(
    [
      'ELIGIBLE_GROUP_MEMBERS',
      groupId,
      searchValue
    ],
    ({ pageParam = { limit: 20, offset: 0 } }) => {
      return Data.getEligibleGroupMembers({
        groupId,
        search: searchValue,
        limit: pageParam.limit,
        offset: pageParam.offset
      });
    },
    {
      enabled: !!groupId,
      refetchOnMount: true,
      listKeyName: 'rows',
      select: (data) => {
        return data.pages;
      }
    }
  );

  const addUserMutation = useMutation(({ groupId, userIds }) => Data.addUserToGroup(groupId, userIds).then(GenericErrorDetectorForMutations));

  const addUserToGroup = useCallback((userId) => {
    if (groupId) {
      addUserMutation.mutate({ groupId, userIds: [userId] }, {
        onSuccess: () => {
          setSearchValue('');
          setPopoverAnchorEl(null);

          toast.success('Successfully added user to the group.');

          eligibleGroupMembersQuery.refetch();
          onAddUser();
        },
        onError: (error) => toast.error(error.message || 'Failed to add user to the group.')
      });
    }
  }, [groupId, addUserMutation, eligibleGroupMembersQuery, onAddUser]);

  return (
    <div className="flex items-center">
      <Button
        color="primary"
        variant="contained"
        onClick={handlePopoverOpen}
      >
        + Add Users to Group
      </Button>

      <Popover
        onClose={handlePopoverClose}
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          elevation: 2,
          style: { width: 200, overflow: 'hidden' }
        }}
      >
        <div className="nucleos-core">
          <div className="p-2">
            <SearchInput
              placeholder="Search Group Name..."
              fullWidth
              value={searchValue}
              onSearch={(search) => setSearchValue(search)}
            />
          </div>

          <BottomScrollListener onBottom={() => !eligibleGroupMembersQuery.isFetching && eligibleGroupMembersQuery.fetchNextPage()} offset={20}>
            {(ref) => (
              <div ref={ref} style={{ maxHeight: 300, overflowY: 'scroll' }}>
                {
                  Array.isArray(eligibleGroupMembersQuery.data) && eligibleGroupMembersQuery.data.length
                    ? (
                      <MenuList dense>
                        {
                          eligibleGroupMembersQuery.data.map((user) => (
                            <MenuItem key={user.id} button onClick={() => addUserToGroup(user.uid)}>
                              {`${user.firstName} ${user.lastName}`}
                            </MenuItem>
                          ))
                        }
                      </MenuList>
                    )
                    : <Typography className="p-2" align="center" color="textMuted">No users found...</Typography>
                }
              </div>
            )}
          </BottomScrollListener>
        </div>
      </Popover>
    </div>
  );
};

const GroupMembersDeletionConfirmationDialog = ({ isBulkAction, bulkItemCount, userFullname, onClose, onCancel, onOk }) => {
  return (
    <Dialog
      open
      maxWidth='xs'
      onClose={onClose}
    >
      <DialogContent sx={{ p: 6 }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default
          })}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Icon icon="Cancel" sx={(theme) => ({ color: theme.palette.error.main, fontSize: 70 })} />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Typography variant='h5'>Are you sure?</Typography>

              {
                isBulkAction
                  ? (
                    <Typography variant='body2'>
                      You want to remove the selected {bulkItemCount} user(s) from the group? Once removed, they will no longer be part of this group.
                    </Typography>
                  )
                  : (
                    <Typography variant='body2'>
                      You want to remove the user {userFullname} from the group? Once removed, the user will no longer be part of this group.
                    </Typography>
                  )
              }
            </Box>

            <Box sx={{ display: 'flex', gap: 4 }}>
              <Button
                color='disabled'
                variant='outlined'
                onClick={onCancel}
              >
                Cancel
              </Button>

              <Button
                color='error'
                variant='contained'
                onClick={() => {
                  onOk();
                  onClose();
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const ColumnKeys = {
  Fullname: 'LEARNER_NAME',
  Username: 'USERNAME'
};

const DEFAULT_SORTING = {
  sortOrder: 'ASC',
  sortBy: ColumnKeys.Fullname
};

const GroupMembersTable = () => {
  const groupCTX = useGroupContext();

  const [confirmationDialogNode, openConfirmationDialog] = useConfirmationDialog();

  const userPermissions = useUserPermissions();

  const [columnSorting, setColumnSorting] = useState(DEFAULT_SORTING);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);

  const removeUserMutation = useMutation(({ groupId, userIds }) => Data.removeUserFromGroup(groupId, userIds).then(GenericErrorDetectorForMutations));

  const groupMembersQuery = useQuery(
    [
      'GroupMembers',
      groupCTX.groupId,
      page,
      columnSorting,
      recordsPerPage
    ],
    () =>
      Data.getGroupMembers({
        groupId: groupCTX.groupId,
        orderBy: columnSorting.sortBy,
        order: columnSorting.sortOrder,
        limit: recordsPerPage,
        offset: (page - 1) * recordsPerPage
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!groupCTX.groupId
    }
  );

  const groupMembersData = useMemo(() => {
    if (groupMembersQuery.data) {
      return ({
        count: groupMembersQuery.data.count,
        rows: groupMembersQuery.data.rows
      });
    }

    return ({
      count: 0,
      rows: []
    });
  }, [groupMembersQuery]);

  const removeUsersFromGroup = useCallback(async ({ userIds, userFullname, isBulkAction }) => {
    const shouldRemove = await openConfirmationDialog((close) => (
      <GroupMembersDeletionConfirmationDialog
        isBulkAction={isBulkAction}
        bulkItemCount={userIds.length}
        userFullname={userFullname}
        onClose={() => close(false)}
        onCancel={() => close(false)}
        onOk={() => close(true)}
      />
    ));

    if (!shouldRemove) {
      return;
    }

    removeUserMutation.mutate({ groupId: groupCTX.groupId, userIds }, {
      onSuccess: () => {
        toast.success('Successfully removed user(s) from the group.');

        groupMembersQuery.refetch();
        setSelectedRows([]);
      },
      onError: (error) => toast.error(error.message || 'Failed to remove user(s) from the group.')
    });
  }, [groupCTX, groupMembersQuery, removeUserMutation, openConfirmationDialog]);

  const handleColumnSorting = useCallback((sortBy, sortOrder) => {
    if (sortOrder === 'NONE' && sortBy !== DEFAULT_SORTING.sortBy) {
      return setColumnSorting(DEFAULT_SORTING);
    }

    if (sortOrder === 'NONE' && sortBy === DEFAULT_SORTING.sortBy) {
      return setColumnSorting({ ...DEFAULT_SORTING, sortOrder: 'ASC' });
    }

    setColumnSorting({ sortBy, sortOrder });
  }, []);

  const columns = [{
    title: (
      <div className="flex items-center">
        {
          userPermissions.canRemoveUserFromAGroup()
            ? (
              <Checkbox
                sx={{ p: 0.5 }}
                color="primary"
                checked={
                  groupMembersData.rows.length &&
                      groupMembersData.rows.every((i) => selectedRows.includes(i.uid))
                }
                indeterminate={
                  !groupMembersData.rows.every((i) => selectedRows.includes(i.uid)) &&
                      groupMembersData.rows.some((i) => selectedRows.includes(i.uid))
                }
                onClick={(e) => e.stopPropagation()}
                onChange={(e, checked) => {
                  e.stopPropagation();

                  if (checked) {
                    setSelectedRows(groupMembersData.rows.map((i) => i.uid));
                  } else {
                    setSelectedRows([]);
                  }
                }}
              />
            )
            : null
        }

        <span>Full Name</span>
      </div>
    ),
    render: (item) => (
      <div className="flex items-center">
        {
          userPermissions.canRemoveUserFromAGroup()
            ? (
              <Checkbox
                sx={{ p: 0.5 }}
                color="primary"
                checked={selectedRows.includes(item.uid)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e, checked) => {
                  e.stopPropagation();

                  if (checked) {
                    setSelectedRows([...selectedRows, item.uid]);
                  } else {
                    setSelectedRows(selectedRows.filter((i) => i !== item.uid));
                  }
                }}
              />
            )
            : null
        }

        <Typography>{`${item.firstName} ${item.lastName}`}</Typography>
      </div>
    ),
    enableSort: true,
    sortOrder: columnSorting.sortBy === ColumnKeys.Fullname ? columnSorting.sortOrder : 'NONE',
    onSortChange: (sortOrder) => handleColumnSorting(ColumnKeys.Fullname, sortOrder)
  }, {
    title: 'Username',
    render: (item) => <Typography>{item.username}</Typography>,
    enableSort: true,
    sortOrder: columnSorting.sortBy === ColumnKeys.Username ? columnSorting.sortOrder : 'NONE',
    onSortChange: (sortOrder) => handleColumnSorting(ColumnKeys.Username, sortOrder)
  }, {
    title: 'Account Type',
    render: (item) => (
      <Typography>
        {
          item.ConfigRole
            ? item.ConfigRole.label
            : item.Role
              ? item.Role.displayName
              : '-'
        }
      </Typography>
    ),
    enableSort: false
  }];

  if (userPermissions.canRemoveUserFromAGroup()) {
    columns.push({
      title: 'Action',
      render: (item) => {
        return (
          <IconButton onClick={() => removeUsersFromGroup({ userIds: [item.uid], userFullname: `${item.firstName} ${item.lastName}` })}>
            <Icon icon="CloseRounded" sx={(theme) => ({ color: theme.palette.error.main })} />
          </IconButton>
        );
      }
    });
  }

  return (
    <Box>
      <LoadingAndErrorHandler
        isLoading={groupMembersQuery.isLoading}
        isSuccess={groupMembersQuery.isSuccess}
        isError={groupMembersQuery.isError}
      >
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Typography variant='h6' sx={{ fontSize: 18, fontWeight: 700 }}>
              {`Group Members (${groupMembersData ? groupMembersData.count : 0})`}
            </Typography>
          </Box>

          {
            selectedRows.length > 0
              ? (
                userPermissions.canRemoveUserFromAGroup()
                  ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => removeUsersFromGroup({ userIds: selectedRows, isBulkAction: true })}
                      >
                        Remove from Group
                      </Button>
                    </Box>
                  )
                  : null
              )
              : (
                userPermissions.canAddUserToAGroup()
                  ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <AddMembersDropdown groupId={groupCTX.groupId} onAddUser={groupMembersQuery.refetch} />
                    </Box>
                  )
                  : null
              )
          }
        </Box>

        <Table
          columns={columns}
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />

              <Typography variant="h3">No members added yet!</Typography>
            </div>
          }
          loading={groupMembersQuery.isFetching}
          rowsData={groupMembersData ? groupMembersData.rows : []}
          pagination
          totalRecords={groupMembersData ? groupMembersData.count : 0}
          recordsPerPage={recordsPerPage}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={(np) => {
            setPage(np);
          }}
        />
      </LoadingAndErrorHandler>

      {
        confirmationDialogNode
      }
    </Box>
  );
};

export default GroupMembersTable;
