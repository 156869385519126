export const rolesList = [
  {
    label: 'Learners',
    value: 'learner.default'
  },
  {
    label: 'External Partners',
    value: 'admin.external'
  },
  {
    label: 'Staff',
    value: 'admin.staff'
  },
  {
    label: 'Super Admin',
    value: 'admin.superadmin'
  }
];

export const ROLES = {
  STUDENT: 'learner.default',
  TEACHER: 'teacher.default',
  PAROLE_OFFICER: 'parole.default',
  SUPER_ADMIN: 'admin.superadmin',
  FORMS_SUPER_ADMIN: 'forms.superadmin'
};

export const ACTIVE_ROLES = {
  LEARNER: 'learner.default',
  SUPER_ADMIN: 'admin.superadmin',
  FORMS_SUPER_ADMIN: 'forms.superadmin',
  STAFF_ADMIN: 'admin.staff',
  EXTERNAL_PARTNER: 'admin.external',
  INVESTIGATOR: 'admin.investigator',
  MESSENGER: 'admin.messenger'
};

export const ACTIVE_ROLES_DISPLAY_NAME = {
  'learner.default': 'Learner',
  'admin.superadmin': 'Admin',
  'forms.superadmin': 'Forms Admin',
  'admin.staff': 'Staff Admin',
  'admin.external': 'External Partner',
  'admin.investigator': 'Investigator',
  'admin.messenger': 'Messenger'
};

export const GRIEVANCE_RESPONSE_STATUS = {
  SATISFIED: 'SATISFIED',
  AUTO_ESCALATED: 'AUTO_ESCALATED',
  DISSATISFIED: 'DISSATISFIED',
  CATEGORY_CHANGE: 'CATEGORY_CHANGE'
};

export const GRIEVANCE_STATUS = {
  NEW: 'NEW',
  SATISFIED: 'SATISFIED',
  DISSATISFIED: 'DISSATISFIED',
  PENDING_FROM_LEARNER: 'PENDING_FROM_LEARNER',
  COMPLETED: 'COMPLETED'
};

export const roleDisplayNames = {
  teacher: 'External Partner',
  parole: 'Parole Officer',
  learner: 'Student',
  admin: 'Admin',
  forms: 'Forms Admin'
};

export const years = [
  {
    label: 'Kindergarten'
  },
  {
    label: 'First'
  },
  {
    label: 'Second'
  },
  {
    label: 'Third'
  },
  {
    label: 'Fourth'
  },
  {
    label: 'Fifth'
  },
  {
    label: 'Sixth'
  },
  {
    label: 'Seventh'
  },
  {
    label: 'Eighth'
  },
  {
    label: 'Freshman'
  },
  {
    label: 'Sophomore'
  },
  {
    label: 'Junior'
  },
  {
    label: 'Senior'
  }
].map((year, value) => ({ ...year, value: value + '' })).concat([{
  label: 'Unassigned',
  value: '-1'
}]);

export const passwordMinimumLength = 7;
export const PAGE_ROW_LIMIT = 10;
export const APPROVAL_STATUS = {
  UNAPPROVED: 'unapproved',
  APPROVED: 'approved',
  DECLINED: 'declined'
};

export const APPEAL_LEVELS = {
  INITIAL_REQUEST: { displayName: 'Initial Request', apiName: 'INITIAL_REQUEST' },
  APPEAL_LEVEL_1: { displayName: 'Appeal 1', apiName: 'LEVEL_1' },
  APPEAL_LEVEL_2: { displayName: 'Appeal 2', apiName: 'LEVEL_2' }
};

export const TIMINGS = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM'
];

export const COURSE_METRICS_FORMATS = {
  CIRCULAR_PROGRESS: 'circular-percent',
  PERCENT: 'percent',
  DURATION: 'duration',
  STATUS: 'status',
  NUMBER: 'number',
  STRING: 'string'
};

export const COURSE_STATUS = {
  COMPLETED: 'Completed',
  STARTED: 'Started',
  INACTIVE: 'Inactive',
  NOT_STARTED: 'Not Started',
  LOCKED: 'Locked',
  WITHDRAWN: 'Withdrawn'
};

export const PLAYER_PLAYBACK_ERRORS = {
  NO_DATA_FOUND: 100
};

export const PLAYER_PLAYBACK_SPEED_OPTIONS = [
  {
    title: '1x',
    value: 1
  },
  {
    title: '2x',
    value: 2
  },
  {
    title: '4x',
    value: 4
  },
  {
    title: '8x',
    value: 8
  },
  {
    title: '16x',
    value: 16
  }
];

export const NOTIFICATIOINS_TYPES = {
  ActionRequest: 'ACTION_REQUEST',
  Grievance: 'GRIEVANCE',
  Message: 'MESSAGE'
};

export const NOTIFICATIOINS_FILTER_OPTIONS = [
  {
    title: 'All Notifications',
    value: ''
  },
  {
    title: 'Action Requests',
    value: NOTIFICATIOINS_TYPES.ActionRequest
  },
  {
    title: 'Grievance Requests',
    value: NOTIFICATIOINS_TYPES.Grievance
  },
  {
    title: 'Messages',
    value: NOTIFICATIOINS_TYPES.Message
  }
];

export const DEFAULT_APP_CONFIG = {
  sidebarOpen: true
};
