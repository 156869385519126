import React from 'react';
import ResponsiveBarChart from '../../../Components/Chart/BarChart/ResponsiveBarChart';
import { Card, Typography } from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import { useHistory } from 'react-router-dom';
import Data from '../../../Middleware/Data';
import { getDays } from '../../../Lib/util';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { COLORSET } from '../../../Components/Chart/helpers';

export default function ActivityByDate () {
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();
  const preDefinedTimePeriod = getDays().find(
    (day) => day.val === GLRContext.timePeriod
  );

  const requestData = {
    groupId,
    type: 'all_application_totals_day',
    apps: GLRContext.apps,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2],
    timePeriod: preDefinedTimePeriod ? preDefinedTimePeriod.timePeriod : ''
  };
  const activityByDateQuery = useQuery(
    QueryKeys.GroupLearningRecords.listing(requestData),
    () => Data.getGroupLearningRecordReportData(requestData)
  );

  const data = activityByDateQuery.data ? activityByDateQuery.data.records : [];

  // List of applications
  const applications = activityByDateQuery.data
    ? activityByDateQuery.data.applications.map(app => app.name)
    : [];

  // Color mapping for applications (optional)
  const colorMapped = applications.reduce(
    (acc, app, currentIndex) => ({
      ...acc,
      [app]: COLORSET[currentIndex % COLORSET.length]
    }),
    {}
  );

  return (
    <div className="nucleos-core">
      <Card className="rounded-xl bg-white mt-12">
        <Card.Header style={{ borderWidth: 0 }} className="border-none">
          <Typography
            className="py-1.5 ml-2"
            variant="h4"
            style={{ fontWeight: 700, fontSize: '18px' }}
          >
            Activity By Date
          </Typography>
        </Card.Header>
        <Card.Body style={{ padding: 20 }}>
          <div>
            <LoadingAndErrorHandler
              isLoading={activityByDateQuery.isFetching}
              isError={activityByDateQuery.isError}
              isSuccess={activityByDateQuery.isSuccess}
            >
              {
                data.length > 0
                  ? (
                    <ResponsiveBarChart
                      data={data}
                      applications={applications}
                      yLabel="Minutes"
                      colorMapped={colorMapped}
                      xDataKey="label"
                      printMode={GLRContext.isPDFMode}
                      gridColor="#ECECEC"
                      xAxisLineColor="#ECECEC80"
                      yAxisLineColor="#ECECEC80"
                      xAxisTickColor="#0000"
                      yAxisTickColor="#0000"
                      yAxisLineMargin={10}
                    />
                  )
                  : (
                    <GenericNoDataFound className="p-10"/>
                  )
              }
            </LoadingAndErrorHandler>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
