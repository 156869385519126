import React from 'react';

export const BufferingOverlay = ({
  children,
  isBuffering
}) => {
  return (
    <div className="buffering-overlay-container">
      <div>
        {children}
      </div>
      {
        isBuffering
          ? (
            <div className='buffering-overlay'>
              <div className='dual-ring-loader' />
            </div>
          )
          : null
      }
    </div>);
};
