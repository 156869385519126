import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Divider, Button as MUIButton } from '@mui/material';
import { Typography, Chip, Button } from '@nucleos/core-ui';
import { useMutation } from 'react-query';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';

import DisableUserIcon from '../../../Assets/disable_user.svg';
import Data from '../../../Middleware/Data';
import { useUserContext } from '../UserContext';
import { getUserExternalStatusLabel } from '../../../Lib/util';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { Icon } from '../../../Components/Shared/Icon';

const ExternalStatusActivation = () => {
  const { uid: userId } = useParams();
  const userCTX = useUserContext();
  const userPermissions = useUserPermissions();
  const externalStatusChip = getUserExternalStatusLabel(userCTX.user.overrideStatus);

  const temporaryActivateUserMutation = useMutation(({ uids }) =>
    Data.temporaryActivateUser(uids).then(GenericErrorDetectorForMutations)
  );

  const onTemporaryActivateClick = () => {
    const mutation = temporaryActivateUserMutation;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="nucleos-core">
            <div className="mx-auto my-0 w-8/12">
              <div
                className="flex flex-col justify-start items-center p-10 rounded-md"
                style={{ background: '#ffffff' }}
              >
                <div>
                  <Icon icon="Cancel" className="h-14 w-14" style={{ fill: '#F83232' }} />
                </div>
                <div className="text-2xl mt-6">Are you sure?</div>
                <div className="text-sm mt-6">
                  <Typography className="text-center">
                    <span>
                      You want to temporarily activate user <u><b>{userCTX.user.firstName} {userCTX.user.lastName}</b></u>? Once the account is
                      activated, the learner can gain access to it.
                    </span>
                  </Typography>
                  <Typography className="text-center my-3" variant="body2">
                    <span>
                      <b>Note: </b>
                      User will be deactivated if External Activation Status is not updated to active within 24 hours.
                    </span>
                  </Typography>
                </div>
                <div
                  className="flex gap-x-6 items-center mt-8 w-full"
                  style={{ justifyContent: 'center' }}
                >
                  <Button style={{ color: '#333333' }} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    style={{ backgroundColor: '#4C741F' }}
                    variant="contained"
                    onClick={() => {
                      const id = toast.loading('Activating the user');
                      mutation.mutate(
                        { uids: [userId] },
                        {
                          onSuccess: () => {
                            toast.success('User successfully Activated.');
                            toast.dismiss(id);
                            userCTX.refetchUser();
                          },
                          onError: () => {
                            toast.dismiss(id);
                            toast.error('Failed to activate the user.');
                          }
                        }
                      );
                      onClose();
                    }}
                  >
                    Activate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  const canTemporaryActivateUser = () => {
    return userPermissions.canActivateOtherUser() &&
      userCTX.user.overrideStatus === 'DEACTIVATED' &&
      !userCTX.user.extSiteStatusOverrideAt &&
      userCTX.user.active &&
      !userCTX.user.isBanned;
  };

  return (
    <>
      <Divider className="my-4" style={{ height: 1 }} />
      <Grid item>
        <Typography variant="h5" style={{ fontWeight: 700, marginBottom: '0.5rem' }}>
          External Activation Status
        </Typography>
      </Grid>
      <Chip
        testId={'user-external-status'}
        rounded="full"
        variant={'outlined'}
        style={{ width: 'max-content' }}
        label={externalStatusChip.label}
        color={externalStatusChip.color}
      />
      {canTemporaryActivateUser() && (
        <>
          <MUIButton
            size="small"
            onClick={() => onTemporaryActivateClick()}
            className="my-1"
            style={{
              color: '#449FFF',
              textAlign: 'left',
              fontSize: 14,
              margin: '0.5rem 0'
            }}
          >
            <img src={DisableUserIcon} style={{ marginRight: 8 }} />
            Temporary Activate
          </MUIButton>
          <Typography>
            <span>
              <b>Note:</b> You can temporarily activate the user for 24 hours. If the External Activation Status isn&apos;t updated to active within this timeframe, the user will be deactivated again.
            </span>
          </Typography>
        </>
      )}
      {userCTX.user.overrideStatus === 'TEMP_ACTIVE' && (
        <Typography className="my-2">Temporarily activated for 24 hours.</Typography>
      )}
    </>
  );
};

export default ExternalStatusActivation;
