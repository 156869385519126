import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner, Typography } from '@nucleos/core-ui';
import { IconButton, ListItemSecondaryAction, MenuItem, styled } from '@mui/material';
import { formatDateTime } from '../../Lib/util';
import parse from 'html-react-parser';
import { NOTIFICATIOINS_TYPES } from '../../Lib/CONSTANTS';

import ActionRequestIcon from '../../Assets/action_request_notification_icon.svg';
import GrievanceRequestIcon from '../../Assets/grievance_notification_icon.svg';
import MessageIcon from '../../Assets/message_notification_icon.svg';
import { useMutation } from 'react-query';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { Icon } from '../../Components/Shared/Icon';

const PREFIX = 'NotificationTile';
const classes = {
  action: `${PREFIX}-action`,
  notification: `${PREFIX}-notification`,
  selected: `${PREFIX}-selected`,
  border: `${PREFIX}-border`
};

const Root = styled('div')(() => ({
  [`& .${classes.action}`]: {
    visibility: 'hidden'
  },

  [`& .${classes.notification}`]: {
    'backgroundColor': 'white',

    '&:hover': {
      backgroundColor: 'white'
    },

    '&:hover $action': {
      visibility: 'inherit'
    }
  },

  [`& .${classes.selected}`]: {
    'backgroundColor': '#ECF5FF',

    '&:hover': {
      backgroundColor: '#ECF5FF'
    }
  },

  [`& .${classes.border}`]: {
    border: '1px solid lightgray'
  }
}));

const NotificationActions = {
  Archive: 'Delete Notification'
};

export const NotificationTile = (props) => {
  const history = useHistory();

  const { notification, markAsRead, archiveNotification, handleClose, isDropdownLayout } = props;

  const archiveNotificationMutation = useMutation((notificationId) => {
    return archiveNotification(notificationId).then(GenericErrorDetectorForMutations);
  });

  const handleNotificationClick = useCallback(async (notificationId, link) => {
    if (link) {
      markAsRead({ notificationId });

      history.push(link);

      if (handleClose) {
        handleClose();
      }
    }
  }, [history, markAsRead, handleClose]);

  const onActionClick = useCallback(async (actionName, notification) => {
    switch (actionName) {
    case NotificationActions.Archive:
      await archiveNotificationMutation.mutate(notification.id);
      break;

    default:
      break;
    }
  }, []);

  return (
    <Root className="nucleos-core">
      <MenuItem
        divider={isDropdownLayout}
        key={notification.id}
        style={{ height: 'auto' }}
        className={`${classes.notification} ${!notification.isRead && classes.selected} ${!isDropdownLayout && classes.border}`}
        onClick={() => handleNotificationClick(notification.id, notification.link)}
      >
        <div
          className='nucleos-core flex flex-auto'
          data-testid="notification-item"
          data-notification-id={notification.id}
          data-notification-link={notification.link}
        >
          {
            archiveNotificationMutation.isLoading
              ? (
                <div className="flex flex-auto justify-center">
                  <LoadingSpinner size={6} />
                </div>
              )
              : (
                <div className="flex gap-x-2">
                  <div style={{ flex: '0 0 40px' }}>
                    {
                      (() => {
                        let src = '';

                        if (notification.category === NOTIFICATIOINS_TYPES.ActionRequest) {
                          src = ActionRequestIcon;
                        } else if (notification.category === NOTIFICATIOINS_TYPES.Grievance) {
                          src = GrievanceRequestIcon;
                        } else if (notification.category === NOTIFICATIOINS_TYPES.Message) {
                          src = MessageIcon;
                        } else {
                          src = '';
                        }

                        return <img src={src} />;
                      })()
                    }
                  </div>

                  <div className="flex flex-col mr-4">
                    <div className="flex flex-col text-wrap">
                      <Typography variant="h5">
                        {notification.title}
                      </Typography>

                      {
                        notification.description
                          ? (
                            <div style={{ textWrap: 'wrap' }}>
                              {parse(notification.description)}
                            </div>
                          )
                          : null
                      }
                    </div>

                    <div>
                      <Typography color="gray">
                        {formatDateTime(notification.createdAt)}
                      </Typography>
                    </div>
                  </div>

                  <ListItemSecondaryAction className={classes.action}>
                    <IconButton onClick={(e) => {
                      e.stopPropagation();

                      onActionClick(NotificationActions.Archive, notification);
                    }} className='ml-3'>
                      <Icon icon="Close" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </div>
              )
          }
        </div>
      </MenuItem>
    </Root>
  );
};
