import { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParamsPagination ({
  defaultPage = 1,
  key = 'page'
} = {}) {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (!params.get(key)) { setPage(params.get(key) || defaultPage); }

    return () => {
      const params = new URLSearchParams(location.search);
      params.delete(key);
      history.replace({
        search: params.toString()
      });
    };
  }, []);

  const setPage = (page) => {
    const params = new URLSearchParams(location.search);
    params.set(key, String(page));
    history.replace({
      search: params.toString()
    });
  };

  return [Number(params.get(key) || defaultPage), setPage];
}
