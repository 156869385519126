import React, { useEffect, useState } from 'react';
import { Modal, Button, Box, useTheme } from '@mui/material';
import { Input, validateEmail } from '../Form';
import DataStore from '../../Stores/DataStore';
import ToastMessage from '../Widgets/ToastMessage';
import { Icon } from './Icon';

const styles = {
  bannerText: {
    'color': '#B87905',
    'fontSize': '14px',
    'lineHeight': '19px',
    'fontWeight': 400,
    '& span': {
      'textDecoration': 'underline',
      '&:hover': {
        color: '#449FFF',
        cursor: 'pointer'
      }
    }
  },
  alertIcon: {
    'marginRight': '10px',
    'position': 'relative',
    '& svg': {
      height: '15px',
      position: 'absolute',
      top: '1px',
      right: '-5px'
    }
  }
};

const EmailVerify = (props) => {
  const { self, resendVerificationEmail, showEmailVerification, setEmailVerification } = DataStore;
  const currentEmailAddress = self.emailAddresses && self.emailAddresses.length > 0 ? self.emailAddresses[0] : '';
  const [email, setEmail] = useState(currentEmailAddress || '');
  const [showEmailVerificationBanner, setShowEmailVerificationBanner] = useState(true);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [validationError, setValidationError] = useState({
    email: {
      error: false,
      message: ''
    }
  });
  const [isEmailVerificationSent, setIsEmailVerificationSent] = useState({
    status: null,
    message: ''
  });
  const theme = useTheme();
  useEffect(() => {
    setEmail(currentEmailAddress);
  }, [currentEmailAddress]);

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };

  const validate = () => {
    const response = validateEmail(email, true);
    if (response !== undefined) {
      setValidationError({
        email: {
          error: true,
          message: response == 'Required' ? response : 'Please enter a valid email address'
        }
      });
    } else {
      setValidationError({
        email: {
          error: false,
          message: ''
        }
      });
    }
    return response;
  };

  const sendEmailHandler = async () => {
    const validationResponse = validate();
    if (validationResponse == undefined) {
      const response = await resendVerificationEmail({ emailAddress: email });
      if (response.success) {
        setIsEmailVerificationSent({
          status: true,
          message: response.success
        });
      } else {
        const errRes = await response.errorMessage;
        setIsEmailVerificationSent({
          status: false,
          message: errRes.error
        });
      }
    }

    setOpenVerificationModal(false);
  };

  const modalCloseHandler = () => {
    setEmail(currentEmailAddress);
    setValidationError({
      email: {
        error: false,
        message: ''
      }
    });
    setIsEmailVerificationSent({
      status: null,
      message: ''
    });
    setOpenVerificationModal(false);
  };

  const closeBannerHandler = () => {
    localStorage.setItem('emailVerifyBannerClosed', true);
    setShowEmailVerificationBanner(false);
    setEmailVerification(false);
  };
  const { isBanner } = props;
  return (
    <>
      { <>
        {isBanner && showEmailVerification && showEmailVerificationBanner
          ? <Box sx={{
            background: '#FFF4E5',
            borderBottom: '1px solid #FFE4C0',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            position: 'relative',
            width: '100%'
          }}>
            <Box sx={styles.bannerText}>
              <Box component='span' sx={styles.alertIcon}><Icon icon="Report" /></Box>
            You have not yet verified your email address. Please <span onClick={() => setOpenVerificationModal(true)} >click here</span> to resend verification email.

            </Box>
            <Box sx={{
              position: 'absolute',
              right: '45px',
              top: '7px',
              color: '#333333',
              cursor: 'pointer'
            }} onClick={closeBannerHandler}><Icon icon="Close" /></Box>
          </Box>
          : null }
        {
          !self.emailVerified && !isBanner
            ? <Box sx={styles.bannerText}>
              <Box component='span' sx={styles.alertIcon}><Icon icon="Report" /></Box>
            Email address not verified. Please <span onClick={() => setOpenVerificationModal(true)} >click here</span> to resend verification email.
            </Box>
            : null
        }
      </> }
      <Modal open={openVerificationModal} onClose={modalCloseHandler} >
        <Box sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(0%, 100%)',
          background: '#FFFFFF',
          borderRadius: '8px',
          padding: '20px',
          width: '50%',
          margin: '0 auto',
          border: 'none',
          outline: 'none'
        }} >
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(51, 51, 51, 0.2)',
            paddingBottom: '12px'
          }}>
            <Box sx={{
              fontSize: '24px',
              lineHeight: '33px',
              fontWeight: 500,
              color: '#333333'
            }}>
              Send Verification Email
            </Box>
            <Box sx={{
              'display': 'flex',
              'alignItems': 'center',
              'cursor': 'pointer',
              'color': '#333333',
              '& span': {
                marginLeft: '3px'
              }
            }} onClick={modalCloseHandler}>
              <Icon icon="Close" />
              <span>Close</span>
            </Box>
          </Box>
          <Box sx={(theme) => ({
            'padding': '10px 0px',
            '& .input-wrapper': {
              justifyContent: 'flex-start'
            },
            '& .input-container': {
              margin: '8px 0px',

              [theme.breakpoints.down('sm')]: {
                width: '100%'
              },
              [theme.breakpoints.between('sm', 'md')]: {
                width: '80%'
              },
              [theme.breakpoints.between('sm', 'md')]: {
                width: '80%'
              }

            },
            '& .nucleos-input': {
              background: '#ffffff !important',
              border: '1px solid #D3D3D3 !important',
              color: '#333333 !important',
              width: '100%'
            },
            '& .nucleos-field-error': {
              margin: '5px 5px'
            }

          })}>
            <Box component='label' sx={{
              'color': 'rgba(51, 51, 51, 0.9)',
              'fontSize': '14px',
              'fontWeight': 400,
              'lineHeight': '19px',
              '& span': {
                color: '#F83232'
              }

            }} >Email Address <span>*</span></Box>
            <Input
              name='email'
              className={styles.emailInput}
              hideMargin={true}
              value={email}
              onBlur={validate}
              touched={validationError.email.error}
              error={validationError.email.message}
              onChange={emailChangeHandler}
            />
            <Box component='p' sx={{
              color: 'rgba(51, 51, 51, 0.6)',
              fontStyle: 'italic',
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 400
            }} >You will receive a email for a verification link on this email address, and click on it to verify your email address.</Box>
          </Box>
          <Box sx={{
            'display': 'flex',
            'justifyContent': 'space-between',
            'marginTop': '30px',
            '& button': {
              textTransform: 'capitalize',
              borderRadius: '4px',
              fontWeight: 400
            }
          }}>
            <Button sx={{
              border: '1px solid #D3D3D3',
              color: '#333333'
            }} onClick={modalCloseHandler} >Cancel</Button>
            <Button sx={{
              color: `${theme.palette.primary.contrastText}`,
              background: `${theme.palette.primary.main}`
            }} onClick={sendEmailHandler} >Send Email</Button>
          </Box>
        </Box>
      </Modal>

      {isEmailVerificationSent.status == true
        ? <ToastMessage
          autoHideDuration={2000}
          message={isEmailVerificationSent.message}
          onClose={() => setIsEmailVerificationSent({ status: null, message: '' })}
          open={isEmailVerificationSent.status}
          type='success'
        />
        : null}

      {isEmailVerificationSent.status == false
        ? <ToastMessage
          autoHideDuration={2000}
          message={isEmailVerificationSent.message}
          onClose={() => setIsEmailVerificationSent({ status: null, message: '' })}
          open={isEmailVerificationSent.status === false}
          type='error'
        />
        : null}
    </>
  );
};

export default (EmailVerify);
