import { useQuery } from 'react-query';
import Data from '../Middleware/Data';
import AuthenticationStore from '../Stores/Authentication';

export const useFacilitiesQuery = (refetchOnMount = false) =>
  useQuery('FACILITIES', () => Data.getAllFacilities(), {
    select: ({ facilities }) =>
      facilities.map((f) => ({
        title: f.name + `${f.shortName ? ' - ' + f.shortName : ''}`,
        value: f.id,
        ...f
      })),
    enabled: AuthenticationStore.isAuthenticated,
    refetchOnMount
  });
