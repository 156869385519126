import React from 'react';
import { observer } from 'mobx-react';
import { Paper, Grid } from '@mui/material';
import { Input } from '../../../../../Components/Form';
import { Link } from 'react-router-dom';
import { getUserStatusLabels } from '../../../../../Lib/util';

const MessageLearnerUser = ({ classes, user, sender }) => {
  if (!user) {
    return null;
  } else {
    return (
      <>
        <div className={classes.detailHeader}>
          <h2 style={{ textDecoration: 'underline' }}>{sender ? 'User' : 'Contact'} Details</h2>
          <Link className={classes.link} to={`/messenger/admin/users/${user.uid}`}>Profile</Link>
        </div>

        <Paper className={classes.paperBaseStyle} style={{ maxWidth: '100%' }}>
          <form className='user-form' method='post'>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      label='Username'
                      name='username'
                      value={user.username}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='First Name'
                      name='firstName'
                      value={user.firstName}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='Last Name'
                      name='lastName'
                      value={user.lastName}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      label={window.nucleosConfig.usernameLabel || 'Username'}
                      name='inmateId'
                      readOnly
                      value={user.inmateId}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='Booking ID'
                      name='bookingID'
                      readOnly
                      value={user.bookingID}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='User Status'
                      name='status'
                      value={getUserStatusLabels(user.active, user.isBanned).map(status => status.label).join(', ')}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='Messenger Status'
                      name='messengerStatus'
                      value={user.messengerActive ? 'Active' : 'Inactive'}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
};

export default observer(MessageLearnerUser);
