import React, { useCallback } from 'react';
import moment from 'moment';
import { Button, TextInput, Typography } from '@nucleos/core-ui';
import { Divider, IconButton, Switch } from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '../Shared/Icon';

const BAN_USER_FORM_INITIAL_STATE = {
  periodInDays: '',
  banIndefinitely: false,
  internalNote: '',
  noteForLearner: ''
};

const banUserFormSchema = yup.object().shape({
  periodInDays: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(1, 'Ban duration must be at least 1 day.')
    .integer('Ban duration must not contain decimal value.')
    .when('banIndefinitely', {
      is: (banIndefinitely) => !!banIndefinitely,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required('Please enter ban duration.')
    }),
  banIndefinitely: yup.boolean(),
  internalNote: yup
    .string()
    .max(500, 'Number of characters exceeded the limit of 500.'),
  noteForLearner: yup
    .string()
    .max(250, 'Number of characters exceeded the limit of 250.')
});

const BanUserModalContent = ({ userDisplayName, onClose, onSuccess }) => {
  const banUserForm = useForm({
    resolver: yupResolver(banUserFormSchema),
    defaultValues: BAN_USER_FORM_INITIAL_STATE,
    mode: 'onChange'
  });

  const handleClose = useCallback(() => {
    onClose();

    banUserForm.reset(BAN_USER_FORM_INITIAL_STATE);
  }, [onClose]);

  const handleBanLearnerFormSubmit = useCallback(
    ({ periodInDays, banIndefinitely, internalNote, noteForLearner }) => {
      onSuccess({
        periodInDays,
        banIndefinitely,
        internalNote,
        noteForLearner
      });

      handleClose();
    },
    [onSuccess, handleClose]
  );

  const timeZone = process.env.REACT_APP_TIMEZONE_IDENTIFIER;

  return (
    <div className="nucleos-core">
      <div className="mx-auto my-0 w-full">
        <div
          className="flex flex-col p-6 rounded-md"
          style={{ background: '#ffffff' }}
        >
          <div className="flex justify-between items-center">
            <div>
              <Typography variant="h3">Ban Learner</Typography>
            </div>

            <div>
              <IconButton onClick={handleClose}>
                <Icon icon="Close" />
              </IconButton>
            </div>
          </div>

          <Divider className="my-3" style={{ height: 1 }} />

          <div className="text-sm">
            Are you sure that you want to ban user <b>{userDisplayName}</b>?
            Once banned, the user will not be able to access their account.
          </div>

          <form
            className="flex flex-col gap-y-2 mt-6"
            onSubmit={(e) => {
              e.preventDefault();

              banUserForm.handleSubmit(
                (data) => handleBanLearnerFormSubmit(data),
                (error) => console.log(error)
              )(e);
            }}
          >
            <div className="flex gap-x-6">
              <div>
                <Typography className="mb-1">
                  {'Ban for (In days) '} <span className="text-red-600">*</span>
                </Typography>
                <Controller
                  name="periodInDays"
                  control={banUserForm.control}
                  render={({ field, fieldState, formState }) => (
                    <>
                      <TextInput
                        {...field}
                        type="number"
                        min={1}
                        step={1}
                        style={{ maxWidth: 250 }}
                        placeholder="e.g. 3"
                        onChange={(_, evt) => field.onChange(evt)}
                        value={field.value}
                        error={
                          !!fieldState.error &&
                          !banUserForm.watch('banIndefinitely')
                        }
                        helperText={(fieldState.error || {}).message}
                        disableHelperText={
                          !fieldState.error ||
                          banUserForm.watch('banIndefinitely')
                        }
                        disabled={banUserForm.watch('banIndefinitely')}
                      />
                      {!fieldState.error &&
                        !banUserForm.getValues('banIndefinitely') &&
                        field.value && (
                        <div className="flex italic pt-2">
                          <Typography
                            variant="h6"
                            style={{ fontWeight: 400 }}
                          >
                              Banned until
                          </Typography>
                            &nbsp;
                          <Typography
                            style={{ color: '#d32f2f', fontWeight: 500 }}
                          >
                            {moment()
                              .tz(timeZone)
                              .startOf('day')
                              .add(parseInt(field.value) + 1, 'days')
                              .subtract(1, 'second')
                              .format('MMM DD, YYYY')}
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>

              <div>
                <div className="h-8" />

                <div className="flex gap-x-2 h-8">
                  <Typography className="mt-2">Ban Indefinitely</Typography>
                  <Controller
                    name="banIndefinitely"
                    control={banUserForm.control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        color='primary'
                        checked={field.value}
                        onChange={(event) => field.onChange(event.target.checked)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div>
              <Typography className="mb-1">Internal Note</Typography>
              <Controller
                name="internalNote"
                control={banUserForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    style={{ resize: 'none' }}
                    placeholder="Add a note..."
                    multiline
                    rows={4}
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!fieldState.error}
                    fullWidth
                  />
                )}
              />
              <Typography className="flex justify-end italic">
                Maximum 500 characters
              </Typography>
            </div>

            <div>
              <div className="flex items-center gap-x-1 mb-1">
                <Typography className="flex items-center">
                  Note for Learner
                </Typography>
                <Typography variant="mini">
                  {'(Note added here will be seen by the learner when he tries to log in.)'}
                </Typography>
              </div>
              <Controller
                name="noteForLearner"
                control={banUserForm.control}
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    style={{ resize: 'none' }}
                    placeholder="Add a note..."
                    multiline
                    rows={4}
                    onChange={(_, evt) => field.onChange(evt)}
                    value={field.value}
                    error={!!fieldState.error}
                    helperText={(fieldState.error || {}).message}
                    disableHelperText={!fieldState.error}
                    fullWidth
                  />
                )}
              />
              <Typography className="flex justify-end italic">
                Maximum 250 characters
              </Typography>
            </div>

            <div className="flex justify-between items-center mt-6">
              <Button style={{ color: '#333333' }} onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="error" variant="contained">
                Ban Learner
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BanUserModalContent;
