import React from 'react';
import PropTypes from 'prop-types';
import './Form.scss';

const Input = props => {
  const {
    className,
    id,
    error,
    label,
    name,
    onBlur,
    onChange,
    onKeyPress,
    placeholder,
    readOnly = false,
    touched,
    type,
    value,
    labelObject,
    hideMargin,
    size,
    maxLength,
    rtl,
    positive,
    minDate,
    maxDate,
    width,
    step,
    disable,
    inputBtn,
    inputStyle
  } = props;
  const classNameAddOn = className ? ` ${className}` : '';
  const containerClassName = `nucleos-field-container input-container${classNameAddOn}`;
  const wrapperClassName = `nucleos-field-label-wrapper input-wrapper${classNameAddOn}`;
  const labelClassName = `nucleos-label input-label${classNameAddOn}`;
  const inputClassName = `nucleos-field nucleos-input${classNameAddOn}`;
  const errorClassName = `nucleos-field-error input-error${classNameAddOn}`;
  const justify = hideMargin ? { justifyContent: 'flex-start' } : {};

  return (
    <div className={containerClassName}>
      <div className={wrapperClassName} style={justify}>
        { !hideMargin && <div style={{ marginRight: '20px' }}> {labelObject && labelObject} </div> }
        {label && <label className={labelClassName}>{label}:</label>}
        <input
          id={id}
          data-test-id={id}
          disabled={!!disable}
          className={inputClassName}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={onKeyPress}
          readOnly={readOnly}
          type={type}
          placeholder={placeholder}
          style={{ ...(rtl ? { direction: 'rtl', caretColor: 'transparent', width } : {}), ...(disable ? { cursor: 'not-allowed', opacity: '.7', fontStyle: 'italic' } : {}), ...inputStyle }}
          size={size}
          maxLength={maxLength}
          min={minDate || positive && '0'}
          max={maxDate || positive && '999999999999999999'}
          step={step}
        />
        {inputBtn}
      </div>
      {error && touched && <span className={errorClassName}>{error}</span>}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  touched: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Input;
