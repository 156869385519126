import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Typography,
  SearchInput,
  Table,
  Button
} from '@nucleos/core-ui';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import HTMLReactParser from 'html-react-parser';

import Data from '../../../Middleware/Data';
import AuthenticationStore from '../../../Stores/Authentication';
import { formatDateTime, getLearnerLicenseStatusLabel } from '../../../Lib/util';
import { Icon } from '../../../Components/Shared/Icon';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import RequestLicenseConfirmPopup from './RequestLicenseConfirmPopup';

const defaultSorting = { column: 'NONE', sortOrder: 'ASC' };

const RequestLicense = ({ applicationUid }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [columnSorting, setColumnSorting] = useState(defaultSorting);
  const { uid: loggedInUserId } = AuthenticationStore;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const queryVariables = useMemo(() => ({
    appId: applicationUid,
    orderBy: columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
    order: columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
    offset: (page - 1) * recordsPerPage,
    limit: recordsPerPage,
    q: JSON.stringify({ search: debouncedSearchTerm })
  }), [applicationUid, columnSorting, page, recordsPerPage, debouncedSearchTerm]);

  const requestLicenseQuery = useQuery(
    ['REQUEST_LICENSE', queryVariables],
    () => Data.getRequestLicenseCourses(queryVariables),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      placeholderData: { rows: [], count: 0 }
    }
  );

  const requestLicenseMutation = useMutation((data) =>
    Data.requestLicense(data).then(GenericErrorDetectorForMutations)
  );

  const requestLicenseHandler = async (formData, course, courseId, onCloseModal) => {
    const payload = {
      UserUid: loggedInUserId,
      ApplicationUid: applicationUid,
      CourseId: courseId,
      learnerRequestNote: formData.note || ''
    };
    requestLicenseMutation.mutate(payload, {
      onSuccess: () => {
        toast.success(`License requested successfully for ${course}.`);
        requestLicenseQuery.refetch();
      },
      onError: (error) => toast.error(error.message)
    });
    onCloseModal();
  };

  const onRequestLicense = (course, courseId) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <RequestLicenseConfirmPopup
          course={course}
          courseId={courseId}
          onRequestLicenseHandler={requestLicenseHandler}
          onCloseModal={onClose}
        />
      ),
      closeOnClickOutside: false,
      closeOnEscape: false
    });
  };

  const columns = [
    {
      title: 'Course Name',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'title' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'title', sortOrder }),
      render: (item) => (
        <Typography><div>{item.title}</div></Typography>
      )
    },
    {
      title: 'Note',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'note' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'note', sortOrder }),
      render: (item) => {
        const parsedNote = HTMLReactParser(item.note);
        return <Typography><div>{parsedNote && parsedNote.props.children ? parsedNote : '––'}</div></Typography>;
      }
    },
    {
      title: 'Requested On',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'requestedOn' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'requestedOn', sortOrder }),
      render: (item) => {
        const sortedByCreatedAt = item.UserLicensesAndSeats.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const requestedOn = sortedByCreatedAt.length ? sortedByCreatedAt[0].createdAt : null;
        return <Typography><div>{requestedOn ? formatDateTime(requestedOn) : '––'}</div></Typography>;
      }
    },
    {
      title: 'Action',
      enableSort: false,
      render: (item) => {
        const sortedByCreatedAt = item.UserLicensesAndSeats.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const status = sortedByCreatedAt.length ? sortedByCreatedAt[0].status : null;
        const licenseStatus = getLearnerLicenseStatusLabel(status);
        return status
          ? (
            <Button
              color={licenseStatus.color}
              variant="outlined"
              className='cursor-not-allowed'
              style={{ width: 150, padding: '3px 12px' }}
            >
              <Typography color={licenseStatus.color} variant='subtitle2' >
                <Icon icon={licenseStatus.icon} style={{ height: 18 }} />
                {licenseStatus.label}
              </Typography>
            </Button>
          )
          : (
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ width: 150, padding: '3px 12px' }}
              onClick={() => onRequestLicense(item.title, item.id)}
            >
              Request License
            </Button>
          );
      }
    }
  ];

  return (
    <div className="nucleos-core">
      <div className="my-2 flex items-center justify-between">
        <Typography style={{ fontWeight: 700 }}>
          {'Click \'Request License\' next to the course to request it.'}
        </Typography>
        <SearchInput
          placeholder="Search"
          style={{ width: '240px' }}
          onSearch={(search) => {
            setSearchTerm(search);
            setPage(1); // Reset page on search
          }}
        />
      </div>
      <Table
        columns={columns}
        noDataMessage={<GenericNoDataFound className="mb-5" />}
        loading={requestLicenseQuery.isFetching}
        rowsData={requestLicenseQuery.data ? requestLicenseQuery.data.rows : []}
        pagination
        totalRecords={requestLicenseQuery.data ? requestLicenseQuery.data.count : 0}
        recordsPerPage={recordsPerPage || 10}
        onRecordsPerPageChange={(rowsPP) => {
          setRecordsPerPage(rowsPP);
          setPage(1);
        }}
        page={page}
        onPageChange={setPage}
      />
    </div>
  );
};

export default RequestLicense;
