import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useFiltersManager = ({
  defaultFilter = [],
  onFilterChange = () => null,
  urlKey = ''
} = {}) => {
  const history = useHistory();
  const urlFilterValue = new URLSearchParams(window.location.search).get(
    urlKey
  );
  const defaultFilters = urlFilterValue
    ? JSON.parse(urlFilterValue)
    : defaultFilter;
  const [filters, _setFilters] = useState(defaultFilters);

  const setFilters = (cb) => {
    _setFilters((_filters) => {
      const newFiltersState = cb(_filters);
      onFilterChange(newFiltersState);
      if (urlKey) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(urlKey, JSON.stringify(newFiltersState));
        history.replace('?' + searchParams.toString());
      }
      return newFiltersState;
    });
  };

  const onFilterApply = ({ key, value, meta }) => {
    const doesFilterAlreadyExist = filters.some((f) => f.key === key);
    if (doesFilterAlreadyExist) {
      setFilters((_filters) => {
        return _filters.map((f) => (f.key === key ? { key, value, meta } : f));
      });
    } else {
      setFilters((_filters) => [..._filters, { key, value, meta }]);
    }
  };

  const onBulkFilterApply = (key, newFilters = []) => {
    setFilters((_filters) => [
      ..._filters.filter((f) => f.key !== key),
      ...newFilters.map((f) => ({ key: key, value: f.value, meta: f.meta }))
    ]);
  };

  const onFilterRemove = ({ key, value }) => {
    const doesFilterAlreadyExist = filters.some((f) => f.key === key);
    if (!doesFilterAlreadyExist) { return; }
    setFilters((_filters) =>
      _filters.filter(
        (f) =>
          !(f.key === key && (value !== undefined ? f.value === value : true))
      )
    );
  };

  const getValue = (key) => {
    const filter = filters.find((f) => f.key === key);
    return filter ? filter.value : null;
  };

  const getValues = (key, { transformValue } = {}) => {
    return filters.reduce(
      (values, f) =>
        f.key === key
          ? [...values, transformValue ? transformValue(f) : f.value]
          : values,
      []
    );
  };

  return {
    filters,
    onFilterApply,
    onFilterRemove,
    onBulkFilterApply,
    getValue,
    getValues
  };
};
