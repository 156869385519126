import React, { useEffect, useState } from 'react';
import { Card, Typography, Table, ToggleButton } from '@nucleos/core-ui';
import HeatmapChart from '../../../Components/Chart/Heatmap/Heatmap';
import { useHistory } from 'react-router-dom';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { SortOrder, useTableSorting } from '../../../hooks/useTableSorting';
import { chunkifyArray } from '../../Forms/Admin/Grievances/utils';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';

const ColumnKeys = {
  LearnerName: 'name'
};

const defaultSorting = {
  column: ColumnKeys.LearnerName,
  sortOrder: SortOrder.Ascending
};

export default function ActivityTotals () {
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();
  const [selectedView, setSelectedView] = useState('table');
  const [page, setPage] = useState(1);
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'totals'
    });
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [activityData, setActivityData] = useState([]);
  const [updatedHeatMapData, setUpdatedHeatMapData] = useState({
    xLabels: [],
    yLabels: [],
    data: []
  });

  const requestData = {
    groupId,
    type: 'all_user_totals_in_group',
    apps: GLRContext.apps,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2],
    page: GLRContext.isPDFMode ? undefined : page,
    limit: GLRContext.isPDFMode ? undefined : recordsPerPage,
    order: columnSorting.sortOrder,
    orderBy: columnSorting.column,
    maxCount: !GLRContext.isPDFMode
  };

  const activityTotalsQuery = useQuery(
    QueryKeys.GroupLearningRecords.listing(requestData),
    () => Data.getGroupLearningRecordReportData(requestData),
    {
      placeholderData: { data: [], count: 0, maxCount: 0 },
      select: (data) => ({
        count: Number(data.count),
        maxCount: data.maxCount / (60 * 60),
        data: data.data.sort((a, b) => {
          const aFullName = `${a.firstName} ${a.lastName}`.toLowerCase();
          const bFullName = `${b.firstName} ${b.lastName}`.toLowerCase();

          const x = aFullName;
          const y = bFullName;

          // compare the word which is comes first
          if (x > y) {
            return getCurrentSorting(ColumnKeys.LearnerName) ===
              SortOrder.Ascending
              ? 1
              : -1;
          }
          if (x < y) {
            return getCurrentSorting(ColumnKeys.LearnerName) ===
              SortOrder.Descending
              ? 1
              : -1;
          }
          return 0;
        })
      })
    }
  );
  const applicationsQuery = useQuery(
    QueryKeys.GroupLearningRecords.listing({
      groupId: requestData.groupId,
      apps: requestData.apps,
      from: requestData.from,
      to: requestData.to,
      type: 'all_application_totals_day'
    }),
    () =>
      Data.getGroupLearningRecordReportData({
        groupId: requestData.groupId,
        apps: requestData.apps,
        from: requestData.from,
        to: requestData.to,
        type: 'all_application_totals_day'
      }),
    {
      select: (data) => data.applications,
      placeholderData: { applications: [] }
    }
  );

  const getReducedData = (data) => {
    return data.reduce(
      (acc, activity) => {
        const userKey = activity.UserUid;
        const applicationKey = activity.ApplicationUid;
        acc.userNameLabel[
          userKey
        ] = `${activity.firstName} ${activity.lastName}`;
        acc.applicationLabel[applicationKey] = activity.application;

        const userData = acc.usersData[userKey] || {
          userId: userKey,
          appsUsage: {}
        };
        acc.usersData[userKey] = {
          ...userData,
          appsUsage: {
            ...userData.appsUsage,
            [applicationKey]: userData.appsUsage[applicationKey]
              ? userData.appsUsage[applicationKey] + activity.seconds
              : activity.seconds
          }
        };
        return acc;
      },
      { usersData: {}, userNameLabel: {}, applicationLabel: {} }
    );
  };

  const reducedData = getReducedData(activityTotalsQuery.data.data);

  useEffect(() => {
    setActivityData([]);
    setPage(1);
    setUpdatedHeatMapData({
      data: [],
      xLabels: [],
      yLabels: []
    });
  }, [
    groupId,
    JSON.stringify(GLRContext.apps),
    GLRContext.timePeriod,
    recordsPerPage,
    columnSorting.column,
    columnSorting.sortOrder
  ]);

  useEffect(() => {
    if (selectedView !== 'chart') { return; }
    const updatedActivityData = [
      ...activityData,
      ...activityTotalsQuery.data.data
    ];
    setActivityData(updatedActivityData);
    const reducedData = getReducedData(updatedActivityData);
    const userIds = Object.keys(reducedData.usersData);
    const appIds = applicationsQuery.data.map((app) => app.uid);

    const newXLabels = applicationsQuery.data.map((app) => app.name);
    const newYLabels = userIds.map(
      (userId) => reducedData.userNameLabel[userId]
    );

    const newData = userIds.map((userId) =>
      appIds.map((appId) => {
        const usageInSeconds =
          reducedData.usersData[userId].appsUsage[appId] || 0;
        const usageInHours = usageInSeconds / (60 * 60);
        return Number(usageInHours.toFixed(2));
      })
    );

    setUpdatedHeatMapData({
      xLabels: newXLabels,
      yLabels: newYLabels,
      data: newData
    });
  }, [selectedView, JSON.stringify(activityTotalsQuery.data), applicationsQuery.data]);

  return (
    <div>
      <Card className="rounded-xl bg-white mt-12">
        <Card.Header style={{ borderWidth: 0 }} className="border-none">
          <div className="flex justify-between items-center">
            <Typography
              className="py-1.5 ml-2"
              variant="h4"
              style={{ fontWeight: 700, fontSize: '18px' }}
            >
              Activity Totals - By User (Hours)
            </Typography>
            {GLRContext.isPDFMode
              ? null
              : (
                <div className="w-48" id="toggle-button-wrapper-activity-total">
                  <ToggleButton
                    options={[
                      { label: 'Table View', value: 'table' },
                      { label: 'Chart View', value: 'chart' }
                    ]}
                    defaultSelected={selectedView}
                    onChange={(option) => {
                      setSelectedView(option.value);
                      setActivityData([]);
                      setPage(1);
                      setRecordsPerPage(10);
                      setUpdatedHeatMapData({
                        data: [],
                        xLabels: [],
                        yLabels: []
                      });
                    }}
                    fullWidth
                  />
                </div>
              )}
          </div>
        </Card.Header>
        <Card.Body style={{ padding: 0 }} className="activity-total-container">
          {GLRContext.isPDFMode
            ? (
              chunkifyArray(Object.keys(reducedData.userNameLabel), 6).map(
                (userIds) => (
                  <div className="mx-4 py-8" style={{ breakAfter: 'always' }}>
                    <Typography variant="h5" className="mb-4 font-bold">
                    Results for{' '}
                      {userIds
                        .map((userId) => reducedData.userNameLabel[userId])
                        .join(', ')}
                    .
                    </Typography>
                    <Table
                      noDataMessage="Empty here"
                      loading={activityTotalsQuery.isFetching}
                      pagination={false}
                      columns={[
                        {
                          title: 'Application',
                          render: (appId) => reducedData.applicationLabel[appId]
                        },
                        ...userIds.map((userId) => ({
                          title: reducedData.userNameLabel[userId],
                          render: (appId) => (
                            <Typography style={{ width: '40px' }}>
                              {Number(
                                (reducedData.usersData[userId].appsUsage[appId] ||
                                0) /
                              60 /
                              60
                              ).toFixed(2) || '0.00'}
                            </Typography>
                          )
                        }))
                      ]}
                      rowsData={applicationsQuery.data.map((app) => app.uid)}
                    />
                  </div>
                )
              )
            )
            : selectedView === 'table'
              ? (
                <Table
                  useFixedHeight
                  noDataMessage={<GenericNoDataFound />}
                  loading={activityTotalsQuery.isFetching}
                  pagination
                  totalRecords={activityTotalsQuery.data.count}
                  page={page}
                  onPageChange={setPage}
                  recordsPerPage={recordsPerPage}
                  onRecordsPerPageChange={(rowsPP) => {
                    setRecordsPerPage(rowsPP);
                    setPage(1);
                  }}
                  columns={[
                    {
                      title: 'Name',
                      render: (data) => reducedData.userNameLabel[data.userId],
                      enableSort: true,
                      sortOrder: getCurrentSorting(ColumnKeys.LearnerName),
                      onSortChange: (sortOrder) =>
                        setColumnSorting({
                          column: ColumnKeys.LearnerName,
                          sortOrder
                        })
                    },
                    ...applicationsQuery.data.map(({ uid, name }) => ({
                      title: name,
                      render: (data) => (
                        <Typography style={{ width: '40px' }}>
                          {Number((data.appsUsage[uid] || 0) / 60 / 60).toFixed(
                            2
                          ) || '0.00'}
                        </Typography>
                      )
                    }))
                  ]}
                  rowsData={Object.values(reducedData.usersData)}
                />
              )
              : (
                <div style={{ padding: 24 }}>
                  <HeatmapChart
                    key={JSON.stringify(updatedHeatMapData.xLabels)}
                    xLabels={updatedHeatMapData.xLabels}
                    yLabels={updatedHeatMapData.yLabels}
                    data={updatedHeatMapData.data}
                    maxLimit={activityTotalsQuery.data.maxCount}
                    loading={
                      activityTotalsQuery.isFetching &&
                  !activityTotalsQuery.isFetched
                    }
                    showLoadMore={
                      updatedHeatMapData.yLabels.length !==
                  activityTotalsQuery.data.count
                    }
                    onLoadMoreClick={() => setPage((prev) => prev + 1)}
                  />
                </div>
              )}
        </Card.Body>
      </Card>
    </div>
  );
}
