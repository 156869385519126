import { useQuery } from 'react-query';
import Data from '../Middleware/Data';
import Authentication from '../Stores/Authentication';
import { QueryKeys } from '../Lib/query-keys';

export const useCategoriesQuery = ({ categoriesType, roleType } = {}) =>
  useQuery(
    QueryKeys.Categories.listing({
      type: categoriesType,
      roleType
    }), () => Data.getAllGrievanceCategories({
      requestType: categoriesType,
      roleType
    }), {
      select: ({ categories }) =>
        categories
          .map((c) => ({
            title: c.name + `${c.shortName ? ' - ' + c.shortName : ''}`,
            value: c.id,
            ...c
          }))
          .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0)),
      enabled: Authentication.isAuthenticated,
      refetchOnMount: false
    });
