import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Typography } from '@mui/material';

export default function DropDownMenu ({
  children,
  actions = [],
  isMenuOpen,
  onActionClick = () => null,
  testId
}) {
  return (
    <Popover className="relative">
      <Popover.Button
        data-testid={`dropdown-button-${testId}`}
        className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
      >
        {children}
      </Popover.Button>

      <Transition
        as={Fragment}
        appear={isMenuOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-2 top-0 z-10 flex max-w-max -translate-x-1/2 px-4">
          <div
            className="flex-auto overflow-hidden bg-white text-sm leading-6 rounded border"
            style={{ borderColor: '#D3D3D3' }}
            data-testid={`dropdown-items-${testId}`}
          >
            <div className="">
              {actions.map((item) => (
                <div
                  key={item.name}
                  data-testid={`dropdown-item-${testId}`}
                  className="group relative flex gap-x-6 p-2 hover:bg-gray-50"
                  onClick={(e) => {
                    e.stopPropagation();
                    onActionClick(item);
                  }}
                >
                  <Typography>{item.name}</Typography>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
