import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const SimpleLoader = ({ sx, size = 50, thickness = 5 }) => {
  return (
    <Box sx={{ width: '100%', my: 8, display: 'flex', justifyContent: 'center', ...sx }}>
      <CircularProgress size={size} thickness={thickness} disableShrink />
    </Box>
  );
};
