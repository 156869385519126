import React, { Component } from 'react';
import { observer } from 'mobx-react';
import DataStore from '../../Stores/DataStore';

const podMap = {
  '3M POD 6A': 'https://kiosk-edge.keefegp.com/init/ca34515j-3mpod6a',
  '3M POD 6B': 'https://kiosk-edge.keefegp.com/init/ca34515j-3mpod6b',
  '2F POD B': 'https://kiosk-edge.keefegp.com/init/ca34151j-2fpodb',
  '2M POD A': 'https://kiosk-edge.keefegp.com/init/ca34151j-2mpoda',
  '2M POD D': 'https://kiosk-edge.keefegp.com/init/ca34151j-2mpodd',
  '2M POD E': 'https://kiosk-edge.keefegp.com/init/ca34151j-2mpode',
  '3M POD 1A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod1a',
  '3M POD 1B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod1b',
  '3M POD 2A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod2a',
  '3M POD 2B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod2b',
  '3M POD 3A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod3a',
  '3M POD 3B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod3b',
  '3M POD 4A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod4a',
  '3M POD 4B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod4b',
  '3M POD 5A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod5a',
  '3M POD 5B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod5b',
  '3M POD 7A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod7a',
  '3M POD 7B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod7b',
  '3M POD 8A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod8a',
  '3M POD 8B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mpod8b',
  '3M ANNEX A': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mannexa',
  '3M ANNEX B': 'https://kiosk-edge.keefegp.com/init/ca34151j-3mannexb'
};

async function getPodURL () {
  const self = await DataStore.getSelf();
  const pod = await self.pod;
  if (!Object.hasOwn(podMap, pod)) { return false; }

  const podURL = await podMap[pod];
  // TODO: console.log(podURL)
  return podURL;
}

@observer
class KeefeRouter extends Component {
  render () {
    getPodURL().then((podURL) => {
      if (!podURL) {
        window.top.location.href = '/home';
      } else {
        window.location.href = podURL;
      }
    });
    return (
      <p>If you see this screen after a few moments, please return to the home page.</p>
    );
  }
}

export default KeefeRouter;
