import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Typography,
  SearchInput,
  Table,
  DateRangePicker,
  MultiSelectDropDown,
  Chip
} from '@nucleos/core-ui';
import { DialogContent, Modal } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import toast from 'react-hot-toast';

import Data from '../../../Middleware/Data';
import DropDownMenu from '../../../Components/Shared/DropDownMenu';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { QueryKeys } from '../../../Lib/query-keys';
import { useFiltersManager } from '../../../hooks/useFilters';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { formatDateTime, getLicenseStatusLabel } from '../../../Lib/util';
import { useTableSorting } from '../../../hooks/useTableSorting';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import { Icon } from '../../../Components/Shared/Icon';
import { GRAY } from '../../../constants/colors';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import AddOrEditLicense from './AddOrEditLicense';
import DeleteLicensePopup from './DeleteLicensePopup';

const defaultSorting = {
  column: 'ADDED_ON',
  sortOrder: 'DESC'
};

const FilterKeys = {
  learnerName: 'learnerName',
  licenseType: 'licenseType',
  courseName: 'courseName',
  approvedOnStartDate: 'approvedOnStartDate',
  approvedOnEndDate: 'approvedOnEndDate',
  status: 'status'
};

const ColumnKeys = {
  NAME: 'NAME',
  LICENSE_TYPE: 'LICENSE_TYPE',
  COURSE_NAME: 'COURSE_NAME',
  ADDED_ON: 'ADDED_ON',
  STATUS: 'STATUS'
};

const licenseTypeOptions = [
  { value: 'app', title: 'App' },
  { value: 'course', title: 'Course' }
];

const statusOptions = [
  { value: 'APPROVED', title: 'Approved' },
  { value: 'REJECTED', title: 'Rejected' },
  { value: 'REVOKED', title: 'Revoked' },
  { value: 'ONBOARDING_SUCCESS', title: 'Onboarded' },
  { value: 'ONBOARDING_FAILED', title: 'Onboarding Failed' }
];

const userActions = [
  { name: 'Edit', allowed: ['APPROVED', 'ONBOARDING_FAILED'] },
  { name: 'Delete', allowed: ['APPROVED', 'REJECTED', 'ONBOARDING_FAILED'] }
];

const ApprovedLicenses = ({ applicationUid, search, licenseMetadata, refetchData, resetRefetchData }) => {
  const filterManager = useFiltersManager({
    defaultFilter: [],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [showAddOrEditLicenseModal, setShowAddOrEditLicenseModal] = useState(false);
  const [licenseDetails, setLicenseDetails] = useState({});
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'approved-licenses'
    });
  const userPermissions = useUserPermissions();

  const startDate = filterManager.getValue(FilterKeys.approvedOnStartDate);
  const endDate = filterManager.getValue(FilterKeys.approvedOnEndDate);

  useEffect(() => {
    if (licenseMetadata.fields) {
      (licenseMetadata.fields || []).forEach(field => {
        FilterKeys[field.key] = field.key;
        ColumnKeys[field.key.toUpperCase()] = field.key.toUpperCase();
      });
    }
  }, [licenseMetadata]);

  const getDynamicFilters = () => {
    const dynamicFilters = {};

    (licenseMetadata.fields || []).forEach(field => {
      const filterValue = filterManager.getValue(FilterKeys[field.key]);
      if (filterValue) {
        dynamicFilters[field.key] = filterValue;
      }
    });

    return dynamicFilters;
  };

  const requestData = {
    appId: applicationUid,
    offset: (page - 1) * recordsPerPage,
    orderBy: columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
    order: columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
    pending: false,
    q: JSON.stringify({
      learnerName: filterManager.getValue(FilterKeys.learnerName) || '',
      licenseType: licenseMetadata.appLicense ? filterManager.getValues(FilterKeys.licenseType) : '',
      courseName: filterManager.getValue(FilterKeys.courseName) || '',
      dateStart: startDate || '',
      dateEnd: endDate || '',
      status: filterManager.getValues(FilterKeys.status) || '',
      search,
      license: {
        ...getDynamicFilters()
      }
    }),
    limit: recordsPerPage
  };

  const approvedLicensesQuery = useQuery(
    QueryKeys.ApprovedLicenses.listing(requestData),
    () => Data.getApprovedLicenses(requestData),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { rows: [], count: 0 }
    }
  );

  useEffect(() => {
    if (refetchData) {
      approvedLicensesQuery.refetch();
      resetRefetchData();
    }
  }, [approvedLicensesQuery, refetchData, resetRefetchData]);

  const renderMultiSelectFilter = (
    dropdownId,
    index,
    columnList,
    filter,
    label,
    options,
    type,
    testId,
    searchable = false,
    searchValue = '',
    onSearchChange = () => null,
    isSearchLoading = false,
    isNextPageLoading = false,
    hasNextOptions = false,
    fetchNextOptionsPage = () => null
  ) => {
    const transformValue = (v) => ({ title: v.meta.label, value: v.value });

    return (
      <MultiSelectDropDown
        dropdownId={dropdownId}
        testId={testId}
        DropdownContainerProps={{
          direction: index === columnList.length - 1 ? 'left' : 'right'
        }}
        disableHelperText
        value={filterManager.getValues(FilterKeys[filter], { transformValue })}
        onChange={(values) => {
          filterManager.onBulkFilterApply(
            FilterKeys[filter],
            values.map((v) => ({
              value: v.value,
              meta: { label: v.title }
            }))
          );
        }}
        label={label}
        getLabel={(selected) =>
          `Selected ${type}${selected.length > 1 ? 's' : ''} (${selected.length
          })`
        }
        fullWidth
        options={options}
        searchable={searchable}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        isSearchLoading={isSearchLoading}
        isNextPageLoading={isNextPageLoading}
        hasNextOptions={hasNextOptions}
        fetchNextOptionsPage={fetchNextOptionsPage}
      />
    );
  };

  const dynamicColumns = licenseMetadata
    ? (licenseMetadata.fields || []).map((field) => ({
      title: field.label,
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys[field.key.toUpperCase()]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys[field.key.toUpperCase()], sortOrder }),
      render: (item) => item.LicensesAndSeatsInventory && item.LicensesAndSeatsInventory.license
        ? <Typography>{item.LicensesAndSeatsInventory.license[field.key] || '––'}</Typography>
        : '––',
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          value={filterManager.getValue(FilterKeys[field.key]) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys[field.key]) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys[field.key] }); }
            filterManager.onFilterApply({
              key: FilterKeys[field.key],
              value: search
            });
          }}
        />
      )
    }))
    : [];

  const columns = [
    {
      title: 'Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.NAME, sortOrder }),
      render: (item) => <Typography>{item.learnerName}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          value={filterManager.getValue(FilterKeys.learnerName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.learnerName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.learnerName }); }
            filterManager.onFilterApply({
              key: FilterKeys.learnerName,
              value: search
            });
          }}
        />
      )
    },
    licenseMetadata && licenseMetadata.appLicense && {
      title: 'License Type',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.LICENSE_TYPE),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.LICENSE_TYPE, sortOrder }),
      render: (item) => <Typography>{item.licenseType}</Typography>,
      renderFilter: (_, index, columnList) =>
        renderMultiSelectFilter(
          'license-type-select',
          index,
          columnList,
          'licenseType',
          'All Types',
          licenseTypeOptions,
          'License Type',
          'filter-license-type'
        )
    },
    {
      title: 'Course Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.COURSE_NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.COURSE_NAME, sortOrder }),
      render: (item) => <Typography>{item.Course.title || '––'}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          value={filterManager.getValue(FilterKeys.courseName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.courseName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.courseName }); }
            filterManager.onFilterApply({
              key: FilterKeys.courseName,
              value: search
            });
          }}
        />
      )
    },
    ...dynamicColumns,
    {
      title: 'Added On',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.ADDED_ON),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.ADDED_ON, sortOrder }),
      render: (item) => (
        <Typography>{formatDateTime(item.createdAt)}</Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <DateRangePicker
          testId="filter-approved-on"
          DropdownContainerProps={{
            direction: 'left'
          }}
          disableHelperText
          fullWidth
          placeholder="Date Range"
          maxDate={new Date()}
          value={{
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null
          }}
          onChange={({ startDate, endDate }) => {
            filterManager.onFilterApply({
              key: FilterKeys.approvedOnStartDate,
              value: moment(startDate).format('YYYY-MM-DD'),
              meta: {
                label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
                  endDate
                ).format('MM/DD/YYYY')}`
              }
            });
            filterManager.onFilterApply({
              key: FilterKeys.approvedOnEndDate,
              value: moment(endDate).format('YYYY-MM-DD')
            });
          }}
        />
      )
    },
    {
      title: 'Status',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.STATUS),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.STATUS, sortOrder }),
      render: (item) => {
        const licenseStatus = getLicenseStatusLabel(item.status);
        return <Chip
          rounded="full"
          variant="outlined"
          color={licenseStatus.color}
        >
          <Typography color={licenseStatus.color} variant='subtitle2' >
            <Icon icon={licenseStatus.icon} style={{ height: 18 }} />
            {licenseStatus.label}
          </Typography>
        </Chip>;
      },
      renderFilter: (_, index, columnList) =>
        renderMultiSelectFilter(
          'status-select',
          index,
          columnList,
          'status',
          'All Statuses',
          statusOptions,
          'Status',
          'filter-status'
        )
    }
  ].filter(Boolean);

  const actionAllowed = (licenseMetadata.fields && licenseMetadata.fields.length) || licenseMetadata.deleteLicense;
  if (actionAllowed && [ACTIVE_ROLES.SUPER_ADMIN].includes(userPermissions.role)) {
    columns.push({
      title: 'Action',
      render: (item) => {
        const filteredActions = userActions.filter((action) =>
          action.allowed.includes(item.status)
        );
        const notAllowed = ['ONBOARDING_SUCCESS', 'REJECTED'].includes(item.status);
        return licenseMetadata && licenseMetadata.deleteLicense
          ? (
            <div className="flex justify-end cursor-pointer">
              <DropDownMenu
                testId={'user-action'}
                onActionClick={(action) => {
                  onActionClick(action.name, item);
                }}
                actions={filteredActions}
              >
                <Icon
                  icon="ThreeDots"
                  sx={{
                    color: GRAY,
                    opacity: ['REVOKED'].includes(item.status) ? 0.5 : 1,
                    cursor: ['REVOKED'].includes(item.status) ? 'not-allowed' : 'pointer'
                  }}
                />
              </DropDownMenu>
            </div>
          )
          : (
            <Icon
              icon="Edit"
              onClick={() => !notAllowed && onEditLicenseClick(item)}
              sx={{
                color: GRAY,
                opacity: notAllowed ? 0.5 : 1,
                cursor: notAllowed ? 'not-allowed' : 'pointer'
              }}
            />
          );
      }
    });
  }

  const onActionClick = (actionName, data) => {
    if (actionName === 'Edit') {
      onEditLicenseClick(data);
    } else if (actionName === 'Delete') {
      onDeleteLicenseClick(data);
    }
  };

  const onEditLicenseClick = (data) => {
    setShowAddOrEditLicenseModal(true);
    let licenseData = {
      licenseId: data.uid,
      learnerName: data.learnerName,
      courseName: data.Course.title
    };
    if (data.LicensesAndSeatsInventory && data.LicensesAndSeatsInventory.license) {
      licenseData = { ...licenseData, ...data.LicensesAndSeatsInventory.license };
    }
    setLicenseDetails(licenseData);
  };

  const onDeleteLicenseClick = (data) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <DeleteLicensePopup
          username={data.learnerName}
          courseName={data.Course.title}
          licenseId={data.uid}
          onSubmitHandler={deleteLicenseHandler}
          onCloseModal={onClose}
        />
      ),
      closeOnClickOutside: false,
      closeOnEscape: false
    });
  };

  const deleteLicenseMutation = useMutation((data) =>
    Data.deleteCourseLicense(data).then(GenericErrorDetectorForMutations)
  );

  const deleteLicenseHandler = async (formData, username, licenseId, onCloseModal) => {
    const payload = {
      licenseId,
      isApproved: false,
      adminRejectNote: formData.note || '',
      license: {}
    };
    deleteLicenseMutation.mutate(payload, {
      onSuccess: () => {
        toast.success(`License deleted successfully for ${username}.`);
        approvedLicensesQuery.refetch();
      },
      onError: () => toast.error(`Failed to delete license for ${username}.`)
    });
    onCloseModal();
  };

  const appliedFilterLabels = filterManager.filters
    .filter((filter) => ![FilterKeys.approvedOnEndDate].includes(filter.key))
    .map((filter) => (
      <Chip
        key={filter.key}
        rounded="full"
        className="mb-2 mr-2"
        style={{ padding: '6px 8px' }}
        label={filter.meta ? filter.meta.label : filter.value}
        onDelete={() => onFilterDelete(filter)}
      />
    ));

  const onFilterDelete = (appliedFilter) => {
    const isDateRangeFilter = [
      FilterKeys.approvedOnStartDate,
      FilterKeys.approvedOnEndDate
    ].includes(appliedFilter.key);

    if (isDateRangeFilter) {
      filterManager.onFilterRemove({
        key: FilterKeys.approvedOnStartDate
      });
      filterManager.onFilterRemove({
        key: FilterKeys.approvedOnEndDate
      });
      return;
    }

    filterManager.onFilterRemove({
      key: appliedFilter.key,
      value: appliedFilter.value
    });
  };

  return (
    <>
      <div className="nucleos-core">
        <div className="flex items-center flex-wrap">
          {appliedFilterLabels.length
            ? (
              <Typography className="mb-2 mr-2">Applied Filters:</Typography>
            )
            : null}
          {appliedFilterLabels}
        </div>
        <LoadingAndErrorHandler
          isLoading={approvedLicensesQuery.isLoading}
          isSuccess={approvedLicensesQuery.isSuccess}
          isError={approvedLicensesQuery.isError}
        >
          <Table
            useFixedHeight
            columns={columns}
            noDataMessage={<GenericNoDataFound className="mb-5" />}
            loading={approvedLicensesQuery.isFetching}
            rowsData={approvedLicensesQuery.data.rows ? approvedLicensesQuery.data.rows : []}
            pagination
            totalRecords={
              approvedLicensesQuery.data.count ? approvedLicensesQuery.data.count : 0
            }
            recordsPerPage={recordsPerPage || 10}
            onRecordsPerPageChange={(rowsPP) => {
              setRecordsPerPage(rowsPP);
              setPage(1);
            }}
            page={page}
            onPageChange={setPage}
          />
        </LoadingAndErrorHandler>
      </div>
      <Modal
        open={showAddOrEditLicenseModal}
        handleClose={() => setShowAddOrEditLicenseModal(false)}
        className="nucleos-core"
      >
        <DialogContent className="h-full flex justify-center items-center">
          <AddOrEditLicense
            action="EDIT"
            applicationUid={applicationUid}
            formFields={licenseMetadata.fields || []}
            licenseDetails={licenseDetails}
            onClose={(refetch) => {
              setShowAddOrEditLicenseModal(false);
              if (refetch) {
                approvedLicensesQuery.refetch();
              }
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default ApprovedLicenses;
