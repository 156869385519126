import React, { useState } from 'react';
import {
  Button,
  Card,
  Typography,
  LoadingSpinner
} from '@nucleos/core-ui';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import LearnersFilter from '../../../../Components/Shared/LearnersFilter';
import Data from '../../../../Middleware/Data';
import toast from 'react-hot-toast';
import { Box, Switch } from '@mui/material';
import HeaderSubPanel from '../../../../Components/HeaderSubPanel';
import { GRIEVANCE_PATH } from '../../../../constants/paths';

const breadcrumbLinks = [
  { href: GRIEVANCE_PATH, label: 'Grievances and Forms' },
  { href: '#', label: 'Settings' }
];

export default function GrievancesSettings () {
  const history = useHistory();
  const [isSettingsEnabled, setIsSettingsEnabled] = useState(false);
  const [selectedFaclities, setSelectedFaclities] = useState([]);
  const [selectedPODs, setSelectedPODs] = useState([]);
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [learnersCount, setLearnersCount] = useState(0);

  const grievanceSettingsQuery = useQuery(
    ['GRIEVANCE_SETTINGS', 'DISABLE_CACHE'],
    () => Data.getGrievanceSettings(),
    {
      onSuccess: (data) => {
        setIsSettingsEnabled(
          !data.grievanceSettings.disableGrievanceForAllLearners
        );
        setSelectedFaclities(
          data.grievanceSettings.facilities.map((fac) => ({
            title: fac.name,
            value: fac.id
          }))
        );
        setSelectedPODs(
          data.grievanceSettings.pods.map((pod) => ({ title: pod, value: pod }))
        );
        setSelectedLearners(
          data.grievanceSettings.learners.map((learner) => ({
            title: `${learner.firstName} ${learner.lastName}`,
            value: learner.uid
          }))
        );
      },
      cacheTime: 0
    }
  );
  const [isLoading, setLoading] = useState(true);
  const saveGrievaceSettingMutation = useMutation(
    () =>
      Data.setGrievanceSettings({
        disableGrievanceForAllLearners: !isSettingsEnabled,
        facilityIds: selectedFaclities.map((v) => v.value),
        pods: selectedPODs.map((v) => v.value),
        userIds: selectedLearners.map((v) => v.value)
      }),
    {
      onSuccess: () => {
        grievanceSettingsQuery.refetch();
        toast.success('User access settings successfully saved');
      }
    }
  );

  return (
    <Box>
      <HeaderSubPanel title="Settings" links={breadcrumbLinks} sx={{ mb: 3 }} />
      <div className="nucleos-core">
        <Typography variant="h1">User Access</Typography>
        <Card className="bg-white p-4 mt-8 rounded-lg">
          {grievanceSettingsQuery.status !== 'success'
            ? (
              <Card.Body>
                <div>
                  <div className="w-full h-56 flex items-center justify-center">
                    {grievanceSettingsQuery.status === 'error'
                      ? (
                        <Typography>Something went wrong please try again</Typography>
                      )
                      : (
                        <LoadingSpinner size={12} />
                      )}
                  </div>
                </div>
              </Card.Body>
            )
            : (
              <Card.Body>
                <div style={{ maxWidth: 1000 }}>
                  <div className="flex items-center mb-6">
                    <Typography variant="h4" className="mr-2">
                      Grievances and Forms Access for Learners
                    </Typography>
                    <Switch
                      color="primary"
                      testId="grievance-setting-enabled"
                      data-switch-enabled={isSettingsEnabled}
                      checked={isSettingsEnabled}
                      onChange={(event) => setIsSettingsEnabled(event.target.checked)}
                    />
                  </div>
                  <LearnersFilter
                    isFormDisabled={!isSettingsEnabled}
                    selectedFaclities={selectedFaclities}
                    selectedLearners={selectedLearners}
                    selectedPODs={selectedPODs}
                    onLoadingChange={setLoading}
                    onChange={({
                      selectedFaclities,
                      selectedLearners,
                      selectedPODs,
                      learnersCount
                    }) => {
                      if (selectedFaclities) { setSelectedFaclities(selectedFaclities); }
                      if (selectedLearners) { setSelectedLearners(selectedLearners); }
                      if (selectedPODs) { setSelectedPODs(selectedPODs); }
                      if (learnersCount) { setLearnersCount(learnersCount); }
                    }}
                  />
                  <div className="flex items-center mt-4">
                    <span className="mr-2">Total Learners: </span>
                    <span
                      className="mr-2"
                      data-testId="grievance-user-access-total-learners-count"
                    >
                      {isSettingsEnabled ? learnersCount : 0}
                    </span>
                  </div>
                  <div className="flex justify-between mt-6">
                    <Button
                      variant="outlined"
                      color='primary'
                      onClick={() => history.goBack()}
                      testId="grievance-user-access-cancel"
                    >
                  Cancel
                    </Button>
                    <Button
                      testId="grievance-user-access-save-changes"
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                      loading={saveGrievaceSettingMutation.isLoading}
                      onClick={saveGrievaceSettingMutation.mutate}
                    >
                  Save Changes
                    </Button>
                  </div>
                </div>
              </Card.Body>
            )}
        </Card>
      </div>
    </Box>
  );
}
