import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  DateRangePicker,
  MultiSelectDropDown,
  Typography,
  LoadingSpinner
} from '@nucleos/core-ui';

import FaceCaptureModal from './FaceCaptureModal';
import { formatDateTime, getDays, getTimePeriodFilterOptions } from '../../../Lib/util';
import { useFacilitiesQuery } from '../../../hooks/useFacilities';

import Data from '../../../Middleware/Data';
import AuthenticationStore from '../../../Stores/Authentication';
import { QueryKeys } from '../../../Lib/query-keys';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import './FaceCaptureLogs.scss';

const filteredTimeOptions = [
  ...getTimePeriodFilterOptions()
    .filter(item => !['last_seven_days'].includes(item.timePeriod))
    .map(item => {
      item.val = `${item.name}|${item.val}`;
      return item;
    }),
  ...getDays().filter(item => ['month'].includes(item.timePeriod))
];

const statusOptions = [
  { title: 'All', value: 'ALL' },
  { title: 'Attempted', value: 'FLAGGED' },
  { title: 'Successful', value: 'NOT_FLAGGED' }
];

const FaceCaptureLogs = ({ userId }) => {
  // eslint-disable-next-line no-unused-vars
  const [_timePeriodTitle, start, end] = filteredTimeOptions[0].val.split('|');

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [timeSelect, setTimeSelect] = useState(filteredTimeOptions[0].val);
  const [openFaceModal, setOpenFaceModal] = useState(false);
  const [faceCapturedDetails, setFaceCapturedDetails] = useState({});
  const [sessionDetails, setSessionDetails] = useState({});
  const [biometricData, setBiometricData] = useState({});
  const [status, setStatus] = useState([statusOptions[0]]);

  const options = filteredTimeOptions.map(({ name, val, timePeriod }) => {
    return { title: name, value: val, timePeriod };
  });

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const facilitiesQuery = useFacilitiesQuery();
  const facilities = facilitiesQuery.data || [];

  const { isAuthenticated, userRole } = AuthenticationStore;

  const adminConfigurationQuery = useQuery(
    ['CONFIGURATIONS', 'ADMIN', isAuthenticated],
    () => Data.getConfigurations(),
    {
      refetchOnMount: false,
      enabled: isAuthenticated && userRole === 'admin'
    }
  );

  const minDate = adminConfigurationQuery.data && adminConfigurationQuery.data.retentionConfig
    ? moment(adminConfigurationQuery.data.retentionConfig.biometrics)
    : moment().subtract(window.nucleosConfig.capturedFaceRetentionLimit || 90, 'days');

  const faceCaptureTimelineQuery = useQuery(
    QueryKeys.FaceCaptureLogs.listing({ userId, startDate, endDate, timeZone, status: status[0].value }),
    () =>
      Data.getFaceCaptureLogsTimeline({
        userId,
        startDate: getStartOfDayDate(startDate),
        endDate: getEndOfDayDate(endDate),
        timeZone,
        status: status[0].value
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { userTimeLine: [] }
    }
  );

  const faceCaptureDetailsQuery = useQuery(
    QueryKeys.FaceCaptureLogs.listing({
      userId,
      startDate: sessionDetails.startDate,
      endDate: sessionDetails.endDate,
      timeZone,
      status: status[0].value
    }),
    () =>
      Data.getFaceCaptureLogsDetails({
        userId,
        startDate: sessionDetails.startDate,
        endDate: sessionDetails.endDate,
        timeZone,
        status: status[0].value
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { userBiometrics: [] },
      enabled: !!sessionDetails.startDate && !!sessionDetails.endDate
    }
  );

  useEffect(() => {
    if (faceCaptureTimelineQuery.data.userTimeLine.length) {
      const activityDate =
        faceCaptureTimelineQuery.data.userTimeLine[0].active_date;
      setSessionDetails({
        index: 0,
        startDate: getStartOfDayDate(activityDate),
        endDate: getEndOfDayDate(activityDate)
      });
    }
  }, [faceCaptureTimelineQuery.data]);

  useEffect(() => {
    if (
      faceCaptureDetailsQuery.data.userBiometrics &&
      sessionDetails.index !== undefined
    ) {
      setBiometricData((prevState) => {
        const newState = {
          ...prevState,
          [sessionDetails.index]: {
            isFetching: faceCaptureDetailsQuery.isFetching,
            userBiometrics: faceCaptureDetailsQuery.data.userBiometrics || []
          }
        };

        if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
          return newState;
        }

        return prevState;
      });
    }
  }, [
    faceCaptureDetailsQuery.data.userBiometrics,
    faceCaptureDetailsQuery.isFetching,
    sessionDetails.index
  ]);

  const getStartOfDayDate = (date) => {
    return moment.utc(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
  };

  const getEndOfDayDate = (date) => {
    return moment.utc(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  };

  const handleTimeSelectDropdown = (e) => {
    const { value } = e[0];
    if (!value.includes('|')) {
      setTimeSelect(value);
      return;
    }

    setStartDate(value.split('|')[1]);
    setEndDate(value.split('|')[2]);

    setTimeSelect(value);
  };

  const onFaceClick = (session) => {
    setOpenFaceModal(true);
    setFaceCapturedDetails(session);
  };

  const onFaceCaptureModalClose = () => {
    setOpenFaceModal(false);
  };

  const onAccordionToggle = (isOpen, activityDate, index) => {
    if (isOpen) {
      setSessionDetails({
        index,
        startDate: getStartOfDayDate(activityDate),
        endDate: getEndOfDayDate(activityDate)
      });
    }
  };

  const renderSessionDetails = (session, index) => {
    const facility = facilities.find(
      (item) => item.id === parseInt(session.facilityId)
    );
    return (
      <div
        className="nucleos-core flex items-center self-stretch gap-8 py-3 px-4"
        style={{
          background: index % 2 === 0 ? '#fff' : '#F8F8F8',
          borderTop: '1px solid #F2EDED'
        }}
      >
        <img
          className="object-cover h-16 w-16 rounded-lg cursor-pointer"
          src={session.signedUrl}
          alt={'Captured Face'}
          onClick={() => onFaceClick(session)}
        />
        <div className="flex items-center sm:gap-16 lg:gap-28 overflow-auto">
          {renderDetail(
            'Face Capture Date & Time',
            formatDateTime(session.time, { use24HoursFormat: true }) || 'NA'
          )}
          {renderDetail('Device Serial Number', !session.serialNumber || session.serialNumber === 'null' ? 'NA' : session.serialNumber)}
          {renderDetail('POD', session.pod || 'NA')}
          {renderDetail('Facility', facility ? facility.title : 'NA')}
          <div className="flex flex-col items-start gap-1">
            <Typography style={{ color: 'rgba(51, 51, 51, 0.6)' }}>
              Status
            </Typography>
            <Chip
              testId="status"
              rounded="full"
              variant={'outlined'}
              label={session.isFlagged ? 'Attempted' : 'Successful'}
              color={session.isFlagged ? 'error' : 'success'}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = (label, value) => (
    <div className="flex flex-col items-start gap-1">
      <Typography style={{ color: 'rgba(51, 51, 51, 0.6)' }}>
        {label}
      </Typography>
      <Typography variant="h6">{value}</Typography>
    </div>
  );

  const renderToolbar = () => {
    const timePeriodOptions = [
      ...options,
      { value: '', title: 'Custom Date', timePeriod: 'custom' }
    ];
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 18
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Showing results from {moment(startDate).format('MMM DD, YYYY')} -{' '}
            {moment(endDate).format('MMM DD, YYYY')}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            className="chart-date__text"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: '15px',
                lineHeight: '20px',
                marginRight: '8px'
              }}
            >
              Time Period:{' '}
            </span>
            <MultiSelectDropDown
              dropdownId="face-capture-logs-time-period-select"
              disableHelperText
              disableMultiSelect
              onChange={(e) => {
                handleTimeSelectDropdown(e);
              }}
              className="w-52"
              value={timePeriodOptions.filter(op => op.value === timeSelect)}
              label="Select Date"
              options={timePeriodOptions}
            />
          </div>
          <div
            className={classNames(
              'chart-date__picker-wrapper items-center',
              timeSelect ? 'hidden' : 'ml-3'
            )}
          >
            <DateRangePicker
              maxDate={new Date()}
              minDate={new Date(minDate)}
              disableHelperText
              testId="custom-date-range"
              className="w-48"
              placeholder="Select date range"
              fullWidth
              onChange={({ startDate, endDate }) => {
                setStartDate(moment(startDate).format('YYYY-MM-DD'));
                setEndDate(moment(endDate).format('YYYY-MM-DD'));
              }}
              value={{
                startDate: startDate ? moment(startDate).toDate() : new Date(),
                endDate: endDate ? moment(endDate).toDate() : new Date()
              }}
              DropdownContainerProps={{
                direction: 'left'
              }}
              infoContent={
                <Typography
                  style={{
                    color: 'rgba(51, 51, 51, 0.80)',
                    borderTop: '1px solid rgba(211, 211, 211, 0.60)',
                    padding: '6px 16px'
                  }}
                >
                  Data available until{' '}
                  {minDate.format('MMM D, YYYY')}
                </Typography>
              }
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                fontWeight: 600,
                fontSize: '15px',
                lineHeight: '20px',
                margin: '0 8px 0 16px'
              }}
            >
              Status{' '}
            </span>
            <MultiSelectDropDown
              dropdownId="face-capture-logs-status-select"
              testId="status"
              disableHelperText
              disableMultiSelect
              onChange={(e) => setStatus(e)}
              value={status}
              label="Status"
              className="w-40"
              options={statusOptions}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!faceCaptureTimelineQuery.isFetching && !faceCaptureTimelineQuery.data.userTimeLine.length) {
      return (
        <div className="flex justify-center items-center h-52 my-8">
          <GenericNoDataFound />
        </div>
      );
    }

    if (faceCaptureTimelineQuery.isFetching) {
      return (
        <div className="h-52 flex justify-center items-center">
          <LoadingSpinner size={12} />
        </div>
      );
    }

    return faceCaptureTimelineQuery.data.userTimeLine.map((item, index) => (
      <Accordion key={index} defaultOpen={!index}>
        <AccordionSummary
          onToggle={(state) =>
            onAccordionToggle(state, item.active_date, index)
          }
        >
          <div className="flex flex-1 justify-between align-center">
            <Typography variant="h5">
              {moment.utc(item.active_date).format('MMM D, YYYY')}
            </Typography>
            <Typography color="gray" variant="subtitle2">
              {+item.activity_count} sessions
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {biometricData[index] && !biometricData[index].isFetching && !biometricData[index].userBiometrics.length
            ? (
              <div className="flex justify-center items-center h-52 my-8">
                <GenericNoDataFound />
              </div>
            )
            : biometricData[index] && biometricData[index].isFetching
              ? (
                <div className="flex justify-center items-center mb-12 mt-2">
                  <LoadingSpinner size={12} />
                </div>
              )
              : (
                (
                  (biometricData[index] &&
                  biometricData[index].userBiometrics) ||
                []
                ).map((session, index) =>
                  renderSessionDetails(session, index)
                )
              )}
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <>
      <div className="pb-12 face-capture-logs-container">
        {renderToolbar()}
        {renderContent()}
      </div>
      <FaceCaptureModal
        open={openFaceModal}
        image={faceCapturedDetails.signedUrl}
        date={formatDateTime(faceCapturedDetails.time, { use24HoursFormat: true })}
        onClose={onFaceCaptureModalClose}
      />
    </>
  );
};

export default FaceCaptureLogs;
