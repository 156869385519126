import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Button,
  LoadingSpinner,
  SlidingPortal,
  Typography
} from '@nucleos/core-ui';
import Data from '../../../../Middleware/Data';
import { GrievanceInfoAndActions } from '../../../../Components/Grievance/GrievanceInfoAndActions';
import { GrievanceResponses } from '../../../../Components/Grievance/GrievanceResponses';
import DataStore from '../../../../Stores/DataStore';
import GrievanceTypeChip from '../../../../Components/Grievance/GrievanceTypeChip';
import GrievanceNoPermissionIcon from '../../../../Assets/graphics/grievance_access_restricted.svg';
import { GenericErrorDetectorForMutations } from '../../../../Middleware/Api';
import toast from 'react-hot-toast';
import moment from 'moment';
import { observer } from 'mobx-react';
import { Icon } from '../../../../Components/Shared/Icon';

export default observer(function GrievanceDetails ({
  onGrievanceUpdate,
  onNext,
  onPrevious,
  disableNext,
  disablePrev
}) {
  const [rightArrowZIndex, setRightArrowZIndex] = useState(1600);
  const history = useHistory();
  const grievanceId = +new URLSearchParams(history.location.search).get(
    'grievanceId'
  );
  const requestNumberParams = new URLSearchParams(history.location.search).get(
    'requestNumber'
  );
  const requestTypeParams = new URLSearchParams(history.location.search).get(
    'requestType'
  );
  const grievanceQuery = useQuery(
    ['grievance', grievanceId],
    () =>
      Data.getAdminGrievance(grievanceId).then(
        GenericErrorDetectorForMutations
      ),
    {
      enabled: !!grievanceId,
      select: (data) => data.grievance
    }
  );

  const onSliderClose = () => {
    DataStore.setPageTitleDetail('Grievances and Forms > Responses');
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('grievanceId');
    searchParams.delete('requestType');
    searchParams.delete('requestNumber');
    history.replace(`/forms/admin/grievances?${searchParams.toString()}`);
  };

  const grievancePDFLoading = DataStore.grievancePDFGenerating.includes(grievanceId);

  const generatePDFMutation = useMutation(
    ['GRIEVANCE_PDF_MUTATION', grievanceId],
    async () => {
      DataStore.setGrievancePDFLoading(grievanceId);

      const toastId = toast.loading('The PDF can take some time to generate. The download will start automatically post that. Thank you for your patience.', {
        duration: 5000,
        style: {
          maxWidth: '70%'
        }
      });

      try {
        const response = await fetch(
          process.env.REACT_APP_PUBLIC_URL +
          `/forms/admin/grievances/${grievanceId}/pdf`,
          {
            credentials: 'include',
            method: 'GET'
          }
        );

        const pdfBlob = await response.blob();

        const url = window.URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${requestType === 'GRIEVANCE' ? 'Grievance' : 'Action Request'}_${requestNumber}_${moment().format('MMDDYYYY_HHmmss')}.pdf`;

        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.error(e);

        toast.dismiss(toastId);

        toast.error('Failed to generate PDF.');
      } finally {
        DataStore.setGrievancePDFLoading(grievanceId, true);
      }
    }
  );

  const grievanceErrorData = grievanceQuery.error
    ? grievanceQuery.error.response || {}
    : {};

  const requestNumber = grievanceQuery.data
    ? grievanceQuery.data.requestNumber
    : requestNumberParams || grievanceErrorData.requestNumber;

  const requestType = grievanceQuery.data
    ? grievanceQuery.data.requestType
    : requestTypeParams || grievanceErrorData.requestType;

  const GrievanceHeader = (
    <div className="flex justify-between">
      <Typography variant="h2">
        {requestNumber}
        {requestType
          ? (
            <GrievanceTypeChip
              className="ml-4"
              requestType={
                grievanceQuery.data
                  ? grievanceQuery.data.requestType
                  : requestType
              }
            />
          )
          : null}
      </Typography>
      <div className="flex gap-x-2 items-center">
        <Button
          size="small"
          variant="outlined"
          onClick={generatePDFMutation.mutate}
          disabled={!!grievancePDFLoading}
          style={{ height: '2rem' }}
        >
          {grievancePDFLoading
            ? (
              <span className="flex items-center">
                <span className="pr-2">Generating PDF...</span>
                <LoadingSpinner size={4} />
              </span>
            )
            : (
              <span className="flex items-center">
                <Icon icon="Pdf" className="mr-2" /> Save as PDF
              </span>
            )}
        </Button>

        <Button variant="text" color="gray" onClick={onSliderClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <Typography>Close</Typography>
        </Button>
      </div>
    </div>
  );

  if (
    grievanceQuery.status === 'error' &&
    grievanceQuery.error &&
    grievanceQuery.error.response &&
    grievanceQuery.error.response.success === false
  ) {
    return (
      <div id="grievance-details">
        {grievanceId !== 0 && (
          <>
            <LeftArrow onClick={onPrevious} disabled={true} />
            <RightArrow onClick={onNext} disabled={true} />
          </>
        )}
        <SlidingPortal isOpen={!!grievanceId} setIsOpen={onSliderClose}>
          <div className="p-4">
            <div className="border-b border-gray-main py-2">
              {GrievanceHeader}
            </div>
            <div
              className="flex flex-col items-center justify-center"
              style={{ marginTop: '20vh' }}
            >
              <img src={GrievanceNoPermissionIcon} alt="Not allowed" />
              <div className="w-96 flex flex-col items-center justify-center text-center">
                <Typography
                  variant="subtitle1"
                  className="mt-4 mb-2 text-xl text-center font-bold"
                >
                  {`You do not have permission to view this ${requestType === 'ACTION_REQUEST' ? 'action request' : 'grievance'}.`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className="text-center"
                >
                  {`If your access for this ${requestType === 'ACTION_REQUEST' ? 'action request' : 'grievance'} was mistakenly removed,
                  please contact the support team.`}
                </Typography>
              </div>
            </div>
          </div>
        </SlidingPortal>
      </div>
    );
  }

  if (grievanceQuery.status === 'error') {
    return (
      <div id="grievance-details">
        <SlidingPortal isOpen={!!grievanceId} setIsOpen={onSliderClose}>
          <div className="p-4">
            <div className="border-b border-gray-main py-2">
              {GrievanceHeader}
            </div>
            <div
              className="flex flex-col items-center justify-center"
              style={{ marginTop: '20vh' }}
            >
              <div className="w-96 flex flex-col items-center justify-center text-center">
                <Typography
                  variant="subtitle1"
                  className="mt-4 mb-2 text-xl text-center font-bold"
                >
                  Something went wrong.
                </Typography>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className="text-center"
                >
                  Please try again after sometime or contact the support team.
                </Typography>
              </div>
            </div>
          </div>
        </SlidingPortal>
      </div>
    );
  }

  if (grievanceQuery.status === 'loading' || grievanceQuery.status === 'idle') {
    return (
      <div id="grievance-details">
        {grievanceId !== 0 && (
          <>
            <LeftArrow onClick={onPrevious} disabled={true} />
            <RightArrow onClick={onNext} disabled={true} />
          </>
        )}
        <SlidingPortal isOpen={!!grievanceId} setIsOpen={onSliderClose}>
          <div className="p-4">
            <div className="border-b border-gray-main py-2">
              {GrievanceHeader}
            </div>
            <div className="flex flex-col items-center justify-center">
              <LoadingSpinner className="mt-52 mb-4 mr-4" />
              <Typography variant="h3">Loading...</Typography>
            </div>
          </div>
        </SlidingPortal>
      </div>
    );
  }

  return (
    <div id="grievance-details">
      {grievanceId !== 0 && (
        <>
          <LeftArrow onClick={onPrevious} disabled={disablePrev} />
          <RightArrow
            onClick={onNext}
            disabled={disableNext}
            rightArrowZIndex={rightArrowZIndex}
          />
        </>
      )}
      <SlidingPortal isOpen={!!grievanceId} setIsOpen={onSliderClose}>
        <div className="p-4">
          {GrievanceHeader}
          <div
            style={{ margin: '10px 0 20px 0' }}
            className="w-full border-b border-bg-gray-main"
          />

          <div className="flex">
            <div style={{ width: '70%' }} className="pl-0">
              <GrievanceResponses
                grievance={grievanceQuery.data}
                onResponseSubmit={() => grievanceQuery.refetch()}
              />
            </div>
            <div className="px-4" style={{ width: '30%' }}>
              <GrievanceInfoAndActions
                grievance={grievanceQuery.data}
                onGrievanceUpdate={() => {
                  grievanceQuery.refetch();
                  onGrievanceUpdate();
                }}
                toggleModal={(open) => setRightArrowZIndex(open ? 0 : 1600)}
              />
            </div>
          </div>
        </div>
      </SlidingPortal>
    </div>
  );
});

const RightArrow = ({
  onClick = () => { },
  disabled,
  rightArrowZIndex = 1600
}) => (
  <div className="nucleos-core">
    <div
      className="w-8 h-8 cursor-pointer rounded-full border border-gray-main bg-background-light fixed right-8 mb-6 flex justify-center items-center"
      style={{
        zIndex: rightArrowZIndex,
        cursor: disabled ? 'not-allowed' : undefined,
        top: '50vh'
      }}
      onClick={disabled ? undefined : onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </div>
  </div>
);
const LeftArrow = ({ onClick = () => { }, disabled }) => (
  <div className="nucleos-core">
    <div
      className="w-8 h-8 cursor-pointer rounded-full border border-gray-main bg-background-light fixed left-8 mb-6 flex justify-center items-center"
      style={{
        zIndex: 1600,
        cursor: disabled ? 'not-allowed' : undefined,
        top: '50vh'
      }}
      onClick={disabled ? undefined : onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </div>
  </div>
);
