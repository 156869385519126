import React from 'react';
import NoUsageHistoryFound from '../../Assets/graphics/no_data_found.svg';

export const GenericNoDataFound = ({ className, size, primaryMessage, secondaryMessage }) => {
  return (
    <div className="nucleos-core">
      <div className={`flex justify-center items-center flex-col ${className}`}>
        <img src={NoUsageHistoryFound} alt="No data found" width={size === 'small' ? 80 : undefined} />
        <div className="text-xl font-bold" style={{ color: '#333333' }}>
          {primaryMessage || 'No data available!'}
        </div>
        <div
          className="text-base font-normal mt-3 w-85 text-center"
          style={{ color: 'rgba(51, 51, 51, 0.7)' }}
        >
          {secondaryMessage || 'Sorry, there are no results available.'}
        </div>
      </div>
    </div>
  );
};
