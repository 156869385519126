import { Switch, Typography, Checkbox, Box } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@nucleos/core-ui';
import React, { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import Data from '../../Middleware/Data';
import { QueryKeys } from '../../Lib/query-keys';

export default function AccountNotificationsCollapse ({
  title,
  handleSettingsChange,
  activities = [],
  isDisabled
}) {
  const allCategoriesCheckboxRef = useRef();

  const categoriesType = (activities.find((activity) => activity.hasCategories) || {}).categoriesType;

  const categoriesQuery = useQuery(
    QueryKeys.Categories.listing({
      type: categoriesType
    }),
    () => Data.getAllGrievanceCategories({
      requestType: categoriesType
    }),
    {
      select: (data) => data.categories,
      placeholderData: { categories: [] },
      enabled: !!activities.filter((activity) => activity.hasCategories).length
    }
  );

  useEffect(() => {
    if (!allCategoriesCheckboxRef.current) { return; }
    if (
      !activities[1] &&
      !activities[1].selectedCategoriesIDs &&
      !categoriesQuery.data
    ) { return; }

    if (
      activities[1].selectedCategoriesIDs.length !==
        categoriesQuery.data.length &&
      activities[1].selectedCategoriesIDs.length !== 0
    ) {
      allCategoriesCheckboxRef.current.indeterminate = true;
    } else {
      allCategoriesCheckboxRef.current.indeterminate = false;
    }
  }, [allCategoriesCheckboxRef.current, categoriesQuery.data, activities]);

  const isEmailNotificationEnabled = activities.some(
    (activity) => activity.isEmailNotificationEnabled
  );
  const isPushNotificationEnabled = activities.some(
    (activity) => activity.isPushNotificationEnabled
  );

  let subtitle;
  if (!isEmailNotificationEnabled && !isPushNotificationEnabled) {
    subtitle = 'Notifications are disabled';
  } else if (isEmailNotificationEnabled && isPushNotificationEnabled) {
    subtitle = 'Email and Push notification';
  } else if (!isEmailNotificationEnabled && isPushNotificationEnabled) {
    subtitle = 'Push notification only';
  } else if (isEmailNotificationEnabled && !isPushNotificationEnabled) {
    subtitle = 'Email notification only';
  }

  return (
    <div className="nucleos-core">
      <Accordion className="m-4 rounded-md border border-gray-main">
        <AccordionSummary>
          <div className="flex items-center">
            <div className="p-2" onClick={(e) => e.stopPropagation()}>
              <Switch
                color='primary'
                checked={!isDisabled && activities.some(
                  (activity) =>
                    activity.isEmailNotificationEnabled ||
                    activity.isPushNotificationEnabled
                )}
                onChange={(event) => {
                  activities.forEach((activity) => {
                    handleSettingsChange({
                      key: activity.key,
                      settingType: 'EMAIL',
                      value: event.target.checked
                    });
                    handleSettingsChange({
                      key: activity.key,
                      settingType: 'PUSH',
                      value: event.target.checked
                    });
                  });
                }}
                disabled={isDisabled}
              />
            </div>
            <div className="ml-4">
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="pl-0 pr-0">
          <div className="flex justify-between py-2 pl-12 bg-gray-100">
            <Typography color="textSecondary">Activity Type</Typography>
            <div className="flex mr-20">
              <svg
                className="mr-9"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.81217 3.66602H17.1872C17.9481 3.66597 18.6803 3.95711 19.2334 4.47971C19.7865 5.00232 20.1187 5.71678 20.1618 6.47652L20.1663 6.64518V15.3535C20.1664 16.1145 19.8752 16.8466 19.3526 17.3997C18.83 17.9528 18.1156 18.285 17.3558 18.3281L17.1872 18.3327H4.81217C4.05121 18.3327 3.31908 18.0416 2.76596 17.519C2.21284 16.9964 1.88067 16.2819 1.83759 15.5222L1.83301 15.3535V6.64518C1.83296 5.88422 2.1241 5.15209 2.64671 4.59897C3.16931 4.04585 3.88377 3.71368 4.64351 3.6706L4.81217 3.66602ZM18.7913 8.59127L11.3205 12.5238C11.2362 12.5683 11.1434 12.5948 11.0483 12.6016C10.9531 12.6084 10.8576 12.5953 10.7678 12.5632L10.6798 12.5247L3.20801 8.59218V15.3535C3.20802 15.7561 3.35941 16.144 3.63213 16.4401C3.90485 16.7363 4.27895 16.9191 4.68017 16.9522L4.81217 16.9577H17.1872C17.5899 16.9576 17.9779 16.8061 18.2741 16.5332C18.5702 16.2603 18.7529 15.886 18.7858 15.4846L18.7913 15.3535V8.59127ZM17.1872 5.04102H4.81217C4.40958 5.04103 4.02172 5.19242 3.72557 5.46514C3.42942 5.73786 3.24664 6.11196 3.21351 6.51318L3.20801 6.64518V7.03843L10.9997 11.1387L18.7913 7.03752V6.64518C18.7913 6.24245 18.6398 5.85446 18.3669 5.55828C18.094 5.26211 17.7196 5.07942 17.3183 5.04652L17.1872 5.04102Z"
                  fill="#333333"
                />
              </svg>

              <svg
                className="mr-9"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00009 0.830084C10.7845 0.827738 12.4997 1.52019 13.7823 2.76073C15.065 4.00127 15.8142 5.69243 15.8714 7.47592L15.8751 7.70508V11.4607L17.1401 14.3537C17.2162 14.528 17.2477 14.7185 17.2317 14.9081C17.2158 15.0976 17.1529 15.2802 17.0487 15.4394C16.9446 15.5985 16.8025 15.7293 16.6352 15.8198C16.4679 15.9103 16.2807 15.9577 16.0905 15.9578L11.7501 15.9606C11.751 16.6765 11.4727 17.3646 10.9744 17.8785C10.476 18.3925 9.79685 18.6918 9.08125 18.713C8.36565 18.7341 7.67002 18.4753 7.1422 17.9917C6.61438 17.508 6.29598 16.8376 6.25467 16.1228L6.25009 15.9578H1.91884C1.72892 15.9576 1.54202 15.9102 1.37494 15.8199C1.20786 15.7296 1.06582 15.5992 0.961584 15.4405C0.857347 15.2817 0.794174 15.0995 0.777739 14.9103C0.761304 14.7211 0.792122 14.5308 0.867423 14.3564L2.12509 11.4616V7.70508C2.12509 3.89633 5.19776 0.830084 9.00009 0.830084ZM10.3751 15.9588L7.62509 15.9606C7.62503 16.3138 7.76088 16.6535 8.00449 16.9092C8.2481 17.1649 8.58077 17.3171 8.93355 17.3342C9.28633 17.3513 9.63216 17.232 9.89935 17.001C10.1665 16.77 10.3346 16.445 10.3687 16.0935L10.3742 15.9588H10.3751ZM9.00009 2.20508C5.95676 2.20508 3.50009 4.65625 3.50009 7.70508V11.7476L2.26809 14.5838H15.7403L14.5001 11.7485V7.717L14.4964 7.51075C14.4488 6.08572 13.8485 4.73513 12.8227 3.74487C11.7968 2.75461 10.4259 2.20238 9.00009 2.20508Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </div>
          {activities.map((activity, index) => (
            <div key={`${activity.title}-${index}`}>
              <div
                className={classNames('flex justify-between py-4 mx-12', {
                  'border-b border-gray-main': !activity.hasCategories
                })}
              >
                <div className='flex flex-col'>
                  <Typography variant="body2">{activity.title}</Typography>
                  {
                    activity.subtitle
                      ? (
                        <Typography variant="caption" color='grey'>{activity.subtitle}</Typography>
                      )
                      : null
                  }
                </div>
                <div className="flex mr-7">
                  <Checkbox
                    sx={{ padding: '2px' }}
                    className="mr-8"
                    disabled={
                      isDisabled ||
                      (activity.keepAtLeastOneSelected &&
                      activity.isEmailNotificationEnabled
                        ? !activity.isPushNotificationEnabled
                        : false)
                    }
                    checked={activity.isEmailNotificationEnabled}
                    onChange={(_, checked) =>
                      handleSettingsChange({
                        key: activity.key,
                        settingType: 'EMAIL',
                        value: checked
                      })
                    }
                  />
                  <Checkbox
                    sx={{ padding: '4px' }}
                    className="mr-8"
                    disabled={
                      isDisabled ||
                      (activity.keepAtLeastOneSelected &&
                      activity.isPushNotificationEnabled
                        ? !activity.isEmailNotificationEnabled
                        : false)
                    }
                    checked={activity.isPushNotificationEnabled}
                    onChange={(_, checked) =>
                      handleSettingsChange({
                        key: activity.key,
                        settingType: 'PUSH',
                        value: checked
                      })
                    }
                  />
                </div>
              </div>
              {activity.hasCategories && (
                <div
                  className="ml-20 my-4 flex flex-col flex-wrap"
                  style={{ maxHeight: 200 }}
                >
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      sx={{ padding: '4px' }}
                      color="primary"
                      disabled={
                        isDisabled ||
                      !(
                        activity.isPushNotificationEnabled ||
                        activity.isEmailNotificationEnabled
                      )
                      }
                      onChange={(_, checked) =>
                        handleSettingsChange({
                          key: activity.key,
                          settingType: 'CATEGORY',
                          categories: checked
                            ? categoriesQuery.data.map((cat) => cat.id)
                            : []
                        })
                      }
                      checked={
                        categoriesQuery.data.length ===
                      activity.selectedCategoriesIDs.length
                      }
                      ref={allCategoriesCheckboxRef}
                    />
                    <Typography fontSize="14px">All categories</Typography>
                  </Box>
                  {categoriesQuery.data.map((cat) => (
                    <Box display="flex" alignItems="center" key={cat.name}>
                      <Checkbox
                        sx={{ padding: '2px' }}
                        color="primary"
                        label={cat.name}
                        id={`notification-settings-${title}-category-${cat.id}`}
                        disabled={
                          isDisabled ||
                          !(
                            activity.isPushNotificationEnabled ||
                            activity.isEmailNotificationEnabled
                          )
                        }
                        onChange={() =>
                          handleSettingsChange({
                            key: activity.key,
                            settingType: 'CATEGORY',
                            categories: activity.selectedCategoriesIDs.includes(
                              cat.id
                            )
                              ? activity.selectedCategoriesIDs.filter(
                                (id) => id !== cat.id
                              )
                              : [...activity.selectedCategoriesIDs, cat.id]
                          })
                        }
                        checked={
                          activity.selectedCategoriesIDs
                            ? activity.selectedCategoriesIDs.includes(cat.id)
                            : false
                        }
                      />
                      <Typography fontSize="14px">{cat.name}</Typography>
                    </Box>
                  ))}
                </div>
              )}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
