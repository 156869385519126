import React from 'react';
import PropTypes from 'prop-types';
import { Switch as SwitchCom, styled } from '@mui/material';
import './Form.scss';

const PREFIX = 'Switch';
const classes = {
  input: `${PREFIX}-input`,
  statusText: `${PREFIX}-statusText`,
  iOSSwitchBase: `${PREFIX}-iOSSwitchBase`,
  iOSChecked: `${PREFIX}-iOSChecked`,
  iOSBar: `${PREFIX}-iOSBar`,
  iOSIcon: `${PREFIX}-iOSIcon`,
  iOSIconChecked: `${PREFIX}-iOSIconChecked`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.input}`]: {
    display: 'flex',
    width: '60%',
    justifyContent: 'left',
    alignItems: 'center'
  },
  [`& .${classes.statusText}`]: {
    fontWeight: 500,
    marginLeft: '20px'
  },
  [`& .${classes.iOSSwitchBase}`]: {
    '&$iOSChecked': {
      'color': theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#52d869'
      }
    },
    'transition': theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  [`& .${classes.iOSChecked}`]: {
    'transform': 'translateX(21px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none'
    }
  },
  [`& .${classes.iOSBar}`]: {
    borderRadius: 13,
    width: 42,
    height: 20,
    marginTop: -10,
    marginLeft: -18,
    border: 'solid 1px',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  [`& .${classes.iOSIcon}`]: {
    width: 18,
    height: 18
  },
  [`& .${classes.iOSIconChecked}`]: {
    boxShadow: theme.shadows[1]
  }
}));

const Switch = props => {
  const {
    className,
    error,
    label,
    name,
    onChange,
    touched,
    value,
    labelObject,
    statusLabel,
    hideMargin,
    disabled
  } = props;
  const classNameAddOn = className ? ` ${className}` : '';
  const containerClassName = `nucleos-field-container input-container${classNameAddOn}`;
  const wrapperClassName = `nucleos-field-label-wrapper input-wrapper${classNameAddOn}`;
  const labelClassName = `nucleos-label input-label${classNameAddOn}`;
  const errorClassName = `nucleos-field-error input-error${classNameAddOn}`;
  const justify = hideMargin ? { justifyContent: 'flex-start' } : {};
  return (
    <Root className={containerClassName}>
      <div className={wrapperClassName} style={justify}>
        { !hideMargin && <div style={{ marginRight: '20px' }}> {labelObject && labelObject} </div> }
        {label && <label className={labelClassName}>{label}:</label>}
        <div className={classes.input}>
          <SwitchCom
            classes={{
              switchBase: classes.iOSSwitchBase,
              bar: classes.iOSBar,
              icon: classes.iOSIcon,
              iconChecked: classes.iOSIconChecked,
              checked: classes.iOSChecked
            }}
            disableRipple
            checked={value}
            onChange={onChange}
            value={value}
            name={name}
            disabled={!!disabled}
          />
          <span className={classes.statusText}>{statusLabel}</span>
        </div>
      </div>
      {error && touched && <span className={errorClassName}>{error}</span>}
    </Root>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  statusLabel: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  touched: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default (Switch);
