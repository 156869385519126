import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, MenuItem, Button, Box } from '@mui/material';

import { lang } from './lang.js';

import './ConsentScreen.scss';

import { Data } from '../../Middleware/NucleosApi';
import AuthenticationStore from '../../Stores/Authentication';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid.js';
import { AppContext } from '../../Contexts/AppContext.js';
import withAppContext from '../../HOC/withAppContext.js';

class ConsentScreen extends Component {
  constructor (props) {
    super(props);

    this.state = {
      lang: 'ENG'
    };
  }

  onLanguageChange = (event) => {
    this.setState({ lang: event.target.value });
  }

  onConsent = () => {
    Data.setInitialConsentStatus()
      .then((res) => {
        if (res === 'success') {
          AuthenticationStore.setInitialConsent(true);
          this.props.history.push('/home');
        } else {
          // this.props.history.push('/logout');

          this.props.appContext.logout();
        }
      });
  }

  render () {
    return (
      <Box width="100%" display='flex' justifyContent="center">
        <div id='consent-screen' className='home'>
          <FlexTileGrid gridColTiers={{ xs: 12 }} tileStyle={{ padding: '25px' }}>
            <div style={{ position: 'relative' }}>
              <div className='logo-container'>

                <AppContext.Consumer>
                  {
                    (appDataConfig) => (
                      <img src={appDataConfig.logoURL} className='logo' alt="Brand Logo" />
                    )
                  }
                </AppContext.Consumer>

                <div className='language-select-container'>
                  <Select
                    value={lang[this.state.lang].code}
                    onChange={this.onLanguageChange}
                  >
                    {Object.keys(lang).map(language => {
                      return (
                        <MenuItem value={lang[language].code}>{lang[language].langname}</MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div id='iframe-container'>
                <h2>{lang[this.state.lang].heading}</h2>
                <iframe height='400' width='100%' src={`/tos-consent-text/${this.state.lang}.html`} title='terms of service' />
              </div>
              <div className='button-container'>
                <Button color='primary' variant='contained' onClick={this.props.appContext.logout}>
                  {lang[this.state.lang].logout}
                </Button>
                <Button onClick={this.onConsent} color='primary' variant='contained'>
                  {lang[this.state.lang].consent}
                </Button>
              </div>
            </div>
          </FlexTileGrid>
        </div>
      </Box>
    );
  }
}

export default withRouter(withAppContext(ConsentScreen));
