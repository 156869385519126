import React, { useEffect, useCallback, useState } from 'react';
import { useParams/*, useHistory */ } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Divider,
  Grid
} from '@mui/material';
import {
  Input,
  validateString,
  validateEmail,
  validatePassword,
  validateSpecialCharacterInString,
  validateStringNotToStartWithNumber
} from '../../Components/Form';
import ToastMessage from '../../Components/Widgets/ToastMessage';
import DataStore from '../../Stores/DataStore';
import AuthenticationStore from '../../Stores/Authentication';
import EmailVerify from '../../Components/Shared/EmailVerify';
import { Icon } from '../../Components/Shared/Icon';
// import { LOGOUT_PATH } from '../../constants/paths';
import { useAppContext } from '../../Contexts/AppContext';

const UpdateSelfForm = () => {
  const appContext = useAppContext();
  const { userRole } = AuthenticationStore;
  const isLearner = userRole === 'learner';
  const { updateUser, self, getPassword, currentUser, setEmailVerification } = DataStore;
  const { uid } = useParams();
  const [shouldHidePassword, setShouldHidePassword] = useState(true);
  const { password: userPassword } = currentUser;
  // const history = useHistory();

  const [toast, setToast] = useState(null);

  useEffect(() => {
    if (self.emailVerified == false && !localStorage.getItem('emailVerifyBannerClosed')) {
      setEmailVerification(true);
    } else {
      setEmailVerification(false);
    }
  }, [self.emailVerified]);

  async function onUpdateUser (uid, data, self, isPasswordUpdated) {
    const result = await updateUser(uid, data, self);
    if (!result.error) {
      if (result.changed) {
        setToast({
          type: 'success',
          message: 'User updated successfully'
        });
      }
      if (isPasswordUpdated) {
        // history.push(LOGOUT_PATH);

        appContext.logout();
      }
    } else {
      const serverError = result.error === 'email taken' ? 'Email address is already taken' : 'A server side error occured. If this continues, please contact your system administrator';

      setToast({
        type: 'error',
        message: serverError
      });
    }
  }

  async function onSubmit (values, { setSubmitting }) {
    const { password, email, firstName, lastName } = values;
    /* If we re-submit the user's existing email address,
      we'll get back an error, so we want to make sure we
      check the submitted value against the current value. */
    const currentEmailAddress = self.emailAddresses && self.emailAddresses.length > 0 ? self.emailAddresses[0] : '';
    const emailNotUpdated = email === currentEmailAddress;
    const data = {
      firstName,
      lastName,
      ...(emailNotUpdated ? null : { email }),
      password
    };
    await onUpdateUser(self.uid, data, true, !!password);
    setSubmitting(false);
  }

  function togglePasswordDisplay () {
    if (uid) {
      getPassword(uid);
    }
    setShouldHidePassword(!shouldHidePassword);
  }

  function validate (values) {
    const { firstName, lastName, email, password, passwordConfirm } = values;
    const firstNameError = validateString(firstName, true, 2, 50) || validateSpecialCharacterInString(firstName) || validateStringNotToStartWithNumber(firstName);
    const lastNameError = validateString(lastName, true, 2, 50) || validateSpecialCharacterInString(lastName) || validateStringNotToStartWithNumber(lastName);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password, passwordConfirm);
    const errors = {
      ...(firstNameError ? { firstName: firstNameError } : null),
      ...(lastNameError ? { lastName: lastNameError } : null),
      ...(emailError ? { email: emailError } : null),
      ...(passwordError ? { password: passwordError } : null)
    };
    return errors;
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirm: ''
  };

  const formik = useFormik({ onSubmit, validate, initialValues });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
  } = formik;

  const setFieldValuesFromStore = useCallback(() => {
    const { firstName, lastName, emailAddresses, password } = self || { firstName: '', lastName: '', emailAddresses: [''] };

    const user = {
      firstName: firstName || '',
      lastName: lastName || '',
      email: emailAddresses ? emailAddresses[0] : '',
      password: password || '',
      passwordConfirm: ''
    };

    Object.keys(user).forEach(key => {
      setFieldValue(key, user[key], false);
    });
  }, [self, setFieldValue]);

  useEffect(
    () => {
      const password = userPassword || '';
      setFieldValue('password', password, false);
    },
    [userPassword, setFieldValue]
  );

  useEffect(() => {
    setFieldValuesFromStore();
  }, [setFieldValuesFromStore]);

  const { firstName, lastName, email, password, passwordConfirm } = values;

  return (
    <>
      <form
        onSubmit={handleSubmit}
        method='post'
        className='settings-form'
      >
        <Grid container>
          <Grid item sm={12} md={9}>
            <Grid container direction='column' spacing={3}>
              {!isLearner && (
                <Grid item>
                  <Input
                    label='First Name'
                    name='firstName'
                    value={firstName}
                    touched={touched.firstName}
                    error={errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={
                      self.firstName
                        ? (
                          self.firstName
                        )
                        : (
                          'loading..'
                        )
                    }
                  />
                </Grid>
              )}
              {!isLearner && (
                <Grid item>
                  <Input
                    label='Last Name'
                    name='lastName'
                    value={lastName}
                    touched={touched.lastName}
                    error={errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={
                      self.lastName ? self.lastName : 'loading..'
                    }
                  />
                </Grid>
              )}
              {!isLearner && (
                <React.Fragment>
                  <Grid item>
                    <Input
                      label='Email Address'
                      name='email'
                      disable={self.emailVerified}
                      value={email}
                      touched={touched.email}
                      error={errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={
                        self.emailAddresses
                          ? (
                            self.emailAddresses[0]
                          )
                          : (
                            'loading..'
                          )
                      }
                    />
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                      <Box sx={{
                        width: '60%',
                        paddingLeft: '15px',
                        marginTop: '10px'
                      }}>
                        <EmailVerify
                          isBanner={false}

                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sx={{ width: '100%' }}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item>
                <div className='user-setting-password-visibility'>
                  <Input
                    label='Password'
                    name='password'
                    value={password}
                    error={errors.password}
                    touched={touched.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='Password'
                    type={shouldHidePassword ? 'password' : 'text'}
                    inputBtn={
                      <Button
                        sx={{
                          marginLeft: '-67px',
                          borderBottom: '0'
                        }}
                        onClick={togglePasswordDisplay}
                      >
                        {shouldHidePassword ? <Icon icon="Eye" /> : <Icon icon="ClosedEye" />}
                      </Button>
                    }
                  />
                </div>
              </Grid>
              <Grid item>
                <Input
                  label='Confirm Password'
                  name='passwordConfirm'
                  value={passwordConfirm}
                  touched={touched.passwordConfirm}
                  error={errors.passwordConfirm}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Repeat Password'
                  type={shouldHidePassword ? 'password' : 'text'}
                />
              </Grid>
              {self.uid && (
                <Grid item>
                  <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item>
                      <Button
                        style={{
                          marginRight: '10px'
                        }}
                        variant="outlined"
                        onClick={setFieldValuesFromStore}
                      >
                        Clear Changes
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ToastMessage
        message={toast && toast.message}
        open={!!toast}
        autoHideDuration={10000}
        onClose={() => setToast(null)}
        type={toast && toast.type}
      />
    </>
  );
};

export default (observer(UpdateSelfForm));
