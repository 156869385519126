import React from 'react';
import { observer } from 'mobx-react';
import AuthenticationStore from '../../Stores/Authentication';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import AdminHome from './AdminHome';
import LearnerHome from './LearnerHome';
import SFThemeHome from './ClientThemeHomepages/SFThemeHome';
import { useUserQuery } from '../../hooks/useUser';
import { maskString } from '../../Lib/util';

const theme = window.nucleosConfig.theme || '';

const HOME_BY_ROLE = {
  admin: AdminHome
};

const HOME_BY_CLIENT_THEME = {
  sf: SFThemeHome
};

function HomeWrapper () {
  const userQuery = useUserQuery();
  const userProfile = userQuery.data;

  if (AuthenticationStore.userRole !== null) {
    const consentStatus = localStorage.getItem('nucleos__consentStatus');

    if (consentStatus === 'false') {
      window.location.assign('/consent');
      return null;
    }

    const RoleHome = HOME_BY_ROLE[AuthenticationStore.userRole] || HOME_BY_CLIENT_THEME[theme] || LearnerHome;

    return (
      <Box display='flex' flexDirection='column' gap={4}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h4' fontWeight={800}>
            Welcome, {userProfile && userProfile.UserTitle
              ? `${userProfile.UserTitle.title} ${userProfile.lastName}`
              : AuthenticationStore.lastName || AuthenticationStore.username
            } &#128075;
          </Typography>

          <Box display='flex' gap={1}>
            {
              window.nucleosDeviceInfo && window.nucleosDeviceInfo.serialNumber
                ? (
                  <>
                    <Typography variant='body2'>
                      Device Serial No.:
                      {
                        maskString(
                          String(window.nucleosDeviceInfo.serialNumber),
                          String(window.nucleosDeviceInfo.serialNumber).length - 4
                        )
                      }
                    </Typography>

                    <Typography variant='body2'>
                      &bull;
                    </Typography>
                  </>
                )
                : null
            }

            <Typography variant='body2'>
              Last logged in: {moment(AuthenticationStore.lastAccessed).format('MMM D, YYYY')}
            </Typography>
          </Box>
        </Box>

        <Box>
          <RoleHome />
        </Box>
      </Box>
    );
  } else {
    window.location.assign('/');
  }
}

export default observer(HomeWrapper);
