import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import DataStore from '../../Stores/DataStore';

const ApplicationInformation = () => {
  const { appDetail } = DataStore;
  const {
    description,
    updatedAt,
    author,
    createdAt,
    subType,
    tags
  } = appDetail;
  return (
    <div className='app-detail__info-panel'>
      <p className='app-detail__info'>Description</p>
      {description}
      <p className='app-detail__info'>
        <span>Date Last Updated: </span>
        <span>
          {moment(updatedAt).format('MM/DD/YYYY')} {author && 'by'} {author}
        </span>
      </p>
      <p className='app-detail__info'>
        <span>Date Added: </span>
        <span>{moment(createdAt).format('MM/DD/YYYY')}</span>
      </p>
      {subType && (
        <p className='app-detail__info'>
          <span>Sub-Type: </span>
          <span>{subType}</span>
        </p>
      )}
      {tags && (
        <p className='app-detail__info'>
          <span>Tags: </span>
          <span>
            {tags &&
              tags
                .reduce((tags, tag) => (tags += `${tag}, `), '')
                .slice(0, -2)}
          </span>
        </p>
      )}
    </div>
  );
};

export default observer(ApplicationInformation);
