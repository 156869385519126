import React, { useState } from 'react';
import { Button, Typography } from '@nucleos/core-ui';
import { confirmAlert } from 'react-confirm-alert';
import { Icon } from '../../../../Components/Shared/Icon';
import { Box, IconButton, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../../Lib/query-keys';
import Data from '../../../../Middleware/Data';
import Authentication from '../../../../Stores/Authentication';

const SelectCategory = ({ nextStep, grievance, previousStep }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    grievance.category ? grievance.category.value : 0
  );

  const theme = useTheme();

  const categoriesQuery = useQuery(
    QueryKeys.LearnerCategories.listing({
      type: grievance.requestType,
      permissionType: 'CREATE'
    }), () => Data.getAllLearnerGrievanceCategories({
      requestType: grievance.requestType,
      permissionType: 'CREATE'
    }), {
      select: ({ categories }) =>
        categories
          .map((c) => ({
            title: c.name + `${c.shortName ? ' - ' + c.shortName : ''}`,
            value: c.id,
            ...c
          }))
          .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0)),
      enabled: Authentication.isAuthenticated,
      refetchOnMount: false
    }
  );
  const categories = categoriesQuery.data;

  const handleNext = () => {
    const category = categories.find((c) => c.value === selectedCategory);
    nextStep(category);
  };
  return (
    <div className="nucleos-core">
      <Typography variant="h1">
        {`What type of ${grievance.requestType === 'ACTION_REQUEST' ? 'action request' : 'grievance'} are you filing?`}
      </Typography>

      <div className="mt-6 grid gap-12 grid-cols-3 mobile-grid-col-2">
        {categories && categories.map((category) => (
          <div
            key={category.id}
            className={
              'relative p-6 border-2 rounded-xl bg-background-light hover:border-primary-light ' +
              (selectedCategory === category.value
                ? 'border-primary-light'
                : '')
            }
            onClick={() => {
              setSelectedCategory(category.value);
              if (
                ['Psychiatric / Mental Services', 'Medical Services', 'Dental'].includes(
                  category.title
                )
              ) {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <Box sx={{ p: 1, pb: 2 }} className="react-confirm-alert-body">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                          <IconButton onClick={onClose}>
                            <Icon icon='Close' />
                          </IconButton>
                        </Box>

                        <Box sx={{ px: 3 }}>
                          <Typography variant='body1' align='center'>
                            {`If this is a life-threatening urgent issue, please contact a Deputy directly and do not file a ${grievance.requestType === 'ACTION_REQUEST' ? 'action request' : 'grievance'}.`}
                          </Typography>

                          <div className="react-confirm-alert-button-group">
                            <Button style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={onClose}>OK</Button>
                          </div>
                        </Box>
                      </Box>
                    );
                  },
                  overlayClassName: 'app-confirmation'
                });
              }
            }}
          >
            <div className="flex  pr-10">
              <div className="mr-4">
                <img
                  src={category.newIcon || category.icon}
                  alt={category.name}
                  style={{ height: 40, width: 40 }}
                />
              </div>
              <div className="flex">
                <Typography variant="h4">{category.title}</Typography>
              </div>
              <div
                className={
                  (selectedCategory === category.value ? '' : 'hidden') +
                ' absolute right-6 top-7'
                }
              >
                <Icon icon="CheckCircle" className="text-primary-dark h-8 w-8" />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6 pb-6 flex justify-between">
        <Button
          variant="outlined"
          className="bg-background-light"
          onClick={previousStep}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={selectedCategory == 0}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default SelectCategory;
