import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

export const DoughnutChart = ({ data, disableScrollInLegend = false }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        legend: {
          display: false
        },
        responsive: false,
        maintainAspectRatio: false,
        cutoutPercentage: 60,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const label = data.labels[tooltipItem.index] || '';
              const value = data.datasets[0].data[tooltipItem.index] || 0;
              return `${label}: ${value} Hours`;
            }
          }
        },
        animation: {
          duration: 0
        }
      }
    });

    const legendContainer = document.getElementById('legend-container');

    if (legendContainer) {
      const legendHtml = myChart.data.labels
        .map((label, index) => {
          const color = myChart.data.datasets[0].backgroundColor[index];
          const percentage = Number(
            (myChart.data.datasets[0].data[index] /
              myChart.data.datasets[0].data.reduce((a, b) => a + b, 0)) *
              100
          ).toFixed(2);
          return `
          <div key=${index} class="nucleos-core">
            <div class="flex items-center mb-2">
              <span style="background-color:${color}; width: 20px; height: 20px; display: inline-block; margin-right: 8px; border-radius: 6px;"></span>
              <p>${label} (${percentage}%)</p>
            </div>
          </div>`;
        })
        .join('');

      legendContainer.innerHTML = `
        <div ${disableScrollInLegend ? '' : 'style="max-height: 180px; overflow-y: scroll'}">
        ${legendHtml}
        <div>
      `;
    }

    return () => {
      myChart.destroy();
    };
  }, [data]);

  return (
    <div className="nucleos-core">
      <div className="flex justify-center py-6">
        <canvas ref={chartRef} width={'200px'} height={'200px'} />
        <div
          id="legend-container"
          className="flex flex-col justify-center ml-8"
        />
      </div>
    </div>
  );
};

export default DoughnutChart;
