import React, { useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Typography
} from '@nucleos/core-ui';
import { formatDateTime } from '../../Lib/util';
import { SubmitGrievanceReplyForm } from './SubmitReplyForm';
import {
  GRIEVANCE_RESPONSE_STATUS,
  GRIEVANCE_STATUS
} from '../../Lib/CONSTANTS';
import { useGrievanceRole } from '../../hooks/useGrievancePermissions';
import { getNumberForAppealLevel } from '../../Pages/Forms/Admin/Grievances/utils';
import { useMutation } from 'react-query';
import Data from '../../Middleware/Data';
import {
  LearnerAcceptOrRejectUI,
  LevelDisplayName
} from './LearnerAcceptOrRejectUI';
import './GrievanceResponses.scss';
import { IconButton } from '@mui/material';
import PDFIcon from '../../Assets/pdf-icon.svg';
import DeleteIcon from '../../Assets/delete-icon.svg';
import DownloadIcon from '../../Assets/download-icon.svg';
import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import useUserPermissions from '../../hooks/useUserPermissions';
import { Icon } from '../Shared/Icon';
import { ERROR_RED } from '../../constants/colors';

const GrievanceResponseAccordion = ({
  defaultOpen,
  response,
  lernerMode,
  grievance,
  SignatureUI,
  onResponseSubmit
}) => {
  const permissions = useUserPermissions();

  const [lernerResponseStatus, setLernerResponseStatus] = useState('');

  const acceptOrRejectMutation = useMutation(
    ({ responseId }) =>
      Data.updateGrievanceResponseStatus(
        grievance.id,
        responseId,
        lernerResponseStatus
      ),
    {
      onSuccess: () => onResponseSubmit()
    }
  );

  const removeAttachmentMutation = useMutation(
    (attachmentId) =>
      Data.deleteGrievanceAttachment(
        grievance.id,
        attachmentId
      ),
    {
      onSuccess: () => {
        toast.success('Attached file removed.');

        onResponseSubmit();
      },
      onError: () => toast.error('Error while removing the attached file.')
    }
  );

  const submitLearnersResponse = (responseId) => {
    if (!lernerResponseStatus) { return; }
    acceptOrRejectMutation.mutate({ responseId });
  };

  const handleFileRemove = (e, file) => {
    e.stopPropagation();

    confirmAlert({
      customUI: ({ onClose }) => (
        <div className="nucleos-core">
          <div className="mx-auto my-0 w-7/12">
            <div
              className="flex flex-col justify-start items-center p-10 rounded-md"
              style={{ background: '#ffffff' }}
            >
              <div>
                <Icon icon="Cancel" className="h-14 w-14" style={{ fill: ERROR_RED }} />
              </div>
              <div className="text-2xl mt-6">Are you sure?</div>

              <div className="flex justify-cente mt-6">
                <Typography className="text-center">
                  <span>
                    Are you sure you want to delete&nbsp;
                    <span className="underline font-semibold">{file.filename}</span>
                    &nbsp;attachment? This process cannot be undone.
                  </span>
                </Typography>
              </div>

              <div className="flex gap-x-6 items-center mt-10">
                <Button style={{ color: '#333333' }} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    removeAttachmentMutation.mutate(file.uid);

                    onClose();
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    });
  };

  const handleDownloadFile = (e, file) => {
    e.stopPropagation();

    fetch(file.signedUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = file.filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error downloading file:', error);

        toast.error('Failed to download file.');
      });
  };

  return (
    <Accordion defaultOpen={defaultOpen}>
      <AccordionSummary>
        <div className="flex flex-1 justify-between items-center">
          <div className="flex items-center">
            <div
              className="p-1 mr-4 bg-gray-main flex justify-center items-center"
              style={{ borderRadius: '50%', width: 40, height: 40 }}
            >
              <Typography variant="h3" color="white">
                {response.User.firstName.slice(undefined, 1).toUpperCase()}
                {response.User.lastName.slice(undefined, 1).toUpperCase()}
              </Typography>
            </div>
            <div>
              <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
                {response.User.UserTitle ? response.User.UserTitle.title : ''}{' '}
                {response.User.showFirstName === true || !lernerMode ? response.User.firstName : ''}{' '}
                {response.User.lastName}
              </Typography>
              <Typography color="gray" variant="subtitle2">
                {`${LevelDisplayName[response.level]} Response`}
              </Typography>
            </div>
          </div>
          <Typography color="gray" variant="subtitle2">
            {formatDateTime(response.createdAt)}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className="overflow-y-auto">
        <Typography>{response.response}</Typography>

        {
          Array.isArray(response.attachments) && response.attachments.length
            ? (
              <div className="grid grid-cols-2 gap-4 my-4">
                {
                  response.attachments.map((file) => (
                    <div
                      className="flex justify-between items-center bg-gray-100 rounded border gap-2 p-2 w-full"
                      onClick={() => window.open(file.signedUrl, '_blank').focus()}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="flex flex-auto gap-x-2">
                        <div className="flex-none">
                          <img src={PDFIcon} style={{ height: 28 }} />
                        </div>

                        <div className="flex-1">
                          <Typography className="font-semibold break-all">
                            {file.filename}
                          </Typography>

                          <Typography variant="mini">
                            {(file.sizeInKb / 1000).toFixed(2)} MB
                          </Typography>
                        </div>
                      </div>

                      <div className="flex gap-x-2 flex-none">
                        {
                          permissions.canDeleteGrievanceAttachment(file.createdByUserUid)
                            ? (
                              <IconButton onClick={(e) => handleFileRemove(e, file)}>
                                <img src={DeleteIcon} />
                              </IconButton>
                            )
                            : null
                        }

                        {
                          permissions.canDownloadGrievanceAttachment()
                            ? (
                              <IconButton onClick={(e) => handleDownloadFile(e, file)}>
                                <img src={DownloadIcon} />
                              </IconButton>
                            )
                            : null
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            )
            : null
        }

        {lernerMode && response.status === null && (
          <LearnerAcceptOrRejectUI
            grievance={grievance}
            lernerResponseStatus={lernerResponseStatus}
            setLernerResponseStatus={setLernerResponseStatus}
            submitLearnersResponse={() => submitLearnersResponse(response.id)}
            isSubmitLoading={acceptOrRejectMutation.isLoading}
            key={response.id}
          />
        )}

        {lernerMode &&
          [
            GRIEVANCE_RESPONSE_STATUS.SATISFIED,
            GRIEVANCE_RESPONSE_STATUS.DISSATISFIED
          ].includes(response.status) && (
          <Typography variant="h5" className="mt-6">
            Your response
          </Typography>
        )}

        <div className="mt-4 inline-block">
          {response.status === GRIEVANCE_RESPONSE_STATUS.SATISFIED && (
            <Chip rounded="sharp" className="py-1 pl-2 pr-2" color="success">
              <div className="flex justify-center items-center">
                <Icon icon="ThumbUp" style={{ height: 20, marginRight: 6 }} />{' '}
                <Typography color="success">Yes, Satisfied</Typography>
              </div>
            </Chip>
          )}

          {response.status === GRIEVANCE_RESPONSE_STATUS.DISSATISFIED && (
            <Chip rounded="sharp" className="py-1 pl-2 pr-2" color="error">
              <div className="flex justify-center items-center">
                <Icon icon="ThumbDown" style={{ height: 20, marginRight: 6 }} />{' '}
                <Typography color="success">
                  {LevelDisplayName[response.level + 1] &&
                    grievance.requestType === 'GRIEVANCE'
                    ? `Appeal to ${LevelDisplayName[response.level + 1]}`
                    : 'Dissatisfied'}
                </Typography>
              </div>
            </Chip>
          )}

          {[
            GRIEVANCE_RESPONSE_STATUS.SATISFIED,
            GRIEVANCE_RESPONSE_STATUS.DISSATISFIED
          ].includes(response.status) && (
            <div>
              <div>{SignatureUI}</div>
              <div className="flex my-4">
                <Typography variant="h6" className="mr-2">
                  Responded At -
                </Typography>
                <Typography variant="body2" color="gray">
                  {formatDateTime(
                    response.learnerActedAt || response.updatedAt
                  )}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export function GrievanceResponses ({
  grievance,
  onResponseSubmit,
  lernerMode,
  showRequestInCard
}) {
  const { GrievanceResponses, createdAt, request, signature, authorization, requestType } =
    grievance;
  const grievanceRole = useGrievanceRole();

  const SignatureUI = (
    <div className="flex my-4">
      <Typography variant="h6" className="mr-2">
        Digital Signature -
      </Typography>
      <Typography>{signature}</Typography>
    </div>
  );
  const AuthorizationUI = [
    'Psychiatric / Mental Services',
    'Medical Services',
    'Dental'
  ].includes(grievance.GrievanceCategory.name) && (
    <div className="flex my-4">
      <Typography variant="h6" className="pr-2">
        {grievance.GrievanceCategory.name === 'Dental' ? 'Dental' : 'Medical and Psychiatric'} Grievance Authorization:
      </Typography>
      <Typography>{authorization}</Typography>
    </div>
  );

  const grievanceResponses = useMemo(() => {
    let temp;

    const responses = GrievanceResponses.map((e) => e).reduce(
      (responses, r) => {
        if (
          [
            GRIEVANCE_RESPONSE_STATUS.CATEGORY_CHANGE,
            GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED
          ].includes(r.status)
        ) {
          if (temp) {
            responses.push(temp);
            temp = null;
          }

          if (r.status === GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED) {
            responses.push(r);
          } else {
            temp = r;
            temp.categoryResponses = [];
          }
        } else if (temp) {
          temp.categoryResponses.unshift(r);
        } else {
          responses.push(r);
        }

        return responses;
      },
      []
    );

    if (temp) {
      responses.push(temp);
      temp = null;
    }

    return responses;
  }, [GrievanceResponses]);

  return (
    <div className="nucleos-core">
      {showRequestInCard
        ? (
          <div className="p-4 bg-background-light border border-gray-light">
            <div className="flex flex-1 justify-between align-center">
              <Typography variant="h4" style={{ marginBottom: 16 }}>
                {`${requestType === 'ACTION_REQUEST' ? 'Action' : 'Grievance'} Request Details`}
              </Typography>
              <Typography color="gray" variant="subtitle2">
                {formatDateTime(createdAt)}
              </Typography>
            </div>
            <Typography color="gray">{request}</Typography>
            {SignatureUI}
            {AuthorizationUI}
          </div>
        )
        : (
          <Accordion
            defaultOpen={
              GrievanceResponses.filter(
                (resp) => resp.status !== GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED
              ).length === 0
            }
          >
            <AccordionSummary>
              <div className="flex flex-1 justify-between align-center">
                <div>
                  <Typography variant="h4">{`${requestType === 'ACTION_REQUEST' ? 'Action' : 'Grievance'} Request Details`}</Typography>
                  <Typography color="gray" variant="subtitle2">
                    {request.slice(undefined, 20)}
                    {request.length > 20 ? '...' : ''}
                  </Typography>
                </div>
                <Typography color="gray" variant="subtitle2">
                  {formatDateTime(createdAt)}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{request}</Typography>
              {SignatureUI}
              {AuthorizationUI}
            </AccordionDetails>
          </Accordion>
        )}
      {[...grievanceResponses].reverse().map((response, index, responses) => {
        if (
          index !== responses.length - 1 &&
          response.status === GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED &&
          responses[index + 1].status ===
          GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED
        ) { return null; }

        if (response.status === GRIEVANCE_RESPONSE_STATUS.CATEGORY_CHANGE) {
          if (!response.categoryResponses.length) {
            return (
              <div
                key={`${response.GrievanceId}-${response.id}`}
                className="p-2 flex items-center justify-between items-center flex-1 pl-10 pr-4"
                style={{ backgroundColor: '#E9F0FF', margin: '1px 0px' }}
              >
                <Typography variant="body2">{response.response}</Typography>
                <Typography color="gray" variant="subtitle2">
                  {formatDateTime(response.createdAt)}
                </Typography>
              </div>
            );
          }

          return (
            <div className="category-accordion" key={`${response.GrievanceId}-${response.id}`}>
              <Accordion defaultOpen={false}>
                <AccordionSummary
                  stateIcons={{ openedIcon: OpenIcon, closedIcon: ClosedIcon }}
                >
                  <div
                    className="py-1 flex items-center justify-between items-center flex-1 pr-4"
                    style={{ backgroundColor: '#E9F0FF', margin: '1px 0px' }}
                  >
                    <div className="flex items-center">
                      <Chip
                        className="mr-2"
                        style={{ padding: '4px 8px' }}
                        rounded="full"
                        label={response.categoryResponses.length}
                      />
                      <Typography variant="body2">
                        {response.response}
                      </Typography>
                    </div>
                    <Typography color="gray" variant="subtitle2">
                      {formatDateTime(response.createdAt)}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="category-responses">
                    {response.categoryResponses.map((res) => (
                      <GrievanceResponseAccordion
                        defaultOpen={false}
                        response={res}
                        lernerMode={lernerMode}
                        SignatureUI={SignatureUI}
                        grievance={grievance}
                        onResponseSubmit={onResponseSubmit}
                      />
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        }

        if (response.status === GRIEVANCE_RESPONSE_STATUS.AUTO_ESCALATED) {
          return (
            <div
              key={`${response.GrievanceId}-${response.id}`}
              className="p-2 flex items-center justify-between items-center flex-1 pl-10 pr-4"
              style={{ backgroundColor: '#E5B12933' }}
            >
              <Typography variant="body2">{response.response}</Typography>
              <Typography color="gray" variant="subtitle2">
                {formatDateTime(response.createdAt)}
              </Typography>
            </div>
          );
        }

        return (
          <GrievanceResponseAccordion
            key={`${response.GrievanceId}-${response.id}`}
            defaultOpen={index === grievanceResponses.length - 1}
            response={response}
            lernerMode={lernerMode}
            SignatureUI={SignatureUI}
            grievance={grievance}
            onResponseSubmit={onResponseSubmit}
          />
        );
      })}
      {!lernerMode &&
        ![
          GRIEVANCE_STATUS.PENDING_FROM_LEARNER,
          GRIEVANCE_STATUS.SATISFIED,
          GRIEVANCE_STATUS.COMPLETED
        ].includes(grievance.response_status) &&
        grievanceRole.canEditGrievance({
          gLevel: getNumberForAppealLevel(grievance.appeal_level),
          gCategoryId: grievance.GrievanceCategory.id,
          gFacilityId: grievance.Facility.id
        }) && (
        <SubmitGrievanceReplyForm
          grievance={grievance}
          onSubmissionSuccess={() => onResponseSubmit()}
        />
      )}
    </div>
  );
}

const ClosedIcon = (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5069_28652)">
      <path
        d="M5.0013 3.41642L1.00373 7.30634C0.971517 7.33837 0.93327 7.36369 0.891205 7.38083C0.84914 7.39798 0.804091 7.40662 0.758667 7.40624C0.713242 7.40586 0.668344 7.39648 0.626569 7.37863C0.584794 7.36079 0.546973 7.33484 0.515294 7.30228C0.483615 7.26972 0.458708 7.23121 0.442013 7.18896C0.425317 7.14671 0.417166 7.10158 0.418031 7.05616C0.418896 7.01074 0.428759 6.96595 0.44705 6.92436C0.465341 6.88278 0.491697 6.84524 0.524592 6.81392L4.76173 2.69129C4.82587 2.62889 4.91182 2.59398 5.0013 2.59398C5.09078 2.59398 5.17674 2.62889 5.24087 2.69129L9.47801 6.81392C9.51091 6.84524 9.53726 6.88278 9.55555 6.92436C9.57385 6.96595 9.58371 7.01074 9.58457 7.05616C9.58544 7.10158 9.57729 7.14671 9.56059 7.18896C9.5439 7.23121 9.51899 7.26972 9.48731 7.30228C9.45563 7.33484 9.41781 7.36079 9.37604 7.37863C9.33426 7.39648 9.28936 7.40586 9.24394 7.40624C9.19851 7.40662 9.15347 7.39798 9.1114 7.38083C9.06933 7.36369 9.03109 7.33837 8.99887 7.30634L5.0013 3.41642Z"
        fill="#333333"
      />
    </g>
    <g clipPath="url(#clip1_5069_28652)">
      <path
        d="M4.9987 16.5836L8.99627 12.6937C9.02848 12.6616 9.06673 12.6363 9.1088 12.6192C9.15086 12.602 9.19591 12.5934 9.24133 12.5938C9.28676 12.5941 9.33166 12.6035 9.37343 12.6214C9.41521 12.6392 9.45303 12.6652 9.48471 12.6977C9.51638 12.7303 9.54129 12.7688 9.55799 12.811C9.57468 12.8533 9.58283 12.8984 9.58197 12.9438C9.5811 12.9893 9.57124 13.0341 9.55295 13.0756C9.53466 13.1172 9.5083 13.1548 9.47541 13.1861L5.23827 17.3087C5.17413 17.3711 5.08818 17.406 4.9987 17.406C4.90922 17.406 4.82326 17.3711 4.75913 17.3087L0.521987 13.1861C0.489092 13.1548 0.462737 13.1172 0.444446 13.0756C0.426154 13.0341 0.416291 12.9893 0.415426 12.9438C0.414562 12.8984 0.422712 12.8533 0.439407 12.811C0.456103 12.7688 0.48101 12.7303 0.51269 12.6977C0.544368 12.6652 0.58219 12.6392 0.623964 12.6214C0.665739 12.6035 0.710637 12.5941 0.756062 12.5938C0.801486 12.5934 0.846535 12.602 0.8886 12.6192C0.930666 12.6363 0.968913 12.6616 1.00113 12.6937L4.9987 16.5836Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0_5069_28652">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(0 10) rotate(-90)"
        />
      </clipPath>
      <clipPath id="clip1_5069_28652">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(10 10) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

const OpenIcon = (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4881_21597)">
      <path
        d="M4.9987 6.58358L8.99627 2.69366C9.02848 2.66163 9.06673 2.63631 9.1088 2.61917C9.15086 2.60202 9.19591 2.59338 9.24133 2.59376C9.28676 2.59414 9.33166 2.60352 9.37343 2.62137C9.41521 2.63921 9.45303 2.66516 9.48471 2.69772C9.51638 2.73028 9.54129 2.76879 9.55799 2.81104C9.57468 2.85329 9.58283 2.89842 9.58197 2.94384C9.5811 2.98926 9.57124 3.03405 9.55295 3.07564C9.53466 3.11722 9.5083 3.15476 9.47541 3.18608L5.23827 7.30871C5.17413 7.37111 5.08818 7.40602 4.9987 7.40602C4.90922 7.40602 4.82326 7.37111 4.75913 7.30871L0.521987 3.18608C0.489092 3.15476 0.462737 3.11722 0.444446 3.07563C0.426154 3.03405 0.416291 2.98926 0.415426 2.94384C0.414562 2.89842 0.422712 2.85328 0.439407 2.81104C0.456103 2.76879 0.48101 2.73027 0.51269 2.69772C0.544368 2.66516 0.58219 2.63921 0.623964 2.62136C0.665739 2.60352 0.710637 2.59414 0.756062 2.59376C0.801486 2.59338 0.846535 2.60202 0.8886 2.61917C0.930666 2.63631 0.968913 2.66163 1.00113 2.69366L4.9987 6.58358Z"
        fill="#333333"
      />
    </g>
    <g clipPath="url(#clip1_4881_21597)">
      <path
        d="M5.0013 13.4164L1.00373 17.3063C0.971517 17.3384 0.93327 17.3637 0.891205 17.3808C0.84914 17.398 0.804091 17.4066 0.758667 17.4062C0.713242 17.4059 0.668344 17.3965 0.626569 17.3786C0.584794 17.3608 0.546973 17.3348 0.515294 17.3023C0.483615 17.2697 0.458708 17.2312 0.442013 17.189C0.425317 17.1467 0.417166 17.1016 0.418031 17.0562C0.418896 17.0107 0.428759 16.9659 0.44705 16.9244C0.465341 16.8828 0.491697 16.8452 0.524592 16.8139L4.76173 12.6913C4.82587 12.6289 4.91182 12.594 5.0013 12.594C5.09078 12.594 5.17674 12.6289 5.24087 12.6913L9.47801 16.8139C9.51091 16.8452 9.53726 16.8828 9.55555 16.9244C9.57385 16.9659 9.58371 17.0107 9.58457 17.0562C9.58544 17.1016 9.57729 17.1467 9.56059 17.189C9.5439 17.2312 9.51899 17.2697 9.48731 17.3023C9.45563 17.3348 9.41781 17.3608 9.37604 17.3786C9.33426 17.3965 9.28936 17.4059 9.24394 17.4062C9.19851 17.4066 9.15347 17.398 9.1114 17.3808C9.06933 17.3637 9.03109 17.3384 8.99887 17.3063L5.0013 13.4164Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0_4881_21597">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(10) rotate(90)"
        />
      </clipPath>
      <clipPath id="clip1_4881_21597">
        <rect
          width="10"
          height="10"
          fill="white"
          transform="translate(0 20) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GrievanceResponses;
