import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  AppBar,
  IconButton,
  Toolbar,
  Badge,
  Menu,
  Container,
  Box,
  Avatar,
  Typography,
  Link,
  List,
  ListItemButton,
  ListItem
} from '@mui/material';
import { Button as NCButton } from '@nucleos/core-ui';
import DataStore from '../../Stores/DataStore';
import AuthStore from '../../Stores/Authentication';
import EmailVerifyBanner from '../Shared/EmailVerify';
import useUserPermissions from '../../hooks/useUserPermissions';
import { NotificationsLayout as NotificationList } from '../../Pages/Notifications';
import { useNotificationsQuery } from '../../hooks/useNotificationsQuery';
import { QueryKeys } from '../../Lib/query-keys';
import socket from '../../Lib/socket';
import { useQueryClient } from 'react-query';

import MessengerIcon from '../../Assets/icons/messenger.svg';
import NotificationBellIcon from '../../Assets/icons/notification_bell.svg';
import { useAppContext } from '../../Contexts/AppContext';
import { Icon } from '../Shared/Icon';
import { HOME_PATH } from '../../constants/paths';

const Header = ({ toggleSidebarOpen, hideSidebarIcon, hideHeaderIcons }) => {
  const appContext = useAppContext();

  const history = useHistory();
  const queryClient = useQueryClient();
  const userPermissions = useUserPermissions();

  const notificationsQuery = useNotificationsQuery();
  const unreadNotificationsCount = notificationsQuery.data.data[0] && notificationsQuery.data.data[0].unreadNotificationsCount;

  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const [anchorElNotificationsDropdown, setAnchorElNotificationsDropdown] = useState(null);

  const { self, getSelf, getRecordingConfigurations, setEmailVerification } = DataStore;

  const handleToggleUserMenu = useCallback((event) => {
    if (anchorElUserMenu) {
      return setAnchorElUserMenu(null);
    }

    setAnchorElUserMenu(event.currentTarget);
  }, [anchorElUserMenu]);

  const handleToggleNotificationsDropdown = useCallback((event) => {
    if (anchorElNotificationsDropdown) {
      return setAnchorElNotificationsDropdown(null);
    }

    setAnchorElNotificationsDropdown(event.currentTarget);
  }, [anchorElNotificationsDropdown]);

  useEffect(() => {
    socket.on('notification', () => {
      queryClient.resetQueries(QueryKeys.Notifications.listings());

      notificationsQuery.refetch();
    });

    return () => {
      socket.off('notification');
    };
  }, [notificationsQuery, notificationsQuery.refetch, queryClient]);

  useEffect(() => {
    if (self.emailVerified === false && AuthStore.userRole !== 'learner' && !localStorage.getItem('emailVerifyBannerClosed')) {
      setEmailVerification(true);
    } else {
      setEmailVerification(false);
    }
  }, [self.emailVerified, setEmailVerification]);

  useEffect(() => {
    const getUser = async () => {
      if (AuthStore.isAuthenticated && AuthStore.userRole !== 'learner') {
        await getSelf();
        await getRecordingConfigurations();
      }
    };
    getUser();
  }, [getSelf, getRecordingConfigurations]);

  const userInitials = `${AuthStore.firstName && AuthStore.firstName[0]}${AuthStore.lastName && AuthStore.lastName[0]}`;
  const isAdmin = AuthStore.userRole === 'admin';

  return (
    <div>
      {
        AuthStore.isAuthenticated
          ? <EmailVerifyBanner
            isBanner={true}
          />
          : null
      }

      <AppBar position="sticky" color='white' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Container disableGutters maxWidth={false} sx={{ px: 2 }}>
          <Toolbar disableGutters>
            <Box display='flex' justifyContent='space-between' flexGrow={1}>
              <Box display='flex' gap={1}>
                {!hideSidebarIcon
                  ? <IconButton onClick={toggleSidebarOpen}>
                    <Icon icon='MenuToggle' color='primary' />
                  </IconButton>
                  : null}

                <Link component={RouterLink} to={HOME_PATH} sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={appContext.logoURL} height={36} />
                </Link>
              </Box>

              <Box display='flex' gap={1}>
                {userPermissions.canViewMessenger() && !hideHeaderIcons
                  ? <IconButton LinkComponent={RouterLink} to={isAdmin ? '/messenger/admin' : '/messenger'}>
                    <img src={MessengerIcon} />
                  </IconButton>
                  : null}

                <Box>
                  {!hideHeaderIcons
                    ? <IconButton onClick={handleToggleNotificationsDropdown}>
                      <Badge invisible={!unreadNotificationsCount} color='error' overlap="circular" variant='dot'>
                        <img src={NotificationBellIcon} />
                      </Badge>
                    </IconButton>
                    : null}

                  <Menu
                    id="notifications-dropdown"
                    anchorEl={anchorElNotificationsDropdown}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(anchorElNotificationsDropdown)}
                    onClose={handleToggleNotificationsDropdown}
                    slotProps={{ paper: { style: { width: '500px' } } }}
                  >
                    <div className='nucleos-core'>
                      <NotificationList
                        hideHeaderSubPanel
                        isDropdownLayout
                        showCategoryFilter={false}
                        showNotificationSearch={false}
                        handleClose={handleToggleNotificationsDropdown}
                      />

                      <div className='px-4 pt-2 pb-1'>
                        <NCButton
                          size='medium'
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            handleToggleNotificationsDropdown();

                            history.push('/notifications');
                          }}
                        >
                          View All
                        </NCButton>
                      </div>
                    </div>
                  </Menu>
                </Box>

                <Box display='flex' alignItems='center'>
                  {!hideHeaderIcons
                    ? <IconButton disableRipple onClick={handleToggleUserMenu} sx={{ padding: 0 }}>
                      <Avatar sx={{ height: 36, width: 36, backgroundColor: '#D5D4EE', color: '#3A47CA', fontSize: 12, fontWeight: 700 }}>{userInitials}</Avatar>
                    </IconButton>
                    : null}

                  <Menu
                    id="user-menu"
                    anchorEl={anchorElUserMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={Boolean(anchorElUserMenu)}
                    onClose={handleToggleUserMenu}
                  >
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => {
                            appContext.logout();
                            handleToggleUserMenu();
                          }}
                        >
                          <Typography textAlign="center">Logout</Typography>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default observer(Header);
