import React from 'react';
import NoUserSelectedSVG from '../../../Assets/graphics/no_user_selected.svg';

function NoUserSelected () {
  return (
    <div className="nucleos-core">
      <div className="flex justify-center items-center flex-col p-20">
        <img src={NoUserSelectedSVG} alt="No user found" />
        <div className="text-xl font-bold" style={{ color: '#333333' }}>
          No User Selected
        </div>
        <div
          className="text-base font-normal mt-3 w-85 text-center"
          style={{ color: 'rgba(51, 51, 51, 0.7)' }}
        >
          Select the user from the above dropdown to see the activity lookup
        </div>
      </div>
    </div>
  );
}

export default NoUserSelected;
