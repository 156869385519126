import React, { useEffect, useCallback, useRef, useState } from 'react';
import moment from 'moment';

import { PlayerControlsOverlay } from './PlayerControlsOverlay';
import { PlaybackErrorOverlay } from './PlaybackErrorOverlay';
import { BufferingOverlay } from './BufferingOverlay';
import { PlayPauseOverlay } from './PlayPauseOverlay';
import { Screen } from './Screen';
import { DateSelector } from './DateSelector';
import { Seekbar } from './Seekbar';

import { PLAYER_PLAYBACK_ERRORS, PLAYER_PLAYBACK_SPEED_OPTIONS } from '../../../Lib/CONSTANTS';

import './Player.css';

export function getMinutes (date) {
  return date.getHours() * 60 + date.getMinutes();
}

const WINDOW_SIZE = 25;
const BUFFER_WINDOW = 50;

export const Player = ({
  userDetails,
  dateRange,
  getMetaData,
  getStreamChunk
}) => {
  const isFetchingChunkRef = useRef(false);
  const isFetchingSectionsRef = useRef(false);
  const streamEndedRef = useRef(false);
  const isPausedRef = useRef(false);
  const recordingsIntervalIdRef = useRef(null);
  const metadataIntervalIdRef = useRef(null);
  const frameIndexRef = useRef(0);
  const sectionIndexRef = useRef(0);
  const streamRef = useRef([]);
  const sectionsRef = useRef([]);
  const speedMultiplierRef = useRef(PLAYER_PLAYBACK_SPEED_OPTIONS[0].value);
  const cursorRef = useRef(null);

  const [sections, setSections] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [isBuffering, setIsBuffering] = useState(true);
  const [playbackError, setPlaybackError] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [currentFrame, setCurrentFrame] = useState({});

  const [playbackSpeed, setPlaybackSpeed] = useState(PLAYER_PLAYBACK_SPEED_OPTIONS[0]);
  const [selectedDate, setSelectedDate] = useState(dateRange[dateRange.length - 1]);

  const getAndSetStream = useCallback(
    async (start, end, size, sectionStartTimestamp) => {
      if (isFetchingChunkRef.current) {
        return;
      }

      isFetchingChunkRef.current = true;

      const [streamChunk, imagesBeforeTimestamp] = await getStreamChunk(start, end, size, sectionStartTimestamp);

      console.log(streamChunk, imagesBeforeTimestamp);

      if (streamChunk.length) {
        streamRef.current.push(...streamChunk);

        console.log(streamRef.current);
      } else {
        streamEndedRef.current = true;
      }

      isFetchingChunkRef.current = false;

      return imagesBeforeTimestamp;
    },
    [getStreamChunk]
  );

  const consumeStream = useCallback(async () => {
    if (streamRef.current.length <= frameIndexRef.current) {
      if (sectionIndexRef.current === sectionsRef.current.length - 1) {
        return;
      }

      return setIsBuffering(true);
    }

    setIsBuffering(false);

    console.log(frameIndexRef.current);

    const frame = streamRef.current[frameIndexRef.current];
    if (frame) {
      setCurrentFrame(frame);
    }

    setCursorPosition(sectionsRef.current[sectionIndexRef.current].startPosition + (sectionsRef.current[sectionIndexRef.current].width / sectionsRef.current[sectionIndexRef.current].totalFrames * frameIndexRef.current));

    frameIndexRef.current++;

    if (
      !streamEndedRef.current &&
      streamRef.current.length &&
      streamRef.current.length - frameIndexRef.current <= BUFFER_WINDOW
    ) {
      await getAndSetStream(
        new Date(new Date(streamRef.current[streamRef.current.length - 1].time).valueOf() + 1000).toISOString(),
        sectionsRef.current[sectionIndexRef.current].last_image_timestamp,
        WINDOW_SIZE
      );
    }

    if (frameIndexRef.current >= streamRef.current.length) {
      if (recordingsIntervalIdRef.current) {
        clearInterval(recordingsIntervalIdRef.current);
      }

      sectionIndexRef.current++;
      if (sectionIndexRef.current < sectionsRef.current.length) {
        frameIndexRef.current = 0;
        streamRef.current = [];
        streamEndedRef.current = false;

        await getAndSetStream(sectionsRef.current[sectionIndexRef.current].first_image_timestamp, sectionsRef.current[sectionIndexRef.current].last_image_timestamp, WINDOW_SIZE);

        recordingsIntervalIdRef.current = setInterval(consumeStream, (sectionsRef.current[sectionIndexRef.current].totalTime / sectionsRef.current[sectionIndexRef.current].totalFrames) / speedMultiplierRef.current);
        consumeStream();
      } else {
        sectionIndexRef.current = sectionsRef.current.length - 1;
      }
    }
  }, [getAndSetStream]);

  const togglePlayPause = useCallback(() => {
    if (recordingsIntervalIdRef.current) {
      clearInterval(recordingsIntervalIdRef.current);
    }

    if (isPaused) {
      recordingsIntervalIdRef.current = setInterval(consumeStream, (sectionsRef.current[sectionIndexRef.current].totalTime / sectionsRef.current[sectionIndexRef.current].totalFrames) / speedMultiplierRef.current);
      consumeStream();
    }

    setIsPaused(!isPaused);
    isPausedRef.current = !isPaused;
  }, [isPaused, consumeStream]);

  const reset = useCallback(async () => {
    if (recordingsIntervalIdRef.current) {
      clearInterval(recordingsIntervalIdRef.current);
    }

    if (metadataIntervalIdRef.current) {
      clearInterval(metadataIntervalIdRef.current);
    }

    await new Promise((resolve) => {
      const id = setInterval(() => {
        if (!isFetchingChunkRef.current && !isFetchingSectionsRef.current) {
          clearInterval(id);

          return resolve();
        }
      }, 50);
    });

    isFetchingChunkRef.current = false;
    isFetchingSectionsRef.current = false;
    isPausedRef.current = false;
    streamEndedRef.current = false;
    recordingsIntervalIdRef.current = null;
    metadataIntervalIdRef.current = null;
    frameIndexRef.current = 0;
    sectionIndexRef.current = 0;
    streamRef.current = [];
    sectionsRef.current = [];

    setCurrentFrame({});
    setPlaybackError(null);
    setIsBuffering(true);
    setIsPaused(false);
    setCursorPosition(0);
  }, []);

  const handleDateSelect = useCallback(async (date) => {
    await reset();

    setSelectedDate(date);
  }, [reset]);

  const jumpTo = useCallback(async (cursorPosition, section, sectionIndex) => {
    console.log(cursorPosition, section, sectionIndex);

    setIsBuffering(true);

    const cursorTimestamp = moment(section.first_image_timestamp).add(cursorPosition / 2, 'minutes').toISOString();

    if (recordingsIntervalIdRef.current) {
      clearInterval(recordingsIntervalIdRef.current);
    }

    await new Promise((resolve) => {
      const id = setInterval(() => {
        if (!isFetchingChunkRef.current) {
          clearInterval(id);

          return resolve();
        }
      }, 50);
    });

    streamRef.current = [];

    const imagesPassed = await getAndSetStream(cursorTimestamp, section.last_image_timestamp, WINDOW_SIZE, section.first_image_timestamp);

    frameIndexRef.current = imagesPassed;
    sectionIndexRef.current = sectionIndex;
    streamRef.current = [...new Array(imagesPassed).fill({}), ...streamRef.current];

    setIsBuffering(false);
    setCursorPosition(section.startPosition + (section.width / section.totalFrames * frameIndexRef.current));

    if (!isPausedRef.current) {
      recordingsIntervalIdRef.current = setInterval(consumeStream, (section.totalTime / section.totalFrames) / speedMultiplierRef.current);
      consumeStream();
    }
  }, [getAndSetStream, consumeStream]);

  const handlePlaybackSpeedChange = useCallback(([selectedSpeed]) => {
    console.log('playback speed: ', selectedSpeed);

    speedMultiplierRef.current = selectedSpeed.value;
    setPlaybackSpeed(selectedSpeed);

    if (recordingsIntervalIdRef.current) {
      clearInterval(recordingsIntervalIdRef.current);
    }

    if (!isPausedRef.current) {
      recordingsIntervalIdRef.current = setInterval(consumeStream, (sectionsRef.current[sectionIndexRef.current].totalTime / sectionsRef.current[sectionIndexRef.current].totalFrames) / speedMultiplierRef.current);
      consumeStream();
    }
  }, [consumeStream]);

  const getSectionsData = useCallback(async () => {
    if (isFetchingSectionsRef.current || !selectedDate) {
      return;
    }

    console.log('getting sectionsData: ', new Date(), Date.now());

    isFetchingSectionsRef.current = true;

    const metadata = await getMetaData(selectedDate);

    const sectionsData = metadata.map((section) => {
      const startTimeInMinutes = getMinutes(new Date(section.first_image_timestamp));
      const endTimeInMinutes = getMinutes(new Date(section.last_image_timestamp));

      return {
        ...section,
        totalTime: (endTimeInMinutes - startTimeInMinutes) * 60 * 1000,
        totalFrames: section.photos,
        startPosition: startTimeInMinutes * 2,
        width: (endTimeInMinutes - startTimeInMinutes) * 2
      };
    });

    console.log(sectionsData);

    setSections(sectionsData);

    if (streamEndedRef.current) {
      if (sectionsData[sectionsData.length - 1].last_image_timestamp !== sectionsRef.current[sectionsRef.current.length - 1].last_image_timestamp) {
        streamEndedRef.current = false;

        await getAndSetStream(
          new Date(new Date(streamRef.current[streamRef.current.length - 1].time).valueOf() + 1000).toISOString(),
          sectionsData[sectionIndexRef.current].last_image_timestamp,
          WINDOW_SIZE
        );

        if (recordingsIntervalIdRef.current) {
          clearInterval(recordingsIntervalIdRef.current);
        }

        if (!isPausedRef.current) {
          recordingsIntervalIdRef.current = setInterval(consumeStream, (sectionsData[sectionIndexRef.current].totalTime / sectionsData[sectionIndexRef.current].totalFrames) / speedMultiplierRef.current);
          consumeStream();
        }
      }
    }
    sectionsRef.current = sectionsData;

    isFetchingSectionsRef.current = false;

    if (!sectionsData.length) {
      setPlaybackError(PLAYER_PLAYBACK_ERRORS.NO_DATA_FOUND);

      return setIsBuffering(false);
    }
  }, [selectedDate, getMetaData, consumeStream]);

  useEffect(() => {
    if (metadataIntervalIdRef.current) {
      clearInterval(metadataIntervalIdRef.current);
    }

    if (moment(selectedDate).isSame(new Date(), 'day')) {
      metadataIntervalIdRef.current = setInterval(getSectionsData, 5 * 60 * 1000);
    }

    return () => clearInterval(metadataIntervalIdRef.current);
  }, [selectedDate, getSectionsData]);

  useEffect(() => {
    (async () => {
      await getSectionsData();

      if (sectionsRef.current.length) {
        await getAndSetStream(sectionsRef.current[sectionIndexRef.current].first_image_timestamp, sectionsRef.current[sectionIndexRef.current].last_image_timestamp, WINDOW_SIZE);

        console.log('intervalId: ', recordingsIntervalIdRef.current);

        if (recordingsIntervalIdRef.current) {
          clearInterval(recordingsIntervalIdRef.current);
        }

        recordingsIntervalIdRef.current = setInterval(consumeStream, (sectionsRef.current[sectionIndexRef.current].totalTime / sectionsRef.current[sectionIndexRef.current].totalFrames) / speedMultiplierRef.current);
        consumeStream();
      }
    })();

    return () => clearInterval(recordingsIntervalIdRef.current);
  }, [getSectionsData, consumeStream]);

  useEffect(() => {
    if (cursorRef.current && frameIndexRef.current === 1 && sectionIndexRef.current === 0) {
      cursorRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [cursorPosition]);

  useEffect(() => {
    console.log(currentFrame);
  }, [currentFrame]);

  useEffect(() => {
    console.error('playback error: ', playbackError);
  }, [playbackError]);

  useEffect(() => {
    (async () => {
      if (selectedDate !== dateRange[dateRange.length - 1]) {
        handleDateSelect(dateRange[dateRange.length - 1]);
      }
    })();
  }, [dateRange[0], dateRange[dateRange.length - 1], handleDateSelect]);

  return (
    <div className="player">
      <PlaybackErrorOverlay playbackError={playbackError}>
        <PlayerControlsOverlay
          isPaused={isPaused}
          userDetails={userDetails}
          currentFrame={currentFrame}
          togglePlayPause={togglePlayPause}
          playbackSpeed={playbackSpeed}
          handlePlaybackSpeedChange={handlePlaybackSpeedChange}
        >
          <BufferingOverlay isBuffering={isBuffering}>
            <PlayPauseOverlay isPaused={isPaused} togglePlayPause={togglePlayPause}>
              <Screen
                src={currentFrame.signedUrl}
                isRestrictedImage={currentFrame.isRestrictedPath}
                time={currentFrame.time}
              />
            </PlayPauseOverlay>
          </BufferingOverlay>
        </PlayerControlsOverlay>
      </PlaybackErrorOverlay>

      <div className='player-timeline-controls flex flex-col gap-y-3 px-5 mt-4'>
        <Seekbar sections={sections} cursorPosition={cursorPosition} cursorRef={cursorRef} jumpTo={jumpTo} />

        <DateSelector dateRange={dateRange} selectedDate={selectedDate} handleDateSelect={handleDateSelect} />
      </div>
    </div>
  );
};
