import { Box, Popover, Typography } from '@mui/material';
import React from 'react';
import { Icon } from '../../Components/Shared/Icon';
import moment from 'moment';

const AvailabilityPopover = ({ anchorEl, handleClose, open, appAvailability }) => {
  return (
    <Popover
      id="availability-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      anchorPosition={{ top: 200, left: 400 }}
    >
      <Box sx={{
        width: '450px',
        background: '#ffffff',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px'
      }}>
        <Box sx={{
          'display': 'flex',
          'justifyContent': 'space-between',
          'alignItems': 'center',
          'padding': '12px 16px',
          'borderBottom': '1px solid #D3D3D3',

          '& .title': {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '22px',
            color: '#000000'
          }
        }}>
          <span className='title'>App Availability Details</span>
          <Box
            component='span'
            onClick={handleClose}
            sx={ {
              color: 'rgba(51, 51, 51, 0.8)',
              cursor: 'pointer'
            }}
          >
            <Icon icon="Close" />
          </Box>
        </Box>
        {appAvailability.map(({ allowedDays, allowedTime }) => (
          <Box display='flex' justifyContent='space-between' gap='10px' sx={{ padding: '10px 20px' }} key={allowedDays.join(', ')}>
            <Typography fontSize='14px'>{allowedDays.join(', ')}</Typography>
            <Box>
              {allowedTime.map((time) => (
                <Box key={time}>
                  <Typography fontSize='14px' width="160px" >{moment(time.startTime, 'HH mm').format('hh:mm A')} - {moment(time.endTime, 'HH mm').format('hh:mm A')}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default AvailabilityPopover;
