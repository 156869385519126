import React, { useEffect, useState } from 'react';
import { Dialog, Typography, Table } from '@nucleos/core-ui';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { QueryKeys } from '../../../Lib/query-keys';
import Data from '../../../Middleware/Data';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import Skeleton from 'react-loading-skeleton';
import { SortOrder, useTableSorting } from '../../../hooks/useTableSorting';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';

const ColumnKeys = {
  CourseName: 'courseName',
  UserCount: 'userCount'
};

const defaultSorting = {
  column: ColumnKeys.UserCount,
  sortOrder: SortOrder.Descending
};

export default function CourseListByApplicationsModal ({
  /*
   * Mode can be completed or in-progress
   */
  mode,
  onClose
}) {
  const history = useHistory();
  const appId = new URLSearchParams(history.location.search).get('appId');
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'course-by-application-modal'
    });
  const isOpen = !!appId && !!groupId;
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const appDetailsQuery = useQuery(
    QueryKeys.Applications.item(appId),
    () => Data.getAppDetail({ id: appId }),
    { enabled: isOpen, select: (app) => app.application }
  );
  const requestData = {
    appId,
    groupId,
    limit: recordsPerPage,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2],
    page: page,
    status: mode === 'completed' ? 'COMPLETED' : 'STARTED',
    order: columnSorting.sortOrder,
    orderBy: columnSorting.column
  };
  const courseQuery = useQuery(
    QueryKeys.Applications.listing(requestData),
    () => Data.getGroupLearningRecordAppCourses(requestData),
    { enabled: isOpen }
  );

  useEffect(() => {
    if (!isOpen) { setPage(1); }
  }, [isOpen]);

  return (
    <div>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        size="large"
        title={
          mode === 'completed' ? 'Courses Completed' : 'Courses In Progress'
        }
      >
        <div className="flex items-center my-5">
          {!appDetailsQuery.isSuccess || !courseQuery.isSuccess
            ? (
              <>
                <Skeleton
                  count={1}
                  circle
                  height={40}
                  width={40}
                  className="mr-2"
                />
                <Skeleton count={1} width={300} height={20} />
              </>
            )
            : (
              <>
                <img
                  className="object-contain"
                  height={40}
                  width={70}
                  src={appDetailsQuery.data.icon}
                  alt={appDetailsQuery.data.name}
                />
                <Typography
                  className="pl-4 font-semibold"
                  variant="h6"
                  style={{ color: '#19223D' }}
                >
                  {appDetailsQuery.data.name}
                </Typography>
              </>
            )}
        </div>
        <Table
          columns={[
            {
              title: 'Course Name',
              render: (data) => data.title,
              enableSort: true,
              sortOrder: getCurrentSorting(ColumnKeys.CourseName),
              onSortChange: (sortOrder) =>
                setColumnSorting({
                  column: ColumnKeys.CourseName,
                  sortOrder
                })
            },
            {
              title: 'No. of Users',
              render: (data) => data.userCount,
              enableSort: true,
              sortOrder: getCurrentSorting(ColumnKeys.UserCount),
              onSortChange: (sortOrder) =>
                setColumnSorting({
                  column: ColumnKeys.UserCount,
                  sortOrder
                })
            }
          ]}
          noDataMessage={<GenericNoDataFound />}
          loading={courseQuery.isLoading || appDetailsQuery.isLoading}
          pagination
          page={page}
          onPageChange={setPage}
          recordsPerPage={recordsPerPage}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          totalRecords={(courseQuery.data || {}).count}
          rowsData={(courseQuery.data || {}).courses || []}
        />
      </Dialog>
    </div>
  );
}
