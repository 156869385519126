import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HeadlessLayout } from './Components/Layout';
import { ErrorBoundary } from 'react-error-boundary';
import ChangePassword from './Pages/ChangePassword';
import ForgotPassword from './Pages/ForgotPassword';
import Logout from './Pages/Logout';

import {
  ACCOUNT_SETTINGS_PATH,
  ACTIVITY_LOOKUP_DETAILS_PATH,
  ACTIVITY_LOOKUP_PATH,
  ADD_GRIEVANCE_PATH,
  ALL_MESSAGES_LIST_PATH,
  CHANGE_PASSWORD_PATH,
  CONSENT_PATH,
  COURSE_CONTENT_LIBRARY_PATH,
  COURSE_CONTENT_APPLICATION_PATH,
  FLAGGED_ACTIVITY_PATH,
  FLAGGED_MESSAGES_LIST_PATH,
  FORGOT_PASSWORD_PATH,
  GRIEVANCES_FORMS_PATH,
  GRIEVANCE_PATH,
  GRIEVANCE_PDF_PATH,
  GRIEVANCE_REQUEST_REPORT,
  GRIEVANCE_SETTINGS,
  GROUP_LEARNING_RECORDS_PATH,
  GROUP_PATH,
  GROUP_RECORD_PDF_PATH,
  HOME_PATH,
  INVESTIGATIVE_PATH,
  KEEFE_INTEGRATION_PATH,
  LEARNER_RECORD_PDF_PATH,
  LEARNER_SESSION_LOOKUP_PATH,
  LEARNER_SESSION_PATH,
  LEARNING_RECORDS_PATH,
  LEARNING_RECORD_PATH,
  LIVE_ACTIVITY_MODAL_PATH,
  LIVE_ACTIVITY_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  MESSAGE_ALERTS_PATH,
  MESSAGE_DETAILS_PATH,
  MESSAGE_USER_DETAILS_PATH,
  MESSAGE_USER_PATH,
  MESSENGER,
  MESSENGER_ADMIN_PATH,
  MESSENGER_ADMIN_USERS_PATHS,
  MESSENGER_USER_DETAIL_PATH,
  MESSENGER_USER_PATH,
  NOTIFICATIONS_PATH,
  PASSWORD_CHANGE_REQUESTED_PATH,
  REDIRECT_PATH,
  RESPONSES_GRIEVANCE_PATH,
  ROLES_SETTINGS_PATH,
  SETTINGS_GROUP_PATH,
  SETTINGS_USER_PATH,
  UNDER_CONSTRUCTION_PATH,
  USER_ANG_GROUP_PATH,
  USER_PATH,
  VERIFY_PATH,
  VIEW_GRIEVANCE_PATH,
  WELCOME_PATH,
  LEARNER_USER_PATH,
  NON_LEARNER_USER_PATH
} from './constants/paths';
import Login from './Pages/Login';
import Verify from './Pages/Verify';
import LearnerRecordPdf from './Pages/LearnerRecordPdf';
import GrievancePDF from './Pages/Forms/Admin/Grievances/PDF/GrievancePDF';
import Redirect from './Pages/Redirect';
import FormsAdmin from './Pages/Forms/Admin';
import RefactoredGrievance from './Pages/Forms/Admin/Grievances/RefactoredGrievance';
import GrievancesSettings from './Pages/Forms/Admin/GrievancesSettings/GrievancesSettings';
import Grievances from './Pages/Forms/Grievances';
import AddGrievance from './Pages/Forms/Grievances/AddGrievance';
import ViewGrievance from './Pages/Forms/Grievances/ViewGrievance';
import Reports from './Pages/Forms/Admin/Grievances/Reports';
import FlaggedMessagesList from './Pages/Messenger/Admin/FlaggedMessagesList';
import MessageAlerts from './Pages/Messenger/Admin/MessageAlerts';
import MessengerUsers from './Pages/Messenger/Admin/MessengerUsers';
import AllMessagesList from './Pages/Messenger/Admin/Messages/index.js';
import MessageDetails from './Pages/Messenger/Admin/Messages/MessageDetails';

import MessengerUser from './Pages/Messenger/MessengerUser';
import ErrorFallback from './Pages/Errors/UIError';
import AuthGuard from './Components/Guards/AuthGuard';
import GroupRecordPdf from './Pages/GroupLearningRecords/PDF/GroupRecordPdf';
import { InvestigativeTools } from './Pages/InvestigativeTools';
import LiveActivity from './Pages/InvestigativeTools/LiveActivity';
import ActivityLookup from './Pages/InvestigativeTools/ActivityLookup';
import GroupLearningRecords from './Pages/GroupLearningRecords/GroupLearningRecords';
import LearnerSessionLookup from './Pages/LearnerSessionLookup/LearnerSessionLookup';
import FlaggedActivityTimeline from './Pages/LearnerSessionLookup/FlaggedActivity/FlaggedActivityTimeline';
import { CourseLibrary } from './Pages/CourseLibrary';
import HomeWrapper from './Pages/Home/HomeWrapper';
import { Application } from './Pages/Application';
import UsersGroups from './Pages/UsersGroups';
import { UserLayout } from './Pages/Users';
import { AccountSettings } from './Pages/AccountSettings';
import ConsentScreen from './Pages/ConsentScreen';
import KeefeRouter from './Pages/Integrations/Keefe';
import UnderConstruction from './Pages/UnderConstruction';
import { NotificationsLayout } from './Pages/Notifications';
import MessengerAdmin from './Pages/Messenger/Admin/MessengerAdmin';
import Messenger from './Pages/Messenger/Messenger';
import { GroupLayout } from './Pages/Groups';

const LearningRecords = lazy(() =>
  import('./Pages/LearningRecords/LearningRecords')
);

const NucleosRoutes = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch>
        <Route exact path={LOGOUT_PATH}>
          <Logout />
        </Route>
        <Route exact path={VERIFY_PATH}>
          <HeadlessLayout>
            <Verify />
          </HeadlessLayout>
        </Route>
        <Route exact path={LOGIN_PATH}>
          <HeadlessLayout>
            <Login />
          </HeadlessLayout>
        </Route>
        <Route exact path={PASSWORD_CHANGE_REQUESTED_PATH}>
          <HeadlessLayout>
            <ForgotPassword />
          </HeadlessLayout>
        </Route>
        <Route exact path={FORGOT_PASSWORD_PATH}>
          <HeadlessLayout>
            <ForgotPassword />
          </HeadlessLayout>
        </Route>
        <Route exact path={CHANGE_PASSWORD_PATH}>
          <HeadlessLayout>
            <ChangePassword />
          </HeadlessLayout>
        </Route>

        <Route exact path={LEARNER_RECORD_PDF_PATH}>
          <HeadlessLayout>
            <LearnerRecordPdf />
          </HeadlessLayout>
        </Route>
        <Route exact path={GROUP_RECORD_PDF_PATH}>
          <HeadlessLayout>
            <GroupRecordPdf />
          </HeadlessLayout>
        </Route>
        <Route exact path={GRIEVANCE_PDF_PATH}>
          <HeadlessLayout>
            <GrievancePDF />
          </HeadlessLayout>
        </Route>

        <AuthGuard>
          <Route exact path={HOME_PATH}>
            <HomeWrapper />
          </Route>
          <Route exact path={WELCOME_PATH}>
            <Login />
          </Route>

          <Route exact path={REDIRECT_PATH}>
            <Redirect />
          </Route>

          <Route exact path={GRIEVANCE_PATH}>
            <FormsAdmin />
          </Route>
          <Route exact path={RESPONSES_GRIEVANCE_PATH}>
            <RefactoredGrievance />
          </Route>
          <Route exact path={GRIEVANCE_SETTINGS}>
            <GrievancesSettings />
          </Route>
          <Route exact path={GRIEVANCES_FORMS_PATH}>
            <Grievances />
          </Route>
          <Route exact path={ADD_GRIEVANCE_PATH}>
            <AddGrievance />
          </Route>
          <Route exact path={VIEW_GRIEVANCE_PATH}>
            <ViewGrievance />
          </Route>
          <Route exact path={GRIEVANCE_REQUEST_REPORT}>
            <Reports />
          </Route>

          <Route exact path={COURSE_CONTENT_LIBRARY_PATH}>
            <CourseLibrary />
          </Route>
          <Route exact path={LEARNING_RECORDS_PATH}>
            <LearningRecords />
          </Route>
          <Route exact path={INVESTIGATIVE_PATH}>
            <InvestigativeTools />
          </Route>
          <Route exact path={LIVE_ACTIVITY_MODAL_PATH}>
            <InvestigativeTools />
          </Route>
          <Route exact path={LIVE_ACTIVITY_PATH}>
            <LiveActivity />
          </Route>
          <Route exact path={ACTIVITY_LOOKUP_PATH}>
            <ActivityLookup />
          </Route>
          <Route exact path={ACTIVITY_LOOKUP_DETAILS_PATH}>
            <ActivityLookup />
          </Route>
          <Route exact path={GROUP_LEARNING_RECORDS_PATH}>
            <GroupLearningRecords />
          </Route>
          <Route exact path={COURSE_CONTENT_APPLICATION_PATH}>
            <Application />
          </Route>
          <Route exact path={LEARNER_USER_PATH}>
            <UsersGroups />
          </Route>
          <Route exact path={NON_LEARNER_USER_PATH}>
            <UsersGroups />
          </Route>
          <Route exact path={USER_PATH}>
            <UsersGroups />
          </Route>
          <Route exact path={GROUP_PATH}>
            <UsersGroups />
          </Route>
          <Route exact path={ROLES_SETTINGS_PATH}>
            <UsersGroups />
          </Route>
          <Route exact path={USER_ANG_GROUP_PATH}>
            <UsersGroups />
          </Route>
          <Route exact path={LEARNER_SESSION_PATH}>
            <LearnerSessionLookup />
          </Route>
          <Route exact path={LEARNER_SESSION_LOOKUP_PATH}>
            <LearnerSessionLookup />
          </Route>
          <Route exact path={FLAGGED_ACTIVITY_PATH}>
            <FlaggedActivityTimeline />
          </Route>
          <Route exact path={LEARNING_RECORD_PATH}>
            <LearningRecords />
          </Route>
          <Route exact path={SETTINGS_USER_PATH}>
            <UserLayout />
          </Route>
          <Route exact path={SETTINGS_GROUP_PATH}>
            <GroupLayout />
          </Route>
          <Route exact path={ACCOUNT_SETTINGS_PATH}>
            <AccountSettings />
          </Route>
          <Route exact path={CONSENT_PATH}>
            <ConsentScreen />
          </Route>
          <Route exact path={KEEFE_INTEGRATION_PATH}>
            <KeefeRouter />
          </Route>
          <Route exact path={UNDER_CONSTRUCTION_PATH}>
            <UnderConstruction />
          </Route>
          <Route exact path={NOTIFICATIONS_PATH}>
            <NotificationsLayout />
          </Route>

          <Route exact path={MESSENGER_ADMIN_PATH}>
            <MessengerAdmin />
          </Route>
          <Route
            exact
            path={FLAGGED_MESSAGES_LIST_PATH}
          >
            <FlaggedMessagesList />
          </Route>
          <Route exact path={MESSAGE_ALERTS_PATH}>
            <MessageAlerts />
          </Route>
          <Route exact path={MESSENGER_ADMIN_USERS_PATHS}>
            <MessengerUsers />
          </Route>
          <Route exact path={ALL_MESSAGES_LIST_PATH}>
            <AllMessagesList />
          </Route>
          <Route exact path={MESSAGE_DETAILS_PATH}>
            <MessageDetails />
          </Route>
          <Route exact path={MESSENGER_USER_DETAIL_PATH}>
            <MessengerUser />
          </Route>
          <Route exact path={MESSENGER}>
            <Messenger />
          </Route>
          <Route exact path={MESSENGER_USER_PATH}>
            <Messenger />
          </Route>
          <Route exact path={MESSAGE_USER_PATH}>
            <Messenger />
          </Route>
          <Route exact path={MESSAGE_USER_DETAILS_PATH}>
            <Messenger />
          </Route>
        </AuthGuard>
      </Switch>
    </ErrorBoundary>
  );
};

export default NucleosRoutes;
