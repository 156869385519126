import React, { useState, useEffect } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import { Tooltip } from '@mui/material';
import { LoadingSpinner, Typography } from '@nucleos/core-ui';
import { GenericNoDataFound } from '../../ErrorStates/GenericNoDataFound';

import './Heatmap.scss';

const totalIntervals = 5;
const legendColors = [
  'rgba(68, 159, 255, 0.05)',
  'rgba(68, 159, 255, 0.2)',
  'rgba(68, 159, 255, 0.4)',
  'rgba(68, 159, 255, 0.6)',
  'rgba(68, 159, 255, 0.8)',
  'rgba(68, 159, 255, 1)'
];

const HeatmapChart = ({ xLabels, yLabels, data, maxLimit, loading, showLoadMore, onLoadMoreClick }) => {
  const [legends, setLegends] = useState([]);
  const xLabelsData = new Array(xLabels.length).fill(false);

  useEffect(() => {
    if (maxLimit) {
      const intervalSize = maxLimit / totalIntervals;
      const intervals = Array.from({ length: totalIntervals }, (_, i) => {
        const lowerBound = intervalSize * i;
        const upperBound = intervalSize * (i + 1);

        return {
          lowerBound: lowerBound.toFixed(2),
          upperBound: upperBound.toFixed(2),
          label: `${lowerBound.toFixed(2)} hrs - ${upperBound.toFixed(2)} hrs`,
          color: legendColors[i + 1]
        };
      });
      intervals.unshift({
        lowerBound: 0,
        upperBound: 0,
        label: '0 hrs',
        color: legendColors[0]
      });
      setLegends(intervals);
    }
  }, [maxLimit]);

  const getCellColor = (value) => {
    if (value === 0) {
      return legendColors[0];
    }
    const legend = legends.find((legend) => {
      return value >= legend.lowerBound && value <= legend.upperBound;
    });

    return legend ? legend.color : 'transparent';
  };

  if (!xLabels.length) {
    return (
      <div className="flex justify-center items-center">
        {loading ? <LoadingSpinner size={12} /> : <GenericNoDataFound />}
      </div>
    );
  }

  return (
    <div className='heatmap-chart-container' style={{ width: '100%' }}>
      <HeatMapGrid
        key={JSON.stringify(xLabels)}
        data={data}
        xLabels={xLabelsData}
        yLabels={yLabels}
        cellRender={(x, y, value) => (
          <Tooltip
            classes={{ tooltip: 'cell-tooltip' }}
            title={
              <div
                style={{
                  display: 'inline-flex',
                  padding: '8px 10px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: 2,
                  borderRadius: 8,
                  background: '#333'
                }}
              >
                <div style={{ fontWeight: 700, fontSize: 14 }}>{xLabels[y]}</div>
                <div style={{ fontWeight: 400, fontSize: 12 }}>{`Hours Spent: ${value} hrs`}</div>
              </div>
            }
          >
            <div style={{ width: 'inherit', height: '1.5rem', backgroundColor: getCellColor(value) }} />
          </Tooltip>
        )}
        yLabelsStyle={() => ({
          color: '#333',
          fontSize: '12px',
          fontWeight: 600,
          paddingRight: '12px',
          textAlign: 'left'
        })}
        cellStyle={(x, y, ratio) => ({
          background: 'transparent',
          borderRadius: 0,
          minWidth: '1.5rem'
        })}
        cellHeight='1.5rem'
        onClick={(x, y) => null}
      />
      {showLoadMore && (<div
        style={{ cursor: loading ? 'not-allowed' : 'pointer', color: '#449FFF', display: 'flex', justifyContent: 'center', paddingTop: 10, fontSize: 14 }}
        onClick={() => !loading && onLoadMoreClick()}
      >
        {loading ? (<LoadingSpinner />) : 'Load More'}
      </div>)}
      {!!legends.length && (
        <div style={{ display: 'flex', paddingTop: 20, justifyContent: 'flex-end', alignItems: 'center', marginTop: 12, borderTop: '1px solid rgba(211, 211, 211, 0.30)' }}>
          <Typography style={{ color: 'rgba(51, 51, 51, 0.80)', marginRight: 10 }}>
            Hours Spent
          </Typography>
          {legends.map((legend, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div
                style={{
                  width: '100px',
                  height: '12px',
                  background: legend.color
                }}
              />
              <Typography style={{ color: 'var(rgba(51, 51, 51, 0.80))', fontSize: 10 }}>
                {legend.label}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeatmapChart;
