import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Snackbar, SnackbarContent, styled } from '@mui/material';
import { Icon } from '../Shared/Icon';

const PREFIX = 'ToastMessage';
const classes = {
  content: `${PREFIX}-content`,
  info: `${PREFIX}-info`,
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  message: `${PREFIX}-message`
};

const Root = styled('div')(() => ({
  [`& .${classes.content}`]: {
    flexWrap: 'nowrap',
    boxShadow: [
      [0, 0, 0, 0.2, 'black'],
      [0, 3, 5, -1, 'rgba(0,0,0,0.2)'],
      [0, 6, 10, 0, 'rgba(0,0,0,0.14)'],
      [0, 1, 18, 0, 'rgba(0,0,0,0.12)']
    ]
  },
  [`& .${classes.info}`]: {
    backgroundColor: 'rgb(72,128,255)'
  },
  [`& .${classes.success}`]: {
    backgroundColor: 'green'
  },
  [`& .${classes.error}`]: {
    backgroundColor: 'rgb(209, 0, 19)'
  },
  [`& .${classes.message}`]: {
    fontSize: '1.1em'
  }
}));

const toastMessagePropTypes = {
  action: PropTypes.node,
  autoHideDuration: PropTypes.number,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['info', 'success', 'error'])
};

const ToastMessage = props => {
  const { action, autoHideDuration = 10000, message, onClose, open, type = 'info' } = props;
  const contentClassName = `${classes.content} ${classes[type]}`;
  return (
    <Root>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        open={open}
      >
        <SnackbarContent
          classes={{ root: contentClassName }}
          aria-describedby='client-snackbar'
          message={<span id='client-snackbar' className={classes.message}>{message}</span>}
          action={action || (
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={onClose}
            >
              <Icon icon="Close" />
            </IconButton>
          )
          }
        />
      </Snackbar>
    </Root>
  );
};

ToastMessage.props = toastMessagePropTypes;

export default ToastMessage;
