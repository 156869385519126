import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import './UserSettings.scss';
import NoResultFoundSVG from '../../Assets/graphics/table_no_data_found.svg';
import Data from '../../Middleware/Data';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Button as NCButton,
  Chip,
  DateRangePicker,
  MultiSelectDropDown,
  SearchInput,
  Table,
  Typography,
  TextInput
} from '@nucleos/core-ui';
import {
  formatDateTime,
  tableFilterLexicographicalSorter,
  stringToBase64Url,
  getUserStatusLabels,
  getUserExternalStatusLabel
} from '../../Lib/util';
import { useFiltersManager } from '../../hooks/useFilters';
import moment from 'moment';
import { SortOrder, useTableSorting } from '../../hooks/useTableSorting';
import { usePreferences } from '../../hooks/usePreferences';
import { ColumnSortingDropdown } from '../Shared/ColumnSortingDropdown';
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Menu, MenuItem, useTheme, Typography as MuiTypography } from '@mui/material';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import { LoadingAndErrorHandler } from '../Shared/LoadingErrorHandler';
import { ACTIVE_ROLES, rolesList } from '../../Lib/CONSTANTS';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';
import DropDownMenu from '../Shared/DropDownMenu';
import Tooltip from '../Shared/Tooltip';
import DataStore from '../../Stores/DataStore';
import { useSearchParamsPagination } from '../../hooks/useSearchParamsPagination';
import { useFacilitiesQuery } from '../../hooks/useFacilities';
import { QueryKeys } from '../../Lib/query-keys';
import useUserPermissions from '../../hooks/useUserPermissions';
import BanUserModalContent from '../User/BanUserModalContent';
import UnbanUserModalContent from '../User/UnbanUserModalContent';
import DefaultPasswordModalContent from '../User/DefaultPasswordModalContent';
import { Icon } from '../Shared/Icon';
import { GRAY } from '../../constants/colors';
import { USER_PATH } from '../../constants/paths';
import GroupForm from '../GroupForm/GroupForm';
import AddUsersToGroupForm from '../GroupForm/AddUsersToGroupForm';

const FilterKeys = {
  Search: 'Search',
  FullName: 'FullName',
  Username: 'Username',
  Email: 'Email',
  LastLoggedIn: 'LastLoggedIn',
  LastLoggedInStartDate: 'LastLoggedInStartDate',
  LastLoggedInEndDate: 'LastLoggedInEndDate',
  Status: 'Status',
  ExternalStatus: 'ExternalStatus',
  RoleIds: 'Role',
  GrievnaceRoleIds: 'GrievnaceRole',
  Pod: 'Pod',
  CreatedAt: 'CreatedAt',
  Facility: 'Facility'
};

const OrderByKeys = {
  [FilterKeys.Email]: 'email',
  [FilterKeys.FullName]: 'fullName',
  [FilterKeys.Username]: 'username',
  [FilterKeys.Pod]: 'pod',
  [FilterKeys.Facility]: 'facility',
  [FilterKeys.RoleIds]: 'Role.name',
  [FilterKeys.GrievnaceRoleIds]: 'ConfigRole.label',
  [FilterKeys.LastLoggedIn]: 'lastLoginAt',
  [FilterKeys.CreatedAt]: 'createdAt',
  [FilterKeys.Status]: 'status',
  [FilterKeys.ExternalStatus]: 'externalStatus'
};
const UserActions = {
  Enable: 'Activate User',
  Disable: 'Deactivate User',
  BanUser: 'Ban User',
  UnBanUser: 'Unban User',
  ViewLiveActivity: 'View Live Activity Feed',
  ViewLearningRecords: 'View Learning Records',
  ViewUserGrievances: 'View User Grievances'
};

function BulkGroupingMenu ({ onMenuItemClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleBulkGroupingMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleBulkGroupingMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback((action) => {
    onMenuItemClick(action);

    handleBulkGroupingMenuClose();
  }, [onMenuItemClick, handleBulkGroupingMenuClose]);

  return (
    <>
      <Button
        id="bulk-grouping-menu-toggle-button"
        sx={(theme) => ({ backgroundColor: theme.palette.primary.dark })}
        aria-controls={open ? 'bulk-grouping-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleBulkGroupingMenuOpen}
        endIcon={<Icon icon='ArrowDown' />}
      >
        Group
      </Button>

      <Menu
        id="bulk-grouping-menu"
        MenuListProps={{
          'aria-labelledby': 'bulk-grouping-menu-toggle-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleBulkGroupingMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('create-new-group')} disableRipple>
          Create New Group
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('add-to-existing-group')} disableRipple>
          Add to Existing Group
        </MenuItem>
      </Menu>
    </>
  );
}

const UsersTable = ({ onAddClick, listViewMode }) => {
  const history = useHistory();
  const userPermissions = useUserPermissions();
  const filterManager = useFiltersManager({
    onFilterChange: () => {
      setPage(1);
    },
    urlKey: 'filters'
  });
  const preferences = usePreferences('users', {
    defaultColumnOrder: [],
    defaultHiddenColumns: ['Facility', 'Pod', 'External Status'],
    onPreferencesUpdated: () => setIsManageColumnsOpen(false)
  });
  const { columnSorting, setColumnSorting } = useTableSorting({
    defaultSorting: { column: FilterKeys.Username, sortOrder: 'ASC' }
  });

  const [isAddUsersToGroupModalOpen, setIsAddUsersToGroupModalOpen] = useState(false);
  const [addUsersToGroupModalType, setAddUsersToGroupModalType] = useState(null);

  const [isManageColumnsOpen, setIsManageColumnsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useSearchParamsPagination({ key: 'users-page' });
  const roleLabels = rolesList.reduce(
    (acc, val) => ({ ...acc, [val.value]: val.label }),
    {}
  );
  const podsQuery = useQuery(['PODS'], () => Data.getPods(), {
    placeholderData: []
  });
  const facilitiesQuery = useFacilitiesQuery();
  const facilities = facilitiesQuery.data || [];
  const [grvRoleSearch, setGrvRoleSearch] = useState('');
  const rolesQuery = useQuery(
    QueryKeys.Roles.listing({ usersListing: true }),
    () => Data.getRoles(true),
    {
      placeholderData: []
    }
  );
  const grievanceRolesQuery = usePaginatedQuery(
    ['GRIEV_ROLE', grvRoleSearch],
    ({ pageParam = {} }) =>
      Data.getUpdatedRoles({
        page: pageParam.page || 1,
        limit: 10,
        roleName: grvRoleSearch,
        forUserListing: true,
        sortBy: 'label',
        sortOrder: 'ASC'
      }),
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((r) => ({ title: r.label, value: r.id }))
        };
      },
      listKeyName: 'rows'
    }
  );

  const filters = {
    status: filterManager.getValues(FilterKeys.Status).length
      ? filterManager.getValues(FilterKeys.Status)
      : -1,
    externalStatus: filterManager.getValues(FilterKeys.ExternalStatus).length
      ? filterManager.getValues(FilterKeys.ExternalStatus)
      : -1,
    email: filterManager.getValue(FilterKeys.Email) || '',
    fullName: filterManager.getValue(FilterKeys.FullName) || '',
    username: filterManager.getValue(FilterKeys.Username) || '',
    startDateLogin:
      filterManager.getValue(FilterKeys.LastLoggedInStartDate) || '',
    endDateLogin: filterManager.getValue(FilterKeys.LastLoggedInEndDate) || ''
  };
  const body = {
    facIds: filterManager.getValues(FilterKeys.Facility),
    pods: filterManager.getValues(FilterKeys.Pod),
    configRoleIds: filterManager.getValues(FilterKeys.GrievnaceRoleIds),
    roleIds: filterManager.getValues(FilterKeys.RoleIds)
  };
  const usersQuery = useQuery(
    [
      'USERS',
      listViewMode,
      page,
      filterManager.getValue(FilterKeys.Search) || '',
      columnSorting,
      filters,
      body,
      preferences.recordsPerPage
    ],
    () =>
      Data.getUsers({
        query: filterManager.getValue(FilterKeys.Search) || '',
        page: page - 1,
        orderBy: OrderByKeys[columnSorting.column],
        order: columnSorting.sortOrder,
        filters,
        body,
        limit: preferences.recordsPerPage,
        roleType: listViewMode
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled:
        preferences.status === 'success' &&
        typeof preferences.recordsPerPage === 'number'
    }
  );
  const bulkActivateUserMutation = useMutation(({ uids }) =>
    Data.bulkReactivateUsers(uids).then(GenericErrorDetectorForMutations)
  );
  const bulkDeActivateUserMutation = useMutation(({ uids, internalNote }) =>
    Data.bulkDeactivateUsers(uids, internalNote).then(
      GenericErrorDetectorForMutations
    )
  );

  const unbanLearnerMutation = useMutation((data) =>
    Data.unbanLearner(data).then(GenericErrorDetectorForMutations)
  );

  const banLearnerMutation = useMutation((data) =>
    Data.banLearner(data).then(GenericErrorDetectorForMutations)
  );

  const setDefaultPasswordMutation = useMutation(({ uids }) =>
    Data.setDefaultPassword(uids).then(GenericErrorDetectorForMutations)
  );

  const rowData = useMemo(() => usersQuery.data ? usersQuery.data.users || [] : [], [usersQuery.data]);

  useEffect(() => {
    if (DataStore.roles.length === 0) { DataStore.getRoles(); }
  }, []);

  const rowDataUIDs = rowData
    .map((u) => u.uid)
    .sort()
    .join(' ');

  useEffect(() => {
    setSelectedRows([]);
  }, [rowDataUIDs]);

  const selectedUsers = useMemo(() => {
    if (selectedRows.length) {
      const selectedRowSet = new Set(selectedRows);
      return rowData.filter(row => selectedRowSet.has(row.uid)) || [];
    }

    return [];
  }, [rowData, selectedRows]);

  const selectedLearners = useMemo(() => {
    if (selectedRows.length) {
      const selectedRowSet = new Set(selectedRows);
      return rowData.filter(row => selectedRowSet.has(row.uid) && row.Role.name === ACTIVE_ROLES.LEARNER) || [];
    }

    return [];
  }, [rowData, selectedRows]);

  const selectedBannedUsers = useMemo(() => {
    if (selectedRows.length) {
      const selectedRowSet = new Set(selectedRows);
      return rowData.filter(row => selectedRowSet.has(row.uid) && row.status.split(',').includes('BAN')) || [];
    }

    return [];
  }, [rowData, selectedRows]);

  const selectedActiveUsers = useMemo(() => {
    if (selectedRows.length) {
      const selectedRowSet = new Set(selectedRows);
      return rowData.filter(row => selectedRowSet.has(row.uid) && row.status.split(',').includes('ACTIVE')) || [];
    }

    return [];
  }, [rowData, selectedRows]);

  const selectedDeactivatedUsers = useMemo(() => {
    if (selectedRows.length) {
      const selectedRowSet = new Set(selectedRows);
      return rowData.filter(row => selectedRowSet.has(row.uid) && row.status.split(',').includes('DEACTIVATED')) || [];
    }

    return [];
  }, [rowData, selectedRows]);

  const lastLoggedInStartDateFV = filterManager.getValue(
    FilterKeys.LastLoggedInStartDate
  );
  const lastLoggedInEndDateFV = filterManager.getValue(
    FilterKeys.LastLoggedInEndDate
  );

  const rolesOptions = rolesQuery.data
    .filter((el) => {
      if (listViewMode === 'LEARNER') {
        return el.name.startsWith('learner.');
      } else if (listViewMode === 'NON_LEARNER') {
        return el.name.startsWith('admin.');
      } else {
        return el;
      }
    })
    .map((i) => ({
      title: i.displayName,
      value: i.uid
    }));

  const openLiveActivity = useCallback((uid) => {
    const filters = {
      learners: [uid],
      pods: [],
      facilities: [],
      liveLearners: false
    };

    const url = `${window.location.origin
    }/investigative-tools/live-activity/?filters=${stringToBase64Url(
      JSON.stringify(filters)
    )}&selectedUser=${stringToBase64Url(uid)}`;

    window.open(url, '_blank').focus();
  }, []);

  const viewLearnerGrienvances = useCallback((user) => {
    const filters = [
      {
        key: 'assignee',
        value: 'ALL'
      },
      {
        key: 'learnerIds',
        value: user.uid,
        meta: {
          label: `${user.firstName} ${user.lastName}`
        }
      }
    ];

    const searchParams = new URLSearchParams();
    searchParams.set('filters', JSON.stringify(filters));

    const url = `${window.location.origin
    }/forms/admin/grievances?${searchParams.toString()}`;

    window.open(url, '_blank').focus();
  }, []);

  const viewLearningRecords = useCallback((userId) => {
    const url = `${window.location.origin}/user/${userId}/learner-record`;

    window.open(url, '_blank').focus();
  }, []);

  const onEnableOrDisableUser = ({ action, userIds, userName, isBulkAction }) => {
    const buttonLabel = action === 'enable' ? 'Activate' : 'Deactivate';
    const mutation =
      action === 'enable'
        ? bulkActivateUserMutation
        : bulkDeActivateUserMutation;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <EnableOrDisableUser
            action={action}
            isBulkAction={isBulkAction}
            buttonLabel={buttonLabel}
            mutation={mutation}
            onClose={onClose}
            userIds={userIds}
            userName={userName}
            usersQuery={usersQuery}
          />
        );
      }
    });
  };

  const onUnban = useCallback(
    (userUids) => {
      const isMoreThanOneSelected = userUids.length > 1;
      const wordPostfix = isMoreThanOneSelected ? 's' : '';
      const loadingId = toast.loading('Unbanning users...');
      unbanLearnerMutation.mutate(
        { userUids },
        {
          onSuccess: () => {
            toast.success(`User${wordPostfix} successfully unbanned.`);
            usersQuery.refetch();
          },
          onError: () => {
            toast.error(`Failed to unban user${wordPostfix}.`);
          },
          onSettled: () => {
            toast.dismiss(loadingId);
          }
        }
      );
    },
    [unbanLearnerMutation, usersQuery]
  );

  const onBan = useCallback(
    (userUid) => {
      return ({
        periodInDays,
        banIndefinitely,
        internalNote,
        noteForLearner
      }) => {
        const loadingId = toast.loading('Banning users...');

        banLearnerMutation.mutate(
          {
            userUid,
            periodInDays,
            banIndefinitely,
            internalNote,
            noteForLearner
          },
          {
            onSuccess: () => {
              toast.success('User successfully banned.');
              usersQuery.refetch();
            },
            onError: () => {
              toast.error('Failed to ban user.');
            },
            onSettled: () => {
              toast.dismiss(loadingId);
            }
          }
        );
      };
    },
    [banLearnerMutation, usersQuery]
  );

  const onSetDefaultPassword = useCallback(
    (userIDs) => {
      const loadingId = toast.loading('Setting default password...');
      setDefaultPasswordMutation.mutate(
        { uids: userIDs },
        {
          onSuccess: () => {
            toast.success('Default password set successfully.');
            usersQuery.refetch();
          },
          onError: () => {
            toast.error('Failed to set default password.');
          },
          onSettled: () => {
            toast.dismiss(loadingId);
          }
        }
      );
    },
    [setDefaultPasswordMutation, usersQuery]
  );

  const onActionClick = (actionName, user) => {
    const userId = user.uid;
    const userDisplayName = `${user.firstName} ${user.lastName}`;

    switch (actionName) {
    case UserActions.Enable:
      onEnableOrDisableUser({
        action: 'enable',
        userIds: [user.uid],
        userName: `${user.firstName} ${user.lastName}`
      });

      break;

    case UserActions.Disable:
      onEnableOrDisableUser({
        action: 'disable',
        userIds: [user.uid],
        userName: `${user.firstName} ${user.lastName}`
      });

      break;

    case UserActions.BanUser:
      confirmAlert({
        customUI: ({ onClose }) => (
          <BanUserModalContent
            userDisplayName={userDisplayName}
            onClose={onClose}
            onSuccess={onBan(userId)}
          />
        )
      });

      break;

    case UserActions.UnBanUser:
      confirmAlert({
        customUI: ({ onClose }) => (
          <UnbanUserModalContent
            userDisplayName={userDisplayName}
            onClose={onClose}
            onSuccess={() => onUnban([userId])}
          />
        )
      });

      break;

    case UserActions.ViewLiveActivity: {
      openLiveActivity(user.uid);

      break;
    }

    case UserActions.ViewLearningRecords: {
      viewLearningRecords(user.uid);

      break;
    }

    case UserActions.ViewUserGrievances: {
      viewLearnerGrienvances(user);

      break;
    }

    default:
      break;
    }
  };

  const transformValue = (v) => ({ title: v.meta.label, value: v.value });

  const columns = [
    {
      title: 'Full Name',
      skeletonClassNames: 'max-skeleton',
      selectionDisabled: true,
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.FullName
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.FullName, sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 130 }}
          className="capitalize"
          data-testid="user-full-name-users-table"
          data-fullname={`${item.firstName} ${item.lastName}`}
        >{`${item.firstName} ${item.lastName}`}</Typography>
      ),
      renderFilter: () => (
        <SearchInput
          placeholder="Full Name"
          fullWidth
          testId="filter-full-name"
          value={filterManager.getValue(FilterKeys.FullName)}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.FullName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.FullName }); }
            filterManager.onFilterApply({
              key: FilterKeys.FullName,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Username',
      skeletonClassNames: 'min-skeleton',
      selectionDisabled: true,
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.Username
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.Username, sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 100 }}
          data-testid="username-users-table"
          data-username={item.username}
        >{`${item.username}`}</Typography>
      ),
      renderFilter: () => (
        <SearchInput
          placeholder="Username"
          fullWidth
          testId="filter-username"
          value={filterManager.getValue(FilterKeys.Username)}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.Username) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.Username }); }
            filterManager.onFilterApply({
              key: FilterKeys.Username,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Email Address',
      skeletonClassNames: 'max-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.Email
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.Email, sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 200 }}
          data-testid="email-users-table"
          data-email={(item.emailAddresses || []).join(', ')}
        >{`${(item.emailAddresses || []).join(', ')}`}</Typography>
      ),
      renderFilter: () => (
        <SearchInput
          placeholder="Email Address"
          fullWidth
          testId="filter-email"
          value={filterManager.getValue(FilterKeys.Email)}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.Email) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.Email }); }
            filterManager.onFilterApply({
              key: FilterKeys.Email,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Pod',
      skeletonClassNames: 'min-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.Pod
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.Pod, sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 100 }}
          data-testid="pod-users-table"
          data-pod={item.pod || 'NA'}
        >{`${item.pod || 'NA'}`}</Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="users-table-pods-select"
          testId="filter-pods"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.Pod, { transformValue })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.Pod,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          label={'All pods'}
          getLabel={(selected) =>
            `Selected pod${selected.length > 1 ? 's' : ''} (${selected.length})`
          }
          fullWidth
          options={(
            podsQuery.data.map((pod) => ({ title: pod.pod, value: pod.pod })) ||
            []
          ).sort(tableFilterLexicographicalSorter)}
        />
      )
    },
    {
      title: 'Facility',
      skeletonClassNames: 'mid-skeleton',
      render: (item) => (
        <Typography
          style={{ minWidth: 150 }}
          data-testid="facility-users-table"
          data-facility={
            `${(item.facility || []).map((f) => f.name).join(', ')}` || 'NA'
          }
        >
          {`${(item.facility || []).map((f) => f.name).join(', ')}` || 'NA'}
        </Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="users-table-facilities-select"
          testId="filter-facility"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.Facility, {
            transformValue
          })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.Facility,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          label={'All Facilities'}
          getLabel={(selected) =>
            `Selected ${selected.length > 1 ? 'facilities' : 'facility'} (${selected.length
            })`
          }
          fullWidth
          options={(facilities || []).sort(tableFilterLexicographicalSorter)}
        />
      )
    },
    {
      title: 'Role',
      skeletonClassNames: 'min-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.RoleIds
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.RoleIds, sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 100 }}
          data-testid="role-users-table"
          data-role={
            item.Role.displayName ||
            roleLabels[item.Role.name] ||
            item.Role.name
          }
        >{`${item.Role.displayName || roleLabels[item.Role.name] || item.Role.name
          }`}</Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="users-table-roles-select"
          testId="filter-role"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.RoleIds, {
            transformValue
          })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.RoleIds,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          label={'All Roles'}
          getLabel={(selected) =>
            `Selected Role${selected.length > 1 ? 's' : ''} (${selected.length
            })`
          }
          fullWidth
          options={rolesOptions}
        />
      )
    },
    {
      title: 'Grievance Role',
      skeletonClassNames: 'mid-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.GrievnaceRoleIds
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.GrievnaceRoleIds, sortOrder }),
      render: (item) => (
        <Tooltip text={item.ConfigRole ? item.ConfigRole.label : 'NA'}>
          <Typography
            style={{ minWidth: 150 }}
            data-testid="grievance-role-users-table"
            data-grievance-role={
              item.ConfigRole
                ? item.ConfigRole.label.length > 20
                  ? `${item.ConfigRole.label.slice(0, 15)}...`
                  : item.ConfigRole.label
                : 'NA'
            }
          >{`${item.ConfigRole
              ? item.ConfigRole.label.length > 20
                ? `${item.ConfigRole.label.slice(0, 15)}...`
                : item.ConfigRole.label
              : 'NA'
            }`}</Typography>
        </Tooltip>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="users-table-grievance-roles-select"
          testId="filter-grievance-role"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.GrievnaceRoleIds, {
            transformValue
          })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.GrievnaceRoleIds,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          searchable
          searchValue={grvRoleSearch}
          onSearchChange={setGrvRoleSearch}
          isSearchLoading={
            grievanceRolesQuery.isFetching && !grievanceRolesQuery.isFetched
          }
          label={'All Grievance Roles'}
          getLabel={(selected) =>
            `Selected Grievance Role${selected.length > 1 ? 's' : ''} (${selected.length
            })`
          }
          fullWidth
          hasNextOptions={grievanceRolesQuery.hasNextPage}
          isNextPageLoading={grievanceRolesQuery.isFetching}
          fetchNextOptionsPage={grievanceRolesQuery.fetchNextPage}
          options={(grievanceRolesQuery.data || {}).pages || []}
        />
      )
    },
    {
      title: 'Last Logged In',
      skeletonClassNames: 'max-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.LastLoggedIn
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.LastLoggedIn, sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 200 }}
          data-testid="last-login-users-table"
          data-last-login={
            item.lastLoginAt ? formatDateTime(item.lastLoginAt) : 'Never'
          }
        >{`${item.lastLoginAt ? formatDateTime(item.lastLoginAt) : 'Never'
          }`}</Typography>
      ),
      renderFilter: () => (
        <DateRangePicker
          testId="filter-last-login"
          disableHelperText
          fullWidth
          placeholder="Date Range"
          maxDate={new Date()}
          value={{
            startDate: lastLoggedInStartDateFV
              ? new Date(lastLoggedInStartDateFV)
              : null,
            endDate: lastLoggedInEndDateFV
              ? new Date(lastLoggedInEndDateFV)
              : null
          }}
          onChange={({ startDate, endDate }) => {
            filterManager.onFilterApply({
              key: FilterKeys.LastLoggedInStartDate,
              value: moment(startDate).format('MM/DD/YYYY'),
              meta: {
                label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
                  endDate
                ).format('MM/DD/YYYY')}`
              }
            });
            filterManager.onFilterApply({
              key: FilterKeys.LastLoggedInEndDate,
              value: moment(endDate).format('MM/DD/YYYY')
            });
          }}
        />
      )
    },
    {
      title: 'Status',
      skeletonClassNames: 'mid-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.Status
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.Status, sortOrder }),
      render: (item) => (
        <div className="flex flex-col gap-2">
          {getUserStatusLabels(item.active, item.isBanned).map((status) => (
            <Chip
              key={`${item.username}-${status.label}`}
              testId={`user-status-${item.username}`}
              rounded="full"
              variant={'outlined'}
              label={status.label}
              color={status.color}
            />
          ))}
        </div>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="users-table-statuses-select"
          testId="filter-status"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.Status, { transformValue })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.Status,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          label={'All Statuses'}
          getLabel={(selected) =>
            `Selected Status${selected.length > 1 ? 'es' : ''} (${selected.length
            })`
          }
          fullWidth
          options={[
            { title: 'Active', value: 'ACTIVE' },
            { title: 'Deactivated', value: 'DEACTIVATED' },
            { title: 'Banned', value: 'BAN' }
          ].sort(tableFilterLexicographicalSorter)}
        />
      )
    }
  ];

  if (window.nucleosConfig.showExternalStatus) {
    columns.push({
      title: 'External Status',
      skeletonClassNames: 'mid-skeleton',
      enableSort: true,
      sortOrder:
        columnSorting.column === FilterKeys.ExternalStatus
          ? columnSorting.sortOrder
          : SortOrder.None,
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: FilterKeys.ExternalStatus, sortOrder }),
      render: (item) => {
        const externalStatusChip = getUserExternalStatusLabel(item.overrideStatus);
        return (
          <Chip
            testId={`user-external-status-${item.username}`}
            rounded="full"
            variant={'outlined'}
            label={externalStatusChip.label}
            color={externalStatusChip.color}
          />
        );
      },
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="users-table-external-statuses-select"
          testId="filter-external-status"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.ExternalStatus, { transformValue })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.ExternalStatus,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          label={'All Statuses'}
          getLabel={(selected) =>
            `Selected Status${selected.length > 1 ? 'es' : ''} (${selected.length
            })`
          }
          fullWidth
          options={[
            { title: 'Active', value: 'ACTIVE' },
            { title: 'Deactivated', value: 'DEACTIVATED' },
            { title: 'Temp. Active', value: 'TEMP_ACTIVE' }
          ].sort(tableFilterLexicographicalSorter)}
        />
      )
    });
  }

  const onFilterDelete = (appliedFilter) => {
    const isDateRangeFilter = [
      FilterKeys.LastLoggedInStartDate,
      FilterKeys.LastLoggedInEndDate
    ].includes(appliedFilter.key);

    if (isDateRangeFilter) {
      filterManager.onFilterRemove({
        key: FilterKeys.LastLoggedInStartDate
      });
      filterManager.onFilterRemove({
        key: FilterKeys.LastLoggedInEndDate
      });
      return;
    }

    filterManager.onFilterRemove({
      key: appliedFilter.key,
      value: appliedFilter.value
    });
  };

  const handleBulkUnbanClick = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <UnbanUserModalContent
          isBulk
          onClose={onClose}
          onSuccess={() => onUnban(selectedRows)}
        />
      )
    });
  }, [onUnban, selectedRows]);

  const handleSetDefaultPasswordClick = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <DefaultPasswordModalContent
          onClose={onClose}
          selectedRows={selectedLearners}
          onSuccess={(userIDs) => {
            onSetDefaultPassword(userIDs);
            onClose();
          }}
        />
      )
    });
  }, [onSetDefaultPassword, selectedLearners]);

  const onBulkActivateOrDeactivate = (action) => {
    onEnableOrDisableUser({
      action: action,
      userIds: selectedRows,
      isBulkAction: true
    });
  };

  const appliedFilterLabels = filterManager.filters
    .filter((filter) => ![FilterKeys.LastLoggedInEndDate].includes(filter.key))
    .map((filter) => (
      <Chip
        key={`${filter.key}-${filter.value}`}
        rounded="full"
        className="mb-2 mr-2"
        style={{ padding: '6px 8px' }}
        label={filter.meta ? filter.meta.label : filter.value}
        onDelete={() =>
          onFilterDelete({
            key: filter.key,
            value: filter.value
          })
        }
      />
    ));

  const shouldShowBulkSelection = userPermissions.canSelectUsersForBulkActions(listViewMode);

  const tableColumns = preferences.getFilteredColumns(columns);
  const firstCol = { ...tableColumns[0] };

  if (firstCol.title && shouldShowBulkSelection) {
    tableColumns[0] = {
      ...firstCol,
      title: (
        <div className="flex items-center">
          <Checkbox
            style={{ padding: 4 }}
            color="primary"
            checked={
              rowData.every((i) => selectedRows.includes(i.uid)) &&
              rowData.length
            }
            indeterminate={
              !rowData.every((i) => selectedRows.includes(i.uid)) &&
              rowData.some((i) => selectedRows.includes(i.uid))
            }
            onClick={(e) => e.stopPropagation()}
            onChange={(e, checked) => {
              e.stopPropagation();
              if (checked) { setSelectedRows(rowData.map((i) => i.uid)); } else { setSelectedRows([]); }
            }}
          />
          {firstCol.title}
        </div>
      ),
      render: (item) => (
        <div className="flex items-center">
          <Checkbox
            style={{ padding: 4 }}
            color="primary"
            checked={selectedRows.includes(item.uid)}
            onClick={(e) => e.stopPropagation()}
            onChange={(e, checked) => {
              e.stopPropagation();
              if (checked) { setSelectedRows([...selectedRows, item.uid]); } else { setSelectedRows(selectedRows.filter((i) => i !== item.uid)); }
            }}
          />
          {firstCol.render(item)}
        </div>
      )
    };
  }

  const userActions = [
    {
      name: UserActions.Enable,
      getPermission: (user) =>
        !user.active && userPermissions.canActivateOtherUser()
    },
    {
      name: UserActions.Disable,
      getPermission: (user) =>
        user.active && userPermissions.canDeactivateOtherUser()
    },
    {
      name: UserActions.UnBanUser,
      getPermission: (user) =>
        user.Role.name === 'learner.default' &&
        user.isBanned &&
        userPermissions.canActivateOtherUser()
    },
    {
      name: UserActions.BanUser,
      getPermission: (user) =>
        user.Role.name === 'learner.default' &&
        !user.isBanned &&
        userPermissions.canDeactivateOtherUser()
    },
    {
      name: UserActions.ViewLiveActivity,
      getPermission: (user) =>
        user.Role.name === ACTIVE_ROLES.LEARNER &&
        userPermissions.canGoToLiveActivity()
    },
    {
      name: UserActions.ViewLearningRecords,
      getPermission: (user) =>
        user.Role.name === ACTIVE_ROLES.LEARNER &&
        userPermissions.canViewLearningRecords()
    },
    {
      name: UserActions.ViewUserGrievances,
      getPermission: (user) =>
        user.Role.name === ACTIVE_ROLES.LEARNER &&
        userPermissions.canGoToGrievances()
    }
  ];

  if (
    [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN].includes(
      userPermissions.role
    )
  ) {
    tableColumns.push({
      title: 'Action',
      skeletonClassNames: 'min-skeleton',
      render: (item) =>
        userActions.filter((action) => action.getPermission(item)).length
          ? (
            <div className="flex justify-end" style={{ minWidth: 30 }}>
              <DropDownMenu
                testId={'user-action'}
                onActionClick={(action) => {
                  onActionClick(action.name, item);
                }}
                actions={userActions.filter((action) =>
                  action.getPermission(item)
                )}
              >
                <Icon icon="ThreeDots" sx={{ color: GRAY }} />
              </DropDownMenu>
            </div>
          )
          : null
    });
  }

  const showBulkUnbanCTA = useMemo(() => {
    return (
      !!selectedBannedUsers.length &&
      listViewMode === 'LEARNER' &&
      userPermissions.canActivateOtherUser()
    );
  }, [selectedBannedUsers, userPermissions, listViewMode]);

  const showBulkDeactivateCTA = useMemo(() => {
    return (
      !!selectedActiveUsers.length &&
      userPermissions.canDeactivateOtherUser()
    );
  }, [selectedActiveUsers, userPermissions]);

  const showBulkActivateCTA = useMemo(() => {
    return (
      !!selectedDeactivatedUsers.length &&
      userPermissions.canActivateOtherUser()
    );
  }, [selectedDeactivatedUsers, userPermissions]);

  const showBulkGroupingCTA = useMemo(() => {
    return (
      !!selectedRows.length &&
      ['LEARNER', 'NON_LEARNER'].includes(listViewMode) &&
      userPermissions.canCreateGroup() &&
      true
    );
  }, [selectedRows, userPermissions, listViewMode]);

  const showBulkSetDefaultPasswordCTA = useMemo(() => {
    return (
      !!selectedLearners.length &&
      window.nucleosConfig.setDefaultPasswordEnabled &&
      listViewMode === 'LEARNER' &&
      userPermissions.canSetDefaultPassword()
    );
  }, [selectedLearners, userPermissions, listViewMode]);

  const handleBulkGroupingOptionsClick = useCallback((action) => {
    setIsAddUsersToGroupModalOpen(true);

    setAddUsersToGroupModalType(action);
  }, []);

  return (
    <section
      className="invert-scrollbar"
      style={{ paddingBottom: 40 }}
    >
      <LoadingAndErrorHandler
        isLoading={preferences.status === 'loading'}
        isSuccess={preferences.status === 'success'}
        isError={false}
      >
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <SearchInput
            testId="user-search"
            placeholder="Search..."
            style={{ width: 300 }}
            onSearch={(value) => {
              if (filterManager.getValue(FilterKeys.Search) === value) { return; }
              if (!value) { return filterManager.onFilterRemove({ key: FilterKeys.Search }); }
              filterManager.onFilterApply({ key: FilterKeys.Search, value });
            }}
            value={filterManager.getValue(FilterKeys.Search) || ''}
          />

          <Box sx={{ display: 'flex', gap: 1 }}>
            {
              showBulkUnbanCTA && (
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleBulkUnbanClick}
                >
                  Unban
                </Button>
              )
            }

            {
              showBulkDeactivateCTA && (
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => onBulkActivateOrDeactivate('disable')}
                >
                  Deactivate
                </Button>
              )
            }

            {
              showBulkActivateCTA && (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => onBulkActivateOrDeactivate('enable')}
                >
                  Activate
                </Button>
              )
            }

            {
              showBulkGroupingCTA && (
                <BulkGroupingMenu onMenuItemClick={handleBulkGroupingOptionsClick} />
              )
            }

            {
              showBulkSetDefaultPasswordCTA && (
                <Button
                  sx={(theme) => ({ backgroundColor: theme.palette.primary.dark })}
                  variant="contained"
                  onClick={handleSetDefaultPasswordClick}
                >
                  Set Default Password
                </Button>
              )
            }

            {
              userPermissions.canCreateUser() &&
              !(
                showBulkUnbanCTA ||
                showBulkDeactivateCTA ||
                showBulkActivateCTA ||
                showBulkGroupingCTA ||
                showBulkSetDefaultPasswordCTA
              ) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onAddClick}
                >
                  Add User +
                </Button>
              )
            }

            <ColumnSortingDropdown
              hiddenColumns={preferences.hiddenColumns}
              setHiddenColumns={preferences.setHiddenColumns}
              sortedColumns={preferences.getUnfilteredColumns(columns)}
              setColumnsOrder={preferences.setColumnsOrder}
              isManageColumnsOpen={isManageColumnsOpen}
              setIsManageColumnsOpen={(open) => {
                preferences.setToDefaultPreferences();
                setIsManageColumnsOpen(open);
              }}
              saveCurrentPreferences={preferences.saveCurrentPreferences}
              saveCurrentPreferencesLoading={
                preferences.updatePreferencesStatus === 'loading'
              }
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2 }}>
          {appliedFilterLabels.length
            ? (
              <Typography className="mb-2 mr-2">Applied Filters:</Typography>
            )
            : null}
          {appliedFilterLabels}
        </Box>

        <Table
          columns={tableColumns}
          useFixedHeight
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />
              <Typography variant="h3">Sorry! No results found.</Typography>
              <Typography className="mt-2">
                Sorry, there are no results for this search,
              </Typography>
              <Typography className="mb-6">
                please try adjusting the filter.
              </Typography>
            </div>
          }
          loading={usersQuery.isFetching}
          rowsData={rowData}
          pagination
          totalRecords={usersQuery.data ? usersQuery.data.count : 0}
          recordsPerPage={preferences.recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            preferences.setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={(np) => {
            setPage(np);
          }}
          onRowClick={(item) => {
            history.push(`${USER_PATH}/${item.uid}`);
          }}
        />
      </LoadingAndErrorHandler>

      <Dialog
        fullWidth
        maxWidth='md'
        open={isAddUsersToGroupModalOpen}
        onClose={() => setIsAddUsersToGroupModalOpen(false)}
      >
        <DialogTitle sx={{ py: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <MuiTypography variant='h6'>
              {
                addUsersToGroupModalType === 'create-new-group'
                  ? 'Create group and add users'
                  : addUsersToGroupModalType === 'add-to-existing-group'
                    ? 'Add users to group'
                    : ''
              }
            </MuiTypography>

            <Button
              color='disabled'
              variant='text'
              startIcon={<Icon icon='Close' />}
              onClick={() => setIsAddUsersToGroupModalOpen(false)}
            >
              Close
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          {
            addUsersToGroupModalType === 'create-new-group'
              ? (
                <GroupForm
                  isModalRenderedForm
                  isAddUsersToGroupForm
                  groupData={{
                    groupType: listViewMode,
                    userIds: selectedUsers.map((user) => user.uid)
                  }}
                  preDeterminedGroupType={listViewMode}
                  selectedUsers={selectedUsers}
                  onCloseClick={() => {
                    setIsAddUsersToGroupModalOpen(false);
                    setAddUsersToGroupModalType(null);
                  }}
                  onGroupCreateOrUpdate={() => {
                    setIsAddUsersToGroupModalOpen(false);
                    setAddUsersToGroupModalType(null);
                  }}
                />
              )
              : addUsersToGroupModalType === 'add-to-existing-group'
                ? (
                  <AddUsersToGroupForm
                    isModalRenderedForm
                    groupData={{
                      userIds: selectedUsers.map((user) => user.uid)
                    }}
                    preDeterminedGroupType={listViewMode}
                    selectedUsers={selectedUsers}
                    onCloseClick={() => {
                      setIsAddUsersToGroupModalOpen(false);
                      setAddUsersToGroupModalType(null);
                    }}
                    onGroupCreateOrUpdate={() => {
                      setIsAddUsersToGroupModalOpen(false);
                      setAddUsersToGroupModalType(null);
                    }}
                  />
                )
                : null
          }
        </DialogContent>
      </Dialog>
    </section>
  );
};

export function EnableOrDisableUser ({
  action,
  isBulkAction,
  userName,
  onClose,
  mutation,
  userIds,
  usersQuery,
  buttonLabel
}) {
  const [internalNote, setInternalNote] = useState('');
  const theme = useTheme();

  return (
    <div className="nucleos-core">
      <div
        className={classNames(
          'mx-auto my-0',
          action === 'disable' ? 'w-7/12' : 'w-8/12'
        )}
      >
        <div
          className="flex flex-col justify-start items-center p-10 rounded-md"
          style={{ background: '#ffffff' }}
        >
          <div>
            <Icon icon="Cancel" className="h-14 w-14" style={{ fill: '#F83232' }} />
          </div>
          <div className="text-2xl mt-6">Are you sure?</div>
          {action === 'enable'
            ? (
              <div className="text-sm mt-6">
                {
                  isBulkAction
                    ? (
                      <Typography className="text-center">
                        <span>
                          You want to activate <strong>selected {userIds.length} user(s)</strong>? Once the account(s) are
                          activated, the user(s) can gain access to it.
                        </span>
                      </Typography>
                    )
                    : (
                      <Typography className="text-center">
                        <span>
                          You want to activate user <strong>{userName}</strong>? Once the account is
                          activated, the user can gain access to it.
                        </span>
                      </Typography>
                    )
                }
              </div>
            )
            : (
              <div className="text-sm mt-3">
                {
                  isBulkAction
                    ? (
                      <Typography className="text-center">
                        <span>
                          You want to deactivate <strong>selected {userIds.length} user(s)</strong>? Once the account(s) are
                          deactivated, the user(s) will lose access to it.
                        </span>
                      </Typography>
                    )
                    : (
                      <Typography className="text-center">
                        <span>
                          You want to deactivate the user <strong>{userName}</strong>?
                          Once the account is deactivated, the user will lose access to it.
                        </span>
                      </Typography>
                    )
                }
              </div>
            )}
          {action === 'disable'
            ? (
              <div className="my-4">
                <Typography className="mb-1">Internal Note</Typography>
                <TextInput
                  style={{ resize: 'none' }}
                  placeholder="Add a note..."
                  onChange={(text) =>
                    setInternalNote(text.length > 500 ? internalNote : text)
                  }
                  value={internalNote}
                  label="Internal Note"
                  multiline
                  rows={4}
                  fullWidth
                  disableHelperText
                />
                <Typography className="justify-end">
                Maximum 500 characters
                </Typography>
                <Typography variant="body2" className="mt-2">
                  <span>
                    <b>Note:</b> Deactivating a user will remove them from having
                  an active login in the Nucleos platform. Their records will
                  still be accessible if not prohibited by regulations. <br />{' '}
                  You should only Deactivate a user if they are no longer active
                  within your organization. <br /> If you would like to Ban a
                  user to due a security or disciplinary action either
                  temporarily or open-endedly, please use the Ban function.
                  </span>
                </Typography>
              </div>
            )
            : null}
          <div
            className="flex gap-x-6 items-center mt-10 w-full"
            style={{
              justifyContent: action === 'disable' ? 'space-between' : 'center'
            }}
          >
            <NCButton style={{ color: '#333333' }} onClick={onClose}>
              Cancel
            </NCButton>
            <NCButton
              color={buttonLabel === 'Activate' ? 'success' : 'error'}
              style={buttonLabel === 'Activate' ? { backgroundColor: theme.palette.success.main, color: '#FFF' } : {}}
              variant="contained"
              onClick={() => {
                const id = toast.loading(
                  `${action === 'enable' ? 'Activating' : 'Deactivating'
                  } the user.`
                );

                mutation.mutate(
                  { uids: userIds, internalNote },
                  {
                    onSuccess: () => {
                      toast.success(
                        `User successfully ${action === 'enable' ? 'Activated' : 'Deactivated'
                        }.`
                      );
                      usersQuery.refetch();
                      toast.dismiss(id);
                    },
                    onError: () => {
                      toast.dismiss(id);
                      toast.error(
                        `Failed to ${action === 'enable' ? 'activate' : 'deactivate'
                        } user.`
                      );
                    }
                  }
                );
                onClose();
              }}
            >
              {buttonLabel}
            </NCButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(UsersTable);
