import React, { useState } from 'react';
import './RecordingIcon.scss';
import AuthenticationStore from '../../Stores/Authentication';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import Data from '../../Middleware/Data';

const RecordingIcon = () => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  const { userRole, isAuthenticated } = AuthenticationStore;

  const isLearner = isAuthenticated && userRole === 'learner';

  const configurationQuery = useQuery(
    ['CONFIGURATIONS', 'EXTENSION', isAuthenticated],
    () => Data.getExtensionConfigurations(),
    {
      refetchOnMount: false,
      enabled: isLearner
    }
  );

  const isIconEnabled = configurationQuery.isSuccess
    ? configurationQuery.data.liveActivityConfig ? configurationQuery.data.liveActivityConfig.isEnabled : false
    : false;

  const handleMouseEnter = () => {
    setPopoverVisible(true);
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  return (
    <>
      {
        isLearner && isIconEnabled && (
          <div className='recording-icon-bar'>
            <div className='recording-icon-container'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleMouseEnter}
            >
              <div className="disc">
                <div className="hole"></div>
              </div>
              <div className='rec-text'>Recording</div>
            </div>
            {isPopoverVisible && (
              <div className="popover" data-testid="recording-popover">
                        All your activities are being recorded.
                <span onClick={handleMouseLeave} className="close-button">&times;</span>
              </div>
            )}
          </div>
        )
      }
    </>
  );
};
export default observer(RecordingIcon);
