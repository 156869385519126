import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  SearchInput,
  Table,
  DateRangePicker,
  MultiSelectDropDown,
  Chip
} from '@nucleos/core-ui';
import { useQuery } from 'react-query';
import Data from '../../../../Middleware/Data';
import { getIconForSummary, getNumberForAppealLevel } from './utils';
import {
  formatDateTime,
  tableFilterLexicographicalSorter
} from '../../../../Lib/util';
import NoResultFoundSVG from '../../../../Assets/graphics/table_no_data_found.svg';
import GrievanceDetails from './GrievanceDetails';
import AlertsLegends from '../Shared/AlertsLegends';
import DataStore from '../../../../Stores/DataStore';
import UpcomingIcon from '../../../../Components/Icons/Upcoming';
import { useGrievanceRole } from '../../../../hooks/useGrievancePermissions';
import GrievanceTypeChip from '../../../../Components/Grievance/GrievanceTypeChip';
import { usePaginatedQuery } from '../../../../hooks/usePaginatedQuery';
import { useFiltersManager } from '../../../../hooks/useFilters';
import moment from 'moment';
import { ColumnSortingDropdown } from '../../../../Components/Shared/ColumnSortingDropdown';
import { usePreferences } from '../../../../hooks/usePreferences';
import { useTableSorting } from '../../../../hooks/useTableSorting';
import { useSearchParamsPagination } from '../../../../hooks/useSearchParamsPagination';
import { Icon } from '../../../../Components/Shared/Icon';
import { Box, Checkbox } from '@mui/material';
import HeaderSubPanel from '../../../../Components/HeaderSubPanel';
import { GRIEVANCE_PATH } from '../../../../constants/paths';

const ApiKeys = {
  search: 'search',
  assignee: 'assignee',
  requestId: 'requestId',
  startDate: 'startDate',
  endDate: 'endDate',
  alert: 'alert',
  catIds: 'catIds',
  facIds: 'facIds',
  appealLevel: 'appeal_level',
  learnerIds: 'learnerIds',
  assigneeIds: 'assigneeIds',
  podIds: 'pods',
  requestType: 'requestType'
};
const AppealLevelLabels = {
  INITIAL_REQUEST: 'Initial Request',
  LEVEL_1: 'Appeal Level 1',
  LEVEL_2: 'Appeal Level 2'
};

const AlertLabelsWithIcon = {
  LOW: (
    <>
      <UpcomingIcon className="mr-2" style={{ fill: '#64748B' }} />{' '}
      {'Response required'}{' '}
    </>
  ),
  MEDIUM: (
    <>
      <Icon icon="Warning" className="mr-2" style={{ color: '#ffa726' }} />{' '}
      {'Response required. No response > 3 days'}{' '}
    </>
  ),
  HIGH: (
    <>
      <Icon icon="Error" className="mr-2" style={{ color: '#f44336' }} />{' '}
      {'Response required. No response > 5 days'}{' '}
    </>
  ),
  DONE: (
    <>
      <Icon icon="CheckCircle" className="mr-2" style={{ color: '#66bb6a' }} />
      {'Responded'}
    </>
  )
};

const defaultHiddenColumns = ['Appeal Level'];
const defaultColumnOrder = [];
const defaultSorting = {
  column: 'CREATED_AT',
  sortOrder: 'DESC'
};

const breadcrumbLinks = [
  { href: GRIEVANCE_PATH, label: 'Grievances and Forms' },
  { href: '#', label: 'Responses' }
];

export default function RefactoredGrievance () {
  const history = useHistory();
  const grievanceRole = useGrievanceRole();
  const filterManager = useFiltersManager({
    defaultFilter: [
      {
        key: ApiKeys.assignee,
        value: 'ALL'
      }
    ],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const [page, setPage] = useSearchParamsPagination();
  const [isManageColumnsOpen, setIsManageColumnsOpen] = useState(false);
  const preferences = usePreferences('grievance', {
    defaultColumnOrder: defaultColumnOrder,
    defaultHiddenColumns: defaultHiddenColumns,
    onPreferencesUpdated: () => setIsManageColumnsOpen(false)
  });
  const { columnSorting, setColumnSorting } = useTableSorting({
    defaultSorting
  });

  const [search, setSearch] = useState({ assignee: '', learner: '' });

  const assigneeSearchQuery = useQuery(
    ['SEARCH', 'ASSIGNEE', search.assignee],
    () => Data.searchFormsAdminUser(search.assignee, true, true),
    { placeholderData: [] }
  );
  const learnerSearchQuery = usePaginatedQuery(
    ['SEARCH', 'LEARNERS', search.learner],
    ({ pageParam = {} }) => {
      return Data.searchFormsLearnerUser(
        search.learner,
        true,
        undefined,
        undefined,
        undefined,
        (pageParam.page || 1) - 1,
        10
      );
    },
    { listKeyName: 'rows' }
  );
  const podsQuery = useQuery(['PODS'], () => Data.getPods(), {
    placeholderData: []
  });

  const facilities = grievanceRole.getViewFacilities();
  const categories = grievanceRole.getViewCategories();
  const [onGrievanceFetchSuccess, setOnGrievanceFetchSuccess] = useState();

  const adminGrievancesQuery = useQuery(
    [
      'GRIEVANCES',
      filterManager.filters.map((f) => f.value),
      columnSorting,
      page,
      preferences.recordsPerPage
    ],
    () =>
      Data.getAllAdminGrievances(
        filterManager.getValue(ApiKeys.search) || '',
        page - 1,
        columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
        columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
        {
          assignee: filterManager.getValue(ApiKeys.assignee) || '',
          requestId: filterManager.getValue(ApiKeys.requestId) || '',
          startDate: filterManager.getValue(ApiKeys.startDate) || '',
          endDate: filterManager.getValue(ApiKeys.endDate) || '',
          alert: filterManager.getValues(ApiKeys.alert) || '',
          requestType: filterManager.getValues(ApiKeys.requestType),
          appealLevel: filterManager.getValues(ApiKeys.appealLevel)
        },
        {
          catIds: filterManager.getValues(ApiKeys.catIds),
          facIds: filterManager.getValues(ApiKeys.facIds),
          pods: filterManager.getValues(ApiKeys.podIds),
          learnerIds: filterManager.getValues(ApiKeys.learnerIds),
          assigneeIds: filterManager.getValues(ApiKeys.assigneeIds)
        },
        preferences.recordsPerPage
      ),
    {
      refetchOnWindowFocus: false,
      enabled: preferences.status === 'success',
      onSuccess: onGrievanceFetchSuccess
    }
  );

  const learnersOptions = [
    ...learnerSearchQuery.data.pages.map((data) => ({
      title: `${data.firstName} ${data.lastName}`,
      value: data.uid
    }))
  ];
  const assignToOptions = [
    ...assigneeSearchQuery.data.map((data) => ({
      title: `${data.firstName} ${data.lastName}`,
      value: data.uid
    }))
  ];

  const startDateFV = filterManager.getValue(ApiKeys.startDate);
  const endDateFV = filterManager.getValue(ApiKeys.endDate);

  const getCategoryLabel = ({ title, icon }) => (
    <>
      <img src={icon} className="w-6 h-6 mx-1.5" /> <span>{title}</span>
    </>
  );

  const alertFilterOptions = [
    {
      title: AlertLabelsWithIcon.LOW,
      value: 'LOW'
    },
    {
      title: AlertLabelsWithIcon.MEDIUM,
      value: 'MEDIUM'
    },
    {
      title: AlertLabelsWithIcon.HIGH,
      value: 'HIGH'
    },
    {
      title: AlertLabelsWithIcon.DONE,
      value: 'DONE'
    }
  ];

  const categoriesFilterOptions =
    (categories &&
      categories.sort(tableFilterLexicographicalSorter).map((c) => {
        return {
          ...c,
          title: getCategoryLabel({ title: c.title, icon: c.icon })
        };
      })) ||
    [];

  const transformValue = (v) => ({ title: v.meta.label, value: v.value });

  const columns = [
    {
      title: 'Request #',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'REQUEST_NUM'
          ? columnSorting.sortOrder
          : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'REQUEST_NUM', sortOrder }),
      render: (item) => <Typography>{item.requestNumber || 'N/A'}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Enter Id"
          style={{ width: 120 }}
          value={filterManager.getValue(ApiKeys.requestId) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(ApiKeys.requestId) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: ApiKeys.requestId }); }
            filterManager.onFilterApply({
              key: ApiKeys.requestId,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Type',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'REQUEST_TYPE'
          ? columnSorting.sortOrder
          : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'REQUEST_TYPE', sortOrder }),
      render: (item) => <GrievanceTypeChip requestType={item.requestType} />,
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-types-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          onChange={(types) => {
            filterManager.onBulkFilterApply(
              ApiKeys.requestType,
              types.map((e) => ({ value: e.value, meta: { label: e.title } }))
            );
          }}
          value={filterManager.getValues(ApiKeys.requestType, {
            transformValue
          })}
          label={'All Types'}
          getLabel={(selected) =>
            `Selected type${selected.length > 1 ? 's' : ''} (${
              selected.length
            })`
          }
          fullWidth
          options={[
            {
              title: 'Grievance request',
              value: 'GRIEVANCE'
            },
            {
              title: 'Action request',
              value: 'ACTION_REQUEST'
            }
          ].sort(tableFilterLexicographicalSorter)}
        />
      )
    },
    {
      title: 'Learner Name',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'LEARNER' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'LEARNER', sortOrder }),
      render: (item) => (
        <Typography>
          <div
            style={{ minWidth: 140 }}
          >{`${item.User.firstName} ${item.User.lastName}`}</div>
        </Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-learners-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          searchable
          isSearchLoading={!learnerSearchQuery.isFetched}
          onSearchChange={(learner) => {
            setSearch({ ...search, learner });
          }}
          onChange={(learners) => {
            filterManager.onBulkFilterApply(
              ApiKeys.learnerIds,
              learners.map((e) => ({
                value: e.value,
                meta: { label: e.title }
              }))
            );
          }}
          value={filterManager.getValues(ApiKeys.learnerIds, {
            transformValue
          })}
          label={'All Learners'}
          isNextPageLoading={learnerSearchQuery.isFetching}
          fetchNextOptionsPage={learnerSearchQuery.fetchNextPage}
          hasNextOptions={learnerSearchQuery.hasNextPage}
          getLabel={(selected) =>
            `Selected learner${selected.length > 1 ? 's' : ''} (${
              selected.length
            })`
          }
          fullWidth
          options={learnersOptions}
        />
      )
    },
    {
      title: 'Alert',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'AGE' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'AGE', sortOrder }),
      render: (item) => (
        <Typography className="flex justify-center">
          <div>{getIconForSummary(item.summary)}</div>
        </Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-alerts-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          onChange={(alert) => {
            filterManager.onBulkFilterApply(
              ApiKeys.alert,
              alert.map((e) => ({
                value: e.value,
                meta: { labelKey: e.value }
              }))
            );
          }}
          value={alertFilterOptions.filter((op) =>
            filterManager.getValues(ApiKeys.alert).includes(op.value)
          )}
          disableWidthLimit
          label={'All Alerts'}
          getLabel={(selected) =>
            `Selected alert${selected.length > 1 ? 's' : ''} (${
              selected.length
            })`
          }
          fullWidth
          options={alertFilterOptions}
        />
      )
    },
    {
      title: 'Appeal Level',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'APPEAL' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'APPEAL', sortOrder }),
      render: (item) => (
        <Typography>
          <div>{AppealLevelLabels[item.summary.appealLevel]}</div>
        </Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-appeal-levels-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          onChange={(appealLevel) => {
            filterManager.onBulkFilterApply(
              ApiKeys.appealLevel,
              appealLevel.map((e) => ({
                value: e.value,
                meta: { label: e.title }
              }))
            );
          }}
          value={filterManager.getValues(ApiKeys.appealLevel, {
            transformValue
          })}
          getLabel={(selected) =>
            `Selected appeal level${selected.length > 1 ? 's' : ''} (${
              selected.length
            })`
          }
          label={'Appeal Level'}
          options={[
            {
              title: AppealLevelLabels.INITIAL_REQUEST,
              value: 'INITIAL_REQUEST'
            },
            { title: AppealLevelLabels.LEVEL_1, value: 'LEVEL_1' },
            { title: AppealLevelLabels.LEVEL_2, value: 'LEVEL_2' }
          ]}
          fullWidth
        />
      )
    },
    {
      title: 'Date Submitted',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'CREATED_AT'
          ? columnSorting.sortOrder
          : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'CREATED_AT', sortOrder }),
      render: (item) => (
        <Typography style={{ maxWidth: 160 }}>
          {formatDateTime(item.createdAt)}
        </Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <div className="flex">
          <DateRangePicker
            disableHelperText
            fullWidth
            placeholder="Date Range"
            maxDate={new Date()}
            value={{
              startDate: startDateFV ? new Date(startDateFV) : null,
              endDate: endDateFV ? new Date(endDateFV) : null
            }}
            onChange={({ startDate, endDate }) => {
              filterManager.onFilterApply({
                key: ApiKeys.startDate,
                value: moment(startDate).format('YYYY-MM-DD'),
                meta: {
                  label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
                    endDate
                  ).format('MM/DD/YYYY')}`
                }
              });
              filterManager.onFilterApply({
                key: ApiKeys.endDate,
                value: moment(endDate).format('YYYY-MM-DD')
              });
            }}
          />
        </div>
      )
    },
    {
      title: 'Assigned to',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'ASSIGNEE' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'ASSIGNEE', sortOrder }),
      render: (item) => (
        <div style={{ minWidth: 120 }}>
          <Typography>
            {item.assignedToUser.firstName
              ? `${item.assignedToUser.firstName} ${item.assignedToUser.lastName}`
              : '-'}
          </Typography>
        </div>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-assignees-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          searchable
          isSearchLoading={assigneeSearchQuery.isFetching}
          onSearchChange={(assignee) => {
            setSearch({ ...search, assignee });
          }}
          onChange={(assignee) => {
            filterManager.onBulkFilterApply(
              ApiKeys.assigneeIds,
              assignee.map((e) => ({
                value: e.value,
                meta: { label: e.title }
              }))
            );
          }}
          value={filterManager.getValues(ApiKeys.assigneeIds, {
            transformValue
          })}
          label={'All Assignees'}
          getLabel={(selected) =>
            `Selected assignee${selected.length > 1 ? 's' : ''} (${
              selected.length
            })`
          }
          fullWidth
          options={assignToOptions}
        />
      )
    },
    {
      title: 'Facility',
      enableSort: true,
      sortOrder:
        columnSorting.column === 'FACILITY' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'FACILITY', sortOrder }),
      render: (item) => (
        <div>
          <Typography>{item.Facility.name}</Typography>
        </div>
      ),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-facilities-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          onChange={(facility) => {
            filterManager.onBulkFilterApply(
              ApiKeys.facIds,
              facility.map((e) => ({
                value: e.value,
                meta: { label: e.title }
              }))
            );
          }}
          value={filterManager.getValues(ApiKeys.facIds, { transformValue })}
          getLabel={(selected) =>
            `Selected ${selected.length > 1 ? 'facilities' : 'facility'} (${
              selected.length
            })`
          }
          label={'Facility'}
          fullWidth
          options={(facilities || []).sort(tableFilterLexicographicalSorter)}
        />
      )
    },
    {
      title: 'Category',
      render: (item) => <Typography>{item.GrievanceCategory.name}</Typography>,
      enableSort: true,
      sortOrder:
        columnSorting.column === 'CATEGORY' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'CATEGORY', sortOrder }),
      renderFilter: (_, idx, clist) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-categories-select"
          DropdownContainerProps={{
            direction: idx === clist.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          onChange={(v) => {
            filterManager.onBulkFilterApply(
              ApiKeys.catIds,
              v.map((e) => ({ value: e.value, meta: { labelKey: e.value } }))
            );
          }}
          value={categoriesFilterOptions.filter((op) =>
            filterManager.getValues(ApiKeys.catIds).includes(op.value)
          )}
          getLabel={(selected) =>
            `Selected ${selected.length > 1 ? 'categories' : 'category'} (${
              selected.length
            })`
          }
          label={'Categories'}
          fullWidth
          options={categoriesFilterOptions}
        />
      )
    },
    {
      title: 'Pod',
      render: (item) => (
        <Typography>{item.User.pod ? item.User.pod : '-'}</Typography>
      ),
      enableSort: true,
      sortOrder:
        columnSorting.column === 'POD' ? columnSorting.sortOrder : 'NONE',
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: 'POD', sortOrder }),
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="grievances-table-pods-select"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          onChange={(v) => {
            filterManager.onBulkFilterApply(
              ApiKeys.podIds,
              v.map((e) => ({ value: e.value, meta: { label: e.title } }))
            );
          }}
          value={filterManager.getValues(ApiKeys.podIds, { transformValue })}
          getLabel={(selected) =>
            `Selected ${selected.length > 1 ? 'categories' : 'category'} (${
              selected.length
            })`
          }
          label={'All Pods'}
          fullWidth
          options={(
            podsQuery.data.map((pod) => ({ title: pod.pod, value: pod.pod })) ||
            []
          ).sort(tableFilterLexicographicalSorter)}
        />
      )
    }
  ];

  if (preferences.status !== 'success' || grievanceRole.isLoading) { return null; }

  const currentId = +new URLSearchParams(window.location.search).get(
    'grievanceId'
  );
  let currentIndex, nextItem, prevItem;
  if (adminGrievancesQuery.isSuccess) {
    currentIndex = adminGrievancesQuery.data.grievances.findIndex(
      (grv) => grv.id === currentId
    );
    nextItem = adminGrievancesQuery.data.grievances[currentIndex + 1];
    prevItem = adminGrievancesQuery.data.grievances[currentIndex - 1];
  }

  const onFilterDelete = (appliedFilter) => {
    const isDateRangeFilter = [ApiKeys.startDate, ApiKeys.endDate].includes(
      appliedFilter.key
    );

    if (isDateRangeFilter) {
      filterManager.onFilterRemove({
        key: ApiKeys.startDate
      });
      filterManager.onFilterRemove({
        key: ApiKeys.endDate
      });
      return;
    }

    filterManager.onFilterRemove({
      key: appliedFilter.key,
      value: appliedFilter.value
    });
  };

  const onArrowClicked = (direction) => () => {
    const upcomingItem = direction === 'next' ? nextItem : prevItem;
    if (upcomingItem) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('grievanceId', upcomingItem.id);
      history.replace(`/forms/admin/grievances/?${searchParams.toString()}`);
    } else {
      setPage(direction === 'next' ? page + 1 : page - 1);
      setOnGrievanceFetchSuccess(() => (data) => {
        const upcomingItem =
          direction === 'next'
            ? data.grievances[0]
            : data.grievances[data.grievances.length - 1];
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('grievanceId', upcomingItem.id);
        history.replace(`/forms/admin/grievances/?${searchParams.toString()}`);
        setOnGrievanceFetchSuccess(undefined);
      });
    }
  };

  const getLabelForFilterChip = (filter) => {
    if ((filter.meta || {}).label || (filter.meta || {}).labelKey) {
      if ((filter.meta || {}).label) { return (filter.meta || {}).label; } else {
        return [...categoriesFilterOptions, ...alertFilterOptions].find(
          (f) => f.value === filter.value
        ).title;
      }
    }

    return filter.value;
  };

  const appliedFilterLabels = filterManager.filters
    .filter((f) => ![ApiKeys.assignee, ApiKeys.endDate].includes(f.key))
    .map((filter) => (
      <Chip
        rounded="full"
        className="mb-2 mr-2"
        style={{ padding: '6px 8px' }}
        label={getLabelForFilterChip(filter)}
        onDelete={() =>
          onFilterDelete({ key: filter.key, value: filter.value })
        }
      />
    ));

  return (
    <Box>
      <HeaderSubPanel title="Responses" links={breadcrumbLinks} sx={{ mb: 3 }} />
      <div className="invert-scrollbar nucleos-core">
        <div className="mt-2 mb-4 flex justify-between">
          <div className="flex items-center">
            <SearchInput
              placeholder="Search by keywords..."
              value={filterManager.getValue(ApiKeys.search) === ''}
              onSearch={(value) => {
                if (filterManager.getValue(ApiKeys.search) === search) { return; }
                if (!value) { return filterManager.onFilterRemove({ key: ApiKeys.search }); }
                filterManager.onFilterApply({
                  key: ApiKeys.search,
                  value: value
                });
              }}
            />
            <Box display='flex' alignItems="center" className="ml-4">
              <Checkbox
                checked={filterManager.getValue(ApiKeys.assignee) === 'SELF'}
                data-checked={filterManager.getValue(ApiKeys.assignee) === 'SELF'}
                onChange={(_, showOnlyAssignedGrievances) => {
                  filterManager.onFilterApply({
                    key: ApiKeys.assignee,
                    value: showOnlyAssignedGrievances ? 'SELF' : 'ALL'
                  });
                }}
              />
              <Typography>Show only my assigned requests</Typography>
            </Box>
          </div>
          <div className="flex flex-1 justify-end">
            <ColumnSortingDropdown
              hiddenColumns={preferences.hiddenColumns}
              setHiddenColumns={preferences.setHiddenColumns}
              sortedColumns={preferences.getUnfilteredColumns(columns)}
              setColumnsOrder={preferences.setColumnsOrder}
              isManageColumnsOpen={isManageColumnsOpen}
              setIsManageColumnsOpen={(open) => {
                preferences.setToDefaultPreferences();
                setIsManageColumnsOpen(open);
              }}
              saveCurrentPreferences={preferences.saveCurrentPreferences}
              saveCurrentPreferencesLoading={
                preferences.updatePreferencesStatus === 'loading'
              }
            />
          </div>
        </div>
        <div className="flex items-center flex-wrap">
          {appliedFilterLabels.length
            ? (
              <Typography className="mb-2 mr-2">Applied Filters:</Typography>
            )
            : null}
          {appliedFilterLabels}
        </div>
        <Table
          columns={preferences.getFilteredColumns(columns)}
          useFixedHeight
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />
              <Typography variant="h3">Sorry! No results found.</Typography>
              <Typography className="mt-2">
              Sorry, there are no results for this search,
              </Typography>
              <Typography className="mb-6">
              please try adjusting the filter.
              </Typography>
            </div>
          }
          loading={adminGrievancesQuery.status !== 'success'}
          rowsData={
            adminGrievancesQuery.data ? adminGrievancesQuery.data.grievances : []
          }
          pagination
          totalRecords={
            adminGrievancesQuery.data ? adminGrievancesQuery.data.count : 0
          }
          recordsPerPage={preferences.recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            preferences.setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
          onRowClick={(item) => {
            const canViewGrievance = grievanceRole.canViewGrievance({
              gLevel: getNumberForAppealLevel(item.summary.appealLevel),
              gCategoryId: item.GrievanceCategory.id,
              gFacilityId: item.Facility.id
            });
            if (!canViewGrievance) { return; }
            setTimeout(() => {
              DataStore.setPageTitleDetail(
                `Grievances and Forms > Responses > ${item.id}`
              );
            }, 0);
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('grievanceId', item.id);
            searchParams.set('requestType', item.requestType);
            searchParams.set('requestNumber', item.requestNumber);
            history.replace(
              `/forms/admin/grievances/?${searchParams.toString()}`
            );
          }}
        />
        <div className="mt-4 pb-4">
          <AlertsLegends />
        </div>
        <GrievanceDetails
          onGrievanceUpdate={adminGrievancesQuery.refetch}
          disableNext={
            adminGrievancesQuery.data
              ? adminGrievancesQuery.data.count / preferences.recordsPerPage <
                page && !nextItem
              : true
          }
          disablePrev={page === 1 && !prevItem}
          onNext={onArrowClicked('next')}
          onPrevious={onArrowClicked('prev')}
        />
      </div>
    </Box>
  );
}
