import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Tabs, Tab, styled, Typography, Divider } from '@mui/material';
import { SearchInput } from '@nucleos/core-ui';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import CourseLibraryTile from './CourseLibraryTile';
import DataStore from '../../Stores/DataStore';
import AuthenticationStore from '../../Stores/Authentication';
import NoCourseContentImage from '../../Assets/graphics/no_courses_found.svg';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import { Icon } from '../../Components/Shared/Icon';

const allTabs = [
  {
    title: 'All',
    icon: 'AllCourses'
  },
  {
    title: 'Academic',
    icon: 'AcademicIcon'
  },
  {
    title: 'Vocational',
    icon: 'VocationIcon'
  },
  {
    title: 'Social / Emotional Wellness',
    icon: 'SocialIcon'
  },
  {
    title: 'CTE',
    icon: 'CTEFilterIcon'
  },
  {
    title: 'Library',
    icon: 'LibraryIcon'
  },
  {
    title: 'Release',
    icon: 'ReleaseIcon'
  },
  {
    title: 'Social',
    icon: 'SocialIcon'
  },
  {
    title: 'Legal',
    icon: 'LegalIcon'
  },
  {
    title: 'Entertainment',
    icon: 'EntertainmentIcon'
  },
  {
    title: 'Informational',
    icon: 'InformationalIcon'
  },
  {
    title: 'Resources',
    icon: 'ResourcesIcon'
  },
  {
    title: 'Services',
    icon: 'ServicesIcon'
  },
  {
    title: 'Communications',
    icon: 'CommunicationIcon'
  }
];

const PREFIX = 'CourseLibrary';
const classes = {
  noContentImageContainer: `${PREFIX}-noContentImageContainer`,
  noContentTextContainer: `${PREFIX}-noContentTextContainer`,
  noContentText: `${PREFIX}-noContentText`,
  noContentSubText: `${PREFIX}-noContentSubText`
};

const Root = styled('div')(() => ({
  [`& .${classes.noContentImageContainer}`]: {
    display: 'flex',
    justifyContent: 'center'
  },
  [`& .${classes.noContentTextContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '30px'
  },
  [`& .${classes.noContentText}`]: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '27px',
    textAlign: 'center',
    letterSpacing: ' -0.005em'
  },
  [`& .${classes.noContentSubText}`]: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'center',
    maxWidth: '440px',
    marginTop: '12px'
  }
}));

const CourseLibrary = () => {
  const { userRole } = AuthenticationStore;
  const isLearner = userRole === 'learner';
  const {
    getApplications,
    getApplicationsActive,
    getApplicationsLoading,
    applications
  } = DataStore;
  const [category, setCategory] = useState('All');
  const [search, setSearch] = useState('');

  useEffect(() => {
    const getApplicationsAction = isLearner
      ? getApplicationsActive
      : getApplications;
    getApplicationsAction();
  }, [isLearner, getApplications, getApplicationsActive]);

  const filteredApps = useMemo(() => {
    let data =
      category === 'All'
        ? applications
        : applications.filter(({ tags = [] }) =>
          (tags || []).includes(category)
        );
    if (search) {
      data = data.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }
    return data;
  }, [category, applications, search]);

  const tabs = useMemo(() => {
    const allTags = new Map();
    applications.forEach(({ tags = [] }) => {
      if (Array.isArray(tags)) {
        tags.forEach(tag => {
          allTags.set(tag, (allTags.get(tag) || 0) + 1);
        });
      }
    });

    return allTabs
      .filter(tab => tab.title === 'All' || allTags.has(tab.title))
      .sort((a, b) => {
        if (a.title === 'All') { return -1; } // 'All' comes first
        if (b.title === 'All') { return 1; } // 'All' comes first
        return allTags.get(b.title) - allTags.get(a.title); // Sort by tag occurrence count
      });
  }, [applications]);

  const NoCourseContent = () => {
    return (
      <div>
        <div className={classes.noContentImageContainer}>
          <img src={NoCourseContentImage} alt="No Courses" />
        </div>
        <div className={classes.noContentTextContainer}>
          <div className={classes.noContentText}>
            {search
              ? 'No Courses/Apps found'
              : 'No Available Courses/Apps in this Category'}
          </div>
          <Typography variant='disabled' className={classes.noContentSubText}>
            {search
              ? 'There were no courses/apps found with this keyword. Please try another.'
              : 'Category has no available courses/applications at the moment - check back soon or explore other categories.'}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Root className="course-library">
      <HeaderSubPanel title="Course & Content Library" sx={{ mb: 3 }} />

      <section>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div></div>
          <SearchInput
            style={{ width: 240 }}
            onSearch={(value) => setSearch(value)}
          />
        </div>
        <Tabs
          variant='scrollable'
          value={category}
          onChange={(_, value) => setCategory(value)}
        >
          {tabs.map(({ title, icon }) => {
            const regex = new RegExp('(/| )+', 'gi');
            const key = title.replace(regex, '-').toLowerCase();
            return (
              <Tab
                key={key}
                value={title}
                style={{
                  minWidth: '125px',
                  // width: 'fit-content',
                  alignSelf: 'baseline',
                  fontWeight: 400
                }}
                label={
                  <div>
                    {/* <img src={icon} alt="" width={50} /> */}
                    <Icon icon={icon} sx={{ color: (theme) => category === title ? theme.palette.link.main : '', height: '40px', width: '40px' }} />
                    <Typography style={{ textTransform: 'capitalize' }}>{title}</Typography>
                  </div>
                }
              />
            );
          })}
        </Tabs>
      </section>
      <Divider />
      <section>
        {!getApplicationsLoading && filteredApps && filteredApps.length === 0
          ? (
            <NoCourseContent classes={classes} />
          )
          : (
            <FlexTileGrid horizontal tileStyle={{ display: 'block' }}>
              {getApplicationsLoading || !filteredApps
                ? (
                  <LoadingComponent small="true" />
                )
                : (
                  filteredApps &&
                  filteredApps.map((app) => {
                    const key = app.name.split(' ').join('-').toLowerCase();
                    return <CourseLibraryTile key={key} app={app} />;
                  })
                )}
            </FlexTileGrid>
          )}
      </section>
    </Root>
  );
};

export default (observer(CourseLibrary));
