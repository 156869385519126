import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Typography,
  SearchInput,
  Table,
  DateRangePicker,
  MultiSelectDropDown,
  Chip
} from '@nucleos/core-ui';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';
import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';

import Data from '../../../Middleware/Data';
import DropDownMenu from '../../../Components/Shared/DropDownMenu';
import useUserPermissions from '../../../hooks/useUserPermissions';
import { QueryKeys } from '../../../Lib/query-keys';
import { useFiltersManager } from '../../../hooks/useFilters';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { formatDateTime } from '../../../Lib/util';
import { useTableSorting } from '../../../hooks/useTableSorting';
import { ACTIVE_ROLES } from '../../../Lib/CONSTANTS';
import { Icon } from '../../../Components/Shared/Icon';
import { GRAY } from '../../../constants/colors';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import ApproveLicensePopup from './ApproveLicensePopup';
import RejectLicensePopup from './RejectLicensePopup';

const defaultSorting = {
  column: 'CREATED_AT',
  sortOrder: 'DESC'
};

const FilterKeys = {
  learnerName: 'learnerName',
  licenseType: 'licenseType',
  courseName: 'courseName',
  note: 'note',
  requestedOnStartDate: 'requestedOnStartDate',
  requestedOnEndDate: 'requestedOnEndDate',
  status: 'status'
};

const ColumnKeys = {
  NAME: 'NAME',
  LICENSE_TYPE: 'LICENSE_TYPE',
  COURSE_NAME: 'COURSE_NAME',
  NOTE: 'NOTE',
  CREATED_AT: 'CREATED_AT'
};

const licenseTypeOptions = [
  { value: 'app', title: 'App' },
  { value: 'course', title: 'Course' }
];

const statusOptions = [
  { value: 'PENDING', title: 'Pending' }
];

const userActions = [
  { name: 'Approve' },
  { name: 'Reject' }
];

const PendingApproval = ({ applicationUid, search, licenseMetadata }) => {
  const filterManager = useFiltersManager({
    defaultFilter: [],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const { columnSorting, setColumnSorting, getCurrentSorting } =
    useTableSorting({
      defaultSorting,
      prefix: 'pending-for-approval'
    });
  const userPermissions = useUserPermissions();

  const startDate = filterManager.getValue(FilterKeys.requestedOnStartDate);
  const endDate = filterManager.getValue(FilterKeys.requestedOnEndDate);

  const requestData = {
    appId: applicationUid,
    offset: (page - 1) * recordsPerPage,
    orderBy: columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
    order: columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
    pending: true,
    q: JSON.stringify({
      learnerName: filterManager.getValue(FilterKeys.learnerName) || '',
      licenseType: licenseMetadata.appLicense ? filterManager.getValues(FilterKeys.licenseType) : '',
      courseName: filterManager.getValue(FilterKeys.courseName) || '',
      note: filterManager.getValue(FilterKeys.note) || '',
      dateStart: startDate || '',
      dateEnd: endDate || '',
      status: filterManager.getValues(FilterKeys.status) || '',
      search
    }),
    limit: recordsPerPage
  };

  const pendingApprovalQuery = useQuery(
    QueryKeys.PendingApproval.listing(requestData),
    () => Data.getPendingForApprovalLicenses(requestData),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { rows: [], count: 0 }
    }
  );

  const renderMultiSelectFilter = (
    dropdownId,
    index,
    columnList,
    filter,
    label,
    options,
    type,
    testId,
    searchable = false,
    searchValue = '',
    onSearchChange = () => null,
    isSearchLoading = false,
    isNextPageLoading = false,
    hasNextOptions = false,
    fetchNextOptionsPage = () => null
  ) => {
    const transformValue = (v) => ({ title: v.meta.label, value: v.value });

    return (
      <MultiSelectDropDown
        dropdownId={dropdownId}
        testId={testId}
        DropdownContainerProps={{
          direction: index === columnList.length - 1 ? 'left' : 'right'
        }}
        disableHelperText
        value={filterManager.getValues(FilterKeys[filter], { transformValue })}
        onChange={(values) => {
          filterManager.onBulkFilterApply(
            FilterKeys[filter],
            values.map((v) => ({
              value: v.value,
              meta: { label: v.title }
            }))
          );
        }}
        label={label}
        getLabel={(selected) =>
          `Selected ${type}${selected.length > 1 ? 's' : ''} (${selected.length
          })`
        }
        fullWidth
        options={options}
        searchable={searchable}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        isSearchLoading={isSearchLoading}
        isNextPageLoading={isNextPageLoading}
        hasNextOptions={hasNextOptions}
        fetchNextOptionsPage={fetchNextOptionsPage}
      />
    );
  };

  const columns = [
    {
      title: 'Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.NAME, sortOrder }),
      render: (item) => <Typography>{item.learnerName}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          value={filterManager.getValue(FilterKeys.learnerName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.learnerName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.learnerName }); }
            filterManager.onFilterApply({
              key: FilterKeys.learnerName,
              value: search
            });
          }}
        />
      )
    },
    licenseMetadata && licenseMetadata.appLicense && {
      title: 'License Type',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.LICENSE_TYPE),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.LICENSE_TYPE, sortOrder }),
      render: (item) => <Typography>{item.licenseType}</Typography>,
      renderFilter: (_, index, columnList) =>
        renderMultiSelectFilter(
          'license-type-select',
          index,
          columnList,
          'licenseType',
          'All Types',
          licenseTypeOptions,
          'License Type',
          'filter-license-type'
        )
    },
    {
      title: 'Course Name',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.COURSE_NAME),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.COURSE_NAME, sortOrder }),
      render: (item) => <Typography>{item.Course.title || '––'}</Typography>,
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          value={filterManager.getValue(FilterKeys.courseName) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.courseName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.courseName }); }
            filterManager.onFilterApply({
              key: FilterKeys.courseName,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Note',
      enableSort: false,
      render: (item) => {
        const parsedNote = item.learnerRequestNote ? HTMLReactParser(item.learnerRequestNote) : '––';
        return (
          <Typography title={item.learnerRequestNote ? parsedNote : ''}>
            <span style={{ display: 'inline-block', width: '30ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {parsedNote}
            </span>
          </Typography>
        );
      },
      renderFilter: (_, index, columnList) => (
        <SearchInput
          placeholder="Search"
          style={{ width: '30ch' }}
          value={filterManager.getValue(FilterKeys.note) || ''}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.note) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.note }); }
            filterManager.onFilterApply({
              key: FilterKeys.note,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Requested On',
      enableSort: true,
      sortOrder: getCurrentSorting(ColumnKeys.CREATED_AT),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: ColumnKeys.CREATED_AT, sortOrder }),
      render: (item) => (
        <Typography>{formatDateTime(item.createdAt)}</Typography>
      ),
      renderFilter: (_, index, columnList) => (
        <DateRangePicker
          testId="filter-requested-on"
          DropdownContainerProps={{
            direction: 'left'
          }}
          disableHelperText
          fullWidth
          placeholder="Date Range"
          maxDate={new Date()}
          value={{
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null
          }}
          onChange={({ startDate, endDate }) => {
            filterManager.onFilterApply({
              key: FilterKeys.requestedOnStartDate,
              value: moment(startDate).format('YYYY-MM-DD'),
              meta: {
                label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(
                  endDate
                ).format('MM/DD/YYYY')}`
              }
            });
            filterManager.onFilterApply({
              key: FilterKeys.requestedOnEndDate,
              value: moment(endDate).format('YYYY-MM-DD')
            });
          }}
        />
      )
    },
    {
      title: 'Status',
      enableSort: false,
      render: (item) =>
        <Chip
          rounded="full"
          variant={'outlined'}
          color={'warning'}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Typography color='warning' variant='subtitle2' >
            <Icon icon="Loop" style={{ height: 18 }} />
            Pending
          </Typography>
        </Chip>,
      renderFilter: (_, index, columnList) =>
        renderMultiSelectFilter(
          'status-select',
          index,
          columnList,
          'status',
          'All Statuses',
          statusOptions,
          'Status',
          'filter-status'
        )
    }
  ].filter(Boolean);

  if ([ACTIVE_ROLES.SUPER_ADMIN].includes(userPermissions.role)) {
    columns.push({
      title: 'Action',
      render: (item) =>
        (
          <div className="flex justify-end cursor-pointer">
            <DropDownMenu
              testId={'user-action'}
              onActionClick={(action) => {
                onActionClick(action.name, item);
              }}
              actions={userActions}
            >
              <Icon icon="ThreeDots" sx={{ color: GRAY }} />
            </DropDownMenu>
          </div>
        )
    });
  }

  const onActionClick = (actionName, data) => {
    switch (actionName) {
    case 'Approve': {
      confirmAlert({
        customUI: ({ onClose }) => (
          <ApproveLicensePopup
            formFields={licenseMetadata.fields || []}
            username={data.learnerName}
            licenseId={data.uid}
            onSubmitHandler={approveLicenseHandler}
            onCloseModal={onClose}
          />
        ),
        closeOnClickOutside: false,
        closeOnEscape: false
      });
      break;
    }

    case 'Reject': {
      confirmAlert({
        customUI: ({ onClose }) => (
          <RejectLicensePopup
            username={data.learnerName}
            licenseId={data.uid}
            onSubmitHandler={rejectLicenseHandler}
            onCloseModal={onClose}
          />
        ),
        closeOnClickOutside: false,
        closeOnEscape: false
      });
      break;
    }

    default:
      break;
    }
  };

  const approveLicenseMutation = useMutation((data) =>
    Data.approveLicense(data).then(GenericErrorDetectorForMutations)
  );

  const approveLicenseHandler = async (formData, username, licenseId, onCloseModal) => {
    const payload = { licenseId, license: formData };
    approveLicenseMutation.mutate(payload, {
      onSuccess: () => {
        toast.success(`License approved successfully for ${username}.`);
        pendingApprovalQuery.refetch();
      },
      onError: () => toast.error(`Failed to approve license for ${username}.`)
    });
    onCloseModal();
  };

  const rejectLicenseMutation = useMutation((data) =>
    Data.rejectLicense(data).then(GenericErrorDetectorForMutations)
  );

  const rejectLicenseHandler = async (formData, username, licenseId, onCloseModal) => {
    const payload = {
      licenseId,
      adminRejectNote: formData.note || ''
    };
    rejectLicenseMutation.mutate(payload, {
      onSuccess: () => {
        toast.success(`License rejected successfully for ${username}.`);
        pendingApprovalQuery.refetch();
      },
      onError: () => toast.error(`Failed to reject license for ${username}.`)
    });
    onCloseModal();
  };

  const appliedFilterLabels = filterManager.filters
    .filter((filter) => ![FilterKeys.requestedOnEndDate].includes(filter.key))
    .map((filter) => (
      <Chip
        key={filter.key}
        rounded="full"
        className="mb-2 mr-2"
        style={{ padding: '6px 8px' }}
        label={filter.meta ? filter.meta.label : filter.value}
        onDelete={() => onFilterDelete(filter)}
      />
    ));

  const onFilterDelete = (appliedFilter) => {
    const isDateRangeFilter = [
      FilterKeys.requestedOnStartDate,
      FilterKeys.requestedOnEndDate
    ].includes(appliedFilter.key);

    if (isDateRangeFilter) {
      filterManager.onFilterRemove({
        key: FilterKeys.requestedOnStartDate
      });
      filterManager.onFilterRemove({
        key: FilterKeys.requestedOnEndDate
      });
      return;
    }

    filterManager.onFilterRemove({
      key: appliedFilter.key,
      value: appliedFilter.value
    });
  };

  return (
    <div className="nucleos-core">
      <div className="flex items-center flex-wrap">
        {appliedFilterLabels.length
          ? (
            <Typography className="mb-2 mr-2">Applied Filters:</Typography>
          )
          : null}
        {appliedFilterLabels}
      </div>
      <LoadingAndErrorHandler
        isLoading={pendingApprovalQuery.isLoading}
        isSuccess={pendingApprovalQuery.isSuccess}
        isError={pendingApprovalQuery.isError}
      >
        <Table
          useFixedHeight
          columns={columns}
          noDataMessage={<GenericNoDataFound className="mb-5" />}
          loading={pendingApprovalQuery.isFetching}
          rowsData={pendingApprovalQuery.data.rows ? pendingApprovalQuery.data.rows : []}
          pagination
          totalRecords={
            pendingApprovalQuery.data.count ? pendingApprovalQuery.data.count : 0
          }
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </LoadingAndErrorHandler>
    </div>
  );
};

export default PendingApproval;
