import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Typography } from '@nucleos/core-ui';
import { Icon } from '../../../Components/Shared/Icon';
import { useAppContext } from '../../../Contexts/AppContext';

export default function LiveActivityHeader ({
  totalLearners,
  page,
  setPage,
  totalPage,
  onUsersListIconClick,
  showCloseButton,
  headerName,
  onClose
}) {
  const appContext = useAppContext();

  const [time, setTime] = useState(moment.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(moment.now()), 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="nucleos-core" id="live-activity-header">
      <div
        className="px-10 py-3 bg-white flex justify-between"
        id="live_activity_header"
      >
        <div className="flex items-center" id="live_activity_header_top_row">
          <Link to={'/home'}>
            <img src={appContext.logoURL} className="h-10" alt="Nucleos" />
          </Link>
          <Typography className="px-6 font-extrabold" variant="h2">
            {headerName || `Live Activity - Screen ${page}/${totalPage}`}
          </Typography>
        </div>
        {!showCloseButton && (
          <div className="flex items-center" id="live_activity_header_last_row">
            <div className="mx-4">
              <Typography
                variant="h4"
                className="justify-center font-bold uppercase"
              >
                Total Learners
              </Typography>
              <Typography
                variant="h3"
                className="justify-center font-extrabold"
              >
                {totalLearners}
              </Typography>
            </div>
            <div className="mx-4">
              <Typography variant="h4" className="justify-center font-bold">
                {moment(time).format('MMM DD, YYYY')}
              </Typography>
              <Typography
                variant="h3"
                className="justify-center font-extrabold"
              >
                {moment(time).format('hh:mm A')}
              </Typography>
            </div>
            <div className="flex items-center ml-4 mr-2">
              <div>
                <IconButton
                  className="border border-solid p-4"
                  style={{ borderColor: '#3333331A' }}
                  onClick={onUsersListIconClick}
                  data-testid="live-activity-list-btn"
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.625 8.5L9.125 8.50075C9.40466 8.50076 9.67429 8.60494 9.88131 8.79296C10.0883 8.98099 10.2179 9.23938 10.2447 9.51775L10.25 9.625V11.125C10.2492 13.75 7.463 14.5 5.375 14.5C3.3335 14.5 0.62375 13.783 0.50375 11.2975L0.5 11.125V9.625C0.500013 9.34534 0.60419 9.07571 0.792214 8.86869C0.980238 8.66166 1.23863 8.5321 1.517 8.50525L1.625 8.5ZM10.616 8.5H14.375C14.6545 8.5 14.9241 8.60407 15.1311 8.79194C15.3381 8.97981 15.4677 9.23801 15.4947 9.51625L15.5 9.625V10.75C15.4992 13.0465 13.3565 13.75 11.75 13.75C11.2059 13.7513 10.6647 13.6699 10.145 13.5085C10.6347 12.946 10.952 12.2313 10.9947 11.335L11 11.125V9.625C11 9.2545 10.8875 8.91175 10.7007 8.6215L10.616 8.5ZM5.375 0.25C6.27011 0.25 7.12855 0.605579 7.76148 1.23851C8.39442 1.87145 8.75 2.72989 8.75 3.625C8.75 4.52011 8.39442 5.37855 7.76148 6.01149C7.12855 6.64442 6.27011 7 5.375 7C4.47989 7 3.62145 6.64442 2.98851 6.01149C2.35558 5.37855 2 4.52011 2 3.625C2 2.72989 2.35558 1.87145 2.98851 1.23851C3.62145 0.605579 4.47989 0.25 5.375 0.25ZM12.125 1.75C12.8212 1.75 13.4889 2.02656 13.9812 2.51884C14.4734 3.01113 14.75 3.67881 14.75 4.375C14.75 5.07119 14.4734 5.73887 13.9812 6.23116C13.4889 6.72344 12.8212 7 12.125 7C11.4288 7 10.7611 6.72344 10.2688 6.23116C9.77656 5.73887 9.5 5.07119 9.5 4.375C9.5 3.67881 9.77656 3.01113 10.2688 2.51884C10.7611 2.02656 11.4288 1.75 12.125 1.75Z"
                      fill="#333333"
                      fillOpacity="0.8"
                    />
                  </svg>
                </IconButton>
              </div>
            </div>
            <div className="border border-solid border-gray mx-4 my-2 h-10" />
            <div className="flex items-center mx-2">
              <div>
                <IconButton
                  disabled={page <= 1}
                  className="border border-solid p-2"
                  style={{
                    borderColor: '#3333331A',
                    opacity: page <= 1 ? '0.5' : '1'
                  }}
                  onClick={() => setPage(page - 1)}
                  data-testid="live-activity-prev-page-btn"
                >
                  <Icon icon='LeftArrow' style={{ fontSize: '30px', color: '#5a5a5b' }} />
                </IconButton>
              </div>
            </div>
            <div className="flex items-center mx-2">
              <div>
                <IconButton
                  disabled={page >= totalPage}
                  className="border border-solid p-2"
                  style={{
                    borderColor: '#3333331A',
                    opacity: page >= totalPage ? '0.5' : '1'
                  }}
                  onClick={() => setPage(page + 1)}
                  data-testid="live-activity-next-page-btn"
                >
                  <Icon icon="RightArrow"
                    style={{ fontSize: '30px', color: '#5a5a5b' }}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        )}
        {showCloseButton && (
          <div className="flex items-end" id="live_activity_header_last_row">
            <div>
              <IconButton
                className="border border-solid p-2"
                style={{ borderColor: '#3333331A' }}
                onClick={() => onClose()}
              >
                <Icon icon="Close" style={{ fontSize: '30px', color: '#5a5a5b' }} />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
