import React from 'react';
import { Button, Card, Typography } from '@nucleos/core-ui';

export default function ChangeGrievanceTypeCard ({
  onClose,
  onTypeChange,
  isTypeChangeLoading
}) {
  return (
    <Card className="w-96 grievance-card mt-2">
      <Card.Header title="Change Request Type" onClose={onClose} />
      <Card.Body>
        <div className="block pb-2">
          <Typography style={{ display: 'block' }} className="mb-4">
            Are you sure you want to change the request type from{' '}
            <b>
              <u>Grievance</u>
            </b>{' '}
            to{' '}
            <b>
              <u>Action Request</u>
            </b>
            ? Please note that this action cannot be reversed.
          </Typography>
        </div>
        <div className="flex justify-between">
          <Button
            size="small"
            variant="contained"
            color="primary"
            loading={isTypeChangeLoading}
            onClick={onTypeChange}
          >
            Change
          </Button>
          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
