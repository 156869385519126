import React, { useState, useEffect } from 'react';
import ScrollTop from '../../Assets/scroll-top.svg';
const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'

    });
  };

  return (
    <div className='nucleos-core'>
      <div className='fixed bottom-5 cursor-pointer' style={{ left: '95%' }} >
        <img onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }}
          src={ScrollTop} alt={'scroll top'} />
      </div>
    </div>
  );
};

export default ScrollToTop;
