import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationsContextProvider, useNotificationsContext } from './NotificationsContext';
import { Button, LoadingSpinner, MultiSelectDropDown, SearchInput, Typography } from '@nucleos/core-ui';
import { Box, Chip, IconButton } from '@mui/material';
import { LoadingAndErrorHandler } from '../../Components/Shared/LoadingErrorHandler';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { NOTIFICATIOINS_FILTER_OPTIONS } from '../../Lib/CONSTANTS';

import { NotificationTile } from './NotificationTile';
import { EmptyNotificationsInfo } from './EmptyNotificationsInfo';

import useUserPermissions from '../../hooks/useUserPermissions';
import { Icon } from '../../Components/Shared/Icon';
import HeaderSubPanel from '../../Components/HeaderSubPanel';
import Authentication from '../../Stores/Authentication';
import { MESSAGE_USER_PATH, MESSENGER } from '../../constants/paths';

const _NotificationsLayout = (props) => {
  const history = useHistory();
  const isLearner = Authentication.userRole === 'learner';

  const {
    isDropdownLayout,
    showCategoryFilter = true,
    showNotificationSearch = true,
    handleClose,
    hideHeaderSubPanel
  } = props;

  const notificationsCTX = useNotificationsContext();

  const userPermissions = useUserPermissions();

  const handleFilterSelect = useCallback(([value]) => {
    notificationsCTX.setCategory(value.value);
  }, [notificationsCTX.setCategory]);

  const goToAccountSettings = useCallback(() => {
    const sp = new URLSearchParams({
      scrollToId: 'account-notification-settings'
    });

    if (handleClose) {
      handleClose();
    }

    history.push({
      pathname: '/account-settings',
      search: sp.toString()
    });
  }, [history, handleClose]);

  const getNotificationLink = (notificationData) => {
    if (notificationData.category === 'MESSAGE') {
      const uid = notificationData.link.split('/')[3];
      const link = `${isLearner ? MESSENGER : MESSAGE_USER_PATH}/user/${uid}`;

      return { ...notificationData, link };
    }

    return notificationData;
  };

  return (
    <Box>
      {!hideHeaderSubPanel ? <HeaderSubPanel title='Notifications' sx={{ mb: 3 }} /> : null}

      <div className="nucleos-core">
        <div className={`flex justify-between mb-2 ${isDropdownLayout ? 'mt-2 mx-4' : ''}`}>
          <div className="flex gap-x-8 items-center">
            <div className="flex flex-col">
              {
                isDropdownLayout
                  ? (
                    <div className="flex items-center gap-x-2 mb-3">
                      <Typography className="font-bold" variant="h4">Notifications</Typography>

                      {
                        notificationsCTX.unreadNotificationsCount
                          ? (
                            <Chip
                              color="primary"
                              clickable={false}
                              style={{ height: 24 }}
                              label={
                                <Typography variant="mini" color="white">{notificationsCTX.unreadNotificationsCount}</Typography>
                              }
                            />
                          )
                          : null
                      }
                    </div>
                  )
                  : null
              }

              <div className="flex gap-x-2">
                <Button
                  className="rounded-3xl px-8"
                  size="small"
                  color="primary"
                  variant={notificationsCTX.showUnread ? 'outlined' : 'contained'}
                  onClick={() => notificationsCTX.setShowUnread(false)}
                >
                All
                </Button>
                <Button
                  className="rounded-3xl px-8"
                  size="small"
                  color="primary"
                  variant={notificationsCTX.showUnread ? 'contained' : 'outlined'}
                  onClick={() => notificationsCTX.setShowUnread(true)}
                >
                Unread {`${notificationsCTX.unreadNotificationsCount && !isDropdownLayout ? `(${notificationsCTX.unreadNotificationsCount})` : ''}`}
                </Button>
              </div>
            </div>

            {
              showCategoryFilter
                ? (
                  <div>
                    <MultiSelectDropDown
                      dropdownId="notifications-category-select"
                      className="w-44"
                      disableHelperText
                      disableMultiSelect
                      onChange={handleFilterSelect}
                      value={NOTIFICATIOINS_FILTER_OPTIONS.filter(op => op.value === notificationsCTX.category)}
                      label="Filter Notification"
                      options={NOTIFICATIOINS_FILTER_OPTIONS}
                    />
                  </div>
                )
                : null
            }
          </div>

          <div>
            <div className="flex gap-x-2 items-center">
              <div>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => notificationsCTX.markAsRead({ all: true })}
                >
                  <Icon icon="DoneAll" className="mr-1" fontSize="small" />
                Mark all as read
                </Button>
              </div>
              {
                showNotificationSearch
                  ? (
                    <div>
                      <SearchInput
                        value={notificationsCTX.search}
                        onSearch={notificationsCTX.setSearch}
                      />
                    </div>
                  )
                  : null
              }

              {
                userPermissions.canViewAccountSettings()
                  ? (
                    <div>
                      <IconButton onClick={goToAccountSettings}>
                        <Icon icon="Settings" color='primary' />
                      </IconButton>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </div>

        <LoadingAndErrorHandler
          isError={notificationsCTX.isError}
          isLoading={notificationsCTX.isLoading}
          isSuccess={notificationsCTX.isSuccess}
        >
          <BottomScrollListener onBottom={() => !notificationsCTX.isFetching && notificationsCTX.fetchNextPage()} offset={50}>
            {(ref) => (
              <div ref={ref} style={{ maxHeight: isDropdownLayout ? '55vh' : 'calc(100vh - 335px)', overflow: 'scroll' }} data-testid="notification-container">
                {notificationsCTX.notifications.map(
                  (notification) => (
                    <NotificationTile
                      key={notification.id}
                      notification={getNotificationLink(notification)}
                      markAsRead={notificationsCTX.markAsRead}
                      archiveNotification={notificationsCTX.archiveNotification}
                      handleClose={handleClose}
                      isDropdownLayout={isDropdownLayout}
                    />
                  )
                )}
                {
                  !notificationsCTX.notifications.length
                    ? (
                      <EmptyNotificationsInfo />
                    )
                    : null
                }
                {
                  notificationsCTX.isFetchingNextPage
                    ? (
                      <div className='nucleos-core'>
                        <LoadingSpinner size={8} className='mx-auto' />
                      </div>
                    )
                    : null
                }
              </div>
            )}
          </BottomScrollListener>
        </LoadingAndErrorHandler>
      </div>
    </Box>
  );
};

export const NotificationsLayout = (props) => (
  <NotificationsContextProvider>
    <_NotificationsLayout {...props} />
  </NotificationsContextProvider>
);
