import React from 'react';
import { PLAYER_PLAYBACK_ERRORS } from '../../../Lib/CONSTANTS';

export const PlaybackErrorOverlay = ({
  children,
  playbackError
}) => {
  return (
    <div className="playback-error-overlay-container">
      <div>
        {children}
      </div>
      {
        playbackError
          ? (
            <div className='playback-error-overlay'>
              {
                playbackError === PLAYER_PLAYBACK_ERRORS.NO_DATA_FOUND
                  ? (
                    <span>We could not find any data for the date you selected.</span>
                  )
                  : null
              }
            </div>
          )
          : null
      }
    </div>);
};
