import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Box, Button, Grid, IconButton, Radio, Typography } from '@mui/material';
import { MultiSelectDropDown } from '@nucleos/core-ui';
import { Icon } from '../../../Components/Shared/Icon';
import { useAvailabilityAndDailyLimitCardContext } from './AvailabilityAndDailyLimitCardContext';
import { timeStringToMinutes } from '../../../Lib/util';
import * as _ from 'lodash-es';

const EMPTY_TIME_PERIOD_BLOCK = {
  startTime: null,
  endTime: null
};

const EMPTY_APP_AVAILABILITY_BLOCK = {
  allowedDays: [],
  allowedTime: [EMPTY_TIME_PERIOD_BLOCK]
};

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const DAYS_OPTIONS = DAYS.map((day) => ({ title: day, value: day }));

const TIME_OPTIONS = new Array(24).fill(null).map((_, idx) => {
  return [{
    title: `${idx}:00`.padStart(5, '0'),
    value: `${idx}:00`.padStart(5, '0')
  }, {
    title: `${idx}:30`.padStart(5, '0'),
    value: `${idx}:30`.padStart(5, '0')
  }];
}).flat();
TIME_OPTIONS.push({
  title: '24:00'.padStart(5, '0'),
  value: '24:00'.padStart(5, '0')
});

const HOURS_OPTIONS = new Array(24).fill(null).map((_, idx) => ({
  title: `${idx} Hour${idx > 1 ? 's' : ''}`,
  value: idx
}));

const MINUTES_OPTIONS = new Array(12).fill(null).map((_, idx) => {
  const minutesValue = idx * 5;

  return ({
    title: `${minutesValue} Min${minutesValue > 1 ? 's' : ''}`,
    value: minutesValue
  });
});

function getDailyLimitState (dailyLimit) {
  let state = {
    isUnlimited: true,
    restricted: false,
    hours: 0,
    minutes: 0
  };

  if (dailyLimit) {
    if (dailyLimit.minutesAllowed) {
      state = {
        isUnlimited: false,
        restricted: true,
        hours: Math.trunc(dailyLimit.minutesAllowed / 60),
        minutes: Math.trunc(dailyLimit.minutesAllowed % 60)
      };
    }
  }

  return state;
}

function DailyLimit ({ id, isDisabled, showBorder, dailyLimit, onChange }) {
  const context = useAvailabilityAndDailyLimitCardContext();

  const defaultValue = useRef(dailyLimit);

  useEffect(() => {
    defaultValue.current = dailyLimit;
  }, [dailyLimit]);

  const [state, setState] = useState(() => getDailyLimitState(dailyLimit));

  const handleChange = useCallback((fieldName, value) => {
    setState((state) => {
      const updatedState = {
        ...state
      };

      context.setDailyLimitError(null);

      if (fieldName === 'isUnlimited') {
        if (value) {
          updatedState.isUnlimited = value;
          updatedState.restricted = false;
          updatedState.hours = 0;
          updatedState.minutes = 0;
        }
      } else if (fieldName === 'restricted') {
        if (value) {
          updatedState.isUnlimited = false;
          updatedState.restricted = value;
          updatedState.hours = defaultValue.current && defaultValue.current.minutesAllowed
            ? Math.trunc(defaultValue.current.minutesAllowed / 60)
            : 0;
          updatedState.minutes = defaultValue.current && defaultValue.current.minutesAllowed
            ? Math.trunc(defaultValue.current.minutesAllowed % 60)
            : 0;
        }
      } else if (fieldName === 'hours') {
        updatedState.hours = value;
      } else if (fieldName === 'minutes') {
        updatedState.minutes = value;
      }

      const updatedDailyLimit = {
        minutesAllowed: null
      };
      if (updatedState.restricted) {
        updatedDailyLimit.minutesAllowed = updatedState.hours * 60 + updatedState.minutes;

        if (!updatedDailyLimit.minutesAllowed) {
          context.setDailyLimitError('You need to set either hours or minutes to update the daily limit.');
        }
      }

      onChange(updatedDailyLimit);

      return updatedState;
    });
  }, [context, onChange]);

  return (
    <Box sx={{ p: 2, border: showBorder ? 1 : 'none', borderRadius: 1, borderColor: '#ebebeb', height: '100%' }}>
      <Box>
        <Typography fontWeight={700}>Daily Limit</Typography>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={isDisabled}
              checked={state.isUnlimited}
              color='secondary'
              size='small'
              onChange={(e, checked) => handleChange('isUnlimited', checked)}
            />
            <Typography>Unlimited</Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={isDisabled}
              checked={state.restricted}
              color='secondary'
              size='small'
              onChange={(e, checked) => handleChange('restricted', checked)}
            />
            <Typography>Limited to</Typography>
          </Box>

          {
            !(state.isUnlimited && isDisabled)
              ? (
                <>
                  <Box sx={{ minWidth: 100 }}>
                    <MultiSelectDropDown
                      dropdownId={`${id}-hours-select`}
                      fullWidth
                      disableHelperText
                      disableMultiSelect
                      label="Hours"
                      options={HOURS_OPTIONS}
                      value={HOURS_OPTIONS.filter((option) => option.value === state.hours)}
                      onChange={([{ value }]) => handleChange('hours', value)}
                      disabled={state.isUnlimited || isDisabled}
                    />
                  </Box>
                  <Typography>:</Typography>
                  <Box sx={{ minWidth: 100 }}>
                    <MultiSelectDropDown
                      dropdownId={`${id}-minutes-select`}
                      fullWidth
                      disableHelperText
                      disableMultiSelect
                      label="Minutes"
                      options={MINUTES_OPTIONS}
                      value={MINUTES_OPTIONS.filter((option) => option.value === state.minutes)}
                      onChange={([{ value }]) => handleChange('minutes', value)}
                      disabled={state.isUnlimited || isDisabled}
                    />
                  </Box>
                </>
              )
              : null
          }
        </Box>
      </Box>

      {
        context.dailyLimitError
          ? (
            <Box sx={{ mt: 2 }}>
              <Typography color='error' variant='body2'>{context.dailyLimitError}</Typography>
            </Box>
          )
          : null
      }
    </Box>
  );
}

function getTimePeriodState (timePeriod) {
  let state = EMPTY_TIME_PERIOD_BLOCK;

  if (timePeriod) {
    state = {
      startTime: timePeriod.startTime,
      endTime: timePeriod.endTime
    };
  }

  return state;
}

function TimePeriod ({ id, index, isDisabled, timePeriod, onChange, addTimePeriod, removeTimePeriod }) {
  const [state, setState] = useState(() => getTimePeriodState(timePeriod));

  useEffect(() => {
    setState(getTimePeriodState(timePeriod));
  }, [timePeriod]);

  const handleChange = useCallback((fieldName, value) => {
    setState((state) => {
      const updatedState = {
        ...state
      };

      if (fieldName === 'startTime') {
        updatedState.startTime = value;
      } else if (fieldName === 'endTime') {
        updatedState.endTime = value;
      }

      onChange(`${index}::timePeriod`, updatedState);

      return updatedState;
    });
  }, [index, onChange]);

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Box sx={{ minWidth: 100 }}>
        <MultiSelectDropDown
          dropdownId={`${id}-start-time-select`}
          fullWidth
          disableHelperText
          disableMultiSelect
          label="Start Time"
          options={TIME_OPTIONS}
          value={TIME_OPTIONS.filter((option) => option.value === state.startTime)}
          onChange={([{ value }]) => handleChange('startTime', value)}
          disabled={isDisabled}
        />
      </Box>

      <Box sx={{ minWidth: 100 }}>
        <MultiSelectDropDown
          dropdownId={`${id}-end-time-select`}
          fullWidth
          disableHelperText
          disableMultiSelect
          label="End Time"
          options={TIME_OPTIONS}
          value={TIME_OPTIONS.filter((option) => option.value === state.endTime)}
          onChange={([{ value }]) => handleChange('endTime', value)}
          disabled={isDisabled}
        />
      </Box>

      <Box>
        {
          index
            ? (
              <IconButton disabled={isDisabled} onClick={removeTimePeriod}>
                <Icon icon='DeleteOutlined' />
              </IconButton>
            )
            : (
              <IconButton disabled={isDisabled} onClick={addTimePeriod}>
                <Icon icon='Add' sx={(theme) => ({ color: theme.palette.primary.main })} />
              </IconButton>
            )
        }
      </Box>
    </Box>
  );
}

function getTimeBlockState (timeBlock) {
  let state = {
    allowedDays: [],
    allowedTime: [EMPTY_TIME_PERIOD_BLOCK]
  };

  if (timeBlock) {
    state = {
      allowedDays: timeBlock.allowedDays,
      allowedTime: timeBlock.allowedTime
    };
  }

  return state;
}

function TimeBlock ({ id, index, isDisabled, timeBlock, usedDaysOptions, onChange, removeTimeBlock }) {
  const [state, setState] = useState(() => getTimeBlockState(timeBlock));

  useEffect(() => {
    setState(getTimeBlockState(timeBlock));
  }, [timeBlock]);

  const handleChange = useCallback((fieldName, value) => {
    setState((state) => {
      const updatedState = {
        ...state
      };

      if (fieldName === 'allowedDays') {
        updatedState.allowedDays = value;
      } else if (fieldName.includes('timePeriod')) {
        const [index] = fieldName.split('::');

        updatedState.allowedTime = state.allowedTime.map((timePeriod, idx) => {
          if (idx === +index) {
            return value;
          }

          return timePeriod;
        });
      }

      onChange(`${index}::timeBlock`, updatedState);

      return updatedState;
    });
  }, [index, onChange]);

  const addTimePeriod = useCallback(() => {
    setState((state) => {
      const updatedState = {
        ...state
      };

      updatedState.allowedTime = [
        ...updatedState.allowedTime,
        EMPTY_TIME_PERIOD_BLOCK
      ];

      onChange(`${index}::timeBlock`, updatedState);

      return updatedState;
    });
  }, [index, onChange]);

  const removeTimePeriod = useCallback((idx) => {
    setState((state) => {
      const updatedAllowedTime = [...state.allowedTime];
      updatedAllowedTime.splice(idx, 1);

      const updatedState = {
        ...state,
        allowedTime: updatedAllowedTime
      };

      onChange(`${index}::timeBlock`, updatedState);

      return updatedState;
    });
  }, [index, onChange]);

  return (
    <Box>
      <Badge
        color='error'
        invisible={!index}
        badgeContent={
          <IconButton size='small' sx={{ p: 0 }} disabled={isDisabled} onClick={removeTimeBlock}>
            <Icon icon='DeleteOutlined' sx={(theme) => ({ fontSize: 16, color: theme.palette.white.main })} />
          </IconButton>
        }
        sx={{ width: '100%' }}
      >
        <Box
          sx={(theme) => ({
            flex: 1,
            display: 'flex',
            backgroundColor: `${theme.palette.secondary.main}10`,
            borderRadius: 2,
            p: 1,
            gap: 2
          })}
        >
          <Box sx={{ width: 200 }}>
            <MultiSelectDropDown
              dropdownId={`${id}-days-select`}
              fullWidth
              disableHelperText
              label="Select Days"
              options={DAYS_OPTIONS.filter((option) => !usedDaysOptions.includes(option.value) || state.allowedDays.includes(option.value))}
              value={DAYS_OPTIONS.filter((option) => state.allowedDays.includes(option.value))}
              onChange={(selectedOptions) => handleChange('allowedDays', selectedOptions.map((option) => option.value))}
              getLabel={(values) => values.map((value) => value.slice(0, 3)).join(', ')}
              disabled={isDisabled}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {
              state.allowedTime.map((timePeriod, index) => (
                <TimePeriod
                  id={`${id}-time-period-${index}`}
                  key={index}
                  index={index}
                  isDisabled={isDisabled}
                  timePeriod={timePeriod}
                  onChange={handleChange}
                  addTimePeriod={addTimePeriod}
                  removeTimePeriod={() => removeTimePeriod(index)}
                />
              ))
            }
          </Box>
        </Box>
      </Badge>
    </Box>
  );
}

function getAppAvailabilityState (appAvailability) {
  let state = {
    isUnlimited: true,
    restricted: false,
    appAvailability: [EMPTY_APP_AVAILABILITY_BLOCK]
  };

  if (Array.isArray(appAvailability) && appAvailability.length) {
    state = {
      isUnlimited: false,
      restricted: true,
      appAvailability: appAvailability
    };
  }

  return state;
}

function getAppAvailabilityUsedDaysOptionsState (appAvailability) {
  const state = [];

  if (Array.isArray(appAvailability) && appAvailability.length) {
    const allUsedDaysOptions = [];

    appAvailability.forEach((timeBlock) => {
      allUsedDaysOptions.push(...timeBlock.allowedDays);
    });

    state.push(...new Set(allUsedDaysOptions));
  }

  return state;
}

function AppAvailability ({
  id,
  isDisabled,
  isEditingTimeBlocks,
  disablePadding,
  showBorder,
  appAvailability,
  onChange,
  setIsEditingTimeBlocks
}) {
  const context = useAvailabilityAndDailyLimitCardContext();

  const defaultValue = useRef(appAvailability);

  useEffect(() => {
    defaultValue.current = appAvailability;
  }, [appAvailability]);

  const [state, setState] = useState(() => getAppAvailabilityState(appAvailability));
  const [usedDaysOptions, setUsedDaysOptions] = useState(() => getAppAvailabilityUsedDaysOptionsState(appAvailability));

  const validate = useCallback((appAvailability) => {
    context.setAvailabilityError(null);

    const allowedDaysEmptyError = appAvailability.some((availability) => !availability.allowedDays.length);

    const startTimeEmptyError = appAvailability.some((availability) => {
      return !!availability.allowedTime.filter((timePeriod) => !timePeriod.startTime).length;
    });

    const endTimeEmptyError = appAvailability.some((availability) => {
      return !!availability.allowedTime.filter((timePeriod) => !timePeriod.endTime).length;
    });

    const endTimeLessThanStartTimeError = appAvailability.some((availability) => {
      return availability.allowedTime.some((timePeriod) => {
        if (timePeriod.startTime && timePeriod.endTime) {
          return timeStringToMinutes(timePeriod.endTime) <= timeStringToMinutes(timePeriod.startTime);
        }

        return true;
      });
    });

    const overlappingTimePeriodError = appAvailability.some((availability) => {
      return availability.allowedTime.some((t1, i1) => {
        return availability.allowedTime.some((t2, i2) => {
          if (i1 === i2) {
            return false;
          }

          if (t1.startTime && t1.endTime && t2.startTime && t2.endTime) {
            const t1Start = timeStringToMinutes(t1.startTime);
            const t1End = timeStringToMinutes(t1.endTime);
            const t2Start = timeStringToMinutes(t2.startTime);

            let isOverlapping = false;
            if (t2Start >= t1Start && t2Start < t1End) {
              isOverlapping = true;
            }

            return isOverlapping;
          }

          return true;
        });
      });
    });

    if (allowedDaysEmptyError) {
      context.setAvailabilityError('Days cannot be empty.');
    } else if (startTimeEmptyError) {
      context.setAvailabilityError('Start time cannot be empty.');
    } else if (endTimeEmptyError) {
      context.setAvailabilityError('End time cannot be empty.');
    } else if (endTimeLessThanStartTimeError) {
      context.setAvailabilityError('End time must be greater than Start time.');
    } else if (overlappingTimePeriodError) {
      context.setAvailabilityError('Time slots\' start and end time must not clash with each other.');
    }
  }, [context]);

  const handleChange = useCallback((fieldName, value) => {
    setState((state) => {
      const updatedState = {
        ...state
      };

      context.setAvailabilityError(null);

      if (fieldName === 'isUnlimited') {
        if (value) {
          updatedState.isUnlimited = value;
          updatedState.restricted = false;
          updatedState.appAvailability = [EMPTY_APP_AVAILABILITY_BLOCK];
        }
      } else if (fieldName === 'restricted') {
        if (value) {
          updatedState.isUnlimited = false;
          updatedState.restricted = value;
          updatedState.appAvailability = Array.isArray(defaultValue.current)
            ? defaultValue.current
            : [EMPTY_APP_AVAILABILITY_BLOCK];
        }
      } else if (fieldName.includes('timeBlock')) {
        const [index] = fieldName.split('::');

        updatedState.appAvailability = state.appAvailability.map((timeBlock, idx) => {
          if (idx === +index) {
            return value;
          }

          return timeBlock;
        });
      }

      const allUsedDaysOptions = [];
      updatedState.appAvailability.forEach((timeBlock) => {
        allUsedDaysOptions.push(...timeBlock.allowedDays);
      });
      setUsedDaysOptions([...new Set(allUsedDaysOptions)]);

      let updatedAppAvailability = null;
      if (updatedState.restricted) {
        updatedAppAvailability = [
          ...updatedState.appAvailability
        ];

        validate(updatedAppAvailability);
      }

      onChange(updatedAppAvailability);

      return updatedState;
    });
  }, [context, validate, onChange]);

  const addTimeBlock = useCallback(() => {
    setState((state) => {
      const updatedAppAvailability = [
        ...state.appAvailability,
        EMPTY_APP_AVAILABILITY_BLOCK
      ];

      validate(updatedAppAvailability);

      onChange(updatedAppAvailability);

      return ({
        ...state,
        appAvailability: updatedAppAvailability
      });
    });
  }, [onChange, validate]);

  const removeTimeBlock = useCallback((index) => {
    setState((state) => {
      const updatedAppAvailability = [...state.appAvailability];
      updatedAppAvailability.splice(index, 1);

      validate(updatedAppAvailability);

      const allUsedDaysOptions = [];
      updatedAppAvailability.forEach((timeBlock) => {
        allUsedDaysOptions.push(...timeBlock.allowedDays);
      });

      setUsedDaysOptions([...new Set(allUsedDaysOptions)]);

      onChange(updatedAppAvailability);

      return {
        ...state,
        appAvailability: updatedAppAvailability
      };
    });
  }, [onChange, validate]);

  return (
    <Box sx={{ p: 2, pl: disablePadding ? 0 : 2, border: showBorder ? 1 : 'none', borderRadius: 1, borderColor: '#ebebeb' }}>
      <Box>
        <Typography fontWeight={700}>Availability</Typography>
      </Box>

      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={isDisabled}
              checked={state.isUnlimited}
              color='secondary'
              size='small'
              onChange={(e, checked) => handleChange('isUnlimited', checked)}
            />
            <Typography>Unlimited</Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              disabled={isDisabled}
              checked={state.restricted}
              color='secondary'
              size='small'
              onChange={(e, checked) => handleChange('restricted', checked)}
            />
            <Typography>Limited to</Typography>
          </Box>
        </Box>

        {
          (isEditingTimeBlocks || !(Array.isArray(appAvailability) && !state.isUnlimited)) && !isDisabled
            ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {
                    state.appAvailability.map((timeBlock, index) => (
                      <TimeBlock
                        id={`${id}-time-block-${index}`}
                        key={index}
                        index={index}
                        isDisabled={state.isUnlimited || isDisabled}
                        timeBlock={timeBlock}
                        usedDaysOptions={usedDaysOptions}
                        onChange={handleChange}
                        removeTimeBlock={() => removeTimeBlock(index)}
                      />
                    ))
                  }
                </Box>

                {
                  !(state.isUnlimited && isDisabled) && state.appAvailability.length < DAYS.length
                    ? (
                      <Box>
                        <Button
                          color='secondary'
                          size='small'
                          sx={{ fontWeight: 700, py: 0 }}
                          onClick={addTimeBlock}
                          disabled={state.isUnlimited || !!context.availabilityError}
                        >
                          Add Time Block
                        </Button>
                      </Box>
                    )
                    : null
                }
              </Box>
            )
            : (
              <>
                {
                  Array.isArray(appAvailability) && appAvailability.length
                    ? (
                      <Box
                        sx={(theme) => ({
                          display: 'flex',
                          justifyContent: 'space-between',
                          backgroundColor: `${theme.palette.secondary.main}10`,
                          borderRadius: 2,
                          p: 1
                        })}
                      >
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {
                            appAvailability.map((availability) => (
                              <Box
                                key={availability.allowedDays.join(', ')}
                                sx={{ display: 'flex', gap: 2 }}
                              >
                                <Box sx={{ width: 120 }}>
                                  <Typography variant='body2'>
                                    {
                                      availability.allowedDays.map((day) => day.slice(0, 3)).join(', ')
                                    }
                                  </Typography>
                                </Box>

                                <Box>
                                  {
                                    availability.allowedTime.map((allowedTime) => (
                                      <Box
                                        key={`${allowedTime.startTime}-${allowedTime.endTime}`}
                                        sx={{ display: 'flex', gap: 0.5 }}
                                      >
                                        <Typography variant='body2'>
                                          {
                                            `${String(allowedTime.startTime).padStart(5, '0')}`
                                          }
                                        </Typography>
                                        <Typography variant='body2'>
                                          -
                                        </Typography>
                                        <Typography variant='body2'>
                                          {
                                            `${String(allowedTime.endTime).padStart(5, '0')}`
                                          }
                                        </Typography>
                                      </Box>
                                    ))
                                  }
                                </Box>
                              </Box>
                            ))
                          }
                        </Box>

                        {
                          !isDisabled
                            ? (
                              <Box>
                                <Button
                                  color='secondary'
                                  size='small'
                                  sx={{ fontWeight: 700, py: 0 }}
                                  onClick={() => setIsEditingTimeBlocks(true)}
                                >
                                  Edit Time Blocks
                                </Button>
                              </Box>
                            )
                            : null
                        }
                      </Box>
                    )
                    : null
                }
              </>
            )
        }
      </Box>

      {
        context.availabilityError
          ? (
            <Box sx={{ mt: 2 }}>
              <Typography color='error' variant='body2'>{context.availabilityError}</Typography>
            </Box>
          )
          : null
      }
    </Box>
  );
}

function getAvailabilityAndDailyLimitCardState (availabilityDetails) {
  const state = {
    appAvailability: null,
    dailyLimit: null
  };

  if (availabilityDetails.appAvailability) {
    state.appAvailability = availabilityDetails.appAvailability;
  }

  if (availabilityDetails.dailyLimit) {
    state.dailyLimit = availabilityDetails.dailyLimit;
  }

  return state;
}

export default function AvailabilityAndDailyLimitCard ({ id, isDisabled, facilityId, podId, availabilityDetails, onUpdate, onChange }) {
  const context = useAvailabilityAndDailyLimitCardContext();

  const showBorder = !facilityId && !podId;
  const disablePadding = !!facilityId || !!podId;

  const [isEditingTimeBlocks, setIsEditingTimeBlocks] = useState(false);
  const [availability, setAvailability] = useState(() => getAvailabilityAndDailyLimitCardState(availabilityDetails));

  const handleSaveChanges = useCallback(async () => {
    await onUpdate({
      facilityId,
      podId,
      availabilityDetails: {
        availabilityJson: availability
      }
    });

    setIsEditingTimeBlocks(false);
  }, [facilityId, podId, availability, onUpdate]);

  const handleDailyLimitChange = useCallback((dailyLimit) => {
    setAvailability((availability) => ({
      ...availability,
      dailyLimit
    }));
  }, []);

  const handleAppAvailabilityChange = useCallback((appAvailability) => {
    setAvailability((availability) => ({
      ...availability,
      appAvailability
    }));
  }, []);

  useEffect(() => {
    onChange(availability);
  }, [availability, onChange]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <AppAvailability
            id={`${id}-app-availability`}
            isDisabled={isDisabled}
            isEditingTimeBlocks={isEditingTimeBlocks}
            showBorder={showBorder}
            disablePadding={disablePadding}
            appAvailability={availabilityDetails.appAvailability}
            onChange={handleAppAvailabilityChange}
            setIsEditingTimeBlocks={setIsEditingTimeBlocks}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <DailyLimit
            id={`${id}-daily-limit`}
            isDisabled={isDisabled}
            showBorder={showBorder}
            dailyLimit={availabilityDetails.dailyLimit}
            onChange={handleDailyLimitChange}
          />
        </Grid>
      </Grid>

      {
        !isDisabled
          ? (
            <Box sx={{ mt: 2 }}>
              <Button
                variant='contained'
                onClick={handleSaveChanges}
                disabled={
                  !!(context.availabilityError || context.dailyLimitError) ||
                  _.isEqual(availabilityDetails, availability)
                }
              >
                Save Changes
              </Button>
            </Box>
          )
          : null
      }
    </Box>
  );
}
