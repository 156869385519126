import { useQuery } from 'react-query';
import Data from '../Middleware/Data';
import AuthenticationStore from '../Stores/Authentication';
import { useCategoriesQuery } from './useCategories';
import { ACTIVE_ROLES, ROLES } from '../Lib/CONSTANTS';
import { useFacilitiesQuery } from './useFacilities';

const Permissions = {
  ALL: 'ALL',
  BY_FACILITY: 'BY_FACILITY',
  BY_CATEGORY: 'BY_CATEGORY',
  NONE: 'NONE'
};

const PermissionRestrictionLevel = {
  [Permissions.ALL]: 1,
  [Permissions.BY_FACILITY]: 2,
  [Permissions.BY_CATEGORY]: 3,
  [Permissions.NONE]: 4
};

export const useGrievanceRole = () => {
  const { isAuthenticated, userRole, fullUserRole } = AuthenticationStore;
  const shortRole = userRole;
  const fullRole = fullUserRole;
  const isLearner = isAuthenticated && shortRole === 'learner';
  const isAdmin = isAuthenticated && shortRole === 'admin';
  const isSuperAdmin = fullRole === ROLES.SUPER_ADMIN;
  const isStaffAdmin = fullRole === 'admin.staff';
  const isInvestigator = fullRole === ACTIVE_ROLES.INVESTIGATOR;

  const grievanceRoleQuery = useQuery(
    'GRIEVANCE_PERMISSIONS',
    () => Data.getGrievanceRole(AuthenticationStore.uid),
    {
      enabled: isAuthenticated && isLearner,
      retry: false,
      refetchOnMount: false
    }
  );

  const learnerConfigurationQuery = useQuery(
    ['CONFIGURATIONS', 'LEARNER', isAuthenticated],
    () => Data.getExtensionConfigurations(),
    {
      refetchOnMount: false,
      enabled: isLearner
    }
  );
  const adminConfigurationQuery = useQuery(
    ['CONFIGURATIONS', 'ADMIN', isAuthenticated],
    () => Data.getConfigurations(),
    {
      refetchOnMount: false,
      enabled: isAdmin
    }
  );
  const facilitiesQuery = useFacilitiesQuery();

  const categoriesQuery = useCategoriesQuery();

  const grievanceRoleData = isAdmin
    ? adminConfigurationQuery.data && adminConfigurationQuery.data.userPayload
    : grievanceRoleQuery.data;
  const grievanceRoleStatus = isAdmin
    ? adminConfigurationQuery.status
    : grievanceRoleQuery.status;

  const getPermissionWithLeastRestrictions = (
    permissionType,
    permissions = grievanceRoleData.configRole.permissions
  ) => {
    return permissions.reduce((accPermission, permission) => {
      if (!accPermission) { return permission; }
      return PermissionRestrictionLevel[
        accPermission.permission[permissionType]
      ] > PermissionRestrictionLevel[permission.permission[permissionType]]
        ? permission
        : accPermission;
    }, null);
  };

  const isLoading =
    grievanceRoleStatus !== 'success' ||
    (isLearner && learnerConfigurationQuery.status !== 'success') ||
    (isAdmin && adminConfigurationQuery.status !== 'success');

  return {
    get isLoading () {
      return isLoading;
    },
    /**
     * This is only for admins.
     */
    getViewFacilities: ({ gLevel } = {}) => {
      if (
        grievanceRoleStatus !== 'success' ||
        facilitiesQuery.status !== 'success'
      ) { return []; }
      if (grievanceRoleData.useLegacyRole) { return facilitiesQuery.data; }

      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (levelPermission.permission.VIEW === Permissions.NONE) { return []; }
      if (levelPermission.permission.VIEW === Permissions.ALL) { return facilitiesQuery.data; }
      if (levelPermission.permission.VIEW === Permissions.BY_FACILITY) {
        return facilitiesQuery.data.filter((facility) =>
          grievanceRoleData.facilities.some(
            (roleFacility) => roleFacility === facility.id
          )
        );
      }
      return facilitiesQuery.data.filter((facility) =>
        grievanceRoleData.facilities.some(
          (roleFacility) => roleFacility === facility.id
        )
      );
    },
    /**
     * Only for admin
     */
    getViewCategories: ({ gLevel } = {}) => {
      if (
        grievanceRoleStatus !== 'success' ||
        categoriesQuery.status !== 'success'
      ) { return []; }
      if (grievanceRoleData.useLegacyRole) { return categoriesQuery.data; }
      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (levelPermission.permission.VIEW === Permissions.NONE) { return []; }
      if (levelPermission.permission.VIEW === Permissions.ALL) { return categoriesQuery.data; }
      if (levelPermission.permission.VIEW === Permissions.BY_CATEGORY) {
        return categoriesQuery.data.filter((category) =>
          grievanceRoleData.configRole.categories.some(
            (roleCategory) => roleCategory === category.id
          )
        );
      }
      return categoriesQuery.data;
    },
    /**
     * Only for learner
     */
    getCreateCategories: () => {
      if (
        grievanceRoleStatus !== 'success' ||
        categoriesQuery.status !== 'success'
      ) { return []; }
      if (grievanceRoleData.useLegacyRole) { return categoriesQuery.data; }
      const levelPermission = grievanceRoleData.configRole.permissions[0];
      if (levelPermission.permission.CREATE === Permissions.NONE) { return []; }
      if (levelPermission.permission.CREATE === Permissions.ALL) { return categoriesQuery.data; }
      if (levelPermission.permission.CREATE === Permissions.BY_CATEGORY) {
        return categoriesQuery.data.filter((category) =>
          grievanceRoleData.configRole.categories.some(
            (roleCategory) => roleCategory === category.id
          )
        );
      }
      return categoriesQuery.data;
    },
    canGoToGrievances: () => {
      if (isLoading) { return null; }
      if (!window.nucleosConfig.formsEnabled) { return false; }
      if (
        isLearner &&
        learnerConfigurationQuery.data.isGrievanceFeatureEnabled !== true
      ) { return false; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      return !grievanceRoleData.configRole.permissions.every(
        (permission) =>
          permission.isEnabled === false ||
          permission.permission.VIEW === Permissions.NONE
      );
    },
    canGoToInvestigativeTools: () => {
      if (isLoading) { return null; }
      if (
        (isStaffAdmin || isSuperAdmin || isInvestigator) &&
        adminConfigurationQuery.data.liveActivityConfig.isEnabled
      ) { return true; }
      return false;
    },
    isGrievanceEnabled: () => {
      if (isLoading) { return null; }
      if (!window.nucleosConfig.formsEnabled) { return false; }
      if (
        isLearner &&
        learnerConfigurationQuery.data.isGrievanceFeatureEnabled !== true
      ) { return false; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      return grievanceRoleData.configRole.isGrievanceEnabled;
    },
    canViewGrievance: ({ gLevel, gCategoryId, gFacilityId } = {}) => {
      if (isLoading) { return null; }
      if (!window.nucleosConfig.formsEnabled) { return false; }
      if (
        isLearner &&
        learnerConfigurationQuery.data.isGrievanceFeatureEnabled !== true
      ) { return false; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      if (grievanceRoleData.configRole.isLearner) {
        const levelPermission = grievanceRoleData.configRole.permissions[0];
        if (levelPermission.permission.VIEW === Permissions.ALL) { return true; }
        if (levelPermission.permission.VIEW === Permissions.BY_CATEGORY) {
          return true;
        }
        return false;
      }
      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (!levelPermission.isEnabled) { return false; }
      if (!levelPermission.permission.VIEW === Permissions.NONE) { return false; }
      if (levelPermission.permission.VIEW === Permissions.ALL) { return true; }
      if (levelPermission.permission.VIEW === Permissions.BY_FACILITY) {
        return grievanceRoleData.facilities.some(
          (facility) => facility === gFacilityId
        );
      }
      if (!grievanceRoleData.facilities.includes(gFacilityId)) { return false; }
      if (levelPermission.permission.VIEW === Permissions.BY_CATEGORY) {
        return grievanceRoleData.configRole.categories.some(
          (category) => category === gCategoryId
        );
      }
      return false;
    },
    /**
     * Only for learner
     */
    canCreateGrievance: () => {
      if (grievanceRoleStatus !== 'success') { return null; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      const levelPermission = grievanceRoleData.configRole.permissions[0];
      if (!levelPermission.isEnabled) { return false; }
      if (!levelPermission.permission.CREATE === Permissions.NONE) { return false; }
      if (levelPermission.permission.CREATE === Permissions.ALL) { return true; }
      if (levelPermission.permission.CREATE === Permissions.BY_CATEGORY) {
        return true;
      }
      return false;
    },
    /**
     * This is only for admins.
     */
    canEditGrievance: ({ gLevel, gCategoryId, gFacilityId } = {}) => {
      if (grievanceRoleStatus !== 'success') { return null; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (!levelPermission.isEnabled) { return false; }
      if (!levelPermission.permission.RESPOND === Permissions.NONE) { return false; }
      if (levelPermission.permission.RESPOND === Permissions.ALL) { return true; }
      if (levelPermission.permission.RESPOND === Permissions.BY_FACILITY) {
        return grievanceRoleData.facilities.some(
          (facility) => facility === gFacilityId
        );
      }
      if (!grievanceRoleData.facilities.includes(gFacilityId)) { return false; }
      if (levelPermission.permission.RESPOND === Permissions.BY_CATEGORY) {
        return grievanceRoleData.configRole.categories.some(
          (category) => category === gCategoryId
        );
      }
      return false;
    },
    /**
     * This is only for admins.
     */
    canAssignGrievance: ({ gLevel, gCategoryId, gFacilityId } = {}) => {
      if (grievanceRoleStatus !== 'success') { return null; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (!levelPermission.isEnabled) { return false; }
      if (!levelPermission.permission.ASSIGN === Permissions.NONE) { return false; }
      if (levelPermission.permission.ASSIGN === Permissions.ALL) { return true; }
      if (levelPermission.permission.ASSIGN === Permissions.BY_FACILITY) {
        return grievanceRoleData.facilities.some(
          (facility) => facility === gFacilityId
        );
      }
      if (!grievanceRoleData.facilities.includes(gFacilityId)) { return false; }
      if (levelPermission.permission.ASSIGN === Permissions.BY_CATEGORY) {
        return grievanceRoleData.configRole.categories.some(
          (category) => category === gCategoryId
        );
      }
      return false;
    },
    /**
     * This is only for admins.
     */
    canDuplicateAndReclassifyGrievance: ({
      gLevel,
      gCategoryId,
      gFacilityId
    } = {}) => {
      if (grievanceRoleStatus !== 'success') { return null; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (!levelPermission.isEnabled) { return false; }
      if (!levelPermission.permission.DUPLICATE === Permissions.NONE) { return false; }
      if (levelPermission.permission.DUPLICATE === Permissions.ALL) { return true; }
      if (levelPermission.permission.DUPLICATE === Permissions.BY_FACILITY) {
        return grievanceRoleData.facilities.some(
          (facility) => facility === gFacilityId
        );
      }
      if (!grievanceRoleData.facilities.includes(gFacilityId)) { return false; }
      if (levelPermission.permission.DUPLICATE === Permissions.BY_CATEGORY) {
        return grievanceRoleData.configRole.categories.some(
          (category) => category === gCategoryId
        );
      }
      return false;
    },
    /**
     * This is only for admins.
     */
    canChangeRequestType: ({ gLevel, gCategoryId, gFacilityId } = {}) => {
      if (grievanceRoleStatus !== 'success') { return null; }
      if (grievanceRoleData.useLegacyRole) { return true; }
      const levelPermission = gLevel
        ? grievanceRoleData.configRole.permissions.find(
          (permission) => permission.level === gLevel
        )
        : getPermissionWithLeastRestrictions('VIEW');
      if (!levelPermission.isEnabled) { return false; }
      if (
        !levelPermission.permission.REQUEST_TYPE_CHANGE === Permissions.NONE
      ) { return false; }
      if (levelPermission.permission.REQUEST_TYPE_CHANGE === Permissions.ALL) { return true; }
      return false;
    }
  };
};
