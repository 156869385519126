import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import FilterToolbar from './GroupRecordHeaderComponents/FilterToolbar';
import { GroupsLearningRecordContextProvider } from './GroupLearningRecordContext';
import ActivityByDate from './ActivityByDate/ActivityByDate';
import ActivityTotals from './ActivityTotals/ActivityTotals';
import CourseStatusReportByApplications from './CourseStatusReport/CourseStatusReportByApplications';
import CourseStatusReportByUsers from './CourseStatusReport/CourseStatusReportByUsers';
import AggregateAppUsage from './AggregateAppUsage/AggregateAppUsage';
import GroupRecordDetails from './GroupRecordHeaderComponents/GroupRecordDetails';
import UniversalGroupSearch from '../../Components/Widgets/UniversalGroupSearch';
import { Icon } from '../../Components/Shared/Icon';
import HeaderSubPanel from '../../Components/HeaderSubPanel';

function GroupLearningRecords () {
  const history = useHistory();
  const location = useLocation();

  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paramGroupId = params.get('groupId');
    setGroupId(paramGroupId);
  }, [location.search]);

  const handleChange = (group) => {
    if (group) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('groupId', group.id);
      history.replace({
        search: searchParams.toString()
      });
    } else {
      history.replace({
        pathname: '/group-reports',
        search: ''
      });
    }
  };

  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} title="Group Learning Records" />
      <div className="nucleos-core" style={{ paddingBottom: '150px' }} key={groupId}>
        <GroupsLearningRecordContextProvider>
          <div className="mb-4 mt-2 flex items-center">
            <Typography variant="h6" className="pr-10">
            Record Lookup:
            </Typography>
            <div className="universal-search-input" style={{ width: 400 }}>
              <UniversalGroupSearch
                Icon={
                  <Icon icon="GroupOutlined" style={{ paddingRight: 6, fill: '#3899FF' }} />
                }
                enableAllGroupOption
                groupId={groupId}
                onGroupSelect={handleChange}
              />
            </div>
          </div>
          {groupId && (
            <>
              <GroupRecordDetails />
              <FilterToolbar />
              <div className="flex gap-x-4">
                <div className="flex flex-col flex-1 gap-y-4">
                  <div className="flex-1">
                    <CourseStatusReportByApplications />
                  </div>

                  <div className="flex-1">
                    <AggregateAppUsage />
                  </div>
                </div>
                <div className="flex-1">
                  <CourseStatusReportByUsers />
                </div>
              </div>
              <div>
                <ActivityByDate />
              </div>
              <div>
                <ActivityTotals />
              </div>
            </>
          )}
        </GroupsLearningRecordContextProvider>
      </div>
    </Box>
  );
}

export default observer(GroupLearningRecords);
