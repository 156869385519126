import React, { useEffect, useRef, useState } from 'react';
import {
  Typography,
  MultiSelectDropDown,
  Button,
  TextInput
} from '@nucleos/core-ui';
import { useMutation, useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { toast } from 'react-hot-toast';
import AddModifyTemplate from './AddModifyTemplate';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import useUserPermissions from '../../hooks/useUserPermissions';
import { QueryKeys } from '../../Lib/query-keys';
import PDFIcon from '../../Assets/pdf-icon.svg';
import DeleteIcon from '../../Assets/delete-icon.svg';
import AttachmentIcon from '../../Assets/attachment-icon.svg';
import { IconButton, LinearProgress } from '@mui/material';

export function SubmitGrievanceReplyForm ({ grievance, onSubmissionSuccess }) {
  const fileUploadRef = useRef(null);

  const [response, setResponse] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState();
  const userPermissions = useUserPermissions();
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
  const [fileUploadProgress, setFileUploadProgress] = useState(0);

  const submitResponseMutation = useMutation(
    () => {
      return Data.submitGrievanceResponse(grievance.id, response, fileList, (event) => {
        console.log(event);

        if (event.lengthComputable) {
          let progress = Math.round((event.loaded / event.total) * 100);

          if (progress > 99) {
            progress = 99;
          }

          setFileUploadProgress(progress);
        }
      }).then(
        GenericErrorDetectorForMutations
      );
    },
    {
      onSuccess: () => {
        toast.success('Response submitted.');
        onSubmissionSuccess();
        setResponse('');
        setFileList([]);
      },
      onError: (err) => {
        console.error(err);

        toast.error('There was some error while submitting your response, please try again.');
      },
      onSettled: () => {
        setFileUploadProgress(0);
      }
    }
  );

  const templatesQuery = useQuery(
    QueryKeys.GrievanceResponseTemplates.listing({
      categoryId: grievance.GrievanceCategory.id
    }),
    () => Data.getFormResponseTemplates(grievance.GrievanceCategory.id),
    {
      placeholderData: {
        templates: []
      }
    }
  );

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.value) {
      setResponse(
        templatesQuery.data.templates.find(
          (template) => template.id === selectedTemplate.value
        ).content
      );
    }
  }, [selectedTemplate]);

  const templateOptions = templatesQuery.data.templates.map((template) => ({
    title: template.name,
    value: template.id
  }));

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter out non-PDF files
    let pdfFiles = files.filter((file) => file.type === 'application/pdf');

    // Limit to 10 files
    if ((pdfFiles.length + fileList.length + ((grievance.allAttachments && grievance.allAttachments.length) || 0)) > 10) {
      setFileUploadErrorMessage(`${pdfFiles.length > 1 ? 'These attachments' : 'This attachment'} cannot be uploaded. You have hit the limit of 10 attachments per grievance.`);
      return;
    }

    // Check file size
    const invalidFiles = pdfFiles.filter((file) => file.size > 25 * 1000 * 1000);
    if (invalidFiles.length > 0) {
      setFileUploadErrorMessage('File(s) exceeding the size limit of 25MB are not allowed.');

      pdfFiles = pdfFiles.filter((file) => file.size <= 25 * 1000 * 1000);
    }

    const uniquePDFFiles = pdfFiles.filter((newFile) =>
      !fileList.some((existingFile) => existingFile.name === newFile.name)
    );

    if (uniquePDFFiles.length !== pdfFiles.length) {
      setFileUploadErrorMessage('Duplicate file(s) are not allowed.');
    }

    const updatedFileList = [...fileList, ...uniquePDFFiles];

    setFileList(updatedFileList);
  };

  const handleFileRemove = (index) => {
    setFileList(prevFiles => prevFiles.filter((file, i) => i !== index));
  };

  return (
    <div className="mt-4 mb-4 border border-bg-gray-main p-4">
      <Typography variant="h6" className="mr-4 mb-2">
        Reply to
      </Typography>
      <MultiSelectDropDown
        dropdownId='grievance-template-select'
        testId="select-template"
        options={templateOptions}
        ActionButton={
          userPermissions.canCreateGrievanceTemplate() && (
            <div className="flex justify-center">
              <Button
                onClick={() => setTemplateDialogOpen(true)}
                fullWidth
                color="primary"
              >
                Create new template
              </Button>
            </div>
          )
        }
        onChange={([selectedTemplate]) => {
          if (!selectedTemplate) { return; }
          setSelectedTemplate(selectedTemplate);
        }}
        value={selectedTemplate ? [selectedTemplate] : []}
        disableMultiSelect
        label="Select Template Response"
      />
      <TextInput
        testId="grievance-response-text"
        className="border border-bg-gray-main w-full px-4 pt-4 pb-4"
        placeholder="Please enter response here....."
        value={response}
        onChange={(value) => setResponse(value)}
        multiline
        fullWidth
        rows={5}
      />

      <div className="flex flex-col gap-2 mb-4">
        <div className="flex items-center gap-x-1">
          <Typography variant="h6">
            Add Attachments
          </Typography>

          <Typography variant="subtitle2" className="">
            {'(Only pdf files are supported, with a maximum of 10 files)'}
          </Typography>
        </div>

        <div className="flex gap-x-4 items-center">
          <div>
            <input value={[]} ref={fileUploadRef} className="hidden" onChange={handleFileChange} multiple type="file" accept=".pdf" />

            <Button
              color="secondary"
              size='small'
              variant="outlined"
              onClick={() => {
                setFileUploadErrorMessage('');

                fileUploadRef.current && fileUploadRef.current.click();
              }}
            >
              <img className="mr-1" src={AttachmentIcon} />
              Add Attachment
            </Button>
          </div>

          <div>
            <Typography color="error">
              {fileUploadErrorMessage}
            </Typography>
          </div>
        </div>

        <div className="flex flex-col divide-y divide-solid w-3/4">
          {
            fileList.map((file, index) => (
              <div key={`${file.name}_${file.lastModified}`} className="flex justify-between items-center py-2">
                <div className="flex gap-x-2">
                  <div>
                    <img src={PDFIcon} style={{ height: 28 }} />
                  </div>

                  <div>
                    <Typography variant="h6">
                      {file.name}
                    </Typography>

                    <Typography variant="mini">
                      {(file.size / 1000 / 1000).toFixed(2)} MB
                    </Typography>
                  </div>
                </div>

                <div>
                  <IconButton onClick={() => handleFileRemove(index)}>
                    <img src={DeleteIcon} />
                  </IconButton>
                </div>
              </div>
            ))
          }
        </div>

        {
          fileUploadProgress
            ? (
              <div className="flex items-center gap-x-4">
                <div className="flex-1">
                  <LinearProgress variant="determinate" value={fileUploadProgress} />
                </div>
                <div className="flex-none">
                  <Typography>{`${fileUploadProgress}% Uploaded`}</Typography>
                </div>
              </div>
            )
            : null
        }
      </div>

      <Button
        style={{ color: 'white' }}
        color="primary"
        variant="contained"
        loading={submitResponseMutation.isLoading}
        onClick={() => {
          setFileUploadErrorMessage('');

          submitResponseMutation.mutate();
        }}
        disabled={!response.length}
      >
        Submit
      </Button>
      <AddModifyTemplate
        isOpen={templateDialogOpen}
        onClose={() => setTemplateDialogOpen(false)}
      />
    </div>
  );
}
