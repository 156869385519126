import React from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@nucleos/core-ui';

import { LoadingAndErrorHandler } from '../../../../Components/Shared/LoadingErrorHandler';
import { QueryKeys } from '../../../../Lib/query-keys';
import Data from '../../../../Middleware/Data';

export default function ApplicationList () {
  const applicationsQuery = useQuery(
    QueryKeys.Applications.listing(),
    () => Data.getApplications(),
    {
      select: (data) =>
        data.map((app) => ({
          uid: app.uid,
          displayName: app.name,
          name: app.name,
          logo: app.logo,
          icon: app.icon,
          longDescription: app.longDescription,
          description: app.description
        })),
      placeholderData: []
    }
  );

  return (
    <div
      style={{
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        gap: '12px'
      }}
    >
      <Typography
        variant="h4"
        style={{ fontWeight: 700, fontSize: '20px', color: '#333' }}
      >
        Application List
      </Typography>
      <div
        style={{
          display: 'flex',
          alignSelf: 'stretch',
          flexDirection: 'column',
          gap: '12px'
        }}
      >
        <LoadingAndErrorHandler
          isLoading={
            applicationsQuery.isFetching && applicationsQuery.isPlaceholderData
          }
          isError={applicationsQuery.isError}
          isSuccess={applicationsQuery.isSuccess}
        >
          {applicationsQuery.data.map((application, i) => {
            return (
              <div
                key={i}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  gap: '20px',
                  padding: '12px 16px',
                  borderBottom:
                    i !== applicationsQuery.data.length - 1
                      ? '1px solid #E9F0FF'
                      : 'none'
                }}
              >
                <img
                  className="h-11 w-11 object-contain"
                  style={{ borderRadius: '50%' }}
                  src={application.logo || application.icon || ''}
                  alt={application.displayName || application.name}
                  title={application.displayName || application.name}
                />
                {application.longDescription || application.description}
              </div>
            );
          })}
        </LoadingAndErrorHandler>
      </div>
    </div>
  );
}
