import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Typography,
  DateRangePicker,
  MultiSelectDropDown,
  Table
} from '@nucleos/core-ui';
import LoadingComponent from '../Components/Widgets/LoadingComponent';
import { SetPageTitleFromUsername } from '../Lib/SetPageTitleFromUsername';
import AuthenticationStore from '../Stores/Authentication';
import DataStore from '../Stores/DataStore';
import { formatMinutes, getDays } from '../Lib/util';
import UserChart from './LearningOverview/UserChart';
import { NoDataFound } from './LearningOverview/NoDataFound';
import ScrollToTop from '../Components/ScrollToTop';
import { useQuery } from 'react-query';
import Data from '../Middleware/Data';

const defaultSorting = {
  sortOrder: 'DESC',
  sortBy: 'date'
};
const defaultRecordsPerPage = 10;

const UsageHistory = (props) => {
  const {
    startDate: customStartDate,
    endDate: customEndDate,
    timeSelect,
    selectedApps,
    setTimeSelect,
    setStartDate,
    setEndDate,
    setTimePeriod,
    setSelectedApps
  } = props;

  const { uid: loggedInUserId } = AuthenticationStore;
  const { getUser } = DataStore;
  const { uid } = useParams();
  const [searchedApp, setSearchedApp] = useState('');
  const [columnSort, setColumnSort] = useState(defaultSorting);
  const [recordsPerPage, setRecordsPerPage] = useState(defaultRecordsPerPage);
  const [page, setPage] = useState(1);

  const selectedDay = getDays().find(
    (day) => day.name === timeSelect.split('|')[0]
  );

  const timePeriod =
    selectedDay && timeSelect ? selectedDay.timePeriod : 'month';
  const startDate = timeSelect
    ? timeSelect.split('|')[1]
    : moment(customStartDate).format('YYYY-MM-DD');
  const endDate = timeSelect
    ? timeSelect.split('|')[2]
    : moment(customEndDate).format('YYYY-MM-DD');

  const getActivityQuery = useQuery(
    [
      'USAGE_ACTIVITY',
      timeSelect,
      columnSort,
      page,
      recordsPerPage,
      selectedApps,
      startDate,
      endDate,
      uid,
      loggedInUserId
    ],
    () => {
      const from = moment(startDate).format('YYYY-MM-DD');
      const to = moment(endDate).format('YYYY-MM-DD');
      const userId = uid || loggedInUserId;
      const orderBy = columnSort.sortBy;
      const order = columnSort.sortOrder;
      const limit = recordsPerPage;
      const apps =
        selectedApps &&
        selectedApps.length &&
        selectedApps.map((app) => app.value);
      return Data.getRawActivity(
        userId,
        from,
        to,
        orderBy,
        order,
        page,
        limit,
        apps
      );
    }
  );

  const getUsageHistoryChartQuery = useQuery(
    [
      'USAGE_HISTORY_CHART',
      timeSelect,
      startDate,
      endDate,
      timePeriod,
      uid,
      loggedInUserId
    ],
    () => {
      const from = moment(startDate).format('YYYY-MM-DD');
      const to = moment(endDate).format('YYYY-MM-DD');
      const userId = uid || loggedInUserId;
      return Data.getUserUsageData(userId, from, to, timePeriod);
    }
  );

  useEffect(() => {
    setSelectedApps(selectedApps || []);
  }, [selectedApps]);

  useEffect(() => {
    if (!timeSelect) { return; }
    const startDate = timeSelect.split('|')[1];
    const endDate = timeSelect.split('|')[2];
    const timePeriod = selectedDay ? selectedDay.timePeriod : 'month';
    setStartDate(startDate);
    setEndDate(endDate);
    setTimePeriod(timePeriod);
  }, [timeSelect]);

  useEffect(() => {
    const userId = uid || loggedInUserId;
    getUser(userId);
  }, [uid, loggedInUserId]);

  const aggregatedActivity = (
    getActivityQuery &&
      getActivityQuery.data &&
      getActivityQuery.data.records &&
      getActivityQuery.data.records.length
      ? getActivityQuery.data.records
      : []
  ).map(
    ({
      date,
      siteTitle,
      timeSpent: minutes,
      Domain: {
        Contentfilter: {
          Application: { name }
        }
      }
    }) => {
      return {
        activity: siteTitle || '-',
        date: moment(date).format('MM/DD/YYYY'),
        minutes,
        Application: name
      };
    }
  );

  const timeOptions = getDays();
  const options = timeOptions.map(({ name, val, timePeriod }) => {
    return { title: name, value: val, timePeriod };
  });

  const handleTimeSelectDropdown = (e) => {
    const { value } = e[0];
    setTimeSelect(value);
  };

  const applicationOptions = [];
  const userApplications =
    (getUsageHistoryChartQuery &&
      getUsageHistoryChartQuery.data &&
      getUsageHistoryChartQuery.data.applications) ||
    [];
  if (
    getUsageHistoryChartQuery &&
    getUsageHistoryChartQuery.data &&
    getUsageHistoryChartQuery.data.applications &&
    getUsageHistoryChartQuery.data.applications.length
  ) {
    if (searchedApp && searchedApp.length) {
      const filterdApps = userApplications.filter((n) =>
        n.toLowerCase().includes(searchedApp.toLowerCase())
      );
      filterdApps.forEach((a) => {
        applicationOptions.push({ title: a, value: a });
      });
    } else {
      userApplications.forEach((a) => {
        applicationOptions.push({ title: a, value: a });
      });
    }
  }

  const setColumnSorting = (sortBy, sortOrder) => {
    // This logic is to make sure that there is sorting applied at any given movement.
    // This will fallback to ascending sort of alert (AGE) column if no sort is applied.
    if (sortOrder === 'NONE' && sortBy !== defaultSorting.sortBy) { return setColumnSort(defaultSorting); }
    if (sortOrder === 'NONE' && sortBy === defaultSorting.sortBy) { return setColumnSort({ ...defaultSorting, sortOrder: 'ASC' }); }

    setColumnSort({ sortBy, sortOrder });
  };

  const recordPerPageChangeHandler = (rowsPP) => {
    setRecordsPerPage(rowsPP);
    setPage(1);
  };

  const pageChangeHandler = (page) => {
    setPage(page);
  };

  const timeSelectOptions = [
    { value: '', title: 'Custom Date', timePeriod: 'custom' },
    ...options
  ] || [];

  return (
    <>
      <SetPageTitleFromUsername />
      <ScrollToTop />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 18
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            Showing results from {moment(startDate).format('MMM DD, YYYY')} -{' '}
            {moment(endDate).format('MMM DD, YYYY')}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            className="chart-date__text"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: '15px',
                lineHeight: '20px',
                marginRight: '8px'
              }}
            >
              Time Period:{' '}
            </span>
            <MultiSelectDropDown
              dropdownId="usage-history-time-period-select"
              disableHelperText
              disableMultiSelect
              onChange={(e) => {
                handleTimeSelectDropdown(e);
              }}
              className="pr-3 w-52"
              value={timeSelectOptions.filter(ts => ts.value === timeSelect)}
              label="Time period"
              options={
                timeSelectOptions
              }
            />
          </div>
          <div
            style={{
              alignItems: 'center'
            }}
            className="chart-date__picker-wrapper"
          >
            <div className="chart-date__picker">
              <DateRangePicker
                format="MM/DD/YYYY"
                maxDate={new Date()}
                disableHelperText
                className={'w-48 text-left' + (timeSelect !== '' ? ' hidden' : '')}
                placeholder="Select date range"
                fullWidth
                onChange={({ startDate, endDate }) => {
                  setStartDate(moment(startDate).format('YYYY-MM-DD'));
                  setEndDate(moment(endDate).format('YYYY-MM-DD'));
                }}
                value={{
                  startDate: startDate ? new Date(startDate) : new Date(),
                  endDate: endDate ? new Date(endDate) : new Date()
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: '15px',
                lineHeight: '20px',
                color: '#333333',
                margin: '0px 8px 0px 24px'
              }}
            >
              Apps:{' '}
            </span>
            <div style={{ width: '172px' }}>
              <MultiSelectDropDown
                dropdownId="usage-history-apps-select"
                style={{
                  width: '172px'
                }}
                disableHelperText
                onChange={setSelectedApps}
                value={selectedApps}
                getLabel={(selected) =>
                  selected.length > 1
                    ? `Selected App${selected.length > 1 ? 's' : ''} (${selected.length
                    })`
                    : selected
                }
                label={'All Apps'}
                searchable
                onSearchChange={(s) => {
                  setSearchedApp(s);
                }}
                options={[
                  ...selectedApps,
                  ...applicationOptions.filter(
                    (app) =>
                      !selectedApps.map((app) => app.value).includes(app.value)
                  )
                ]}
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
      <div className="message-audit">
        {getActivityQuery &&
          getActivityQuery.isLoading &&
          getUsageHistoryChartQuery &&
          getUsageHistoryChartQuery.isLoading
          ? (
            <LoadingComponent />
          )
          : (
            <>
              <section className="message-audit__table-container">
                <div style={{ width: '100%' }}>
                  <UserChart
                    userUsageTotals={
                      getUsageHistoryChartQuery &&
                    getUsageHistoryChartQuery.data &&
                    getUsageHistoryChartQuery.data.records
                    }
                    loading={
                      getUsageHistoryChartQuery &&
                    getUsageHistoryChartQuery.isLoading
                    }
                    userApplications={userApplications}
                    selectedApp={selectedApps}
                  />
                  <div className="message-audit__table">
                    <Table
                      loading={getActivityQuery && getActivityQuery.isLoading}
                      columns={[
                        {
                          title: 'Activity',
                          render: (data) => (
                            <div className="nucleos-core" style={{ minWidth: 0 }}>
                              <p className="truncate" title={data.activity}>
                                {data.activity}
                              </p>
                            </div>
                          ),
                          enableSort: true,
                          sortOrder:
                          columnSort.sortBy == 'siteTitle'
                            ? columnSort.sortOrder
                            : 'NONE',
                          onSortChange: (sortOrder) => {
                            setColumnSorting('siteTitle', sortOrder);
                          }
                        },
                        {
                          title: 'Application',
                          render: (data) => <p>{data.Application}</p>,
                          enableSort: true,
                          sortOrder:
                          columnSort.sortBy == 'application'
                            ? columnSort.sortOrder
                            : 'NONE',
                          onSortChange: (sortOrder) => {
                            setColumnSorting('application', sortOrder);
                          }
                        },
                        {
                          title: 'Date',
                          render: (data) => <p>{data.date}</p>,
                          enableSort: true,
                          sortOrder:
                          columnSort.sortBy == 'date'
                            ? columnSort.sortOrder
                            : 'NONE',
                          onSortChange: (sortOrder) => {
                            setColumnSorting('date', sortOrder);
                          }
                        },
                        {
                          title: 'Time Spent',
                          render: ({ minutes }) => (
                            <p>{formatMinutes(minutes)}</p>
                          ),
                          enableSort: true,
                          sortOrder:
                          columnSort.sortBy == 'timeSpent'
                            ? columnSort.sortOrder
                            : 'NONE',
                          onSortChange: (sortOrder) => {
                            setColumnSorting('timeSpent', sortOrder);
                          }
                        }
                      ]}
                      rowsData={
                        selectedApps.length > 0
                          ? aggregatedActivity.filter((activity) => {
                            return selectedApps.some(
                              (app) => app.title === activity.Application
                            );
                          })
                          : aggregatedActivity
                      }
                      noDataMessage={<NoDataFound />}
                      striped
                      recordsPerPage={recordsPerPage}
                      onRecordsPerPageChange={recordPerPageChangeHandler}
                      page={page}
                      onPageChange={pageChangeHandler}
                      pagination
                      totalRecords={
                        getActivityQuery &&
                        getActivityQuery.data &&
                        getActivityQuery.data.count
                          ? getActivityQuery.data.count
                          : 0
                      }
                    />
                  </div>
                </div>
              </section>
            </>
          )}
      </div>
    </>
  );
};

export default (observer(UsageHistory));
