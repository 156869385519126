import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

function useHorizontalScroll () {
  const elRef = useRef();

  useEffect(() => {
    const el = elRef.current;

    if (el) {
      const onWheel = (e) => {
        if (e.deltaY == 0) {
          return;
        }

        e.preventDefault();

        el.scrollTo({
          left: el.scrollLeft + e.deltaY
        });
      };

      el.addEventListener('wheel', onWheel);

      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);

  return elRef;
}

export const Seekbar = (props) => {
  const { sections, cursorPosition, cursorRef, jumpTo } = props;

  const [seekbarTooltipVisible, setSeekbarTooltipVisible] = useState(false);
  const [seekbarMousePosition, setSeekbarMousePosition] = useState({ x: undefined, y: undefined });

  const seekbarRef = useHorizontalScroll();

  const handleSectionJump = useCallback((e, section, sectionIndex) => {
    e.stopPropagation();
    e.preventDefault();

    const targetRect = e.target.getBoundingClientRect();
    const cursorClickPosition = e.clientX - targetRect.left;

    jumpTo(cursorClickPosition, section, sectionIndex);
  }, [jumpTo]);

  const handleSeekbarTooltipMouseMove = useCallback((e) => {
    setSeekbarMousePosition({ x: e.clientX, y: e.clientY });
  }, []);

  const handleSeekbarMouseOver = useCallback((e) => {
    setSeekbarTooltipVisible(true);
  });

  const handleSeekbarMouseOut = useCallback((e) => {
    setSeekbarTooltipVisible(false);
  });

  const handleSeekbarSectionMouseOver = useCallback((e) => {
    e.stopPropagation();

    setSeekbarTooltipVisible(false);
  });

  return (
    <div className='seekbar-container'>
      <div className='seekbar' ref={seekbarRef}>
        <Tooltip
          open={seekbarTooltipVisible}
          title="User was offline at this moment"
          onMouseMove={handleSeekbarTooltipMouseMove}
          placement='top'
          PopperProps={{
            anchorEl: {
              clientHeight: 0,
              clientWidth: 0,
              getBoundingClientRect: () => ({
                top: seekbarRef.current ? seekbarRef.current.getBoundingClientRect().top : seekbarMousePosition.y,
                left: seekbarMousePosition.x,
                right: seekbarMousePosition.x,
                bottom: seekbarRef.current ? seekbarRef.current.getBoundingClientRect().bottom : seekbarMousePosition.y,
                width: 0,
                height: 0
              })
            }
          }}
        >
          <div className='seek' onMouseOver={handleSeekbarMouseOver} onMouseOut={handleSeekbarMouseOut}>
            {
              sections.map((section, sectionIndex) => (
                <div
                  className='seek-section'
                  title=''
                  key={section.first_image_timestamp}
                  style={{ left: section.startPosition, width: section.width }}
                  onClick={(e) => handleSectionJump(e, section, sectionIndex)}
                  onMouseOver={handleSeekbarSectionMouseOver}
                />
              ))
            }

            <div ref={cursorRef} className='cursor' title='' style={{ left: cursorPosition }} onMouseOver={handleSeekbarSectionMouseOver}></div>
          </div>
        </Tooltip>

        <div className='timeline'>
          {
            new Array(23).fill(null).map((e, i) => (
              <div className='timeline-label' style={{ left: 120 * (i + 1) }}>
                <span>
                  {`${(i + 1).toString().padStart(2, '0')}:00`}
                </span>

                <div className='timeline-marker'>
                  <span>|</span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
