import React, { useState } from 'react';
import { Typography, Button, LoadingSpinner } from '@nucleos/core-ui';
import ApplicationDescriptionModal from '../ApplicationDescription/ApplicationDescriptionModal';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import SaveAsPDFDialog from '../PDF/ExportAsPDFModal';
import { useIsMutating } from 'react-query';
import { Icon } from '../../../Components/Shared/Icon';

export default function GroupRecordDetails () {
  const GLRContext = useGroupsLearningRecordContext();
  const [showApplicationDescriptionModal, setShowApplicationDescriptionModal] =
    useState(false);
  const [saveAsPDFModal, setSaveAsPDFModal] = useState(false);
  const exportPDFLoading = useIsMutating(['PDF_MUTATION']);

  return (
    <>
      <div
        className="flex justify-between items-center py-4"
        style={{ borderTop: '0.5px solid #BFC5D0' }}
      >
        {!GLRContext.isPDFMode
          ? (
            <>
              <div>
                <Typography variant="h3" color="#19223D">
                Group Record Details - {(GLRContext.group || {}).name}
                </Typography>
              </div>
              <div className="flex items-center" style={{ gap: 14 }}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{
                    height: '2.2rem'
                  }}
                  onClick={() => setSaveAsPDFModal(true)}
                  disabled={!!exportPDFLoading}
                >
                  {exportPDFLoading
                    ? (
                      <span className="flex items-center">
                        <span className="pr-2">Generating PDF...</span>
                        <LoadingSpinner size={4} />
                      </span>
                    )
                    : (
                      <span className="flex items-center">
                        <Icon icon="Pdf" className="mr-2" /> Save as PDF
                      </span>
                    )}
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  style={{
                    height: '2.2rem'
                  }}
                  onClick={() => setShowApplicationDescriptionModal(true)}
                >
                Application Description
                </Button>
              </div>
            </>
          )
          : null}
      </div>
      <SaveAsPDFDialog
        isOpen={saveAsPDFModal}
        onClose={() => setSaveAsPDFModal(false)}
      />
      <ApplicationDescriptionModal
        open={showApplicationDescriptionModal}
        onClose={() => setShowApplicationDescriptionModal(false)}
      />
    </>
  );
}
