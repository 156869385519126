import React from 'react';
import { Avatar } from '@mui/material';

const Icon = ({ initials, isUser }) => {
  // return <div style={{ textAlign: 'center', backgroundColor: isUser ? '#204873' : '#1477A9', color: 'white', height: '50px', lineHeight: '50px', width: '50px', borderRadius: '50%' }} >
  // </div>
  return <Avatar style={{ backgroundColor: isUser ? '#204873' : '#1477A9' }}>{initials}</Avatar>;
};

export default Icon;
