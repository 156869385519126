import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Typography,
  DateRangePicker,
  MultiSelectDropDown,
  Button
} from '@nucleos/core-ui';

import { observer } from 'mobx-react';
import moment from 'moment';

import Data from '../../../Middleware/Data';
import DataStore from '../../../Stores/DataStore';
import NoUserSelected from './NoUserSelected';
import UniversalUserSearch from '../../../Components/Widgets/UserSearch/UniversalUserSearch';
import ActivityLookupPlayer from '../../../Components/ActivityLookupPlayer';
import {
  getTimePeriodFilterOptions,
  enumerateDaysBetweenDates,
  preLoadImageURLs,
  stringToBase64Url
} from '../../../Lib/util';

import './styles.scss';
import { Icon } from '../../../Components/Shared/Icon';
import { Stack } from '@mui/material';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';
import { INVESTIGATIVE_PATH } from '../../../constants/paths';

const timePeriodOptions = getTimePeriodFilterOptions();
const options = timePeriodOptions.map(({ name, val, timePeriod }) => {
  return { title: name, value: `${val}+${name}`, timePeriod };
});
const [initialPeriodStart, initialPeriodEnd] =
  timePeriodOptions[0].val.split('|');

const breadcrumbLinks = [
  { href: INVESTIGATIVE_PATH, label: 'Investigative Toolset' },
  { href: '#', label: 'Activity Lookup' }
];

function ActivityLookup () {
  const history = useHistory();
  const { uid } = useParams();

  const [timePeriod, setTimePeriod] = useState(
    `${timePeriodOptions[0].val}+${timePeriodOptions[0].name}`
  );
  const [startDate, setStartDate] = useState(initialPeriodStart);
  const [endDate, setEndDate] = useState(initialPeriodEnd);

  const [lastActivityTimestamp, setLastActivityTimestamp] = useState();

  const { currentUser } = DataStore;

  const dateRange = useMemo(() => {
    return enumerateDaysBetweenDates(startDate, endDate);
  }, [startDate, endDate, enumerateDaysBetweenDates]);

  const setUser = useCallback(
    (user) => {
      if (!user) {
        return;
      }

      const { uid } = user;

      DataStore.getUser(uid);

      history.replace(`/investigative-tools/activity-lookup/${uid}`);
    },
    [DataStore.getUser]
  );

  const handleTimePeriodSelect = useCallback((e) => {
    const { value } = e[0];

    setTimePeriod(value);

    if (!value.includes('|')) {
      return;
    }

    const val = value.split('+')[0];
    setStartDate(val.split('|')[0]);
    setEndDate(val.split('|')[1]);
  }, []);

  const getStreamChunk = useCallback(
    async (start, end, size, sectionStartTimestamp) => {
      if (!uid) {
        return [];
      }

      const payload = {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        startingTimeStamp: start,
        endingTimeStamp: end,
        limit: size
      };

      if (sectionStartTimestamp) {
        payload.sectionTimeStamp = sectionStartTimestamp;
      }

      const data = await Data.getActivityLookupChunk(uid, payload);

      const chunk = data.userScreenshots;
      const imagesBeforeTimestamp = data.countOfImagesFromSection;

      await preLoadImageURLs(chunk.map((e) => e.signedUrl));

      console.log('stream chunk: ', chunk);

      return [chunk, imagesBeforeTimestamp];
    },
    [uid, Data.getActivityLookupChunk]
  );

  const getMetaData = useCallback(
    async (date) => {
      if (!uid) {
        return {};
      }

      const data = await Data.getActivityLookupMetaData(uid, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        date
      });

      console.log('meta data: ', data);

      setLastActivityTimestamp(data.lastGeneratedImage);

      return data.userTimeLine;
    },
    [uid, Data.getActivityLookupMetaData]
  );

  const openLiveActivity = useCallback(() => {
    const filters = {
      learners: [uid],
      pods: [],
      facilities: [],
      liveLearners: false
    };

    const url = `${
      window.location.origin
    }/investigative-tools/live-activity/?filters=${stringToBase64Url(
      JSON.stringify(filters)
    )}&selectedUser=${stringToBase64Url(uid)}`;

    window.open(url, '_blank').focus();
  }, [uid]);

  useEffect(() => {
    console.log('user change: ', JSON.parse(JSON.stringify(currentUser)));
  }, [currentUser]);

  useEffect(() => {
    if (uid) {
      DataStore.getUser(uid);
    }
  }, [uid]);

  const timePeriodOptionList = [
    { value: '', title: 'Custom Date', timePeriod: 'custom' },
    ...options
  ];
  return (
    <Stack>
      <HeaderSubPanel title="Activity Lookup" links={breadcrumbLinks} sx={{ mb: 3 }} />
      <div className="nucleos-core">
        <div className="flex pb-5">
          <div className="flex items-center">
            <Typography variant="h4" className="mr-8">
            Activity Lookup:
            </Typography>
          </div>

          <div className="user-search-input rounded-full w-96">
            <UniversalUserSearch
              Icon={
                <Icon icon="Account"
                  style={{ paddingRight: 6, fill: '#3899FF' }}
                />
              }
              learnerOnly
              onUserSelect={setUser}
              userId={uid}
            />
          </div>
        </div>

        {currentUser.uid
          ? (
            <div className="pt-5 border-t pb-12">
              <div className="flex justify-between">
                <div className="flex items-center">
                  <Typography>
                Showing results from {moment(startDate).format('MMM DD, YYYY')}{' '}
                - {moment(endDate).format('MMM DD, YYYY')}
                  </Typography>
                </div>
                <div className="flex gap-x-2">
                  <div className="flex items-center">
                    <Typography variant="h5" className="mr-4">
                      Time Period:
                    </Typography>

                    <MultiSelectDropDown
                      dropdownId="activity-lookup-time-period-select"
                      className="w-32"
                      disableHelperText
                      disableMultiSelect
                      onChange={handleTimePeriodSelect}
                      value={timePeriodOptionList.filter(
                        (op) => op.value === timePeriod
                      )}
                      label="Select Date"
                      options={timePeriodOptionList}
                    />
                  </div>

                  <div>
                    <DateRangePicker
                      maxDate={new Date()}
                      minDate={new Date(moment().subtract(1, 'year'))}
                      disableHelperText
                      className={
                        'w-48 text-left' + (timePeriod !== '' ? ' hidden' : '')
                      }
                      placeholder="Select date range"
                      onChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                      }}
                      value={{
                        startDate: startDate ? new Date(startDate) : new Date(),
                        endDate: endDate ? new Date(endDate) : new Date()
                      }}
                      DropdownContainerProps={{ direction: 'left' }}
                    />
                  </div>
                </div>
              </div>

              <div className="player-container flex flex-col mt-5 rounded-md">
                <div className="pseudo-video-player flex justify-center items-center">
                  <ActivityLookupPlayer
                    userDetails={currentUser}
                    dateRange={dateRange}
                    getMetaData={getMetaData}
                    getStreamChunk={getStreamChunk}
                  />
                </div>

                <div className="flex items-center p-5 pt-0 mt-4 justify-between">
                  <div>
                    <Typography>
                  Activity data last updated at{' '}
                      {moment(lastActivityTimestamp).format(
                        'ddd MMM DD, YYYY hh:mm a'
                      )}
                    </Typography>
                  </div>

                  <div>
                    <Button
                      color='primary'
                      size="small"
                      variant="outlined"
                      style={{
                        borderRadius: 40
                      }}
                      onClick={openLiveActivity}
                    >
                  View Live Activity
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )
          : (
            <NoUserSelected />
          )}
      </div>
    </Stack>
  );
}

export default observer(ActivityLookup);
