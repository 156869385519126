import { Typography } from '@nucleos/core-ui';
import React, { useState } from 'react';

export const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div className="nucleos-core">
      <div
        className="relative inline-block"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
        {isVisible && (
          <div
            style={{ zIndex: 10000, bottom: '100%' }}
            className="absolute px-2 py-1 border bg-white text-sm rounded-lg"
          >
            <Typography variant="body2">
              {text}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
