import React from 'react';
import ProgressDonutChart from '../../Components/Chart/ProgressDonutChart';
import { COURSE_METRICS_FORMATS, COURSE_STATUS } from '../../Lib/CONSTANTS';
import { msToHHMMSS } from '../../Lib/util';

const CourseProgressMetric = (props) => {
  const { valueKey, format, label, rawData, courseStatus } = props;
  let valueComponent = null;
  if (format == COURSE_METRICS_FORMATS.CIRCULAR_PROGRESS) {
    valueComponent = (<div className='flex justify-start items-center' >
      {(rawData[valueKey] || rawData[valueKey] == 0) ? <ProgressDonutChart value={rawData[valueKey]} fill="#21BF1D" size={30} /> : null}
      <div className='text-base font-semibold' style={{ color: '#333333' }} >{ ((rawData[valueKey] || rawData[valueKey] == 0) && rawData[valueKey] != 'NA') ? `${parseFloat(rawData[valueKey]).toFixed(2).replace(/[.,]00$/, '')}%` : 'NA' }</div>
    </div>);
  }
  if (format == COURSE_METRICS_FORMATS.DURATION) {
    valueComponent = (<div className='text-base font-semibold' style={{ color: '#333333' }} >  {(rawData[valueKey] || rawData[valueKey] == 0) ? `${msToHHMMSS(rawData[valueKey])}h` : 'NA' } </div>);
  }

  if (format == COURSE_METRICS_FORMATS.PERCENT) {
    valueComponent = (<div className='text-base font-semibold' style={{ color: '#333333' }} >{((rawData[valueKey] || rawData[valueKey] == 0) && rawData[valueKey] != 'NA') ? `${parseFloat(rawData[valueKey]).toFixed(2).replace(/[.,]00$/, '')}%` : 'NA' }</div>);
  }

  if (format == COURSE_METRICS_FORMATS.NUMBER) {
    valueComponent = (<div className='text-base font-semibold' style={{ color: '#333333' }} >{(rawData[valueKey] || rawData[valueKey] == 0) || 'NA'}</div>);
  }

  if (format == COURSE_METRICS_FORMATS.STRING) {
    valueComponent = (<div className='text-base font-semibold' style={{ color: '#333333' }} >{(rawData[valueKey]) || 'NA'}</div>);
  }

  if (format == COURSE_METRICS_FORMATS.STATUS) {
    valueComponent = (<div className='text-base font-semibold' style={{ color: '#333333' }} >{(COURSE_STATUS[courseStatus] || COURSE_STATUS[rawData[valueKey]]) || 'NA'}</div>);
  }

  const getLabel = () => {
    return (label || 'NA');
  };
  return (
    <div className='mr-10' style={{ width: '15%' }}>
      <div style={{ color: 'rgba(51, 51, 51, 0.6)' }} className='text-xs font-normal mb-2' >{getLabel(label) || 'NA'}</div>
      {valueComponent}
    </div>
  );
};

const CourseProgressMetrics = (props) => {
  const { rawData, metaRawData, courseStatus } = props;
  if (rawData && metaRawData && metaRawData.course_grid && metaRawData.course_grid.length) {
    const courseGrid = metaRawData.course_grid;
    return (
      <div className='flex justify-start items-center'>
        {
          courseGrid.map((cg, index) => <CourseProgressMetric key={`cg-value-metric-${index}`} valueKey={cg.key} format={cg.format} label={cg.label} rawData={rawData} courseStatus={courseStatus} />)
        }
      </div>

    );
  } else {
    return null;
  }
};

export default CourseProgressMetrics;
