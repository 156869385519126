import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import {
  TextInput,
  Typography,
  Button as NCButton,
  MultiSelectDropDown
} from '@nucleos/core-ui';

import Data from '../../Middleware/Data';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { validateString } from '../../Components/Form';
import InfoOutlined from '../../Assets/info-outline.svg';
import { Icon } from '../../Components/Shared/Icon';

const AddUpdateUserDetails = ({
  action,
  userDetails,
  applicationUid,
  applicationName,
  applicationKey,
  userPasswordMetadata,
  onClose
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const isNew = action === 'ADD';

  const { data, isFetching } = useQuery(
    ['MISSING_APPLICATION_USERS', applicationUid, searchTerm],
    () => Data.getMissingApplicationUsers(applicationUid, searchTerm),
    {
      select: (data) =>
        (data.users || []).map((user) => ({
          title: `${user.fullname} (${user.username})`,
          value: user.uid
        })),
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: []
    }
  );

  const createApplicationUserMutation = useMutation(
    (data) =>
      Data.createApplicationUser(data).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        closeModalHandler(true);
        toast.success('User created successfully.');
      },
      onError: (err) => toast.error(err.message)
    }
  );

  const updateApplicationUserMutation = useMutation(
    (data) =>
      Data.updateApplicationUser(data).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        closeModalHandler(true);
        toast.success('User details updated successfully.');
      },
      onError: (err) => toast.error(err.message)
    }
  );

  const validate = (values) => {
    const { name, appUsername, appPassword } = values;
    const nameError = !name.trim() ? 'Required' : undefined;
    const appUsernameError = validateString(appUsername, true, 2, 255);
    const appPasswordError = validateString(appPassword, true, 2, 255);
    const errors = {};
    if (nameError) { errors.name = nameError; }
    if (appUsernameError) { errors.appUsername = appUsernameError; }
    if (appPasswordError) { errors.appPassword = appPasswordError; }
    userPasswordMetadata.forEach((obj) => {
      const objError = !values[obj.key] || !values[obj.key].trim() ? 'Required' : undefined;
      if (objError) { errors[obj.key] = objError; }
    });
    return errors;
  };

  const onSubmit = (values) => {
    const { name, appUsername, appPassword, ...rest } = values;
    if (isNew) {
      createApplicationUserMutation.mutate({
        nucleosUserId: name,
        appUsername,
        appPassword,
        applicationUid,
        applicationKey,
        ...rest
      });
    } else {
      updateApplicationUserMutation.mutate({
        username: appUsername,
        password: appPassword,
        ...rest
      });
    }
  };

  const formik = useFormik({
    initialValues: userDetails,
    validate,
    onSubmit
  });

  const { values, errors, touched, handleSubmit, setFieldValue, resetForm } =
    formik;

  const closeModalHandler = (reload = false) => {
    resetForm();
    onClose(reload);
  };

  const renderConditionalFields = () => {
    return userPasswordMetadata.map((metadata) => (
      <div key={metadata.key}>
        <Typography className="mb-1.5">
          {metadata.label}
          <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>
            *
          </span>
        </Typography>
        <TextInput
          name={metadata.key}
          autoComplete="new-name"
          placeholder={`Enter ${metadata.label}`}
          onChange={(e) => setFieldValue(metadata.key, e)}
          value={values[metadata.key]}
          error={touched[metadata.key] && errors[metadata.key]}
          helperText={
            touched[metadata.key] &&
            errors[metadata.key] && (
              <span className="text-xs" style={{ color: '#d32f2f' }}>
                {errors[metadata.key]}
              </span>
            )
          }
        />
      </div>
    ));
  };

  return (
    <Grid style={{ width: window.screen.width * 0.5 }}>
      <div className="nucleos-core">
        <div className="p-5 rounded-lg" style={{ background: '#FFFFFF' }}>
          <div
            className="flex justify-between items-center pb-3"
            style={{ borderBottom: '1px solid rgba(51, 51, 51, 0.2)' }}
          >
            <div className="text-2xl font-medium">
              {isNew ? 'Add New User' : 'Change User Details'}
            </div>
            <div className="flex cursor-pointer" onClick={() => closeModalHandler()}>
              <Icon icon="Close" />
            </div>
          </div>
          <div
            className="mt-4 overflow-scroll"
            style={{ maxHeight: window.screen.height * 0.6 }}
          >
            <form method="post" onSubmit={handleSubmit} autoComplete="off">
              <div className="mb-6 flex items-center">
                <img src={InfoOutlined} alt="info" />
                <Typography className="ml-2">
                  Please ensure the login details are for a valid user account
                  for
                  <span style={{ color: '#333', fontWeight: '600' }}>
                    &nbsp;
                    {applicationName}
                  </span>
                </Typography>
              </div>
              <div className="w-2/3">
                {isNew
                  ? (
                    <>
                      <Typography className="mb-1.5">
                      Nucleos Username
                        <span
                          className="text-xs ml-1"
                          style={{ color: '#d32f2f' }}
                        >
                        *
                        </span>
                      </Typography>
                      <MultiSelectDropDown
                        dropdownId="add-update-user-details-username-select"
                        fullWidth
                        disableMultiSelect
                        searchable
                        isSearchLoading={isFetching}
                        onSearchChange={(search) => {
                          setSearchTerm(search);
                        }}
                        onChange={(e) => {
                          setFieldValue('name', e[0].value);
                        }}
                        value={values.name ? data.filter(op => op.value === values.name) : []}
                        label="Select"
                        options={data}
                        error={touched.name && errors.name}
                        helperText={
                          touched.name &&
                        errors.name && (
                            <span
                              className="text-xs"
                              style={{ color: '#d32f2f' }}
                            >
                              {errors.name}
                            </span>
                          )
                        }
                      />
                    </>
                  )
                  : (
                    <>
                      <Typography className="mb-1.5">Name</Typography>
                      <Typography
                        className="mb-4"
                        style={{ color: 'rgba(51, 51, 51, 1)' }}
                      >
                        {userDetails.name}
                      </Typography>
                    </>
                  )}
                <Typography className="mb-1.5">
                  App Username/Email
                  <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>
                    *
                  </span>
                </Typography>
                <TextInput
                  name="appUsername"
                  autoComplete="new-name"
                  placeholder="Enter app username/email"
                  onChange={(e) => {
                    setFieldValue('appUsername', e);
                  }}
                  value={values.appUsername}
                  error={touched.appUsername && errors.appUsername}
                  helperText={
                    touched.appUsername &&
                    errors.appUsername && (
                      <span className="text-xs" style={{ color: '#d32f2f' }}>
                        {errors.appUsername}
                      </span>
                    )
                  }
                />
                <Typography className="mb-1.5">
                  App Password
                  <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>
                    *
                  </span>
                </Typography>
                <TextInput
                  name="appPassword"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Enter Password"
                  onChange={(e) => setFieldValue('appPassword', e)}
                  value={values.appPassword}
                  error={touched.appPassword && errors.appPassword}
                  helperText={
                    touched.appPassword &&
                    errors.appPassword && (
                      <span className="text-xs" style={{ color: '#d32f2f' }}>
                        {errors.appPassword}
                      </span>
                    )
                  }
                />
                {userPasswordMetadata.length ? renderConditionalFields() : null}
              </div>
              <div className="flex justify-between mt-4">
                <NCButton onClick={() => closeModalHandler()}>Cancel</NCButton>
                <NCButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={
                    createApplicationUserMutation.isLoading ||
                    updateApplicationUserMutation.isLoading
                  }
                  disabled={
                    createApplicationUserMutation.isLoading ||
                    updateApplicationUserMutation.isLoading
                  }
                  style={{ padding: '0.5rem 1.5rem', fontWeight: 400, fontSize: '0.875rem' }}
                  sx={{
                    '&.MuiButton-root': {
                      'backgroundColor': '#009791',
                      'color': '#fff',
                      '&:hover': {
                        backgroundColor: '#006965'
                      }
                    }
                  }}
                  onClick={handleSubmit}
                >
                  {isNew ? 'Add User' : 'Save Changes'}
                </NCButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default AddUpdateUserDetails;
