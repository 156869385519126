import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@nucleos/core-ui';
import moment from 'moment';

import PrevIcon from '../../../Assets/PrevIcon.svg';
import NextIcon from '../../../Assets/NextIcon.svg';

const ITEMS_PER_PAGE = 7;

export const DateSelector = (props) => {
  const { dateRange, selectedDate, handleDateSelect } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const [visibleDatesList, totalPages] = useMemo(() => {
    console.log('dateRange currentPage: ', currentPage);
    let offset = (currentPage - 1) * ITEMS_PER_PAGE;

    console.log('dateRange offset: ', offset);

    if (offset + ITEMS_PER_PAGE > dateRange.length) {
      offset = offset - (offset + ITEMS_PER_PAGE - dateRange.length);
    }

    console.log('dateRange offset after: ', offset);

    const visibleDatesList = dateRange.slice(offset, offset + ITEMS_PER_PAGE);

    console.log('dateRange List: ', visibleDatesList);

    const totalPages = Math.ceil(dateRange.length / ITEMS_PER_PAGE);

    return [visibleDatesList, totalPages];
  }, [currentPage, dateRange]);

  const nextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  const prevPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  useEffect(() => {
    console.log('dateRange: ', dateRange);

    setCurrentPage(Math.ceil(dateRange.length / ITEMS_PER_PAGE));
  }, [dateRange]);

  return (
    <div className='date-selector flex gap-x-4 justify-center items-center'>
      {
        totalPages > 1
          ? (
            <Button
              className="date-selector-option"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <img src={PrevIcon} />
            </Button>
          )
          : null
      }

      {
        visibleDatesList.map((date) => (
          <Button
            className={`date-selector-option ${date === selectedDate ? 'selected' : ''}`}
            key={new Date(date).valueOf()}
            onClick={() => handleDateSelect(date)}
          >
            <div className='flex flex-col justify-center items-center'>
              <Typography variant='mini'>
                {moment(date).format('ddd')}
              </Typography>
              <Typography variant='h6'>
                {moment(date).format('DD')}
              </Typography>
              <Typography variant='subtitle2'>
                {moment(date).format('MMM')}
              </Typography>
            </div>
          </Button>
        ))
      }

      {
        totalPages > 1
          ? (
            <Button
              className="date-selector-option"
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              <img src={NextIcon} />
            </Button>
          )
          : null
      }
    </div>
  );
};
