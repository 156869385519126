import React from 'react';
import { Card, Typography } from '@nucleos/core-ui';
import DoughnutChart from '../../../Components/Chart/DoughnutChart/DoughnutChart';
import { useQuery } from 'react-query';
import Data from '../../../Middleware/Data';
import { QueryKeys } from '../../../Lib/query-keys';
import { useHistory } from 'react-router-dom';
import { useGroupsLearningRecordContext } from '../GroupLearningRecordContext';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import { GenericNoDataFound } from '../../../Components/ErrorStates/GenericNoDataFound';
import { COLORSET } from '../../../Components/Chart/helpers';

export default function AggregateAppUsage () {
  const history = useHistory();
  const groupId = new URLSearchParams(history.location.search).get('groupId');
  const GLRContext = useGroupsLearningRecordContext();

  const requestData = {
    groupId,
    type: 'all_group_records_count_by_category',
    apps: GLRContext.apps,
    from: GLRContext.timePeriod.split('|')[1],
    to: GLRContext.timePeriod.split('|')[2]
  };
  const aggregateAppUsageQuery = useQuery(
    QueryKeys.GroupLearningRecords.listing(requestData),
    () => Data.getGroupLearningRecordReportData(requestData)
  );

  const aggregateAppUsageData = aggregateAppUsageQuery.data
    ? aggregateAppUsageQuery.data.sort(
      (a, b) => b.totalSeconds - a.totalSeconds
    )
    : [];

  const hasNoData =
    aggregateAppUsageData.length === 0 && aggregateAppUsageQuery.isSuccess;

  return (
    <div className="h-full">
      <Card className="rounded-xl bg-white h-full">
        <Card.Header style={{ border: 'none' }}>
          <Typography
            className="py-1.5 ml-2"
            variant="h4"
            style={{ fontWeight: 700, fontSize: '18px' }}
          >
            Aggregate App Usage
          </Typography>
        </Card.Header>
        <Card.Body style={{ padding: 0 }}>
          <LoadingAndErrorHandler
            isLoading={aggregateAppUsageQuery.isFetching}
            isError={aggregateAppUsageQuery.isError}
            isSuccess={aggregateAppUsageQuery.isSuccess}
          >
            {hasNoData
              ? (
                <div className="flex justify-center items-center h-52">
                  <GenericNoDataFound size="small" />
                </div>
              )
              : (
                <DoughnutChart
                  disableScrollInLegend={GLRContext.isPDFMode}
                  data={{
                    labels: aggregateAppUsageData.map((data) => data.category),
                    datasets: [
                      {
                        data: aggregateAppUsageData.map((data) =>
                          Number(
                            (Math.floor(data.totalSeconds) / 60 / 60).toFixed(2)
                          )
                        ),
                        backgroundColor: aggregateAppUsageData.map((data, index) =>
                          COLORSET[index % COLORSET.length]
                        ),
                        hoverBackgroundColor: aggregateAppUsageData.map((data, index) =>
                          COLORSET[index % COLORSET.length]
                        )
                      }
                    ]
                  }}
                />
              )}
          </LoadingAndErrorHandler>
        </Card.Body>
      </Card>
    </div>
  );
}
