import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  TextInput,
  Typography,
  Button,
  MultiSelectDropDown
} from '@nucleos/core-ui';

import Data from '../../../Middleware/Data';
import InfoOutlined from '../../../Assets/info-outline.svg';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import { Icon } from '../../../Components/Shared/Icon';
import { usePaginatedQuery } from '../../../hooks/usePaginatedQuery';
import { tableFilterLexicographicalSorter } from '../../../Lib/util';

const createValidationSchema = (formFields, isNew) => {
  const shape = formFields.reduce((acc, field) => {
    acc[field.key] = yup.string().required(`${field.label} is required`);
    return acc;
  }, {});

  return yup.object().shape({
    ...(isNew && {
      UserUid: yup.string().required('Please select a user'),
      CourseId: yup.string().required('Please select a course')
    }),
    ...shape
  });
};

const AddOrEditLicense = ({
  action,
  licenseDetails = {},
  applicationUid,
  formFields,
  onClose
}) => {
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [courseSearchTerm, setCourseSearchTerm] = useState('');

  const validationSchema = createValidationSchema(formFields);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      UserUid: licenseDetails.UserUid || '',
      CourseId: licenseDetails.courseId || '',
      ...formFields.reduce((acc, field) => {
        acc[field.key] = licenseDetails[field.key] || '';
        return acc;
      }, {})
    }
  });

  const isNew = action === 'ADD';

  const closeModalHandler = (refetch) => {
    form.reset();
    onClose(refetch);
  };

  const userListQuery = usePaginatedQuery(
    ['USERS_LIST', userSearchTerm],
    ({ pageParam = {} }) => {
      return Data.getUsers({
        query: userSearchTerm,
        page: pageParam.page ? pageParam.page - 1 : 0,
        roleType: 'LEARNER',
        limit: 10
      });
    },
    {
      select: (data) => {
        return {
          pages: data.pages.map((r) => ({
            title: `${r.firstName} ${r.lastName} (${r.username})`,
            value: r.uid
          }))
        };
      },
      listKeyName: 'users',
      placeholderData: { data: { pages: [] } }
    }
  );

  const courseListQuery = useQuery(
    ['COURSE_LIST'],
    () =>
      Data.getCourses(applicationUid, true),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: []
    }
  );

  const filteredCourses = (courseListQuery.data || []).filter(course =>
    course.title.toLowerCase().includes(courseSearchTerm.toLowerCase())
  ).map(course => ({
    title: course.title,
    value: course.id
  }));

  const assignLicenseMutation = useMutation((data) => Data.assignCourseLicense(data).then(GenericErrorDetectorForMutations));

  const updateLicenseMutation = useMutation((data) => Data.updateCourseLicense(data).then(GenericErrorDetectorForMutations));

  const onSubmitHandler = async (data) => {
    if (isNew) {
      const { UserUid, CourseId, ...restFields } = data;
      const payload = {
        UserUid,
        CourseId,
        ApplicationUid: applicationUid,
        license: { ...restFields }
      };
      assignLicenseMutation.mutate(payload,
        {
          onSuccess: () => {
            closeModalHandler(true);
            toast.success('License assigned successfully.');
          },
          onError: (error) => toast.error(error.message)
        }
      );
    } else {
      const { UserUid, CourseId, ...restFields } = data;
      const payload = {
        licenseId: licenseDetails.licenseId,
        license: { ...restFields }
      };
      updateLicenseMutation.mutate(payload,
        {
          onSuccess: () => {
            closeModalHandler(true);
            toast.success('License updated successfully.');
          },
          onError: () => toast.error('Failed to update license.')
        });
    }
  };

  return (
    <Grid style={{ width: window.screen.width * 0.5 }}>
      <div className="nucleos-core">
        <div className="p-5 rounded-lg" style={{ background: '#FFFFFF' }}>
          <div
            className="flex justify-between items-center pb-3"
            style={{ borderBottom: '1px solid rgba(51, 51, 51, 0.2)' }}
          >
            <div className="text-2xl font-medium">
              {isNew ? 'Assign New License' : 'Edit License Details'}
            </div>
            <div className="flex cursor-pointer" onClick={() => closeModalHandler()}>
              <Icon icon="Close" />
            </div>
          </div>
          <div
            className="mt-4 overflow-scroll"
            style={{ maxHeight: window.screen.height * 0.6 }}
          >
            <form
              className="w-full"
              onSubmit={form.handleSubmit(onSubmitHandler)}
              style={{ minHeight: '20rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <div>
                <Typography>Nucleos Username <span className="text-red-600 pl-1">*</span></Typography>
                {isNew
                  ? <Controller
                    name="UserUid"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <MultiSelectDropDown
                        fullWidth
                        disableMultiSelect
                        disableWidthLimit
                        label="Select"
                        value={field.value ? userListQuery.data.pages.filter(op => op.value === field.value) : []}
                        onChange={([item]) => field.onChange(item.value)}
                        error={!!fieldState.error}
                        helperText={(fieldState.error || {}).message}
                        disableHelperText={!(fieldState.error || {}).message}
                        options={((userListQuery.data || {}).pages || []).sort(
                          tableFilterLexicographicalSorter
                        )}
                        searchable
                        isSearchLoading={userListQuery.isFetching}
                        onSearchChange={setUserSearchTerm}
                        fetchNextOptionsPage={userListQuery.fetchNextPage}
                        isNextPageLoading={userListQuery.isFetching}
                        hasNextOptions={userListQuery.hasNextPage}
                        disabled={!isNew}
                      />
                    )}
                  />
                  : <TextInput
                    disabled
                    disableHelperText
                    value={licenseDetails.learnerName}
                  />
                }
                <Typography className='mt-3'>Course Name <span className="text-red-600 pl-1">*</span></Typography>
                {
                  isNew
                    ? <Controller
                      name="CourseId"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <MultiSelectDropDown
                          fullWidth
                          disableMultiSelect
                          disableWidthLimit
                          label="Select"
                          value={field.value ? filteredCourses.filter(op => op.value === field.value) : []}
                          onChange={([item]) => field.onChange(item.value)}
                          error={!!fieldState.error}
                          helperText={(fieldState.error || {}).message}
                          disableHelperText={!(fieldState.error || {}).message}
                          options={(filteredCourses || []).sort(
                            tableFilterLexicographicalSorter
                          )}
                          searchable
                          onSearchChange={setCourseSearchTerm}
                          disabled={!isNew}
                        />
                      )}
                    />
                    : <TextInput
                      disabled
                      disableHelperText
                      value={licenseDetails.courseName}
                    />
                }
                {formFields.map((field) => (
                  <div key={field.key} className='mt-3'>
                    <Typography className="mb-1.5">
                      {field.label}
                      <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>*</span>
                    </Typography>
                    <Controller
                      name={field.key}
                      control={form.control}
                      render={({ field: formField, fieldState }) => (
                        <TextInput
                          {...formField}
                          placeholder={`Enter ${field.label}`}
                          label={field.label}
                          error={!!fieldState.error}
                          helperText={(fieldState.error || {}).message}
                          disableHelperText={!fieldState.error}
                          fullWidth
                        />
                      )}
                    />
                  </div>
                ))}
                <div className="my-6 flex items-center">
                  <img src={InfoOutlined} alt="info" />
                  <Typography className="ml-2">
                    It may take approximately 1-2 hours to onboard the users.
                  </Typography>
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <Button onClick={() => closeModalHandler()}>Cancel</Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={
                    assignLicenseMutation.isLoading ||
                    updateLicenseMutation.isLoading
                  }
                  disabled={
                    assignLicenseMutation.isLoading ||
                    updateLicenseMutation.isLoading
                  }
                  style={{ padding: '0.5rem 1.5rem', fontWeight: 400, fontSize: '0.875rem' }}
                  sx={{
                    '&.MuiButton-root': {
                      'backgroundColor': '#009791',
                      'color': '#fff',
                      '&:hover': {
                        backgroundColor: '#006965'
                      }
                    }
                  }}
                >
                  {isNew ? 'Assign License' : 'Save Changes'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default AddOrEditLicense;
