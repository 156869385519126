import React from 'react';
import { Redirect, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthenticationStore from '../../Stores/Authentication';
import { ADD_GRIEVANCE_PATH, GRIEVANCE_PATH, GRIEVANCE_REQUEST_REPORT, GRIEVANCE_SETTINGS, HOME_PATH, RESPONSES_GRIEVANCE_PATH, VIEW_GRIEVANCE_PATH } from '../../constants/paths';
import { useGrievanceRole } from '../../hooks/useGrievancePermissions';

const grievanceRoute = [GRIEVANCE_PATH, GRIEVANCE_REQUEST_REPORT, VIEW_GRIEVANCE_PATH, ADD_GRIEVANCE_PATH, GRIEVANCE_SETTINGS, RESPONSES_GRIEVANCE_PATH];

const RoleGuard = ({ children, roles }) => {
  const grievanceRole = useGrievanceRole();
  const pathname = window.location.pathname;
  const isGrievanceRoute = !!grievanceRoute.find((path) => !!matchPath(pathname, { path, exact: true }));

  if (isGrievanceRoute) {
    if (grievanceRole.isLoading) {
      return null;
    } else if (grievanceRole.canGoToGrievances()) {
      return children;
    } else {
      return <Redirect to={HOME_PATH} />;
    }
  }

  if (roles && roles.length && !roles.includes(AuthenticationStore.fullUserRole)) {
    if (!AuthenticationStore.userRole) { return <Redirect to='/logout' />; }

    if (AuthenticationStore.userRole && AuthenticationStore.userRole.includes('forms')) {
      return <Redirect to='/forms/admin' />;
    }

    return <Redirect to='/home' />;
  }

  return (
    <>
      {/* {grievanceRole.isLoading && !AuthenticationStore.uid ? null : children}
       */}
      {children}
    </>
  );
};

RoleGuard.propTypes = {
  children: PropTypes.node
};

export default RoleGuard;
