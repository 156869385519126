import React from 'react';
import { useMutation } from 'react-query';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import { TextInput, Typography, Button } from '@nucleos/core-ui';

import Data from '../../../Middleware/Data';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import { validateString } from '../../../Components/Form';
import InfoOutlined from '../../../Assets/info-outline.svg';
import { Icon } from '../../../Components/Shared/Icon';

const UpdateAppPassword = ({ appDetails, userDisplayName, onClose }) => {
  const { userPasswordMetadata } = appDetails;

  const updateApplicationUserMutation = useMutation(
    (data) =>
      Data.updateApplicationUser(data).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        closeModalHandler(true);
        toast.success('User details updated successfully.');
      },
      onError: (err) => toast.error(err.message)
    }
  );

  const validate = (values) => {
    const { name, username, password } = values;
    const nameError = !name.trim() ? 'Required' : undefined;
    const usernameError = validateString(username, true, 2, 255);
    const passwordError = validateString(password, true, 2, 255);
    const errors = {};
    if (nameError) { errors.name = nameError; }
    if (usernameError) { errors.username = usernameError; }
    if (passwordError) { errors.password = passwordError; }
    userPasswordMetadata.forEach((obj) => {
      const objError = !values[obj.key] || !values[obj.key].trim() ? 'Required' : undefined;
      if (objError) { errors[obj.key] = objError; }
    });

    return errors;
  };

  const onSubmit = (values) => {
    const { appUserId, username, password, uid } = values;

    const data = {
      uid,
      appUserId,
      username,
      password
    };

    userPasswordMetadata.forEach((metadata) => {
      data[metadata.key] = values[metadata.key];
    });

    updateApplicationUserMutation.mutate(data);
  };

  const formik = useFormik({
    initialValues: appDetails,
    validate,
    onSubmit
  });

  const { values, errors, touched, handleSubmit, setFieldValue, resetForm } =
    formik;

  const closeModalHandler = (reload = false) => {
    resetForm();
    onClose(reload);
  };

  const renderConditionalFields = () => {
    return userPasswordMetadata.map((metadata) => (
      <div key={metadata.key}>
        <Typography className="mb-1.5">
          {metadata.label}
          <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>
            *
          </span>
        </Typography>
        <TextInput
          name={metadata.key}
          autoComplete="new-name"
          placeholder={`Enter ${metadata.label}`}
          onChange={(e) => setFieldValue(metadata.key, e)}
          value={values[metadata.key]}
          error={touched[metadata.key] && errors[metadata.key]}
          helperText={
            touched[metadata.key] &&
            errors[metadata.key] && (
              <span className="text-xs" style={{ color: '#d32f2f' }}>
                {errors[metadata.key]}
              </span>
            )
          }
        />
      </div>
    ));
  };

  return (
    <Grid style={{ width: window.screen.width * 0.5 }}>
      <div className="nucleos-core">
        <div className="p-5 rounded-lg" style={{ background: '#FFFFFF' }}>
          <div
            className="flex justify-between items-center pb-3"
            style={{ borderBottom: '1px solid rgba(51, 51, 51, 0.2)' }}
          >
            <div className="text-2xl font-medium">
              Change Application Details
            </div>
            <div
              className="flex cursor-pointer"
              onClick={() => closeModalHandler()}
            >
              <Icon icon="Close" />
            </div>
          </div>
          <div
            className="mt-4 overflow-scroll"
            style={{ maxHeight: window.screen.height * 0.6 }}
          >
            <form method="post" onSubmit={handleSubmit} autoComplete="off">
              <div className="mb-6 flex items-center">
                <img src={InfoOutlined} alt="info" />
                <Typography className="ml-2">
                  Please ensure the login details are for a valid user account
                  for
                  <span style={{ color: '#333', fontWeight: '600' }}>
                    &nbsp;
                    {appDetails.name}
                  </span>
                </Typography>
              </div>
              <div className="w-2/3">
                <>
                  <Typography className="mb-1.5">Name</Typography>
                  <Typography
                    className="mb-4"
                    style={{ color: 'rgba(51, 51, 51, 1)' }}
                  >
                    {userDisplayName}
                  </Typography>
                </>

                <Typography className="mb-1.5">
                  App Username
                  <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>
                    *
                  </span>
                </Typography>
                <TextInput
                  name="username"
                  autoComplete="new-name"
                  placeholder="Enter app username/email"
                  onChange={(e) => {
                    setFieldValue('username', e);
                  }}
                  value={values.username}
                  error={touched.username && errors.username}
                  helperText={
                    touched.username &&
                    errors.username && (
                      <span className="text-xs" style={{ color: '#d32f2f' }}>
                        {errors.username}
                      </span>
                    )
                  }
                />
                <Typography className="mb-1.5">
                  App Password
                  <span className="text-xs ml-1" style={{ color: '#d32f2f' }}>
                    *
                  </span>
                </Typography>
                <TextInput
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  placeholder="Enter Password"
                  onChange={(e) => setFieldValue('password', e)}
                  value={values.password}
                  error={touched.password && errors.password}
                  helperText={
                    touched.password &&
                    errors.password && (
                      <span className="text-xs" style={{ color: '#d32f2f' }}>
                        {errors.password}
                      </span>
                    )
                  }
                />

                {userPasswordMetadata.length ? renderConditionalFields() : null}
              </div>
              <div className="flex justify-between mt-4">
                <Button onClick={() => closeModalHandler()}>Cancel</Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={updateApplicationUserMutation.isLoading}
                  disabled={updateApplicationUserMutation.isLoading}
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default UpdateAppPassword;
