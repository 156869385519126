import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, ButtonGroup, Modal, DialogContent } from '@mui/material';
import { SearchInput } from '@nucleos/core-ui';
import { Icon } from '../../../Components/Shared/Icon';
import PendingApproval from './PendingApproval';
import ApprovedLicenses from './ApprovedLicenses';
import AddOrEditLicense from './AddOrEditLicense';

const TAB_KEYS = {
  Pending: 'pending',
  Approved: 'approved'
};

const ManageLicenses = ({ applicationUid, licenseMetadata }) => {
  const history = useHistory();
  const [showAddOrEditLicenseModal, setShowAddOrEditLicenseModal] =
    useState(false);
  const [refetchApprovedLicensesData, setRefetchApprovedLicensesData] =
    useState(false);
  const [activeTab, setActiveTab] = useState(
    history.location.search.includes('approved-licenses')
      ? TAB_KEYS.Approved
      : TAB_KEYS.Pending
  );
  const [searchTerm, setSearchTerm] = useState('');

  const onAssignLicense = () => {
    // Assign license logic here
    setShowAddOrEditLicenseModal(true);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    history.replace({ search: '' });
  };

  const getButtonStyles = (tab) => (theme) => ({
    backgroundColor: activeTab === tab ? '#E9F0FF' : theme.palette.white.main,
    color: theme.palette.getContrastText(
      activeTab === tab
        ? theme.palette.secondary.light
        : theme.palette.white.main
    ),
    px: 2
  });

  const renderTabButton = (label, tab) => (
    <Button
      onClick={() => handleTabChange(tab)}
      sx={getButtonStyles(tab)}
      startIcon={
        activeTab === tab ? <Icon icon="Check" color="success" /> : null
      }
    >
      {label}
    </Button>
  );

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <ButtonGroup
          size="small"
          color="secondary"
          variant="outlined"
          sx={{ height: '2.3rem' }}
        >
          {renderTabButton('Pending for Approval', TAB_KEYS.Pending)}
          {renderTabButton('Approved Licenses', TAB_KEYS.Approved)}
        </ButtonGroup>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <SearchInput
            placeholder="Search"
            style={{ width: '240px' }}
            onSearch={setSearchTerm}
          />
          <Button
            variant="contained"
            onClick={onAssignLicense}
            startIcon={<Icon icon="Add" />}
          >
            Assign License
          </Button>
        </Box>
      </Box>
      {activeTab === TAB_KEYS.Pending && (
        <PendingApproval
          applicationUid={applicationUid}
          search={searchTerm}
          licenseMetadata={licenseMetadata}
        />
      )}
      {activeTab === TAB_KEYS.Approved && (
        <ApprovedLicenses
          applicationUid={applicationUid}
          search={searchTerm}
          licenseMetadata={licenseMetadata}
          refetchData={refetchApprovedLicensesData}
          resetRefetchData={() => setRefetchApprovedLicensesData(false)}
        />
      )}
      <Modal
        open={showAddOrEditLicenseModal}
        handleClose={() => setShowAddOrEditLicenseModal(false)}
        className="nucleos-core"
      >
        <DialogContent className="h-full flex justify-center items-center">
          <AddOrEditLicense
            action="ADD"
            applicationUid={applicationUid}
            formFields={licenseMetadata.fields || []}
            onClose={(refetch) => {
              setShowAddOrEditLicenseModal(false);
              if (refetch) {
                setRefetchApprovedLicensesData(true);
              }
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default ManageLicenses;
