import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';
import LoadingComponent from '../../Components/Widgets/LoadingComponent';
import { mapCourseProgressData } from '../../Lib/mapCourseProgressData';
import defaultIcon from '../../Assets/course-icon-default.svg';
import NoActivity from '../../Assets/graphics/no_activity.svg';
import { Typography } from '@nucleos/core-ui';
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery';
import Data from '../../Middleware/Data';
import AuthenticationStore from '../../Stores/Authentication';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ScrollBottomLoading from '../../Components/Shared/ScrollBottomLoading';
import { useAppContext } from '../../Contexts/AppContext';
import { COURSE_CONTENT_LIBRARY_PATH } from '../../constants/paths';

const recordPropTypes = {
  courseProgress: PropTypes.shape({
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    icon: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    lastActivity: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    displayName: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['COMPLETED', 'STARTED', 'INACTIVE', 'NOT_STARTED', 'LOCKED', 'WITHDRAWN']),
    updatedAt: PropTypes.string
  }).isRequired
};

const NoRecordsFound = () => {
  return (
    <div className='nucleos-core'>
      <div className='mt-6 rounded-lg flex justify-center items-center py-6 flex-col'>
        <div>
          <img src={NoActivity} />
        </div>
        <div className='mt-3'><Typography className='font-bold text-xl' style={{ color: '#19223D' }} >Sorry! No learning records yet.</Typography></div>
        <div className='mt-1'><Typography className='font-normal text-sm' style={{ color: 'rgba(51, 51, 51, 0.7)' }}>It seems that no significant learning progress has been made at this time.</Typography></div>
      </div>
    </div>
  );
};

export const Record = props => {
  const { courseProgress: { icon, displayName, updatedAt, lastActivity, ApplicationUid, logo }, showAppLink = true } = props;

  const link = `${window.location.origin}${COURSE_CONTENT_LIBRARY_PATH}/${ApplicationUid}`;

  const dateString = new Date(lastActivity || updatedAt);
  return !displayName
    ? null
    : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={logo || icon || defaultIcon}
            style={{ height: '40px', borderRadius: '40px' }}
            alt={displayName}
          />
          <div className='learning-records__record-detail'>
            <strong className='learning-records__record-name'>
              {' '}
              {displayName}
            </strong>
            {
              showAppLink
                ? (
                  <>
                    <br />
                    <a
                      href={link}
                      className='learning-records__launch-application'
                      style={{ color: '#2D95FF' }}
                    >
                      View Application
                    </a>
                  </>
                )
                : null
            }
          </div>
        </div>
        <p className='learning-records__record-light'>
          {dateString === 'Invalid Date' ? '' : moment(dateString).format('MMM DD, YYYY')}
        </p>
      </div>
    );
};

export const LearningRecordsInfo = ({ applications, user }) => {
  const appContext = useAppContext();

  const {
    firstName,
    lastName,
    createdAt,
    updatedAt
  } = user;
  const learnerName = firstName ? `${firstName} ${lastName}` : '';
  return (
    <div className='learning-records__info-panel'>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <p className='learning-records__info-heading'>{learnerName}</p>
        <small style={{ marginLeft: 'auto' }}> Verified By</small>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <p>{`${moment(createdAt && createdAt).format(
          'MMMM YYYY'
        )} - ${moment(updatedAt && updatedAt).format('MMMM YYYY')}`}
        </p>
        <img style={{ width: '150px', height: '20px', marginLeft: 'auto' }} alt='nucleos logo' src={appContext.logoURL} />
      </div>

      <p className='learning-records__info-heading'>Institutions</p>
      <p>
        {applications}
      </p>
      <p className='learning-records__info-heading'>About</p>
      <p>
        The Nucleos Learning Record provides a verifiable record that the learner listed above has completed the following courses of study. For more information, please see <a style={{ textDecoration: 'underline', color: '#2D95FF' }} href='https://www.record.nucleos.com/about' target='_blank' rel="noopener noreferrer">record.nucleos.com/about</a>.

      </p>
    </div>
  );
};

Record.propTypes = recordPropTypes;

const Records = () => {
  const { uid } = useParams();
  const { uid: loggedInUserId, userRole } = AuthenticationStore;
  const recordsUserUid = userRole === 'learner' ? loggedInUserId : uid;

  const recordsQuery = usePaginatedQuery(
    ['COURSE_PROGRESS', recordsUserUid],
    ({ pageParam = {} }) =>
      Data.getLearningRecords(
        recordsUserUid,
        pageParam.offset || '0',
        pageParam.limit || '10'
      ).then((res) => ({
        ...res,
        CourseProgresses: res.user.CourseProgresses
      })),
    { listKeyName: 'CourseProgresses', enabled: !!recordsUserUid }
  );
  const institutionsQuery = useQuery(
    ['INSTITUTIONS', recordsUserUid],
    () => Data.getInstitutions(recordsUserUid),
    { enabled: !!recordsUserUid }
  );

  const CourseProgresses = recordsQuery.data.pages;

  if (!recordsUserUid) { return null; }

  if (recordsQuery.isLoading || institutionsQuery.isFetching) {
    return (<LoadingComponent />);
  } else if (!CourseProgresses || CourseProgresses.length < 1) {
    return <NoRecordsFound />;
  } else {
    const completed = CourseProgresses.map(mapCourseProgressData).filter(progress => progress !== null);

    const applications = (institutionsQuery.data ? institutionsQuery.data.institutions || [] : []).map(i => i.appName).join(', ');

    return (
      <section>
        <section id='learning-records-info'>
          <section>
            <LearningRecordsInfo applications={applications} user={recordsQuery.data.data[0].user} />
          </section>
        </section>
        <ScrollBottomLoading
          dataCount={completed.length}
          isFetching={recordsQuery.isFetching}
          fetchNextPage={recordsQuery.fetchNextPage}
          hasMore={recordsQuery.hasNextPage}
        >
          <section id='courses-info' style={{ paddingTop: '20px' }}>
            <FlexTileGrid
              gridColTiers={{ xs: 12 }}
              horizontal
              tileStyle={{ padding: '30px' }}
              spacing={0}
              gridItemStyle={{ padding: '10px 0' }}
            >
              {completed.map &&
                completed.map((mappedCourseProgress, index) => {
                  return (
                    <Record
                      key={index}
                      courseProgress={mappedCourseProgress}
                    />
                  );
                })}
            </FlexTileGrid>
          </section>
        </ScrollBottomLoading>
      </section>
    );
  }
};

export default observer(Records);
