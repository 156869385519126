import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box
} from '@mui/material';
import FlexTileGrid from '../../../Components/Shared/TileGrid/TileGrid';
import DataStore from '../../../Stores/DataStore';
import { formatDateTime, getMessageSentFrom, getMessageSentTo } from '../../../Lib/util';
import { PAGE_ROW_LIMIT } from '../../../Lib/CONSTANTS';
import { MESSAGE_ALERTS_PATH, MESSENGER_ADMIN_PATH } from '../../../constants/paths';
import HeaderSubPanel from '../../../Components/HeaderSubPanel';

const breadcrumbLinks = (name) => [
  { href: MESSENGER_ADMIN_PATH, label: 'Messenger Admin' },
  { href: MESSAGE_ALERTS_PATH, label: 'Message Alerts' },
  { href: '#', label: name }
];

const FlaggedMessagesList = () => {
  const [messageAlerts, setMessageAlerts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const { keyword } = useParams();

  const getMessagesForAlertKeyword = async () => {
    try {
      DataStore.setPageTitleDetail(`Messenger Admin > Message Alerts > ${keyword}`);
      const { messages, count } = await DataStore.getMessagesForAlertKeyword(keyword, page);
      setMessageAlerts(messages);
      setTotalRecords(count);
      await markAlertsAsViewed();
    } catch (error) {
      // TODO: console.log(error)
    }
  };

  const markAlertsAsViewed = async () => {
    await DataStore.markMessageAlertsAsViewed(keyword, page);
  };

  useEffect(() => {
    getMessagesForAlertKeyword();
  }, [page]);
  return (
    <Box>
      <HeaderSubPanel sx={{ mb: 3 }} links={breadcrumbLinks(keyword)} title={keyword} />
      <Box sx={{
        boxShadow: '0px 10px 40px 0px rgb(64 72 82 / 7%)',
        width: '80vw',
        margin: 'auto'
      }}>
        <Box sx={{
          backgroundColor: '#E9F0FF',
          height: '51px',
          padding: '15px',
          borderRadius: '8px',
          fontWeight: 600
        }}>
          <p>Alert Keyword: {keyword}</p>
        </Box>
        <section>
          <FlexTileGrid
            gridColTiers={{ xs: 12 }}
            tileStyle={{ padding: 0, maxWidth: '100%', overflow: 'scroll' }}
            className='usage-history__table-container'
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>From</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>To</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>Message</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>Date Sent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messageAlerts.map((row, index) => {
                  const from = getMessageSentFrom(row.Message);
                  const to = getMessageSentTo(row.Message);
                  return <TableRow
                    key={row.id}
                    style={{
                      background: index % 2 ? 'white' : '#f8f8fb'
                    }}
                  >
                    <TableCell style={{ whiteSpace: 'nowrap' }}><Link style={{ color: '#449FFF', textDecoration: 'underline' }} to={`${from.url}`}>{from.name}</Link></TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}><Link style={{ color: '#449FFF', textDecoration: 'underline' }} to={`${to.url}`}>{to.name}</Link></TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{row.Message.message}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>{formatDateTime(row.Message.createdAt)}</TableCell>
                  </TableRow>;
                })}
              </TableBody>
            </Table>
          </FlexTileGrid>
        </section>
      </Box>
      <TablePagination component="div" count={totalRecords} rowsPerPage={PAGE_ROW_LIMIT} page={page} onPageChange={(event, nextPage) => {
        setPage(nextPage);
      }} rowsPerPageOptions={[10]}
      />
    </Box>
  );
};
export default (FlaggedMessagesList);
