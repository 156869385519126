import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Data from '../../Middleware/Data';
import { QueryKeys } from '../../Lib/query-keys';

const initialValue = {
  groupId: null,
  groupQuery: null,
  group: null,
  isLoading: true,
  isSuccess: false,
  isError: false,
  refetchGroup: async () => {}
};

export const GroupContext = React.createContext(initialValue);

export const useGroupContext = () => React.useContext(GroupContext);

export const GroupContextProvider = ({ children }) => {
  const { uid: groupId } = useParams();

  const groupQuery = useQuery(
    QueryKeys.Groups.item(groupId),
    () => Data.getGroup(groupId),
    {
      enabled: !!groupId,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  return (
    <GroupContext.Provider
      value={{
        groupId,
        groupQuery: groupQuery,
        group: groupQuery.data,
        isLoading: groupQuery.isLoading,
        isError: groupQuery.isError,
        isSuccess: groupQuery.isSuccess,
        refetchGroup: groupQuery.refetch
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};
