import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './LoadingComponent.scss';

import { AppContext } from '../../Contexts/AppContext';

@observer
class LoadingComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  componentDidMount () {
    this.interval = setInterval(() => { this.setState({ count: this.state.count + 1 }); }, 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  render () {
    const { small, tillApiResponse, limit, message } = this.props;
    if (small === 'true' && !tillApiResponse) {
      const show = this.state.count < (limit || 4);
      !show && this.interval && clearInterval(this.interval);
      return (
        <div className='wholePage'>
          { show ? <div className='loaderSmall' /> : (message || '') } {(show && message) && ' '}
        </div>
      );
    }

    if (tillApiResponse && small == 'true') {
      return (<div className='wholePage'>
        { <div className='loaderSmall' /> }
      </div>);
    }

    if (this.props.tiny) {
      return <div className='wholePage'>
        <div className='loaderTiny' />
      </div>;
    }

    return (
      <AppContext.Consumer>
        {
          (appDataConfig) => (
            <div className='wholePage'>
              <img alt='' src={appDataConfig.logoURL} className='auth-header__logo' style={{ maxHeight: 36 }} />
              <div className='loader' />
            </div>
          )
        }
      </AppContext.Consumer>
    );
  }
}

export default LoadingComponent;
