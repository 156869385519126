import React, { useState } from 'react';

const initialValue = {
  availabilityError: null,
  dailyLimitError: null,

  setAvailabilityError: (e) => {},
  setDailyLimitError: (e) => {}
};

export const AvailabilityAndDailyLimitCardContext = React.createContext(initialValue);

export const useAvailabilityAndDailyLimitCardContext = () =>
  React.useContext(AvailabilityAndDailyLimitCardContext);

export const AvailabilityAndDailyLimitCardContextProvider = ({ children }) => {
  const [availabilityError, setAvailabilityError] = useState(null);
  const [dailyLimitError, setDailyLimitError] = useState(null);

  return (
    <AvailabilityAndDailyLimitCardContext.Provider
      value={{
        availabilityError,
        dailyLimitError,

        setAvailabilityError,
        setDailyLimitError
      }}
    >
      {children}
    </AvailabilityAndDailyLimitCardContext.Provider>
  );
};
