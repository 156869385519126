import React from 'react';
import { Button, Card, Typography } from '@nucleos/core-ui';

export default function ConfirmGrievanceCategoryChangeCard ({
  onClose,
  onCategoryChange,
  isCategoryChangeLoading,
  requestType,
  initialCategory,
  newCategory
}) {
  return (
    <Card className="w-96 grievance-card mt-2">
      <Card.Header title="Change Category" onClose={onClose} />
      <Card.Body>
        <div className="block pb-2">
          <Typography style={{ display: 'block' }} className="mb-4">
            Are you sure you want to change the category of this {requestType === 'GRIEVANCE' ? 'grievance' : 'action request'} from&nbsp;
            <b>
              {initialCategory}
            </b>
            &nbsp;to&nbsp;
            <b>
              {newCategory}
            </b>
            ? This action will result in the request priority being reset to Low.
          </Typography>
        </div>
        <div className="flex justify-between">
          <Button
            size="small"
            variant="contained"
            color="primary"
            loading={isCategoryChangeLoading}
            onClick={onCategoryChange}
          >
            Confirm
          </Button>
          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
