import React, { useMemo } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import './LearningOverview.scss';
import defaultIcon from '../../Assets/course-icon-default.svg';
import CourseProgressMetrics from './CourseProgressMetrics';
import {
  TimeLine,
  Typography
} from '@nucleos/core-ui';
import NoActivity from '../../Assets/graphics/no_activity.svg';

const NoProgressFound = () => {
  return (
    <div className='nucleos-core'>
      <div className='mt-6 rounded-lg flex justify-center items-center py-6 flex-col'>
        <div>
          <img src={NoActivity} alt = 'No Activity'/>
        </div>
        <div className='mt-3'><Typography className='font-bold text-xl' style={{ color: '#19223D' }} >Sorry! No learning progress yet.</Typography></div>
        <div className='mt-1'><Typography className='font-normal text-sm' style={{ color: 'rgba(51, 51, 51, 0.7)' }}>It seems that no significant learning progress has been made at this time.</Typography></div>
      </div>
    </div>
  );
};

const ProgressItem = props => {
  const { title, updatedAt, courseIcon, createdAt, courseLessons, rawData, metaRawData, courseStatus, applicationName } = props;

  const inProgressCourses = courseLessons && courseLessons.length && courseLessons.filter(cl => {
    if (cl && cl.CourseLessonProgresses && cl.CourseLessonProgresses.length) {
      return !!(cl.CourseLessonProgresses[0] && cl.CourseLessonProgresses[0].status && cl.CourseLessonProgresses[0].status === 'STARTED');
    } else {
      return false;
    }
  });

  return (
    <Grid item xs={12} className='learning-overview__progr ess-container'>
      <div className='learning-overview__progr ess-row'>

        <div className='w-full rounded-lg' style={{ backgroundColor: '#FFFFFF', boxShadow: '0px 10px 40px rgba(64, 72, 82, 0.1)' }}>

          <div className={`flex items-center flex-start  ${courseLessons.length ? 'mb-3.5' : 'pb-4'}`}>
            <div className='mt-4 ml-4 h-11 w-11'>
              <img className='object-contain h-11 w-11' style={{ borderRadius: '50%' }} src={courseIcon || defaultIcon} title={applicationName} alt={applicationName}/>
            </div>
            <div className='ml-3 mt-4 mr-8' style={{ width: '40%' }}>
              <div style={{ color: '#19223D' }} className='text-base'>
                {title}
              </div>
              <div className='progress-date-container text-xs mt-1' style={{ color: 'rgba(51, 51, 51, 0.6)' }} >
                <div>Enrollment: {createdAt ? moment(new Date(createdAt)).format('MM/DD/YYYY') : 'N/A'}</div>
                <div className='h-1 w-1 mx-2 progress-date-dot' style={{ background: '#adadad', borderRadius: '70%' }}></div>
                <div>Last Activity: {updatedAt ? moment(new Date(updatedAt)).format('MM/DD/YYYY') : 'N/A'}</div>
              </div>

            </div>
            <div className='w-3/5 mt-4'>
              <CourseProgressMetrics rawData={rawData} metaRawData={metaRawData} courseStatus={courseStatus} />
            </div>
          </div>
          {courseLessons.length
            ? <div className='flex justify-between  pt-3.5 ml-4 mr-4 pb-5' style={{ borderTop: '1px solid #E4E4E4', color: 'rgba(51, 51, 51, 0.6)' }}>
              <div className='text-xs'>
                Lessons in Progress: <span style={{ color: '#333333' }}>{inProgressCourses.length}</span>
              </div>
            </div>
            : null}

        </div>
      </div>
    </Grid>
  );
};

const UserProgress = (props) => {
  const { userLearningProgress } = props;

  const pi = useMemo(() => userLearningProgress.map((ul, index) => {
    const icon = (ul.Application && (ul.Application.logo || ul.Application.icon)) || ul.icon;
    const metaRawData = ul.metaRawData;
    const rawData = ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && ul.CourseProgresses[0].rawData;
    const courseStatus = ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && ul.CourseProgresses[0].status;
    const applicationUid = ul.ApplicationUid;
    const applicationName = ul.Application && ul.Application.name;
    const updatedAt = ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && ((ul.CourseProgresses[0].rawData && ul.CourseProgresses[0].rawData.lastActivity) || ul.CourseProgresses[0].progressUpdatedAt);
    return {
      renderLabel: () => (
        <div className='w-32 whitespace-nowrap flex justify-start'>
          <div className='relative top-2.5'>
            <Typography className='text-sm font-normal' style={{ color: 'rgba(51, 51, 51, 0.7)' }} >{updatedAt ? moment(new Date(updatedAt)).format('MMM DD, YYYY') : 'Not Available'}</Typography>
          </div>
        </div>
      ),
      renderValue: () => (<div className='mb-5 w-full avoid-element-break' style={{ border: '1px solid #E9F0FF', borderRadius: '5px' }}> <ProgressItem
        key={`progress-item-${ul.applicationUid}-${index}`}
        title={ul.title}
        updatedAt={updatedAt}
        createdAt={ul.CourseProgresses && ul.CourseProgresses.length && ul.CourseProgresses[0] && (ul.CourseProgresses[0].enrolledAt || ul.CourseProgresses[0].createdAt)}
        courseIcon={icon}
        courseLessons={ul.CourseLessons}
        courseId={ul.id}
        metaRawData={metaRawData}
        rawData={rawData}
        courseStatus={courseStatus}
        applicationUid={applicationUid}
        applicationName={applicationName}
      /> </div>)
    };
  }));

  if (!userLearningProgress) {
    return (
      <div className='flex justify-center'>
        ...loading
      </div>
    );
  } else {
    return (
      <div className='nucleos-core m-6'>
        { pi.length === 0
          ? <NoProgressFound />
          : <div className='mt-6'>
            <div className='flex flex-col'>
              <TimeLine
                items={[
                  ...pi
                ]}
              />
            </div>
          </div>}
      </div>
    );
  }
};

export default UserProgress;
