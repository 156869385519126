import React from 'react';
import { observer } from 'mobx-react';
import Records from './Records';
import './LearnerRecords.scss';
import { SetPageTitleFromUsername } from '../../Lib/SetPageTitleFromUsername';
import ScrollToTop from '../../Components/ScrollToTop';

const LearningRecords = () => {
  // const data = Object.values(userUsageTotals.map(({ application, day, seconds }) => {
  //   return { day, [application]: Math.round(seconds / 60) }
  // }).reduce((acc, val) => {
  //   return { ...acc, [val.day]: { ...(acc[val.day] || {}), ...val } }
  // }, {}))

  return (
    <React.Fragment>
      <SetPageTitleFromUsername />
      <ScrollToTop />
      <div className='learning-records'>
        <div>
          <Records />
        </div>
      </div>
    </React.Fragment>
  );
};
export default observer(LearningRecords);
