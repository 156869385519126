import { Typography } from '@nucleos/core-ui';
import React from 'react';

export default function GrievanceTypeChip ({ requestType, style, className }) {
  return (
    <div
      className={'px-2 py-1 border rounded-full ' + className}
      style={{
        borderColor: requestType === 'GRIEVANCE' ? '#F44336' : '#F48C06',
        color: requestType === 'GRIEVANCE' ? '#F44336' : '#F48C06',
        ...style
      }}
    >
      <Typography variant="body2" color="none" className="justify-center">
        {requestType === 'GRIEVANCE' ? 'Grievance' : 'Action Request'}
      </Typography>
    </div>
  );
}
