import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '@nucleos/core-ui';

import FlexTileGrid from '../../Components/Shared/TileGrid/TileGrid';

import CourseContentLibraryIcon from '../../Assets/icons/course_content_library.svg';
import UsersGroupsListIcon from '../../Assets/icons/users_groups_list.svg';
import LearningRecordsIcon from '../../Assets/icons/learning_records.svg';
import InvestigativeToolsetIcon from '../../Assets/icons/investigative_toolset.svg';
import GrievancesIcon from '../../Assets/icons/grievances.svg';
import AccountSettingsIcon from '../../Assets/icons/account_settings.svg';
import MessagesIcon from '../../Assets/icons/messenger_dashboard.svg';
import useUserPermissions from '../../hooks/useUserPermissions';
import { useGrievanceRole } from '../../hooks/useGrievancePermissions';
import { ACCOUNT_SETTINGS_PATH, COURSE_CONTENT_LIBRARY_PATH, GRIEVANCE_PATH, INVESTIGATIVE_PATH, LEARNING_RECORDS_PATH, MESSENGER_ADMIN_PATH, USER_PATH } from '../../constants/paths';
import { ACTIVE_ROLES } from '../../Lib/CONSTANTS';

const adminFeatures = [
  {
    name: 'Course & Content Library',
    icon: CourseContentLibraryIcon,
    link: COURSE_CONTENT_LIBRARY_PATH
  },
  {
    name: 'View Users & Groups',
    icon: UsersGroupsListIcon,
    link: USER_PATH
  },
  {
    name: 'Learner Records',
    icon: LearningRecordsIcon,
    link: LEARNING_RECORDS_PATH
  },
  {
    name: 'Investigative Toolset',
    icon: InvestigativeToolsetIcon,
    link: INVESTIGATIVE_PATH
  },
  {
    name: 'Grievances and Forms',
    icon: GrievancesIcon,
    link: GRIEVANCE_PATH
  },
  {
    name: 'Messages',
    icon: MessagesIcon,
    link: MESSENGER_ADMIN_PATH
  },
  {
    name: 'Settings',
    icon: AccountSettingsIcon,
    link: ACCOUNT_SETTINGS_PATH
  }
];

function AdminHome () {
  const userPermissions = useUserPermissions();
  const grievanceRole = useGrievanceRole();

  if (grievanceRole.isLoading) {
    return (
      <div className="nucleos-core">
        <div className='h-96 flex justify-center items-center'>
          <LoadingSpinner size={12} />
        </div>
      </div>
    );
  }

  const featureFlags = {
    'Investigative Toolset': grievanceRole.canGoToInvestigativeTools(),
    'Grievances and Forms': grievanceRole.canGoToGrievances(),
    'Learner Records': userPermissions.canViewLearningRecords(),
    'View Users & Groups': [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER].includes(userPermissions.role),
    'Course & Content Library': [ACTIVE_ROLES.SUPER_ADMIN, ACTIVE_ROLES.STAFF_ADMIN, ACTIVE_ROLES.EXTERNAL_PARTNER].includes(userPermissions.role),
    'Messages': [ACTIVE_ROLES.INVESTIGATOR, ACTIVE_ROLES.MESSENGER].includes(userPermissions.role)
  };

  const features = adminFeatures
    .filter(({ name }) =>
      featureFlags[name] === undefined ? true : featureFlags[name]
    )
    .map(({ name, icon, link }, i) => (
      <Link to={link} key={i}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: '',
            justifyContent: 'space-around'
          }}
        >
          <p style={{ color: '#3899FF', display: 'inline' }}>
            <img src={icon} alt="" />
          </p>
          <h2 style={{ paddingLeft: '25px', fontSize: '18px' }}> {name} </h2>
        </div>
      </Link>
    ));

  return (
    <div className='home'>
      <FlexTileGrid tileStyle={{ padding: '16px' }}>{features}</FlexTileGrid>
    </div>
  );
}

export default AdminHome;
