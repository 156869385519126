import React from 'react';
import UAParser from 'ua-parser-js';
import { confirmAlert } from 'react-confirm-alert';

import { Typography, Button } from '@nucleos/core-ui';
import { ArrowForwardIos } from '@mui/icons-material';

import AuthenticationStore from '../../Stores/Authentication';
import ChromeWebStoreImg from '../../Assets/graphics/chrome_web_store.svg';
import GoogleChromeIcon from '../../Assets/icons/google_chrome_icon.svg';

function ExtensionPrompt ({ onPromptClosed = () => null }) {
  const parser = new UAParser(navigator.userAgent);
  const browser = parser.getBrowser();
  const chromeAgent = browser.name === 'Chrome';
  const consentStatus = localStorage.getItem('nucleos__consentStatus');

  if (!window.nucleosConfig.isPublicExtension) {
    return null;
  }
  if (JSON.parse(localStorage.getItem('extensionPromptClosed')) || window.nucleosExtension) {
    return null;
  }
  if (!window.nucleosExtension && AuthenticationStore.isAuthenticated && AuthenticationStore.userRole === 'learner') {
    if (consentStatus === 'false') {
      return null;
    }
    return confirmAlert({
      customUI: ({ onClose }) => (
        <div className="nucleos-core">
          <div className={`mx-auto my-0 ${chromeAgent ? 'w-9/12' : 'w-6/12'}`}>
            <div
              className="flex flex-col justify-start p-6 rounded-md gap-4"
              style={{ background: '#ffffff' }}
            >
              <Typography variant='h2' style={{ color: '#18162F', fontWeight: 800 }}>
                {chromeAgent ? 'Install AchieveDXP - Learner Experience Chrome Extension' : 'Maximize Your AchieveDXP Experience with Chrome'}
              </Typography>
              {chromeAgent
                ? (
                  <div className='flex flex-col gap-4 overflow-y-auto' style={{ maxHeight: '75vh' }}>
                    <img src={ChromeWebStoreImg} alt="AchieveDXP Extension" />
                    <div className='flex flex-col gap-2'>
                      <Typography variant='h1'>Benefits of Installing AchieveDXP Chrome Extension</Typography>
                      <ul className="px-6" style={{ listStyle: 'disc' }}>
                        <li>The AchieveDXP extension enables automatic login to all connected learning apps and services from your institution, providing a smooth and efficient user experience.</li>
                        <li>The extension increases security by implementing advanced protection measures, ensuring your data remains safe and secure.</li>
                        <li>Gain valuable insights with built-in analytics features, allowing you to track your progress and performance effectively.</li>
                      </ul>
                    </div>
                  </div>
                )
                : (
                  <>
                    <Typography variant='h4' style={{ fontWeight: 'normal' }}>Want the full capabilities of AchieveDXP? Password management and learning app time spent tracking and goal-setting are available in the AchieveDXP Chrome extension for use on the Chrome browser. Please use Chrome to access these full features.</Typography>
                    <Typography className='py-4 justify-center'>If you don’t have the Chrome browser, please download it from the link below:</Typography>
                  </>
                )}
              <div className="flex flex-col gap-2 items-center">
                <Button
                  variant='contained'
                  color='primary'
                  style={{ borderRadius: '1rem' }}
                  onClick={() => {
                    if (chromeAgent) {
                      window.open(`https://chromewebstore.google.com/detail/achieve-dxp-learner-exper/${window.nucleosConfig.extensionId}`);
                    } else {
                      window.open('https://www.google.com/chrome/');
                    }
                    localStorage.setItem('extensionPromptClosed', true);
                    onClose();
                    onPromptClosed();
                  }}
                >
                  <img src={GoogleChromeIcon} alt="Google Chrome" />
                  <span className='pl-2 pr-12'>{chromeAgent ? 'Add to Chrome' : 'Download Chrome'}</span>
                  <ArrowForwardIos />
                </Button>
                <Button
                  color='primary'
                  style={{ border: 0 }}
                  onClick={() => {
                    localStorage.setItem('extensionPromptClosed', true);
                    onClose();
                    onPromptClosed();
                  }}
                >
                  I’ll install it later
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
      overlayClassName: 'app-confirmation',
      closeOnEscape: false,
      closeOnClickOutside: false
    });
  }
  return null;
}

export default ExtensionPrompt;
