import React, { useCallback, useState } from 'react';
import NoResultFoundSVG from '../../../Assets/graphics/table_no_data_found.svg';
import Data from '../../../Middleware/Data';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Chip,
  DateRangePicker,
  MultiSelectDropDown,
  SearchInput,
  Table,
  Typography
} from '@nucleos/core-ui';
import {
  formatDateTime,
  tableFilterLexicographicalSorter
} from '../../../Lib/util';
import { useFiltersManager } from '../../../hooks/useFilters';
import moment from 'moment';
import toast from 'react-hot-toast';
import { SortOrder, useTableSorting } from '../../../hooks/useTableSorting';
import { confirmAlert } from 'react-confirm-alert';
import { LoadingAndErrorHandler } from '../../../Components/Shared/LoadingErrorHandler';
import DropDownMenu from '../../../Components/Shared/DropDownMenu';
import { useSearchParamsPagination } from '../../../hooks/useSearchParamsPagination';
import { QueryKeys } from '../../../Lib/query-keys';
import useUserPermissions from '../../../hooks/useUserPermissions';
import UnbanUserModalContent from '../../../Components/User/UnbanUserModalContent';
import BanUserModalContent from '../../../Components/User/BanUserModalContent';
import { GenericErrorDetectorForMutations } from '../../../Middleware/Api';
import { Box, Tooltip } from '@mui/material';
import { Icon } from '../../../Components/Shared/Icon';
import { LEARNER_SESSION_PATH } from '../../../constants/paths';

import BannedStatusIcon from '../../../Assets/icons/banned_status_icon.svg';
import LoginFlagStatusIcon from '../../../Assets/icons/login_flag_status_icon.svg';
import DeviceFlagStatusIcon from '../../../Assets/icons/device_flag_status_icon.svg';

const DEFAULT_RECORDS_PER_PAGE = 10;

const FilterKeys = {
  LearnerName: 'LearnerName',
  LastActivity: 'LastActivity',
  LastActivityDateStart: 'LastActivityDateStart',
  LastActivityDateEnd: 'LastActivityDateEnd',
  FlagCount: 'FlagCount',
  BanCount: 'BanCount',
  BannedUntil: 'BannedUntil',
  BannedUntilDateStart: 'BannedUntilDateStart',
  BannedUntilDateEnd: 'BannedUntilDateEnd',
  Status: 'Status'
};

const OrderByKeys = {
  [FilterKeys.LearnerName]: 'LEARNER_NAME',
  [FilterKeys.LastActivity]: 'REPORTED_DATE',
  [FilterKeys.FlagCount]: 'NUM_FLAGS',
  [FilterKeys.BanCount]: 'NUM_BANS',
  [FilterKeys.BannedUntil]: 'BANNED_UNTIL',
  [FilterKeys.Status]: 'STATUS'
};

const UserActions = {
  BanUser: 'Ban User',
  UnbanUser: 'Unban User'
};

const FlaggedEventsTable = () => {
  const history = useHistory();
  const userPermissions = useUserPermissions();

  const [recordsPerPage, setRecordsPerPage] = useState(DEFAULT_RECORDS_PER_PAGE);

  const [page, setPage] = useSearchParamsPagination();

  const filterManager = useFiltersManager({
    defaultFilter: [],
    onFilterChange: () => setPage(1),
    urlKey: 'filters'
  });

  const { columnSorting, setColumnSorting, getCurrentSorting } = useTableSorting({
    defaultSorting: {
      column: OrderByKeys[FilterKeys.LastActivity], sortOrder: SortOrder.Descending
    }
  });

  const unbanLearnerMutation = useMutation((data) =>
    Data.unbanLearner(data).then(GenericErrorDetectorForMutations)
  );

  const banLearnerMutation = useMutation((data) =>
    Data.banLearner(data).then(GenericErrorDetectorForMutations)
  );

  const filters = {
    learnerName: filterManager.getValue(FilterKeys.LearnerName) || '',
    reportedDateStart: filterManager.getValue(FilterKeys.LastActivityDateStart) || '',
    reportedDateEnd: filterManager.getValue(FilterKeys.LastActivityDateEnd) || '',
    bannedUntilDateStart: filterManager.getValue(FilterKeys.BannedUntilDateStart) || '',
    bannedUntilDateEnd: filterManager.getValue(FilterKeys.BannedUntilDateEnd) || '',
    flagCount: filterManager.getValue(FilterKeys.FlagCount) || '',
    banCount: filterManager.getValue(FilterKeys.BanCount) || '',
    status: filterManager.getValues(FilterKeys.Status)
  };

  const flaggedEventsQuery = useQuery(
    QueryKeys.FlaggedEvents.listing({
      filters,
      page,
      columnSorting,
      recordsPerPage
    }),
    () =>
      Data.getFlaggedEvents({
        filters,
        offset: (page - 1) * recordsPerPage,
        orderBy: columnSorting.column,
        order: columnSorting.sortOrder,
        limit: recordsPerPage
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      placeholderData: { rows: [], count: 0 }
    }
  );

  const rowData = flaggedEventsQuery.data ? (flaggedEventsQuery.data.rows || []) : [];

  const onUnban = useCallback((userUids) => {
    const loadingId = toast.loading('Unbanning users...');

    unbanLearnerMutation.mutate({ userUids }, {
      onSuccess: () => {
        toast.success('User successfully unbanned.');

        flaggedEventsQuery.refetch();
      },
      onError: () => {
        toast.error('Failed to unban user.');
      },
      onSettled: () => {
        toast.dismiss(loadingId);
      }
    });
  }, [unbanLearnerMutation]);

  const onBan = useCallback((userUid) => {
    return ({ periodInDays, banIndefinitely, internalNote, noteForLearner }) => {
      const loadingId = toast.loading('Banning users...');

      banLearnerMutation.mutate({ userUid, periodInDays, banIndefinitely, internalNote, noteForLearner }, {
        onSuccess: () => {
          toast.success('User successfully banned.');

          flaggedEventsQuery.refetch();
        },
        onError: () => {
          toast.error('Failed to ban user.');
        },
        onSettled: () => {
          toast.dismiss(loadingId);
        }
      });
    };
  }, [banLearnerMutation]);

  const onActionClick = (actionName, data) => {
    switch (actionName) {
    case UserActions.BanUser: {
      confirmAlert({
        customUI: ({ onClose }) => (
          <BanUserModalContent
            userDisplayName={data.learnerName}
            onClose={onClose}
            onSuccess={onBan(data.UserUid)}
          />
        )
      });

      break;
    }

    case UserActions.UnbanUser: {
      confirmAlert({
        customUI: ({ onClose }) => (
          <UnbanUserModalContent
            userDisplayName={data.learnerName}
            onClose={onClose}
            onSuccess={() => onUnban([data.UserUid])}
          />
        )
      });

      break;
    }

    default:
      break;
    }
  };

  const transformValue = (v) => ({ title: v.meta.label, value: v.value });

  const columns = [
    {
      title: 'Learner Name',
      skeletonClassNames: 'max-skeleton',
      enableSort: true,
      sortOrder: getCurrentSorting(OrderByKeys[FilterKeys.LearnerName]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: OrderByKeys[FilterKeys.LearnerName], sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 130 }}
          className="capitalize"
          data-testid="learner-name-flagged-events-table"
          data-fullname={item.learnerName}
        >
          {item.learnerName}
        </Typography>
      ),
      renderFilter: () => (
        <SearchInput
          placeholder="Full Name"
          fullWidth
          testId="filter-full-name"
          value={filterManager.getValue(FilterKeys.LearnerName)}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.LearnerName) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.LearnerName }); }

            filterManager.onFilterApply({
              key: FilterKeys.LearnerName,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Last Flagged Date & Time',
      skeletonClassNames: 'max-skeleton',
      enableSort: true,
      sortOrder: getCurrentSorting(OrderByKeys[FilterKeys.LastActivity]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: OrderByKeys[FilterKeys.LastActivity], sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 200 }}
          data-testid="last-activity-flagged-events-table"
          data-last-login={formatDateTime(item.lastViolationReportedAt)}
        >
          {formatDateTime(item.lastViolationReportedAt)}
        </Typography>
      ),
      renderFilter: () => (
        <DateRangePicker
          testId="filter-last-activity"
          disableHelperText
          fullWidth
          placeholder="Date Range"
          maxDate={new Date()}
          value={{
            startDate: filters.reportedDateStart
              ? new Date(filters.reportedDateStart)
              : null,
            endDate: filters.reportedDateEnd
              ? new Date(filters.reportedDateEnd)
              : null
          }}
          onChange={({ startDate, endDate }) => {
            filterManager.onFilterApply({
              key: FilterKeys.LastActivityDateStart,
              value: moment(startDate).format('YYYY/MM/DD'),
              meta: {
                label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`
              }
            });
            filterManager.onFilterApply({
              key: FilterKeys.LastActivityDateEnd,
              value: moment(endDate).format('YYYY/MM/DD')
            });
          }}
        />
      )
    },
    {
      title: 'No. of Flags #',
      skeletonClassNames: 'min-skeleton',
      enableSort: true,
      sortOrder: getCurrentSorting(OrderByKeys[FilterKeys.FlagCount]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: OrderByKeys[FilterKeys.FlagCount], sortOrder }),
      render: (item) => (
        <Tooltip arrow placement="left" title={
          <div className="nucleos-core">
            <div className="flex flex-col gap-2 p-2">
              <Typography color="white" variant="h4" >No. of Flags</Typography>

              <Typography color="white">Login Flags: {item.flagCount}</Typography>
              <Typography color="white">Device Flags: {item.podFlagCount}</Typography>
            </div>
          </div>
        }>
          <div>
            <Typography
              style={{ minWidth: 130 }}
              className="capitalize"
              data-testid="flag-count-flagged-events-table"
              data-fullname={item.flagCount + item.podFlagCount}
            >
              {item.flagCount + item.podFlagCount}
            </Typography>
          </div>
        </Tooltip>
      ),
      renderFilter: () => (
        <SearchInput
          fullWidth
          testId="filter-flag-count"
          value={filterManager.getValue(FilterKeys.FlagCount)}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.FlagCount) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.FlagCount }); }
            filterManager.onFilterApply({
              key: FilterKeys.FlagCount,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'No. of Bans #',
      skeletonClassNames: 'min-skeleton',
      enableSort: true,
      sortOrder: getCurrentSorting(OrderByKeys[FilterKeys.BanCount]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: OrderByKeys[FilterKeys.BanCount], sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 130 }}
          className="capitalize"
          data-testid="user-full-name-flagged-events-table"
          data-fullname={item.banCount}
        >
          {item.banCount}
        </Typography>
      ),
      renderFilter: () => (
        <SearchInput
          fullWidth
          testId="filter-ban-count"
          value={filterManager.getValue(FilterKeys.BanCount)}
          onSearch={(search) => {
            if (filterManager.getValue(FilterKeys.BanCount) === search) { return; }
            if (!search) { return filterManager.onFilterRemove({ key: FilterKeys.BanCount }); }
            filterManager.onFilterApply({
              key: FilterKeys.BanCount,
              value: search
            });
          }}
        />
      )
    },
    {
      title: 'Banned Until',
      skeletonClassNames: 'max-skeleton',
      enableSort: true,
      sortOrder: getCurrentSorting(OrderByKeys[FilterKeys.BannedUntil]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: OrderByKeys[FilterKeys.BannedUntil], sortOrder }),
      render: (item) => (
        <Typography
          style={{ minWidth: 200 }}
          data-testid="banned-until-flagged-events-table"
          data-last-login={
            item.isBanned
              ? (
                item.bannedUntil ? moment(item.bannedUntil).format('MMM DD, YYYY') : 'Indefinitely'
              )
              : '-'
          }
          color={item.isBanned ? 'error' : 'none'}
        >
          {`
            ${item.isBanned
          ? (
            item.bannedUntil ? moment(item.bannedUntil).format('MMM DD, YYYY') : 'Indefinitely'
          )
          : '-'}
          `}
        </Typography>
      ),
      renderFilter: () => (
        <DateRangePicker
          testId="filter-banned-until"
          disableHelperText
          fullWidth
          placeholder="Date Range"
          value={{
            startDate: filters.bannedUntilDateStart
              ? new Date(filters.bannedUntilDateStart)
              : null,
            endDate: filters.bannedUntilDateEnd
              ? new Date(filters.bannedUntilDateEnd)
              : null
          }}
          onChange={({ startDate, endDate }) => {
            filterManager.onFilterApply({
              key: FilterKeys.BannedUntilDateStart,
              value: moment(startDate).format('YYYY/MM/DD'),
              meta: {
                label: `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`
              }
            });
            filterManager.onFilterApply({
              key: FilterKeys.BannedUntilDateEnd,
              value: moment(endDate).format('YYYY/MM/DD')
            });
          }}
        />
      )
    },
    {
      title: 'Latest Status',
      skeletonClassNames: 'mid-skeleton',
      enableSort: true,
      sortOrder: getCurrentSorting(OrderByKeys[FilterKeys.Status]),
      onSortChange: (sortOrder) =>
        setColumnSorting({ column: OrderByKeys[FilterKeys.Status], sortOrder }),
      render: (item) => {
        if (item.status) {
          return (
            <div className="flex flex-col gap-1">
              {
                item.status.map((status) => (
                  <Box
                    key={`user-status-${item.learnerName}-${status}`}
                    sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}
                  >
                    <img src={(() => {
                      if (status === 'BANNED') {
                        return BannedStatusIcon;
                      }

                      if (status === 'FLAG') {
                        return LoginFlagStatusIcon;
                      }

                      if (status === 'POD_FLAG') {
                        return DeviceFlagStatusIcon;
                      }
                    })()} />

                    <Chip
                      testId={`user-status-${item.learnerName}-${status}`}
                      rounded="full"
                      variant="outlined"
                      label={(() => {
                        if (status === 'BANNED') {
                          return 'Banned';
                        }

                        if (status === 'FLAG') {
                          return 'Login Flag';
                        }

                        if (status === 'POD_FLAG') {
                          return 'Device Flag';
                        }
                      })()}
                      color={(() => {
                        if (status === 'BANNED') {
                          return 'error';
                        }

                        if (['POD_FLAG', 'FLAG'].includes(status)) {
                          return 'warning';
                        }
                      })()}
                    />
                  </Box>
                ))
              }
            </div>
          );
        }

        return '-';
      },
      renderFilter: (_, index, columnList) => (
        <MultiSelectDropDown
          dropdownId="flagged-events-latest-statuses-select"
          testId="filter-status"
          DropdownContainerProps={{
            direction: index === columnList.length - 1 ? 'left' : 'right'
          }}
          disableHelperText
          value={filterManager.getValues(FilterKeys.Status, { transformValue })}
          onChange={(values) => {
            filterManager.onBulkFilterApply(
              FilterKeys.Status,
              values.map((v) => ({
                value: v.value,
                meta: { label: v.title }
              }))
            );
          }}
          label={'All Statuses'}
          getLabel={(selected) =>
            `Selected Status${selected.length > 1 ? 'es' : ''} (${selected.length
            })`
          }
          fullWidth
          options={[
            { title: 'Banned', value: 'BAN' },
            { title: 'Login Flag', value: 'FLAG' },
            { title: 'Device Flag', value: 'POD_FLAG' }
          ].sort(tableFilterLexicographicalSorter)}
        />
      )
    },
    {
      title: 'Action',
      skeletonClassNames: 'min-skeleton',
      render: (item) =>
        userActions.filter((action) => action.getPermission(item)).length
          ? (
            <div className="flex justify-end" style={{ minWidth: 30 }}>
              <DropDownMenu
                testId={'user-action'}
                onActionClick={(action) => {
                  onActionClick(action.name, item);
                }}
                actions={userActions.filter((action) =>
                  action.getPermission(item)
                )}
              >
                <Icon icon="ThreeDots" style={{ fill: '#333333CC' }} />
              </DropDownMenu>
            </div>
          )
          : null
    }
  ];

  const onFilterDelete = (appliedFilter) => {
    const isDateRangeFilter = [
      FilterKeys.LastActivityDateStart,
      FilterKeys.LastActivityDateEnd
    ].includes(appliedFilter.key);

    if (isDateRangeFilter) {
      filterManager.onFilterRemove({
        key: FilterKeys.LastActivityDateStart
      });
      filterManager.onFilterRemove({
        key: FilterKeys.LastActivityDateEnd
      });
      return;
    }

    filterManager.onFilterRemove({
      key: appliedFilter.key,
      value: appliedFilter.value
    });
  };

  const appliedFilterLabels = filterManager.filters
    .filter((filter) => ![FilterKeys.LastActivityDateEnd, FilterKeys.BannedUntilDateEnd].includes(filter.key))
    .map((filter) => (
      <Chip
        rounded="full"
        className="mb-2 mr-2"
        style={{ padding: '6px 8px' }}
        label={filter.meta ? filter.meta.label : filter.value}
        onDelete={() =>
          onFilterDelete({
            key: filter.key,
            value: filter.value
          })
        }
      />
    ));

  const userActions = [
    {
      name: UserActions.UnbanUser,
      getPermission: (user) =>
        user.isBanned &&
        userPermissions.canActivateOtherUser()
    },
    {
      name: UserActions.BanUser,
      getPermission: (user) =>
        !user.isBanned &&
        userPermissions.canDeactivateOtherUser()
    }
  ];

  return (
    <section
      className="nucleos-core invert-scrollbar"
      style={{ paddingBottom: 40 }}
    >
      <LoadingAndErrorHandler
        isLoading={flaggedEventsQuery.isLoading}
        isSuccess={flaggedEventsQuery.isSuccess}
        isError={flaggedEventsQuery.isError}
      >
        <div className="flex flex-col">
          <div className="flex items-center flex-wrap">
            {appliedFilterLabels.length
              ? (
                <Typography className="mb-2 mr-2">Applied Filters:</Typography>
              )
              : null}
            {appliedFilterLabels}
          </div>

          <div>
            <Table
              columns={columns}
              useFixedHeight
              noDataMessage={
                <div className="flex flex-col items-center justify-center">
                  <img src={NoResultFoundSVG} alt="No result found" />
                  <Typography variant="h3">Sorry! No results found.</Typography>
                  <Typography className="mt-2">
                    Sorry, there are no results for this search,
                  </Typography>
                  <Typography className="mb-6">
                    please try adjusting the filter.
                  </Typography>
                </div>
              }
              loading={flaggedEventsQuery.isFetching}
              rowsData={rowData}
              pagination
              totalRecords={flaggedEventsQuery.data ? flaggedEventsQuery.data.count : 0}
              recordsPerPage={recordsPerPage || 10}
              onRecordsPerPageChange={(rowsPP) => {
                setRecordsPerPage(rowsPP);
                setPage(1);
              }}
              page={page}
              onPageChange={(np) => {
                setPage(np);
              }}
              onRowClick={(item) => {
                history.push(`${LEARNER_SESSION_PATH}/flagged-activity/${item.UserUid}`);
              }}
            />
          </div>

          <div className="flex flex-col gap-2 mt-6">
            <div className="flex gap-x-4 items-center">
              <div>
                <Chip
                  style={{ minWidth: 100, padding: '2px 12px' }}
                  rounded="full"
                  variant="contained"
                  label="Login Flag"
                  color="warning"
                />
              </div>

              <div>
                <Typography>
                  This is raised when a user login credentials are used while a user has another live session going with a same credential. It maybe a sign that this user is sharing credentials. A false flag might be raised if the user is attempting to login into second device without logging out of the first device they were logged in to.
                </Typography>
              </div>
            </div>

            <div className="flex gap-x-4 items-center">
              <div>
                <Chip
                  style={{ minWidth: 100, padding: '2px 12px' }}
                  rounded="full"
                  variant="contained"
                  label="Device Flag"
                  color="warning"
                />
              </div>

              <div>
                <Typography>
                  This is raised when a user tries to login from a POD other than the POD assigned to him. It maybe a sign that this user is sharing credentials.
                </Typography>
              </div>
            </div>

            <div className="flex gap-x-4 items-center">
              <div>
                <Chip
                  style={{ minWidth: 100, padding: '2px 12px' }}
                  rounded="full"
                  variant="contained"
                  label="Banned"
                  color="error"
                />
              </div>

              <div>
                <Typography>
                  A banned flag indicates that an administrator has banned this user&apos;s account. A user with the banned account will be restricted from logging in either temporarily or permanently based on administrator&apos;s selection. An administrator can activate any user at any point of time.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </LoadingAndErrorHandler>
    </section>
  );
};

export default FlaggedEventsTable;
