import React from 'react';
import { RadialBarChart, PolarAngleAxis, RadialBar } from 'recharts';
const ProgressDonutChart = (props) => {
  const { value, size, fill } = props;
  const data = [
    { name: 'L1', value: parseInt(value) }
  ];

  const circleSize = size;
  return (
    <div>
      <RadialBarChart
        width={circleSize}
        height={circleSize}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={10}
        outerRadius={circleSize}
        barSize={5}
        data={data}
        startAngle={90}
        endAngle={-270}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar
          background
          clockWise
          dataKey="value"
          cornerRadius={circleSize / 2}
          fill={fill || '#21BF1D'}
        />
      </RadialBarChart>

    </div>
  );
};

export default ProgressDonutChart;
