import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { Button } from '@mui/material';
import DataStore from '../Stores/DataStore';
// import ToastMessage from '../Components/Widgets/ToastMessage';
import withQueryClient from '../HOC/withQueryClient';
import withAppContext from '../HOC/withAppContext';

@withRouter
class Logout extends Component {
  constructor (props) {
    super(props);
    this.login = this.login.bind(this);
    this.state = {
      lastError: localStorage.getItem('lastError'),
      logoutMessage: localStorage.getItem('logoutMessage')
    };
  }

  login () {
    DataStore.setLogout(false);
    this.props.history.replace(localStorage.getItem('loginPath') || '/login');

    window.location.reload();
  }

  async componentDidMount () {
    this.props.appContext.logout();
  }

  componentWillUnmount () {
    this.setState({ lastError: null, logoutMessage: null });
  }

  // render () {
  //   const { lastError, logoutMessage } = this.state;
  //   const showToaster = logoutMessage || lastError;
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center'
  //       }}
  //     >
  //       {showToaster && (
  //         <ToastMessage
  //           message={
  //             logoutMessage ||
  //             lastError ||
  //           'Something went wrong. Contact your administrator if this continues.'
  //           }
  //           open={showToaster}
  //           autoHideDuration={10000}
  //           onClose={() =>
  //             this.setState({
  //               lastError: null,
  //               logoutMessage: null
  //             })
  //           }
  //           type={logoutMessage ? 'success' : 'error'}
  //         />
  //       )}
  //       <h3 data-testid="Logout__success_msg" style={{ marginBottom: '100px' }}>
  //         Logout Success.
  //       </h3>
  //       <Button variant="contained" onClick={this.login}>
  //         Back To Login
  //       </Button>
  //     </div>
  //   );
  // }

  render () {
    return (
      <></>
    );
  }
}

export default withQueryClient(withAppContext(Logout));
