export const getExtensionInfo = () => {
  return new Promise((resolve, reject) => {
    if (
      !window.chrome ||
      !window.chrome.runtime ||
      !window.chrome.runtime.sendMessage
    ) {
      reject(null);
    }

    window.chrome.runtime.sendMessage(
      window.nucleosConfig.extensionId,
      { command: 'extInfo' },
      (extInfo) => {
        if (extInfo) {
          const extensionInfo = setExtensionInfoOnWindow(extInfo);
          resolve(extensionInfo);
        } else {
          reject(null);
        }
      }
    );
  });
};

export const getDeviceInfo = () => {
  return new Promise((resolve, reject) => {
    if (
      !window.chrome ||
      !window.chrome.runtime ||
      !window.chrome.runtime.sendMessage
    ) {
      reject(null);
    }

    window.chrome.runtime.sendMessage(
      window.nucleosConfig.extensionId,
      { command: 'deviceInfo' },
      (deviceInfo) => {
        if (deviceInfo) {
          setDeviceInfoOnWindow(deviceInfo);
          resolve(deviceInfo);
        } else { reject(null); }
      }
    );
  });
};

const setDeviceInfoOnWindow = (deviceInfo) => {
  window.nucleosDeviceInfo = deviceInfo;
};

const setExtensionInfoOnWindow = (extensionInfo) => {
  const hostWithProto = window.location.href
    .split('/')
    .filter(Boolean)
    .slice(0, 2)
    .join('//')
    .toLowerCase();

  const nucleosExtension = {
    app_bar_detected: false,
    build_version: extensionInfo ? extensionInfo.build_version : null,
    site: extensionInfo ? extensionInfo.site : null,
    valid_site: extensionInfo
      ? extensionInfo.frontend_url.toLowerCase() === hostWithProto
      : false
  };
  window.nucleosExtension = nucleosExtension;

  return nucleosExtension;
};
