import React, { useEffect, useState } from 'react';
import UserChart from './LearningOverview/UserChart';
import UserProgressPDF from './LearningOverview/UserProgressPDF';
import defaultIcon from '../Assets/course-icon-default.svg';
import './LearningOverview/LearningOverview.scss';
import { useParams } from 'react-router-dom';
import { Typography, Table } from '@nucleos/core-ui';
import { Box, LinearProgress } from '@mui/material';
import { getHoursAndMinutes } from '../Lib/util';
import { mapCourseProgressData } from '../Lib/mapCourseProgressData';
import FlexTileGrid from '../Components/Shared/TileGrid/TileGrid';
import { Record, LearningRecordsInfo } from './LearnerRecords/Records';
import { NoDataFound } from './LearningOverview/NoDataFound';
import moment from 'moment';

import { LearnerRecordsOptionKeys } from './LearningRecords/LearningRecords';
import { Icon } from '../Components/Shared/Icon';
import { useAppContext } from '../Contexts/AppContext';

const SectionHeader = (props) => {
  const { title } = props;

  return (
    <Box sx={(theme) => ({ width: '100%', borderLeft: `6px solid ${theme.palette.primary.dark} !important`, padding: '16px 40px 16px 40px' })}>
      <span style={{ fontSize: 24, fontWeight: 600, lineHeight: '29px', letterSpacing: 1.2, textTransform: 'uppercase' }}>{title}</span>
    </Box>
  );
};

const Goal = (props) => {
  const { name, applicationName, applicationIcon, minutes, minutesRemaining } =
    props;
  const {
    hoursAdd: originalHoursAdd,
    minutesRemainder: originalMinutesRemainder
  } = getHoursAndMinutes(minutes);
  const goalProgress = minutes - minutesRemaining;
  const { hoursAdd: goalHrs, minutesRemainder: goalMins } =
    getHoursAndMinutes(goalProgress);
  const percentage = minutes
    ? ((minutes - minutesRemaining) / minutes) * 100
    : undefined;

  return (
    <>
      <div
        className="p-6 mx-2 rounded-lg m-5 border avoid-element-break"
        style={{
          background: '#ffffff',
          width: '32%',
          minWidth: '32%',
          height: '100%'
        }}
      >
        <div className="mb-4 flex justify-between">
          <div className="min-w-0" style={{ flex: 1 }}>
            <p title={name} className="truncate text-base font-bold">
              {name}
            </p>
          </div>
        </div>
        <div className="flex justify-start items-center">
          <img
            src={applicationIcon}
            className="h-5 w-5 object-contain"
            alt="App Logo"
            style={{ borderRadius: '50%' }}
          />
          <Typography
            className="ml-1 text-lg font-extrabold"
            style={{ color: '#000000' }}
          >
            {' '}
            {applicationName}{' '}
          </Typography>
        </div>
        <div className="mt-4">
          <LinearProgress
            color="primary"
            value={percentage}
            variant="determinate"
          />
        </div>
        <div>
          <div className="mt-5" style={{ wordBreak: 'break-word' }}>
            {applicationName
              ? (
                <div>
                  {!!minutes || minutes === 0
                    ? (
                      <div className="flex justify-start items-center">
                        <Icon icon="Clock" color="primary" />
                        <span className="ml-2 text-sm" style={{ color: '#000000' }}>
                          {`${goalHrs ? goalHrs.toString() : ''}${goalHrs ? 'h ' : ''
                          }${goalMins.toString()}m out of ${originalHoursAdd ? originalHoursAdd.toString() : ''
                          }${originalHoursAdd ? 'h ' : ''
                          }${originalMinutesRemainder.toString()}m`}
                        </span>
                      </div>
                    )
                    : (
                      ''
                    )}

                  {(minutes === null || undefined) && (
                    <div className="flex justify-start items-center">
                      <Icon icon="Clock" color="primary" />{' '}
                      <span className="ml-2 text-sm" style={{ color: '#000000' }}>
                      Choose a time to track application progress.
                      </span>
                    </div>
                  )}
                </div>
              )
              : (
                <div className="flex justify-start items-center">
                  <Icon icon="Clock" color="primary" />{' '}
                  <span className="ml-2 text-sm" style={{ color: '#000000' }}>
                    {' '}
                    {minutes === null || undefined
                      ? 'Choose a time and an Application to track progress.'
                      : 'Choose an Application in order to track time.'}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const LearnerRecordPdf = () => {
  const appContext = useAppContext();

  const { uid } = useParams();
  const [goals, setGoals] = useState([]);
  const [userApplications, setUserApplications] = useState([]);
  const [userLearningProgress, setUserLearningProgress] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [institutions, setInstitutions] = useState('');
  const [activityData, setActivityData] = useState([]);

  const search = new URLSearchParams(window.location.search);

  const from = search.get('from');
  const to = search.get('to');
  const timePeriod = search.get('timePeriod');
  const apps = JSON.parse(search.get('apps'));
  const enabledOptions = JSON.parse(search.get('enabledOptions'));

  useEffect(() => {
    const options = {
      credentials: 'include',
      mode: 'cors',
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' })
    };

    fetch(process.env.REACT_APP_PUBLIC_URL + `/user/${uid}/courses`, options)
      .then((r) => r.json())
      .then((r) => setUserLearningProgress(r.rows));

    fetch(process.env.REACT_APP_PUBLIC_URL + `/goal/user/${uid}`, options)
      .then((r) => r.json())
      .then((r) => setGoals(r));

    fetch(process.env.REACT_APP_PUBLIC_URL + `/usage/progress/${uid}`, options)
      .then((r) => r.json())
      .then((r) => {
        const completedCourses = r.user.CourseProgresses.map(
          mapCourseProgressData
        ).filter((progress) => progress !== null);

        const uniqueApps = new Set(r.user.CourseProgresses.map(cp => cp.Course.Application.name));
        setInstitutions(Array.from(uniqueApps).join(', '));

        setCompletedCourses(completedCourses);
        console.log(r.user);

        const { firstName, lastName, username, createdAt, updatedAt } = r.user;
        setUserDetails({ firstName, lastName, username, createdAt, updatedAt });
      });

    if (enabledOptions[LearnerRecordsOptionKeys.UsageHistory]) {
      const chartUrl =
        process.env.REACT_APP_PUBLIC_URL +
        `/reports/user?type=userPerDay&values=${uid}&from=${from}&to=${to}&timePeriod=${timePeriod}`;

      fetch(chartUrl, options)
        .then((r) => r.json())
        .then((r) => setUserApplications(r));

      fetch(`${process.env.REACT_APP_PUBLIC_URL}/usage/rawUsage/${uid}?from=${from}&to=${to}`, options)
        .then((r) => r.json())
        .then((r) => {
          const data = Array.isArray(r.records)
            ? r.records.map(
              ({
                date,
                siteTitle,
                timeSpent: minutes,
                Domain: {
                  Contentfilter: {
                    Application: { name }
                  }
                }
              }) => {
                return {
                  activity: siteTitle || '-',
                  date: moment(date).format('MM/DD/YYYY'),
                  minutes,
                  Application: name
                };
              }
            )
            : [];

          setActivityData(data);
        });
    }
  }, []);

  return (
    <div className="nucleos-core nucleos-learner-record-pdf">
      <section>
        <Box
          className="px-28 py-24"
          sx={(theme) => ({
            background: `linear-gradient(315.43deg, ${theme.palette.primary.main} -17.41%, ${theme.palette.primary.dark} 100%)`, width: '100%', height: '100vh', display: 'flex', flexDirection: 'column'
          })}
        >
          <Box>
            <img src={appContext.monochromeLogoURL} alt='logo' style={{ height: 64 }} />
          </Box>

          <div className="mt-auto" style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <p style={{ fontSize: 56, fontWeight: 600, textTransform: 'uppercase', color: '#fff' }}>Learning<br />Records</p>
            </div>

            <div className="mt-16">
              <p style={{ fontSize: 32, color: '#fff' }}>
                {
                  `${moment(from).format('MMM DD, YYYY')} - ${moment(to).format('MMM DD, YYYY')}`
                }
              </p>
            </div>

            <div className="mt-16" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <p style={{ fontSize: 32, color: '#fff' }}>{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                <p style={{ fontSize: 32, color: '#fff' }}>{`Username: ${userDetails.username}`}</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <p style={{ fontSize: 24, color: '#fff' }}>
                  {
                    `${moment(new Date()).format('MMM DD, YYYY')}`
                  }
                </p>
              </div>
            </div>
          </div>
        </Box>
      </section>

      <section>
        <SectionHeader title='Learning Records' />

        <div className="m-5">
          <LearningRecordsInfo applications={institutions} user={userDetails} />

          {
            Array.isArray(completedCourses) && completedCourses.length > 0 && enabledOptions[LearnerRecordsOptionKeys.LearningRecords]
              ? (
                <div id="courses-info" style={{ paddingTop: 20 }}>
                  <FlexTileGrid
                    gridItemClassName="avoid-element-break"
                    gridColTiers={{ xs: 12 }}
                    horizontal
                    tileStyle={{ padding: '30px', border: '1px solid #D3D3D3' }}
                    spacing={0}
                    gridItemStyle={{ padding: '10px 0' }}
                  >
                    {completedCourses.map((mappedCourseProgress, index) => {
                      return (
                        <Record key={index} courseProgress={mappedCourseProgress} showAppLink={false} />
                      );
                    })}
                  </FlexTileGrid>
                </div>
              )
              : null
          }
        </div>
      </section>

      {
        Array.isArray(goals) && goals.length > 0 && enabledOptions[LearnerRecordsOptionKeys.ProgressAndGoals]
          ? (
            <section>
              <SectionHeader title='Goals' />

              <div className="flex items-center flex-wrap m-5">
                {goals.map((g, index) => {
                  const progressPercent = g.minutes
                    ? ((g.minutes - g.minutesRemaining) / g.minutes) * 100
                    : undefined;

                  return (
                    <Goal
                      key={`${g.ApplicationUid}-${index}`}
                      name={g.name}
                      applicationName={g.Application && g.Application.name}
                      progressPercent={progressPercent}
                      applicationIcon={
                        g.Application &&
                        (g.Application.logo || g.Application.icon || defaultIcon)
                      }
                      minutes={g.minutes}
                      minutesRemaining={g.minutesRemaining}
                      id={g.id}
                    />
                  );
                })}
              </div>
            </section>
          )
          : null
      }

      {
        Array.isArray(userLearningProgress) && userLearningProgress.length > 0 && enabledOptions[LearnerRecordsOptionKeys.ProgressAndGoals]
          ? (
            <section>
              <SectionHeader title='Learning Progress' />

              <div className="m-5">
                <UserProgressPDF userLearningProgress={userLearningProgress} />
              </div>
            </section>
          )
          : null
      }

      {
        (Array.isArray(userApplications.records) && userApplications.records.length > 0) &&
          (Array.isArray(userApplications.applications) && userApplications.applications.length > 0) &&
          enabledOptions[LearnerRecordsOptionKeys.UsageHistory]
          ? (
            <section>
              <SectionHeader title='Usage History - Graph' />

              <div className="m-5">
                <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h4">
                    Showing results from {moment(from).format('MMM DD, YYYY')} -{' '}
                    {moment(to).format('MMM DD, YYYY')}
                  </Typography>
                </div>

                <UserChart
                  printMode={true}
                  userUsageTotals={userApplications.records}
                  userApplications={apps.length ? apps : userApplications.applications}
                  selectedApp={apps ? apps.map((app) => ({ title: app, value: app })) : []}
                  rawActivity="[1,3]"
                />
              </div>
            </section>
          )
          : null
      }

      {
        Array.isArray(activityData) && activityData.length > 0 && enabledOptions[LearnerRecordsOptionKeys.UsageHistory]
          ? (
            <section>
              <SectionHeader title='Usage History - Details' />

              <div className="m-5">
                <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h4">
                    Showing results from {moment(from).format('MMM DD, YYYY')} -{' '}
                    {moment(to).format('MMM DD, YYYY')}
                  </Typography>
                </div>

                <Table
                  columns={[
                    {
                      title: 'Activity',
                      render: (data) => (
                        <div className="nucleos-core" style={{ minWidth: 0, maxWidth: 300 }}>
                          <p className="truncate" title={data.activity}>
                            {data.activity}
                          </p>
                        </div>
                      )
                    },
                    {
                      title: 'Application',
                      render: (data) => <p>{data.Application}</p>
                    },
                    {
                      title: 'Date',
                      render: (data) => <p>{data.date}</p>
                    },
                    {
                      title: 'Time Spent',
                      render: ({ minutes }) => (
                        <p>{minutes < 1 ? '<1 m' : `${minutes | 0} m`}</p>
                      )
                    }
                  ]}
                  rowsData={
                    apps.length > 0
                      ? activityData.filter((activity) => {
                        return apps.some(
                          (app) => app === activity.Application
                        );
                      })
                      : activityData
                  }
                  noDataMessage={<NoDataFound />}
                  striped
                />
              </div>
            </section>
          )
          : null
      }
    </div>
  );
};

export default LearnerRecordPdf;
