import { useState } from 'react';

export function useConfirmationDialog () {
  const [dialogNode, setDialogNode] = useState(null);

  function openDialog (dialogFactory) {
    return new Promise((resolve) => {
      function close (value) {
        resolve(value);

        setDialogNode(null);
      }

      setDialogNode(dialogFactory(close));
    });
  }

  return [dialogNode, openDialog];
}
