import React from 'react';
import { Box } from '@mui/material';

const HeadlessLayout = ({ children }) => {
  return (
    <Box display='flex' flexDirection='column' width='100%' overflow='auto'>
      {children}
    </Box>
  );
};

export default HeadlessLayout;
