import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  TextInput,
  Typography,
  MultiSelectDropDown
} from '@nucleos/core-ui';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ConfigRoleSelect from '../Roles/RolesSelect';
import { useMutation } from 'react-query';
import Data from '../../Middleware/Data';
import toast from 'react-hot-toast';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { getGroupFormSchema } from './validation-schema';
import { Icon } from '../Shared/Icon';
import { Box } from '@mui/material';

const GROUP_TYPES = [{
  title: 'Learner',
  value: 'LEARNER'
}, {
  title: 'Non Learner',
  value: 'NON_LEARNER'
}];

export default function GroupForm ({
  isModalRenderedForm,
  isAddUsersToGroupForm,
  groupId,
  groupData,
  preDeterminedGroupType,
  selectedUsers,
  disableForm,
  onCloseClick,
  onGroupCreateOrUpdate
}) {
  const isEditingGroup = !!groupId;
  const isCreatingGroup = !isEditingGroup;

  const usersOptions = useMemo(() => {
    return (selectedUsers || []).map((user) => ({
      title: `${user.firstName} ${user.lastName}`,
      value: user.uid
    }));
  }, [selectedUsers]);

  const visibleFields = {
    name: true,
    description: true,
    groupType: true,
    roleId: true,
    userIds: isAddUsersToGroupForm
  };

  const enabledFields = {
    name: true,
    description: true,
    groupType: !preDeterminedGroupType,
    roleId: true,
    userIds: isAddUsersToGroupForm
  };

  const groupForm = useForm({
    resolver: yupResolver(getGroupFormSchema({ isAddUsersToGroupForm })),
    defaultValues: {
      name: '',
      description: '',
      groupType: '',
      roleId: '',
      userIds: []
    },
    mode: 'onChange'
  });

  const groupType = groupForm.watch('groupType');

  const createGroupMutation = useMutation(
    (data) =>
      Data.createGroup({
        name: data.name,
        description: data.description,
        groupType: data.groupType,
        roleId: data.roleId,
        userIds: data.userIds
      }).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        if (onGroupCreateOrUpdate) { onGroupCreateOrUpdate(); }

        return toast.success('Group created successfully.');
      },
      onError: (error) => toast.error(error.message || 'Failed to create Group.')
    }
  );
  const updateGroupMutation = useMutation(
    (data) =>
      Data.updateGroup(groupId, {
        name: data.name,
        description: data.description,
        groupType: data.groupType,
        roleId: data.roleId,
        userIds: data.userIds
      }).then(GenericErrorDetectorForMutations),
    {
      onSuccess: () => {
        if (onGroupCreateOrUpdate) {
          onGroupCreateOrUpdate();
        }

        toast.success('Group details updated.');
      },
      onError: (error) => toast.error(error.message || 'Failed to update Group.')
    }
  );

  const initiateFormToOriginalState = useCallback(() => {
    groupForm.reset({
      name: isEditingGroup ? groupData.name : '',
      description: isEditingGroup ? groupData.description : '',
      groupType: (isEditingGroup || preDeterminedGroupType) ? groupData.groupType : '',
      roleId: isEditingGroup
        ? groupData.associatedRole
          ? groupData.associatedRole.id
          : ''
        : '',
      userIds: isEditingGroup || selectedUsers ? groupData.userIds : ''
    });
  }, [isEditingGroup, groupData, groupForm, preDeterminedGroupType, selectedUsers]);

  useEffect(() => {
    if (groupData) {
      initiateFormToOriginalState();
    }
  }, [groupData, initiateFormToOriginalState]);

  const createGroupHandler = (data) => {
    if (isEditingGroup) {
      /** Un-comment the code below when only changed fields are required while updating */

      // const updatedData = Object.keys(groupForm.formState.dirtyFields).reduce((updatedDataAcc, field) => {
      //   updatedDataAcc[field] = data[field];

      //   return updatedDataAcc;
      // }, {});

      updateGroupMutation.mutate({ ...data });
    } else {
      createGroupMutation.mutate({ ...data });
    }
  };

  return (
    <div
      className="nucleos-core"
      data-test-id={isCreatingGroup ? 'create-group-form' : 'update-group-form'}
    >
      <Box sx={{ mb: isModalRenderedForm ? 6 : 2 }}>
        {
          visibleFields.name && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>
                Group Name <span className="text-red-600">*</span>
                </Typography>
                <Controller
                  name="name"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      testId="group-name"
                      onChange={(_, evt) => field.onChange(evt)}
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      disabled={disableForm || !enabledFields.name}
                      label="Group Name"
                      fullWidth
                      placeholder="Enter Group Name"
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }

        {
          visibleFields.groupType && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>
                Group Type <span className="text-red-600">*</span>
                </Typography>
                <Controller
                  name="groupType"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <MultiSelectDropDown
                      fullWidth
                      dropdownId='group-form-type-select-dropdown'
                      testId='group-type'
                      label='Group Type'
                      name='groupType'
                      value={GROUP_TYPES.filter((op) => op.value === field.value)}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      onChange={([item]) => field.onChange(item.value)}
                      disabled={disableForm || !enabledFields.groupType}
                      options={GROUP_TYPES}
                      disableMultiSelect
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }

        {
          visibleFields.roleId && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>Grievance Role</Typography>
                <Controller
                  name="roleId"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <ConfigRoleSelect
                      id='group-form-grievance-role-select'
                      name="configRole"
                      testId="group-grievance-role"
                      value={field.value ? [field.value] : []}
                      error={!!fieldState.error}
                      noSelectedValue="Select Grievance Role"
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      onChange={([item]) => field.onChange(item.value)}
                      ConfigRole={{}}
                      disabled={disableForm || !enabledFields.roleId}
                      roleId={field.value}
                      filterOptions={{
                        byLearner: groupType === 'LEARNER',
                        byNonLearner: groupType === 'NON_LEARNER'
                      }}
                      label='Select Grievance Role'
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }

        {
          visibleFields.description && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>Group Description</Typography>
                <Controller
                  name="description"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      testId="group-description"
                      onChange={(_, evt) => field.onChange(evt)}
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      disabled={disableForm || !enabledFields.description}
                      label="Group Description"
                      fullWidth
                      placeholder="Enter Group Description"
                      multiline
                      rows={3}
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }

        {
          visibleFields.userIds && (
            <Box sx={{ mb: 2 }}>
              <Box>
                <Typography>
              Users <span className="text-red-600">*</span>
                </Typography>
                <Controller
                  name="userIds"
                  control={groupForm.control}
                  render={({ field, fieldState }) => (
                    <MultiSelectDropDown
                      {...field}
                      dropdownId="group-from-users-select"
                      onChange={(values) =>
                        field.onChange(values.map((v) => v.value))
                      }
                      testId="group-users"
                      value={field.value ? usersOptions.filter(user => field.value.includes(user.value)) : []}
                      error={!!fieldState.error}
                      helperText={(fieldState.error || {}).message}
                      disableHelperText={!(fieldState.error || {}).message}
                      disabled={disableForm || !enabledFields.userIds}
                      label='Select Users'
                      options={usersOptions}
                      fullWidth
                      getLabel={(selectedValues) => (
                        <div
                          className="flex flex-wrap gap-1"
                          data-testid="group-form-selected-users-list"
                        >
                          {selectedValues.map((user) => (
                            <div
                              key={user}
                              className="px-2 py-1 mr-2 flex items-center rounded-full bg-lightblue-light"
                              data-testid="group-form-selected-user"
                            >
                              {
                                (usersOptions.find((f) => f.value === user) || {}).title
                              }
                              <Icon
                                icon="Close"
                                data-testid="group-form-selected-user-remove-btn"
                                data-userid={user}
                                style={{ fontSize: 16 }}
                                className="ml-2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  field.onChange(
                                    field.value.filter((v) => v !== user)
                                  );
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      DropdownContainerProps={isModalRenderedForm ? { position: 'top' } : {}}
                    />
                  )}
                />
              </Box>
            </Box>
          )
        }
      </Box>

      <div
        className={
          'flex justify-between ' + (isEditingGroup ? '' : 'flex-row-reverse')
        }
      >
        <Button
          color="primary"
          variant="contained"
          onClick={groupForm.handleSubmit(createGroupHandler, console.error)}
          disabled={!groupForm.formState.isDirty}
          loading={
            (groupId ? updateGroupMutation : createGroupMutation).isLoading
          }
        >
          {isEditingGroup ? 'Save Changes' : 'Create Group'}
        </Button>

        <Button
          variant="outlined"
          onClick={
            isEditingGroup ? initiateFormToOriginalState : onCloseClick
          }
        >
          {isEditingGroup ? 'Clear Changes' : 'Close'}
        </Button>
      </div>
    </div>
  );
}
