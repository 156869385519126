import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Typography,
  SearchInput,
  Table,
  Button
} from '@nucleos/core-ui';
import { Modal, DialogContent, Switch } from '@mui/material';
import UpdateAppPassword from './UpdateAppPassword';
import Data from '../../../Middleware/Data';
import { formatDateTime } from '../../../Lib/util';
import NoResultFoundSVG from '../../../Assets/graphics/table_no_data_found.svg';
import PasswordDots from '../../../Assets/password-dots.svg';
import { useUserContext } from '../UserContext';
import CryptoJS from 'crypto-js';
import { Icon } from '../../../Components/Shared/Icon';

const defaultSorting = {
  column: 'name',
  sortOrder: 'ASC'
};

const UserApplications = () => {
  const userContext = useUserContext();
  const { user } = userContext;

  const [searchTerm, setSearchTerm] = useState('');
  const [showLoginOnlyApps, setShowLoginOnlyApps] = useState(false);
  const [showAllPasswords, setShowAllPasswords] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [appDetails, setAppDetails] = useState({});
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [columnSorting, setColumnSorting] = useState(defaultSorting);

  const userApplicationsQuery = useQuery(
    [
      'UserApplications',
      user.uid,
      showLoginOnlyApps,
      searchTerm,
      columnSorting,
      page,
      recordsPerPage
    ],
    () =>
      Data.getUserApplications(
        user.uid,
        showLoginOnlyApps,
        searchTerm,
        columnSorting.sortOrder !== 'NONE' ? columnSorting.column : '',
        columnSorting.sortOrder !== 'NONE' ? columnSorting.sortOrder : '',
        page,
        recordsPerPage
      ),
    {
      select: (data) => {
        return {
          ...data,
          rows: (data.rows || []).map((app) => {
            const appData = {
              ...app,
              userPasswordMetadata: JSON.parse(app.userPasswordMetadata) || []
            };

            if (app.password) {
              const { password } = app;

              appData.password = CryptoJS.AES.decrypt(
                password,
                process.env.REACT_APP_CODE_ENCRYPTION_KEY
              ).toString(CryptoJS.enc.Utf8);
            }

            return appData;
          })
        };
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const columns = useMemo(() => {
    const columns = [
      {
        title: 'Application Name',
        enableSort: true,
        sortOrder:
          columnSorting.column === 'name' ? columnSorting.sortOrder : 'NONE',
        onSortChange: (sortOrder) =>
          setColumnSorting({ column: 'name', sortOrder }),
        render: (item) => (
          <div className="flex items-center gap-x-2">
            <img src={item.logo} style={{ width: 25, height: 25 }} />

            <Typography style={{ fontWeight: 600, color: '#333' }}>
              <div>{item.name}</div>
            </Typography>
          </div>
        )
      },
      {
        title: 'App Username',
        render: (item) => (
          <Typography>
            <div>{item.username}</div>
          </Typography>
        )
      },
      {
        title: 'App Password',
        enableSort: false,
        render: (item) => (
          <Typography>
            {item.password
              ? (
                showAllPasswords
                  ? (
                    <div>{item.password}</div>
                  )
                  : (
                    <img src={PasswordDots} alt="hidden" />
                  )
              )
              : null}
          </Typography>
        )
      },
      {
        title: 'Updated On',
        enableSort: true,
        sortOrder:
          columnSorting.column === 'updatedAt'
            ? columnSorting.sortOrder
            : 'NONE',
        onSortChange: (sortOrder) =>
          setColumnSorting({ column: 'updatedAt', sortOrder }),
        render: (item) => (
          <Typography>
            <div>{formatDateTime(item.updatedAt)}</div>
          </Typography>
        )
      },
      {
        title: 'Action',
        enableSort: false,
        render: (item) => (
          <Button
            variant="contained"
            disabled={!item.password}
            onClick={() => onEditDetails(item)}
          >
            <Icon icon='Edit' />
          </Button>
        )
      }
    ];

    return columns;
  }, [columnSorting, showAllPasswords]);

  const onEditDetails = (app) => {
    const details = {
      ...app,
      uid: user.uid,
      appUserId: app.id
    };

    setAppDetails(details);
    setOpenModal('Edit');
  };

  return (
    <>
      <div className="nucleos-core">
        <div className="my-3 flex justify-between">
          <SearchInput
            placeholder="Search"
            style={{ width: '240px' }}
            onSearch={(search) => {
              setSearchTerm(search);
              setPage(1);
            }}
          />
          <div className="flex items-center gap-x-4">
            <Typography>Apps with login</Typography>
            <Switch
              checked={showLoginOnlyApps}
              label={'Apps with login'}
              onChange={(event) => {
                setShowLoginOnlyApps(event.target.checked);
                setPage(1);
              }}
            />
            <Typography>Show all passwords</Typography>
            <Switch
              checked={showAllPasswords}
              label={'Show all passwords'}
              onChange={(event) => setShowAllPasswords(event.target.checked)}
            />
          </div>
        </div>
        <Table
          columns={columns}
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />
              <Typography className="mb-2" variant="h3">
                Sorry! No results found.
              </Typography>
              {searchTerm && (
                <Typography className="mb-2">
                  Sorry, there are no results for this search.
                </Typography>
              )}
            </div>
          }
          loading={userApplicationsQuery.status !== 'success'}
          rowsData={
            userApplicationsQuery.data ? userApplicationsQuery.data.rows : []
          }
          pagination
          totalRecords={
            userApplicationsQuery.data ? userApplicationsQuery.data.count : 0
          }
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </div>

      <Modal
        open={!!openModal}
        handleClose={() => setOpenModal('')}
        className="nucleos-core"
      >
        <DialogContent className="h-full flex justify-center items-center">
          <UpdateAppPassword
            appDetails={appDetails}
            userDisplayName={`${user.firstName} ${user.lastName}`}
            onClose={(reload) => {
              setOpenModal('');
              setAppDetails({});
              if (reload) {
                userApplicationsQuery.refetch();
              }
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default UserApplications;
