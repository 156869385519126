import React from 'react';
import {
  Modal,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  Switch
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  TextInput,
  ToggleButton,
  MultiSelectDropDown,
  Button as NucleosButton
} from '@nucleos/core-ui';
import { Controller, useForm } from 'react-hook-form';
import {
  actionTypes,
  permissionEnum,
  roleSchema,
  actionLabels
} from './RoleSchema';
import { QueryKeys } from '../../Lib/query-keys';
import Data from '../../Middleware/Data';
import { useCategoriesQuery } from '../../hooks/useCategories';
import { LoadingAndErrorHandler } from '../Shared/LoadingErrorHandler';
import { GenericErrorDetectorForMutations } from '../../Middleware/Api';
import { Icon } from '../Shared/Icon';

const styles = {
  categoryCheckbox: {
    'width': '50%',
    '& .category': {
      'padding': '4px 7px 4px 12px',
      '& svg': {
        fill: '#cccccc'
      }
    },
    '& .categoryChecked': {
      'padding': '4px 7px 4px 12px',
      '& svg': {
        fill: '#449FFF'
      }
    },
    '& .allCategory': {
      padding: '4px 7px 4px 12px'
    }
  },
  inputLabel: {
    'fontSize': '14px',
    'lineHeight': '19px',
    'fontWeight': 400,
    'color': 'rgba(51, 51, 51, 0.9)',
    '& span': {
      color: '#F83232'
    }
  },
  createBtn: {
    'background': '#449FFF',
    'borderRadius': '4px',
    'color': '#ffffff',
    'height': '35px',
    'fontSize': '14px',
    'lineHeight': '19px',
    'textTransform': 'capitalize',
    '&:hover': {
      background: 'rgb(23, 105, 170)'
    },
    '&[disabled]': {
      color: '#ffffff',
      opacity: 0.7
    }
  }
};

const options = [
  { title: 'All', value: permissionEnum.ALL },
  { title: 'Filter By Facility', value: permissionEnum.BY_FACILITY },
  { title: 'Filter By Category', value: permissionEnum.BY_CATEGORY },
  { title: 'None', value: permissionEnum.NONE }
];

const learnerOptions = [
  { title: 'Filter By Category', value: permissionEnum.BY_CATEGORY },
  { title: 'None', value: permissionEnum.NONE }
];

const requestTypeChangeOptions = [
  { title: 'All', value: permissionEnum.ALL },
  { title: 'None', value: permissionEnum.NONE }
];

const getAllowedOptions = ({ viewPermission = '', actionName, isLearner }) => {
  const _options = isLearner ? learnerOptions : options;
  if (actionName === 'VIEW') { return _options; }
  switch (viewPermission) {
  case permissionEnum.BY_CATEGORY: {
    const allowedOptions = [permissionEnum.BY_CATEGORY, permissionEnum.NONE];
    return _options.filter(({ value }) => allowedOptions.includes(value));
  }
  case permissionEnum.BY_FACILITY: {
    const allowedOptions = [
      permissionEnum.BY_FACILITY,
      permissionEnum.BY_CATEGORY,
      permissionEnum.NONE
    ];
    return _options.filter(({ value }) => allowedOptions.includes(value));
  }
  case permissionEnum.NONE: {
    const allowedOptions = [permissionEnum.NONE];
    return _options.filter(({ value }) => allowedOptions.includes(value));
  }
  default:
    return _options;
  }
};

const GrievanceLevel = ({
  id,
  label,
  isSwitchDisabled,
  isSwitchedOn,
  onSwitchChange,
  permission,
  onPermissionChange,
  errors = {},
  actions = [],
  disabledActions = [],
  isDropdownsDisabled,
  isLearner
}) => {
  const optionsList = {
    [actionTypes.VIEW]: getAllowedOptions({
      viewPermission: permission[actionTypes.VIEW],
      actionName: actionTypes.VIEW,
      isLearner
    }),
    [actionTypes.ASSIGN]: getAllowedOptions({
      viewPermission: permission[actionTypes.VIEW],
      actionName: actionTypes.ASSIGN
    }),
    [actionTypes.DUPLICATE]: getAllowedOptions({
      viewPermission: permission[actionTypes.VIEW],
      actionName: actionTypes.DUPLICATE
    }),
    [actionTypes.RESPOND]: getAllowedOptions({
      viewPermission: permission[actionTypes.VIEW],
      actionName: actionTypes.RESPOND
    }),
    [actionTypes.REQUEST_TYPE_CHANGE]: requestTypeChangeOptions,
    [actionTypes.CREATE]: learnerOptions
  };

  const hasMasterSwitch =
    typeof isSwitchedOn === 'boolean' && typeof onSwitchChange === 'function';

  const _errors = errors.permission || {};

  return (
    <Box sx={{
      background: '#F8F8F8',
      border: '1px solid rgba(51, 51, 51, 0.1) !important',
      borderRadius: '8px',
      padding: '12px',
      margin: '8px 0px'
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '700'
      }}>
        <Typography variant="h5" className="mr-4">
          {label}
        </Typography>
        {hasMasterSwitch && (
          <Switch
            checked={isSwitchedOn}
            onChange={onSwitchChange}
            disabled={isSwitchDisabled}
            color='primary'
          />
        )}
      </Box>
      <div
        className={`mt-2 flex flex-wrap ${(hasMasterSwitch && !isSwitchedOn) || isSwitchDisabled
          ? 'disabled'
          : null
        }`}
      >
        {actions.map((action, index) => (
          <div
            key={`grievance-action-${action}`}
            className={index !== actions.length - 1 ? 'mr-4' : ''}
            style={{
              width: `${92 / actions.length}%`,
              minWidth: '8rem',
              maxWidth: '16rem'
            }}
          >
            <Box component='label' sx={styles.inputLabel}>
              {actionLabels[action]}
              <span> *</span>
            </Box>
            <MultiSelectDropDown
              dropdownId={`${id}-action-${action}`}
              onChange={([selection]) =>
                selection ? onPermissionChange(action, selection.value) : null
              }
              disableMultiSelect
              label="Select"
              value={
                permission[action]
                  ? (optionsList[action] || []).filter(
                    (op) => permission[action] === op.value
                  )
                  : []
              }
              options={optionsList[action]}
              disabled={
                disabledActions.includes(action) ||
                isDropdownsDisabled ||
                (hasMasterSwitch && (isSwitchDisabled || !isSwitchedOn))
              }
              error={!!_errors[action]}
              helperText={(_errors[action] || {}).message}
            />
          </div>
        ))}
      </div>
    </Box>
  );
};

const emptyPermissionsForLearner = {
  [actionTypes.VIEW]: '',
  [actionTypes.CREATE]: ''
};
const emptyPermissionsForNonLearner = {
  [actionTypes.VIEW]: '',
  [actionTypes.ASSIGN]: '',
  [actionTypes.RESPOND]: '',
  [actionTypes.DUPLICATE]: '',
  [actionTypes.REQUEST_TYPE_CHANGE]: ''
};

const defaultFormValuesNonLearner = {
  name: '',
  description: '',
  isLearner: false,
  isGrievanceEnabled: true,
  grievancePermission: {
    categories: [],
    permissions: [1, 2, 3].map((level) => ({
      level: level,
      isEnabled: false,
      permission: emptyPermissionsForNonLearner
    }))
  }
};
const defaultFormValuesLearner = {
  name: '',
  description: '',
  isLearner: true,
  isGrievanceEnabled: true,
  grievancePermission: {
    categories: [],
    permissions: [
      {
        level: 0,
        isEnabled: true,
        permission: emptyPermissionsForLearner
      }
    ]
  }
};

const CreateRoleRefactored = ({
  openModal,
  closeModal,
  selectedRoleId,
  isEditRole
}) => {
  const queryClient = useQueryClient();

  const selectedRoleQuery = useQuery(
    QueryKeys.Roles.item(selectedRoleId),
    () => Data.getUpdatedRole(selectedRoleId),
    {
      enabled: !!selectedRoleId,
      onSuccess: (data) => {
        const permissions = (data.isLearner ? [0] : [1, 2, 3]).map(
          (level) =>
            data.grievancePermission.permissions.find(
              (per) => per.level === level
            ) || {
              level: level,
              isEnabled: false,
              permission: data.isLearner
                ? emptyPermissionsForLearner
                : emptyPermissionsForNonLearner
            }
        );
        rolesForm.reset({
          name: data.label,
          description: data.description,
          isGrievanceEnabled: data.isGrievanceEnabled,
          isLearner: data.isLearner,
          grievancePermission: {
            permissions: permissions,
            categories: data.grievancePermission.categories.map((c) => c.id)
          }
        });
      }
    }
  );

  const createRoleMutation = useMutation((data) =>
    Data.createRole(data).then(GenericErrorDetectorForMutations)
  );
  const updateRoleMutation = useMutation((data) =>
    Data.updateRole(data, selectedRoleId).then(GenericErrorDetectorForMutations)
  );

  const roleMutation = selectedRoleId ? updateRoleMutation : createRoleMutation;

  const rolesForm = useForm({
    resolver: yupResolver(roleSchema),
    defaultValues: defaultFormValuesNonLearner
  });

  const isLearnerRole = rolesForm.watch('isLearner');
  const isGrievanceEnabled = rolesForm.watch('isGrievanceEnabled');
  const permissions = rolesForm.watch('grievancePermission.permissions') || [];

  const categoriesQuery = useCategoriesQuery({ roleType: isLearnerRole ? 'LEARNER' : 'NON_LEARNER' });
  const allCategories = categoriesQuery.data || [];
  const isSystemDefinedRole = isEditRole
    ? selectedRoleQuery.data && selectedRoleQuery.data.isSystemDefined
    : false;

  const isFilterByCategorySelected = permissions.some(
    (per) =>
      per.isEnabled &&
      Object.values(per.permission || {}).includes(permissionEnum.BY_CATEGORY)
  );

  const formUpdateOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true
  };

  const createRoleHandler = async (data) => {
    const payload = {
      label: data.name,
      description: data.description,
      isLearner: data.isLearner,
      isGrievanceEnabled: data.isGrievanceEnabled,
      grievanceReq: {
        categoryIds: data.grievancePermission.categories,
        permissions: data.grievancePermission.permissions.filter(
          (permission) => permission.isEnabled
        )
      }
    };
    roleMutation.mutate(payload, {
      onSuccess: () => {
        toast.success(
          selectedRoleId
            ? 'Role updated successfully.'
            : 'Role created successfully.'
        );
        queryClient.invalidateQueries({ queryKey: ['ROLES'] });
        closeModalHandler();
      },
      onError: () => {
        toast.error('Something went wrong');
      }
    });
  };

  const categoryChangeHandler = (id, selectedCategories) => {
    let updatedIds = [...selectedCategories];
    if (updatedIds.includes(id)) {
      updatedIds = updatedIds.filter((item) => item !== id);
    } else {
      updatedIds.push(id);
    }
    rolesForm.setValue(
      'grievancePermission.categories',
      updatedIds,
      formUpdateOptions
    );
  };

  const allCategoryHandler = (selectedCategories) => {
    if (selectedCategories.length === allCategories.length) {
      rolesForm.setValue(
        'grievancePermission.categories',
        [],
        formUpdateOptions
      );
    } else {
      const ids = allCategories.map((cat) => cat.id);
      rolesForm.setValue(
        'grievancePermission.categories',
        ids,
        formUpdateOptions
      );
    }
  };

  const getCheckedIcon = (selectedCategories) => {
    if (allCategories.length == selectedCategories.length) {
      return <Icon icon="CheckedBox" style={{ color: '#449FFF' }} color="#449FFF" />;
    } else if (
      selectedCategories.length > 0 &&
      selectedCategories.length !== allCategories.length
    ) {
      return <Icon icon="IndeterminateCheckbox" style={{ color: '#449FFF' }} color="#449FFF" />;
    } else {
      return <Icon icon="UncheckedBox" style={{ color: '#cccccc' }} color="#cccccc" />;
    }
  };

  const onPermissionChange = ({
    fieldName,
    action,
    value,
    levelPermission
  }) => {
    rolesForm.setValue(
      `${fieldName}.permission.${action}`,
      value,
      formUpdateOptions
    );
    if (action === actionTypes.VIEW) {
      const viewPermission = value;
      const affectedActions = isLearnerRole
        ? [actionTypes.CREATE]
        : [actionTypes.ASSIGN, actionTypes.DUPLICATE, actionTypes.RESPOND];

      if (viewPermission === permissionEnum.NONE) {
        rolesForm.setValue(
          `${fieldName}.permission.${actionTypes.REQUEST_TYPE_CHANGE}`,
          permissionEnum.NONE,
          formUpdateOptions
        );
        affectedActions.forEach((otherAction) =>
          rolesForm.setValue(
            `${fieldName}.permission.${otherAction}`,
            permissionEnum.NONE,
            formUpdateOptions
          )
        );
      } else {
        const resetPermissionForActions = [];
        affectedActions.forEach((action) => {
          const allowedOptions = getAllowedOptions({
            viewPermission: viewPermission,
            actionName: action,
            isLearner: isLearnerRole
          }).map((op) => op.value);
          if (
            levelPermission[action] &&
            !allowedOptions.includes(levelPermission[action])
          ) { resetPermissionForActions.push(action); }
        });
        resetPermissionForActions.forEach((action) =>
          rolesForm.setValue(
            `${fieldName}.permission.${action}`,
            '',
            formUpdateOptions
          )
        );
      }
    }
  };

  const closeModalHandler = () => {
    rolesForm.reset(defaultFormValuesNonLearner);
    closeModal();
  };

  const getModalTitle = () => {
    if (isSystemDefinedRole) {
      return 'Grievance Role Details';
    } else if (isEditRole) {
      return 'Edit Grievance Role Details';
    } else {
      return 'Create Grievance Role';
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Modal
        open={openModal}
        onClose={closeModalHandler}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{
          // top: '50%',
          // left: '50%',
          // transform: 'translate(0%, 100%)',
          background: '#FFFFFF',
          borderRadius: '8px',
          padding: '20px',
          width: '70%',
          margin: '0 auto',
          // border: 'none',
          outline: 'none',
          position: 'relative'
          // '& .nucleos-field': {
          //     height: '32px',
          //     padding: '6px 12px !important',
          //     borderRadius: '4px'
          // }
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(51, 51, 51, 0.2)',
            paddingBottom: '12px'
          }}>
            <Box sx={{
              fontSize: '24px',
              lineHeight: '33px',
              fontWeight: 500,
              color: '#333333'
            }}>{getModalTitle()}</Box>
            <Box sx={{
              'display': 'flex',
              'alignItems': 'center',
              'cursor': 'pointer',
              'color': '#333333',
              '& span': {
                marginLeft: '3px'
              }
            }}
            onClick={closeModalHandler}
            >
              {/* <CloseIcon /> */}
              <Icon icon="Close" />
              <span>Close</span>
            </Box>
          </Box>
          <LoadingAndErrorHandler
            isLoading={isEditRole ? selectedRoleQuery.isFetching : false}
            isError={isEditRole ? selectedRoleQuery.isError : false}
            isSuccess={isEditRole ? selectedRoleQuery.isSuccess : true}
            className="py-80"
          >
            <form
              onSubmit={rolesForm.handleSubmit(createRoleHandler, (error) =>
                console.log(error)
              )}
            >
              <div
                className=' nucleos-core'
                style={{
                  height: window.screen.height * 0.6,
                  overflow: 'scroll'
                }}
              >
                <Box sx={{
                  'marginTop': '16px',
                  '& .input-wrapper': {
                    justifyContent: 'flex-start !important'
                  },

                  '& .nucleos-input': {
                    background: '#ffffff !important',
                    color: '#333333 !important',
                    border: '1px solid #D3D3D3 !important',
                    borderRadius: '4px',
                    width: '100%',
                    marginTop: '4px',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '19px'
                  }
                }}>
                  <Box component='label' sx={styles.inputLabel}>
                    Role Name <span>*</span>
                  </Box>
                  <Controller
                    name="name"
                    control={rolesForm.control}
                    render={({ field, fieldState }) => (
                      <TextInput
                        {...field}
                        placeholder="Enter Role Name"
                        onChange={(_, evt) => field.onChange(evt)}
                        value={field.value}
                        disabled={isSystemDefinedRole}
                        error={!!fieldState.error}
                        helperText={(fieldState.error || {}).message}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={{
                  '& .input-wrapper': {
                    justifyContent: 'flex-start !important',
                    background: '#ffffff !important',
                    color: '#333333 !important',
                    border: '1px solid #D3D3D3 !important',
                    borderRadius: '4px',
                    width: '100%',
                    marginTop: '4px'
                  },
                  '& textarea': {
                    background: '#ffffff !important',
                    color: '#333333 !important',
                    height: '80px',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '19px'
                  }
                }}>
                  <Box component='label' sx={styles.inputLabel}>
                    Description <span>*</span>
                  </Box>
                  <Controller
                    name="description"
                    control={rolesForm.control}
                    render={({ field, fieldState }) => (
                      <TextInput
                        {...field}
                        placeholder="Enter Description"
                        multiline
                        rows={10}
                        onChange={(_, evt) => field.onChange(evt)}
                        value={field.value}
                        disabled={isSystemDefinedRole}
                        error={!!fieldState.error}
                        helperText={(fieldState.error || {}).message}
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <div className="w-60">
                  <Typography className="mt-2">Role Type</Typography>
                  <Controller
                    name="isLearner"
                    control={rolesForm.control}
                    render={({ field }) => (
                      <ToggleButton
                        className="mb-2"
                        options={[
                          { label: 'Non-learner', value: false },
                          { label: 'Learner', value: true }
                        ]}
                        disabled={isEditRole || isSystemDefinedRole}
                        onChange={(option) => {
                          field.onChange(option.value);
                          rolesForm.reset(
                            {
                              ...rolesForm.getValues(),
                              isGrievanceEnabled: true,
                              grievancePermission: option.value
                                ? defaultFormValuesLearner.grievancePermission
                                : defaultFormValuesNonLearner.grievancePermission
                            },
                            { keepDirty: false, keepTouched: true }
                          );
                        }}
                        defaultSelected={field.value}
                      />
                    )}
                  />
                </div>
                <Box sx={{
                  border: '1px solid #D3D3D3 !important',
                  borderRadius: '4px',
                  marginTop: '16px'
                  // padding: '16px'
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '4px',
                    padding: '16px 12px',
                    height: '46px'
                  }}>
                    <Box sx={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      fontWeight: '600',
                      color: '#333333'
                    }}>Grievance</Box>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <Controller
                        name="isGrievanceEnabled"
                        control={rolesForm.control}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            checked={field.value}
                            onChange={(event) => field.onChange(event.target.checked)}
                            className={styles.grievanceToggleSelect}
                            disabled={isSystemDefinedRole}
                            color='primary'
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Controller
                    name="isGrievanceEnabled"
                    control={rolesForm.control}
                    render={({ fieldState }) => (
                      <Typography
                        color="error"
                        className={'mx-3 ' + (fieldState.error ? 'mb-1' : '')}
                      >
                        {(fieldState.error || {}).message}
                      </Typography>
                    )}
                  />
                  {isGrievanceEnabled
                    ? (
                      <Box sx={{
                        borderTop: '1px solid #D3D3D3 !important',
                        padding: '16px 12px'
                      }}>
                        <Controller
                          name="grievancePermission.permissions.root"
                          control={rolesForm.control}
                          render={({ fieldState }) => (
                            <Typography
                              color="error"
                              className={fieldState.error ? 'my-2' : ''}
                            >
                              {(fieldState.error || {}).message}
                            </Typography>
                          )}
                        />
                        {isGrievanceEnabled &&
                        (isLearnerRole
                          ? (
                            <div>
                              <Controller
                                name={'grievancePermission.permissions.0'}
                                control={rolesForm.control}
                                key={'learner-controller'}
                                render={({ field, fieldState }) => {
                                  return (
                                    <GrievanceLevel
                                      name={'learner.grievancePermission.permissions.0'}
                                      label={'Permissions'}
                                      actions={[
                                        actionTypes.VIEW,
                                        actionTypes.CREATE
                                      ]}
                                      disabledActions={
                                        field.value.permission[
                                          actionTypes.VIEW
                                        ] === permissionEnum.NONE
                                          ? [actionTypes.CREATE]
                                          : []
                                      }
                                      onPermissionChange={(action, value) => {
                                        onPermissionChange({
                                          fieldName: field.name,
                                          levelPermission: field.value.permission,
                                          action,
                                          value
                                        });
                                      }}
                                      permission={field.value.permission}
                                      errors={fieldState.error}
                                      isLearner={true}
                                      isDropdownsDisabled={
                                        isSystemDefinedRole || !isGrievanceEnabled
                                      }
                                    />
                                  );
                                }}
                              />
                            </div>
                          )
                          : (
                            <div>
                              {[1, 2, 3].map((level) => (
                                <Controller
                                  key={level}
                                  name={`grievancePermission.permissions.${level - 1}`}
                                  control={rolesForm.control}
                                  render={({ field, fieldState }) => {
                                    return (
                                      <GrievanceLevel
                                        id={`nonLearner.grievancePermission.permissions.${level}`}
                                        label={`Level ${level}`}
                                        actions={[
                                          actionTypes.VIEW,
                                          actionTypes.RESPOND,
                                          actionTypes.ASSIGN,
                                          actionTypes.DUPLICATE,
                                          actionTypes.REQUEST_TYPE_CHANGE
                                        ]}
                                        disabledActions={
                                          field.value.permission[
                                            actionTypes.VIEW
                                          ] === permissionEnum.NONE
                                            ? [
                                              actionTypes.RESPOND,
                                              actionTypes.ASSIGN,
                                              actionTypes.DUPLICATE,
                                              actionTypes.REQUEST_TYPE_CHANGE
                                            ]
                                            : []
                                        }
                                        isSwitchedOn={field.value.isEnabled}
                                        onSwitchChange={() =>
                                          rolesForm.setValue(
                                            `${field.name}.isEnabled`,
                                            !field.value.isEnabled,
                                            formUpdateOptions
                                          )
                                        }
                                        onPermissionChange={(action, value) =>
                                          onPermissionChange({
                                            fieldName: field.name,
                                            levelPermission:
                                            field.value.permission,
                                            action,
                                            value
                                          })
                                        }
                                        permission={field.value.permission}
                                        errors={fieldState.error}
                                        isDropdownsDisabled={
                                          isSystemDefinedRole ||
                                        !isGrievanceEnabled
                                        }
                                        isSwitchDisabled={
                                          isSystemDefinedRole ||
                                        !isGrievanceEnabled
                                        }
                                      />
                                    );
                                  }}
                                />
                              ))}
                            </div>
                          ))}
                        {isFilterByCategorySelected && (
                          <div>
                            <Box sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              color: 'rgba(51, 51, 51, 0.9)'
                            }}>
                            Categories{' '}
                              <span style={{ color: '#F83232' }}>*</span>
                            </Box>
                            <Controller
                              control={rolesForm.control}
                              name="grievancePermission.categories"
                              render={({ field, fieldState }) => (
                                <div>
                                  <Typography color="error" className="my-2">
                                    {(fieldState.error || {}).message}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexFlow: 'wrap'
                                    }}
                                  >
                                    <Box
                                      sx={styles.categoryCheckbox}
                                      ref={field.ref}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={() =>
                                              allCategoryHandler(field.value)
                                            }
                                            checkedIcon={getCheckedIcon(
                                              field.value
                                            )}
                                            checked={true}
                                            className={'allCategory'}
                                            disabled={isSystemDefinedRole}
                                          />
                                        }
                                        label={'All Categories'}
                                      />
                                    </Box>
                                    {allCategories.map((cat) => (
                                      <Box sx={styles.categoryCheckbox}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={field.value.includes(
                                                cat.id
                                              )}
                                              onChange={() =>
                                                categoryChangeHandler(
                                                  cat.id,
                                                  field.value
                                                )
                                              }
                                              id={cat.id}
                                              className={
                                                field.value.includes(cat.id)
                                                  ? 'categoryChecked'
                                                  : 'category'
                                              }
                                              disabled={isSystemDefinedRole}
                                            />
                                          }
                                          label={cat.name}
                                        />
                                      </Box>
                                    ))}
                                  </Box>
                                </div>
                              )}
                            />
                          </div>
                        )}
                      </Box>
                    )
                    : null}
                </Box>
              </div>
              {!isSystemDefinedRole
                ? (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '16px'
                  }}>
                    <Button
                      sx={{
                        background: '#ffffff',
                        borderRadius: '4px',
                        color: '#333333',
                        height: '35px',
                        fontSize: '14px',
                        lineHeight: '19px',
                        textTransform: 'capitalize',
                        border: '1px solid #cccccc'
                      }}
                      onClick={closeModalHandler}
                    >
                    Cancel
                    </Button>
                    <div
                      className="nucleos-core"
                    >
                      {isEditRole
                        ? (
                          <NucleosButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={styles.createBtn}
                            loading={roleMutation.isLoading}
                            disabled={
                              !rolesForm.formState.isDirty || roleMutation.isLoading
                            }
                          >
                        Update
                          </NucleosButton>
                        )
                        : (
                          <NucleosButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={styles.createBtn}
                            disabled={
                              !rolesForm.formState.isDirty || roleMutation.isLoading
                            }
                          >
                        Create
                          </NucleosButton>
                        )}
                    </div>
                  </Box>
                )
                : null}
            </form>
          </LoadingAndErrorHandler>
        </Box>
      </Modal>
    </Box>
  );
};

export default (CreateRoleRefactored);
