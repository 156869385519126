import React from 'react';
import { observer } from 'mobx-react';
import { Paper, Grid } from '@mui/material';
import { Input, Textarea } from '../../../../../Components/Form';
import { formatDateTime } from '../../../../../Lib/util';

const MessageDetail = ({ classes, message }) => {
  if (!message) {
    return null;
  } else {
    return (
      <>
        <div className={classes.detailHeader2}>
          <h2 style={{ textDecoration: 'underline' }}>Message Details</h2>
        </div>

        <Paper className={classes.paperBaseStyle} style={{ maxWidth: '100%' }}>
          <form className='user-form' method='post'>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      label='Message ID'
                      name='messageId'
                      value={message.id}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='Sent Date'
                      name='firstName'
                      value={formatDateTime(message.createdAt)}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='Length'
                      name='length'
                      value={message.message.length}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      label='Message Flagged'
                      name='flagged'
                      readOnly
                      value={message.flagged ? 'TRUE' : 'FALSE'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      label='Alert Keywords'
                      name='keywords'
                      value={message.flaggedWords}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <Textarea
                  label={'Message'}
                  value={message.message}
                  name="message"
                  readOnly
                  rows={6}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
};

export default observer(MessageDetail);
